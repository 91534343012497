<div class="card" *ngIf="!isNewJourney">
  <div class="card-body">
  </div>
</div>
<div class="form-display">
  <form [formGroup]="formData" action="{{paymentUrl}}" method="post" name="payDollarForm" id="payDollarForm"
    #payDollarForm>
    <input type="text" id="orderRef" name="orderRef" formControlName="orderRef">
    <input type="text" id="amount" name="amount" formControlName="amount">
    <input type="text" id="currCode" name="currCode" formControlName="currCode">
    <input type="text" id="merchantId" name="merchantId" formControlName="merchantId">
    <input type="text" id="remark" name="remark" formControlName="remark">
    <input type="text" id="successUrl" name="successUrl" formControlName="successUrl">
    <input type="text" id="cancelUrl" name="cancelUrl" formControlName="cancelUrl">
    <input type="text" id="failUrl" name="failUrl" formControlName="failUrl">
    <input type="text" id="mpsMode" name="mpsMode" formControlName="mpsMode">
    <input type="text" id="payType" name="payType" formControlName="payType">
    <input type="text" id="lang" name="lang" formControlName="lang">
    <input type="text" id="payMethod" name="payMethod" formControlName="payMethod">
    <input type="text" id="redirect" name="redirect" formControlName="redirect">
    <input type="text" id="secureHash" name="secureHash" formControlName="secureHash">
    <input value="Submit" type="submit">
  </form>
</div>