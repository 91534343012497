import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateService, HaveLatestPage } from './can-activate.service';
import { ManageOnboardingComponent } from './manage-onboarding.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ProfileComponent } from './profile/profile.component';
import { ReviewProfileComponent } from './review-profile/review-profile.component';
import { IdentityDocumentComponent } from './identity-document/identity-document.component';
import { IdentityDetailComponent } from './identity-detail/identity-detail.component';
import { AddressDetailComponent } from './address-detail/address-detail.component';
import { IdentitySelectionComponent } from './identity-selection/identity-selection.component';
const routes: Routes = [
  {
    path: '',
    component: ManageOnboardingComponent,
    canActivate: [CanActivateService],
    data: { state: 'onboarding', pagename: 'Let\'s complete your profile ' },
    children: [
      {
        path: '',
        component: WelcomePageComponent,
        data: { state: 'welcome' }
      },
      {
        path: 'identity-selection',
        component: IdentitySelectionComponent,
        data: { state: 'selectIdType', pagename: 'Upload your ID' },
        canActivate: [HaveLatestPage]
      },
      {
        path: 'identity-document',
        component: IdentityDocumentComponent,
        data: { state: 'uploadId', pagename: 'Upload your ID' },
        canActivate: [HaveLatestPage]
      },
      {
        path: 'identity-detail',
        component: IdentityDetailComponent,
        data: { state: 'detailId', pagename: 'Confirm your ID details' },
        canActivate: [HaveLatestPage]
      },
      {
        path: 'address-detail',
        component: AddressDetailComponent,
        data: {
          state: 'addressDetail', pagename: 'Confirm your address'
        },
        canActivate: [HaveLatestPage]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { state: 'profile', pagename: 'Add to your profile' },
        canActivate: [HaveLatestPage]
      },
      {
        path: 'review-profile',
        component: ReviewProfileComponent,
        data: { state: 'reviewProfile', pagename: 'Review your profile details' },
        canActivate: [HaveLatestPage]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnBoardingRoutingModule { }
