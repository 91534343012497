import { InjectionToken } from '@angular/core';
import {v4 as uuidv4} from 'uuid';

export const UUID_TOKEN = new InjectionToken<string>('UUID_TOKEN');

// Generate UUID V4
export const generateUuidV4 = (): string => {
  return uuidv4();
};

