<app-form-wrapper [hasBackButton]="false">
  <div title>Terms and conditions</div>
  <div subTitle>
  </div>
  <div content class="main-content">
    <div class="terms-and-condition-box">
      <div class="row">
        <div class="col-12 page-header">
          <h2>IELTS One Skill Retake: Terms and Conditions</h2>
        </div>
      </div>
      <div class="bg-theme has-border-top has-border-bottom mb-4 row p-2">
        <div class="col-md-10 col-lg-8 col-print-10 center-block">
          <div class="textalign well" *ngIf="!testCentreName?.toLocaleLowerCase()?.startsWith('hk')">
            <b>IELTS Declaration</b>
            <ol>
              <li>
                I acknowledge that the IELTS test is jointly owned by British Council, IELTS Australia Pty Ltd (ABN 84
                008 664 766), which is wholly owned by IDP Education Ltd (‘IDP: IELTS Australia’) and Cambridge
                Assessment English (part of the University of Cambridge), collectively referred to as the IELTS Test
                Partners.
              </li>
              <li>
                I acknowledge that I have read the IELTS Information for Candidates booklet.
              </li>
              <li>
                I acknowledge that I have read the ‘IELTS Notice to Candidates’ in this Application Form and agree to
                abide by IELTS test terms and conditions.
              </li>
              <li>I understand that there may be local terms and conditions that I must comply with and that the centre
                will provide details of these on request
              </li>
              <li>I understand that if the details on this Application Form are not completed my application may not be
                processed. I further understand that completing and submitting this form does not guarantee registration
                on my preferred test date or at my preferred test location. I understand that my registration will be
                confirmed in writing from the test centre.
              </li>
              <li>I certify that the information in my application is complete, true and accurate.
              </li>
              <li>Your computer-delivered IELTS test session may be cancelled or postponed on short notice if technical
                issues impact on the running of the test. If a session is cancelled for technical issues, you may choose
                to be rescheduled to the next available test at the same Test Centre or have a full refund of the test
                fee.
              </li>
              <li>I understand that my test result cannot be used for United Kingdom Visas and Immigration (UKVI)
                application purposes and that I will not be eligible for a refund if I subsequently find that I require
                an IELTS for UKVI test result.
              </li>
            </ol>
            <b>Personal Information
            </b>
            <div>
              I understand that:
            </div>
            <ol>
              <li>
                It is necessary for my personal information to be collected and processed in order for me to take the
                IELTS test, to verify my identity and to receive my results. This personal information is collected and
                securely stored when I apply for and when I take the IELTS test and is used by the IELTS Test Partners
                for the purpose of the IELTS test.

              </li>
              <li>
                My personal information, including, without limitation, test performance or score data and my test
                result may be disclosed by the IELTS Test Partners to Recognising Organisations to which I apply and to
                governments (including visa processing authorities) for the purpose of allowing these organisations to
                verify my test result.

              </li>
              <li>
                My personal information may be processed in an anonymous form by the IELTS Test Partners for statistical
                and research purposes.

              </li>
              <li>
                My photograph is taken by the test centre on test day and will appear on my Test Report Form. This
                photograph will be provided to any Recognising Organisations or government authorities to which I apply
                to allow these organisations to verify my test result.

              </li>
              <li>
                My Speaking test will be recorded. In the event the test does not record I will be required to re-take
                the Speaking test. An observer may attend my Speaking test as part of the examiner monitoring process.

              </li>
              <li>
                I may be filmed for security and quality assurance purposes when registering for the test and during the
                test. The video footage will be for held for 60 days after my test and may be used for audit or
                investigation purposes.

              </li>
              <li>
                I will be required to verify my identity on test day by providing the identity document specified by the
                test centre. I may also be requested to confirm my identity on test day by having a finger scan taken.
                The finger scan is taken as a Binary Large Object (BLOB), and no image of my fingerprint will be held.
                This biometric data will be held for 60 days after my test and will not be disclosed to any entity
                except the IELTS Test Partners.

              </li>
              <li>
                I can access further details on how the IELTS Test Partners use my personal information (including how
                long it is retained in different circumstances and how I can exercise my rights) at
                <a target="_blank" rel="noopener"
                  href="https://www.ielts.org/policy/privacy-policy">www.ielts.org/policy/privacy-policy</a>
              </li>
            </ol>
            <b>Test Fees and Refunds
            </b>
            <div>I understand that:</div>
            <ol>
              <li>
                I will be charged the full test fee upon registration.

              </li>
              <li>
                If I request a transfer or cancel my test, my right to a refund will be determined in accordance with
                local legislation.

              </li>
              <li>
                Chargebacks Prohibition. I agree that I will not dispute or otherwise seek a “chargeback” from the
                company whose credit card or other method of payment I use to book and pay for the IELTS test or
                otherwise attempt to reverse payment. If I do so, my IELTS test and/or results may be cancelled
                immediately, and the IELTS partners may, at our discretion, refuse to accept future purchases made from
                accounts on which such chargebacks have been made, and may prohibit future purchases from the account
                holder, and from any person who booked an IELTS test and reversed payment.
              </li>
            </ol>
            <b>Test Integrity, Security and Malpractice</b>
            <div>I understand that:</div>
            <ol>
              <li>
                I must attend all four test components in order to receive a test result and any exception to this must
                be approved in advance by the test centre.

              </li>
              <li>
                The IELTS Test Partners have a responsibility to all candidates and Recognising Organisations to ensure
                the highest confidence in IELTS test results. The IELTS Test Partners may therefore be required to
                withhold test results temporarily or permanently, or to cancel test results which have been issued, if
                they consider those results to be unreliable for any reason.

              </li>
              <li>
                My test result may not be issued 13 days after the test if any of the IELTS Test Partners consider it
                necessary to review any matter associated with my test or the administration of my test, including
                making enquiries as to whether any rules or regulations have been breached. I may be required to provide
                additional samples of my writing and speaking for the purposes of assisting any investigation before or
                after the test. In exceptional circumstances I may be required to re-take one or more IELTS components.

              </li>
              <li>
                If I am suspected of engaging in any form of malpractice, or do anything that might damage the integrity
                and security of IELTS, I may not receive a test result, my test fee will not be refunded and I may be
                banned from taking the IELTS test in the future. If I am banned I will receive a notification stating
                the length of the ban. If I am subject to a ban and I register to take the test, I will not receive a
                test result and I will not be entitled to a refund

              </li>
              <li>
                Details of any proven or suspected malpractice may be provided to Recognising Organisations, including
                governments (including visa processing authorities) and appropriate regulatory authorities, and
                centrally to the IELTS Test Partners. In instances where malpractice has been proven, I may be liable to
                prosecution.

              </li>
              <li>
                The work I produce in the IELTS test remains the property of the IELTS Test Partners and will not be
                released to me. In cases of proven or suspected malpractice my work may be provided to relevant
                authorities.
              </li>
            </ol>
            <b>Disclaimer:</b>
            <div>
              <p>
                The International English Language Testing System (IELTS) is designed to be one of many factors used by
                academic
                institutions, government agencies, professional bodies and employers in determining whether a test taker
                can be admitted
                as a student or be considered for employment or for citizenship purposes. IELTS is not designed to be
                the sole method of
                determining admission or employment for the test taker. IELTS is made available worldwide to all
                persons, regardless of
                age, gender, race, nationality or religion, but it is not recommended to persons under 16 years of age.

                British Council, IDP: IELTS Australia and Cambridge Assessment English and any other party involved in
                creating,
                producing, or delivering IELTS shall not be liable for any direct, incidental, consequential, indirect,
                special,
                punitive, or similar damages arising out of access to, use of, acceptance by, or interpretation of the
                results by any
                third party, or any errors or omissions in the content therein

                IELTS test terms and conditions
              </p>
            </div>
            <div>
              <b>You must...</b>
              <ul>
                <li>
                  provide proof of your identity (passport or National Identity Card) at registration. Contact the test
                  centre to confirm which type of identity document is accepted. Candidates taking the test outside
                  their own country must use a passport.

                </li>
                <li>
                  inform the centre of any changes to your identity document before the test date. If you do not do this
                  you will not be allowed to take the test and you will not be eligible for a refund or transfer.

                </li>
                <li>
                  bring the same identity document on the test day as the one recorded in your application. If you do
                  not do this you will not be allowed to take the test and you will not be eligible for a refund or
                  transfer.

                </li>
                <li>
                  arrive at the centre before the scheduled test start time. If you arrive late, you will not be allowed
                  to take the test and you will not be eligible for a refund or transfer.

                </li>
                <li>
                  leave all personal belongings in the designated belongings area. The only items you may bring into the
                  test room are your approved identity document, standard pen(s), pencil(s) and eraser(s), and a bottle
                  of water (label-free). All electronic devices and all watches must be left in the belongings area and
                  must be switched off. You may be electronically scanned for devices at any time during the test day.
                  Any candidate who breaches these conditions will not receive an IELTS test result or be eligible for a
                  refund or transfer. While the centre will take all reasonable measures to secure your items in the
                  belongings area, they cannot be held responsible for any loss.

                </li>
                <li>
                  consent for your identity to be verified both at test registration and on test day. This may include:
                  <ul>
                    <li>
                      having your photograph taken. You will be required to temporarily remove any covering from your
                      face. Any candidate who refuses to have a photograph taken will not be allowed to sit the test and
                      will not be entitled to a refund. The photograph taken by the centre will appear on your Test
                      Report Form.

                    </li>
                    <li>
                      providing a sample of your signature.

                    </li>
                    <li>
                      having your finger scan taken.

                    </li>
                  </ul>
                </li>
                <li>
                  keep only the following items on your desk: your identity document, pen(s), pencil(s), eraser(s), and
                  a bottle of water (label-free).

                </li>
                <li>
                  tell the invigilator at once if you think you have not been given the correct question paper or if the
                  question paper is incomplete or illegible.

                </li>
                <li>
                  raise your hand if you need to ask the invigilator something. Candidates may not ask for, and will not
                  be given, any explanation of the test questions.

                </li>
                <li>
                  attend all four components of the test. If you do not attend all four components, you will not receive
                  an IELTS test result. Exceptions are possible but must be requested at registration and written
                  approval must be received from the centre.

                </li>
                <li>
                  inform the invigilator on the day of the test if you believe that your performance may be affected by
                  ill health, by the way in which the test is delivered at the centre or for any other reason. If you
                  have any issues, concerns or complaints relating to the delivery of the test you can submit a
                  complaint. All complaints will be dealt with in line with our Complaints Policy available on <a
                    target="_blank" rel="noopener" href="https://www.ielts.org/for-test-takers/ielts-complaints-policy">ielts.org</a>

                </li>
                <li>
                  leave all test materials in the test room at the end of the test. The test materials include question
                  papers, Speaking booklets, answer sheets and rough paper. Any candidate who attempts to remove test
                  materials from the test room will not receive an IELTS test result.

                </li>
              </ul>
              <b>You must not...</b>
              <ul>
                <li>
                  talk to or disturb other candidates once the test has started.

                </li>
                <li>
                  lend anything to, or borrow anything from, another candidate during the test.

                </li>
                <li>
                  eat or smoke in the test room.

                </li>
                <li>
                  leave the test room without the permission of the invigilator.

                </li>
                <li>
                  leave your seat until all test materials have been collected and you have been told you can leave.

                </li>
                <li>
                  engage in any form of malpractice which may damage the integrity and security of the IELTS test.
                  Malpractice includes, but is not limited to:
                  <ul>
                    <li>
                      attempting to cheat in any way, including using notes of any kind from any source.

                    </li>
                    <li>
                      helping another candidate to cheat.

                    </li>
                    <li>
                      impersonating another candidate or having another candidate impersonate you.

                    </li>
                    <li>
                      copying the work of another candidate.

                    </li>
                    <li>
                      disrupting the test in any way.

                    </li>
                    <li>
                      reproducing any part of the test in any medium.

                    </li>
                    <li>
                      attempting to alter the data on the Test Report Form.

                    </li>
                  </ul>
                </li>
                <li>
                  Candidates engaging in malpractice will not be allowed to complete the test and will not receive an
                  IELTS test result. Candidates engaging in malpractice may be banned from taking the test in future,
                  and may be liable to legal action. Candidates engaging in malpractice may be reported to regulatory
                  authorities globally.

                </li>
              </ul>
            </div>
            <div>
              <b>Your IELTS test result</b>
              <ul>
                <li>
                  Results are issued by centres, usually 13 days after the test.

                </li>
                <li>
                  You will receive only one copy of your Test Report Form. Replacement copies are not issued in the
                  event of loss or damage.

                </li>
                <li>
                  The Test Report Form will be issued in your name as it appears on the identity document used at
                  registration. If you find that your personal details are incorrect on the Test Report Form, please
                  contact the centre where you took the test. Documentation must be provided to verify the correct
                  details. If you change your name after receiving your Test Report Form, the name will not be changed
                  on the Test Report Form.

                </li>
                <li>
                  Your result may not be issued 13 days after the test if the IELTS Test Partners decide that it is
                  necessary to review any matter associated with your test or the administration of your test. To assist
                  any investigation, you may be required to provide writing and speaking samples. In exceptional
                  circumstances you may be required to re-take one or more IELTS components.

                </li>
                <li>
                  Your result may be cancelled after it has been issued if any irregularity is identified. You may be
                  required to re-take one or more IELTS components.

                </li>
                <li>
                  Your result will be disclosed to the Recognising Organisations which you nominated in your application
                  or to which you applied with your Test Report Form, for the purpose of allowing those organisations to
                  verify the result or to carry out any enquiries in relation to suspected malpractice.

                </li>
                <li>
                  If any of the data on the Test Report Form provided by you or your agent to Recognising Organisations
                  has been altered in any way, your original test result may be cancelled.

                </li>
                <li>
                  Candidates are not permitted access to the work they produce in the IELTS test.

                </li>
                <li>
                  I understand that there may be video recording and CCTV (Closed Circuit TV) in the test centre. I
                  agree to be videoed while I am in the test centre on the test day, including from the place where I
                  register and in each of the test rooms used for each part of the test I take. I understand that video
                  recordings will be used for monitoring purposes, investigations into suspected malpractice and
                  enquiries on results. I understand if I do not agree to be video recorded I will not be able to take
                  the test.

                </li>
              </ul>
            </div>
            <div>
              Cancelling your IELTS test or requesting a transfer
              <ul>
                <li>
                  For information on cancelling your IELTS test or requesting a transfer, please go to <a
                    target="_blank" rel="noopener" href="https://www.ielts.org/">www.ielts.org</a>.
                </li>
              </ul>
            </div>
            <div>
              How IELTS uses your information
              <ul>
                <li>
                  The IELTS Test Partners recognise and support the right of IELTS test candidates to privacy.

                </li>
                <li>
                  When you provide your identity information the IELTS Test Partners link that information to your IELTS
                  test and test result on the Test Report Form. This enables you to submit your Test Report Form to
                  Recognising Organisations and for them to verify your result.

                </li>
                <li>
                  Test Report Forms will only be sent to those Recognising Organisations nominated by the candidate in
                  their application or at the request of the candidate after the issue of results. When a candidate
                  submits a Test Report Form to a Recognising Organisation they consent to the verification of the
                  result by that organisation.

                </li>
                <li>
                  The IELTS Test Partners or their authorised representatives may share candidate personal data
                  including without limitation test performance or score data or photographs taken by the centre with
                  educational institutions, governments (including visa processing authorities), professional bodies and
                  commercial organisations that recognise IELTS scores (‘Recognising Organisations’) or law enforcement
                  agencies and regulatory authorities where required for verification purposes or other purposes to
                  protect the IELTS test and its stakeholders against any form of malpractice. Finger-scan data, where
                  obtained, will not be disclosed to any entity except the IELTS Test Partners.

                </li>
                <li>
                  The IELTS Test Partners will retain work produced by candidates in the IELTS test and may use it for
                  quality control purposes and for investigations into suspected malpractice. Test score data and test
                  responses, in an anonymous form, may also be used for informational, research, statistical or training
                  purposes.

                </li>

              </ul>
            </div>
            <div>
              <div><b>Acknowledgement</b></div>
              <ul>
                <li>
                  I understand and acknowledge that completion of this Application & Payment Form does not guarantee
                  that I
                  will be enrolled in a test.
                </li>
                <li>
                  I have read and understand <a target="_blank" rel="noopener"
                    [href]="hrefValues.legalNoticeAusUrl">
                    IELTS Australia's Privacy Policy </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="textalign well" *ngIf="testCentreName?.toLocaleLowerCase()?.startsWith('hk')">
            <b>IELTS Declaration</b>
            <ol>
              <li>
                I acknowledge that the IELTS test is jointly owned by British Council, IELTS Australia Pty Ltd (ABN 84
                008 664 766), which is wholly owned by IDP Education Ltd (‘IDP: IELTS Australia’) and Cambridge
                Assessment English (part of the University of Cambridge), collectively referred to as the IELTS Test
                Partners.

              </li>
              <li>
                I acknowledge that I have read the IELTS Information for Candidates booklet.

              </li>
              <li>
                I acknowledge that I have read the ‘IELTS Notice to Candidates’ in this Application Form and agree to
                abide by IELTS test terms and conditions.

              </li>
              <li>
                I understand that there may be local terms and conditions that I must comply with and that the centre
                will provide details of these on request

              </li>
              <li>

                I understand that if the details on this Application Form are not completed my application may not be
                processed. I further understand that completing and submitting this form does not guarantee registration
                on my preferred test date or at my preferred test location. I understand that my registration will be
                confirmed in writing from the test centre.
              </li>
              <li>
                I certify that the information in my application is complete, true and accurate.

              </li>
              <li>
                Your computer-delivered IELTS test session may be cancelled or postponed on short notice if technical
                issues impact on the running of the test. If a session is cancelled for technical issues, you may choose
                to be rescheduled to the next available test at the same Test Centre or have a full refund of the test
                fee.

              </li>
              <li>
                I understand that my test result cannot be used for United Kingdom Visas and Immigration (UKVI)
                application purposes and that I will not be eligible for a refund if I subsequently find that I require
                an IELTS for UKVI test result.

              </li>
            </ol>
            <b>Personal Information</b>
            <div>I understand that:</div>
            <ol>
              <li>
                It is necessary for my personal information to be collected and processed in order for me to take the
                IELTS test, to verify my identity and to receive my results. This personal information is collected and
                securely stored when I apply for and when I take the IELTS test and is used by the IELTS Test Partners
                for the purpose of the IELTS test.

              </li>
              <li>
                My personal information, including, without limitation, test performance or score data and my test
                result may be disclosed by the IELTS Test Partners to Recognising Organisations to which I apply and to
                governments (including visa processing authorities) for the purpose of allowing these organisations to
                verify my test result.

              </li>
              <li>
                My personal information may be processed in an anonymous form by the IELTS Test Partners for statistical
                and research purposes.

              </li>
              <li>
                My photograph is taken by the test centre on test day and will appear on my Test Report Form. This
                photograph will be provided to any Recognising Organisations or government authorities to which I apply
                to allow these organisations to verify my test result.

              </li>
              <li>
                My Speaking test will be recorded. In the event the test does not record I will be required to re-take
                the Speaking test. An observer may attend my Speaking test as part of the examiner monitoring process.

              </li>
              <li>
                I may be filmed for security and quality assurance purposes when registering for the test and during the
                test. The video footage will be for held for 60 days after my test and may be used for audit or
                investigation purposes.

              </li>
              <li>
                I will be required to verify my identity on test day by providing the identity document specified by the
                test centre. I may also be requested to confirm my identity on test day by having a finger scan taken.
                The finger scan is taken as a Binary Large Object (BLOB), and no image of my fingerprint will be held.
                This biometric data will be held for 60 days after my test and will not be disclosed to any entity
                except the IELTS Test Partners.

              </li>
              <li>
                I can access further details on how the IELTS Test Partners use my personal information (including how
                long it is retained in different circumstances and how I can exercise my rights) at <a target="_blank"
                  rel="noopener" href="https://www.ielts.org/policy/privacy-policy">www.ielts.org/policy/privacy-policy</a>

              </li>
            </ol>
            <b>Test Fees and Refunds</b>
            <div>I understand that:</div>
            <ol>
              <li>
                I will be charged the full test fee upon registration.

              </li>
              <li>
                If I request a transfer or cancel my test, my right to a refund will be determined in accordance with
                local legislation.

              </li>
              <li>
                Chargebacks Prohibition. I agree that I will not dispute or otherwise seek a “chargeback” from the
                company whose credit card or other method of payment I use to book and pay for the IELTS test or
                otherwise attempt to reverse payment. If I do so, my IELTS test and/or results may be cancelled
                immediately, and the IELTS partners may, at our discretion, refuse to accept future purchases made from
                accounts on which such chargebacks have been made, and may prohibit future purchases from the account
                holder, and from any person who booked an IELTS test and reversed payment.

              </li>
            </ol>
            <b>
              Test Integrity, Security and Malpractice
            </b>
            I understand that:
            <ol>
              <li>
                I must attend all four test components in order to receive a test result and any exception to this must
                be approved in advance by the test centre.

              </li>
              <li>
                The IELTS Test Partners have a responsibility to all candidates and Recognising Organisations to ensure
                the highest confidence in IELTS test results. The IELTS Test Partners may therefore be required to
                withhold test results temporarily or permanently, or to cancel test results which have been issued, if
                they consider those results to be unreliable for any reason.

              </li>
              <li>
                My test result may not be issued 13 days after the test if any of the IELTS Test Partners consider it
                necessary to review any matter associated with my test or the administration of my test, including
                making enquiries as to whether any rules or regulations have been breached. I may be required to provide
                additional samples of my writing and speaking for the purposes of assisting any investigation before or
                after the test. In exceptional circumstances I may be required to re-take one or more IELTS components.

              </li>
              <li>
                If I am suspected of engaging in any form of malpractice, or do anything that might damage the integrity
                and security of IELTS, I may not receive a test result, my test fee will not be refunded and I may be
                banned from taking the IELTS test in the future. If I am banned I will receive a notification stating
                the length of the ban. If I am subject to a ban and I register to take the test, I will not receive a
                test result and I will not be entitled to a refund

              </li>
              <li>
                Details of any proven or suspected malpractice may be provided to Recognising Organisations, including
                governments (including visa processing authorities) and appropriate regulatory authorities, and
                centrally to the IELTS Test Partners. In instances where malpractice has been proven, I may be liable to
                prosecution.

              </li>
              <li>
                The work I produce in the IELTS test remains the property of the IELTS Test Partners and will not be
                released to me. In cases of proven or suspected malpractice my work may be provided to relevant
                authorities.

              </li>
            </ol>
            <b>disclaimer:</b>
            <div>
              <p>The International English Language Testing System (IELTS) is designed to be one of many factors used by
                academic institutions, government agencies, professional bodies and employers in determining whether a
                test taker can be admitted as a student or be considered for employment or for citizenship purposes.
                IELTS
                is not designed to be the sole method of determining admission or employment for the test taker. IELTS
                is
                made available worldwide to all persons, regardless of age, gender, race, nationality or religion, but
                it
                is not recommended to persons under 16 years of age.

                British Council, IDP: IELTS Australia and Cambridge Assessment English and any other party involved in
                creating, producing, or delivering IELTS shall not be liable for any direct, incidental, consequential,
                indirect, special, punitive, or similar damages arising out of access to, use of, acceptance by, or
                interpretation of the results by any third party, or any errors or omissions in the content therein

                IELTS test terms and conditions</p>
            </div>
            <b>You must...</b>
            <ul>
              <li>
                provide proof of your identity (passport or National Identity Card) at registration. Contact the test
                centre to confirm which type of identity document is accepted. Candidates taking the test outside their
                own country must use a passport.

              </li>
              <li>
                inform the centre of any changes to your identity document before the test date. If you do not do this
                you will not be allowed to take the test and you will not be eligible for a refund or transfer.

              </li>
              <li>
                bring the same identity document on the test day as the one recorded in your application. If you do not
                do this you will not be allowed to take the test and you will not be eligible for a refund or transfer.

              </li>
              <li>
                arrive at the centre before the scheduled test start time. If you arrive late, you will not be allowed
                to take the test and you will not be eligible for a refund or transfer.

              </li>
              <li>
                leave all personal belongings in the designated belongings area. The only items you may bring into the
                test room are your approved identity document, standard pen(s), pencil(s) and eraser(s), and a bottle of
                water (label-free). All electronic devices and all watches must be left in the belongings area and must
                be switched off. You may be electronically scanned for devices at any time during the test day. Any
                candidate who breaches these conditions will not receive an IELTS test result or be eligible for a
                refund or transfer. While the centre will take all reasonable measures to secure your items in the
                belongings area, they cannot be held responsible for any loss.

              </li>
              <li>
                consent for your identity to be verified both at test registration and on test day. This may include:
                <ul>
                  <li>
                    having your photograph taken. You will be required to temporarily remove any covering from your
                    face. Any candidate who refuses to have a photograph taken will not be allowed to sit the test and
                    will not be entitled to a refund. The photograph taken by the centre will appear on your Test Report
                    Form.

                  </li>
                  <li>
                    providing a sample of your signature.

                  </li>
                  <li>
                    having your finger scan taken (persons under the age of 18 excluded). If you do not consent to
                    having your finger scan taken you must make this known to a test invigilator before a scan is taken.
                    You will then be directed to have your identity manually verified.

                  </li>
                </ul>
              </li>
              <li>
                keep only the following items on your desk: your identity document, pen(s), pencil(s), eraser(s), and a
                bottle of water
                (label-free).

              </li>
              <li>
                tell the invigilator at once if you think you have not been given the correct question paper or if the
                question paper is
                incomplete or illegible.

              </li>
              <li>
                raise your hand if you need to ask the invigilator something. Candidates may not ask for, and will not
                be given, any
                explanation of the test questions.

              </li>
              <li>
                attend all four components of the test. If you do not attend all four components, you will not receive
                an IELTS test
                result. Exceptions are possible but must be requested at registration and written approval must be
                received from the
                centre.

              </li>
              <li>
                inform the invigilator on the day of the test if you believe that your performance may be affected by
                ill health, by the way in which the test is delivered at the centre or for any other reason. If you have
                any issues, concerns or complaints relating to the delivery of the test you can submit a complaint. All
                complaints will be dealt with in line with our Complaints Policy available on <a target="_blank"
                  rel="noopener" href="https://www.ielts.org/for-test-takers/ielts-complaints-policy">ielts.org.</a> .
              </li>
              <li>
                leave all test materials in the test room at the end of the test. The test materials include question
                papers, Speaking booklets, answer sheets and rough paper. Any candidate who attempts to remove test
                materials from the test room will not receive an IELTS test result.

              </li>
            </ul>
            <b>You must not...</b>
            <ul>
              <li>
                talk to or disturb other candidates once the test has started.

              </li>
              <li>
                lend anything to, or borrow anything from, another candidate during the test.

              </li>
              <li>
                eat or smoke in the test room.

              </li>
              <li>
                leave the test room without the permission of the invigilator.

              </li>
              <li>
                leave your seat until all test materials have been collected and you have been told you can leave.

              </li>
              <li>
                engage in any form of malpractice which may damage the integrity and security of the IELTS test.
                Malpractice includes, but is not limited to:
                <ul>
                  <li>
                    attempting to cheat in any way, including using notes of any kind from any source.

                  </li>
                  <li>
                    helping another candidate to cheat.

                  </li>
                  <li>
                    impersonating another candidate or having another candidate impersonate you.

                  </li>
                  <li>
                    copying the work of another candidate.

                  </li>
                  <li>
                    disrupting the test in any way.

                  </li>
                  <li>
                    reproducing any part of the test in any medium.

                  </li>
                  <li>
                    attempting to alter the data on the Test Report Form.

                  </li>
                </ul>
              </li>
              <li>
                Candidates engaging in malpractice will not be allowed to complete the test and will not receive an
                IELTS test result.
                Candidates engaging in malpractice may be banned from taking the test in future, and may be liable to
                legal action.
                Candidates engaging in malpractice may be reported to regulatory authorities globally.

              </li>
            </ul>
            <b>Your IELTS test result</b>
            <ul>
              <li>
                Results are issued by centres, usually 13 days after the test.

              </li>
              <li>
                You will receive only one copy of your Test Report Form. Replacement copies are not issued in the event
                of loss or damage.

              </li>
              <li>
                The Test Report Form will be issued in your name as it appears on the identity document used at
                registration. If you find that your personal details are incorrect on the Test Report Form, please
                contact the centre where you took the test. Documentation must be provided to verify the correct
                details. If you change your name after receiving your Test Report Form, the name will not be changed on
                the Test Report Form.

              </li>
              <li>
                Your result may not be issued 13 days after the test if the IELTS Test Partners decide that it is
                necessary to review any matter associated with your test or the administration of your test. To assist
                any investigation, you may be required to provide writing and speaking samples. In exceptional
                circumstances you may be required to re-take one or more IELTS components.

              </li>
              <li>
                Your result may be cancelled after it has been issued if any irregularity is identified. You may be
                required to re-take one or more IELTS components.

              </li>
              <li>
                Your result will be disclosed to the Recognising Organisations which you nominated in your application
                or to which you applied with your Test Report Form, for the purpose of allowing those organisations to
                verify the result or to carry out any enquiries in relation to suspected malpractice.

              </li>
              <li>
                If any of the data on the Test Report Form provided by you or your agent to Recognising Organisations
                has been altered in any way, your original test result may be cancelled.

              </li>
              <li>
                Candidates are not permitted access to the work they produce in the IELTS test.

              </li>
            </ul>
            Cancelling your IELTS test or requesting a transfer

            <ul>
              <li>
                For information on cancelling your IELTS test or requesting a transfer, please go to <a target="_blank"
                  rel="noopener" [href]="hrefValues?.cxUrl">www.ielts.org</a>.
              </li>
            </ul>
            <b>How IELTS uses your information</b>
            <ul>
              <li>
                The IELTS Test Partners recognise and support the right of IELTS test candidates to privacy.

              </li>
              <li>
                When you provide your identity information the IELTS Test Partners link that information to your IELTS
                test and test result on the Test Report Form. This enables you to submit your Test Report Form to
                Recognising Organisations and for them to verify your result.

              </li>
              <li>
                Test Report Forms will only be sent to those Recognising Organisations nominated by the candidate in
                their application or at the request of the candidate after the issue of results. When a candidate
                submits a Test Report Form to a Recognising Organisation they consent to the verification of the result
                by that organisation.

              </li>
              <li>
                The IELTS Test Partners or their authorised representatives may share candidate personal data including
                without limitation test performance or score data or photographs taken by the centre with educational
                institutions, governments (including visa processing authorities), professional bodies and commercial
                organisations that recognise IELTS scores (‘Recognising Organisations’) or law enforcement agencies and
                regulatory authorities where required for verification purposes or other purposes to protect the IELTS
                test and its stakeholders against any form of malpractice. Finger-scan data, where obtained, will not be
                disclosed to any entity except the IELTS Test Partners.

              </li>
              <li>
                The IELTS Test Partners will retain work produced by candidates in the IELTS test and may use it for
                quality control purposes and for investigations into suspected malpractice. Test score data and test
                responses, in an anonymous form, may also be used for informational, research, statistical or training
                purposes.

              </li>
              <li>
                <a target="_blank" rel="noopener" href="https://www.idp.com/hongkong/pics/?lang=en">Personal Information Collection
                  Statement</a>
              </li>
            </ul>
            <div><b>Acknowledgement</b></div>
            <ul>
              <li>
                <p>
                  I understand and acknowledge that completion of this Application & Payment Form does not guarantee
                  that I
                  will be
                  enrolled in a test.
                </p>
              </li>
              <li>
                <p>
                  I have read and understand <a target="_blank" rel="noopener" [href]="hrefValues?.legalNotice">IELTS
                    Australia's
                    Privacy
                    Policy</a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-wrapper>