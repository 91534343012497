import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { ViewportService } from '../../../../../shared/services/viewport.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ssr-paging',
  templateUrl: 'ssr-paging.component.html',
  styleUrls: ['ssr-paging.component.scss']
})
export class SsrPagingComponent implements OnDestroy, AfterViewInit {

  @HostBinding('style.display') displayContents = 'block';
  @Output() onPageChange = new EventEmitter<any>();
  @Input() totalCount = 0;
  @Input() @Output() pageSize = 0;
  @Input() page = 1;
  @Input() pageSizeOptions: number[] = [5, 25, 50, 100];
  @Input() rows$: Observable<Array<any>> = of<[]>();

  get pagingRequired(): boolean {
    return this.totalCount > 0 && ((this.totalCount / this.pageSize) > 1);
  }

  get pageCount(): number {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  @ViewChild(NgbPagination, {static: false})
  set pager(p: NgbPagination) {
    this.initialize(p);
  }

  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private viewportService: ViewportService
  ) {
  }

  maxSize(): Observable<number> {
    return this.viewportService.isMobile$.pipe(map(isMobile => isMobile ? 3 : 6));
  }

  initialize(pager: NgbPagination | undefined) {

    if (!pager) {
      return;
    }

    this.subscriptions.push(pager.pageChange.subscribe((p: number) => {
      this.onPageChange.emit(p);
    }));

    this.subscriptions.push(this.rows$.subscribe((rows) => {
      if (rows) {
        pager.collectionSize = this.totalCount;
        pager.pageSize = this.pageSize;
        pager.selectPage(this.page);
      }
    }));
  }

  ngOnDestroy() {
    const unsubscribedValue = this.subscriptions.map(sub => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
