<div class="card">
  <div class="card-body">
    <p>Please wait while we fetch your payment status</p>
  </div>
</div>
<app-confirm-modal #paymentModalPopup [title]="title" imageUrl="assets/images/material-error.svg"
  [acceptText]="acceptText" titleClass="" [showReject]="false" [showCloseIcon]="false"
  (onAcceptClicked)="onAcceptClicked()" (onClose)="onAcceptClicked()">
</app-confirm-modal>
<app-payment-v2-popup [description]="desc" [paymentErrorModal]="paymentNewModalPopup" [title]="title" 
[acceptText]="acceptText"  [showCloseIcon]="true" [showReject]="false" (onAcceptClicked)="onAcceptClicked()"
[imageUrl]="imageUrl" (onClose)="onAcceptClicked()">