import { Booking } from '@idp-education/ors-test-taker-bff-client-v1';
import { createAction, props, Action } from '@ngrx/store';
import { DateTime } from 'luxon';

const SET_STT = 'setSpeakingTestTime';
const SET_SCS = 'SetCurrentStep';
const SET_SCSN = 'SetCurrentStepName';
const SET_SPV = 'SetProgressValue';
const SET_STS = 'SetTotalStep';
const SET_LRW = 'SetLRWTestTime';
const CLR_LRW = 'clearLRWTestTime';
const SET_LPU = 'SetLatestPageUrl';
const SET_RFG = 'SetRequestForGenerateBooking';
const SET_IPB = 'SetIsPayedBooking';
const RESET = 'ResetState';
const CLEAR = 'ClearState';
const SET_PRODUCT_TYPE = 'SetProductType';
const SET_SPEAKING_REDIRECT_URL = 'SetSpeakingRedirectUrl';
const SET_LRW_REDIRECT_URL = 'SetLRWRedirectUrl';
const SET_TEST_LOCAL_TIMEZONE = 'SetTestLocalTimeZone';
const SET_NON_IOL_PRODUCT_ID = 'SetNonIOLProductId';
const SET_NON_IOL_LOCATION_ID = 'nonIOLLocationId';
const SET_REFERRAL_PARTNER_ID = 'SerReferralPartnerId';
const SET_IS_NEW_PAYMENT_JOURNEY = 'SetIsNewPaymentJourney';
const SET_IS_REGISTERED = 'setIsRegistered';


export type ISpeakingTime = {
    from: DateTime,
    to: DateTime,
    speaking?: Booking,
};
export type ILRWTime = {
    from: DateTime,
    to: DateTime,
    lrw?: Booking,
};
export const setSpeakingTestTime = createAction(SET_STT, props<ISpeakingTime>());

export const setCurrentStep = createAction(SET_SCS, props<{ currentStep: number }>());

export const setCurrentStepName = createAction(SET_SCSN, props<{ currentStepName: string }>());

export const setProgressValue = createAction(SET_SPV, props<{ progressValue: number }>());

export const setTotalStep = createAction(SET_STS, props<{ totalSteps: number }>());

export const SetLRWTestTime = createAction(SET_LRW, props<ISpeakingTime>());

export const clearLRWTestTime = createAction(CLR_LRW);

export const SetLatestPageUrl = createAction(SET_LPU, props<{ latestPage: string }>());

export const SetRequestForGenerateBooking = createAction(SET_RFG, props<{ requestForGenerateBooking: boolean }>());

export const SetIsPayedBooking = createAction(SET_IPB, props<{ isPayed: boolean }>());

export const ResetState = createAction(RESET);

export const SetProductType = createAction(SET_PRODUCT_TYPE, props<{ isNotIOLProduct: boolean }>());

export const SetSpeakingRedirectUrl = createAction(SET_SPEAKING_REDIRECT_URL, props<{ speakingUrl: string }>());

export const SetLRWRedirectUrl = createAction(SET_LRW_REDIRECT_URL, props<{ lrwUrl: string }>());

export const SetTestLocalTimeZone = createAction(SET_TEST_LOCAL_TIMEZONE, props<{ testLocalTimezone: string }>());

export const SetNonIOLProductId = createAction(SET_NON_IOL_PRODUCT_ID, props<{ nonIOLProductId: string }>());

export const SetNonIOLLocationId = createAction(SET_NON_IOL_LOCATION_ID, props<{ nonIOLLocationId: string }>());

export const SetReferralPartnerId = createAction(SET_REFERRAL_PARTNER_ID, props<{ referralPartnerId: string }>());

export const SetIsNewPaymentJourney = createAction(SET_IS_NEW_PAYMENT_JOURNEY, props<{ isNewPaymentJourney: string }>());

export const SetIsRegistered = createAction(SET_IS_REGISTERED);

export const clearState = createAction(CLEAR);


export const SetUnder18User = createAction('[Confirmation Page] setUnder18User', props<{ isUnder18: boolean }>());

export const SetConsentAccepted = createAction('[Confirmation Page] setConsentAccepted', props<{ isAccepted: boolean }>());


