import { Validators, ValidatorFn } from '@angular/forms';
import { UserProfileTitle } from '@idp-education/ors-test-taker-bff-client-v1';
import { birthdateLengthValidator } from 'shared/validators/custom-validators';
import { DATE_REGEXP, EMAIL_REGEXP, IDENTITYNO_REGEXP, PASSWORD_REGEXP } from 'src/app/shared/sharedRegex';
export interface IUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    birthDate: string;
    pass: string;
    confirmPass: string;
    communicationsCheckbox: boolean;
    termAndCondition2: boolean;
}
export class User {
    public firstName: string;

    public lastName: string;

    public emailAddress: string;

    public mobileNumber: any;

    public nationalityId: string;

    public sub: string;

    public title: UserProfileTitle;

    public sex: string;

    public birthDate: string;

    public userProfileId: string;

    public pass: string;

    public confirmPass: string;

    public termAndCondition2 = false;

    public termAndCondition1: boolean;

    public inactive = false;

    public communicationsCheckbox = false;
    public static fieldNamesAsArray() {
        return [
            'id',
            'lastName',
            'firstName',
            'email',
            'mobileNumber',
            'birthDate',
            'pass',
            'confirmPass',
            'termAndCondition1',
            'termAndCondition2',
            'communicationsCheckbox'
        ];
    }

    public copyTo(props: IUser) {
        this.firstName = props.firstName;
        this.lastName = props.lastName;
        this.emailAddress = props.email;
        this.mobileNumber = props.mobileNumber;
        this.birthDate = props.birthDate;
        this.pass = props.pass;
        this.confirmPass = props.confirmPass;
        this.termAndCondition2 = props.termAndCondition2;
        this.communicationsCheckbox = props.communicationsCheckbox;
    }
    public firstNameValidations() {
        return [
            Validators.minLength(2),
            Validators.maxLength(100)
        ];
    }
    public lastNameVaidators() {
        return [
            Validators.minLength(2),
            Validators.maxLength(100)
        ];
    }
    public emailValidators(): ValidatorFn[] {
        return [
            Validators.pattern(EMAIL_REGEXP),
            Validators.required
        ];
    }
    public birthDateValidators() {
        return [
            Validators.pattern(DATE_REGEXP),
            birthdateLengthValidator,
            Validators.required,
        ];
    }
    public mobileNumberValidators() {
        return [
            Validators.required,
        ];
    }
    public passValidators() {
        return [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(new RegExp(PASSWORD_REGEXP))
        ];
    }
    public termAndCondition1Validator() {
        return [Validators.required];
    }
    public termAndCondition2Validator() {
        return [Validators.nullValidator];
    }
    public identityNoValidators() {
        return [Validators.required, Validators.pattern(IDENTITYNO_REGEXP), Validators.maxLength(20)];
    }
    getValidatorFields(fieldName: string): ValidatorFn {
        let validators: any[];
        switch (fieldName) {
            case 'firstName':
                validators = this.firstNameValidations();
                break;
            case 'lastName':
                validators = this.lastNameVaidators();
                break;
            case 'email':
                validators = this.emailValidators();
                break;
            case 'mobileNumber':
                validators = this.mobileNumberValidators();
                break;
            case 'birthDate':
                validators = this.birthDateValidators();
                break;
            case 'pass':
                validators = this.passValidators();
                break;
            case 'termAndCondition1':
                validators = this.termAndCondition1Validator();
                break;
            case 'termAndCondition2':
                validators = this.termAndCondition2Validator();
                break;
            case 'identityNo':
                validators = this.identityNoValidators();
                break;
            default:
                validators = [Validators.nullValidator];
                break;
        }
        return Validators.compose(validators);
    }
}
