import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';

@Component({
  selector: 'app-terms-and-condition-ukvi-osr',
  templateUrl: './terms-and-condition-ukvi-osr.component.html',
  styleUrls: ['./terms-and-condition-ukvi-osr.component.scss']
})
export class TermsAndConditionUkviOsrComponent {
  testCentreName: string;
  hrefValues = {
    cxUrl: generateUtmUrl(UtmLinkKeys.CxUrl),
    legalNotice: generateUtmUrl(UtmLinkKeys.LegalNotice),
    legalNoticeAusUrl: generateUtmUrl(UtmLinkKeys.LegalNoticeAusUrl)
  }
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.testCentreName = params['tcName'];
    });
  }
}
