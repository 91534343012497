import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyTestsRoutingModule } from './my-tests-routing.module';
import { MyTestsComponent } from './my-tests.component';
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { NotifyInstitutionsComponent } from './notify-institutions/notify-institutions.component';
import { StatusIconsComponent } from './status-icons/status-icons.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { SearchInstitutionComponent } from './search-institution/search-institution.component';
import { SelectInstitutionComponent } from './select-Institution/select-Institution.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { EditNotifyInstitutionsComponent } from './edit-notify-institutions/edit-notify-institutions.component';
import { EORManagementComponent } from './EOR/eor-management/eor-management.component';
import { EorRequestFormComponent } from './EOR/eor-request-form/eor-request-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EorViewFormComponent } from './EOR/eor-view-form/eor-view-form.component';
import { SsrSearchInstitutionComponent } from './SSR/ssr-search-institution/ssr-search-institution.component';
import { SsrTestFormatComponent } from './SSR/ssr-test-format/ssr-test-format.component';
import { SsrTestLocationComponent } from './SSR/ssr-test-location/ssr-test-location.component';
import { SharedStateService } from './shared-state.service';
import { SsrTestDateComponent } from './SSR/ssr-test-date/ssr-test-date.component';
import { SsrCriteriaSummaryComponent } from './SSR/ssr-test-result/ssr-criteria-summary/ssr-criteria-summary.component';
import { SsrTestResultComponent } from './SSR/ssr-test-result/ssr-test-result.component';
import { SsrListItemComponent } from './SSR/ssr-test-result/ssr-list-item/ssr-list-item.component';
import {
  SsrExpectedResultComponent
} from './SSR/ssr-test-result/ssr-list-item/ssr-expected-result/ssr-expected-result.component';
import {
  SsrSeatAvailabilityComponent
} from './SSR/ssr-test-result/ssr-list-item/ssr-seat-availability/ssr-seat-availability.component';
import { SsrTestFeeComponent } from './SSR/ssr-test-result/ssr-list-item/ssr-test-fee/ssr-test-fee.component';
import { SsrPagingComponent } from './SSR/ssr-test-result/ssr-paging/ssr-paging.component';
import { SsrPaymentConfirmationComponent } from './SSR/ssr-payment-confirmation/ssr-payment-confirmation.component';
import { SsrBookingConfirmationComponent } from './SSR/ssr-booking-confirmation/ssr-booking-confirmation.component';
import {
  SsrListItemTestFormatComponent
} from './SSR/ssr-test-result/ssr-list-item/ssr-list-item-test-format/ssr-list-item-test-format.component';
import { SsrTestTypeComponent } from './SSR/ssr-test-result/ssr-list-item/ssr-test-type/ssr-test-type.component';
import { SsrPaymentComponent } from './SSR/ssr-payment/ssr-payment.component';
import { UpdateApplicationDetailsComponent } from './update-application-details/update-application-details.component';
import { SsrInstructionsDisplayComponent } from './SSR/ssr-instructions-display/ssr-instructions-display.component';

@NgModule({
  declarations: [
    MyTestsComponent,
    NotifyInstitutionsComponent,
    StatusIconsComponent,
    InlineEditComponent,
    SearchInstitutionComponent,
    SelectInstitutionComponent,
    EditNotifyInstitutionsComponent,
    EORManagementComponent,
    EorRequestFormComponent,
    EorViewFormComponent,
    SsrSearchInstitutionComponent,
    SsrTestFormatComponent,
    SsrTestLocationComponent,
    SsrTestDateComponent,
    SsrTestResultComponent,
    SsrListItemComponent,
    SsrExpectedResultComponent,
    SsrSeatAvailabilityComponent,
    SsrTestFeeComponent,
    SsrPagingComponent,
    SsrCriteriaSummaryComponent,
    SsrPaymentConfirmationComponent,
    SsrListItemTestFormatComponent,
    SsrTestTypeComponent,
    SsrPaymentComponent,
    SsrBookingConfirmationComponent,
    UpdateApplicationDetailsComponent,
    SsrInstructionsDisplayComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MyTestsRoutingModule,
    FeatureToggleModule,
    ReactiveFormsModule
  ],
  exports: [
    SearchInstitutionComponent,
    SelectInstitutionComponent,
  ],
  providers: [
    SharedStateService,
    provideNgxMask()
  ],
})
export class MyTestsModule { }
