import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { NAME_REGEXP } from 'src/app/shared/sharedRegex';
import { getAutomationTestMobileFieldToggle } from '../../../utils/automation-test-toggle';
import { DobDateCheckerHelper } from 'shared/helper/dob-date-checker.helper';

@Component({
  selector: 'app-signup-detail-form',
  templateUrl: './signup-detail-form.component.html',
  styleUrls: ['./signup-detail-form.component.scss']
})
export class SignupDetailFormComponent implements OnInit {
  isDisableEmail = false;
  @Input() form = null;
  @Input() set disableEmail(value: boolean) {
    this.isDisableEmail = value;
    if (this.isDisableEmail) {
       this.disableFields('email');
    }
  }
  @Input() set disableFirstName(value: boolean) {
    if(value) {
      this.disableFields('firstName');
    }
  }
  @Input() set disableFamilyName(value: boolean) {
    if(value) {
      this.disableFields('lastName');
    }
  }
  @Input() set disableDateOfBirth(value: boolean) {
    if(value) {
      this.disableFields('birthDate');
    }
  }
  @Input() reverseMobileFieldOrder = false;
  @Input() showTitle = false;
  @Input() selectedcountryISO = '';
  email: string;

  customMessages = {
    email: {
      pattern: () => $localize`Valid email address required`,
    },
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`,
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    },
  };
  BirthDateTooltip = 'Your date of birth';
  under16YearsOld = false;

  constructor(private dobDateCheckerHelper: DobDateCheckerHelper) {

  }
  ngOnInit(): void {
    this.email = localStorage.getItem('emailID');
    if (this.form && this.email !== '') {
    this.form.get('email').setValue(this.email);
    if (this.isDisableEmail) {
    this.disableFields('email');
    }
    }
  }

  onChangeBirthDate() {
    this.under16YearsOld = this.dobDateCheckerHelper.birthDateChanged(this.form);
  }

  getBirthDateTooltip() {
    return this.dobDateCheckerHelper.getTooltip(this.under16YearsOld);
  }
  onlyAlphaNumeric(event) {
    const eventData = event.clipboardData ? event.clipboardData : event.dataTransfer;
    const pastedData = eventData.getData('Text');
    if (!NAME_REGEXP.test(pastedData)) {
      event.preventDefault();
    } else {
      return;
    }
  }
  // To retain the emailID on page refresh
  @HostListener('window:beforeunload')
  setLocalStorage() {
    localStorage.setItem('emailID', this.email);
  }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }
  disableFields(formControlName) {
    this.form?.get(formControlName).disable();
  }
}
