<div class="container-fluid">
    <div class="">
      <div class="flexbox header-box mb-3">
        <div class="row d-flex align-items-center ">
          <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3 col" (click)="backToRo()">
            <img src="/assets/images/back-button-ssr.svg" alt="back icon">
          </button>
          <h2 class="underlined-title col text-nowrap">Test Format</h2>
        </div>
      </div>
      <hr>
      <div class="card row">
        <div class="row cardTitle d-flex">
            <img src="/assets/images/laptop.svg" alt="" class="img-content">
            <h3 class="subTitle">IELTS on computer</h3>
          </div>
          <div class="row margin-align" i18n>Complete the Listening, Reading and Writing tasks on a computer in a test centre.
            The Speaking will be face-to-face with an examiner.</div>
      </div>
    </div>
    <div class="container-footer">
      <app-two-button [acceptText]="'Continue'" [rejectText]="'Cancel'" [inCenter]="false" [enableAcceptClick]="true"
        [showReject]="true" (rejectClick)="onCancelClick.emit()" (acceptClick)="onssrTestLocationClick.emit()">
      </app-two-button>
    </div>
  </div>

