import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  GetTestLocationIdentificationTypesItem,
  IdentificationType,
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import {
  setCurrentStep,
  setCurrentStepName,
  setProgressValue,
  setIsAchievedLastStep,
  setSelectedIdType,
  setDetail,
  setImageUrl,
} from '../store/onboarding.actions';
import { IState } from '../store/onboarding.reducer';

@Component({
  selector: 'app-identity-selection',
  templateUrl: './identity-selection.component.html',
  styleUrls: ['./identity-selection.component.scss'],
})
export class IdentitySelectionComponent implements OnInit {
  idTypes: GetTestLocationIdentificationTypesItem[] = [];
  selectedId: IdentificationType;
  state: IState;
  storeSub: Subscription;

  constructor(
    private apiService: ApiService,
    private store: Store<{ onboardingStore }>,
    private titleService: Title,
    private router: Router,
    private loadingService: LoadingService,
    private metaTagService: Meta
  ) {
    this.storeSub = this.store
      .select((appState) => appState.onboardingStore)
      .subscribe((e) => {
        this.state = e;
        this.idTypes = this.state?.identificationType || [];
      });
    this.titleService.setTitle('Create your profile | IDP IELTS');
    this.store.dispatch(setCurrentStep({ currentStep: 3 }));
    this.store.dispatch(
      setCurrentStepName({ currentStepName: 'Identity Document' })
    );
    this.store.dispatch(setProgressValue({ progressValue: 48 }));
    this.store.dispatch(setIsAchievedLastStep({ isAchievedLastStep: false }));
    this.getTestLocationIdentificationTypes();
  }

  ngOnInit(): void {
    this.metaTagService.addTags([
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    ]);
    if (this.state?.isAchievedLastStep) {
      this.router.navigate(['/onboarding/review-profile']);
    }
  }

  onIdTypeSelect(idType: IdentificationType) {
    this.selectedId = idType;
    this.store.dispatch(setSelectedIdType({ selectedIdType: this.selectedId }));
  }

  onNextClick() {
    if (!this.selectedId) {
      return;
    }

    if (this.selectedId.code !== this.state?.detail?.identityTypeId) {
      this.store.dispatch(setImageUrl({ imageUrl: null }));
      this.store.dispatch(setDetail({ detail: null }));
    }
    this.router.navigate(['/onboarding/identity-document']);
  }

  getTestLocationIdentificationTypes() {
    if (this.state?.testLocationId) {
      this.loadingService.increaseLoadingCounter();
      this.apiService
        .getTestLocationIdentificationTypes(this.state.testLocationId)
        .subscribe((res) => {
          if (Array.isArray(res) && res.length) {
            this.onIdTypeSelect(this.state?.selectedIdType || this.idTypes[0]);
            this.loadingService.decreaseLoadingCounter();
          }
        });
    }
  }
}
