<app-account-layout [callToActions]="callToActions" [userName]="getUserProfileName$ | async">
    <div otherContent>
        <div class="last-score">
            <app-last-score *ngIf="hasResult" [results]="lastResult"
                [applicationId]="lastResult && lastResult.applicationId" (onDownloadResult)="onDownloadResult($event)">
            </app-last-score>
        </div>
        <div class="upcoming-test">
            <app-upcoming-test i18n-title [title]="'Upcoming tests'" i18n-stTitle [stTitle]="'Speaking test'"
                i18n-lrwTitle [lrwTitle]="'Listening, Reading and Writing test'" i18n-lrwTitleMd [showGetDetail]="false"
                [lrwTitleMd]="'Listening, Reading & Writing test'" [tests]="upComingTests" (onRefetchTests)="reload()">
                <div class="no-booking" *ngIf="doesApplicationExist === false">
                    <app-no-booking></app-no-booking>
                </div>
            </app-upcoming-test>
        </div>
        <div *ngIf="askitems.length > 0" class="ask-ielts">
            <app-expandable-box [items]="askitems" i18n-title [title]="'Ask IELTS'"></app-expandable-box>
        </div>
        <h3 class="result-disclaimer" i18n>
            Your test centre or the IELTS partners will not accept any responsibility in
            the event that your provisional result fail to display here, whether due to
            technical fault or administrative procedures.
        </h3>
    </div>
</app-account-layout>