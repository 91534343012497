import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import * as worker from 'worker-timers';
import { Duration } from 'luxon';
import { isNumber, isString } from 'lodash';
import { CalcRemainingTimeService } from '../../../services/calcRemaining-time.service';

@Component({
  selector: 'app-upcoming-test-incomplete-action',
  templateUrl: './upcoming-test-incomplete-action.component.html',
  styleUrls: ['./upcoming-test-incomplete-action.component.scss']
})
export class UpcomingTestIncompleteActionComponent implements OnInit, OnDestroy {
  @Input() currentTime: string = null;
  @Input() targetTime: string;
  @Input() targetUnit: string;
  @Input() isOfflineIncompleteProfileComplete: boolean;
  showTime = null;
  @ViewChild('timer') timer: ElementRef;
  @ViewChild('button') button: ElementRef;
  intervalTimer;

  constructor(
    private renderer: Renderer2,
    private calcRemainingTime: CalcRemainingTimeService
  ) {
  }

  ngOnDestroy(): void {
    if (this.intervalTimer) {
      worker.clearInterval(this.intervalTimer);
      this.intervalTimer = null;
    }
  }

  ngOnInit(): void {
    let distance = this.getDuration(this.targetTime);
    this.intervalTimer = worker.setInterval(() => {
      this.showTime = this.calcRemainingTime.getTime(distance.as('seconds'));
      this.addAttribute(distance);
      distance = distance.minus({ second: 1 });
    }, 1000);
  }

  addAttribute(distance: Duration): void {
    if (distance.as('seconds') >= 1) {
      this.renderer.removeAttribute(this.button.nativeElement, 'disabled');
    } else {
      this.renderer.setAttribute(this.button.nativeElement, 'disabled', 'disabled');
    }
  }

  getDuration(date: number | string): Duration {
    if (isNumber(date) || (isString(date) && isNumber(parseInt(date.toString())))) {
      return Duration.fromObject({
        [this.targetUnit]: parseInt(date.toString())
      });
    } else {
      console.error('target time is not valid');
    }
  }

}
