import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-offline-wizard',
  template: `
      <div>
        <app-offline-request-content (onRequestComplete)="onSuccessPayment.emit()" [userName]="userName"
          [receiptNumber]="receiptNumber"
          [applicationPaymentId]="applicationPaymentId" [customButtonLabel]="stepTwoCustomButtonLabel" [showBackButton]="showBackButton">
        </app-offline-request-content>
      </div>
  `,
  styleUrls: ['./offline-wizard.component.scss']
})
export class OfflineWizardComponent implements OnInit {

  @Input()
  userName = '';
  @Input()
  receiptNumber = '';
  @Input()
  applicationPaymentId = '';
  @Input()
  stepTwoCustomButtonLabel = '';
  @Input() showBackButton = true;
  @Output() onSuccessPayment = new EventEmitter<void>();

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    timer(0).pipe(first()).subscribe(() => this.cdr.detectChanges());
  }

}
