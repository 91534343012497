<div class="form-group d-flex flex-grow-1" [formGroup]="form"
     [ngClass]="inOneLine && 'd-flex justify-content-md-start align-items-md-center justify-content-center align-items-start flex-column flex-md-row'">
  <div class="d-flex input-cn d-flex flex-grow-1">
    <div class="input-group d-flex flex-grow-1" [class.show-clear-button]="showClearButton">
      <div *ngIf="tooltip" class="tooltip-box" #tooltipBox>{{ tooltip }}</div>
      <input [ngbTooltip]="placeHolder" container="body"
             [type]="type ? type : 'text'" (change)="onChange($event)" (blur)="required ? setNudge() : ''"
             [placeholder]="placeHolder" autocomplete="off" i18n-placeholder class="form-control custom-input-control" [id]="id"
             [formControlName]="fcn" [mask]="mask ? mask : ''" tabindex [dropSpecialCharacters]="dropSpecialCharacters"
             #inputContainer [title]="form && form.get(fcn).value ?
        form && form.get(fcn).value : placeHolder " [attr.disabled]="inputDisabled ? 'true' : null"
             [maxlength]="maxLength"/>
      <button tabindex="-1" class="btn" type="button" id="clear-button" *ngIf="showClearButton" (click)="onClear()">
        <span class="visually-hidden">clear</span>
      </button>
      <button class="input-group-append btn btn-primary" type="submit" id="search-button">
        <i class="fa fa-search" aria-hidden="true"></i><span class="visually-hidden">Search</span>
      </button>
    </div>
  </div>
</div>
