import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel
} from '@angular/forms';
import { v4 } from 'uuid';

@Component({
  selector: 'app-yes-no-question',
  templateUrl: './yes-no-question.component.html',
  styleUrls: ['./yes-no-question.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: YesNoQuestionComponent,
      multi: true,
    }
  ]
})
export class YesNoQuestionComponent implements OnInit, ControlValueAccessor {
  @Input() question: string;

  @Input() control!: UntypedFormControl;
  @Input() inputDisabled: boolean;
  formControlName: FormControlName;
  public readonly id = v4();

  constructor(
    @Inject(Injector) private injector: Injector,
  ) { }

  onChange: any = () => { /* empty */ };
  onTouch: any = () => { /* empty */ };
  writeValue(obj: any): void {
    if (this.control) {
      this.control.markAsDirty();
      this.control.markAllAsTouched();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void { /* empty */ }

  ngOnInit(): void {
    setTimeout(() => {
      this.setComponentControl();
    }, 0);
  }
  private setComponentControl(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case NgModel:
        throw new Error('Not supported \'NgModel\'');
      case FormControlName: {
        this.formControlName = this.injector.get(FormControlName);
        this.control = this.injector.get(FormGroupDirective).getControl(this.formControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective)?.form as UntypedFormControl;
        break;
      }
    }
  }
}
