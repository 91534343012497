import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IModalButtons } from '../../models/components/confirm-modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() title = '';
  @Input() title2 = '';
  @Input() subTitle = '';
  @Input() imageUrl = '';
  @Input() acceptClass = '';
  @Input() acceptText = 'Yes';
  @Input() rejectClass = '';
  @Input() rejectText = 'No';
  @Input() showReject = true;
  @Input() textClass = '';
  @Input() disableAcceptButton = false;
  @Input() defaultActiveButton: IModalButtons = 'accept';
  @Input() imageClass = '';
  @Input() titleClass = '';
  @Input() modalClass = '';
  @Input() showCloseIcon = true;
  @Input() titleCentre = true;
  @Input() iconCentre = false;
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<string>();
  @Output() onAcceptClicked = new EventEmitter<void>();
  @Output() onRejectClicked = new EventEmitter<void>();

  @ViewChild('confirmationModal') confirmationModal: ElementRef;
  @ViewChild('ab', { static: false }) acceptButton: ElementRef;

  private closeResult;
  activeButton: IModalButtons = this.defaultActiveButton;
  private modalElement: NgbModalRef;

  private _showAccept = true;
  public get showAccept(): boolean {
    return this._showAccept;
  }

  constructor(
    private modalService: NgbModal,
    private router: Router) {
  }

  open(settings: NgbModalOptions = {}) {
    this.modalElement = this.modalService.open(this.confirmationModal, { ariaLabelledBy: 'modal-basic-title', ...settings });
    this.modalElement.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.onClose.emit(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.onClose.emit(this.closeResult);
    });
    this.onOpen.emit();
    return this.modalElement;
  }

  get modal() {
    return this.modalElement;
  }
  get modelService() {
    return this.modalService;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onAcceptClick() {
    this.onAcceptClicked.emit();
    this.closeModal('accept');
  }
  onHover(button: IModalButtons) {
    this.activeButton = button;
  }
  onRejectClick() {
    this.onRejectClicked.emit();
    this.closeModal('reject');
  }

  closeModal(result) {
    this.modalElement?.close(result);
  }
  changeAcceptButtonVisibility(mode: boolean) {
    this._showAccept = mode;
  }
}
