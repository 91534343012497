import { Component, ElementRef, Input, Renderer2, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { FormValidatorService } from 'shared/services/form-validator.service';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnChanges, OnInit {
  countryISOcode: string;
  @Input() fcn = null;
  @Input() form: UntypedFormGroup = null;
  @Input() placeHolder = null;
  @Input() required = false;
  @Input() showError = false;
  @Input() errorCMessage = null;
  @Input() tooltip = null;
  @Input() type = null;
  @Input() mask = null;
  @Input() dropSpecialCharacters = false;
  @Input() enablePlaceholder = true;
  @Input() showPasswordIcon = false;
  @Input() showRequiredError = true;
  @Input() inputDisabled = false;
  @Input() AcceptSpace = true;
  @Input() maxLength = null;
  @Input() firstErrorOnly = false;
  @Input() inOneLine = false;
  @Input() showTick = true;
  @Input() validShowTick = false;
  @Input() showNextButton = false;
  @Input() customMessage = {};
  @Input() preventPaste = false;
  // tslint:disable-next-line: max-line-length
  @Input() set selectedCountryISO(code: string) {
    if (code) {
      const countryIso = Object.values(CountryISO).find(country => country === code.toLocaleLowerCase());
      if (countryIso) {
        this.countryISOcode = countryIso;
      }
    
    } else {
    this.countryISOcode = CountryISO.Australia;
    }
  }
  // added optional country ISO since there is an unresolved issue separateDialCode property of "ngx-intl-tel-input" on github.
  @Input() nextToLabelToolTip?: string | TemplateRef<any>;
  @Input() nextToLabelToolTipClass?: string;
  CountryISO = CountryISO;
  // tslint:disable-next-line: no-output-native
  @Output() changeData: EventEmitter<string> = new EventEmitter();
  @Output() nextButtonClick: EventEmitter<null> = new EventEmitter();
  @Input() title = null;
  @ViewChild('inputContainer') inputContainer: ElementRef;
  @ViewChild('tooltipBox') tooltipBox: ElementRef;
  @ViewChild('passwordIconBox') passwordIconBox: ElementRef;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  constructor(
    private renderer: Renderer2,
    private formValidatorService: FormValidatorService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.showValidationTick((changes.form.currentValue as UntypedFormGroup)?.get(this.fcn)?.value);
    }
  }

  ngOnInit(): void {
    if(this.form?.get(this.fcn).disabled) {
      this.showValidationTick(this.form.get(this.fcn)?.value);
    }
  }
  checkAcceptSpace(): boolean {
    if (this.AcceptSpace) {
      return true;
    } else {
      const isWhitespace = this.form.get(this.fcn).value.trim().length === 0;
      if (isWhitespace) {
        return false;
      } else {
        this.form.get(this.fcn).setValue(this.form.get(this.fcn).value.trim());
        return true;
      }
    }
  }
  togglePassword() {
    if (this.passwordIconBox.nativeElement.classList.contains('hide')) {
      this.renderer.removeClass(this.passwordIconBox.nativeElement, 'hide');
      this.renderer.addClass(this.passwordIconBox.nativeElement, 'show');
      this.type = 'text';
    } else {
      this.renderer.removeClass(this.passwordIconBox.nativeElement, 'show');
      this.renderer.addClass(this.passwordIconBox.nativeElement, 'hide');
      this.type = 'password';
    }
  }
  showTooltip() {
    this.renderer.addClass(this.tooltipBox.nativeElement, 'showTooltip');
  }
  hideTooltip() {
    this.renderer.removeClass(this.tooltipBox.nativeElement, 'showTooltip');
  }
  onChange(event: any) {
    try {
      const value = event.currentTarget.value;
      this.changeData.emit(value ? value : '');
    } catch (error) {
      this.changeData.emit('');
    }
  }
  setNudge() {
    this.formValidatorService.setNudge(this.renderer, this.inputContainer.nativeElement, this.form, this.fcn);
  }

  showValidationTick(value) {
    if ((typeof value === 'object' && value?.number?.length > 0) || value?.length > 0 || this.form?.get(this.fcn)?.disabled) {
      this.validShowTick = true;
    } else {
      this.validShowTick = false;
    }
  }
  hideValidationTick() {
    this.validShowTick = false;
  }
  onlyNumbers(event) {
    const eventData = event.clipboardData ? event.clipboardData : event.dataTransfer;
    const pastedData = eventData.getData('Text');
    if (isNaN(pastedData)) {
      event.preventDefault();
    } else {
      return;
    }
  }
  isPreventPaste(event) {
    if (this.preventPaste) {
      event.preventDefault();
    } else {
      return event.clipboardData.getData('text');
    }
  }
}
