import { Directive, Input, TemplateRef } from '@angular/core';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'ng-template[paymentPanel]',

})
export class PanelDirective {
  @Input() paymentPanel: IPaymentMethods;
  constructor(public ref: TemplateRef<any>) { }
}
