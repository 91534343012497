import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { SharedStateService } from './shared-state.service';
import { Store } from '@ngrx/store';
import { SetLatestPageUrl } from '../booking/store/booking.actions';
@Injectable({
  providedIn: 'root'
})
export class CanActivateService  {

  constructor(private router: Router,
              private store: Store) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          observer.next(true);
        })
        .catch(() => {
          this.store.dispatch(SetLatestPageUrl({latestPage: '/onboarding'}));
          observer.next(this.router.parseUrl('/account/signin'));
        })
        .finally(() => {
          observer.complete();
        });
    });
  }
}


@Injectable({
  providedIn: 'root'
})
export class HasImageService  {
  constructor(private shareState: SharedStateService,
              private router: Router,
              private store: Store<{onboardingStore}>) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.store.select(appState => appState.onboardingStore).pipe(map(s => {
        if (s.imageUrl) {
        return true;
      } else {
        return this.router.parseUrl('/onboarding');
      }
    }
    ), first());
  }
}

@Injectable({
  providedIn: 'root'
})
export class HaveLatestPage  {
  constructor(private store: Store<{ onboardingStore }>,
              private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return new Observable<boolean | UrlTree>((observer) => {
          this.store.select(st => st.onboardingStore.currentStep).subscribe((payload) => {
              if (payload != null) {
                  observer.next(true);
              } else {
                  observer.next(this.router.parseUrl('/onboarding'));
              }

          });
      });
  }
}
