import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-template',
  template: '<ng-content *ngIf="visible"></ng-content>',
  styleUrls: ['./step-template.component.scss']
})
export class StepTemplateComponent {
  @Input() visible = false;

}
