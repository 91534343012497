<app-form-wrapper [hasBackButton]="false">
  <div title>Terms and conditions</div>
  <div subTitle></div>
  <div content class="main-content">
    <div class="terms-and-condition-box">
      <div class="row">
        <div class="col-12 page-header">
          <h2>IELTS on Computer UKVI</h2>
        </div>
      </div>
      <div class="bg-theme has-border-top has-border-bottom mb-4 row p-2">
        <div class="col-md-10 col-lg-8 col-print-10 center-block">
          <div class="textalign well">
            <fieldset>
              <legend class="visually-hidden">Terms and conditions ukvi hongkong</legend>
              <ol>
                <li>
                  <p>General Acknowledgements</p>
                  <p>I acknowledge that…</p>
                  <ol>
                    <li>
                      The IELTS test is jointly owned by British Council, IELTS
                      Australia Pty Ltd (ABN 84 008 664 766), which is wholly
                      owned by IDP Education Ltd (‘IDP: IELTS Australia’) and
                      Cambridge Assessment English (part of the University of
                      Cambridge), collectively referred to as the IELTS TEST
                      PARTNERS or IELTS PARTNERS.
                    </li>
                    <li>
                      I will abide by these terms and conditions for the IELTS
                      On Computer for UKVI test.
                    </li>
                    <li>
                      I understand that there may be local terms and conditions
                      that I must comply with and that the test centre will
                      provide details of these on request.
                    </li>
                    <li>
                      I certify that the information in my application is
                      complete, true, and accurate.
                    </li>
                    <li>
                      I acknowledge that I am responsible for taking my test
                      well in advance of any submission deadlines to Recognising
                      Organisations. The IELTS PARTNERS cannot be held
                      responsible if the issuance of test taker results exceeds
                      the normal period given in these terms and conditions.
                    </li>
                    <li>
                      I have read and understand IELTS Partner privacy policy
                      and provisions at
                      <a
                        rel="noopener"
                        href="https://ielts.org/legal/privacy-policy"
                        target="_blank"
                      >
                        https://ielts.org/legal/privacy-policy
                      </a>
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Personal Information</p>
                  <p>I understand that…</p>
                  <ol>
                    <li>
                      It is necessary for my personal information to be
                      collected and processed in order for me to take the IELTS
                      test, to verify my identity and to receive my results.
                      This personal information is collected and securely stored
                      when I register for and when I take the IELTS test and is
                      used by the IELTS Test Partners for the purpose of the
                      IELTS test.
                    </li>
                    <li>
                      My personal information, including, without limitation,
                      test performance or score data and my test result may be
                      disclosed by the IELTS Test Partners to Recognising
                      Organisations to which I apply and to governments
                      (including visa processing authorities) for the purpose of
                      allowing these organisations to verify my test result.
                    </li>
                    <li>
                      My personal information may be processed in an anonymous
                      form by the IELTS Test Partners for statistical and
                      research purposes.
                    </li>
                    <li>
                      My photograph will be taken by the test centre on test day
                      and will appear on my Test Report Form. This photograph
                      will be provided to any Recognising Organisations or
                      government authorities to which I apply, to allow these
                      organisations to verify my test result.
                    </li>
                    <li>
                      I will be required to verify my identity on test day by
                      providing the identity document specified by the test
                      centre. I may also be requested to confirm my identity on
                      test day by having a finger scan taken. The finger scan is
                      taken as a Binary Large Object (BLOB), and no image of my
                      fingerprint will be held. This biometric data will be held
                      for 60 days after my test and will not be disclosed to any
                      entity except the IELTS Test Partners.
                    </li>
                    <li>
                      I will be asked to provide my finger scan. If I am under 18 years 
                      of age or I do not consent to a finger scan, I will be required 
                      to have my identity manually verified in line with the 
                      <a rel="noopener" href="https://www.idp.com/hongkong/pics/?lang=en"
                      target="_blank">Personal Information Collection Statement</a>
                    </li>
                    <li>
                      My Speaking test will be recorded: audio and/or video. In
                      the event the test does not record I will be required to
                      re-take the Speaking test. An observer may attend my
                      Speaking test as part of the examiner monitoring process.
                    </li>
                    <li>
                      I may be filmed for security and quality assurance
                      purposes on test day, before and during the test. The
                      video footage will be for held for 60 days after my test
                      and may be used for audit or investigation purposes. This
                      period may be shorter in line with local legislation in a
                      limited number of jurisdictions.
                    </li>
                    <li>
                      I can access further details on how the IELTS Test
                      Partners use my personal information (including how long
                      it is retained in different circumstances and how I can
                      exercise my rights) at
                      <a
                        rel="noopener"
                        href="https://www.ielts.org/policy/privacy-policy"
                        target="_blank"
                      >
                        www.ielts.org/policy/privacy-policy
                      </a>
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Cancellation, Refund and Transfers</p>
                  <ol>
                    <li>
                      Cancellations <br />
                      I can cancel my IELTS test booking at any time before
                      taking the test by notifying the test centre. Refund
                      policy and any other applicable charges depends upon when
                      I cancel and whether exceptional circumstances apply.
                      <ol>
                        <li>
                          Time to test date.
                          <ul>
                            <li>
                              More than 14 days before the test: <br />
                              If I cancel my IELTS test registration more than
                              14 days before the test I will receive a 75%
                              refund of the total test fee.
                            </li>
                            <li>
                              Within 14 days but more than two days before the
                              test: <br />
                              If I cancel my IELTS test registration within 14
                              days but more than two days before the test I will
                              receive a 50% refund of the total test fee.
                            </li>
                            <li>
                              Within two days of the test: <br />
                              If I cancel my IELTS test registration within two
                              days of the test I will receive a 25% refund of
                              the total test fee.
                            </li>
                            <li>
                              On the test day or after the test: <br />
                              If I cancel my IELTS test registration on the test
                              day or after the test no refund will be provided.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Test taker exceptions <br />
                          I may make a case for exceptional circumstances to my
                          test centre before the test and up to five days after
                          the test date if I do not attend the test. The test
                          centre will respond to me within seven working days of
                          receiving my case in writing. My test centre will
                          assess my case for exceptional circumstances. <br />
                          All cases for exceptional circumstances and supporting
                          evidence must be received by the test centre no later
                          than five days after the scheduled test date. If the
                          test centre approves my case, I will receive a full
                          refund less an administration fee of no more than 25%
                          of the test fee. <br />
                          If the test centre does not approve my case, then the
                          terms given in Section 3.1.1 will apply. <br />
                          <br />
                          Exceptional circumstances are defined as: <br />
                          <ul>
                            <li>
                              serious medical conditions which prevent me from
                              attending or performing normally on test day; such
                              conditions require supporting evidence of a
                              medical certificate from a qualified medical
                              practitioner.
                            </li>
                            <li>
                              evidence of bereavement, trauma or other forms of
                              significant hardship.
                            </li>
                            <li>military service.</li>
                          </ul>
                        </li>
                        <li>
                          Test centre exceptions <br />
                          Under certain circumstances outside test centre
                          control, the test centre may have to cancel my test.
                          These circumstances include, and are not limited to,
                          extreme weather conditions, natural disaster, civil
                          unrest and industrial action. <br />
                          In these circumstances, the test centre will provide
                          me with as much notice as possible and give me the
                          choice of either: <br />
                          <ul>
                            <li>a full refund, or</li>
                            <li>
                              a transfer to a future test date convenient to me.
                            </li>
                          </ul>
                          In cases where the test centre cancels a test under
                          circumstances within its control then in addition to a
                          full refund or transfer to another test date, I may be
                          eligible to apply for compensation for expenses
                          incurred. My eligibility will be assessed as a
                          reasonable claim or not by the test centre and any
                          compensation will be awarded depending on the
                          following conditions:
                          <ul>
                            <li>
                              The test centre will assess whether the decision
                              to cancel is within or outside its control.
                            </li>
                            <li>
                              Any compensation awarded will be limited to travel
                              and accommodation expenses only and which are:
                              <ul>
                                <li>
                                  directly incurred as a result of planning my
                                  attendance on test day
                                </li>
                                <li>evidenced by payment receipt(s)</li>
                                <li>
                                  evidenced that cancelled travel and/or
                                  accommodation charges are non-recoverable from
                                  vendor.
                                </li>
                                <li>
                                  benchmarked against lowest reasonable market
                                  rates for the expenses in question (this will
                                  be carried out by the test centre).
                                </li>
                              </ul>
                              If I travel further than the closest test venue to
                              my normal place of residence, then the test center
                              will not find my claim reasonable.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Transfers
                      <ol>
                        <li>
                          More than 14 days before the test: <br />
                          I may change my test date at any time more than 14
                          days before the test. <br />
                          I must select a test date, where available, within the
                          next three months from my original test date. If my
                          preferred test date is more than three months after my
                          original test date, then my transfer will be treated
                          as a cancellation. <br />
                          I can only transfer the same booking once. <br />
                          The test centre may charge an administration fee of up
                          to 25% of the total test fee. <br />
                        </li>
                        <li>
                          Within 14 days before the test: <br />
                          Any transfer requests within 14 days of the test will
                          be treated as a cancellation. <br />
                          Please refer to Section 3.1 (Cancellations) of this
                          policy document.
                        </li>
                        <li>
                          Notes <br />
                          The test centre is responsible for issuing refunds and
                          organising transfers. <br />
                          Under Section 3.1.2, the test
                          centre is responsible for decisions related to test
                          taker claims for exceptional circumstances. <br />
                          Under Section 3.1.3, the test centre is responsible
                          for decisions related to compensation, including
                          eligibility and any amounts awarded. <br />
                          If local consumer protection law in the country where
                          I have registered to take the IELTS test provides for
                          cancellation and transfer rights which are more
                          favourable to the test taker than the rights set out
                          above, then local consumer protection law will apply.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Chargeback Prohibition: <br />
                      I agree that I will not dispute or otherwise seek a
                      “chargeback” from the company whose credit card or other
                      method of payment I use to book and pay for the IELTS test
                      or otherwise attempt to reverse payment. If I do so, my
                      IELTS test and/or results may be cancelled, and the IELTS
                      Partners may, at their discretion, refuse to accept future
                      purchases made from accounts on which such chargebacks
                      have been made. The IELTS Partners may prohibit future
                      purchases from the account holder, and from any person who
                      booked an IELTS test and reversed payment.
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Test Integrity, Security, and Malpractice</p>
                  <p>I understand that…</p>
                  <ol>
                    <li>
                      I must attend all four test skills in order to receive a
                      test result and any exception to this must be approved in
                      advance by the test centre.
                    </li>
                    <li>
                      The IELTS Test Partners have a responsibility to all Test
                      takers and Recognising Organisations to ensure the highest
                      confidence in IELTS test results. The IELTS Test Partners
                      may therefore be required to withhold test results
                      temporarily or permanently, or to cancel test results
                      which have been issued, if they consider those results to
                      be unreliable for any reason.
                    </li>
                    <li>
                      My test result may not be issued within 5 days after the
                      test if any of the IELTS Test Partners consider it
                      necessary to review any matter associated with my test or
                      the administration of my test, including making enquiries
                      as to whether I, other test takers or other parties breach
                      test rules or regulations. I may be required to provide an
                      additional speaking sample for the purposes of assisting
                      any investigation before or after the test. In exceptional
                      circumstances I may be required to re-take one or more
                      IELTS skills.
                    </li>
                    <li>
                      If I am suspected of engaging in any form of malpractice
                      or do anything that might damage the integrity and
                      security of IELTS, I may not receive a test result, my
                      test fee will not be refunded, and I may be banned from
                      taking the IELTS test for a five-year period. If I am
                      subject to a ban and I register to take the test, I will
                      not receive a test result and I will not be entitled to a
                      refund. If a result is issued to me while I am subject to
                      a ban, this result will be cancelled, and I will not be
                      entitled to a refund.
                    </li>
                    <li>
                      Details of any proven or suspected malpractice may be
                      provided to Recognising Organisations, including
                      governments (including visa processing authorities) and
                      appropriate regulatory authorities, and centrally to the
                      IELTS Test Partners. In instances where malpractice has
                      been proven, I may be liable to prosecution.
                    </li>
                    <li>
                      The work I produce in the IELTS test remains the property
                      of the IELTS Test Partners and will not be released to me.
                      In cases of proven or suspected malpractice my work may be
                      provided to relevant authorities.
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Test Day Arrangements and Behaviour</p>
                  <p>
                    I understand and acknowledge that on the day of the test, I
                    must…
                  </p>
                  <ol>
                    <li>
                      Provide valid proof of my identity (passport or National
                      Identity Card) at registration. If in doubt, I should
                      contact the test centre in advance to confirm which type
                      of identity document is accepted. If I am taking the test
                      outside my own country, I must use a passport for identity
                      purposes.
                    </li>
                    <li>
                      Inform the centre of any changes to my identity document
                      before the test date. If I do not do this, I will not be
                      allowed to take the test and I will not be eligible for a
                      refund or transfer.
                    </li>
                    <li>
                      Bring the same valid identity document on test day as the
                      one I used to book my test. If I do not do this, I will
                      not be allowed to take the test and I will not be eligible
                      for a refund or transfer.
                    </li>
                    <li>
                      Arrive at the centre before the scheduled test start time.
                      If I arrive late, I will not be allowed to take the test
                      and will not be eligible for a refund or transfer.
                    </li>
                    <li>
                      Leave all personal belongings in the designated area. The
                      only items I may bring into the test room are my approved
                      identity document, standard pen(s), pencil(s) and
                      eraser(s), and a bottle of water (label-free). All
                      electronic devices and all watches must be left in the
                      belongings area and must be switched off. I may be
                      electronically scanned for devices at any time on the test
                      day. Any test takers who breach these conditions will not
                      receive an IELTS test result or be eligible for a refund
                      or transfer. While the centre will take all reasonable
                      measures to secure my items in the belongings area, they
                      cannot be held responsible for any loss.
                    </li>
                    <li>
                      Allow for my identity to be verified both at test booking
                      and on test day. This may include:
                      <ol>
                        <li>
                          Having my photograph taken. I will be required to
                          temporarily remove any covering from my face. Any test
                          takers who refuse to have a photograph taken will not
                          be permitted to sit the test and will not be entitled
                          to a refund. The photograph taken by the centre will
                          appear on my Test Report Form.
                        </li>
                        <li>Providing a sample of my signature.</li>
                        <li>
                          Having my finger scan taken to create a unique digital
                          Identifier stored as a binary large object.
                        </li>
                      </ol>
                      I understand that I should…
                    </li>
                    <li>
                      Follow the instructions given by test day personnel.
                    </li>
                    <li>
                      Tell the invigilator at once if I do not see the correct
                      test on my computer screen.
                    </li>
                    <li>
                      Raise my hand if I need to ask the invigilator something.
                      Test takers may not ask for, and will not be given, any
                      explanation of the test questions.
                    </li>
                    <li>
                      Attend all four skills of the test. If I do not attend all
                      four skills, I will not receive an IELTS test result.
                      Exceptions are possible but must be requested at time of
                      booking and written approval must be received from the
                      test centre.
                    </li>
                    <li>
                      Inform the invigilator on the day of the test if I believe
                      my performance is affected by ill health, or owing to the
                      way the test is delivered, or for any other reason. If I
                      have any issues, concerns or complaints relating to the
                      delivery of the test I can submit a complaint. The IELTS
                      Partners will address complaints in accordance with the
                      IELTS Partners Complaints Policy available on
                      <a
                        rel="noopener"
                        href="https://ielts.org/legal/complaints-procedure"
                        target="_blank"
                      >
                        https://ielts.org/legal/complaints-procedure
                      </a>
                    </li>
                    <li>
                      Leave all test materials in the test room at the end of
                      the test. The test materials include question papers,
                      Speaking booklets, answer sheets and rough paper. Any test
                      taker who attempts to remove test materials from the test
                      room will not receive an IELTS test result.
                    </li>
                    <li>
                      I undertake not to… <br />
                      <ol>
                        <li>
                          Talk to or disturb other test takers once the test has
                          started.
                        </li>
                        <li>
                          Lend anything to, or borrow anything from, another
                          test taker during the test.
                        </li>
                        <li>Eat or smoke in the test room.</li>
                        <li>
                          Leave the test room without the permission of the
                          invigilator.
                        </li>
                        <li>
                          Leave my seat until all test materials have been
                          collected and I have been told I can leave.
                        </li>
                        <li>
                          Engage in any form of malpractice which may damage the
                          integrity and security of the IELTS test. Malpractice
                          includes, but is not limited to attempting to cheat,
                          including using notes of any kind from any source;
                          helping another test taker to cheat; impersonating
                          another test taker or having another test taker
                          impersonate me; copying the work of another test
                          taker; disrupting the test in any way; reproducing any
                          part of the test in any medium; attempting to alter
                          the data on the Test Report Form.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Test Results</p>
                  <p>I understand that…</p>
                  <ol>
                    <li>
                      Results are issued by centres, usually within 5 days after
                      the test. The release of my results may be affected by
                      Public Holidays, in which case the results will usually be
                      released on the next working day after the holiday.
                    </li>
                    <li>
                      I will be able to access a downloadable and printable
                      version of my electronic Test Report Form from the online
                      booking platform I used to register for my test.
                    </li>
                    <li>
                      The test centre does not normally supply paper Test report
                      Forms. If a Recognising Organisation makes it a
                      requirement to receive a paper Test Report Form directly
                      from IELTS then I should submit a request to the test
                      centre where I took the test and provide evidence of this
                      requirement e.g. an email from the organisation or
                      relevant website reference. At their discretion, the test
                      centre will then decide whether to send a paper version of
                      the Test Report Form to the nominated recognising
                      organisation. The test centre may charge a reasonable fee
                      for this service to cover postage and administrative
                      costs.
                    </li>
                    <li>
                      The Test Report Form will be issued in my name as it
                      appears on the identity document, I used to book my test.
                      If I find my personal details are incorrect on the Test
                      Report Form, I will contact the test centre where I took
                      the test. I must provide to verify the revised details. If
                      I change my name after receiving my Test Report Form, the
                      original name will not be changed on the Test Report Form.
                    </li>
                    <li>
                      The IELTS Partners may not issue my result within 5 days
                      after the test should they decide it is necessary to
                      review any matter associated with my test or the
                      administration of my test. To assist any investigation, I
                      may be required to provide writing and speaking samples.
                      In exceptional circumstances, I may be required to retake
                      one or more IELTS skills.
                    </li>
                    <li>
                      My result may be cancelled after it has been issued if any
                      irregularity is identified. I may be required to re-take
                      one or more IELTS skills.
                    </li>
                    <li>
                      My result will be disclosed to the Recognising
                      Organisations I nominate at time of booking or to whom I
                      send my Test Report Form. This is done for the purpose of
                      allowing these organisations to verify my test result or
                      to carry out any enquiries in relation to suspected
                      malpractice.
                    </li>
                    <li>
                      If any of the data on the Test Report Form provided by me
                      or my agent to Recognising Organisations has been altered
                      in any way, that test result will be cancelled, and I may
                      be banned from taking any IELTS test variant for a
                      five-year period.
                    </li>
                    <li>
                      I understand that there may be video recording and CCTV
                      (Closed Circuit TV) in the test centre. I agree to be
                      videoed while I am in the test centre on the test day,
                      including during check-in procedures and in each of the
                      test rooms used for each part of the test. I understand
                      that video recordings will be used for monitoring
                      purposes, investigations into suspected malpractice and
                      enquiries on results. I understand if I do not agree to be
                      video recorded, I will not be able to take the test.
                    </li>
                    <li>
                      My result may be adjusted or cancelled after release if
                      the IELTS PARTNERS identify any anomalies affecting the
                      reliability of my test score.
                    </li>
                    <li>
                      I understand that I have the option to request and pay for
                      a result review (also known as an IELTS remark or IELTS
                      Enquiry on Result) within six weeks of taking my test. The
                      remark process may take anywhere from less than an hour to
                      a maximum of 21 days for completion. If my score is
                      revised to a higher band, I will receive a full refund of
                      the review fees and a new Test Report Form. However, if my
                      result remains unchanged, I will not receive a refund for
                      the fees incurred during the result review process.
                    </li>
                    <li>
                      I understand and confirm that once I submit a result
                      review application, I cannot cancel the request or make
                      any changes.
                    </li>
                  </ol>
                </li>

                <li>
                  <p>Disclaimers</p>
                  <ol>
                    <li>
                      The International English Language Testing System (IELTS)
                      is designed to be one of many means and factors used by
                      academic institutions, government agencies, professional
                      bodies and employers in determining whether a test taker
                      can be admitted as a student or be considered for
                      employment or for citizenship purposes. IELTS is not
                      designed to be the sole method of determining admission or
                      employment. IELTS tests are made available to all persons,
                      regardless of gender, race, nationality or religion. The
                      One Skill Retake is not recommended to persons under 16
                      years of age.
                    </li>
                    <li>
                      The IELTS PARTNERS and any other party involved in
                      creating, producing, or delivering IELTS shall not be
                      liable for any direct, incidental, consequential,
                      indirect, special, punitive, or similar damages arising
                      out of access to, use of, acceptance by, or interpretation
                      of the results by any third party, or any errors or
                      omissions in the content therein.
                    </li>
                  </ol>
                </li>
              </ol>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-wrapper>
