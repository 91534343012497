<form [formGroup]="form" appApplyTabIndexes *ngIf="form">
  <div *ngIf=!isAddROEnabled class="row">
    <div class="col-12">
      <div class="row-content weight-bold">
        <span class='label'>Delivery Status:&nbsp;</span>
        <span class="value">{{form.get('status').value}}</span>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-lg-6 col-md-12"
      *ngIf="!isAddROEnabled && (form.get('status').value === 'Delivered' || form.get('status').value === 'Redelivered')">
      <div class="row-content weight-bold">
        <app-custom-input fcn="TRFNO" title="TRF No&nbsp;" [form]="form" id="TRFNO" i18n-placeholder
          placeHolder="TRF No" maxLength="255">
        </app-custom-input>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="row-content weight-bold">
        <app-custom-input fcn="contactName" title="Contact Name" [form]="form" id="ContactName" i18n-placeholder
          placeHolder="Contact Name" maxLength="100" [required]="false">
        </app-custom-input>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="row-content weight-bold">
        <app-custom-input fcn="referenceNumber" title="Case/Reference Number" [form]="form" i18n-placeholder
          placeHolder="Case/Reference Number" maxLength="100" [required]="false">
        </app-custom-input>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="row-content weight-bold">
        <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('confirmationStatus')"
          title="Send my result when" i18n-placeholder placeHolder="Confirmation Status" [options]="confirmationStatus"
          (onChangeModel)="showScores = $event && $event.Id" [defaultShowError]="true" [isRequired]="true">
        </app-dropdown>
      </div>
    </div>
    <div *ngIf="showScores"class="col-lg-6 col-md-12">
      <div class="row-content weight-bold">
        <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('overall')" title="Minimum overall"
          i18n-placeholder placeHolder="Overall" [options]="showScores ? scoreWithoutAny : score"
          *ngIf="showScores ? hasScoreWithoutAny(): hasScore()" [defaultShowError]="true" [isRequired]="showScores">
        </app-dropdown>
      </div>
    </div>
    <ng-container *ngIf="showScores">
      <div class="col-lg-6 col-md-12">
        <div class="row-content weight-bold">
          <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('listening')" title="Minimum listening"
            i18n-placeholder placeHolder="listening" [options]="score" *ngIf="hasScore()" [defaultShowError]="true"
                        [isRequired]="showScores">
          </app-dropdown>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row-content weight-bold">
          <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('reading')" title="Minimum reading"
            i18n-placeholder placeHolder="reading" [options]="score" *ngIf="hasScore()" [defaultShowError]="true"
                        [isRequired]="showScores">
          </app-dropdown>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row-content weight-bold">
          <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('writing')" title=" Minimum writing"
            i18n-placeholder placeHolder="writing" [options]="score" *ngIf="hasScore()" [defaultShowError]="true"
                        [isRequired]="showScores">
          </app-dropdown>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="row-content weight-bold">
          <app-dropdown idKey="key" labelKey="value" [formControl]="form.get('speaking')" title="Minimum speaking"
            i18n-placeholder placeHolder="speaking" [options]="score" *ngIf="hasScore()" [defaultShowError]="true"
                        [isRequired]="showScores">
          </app-dropdown>
        </div>
      </div>
    </ng-container>
  </div>
</form>
