<div class="flexbox header">
  <div class="flexbox align-items-center">
    <h2 class="underlined-title">
      <span>Your latest score
      </span>
    </h2>
  </div>
</div>
<div class="main-content">
  <div class="row no-margin share-box" *featureToggle="'shareSocialNetwork'">
    <span>You can now share your score online</span>
    <div class="social-box">
      <a href="#">
        <img src="/assets/images/icon-facebook-red.svg" alt="facebook">
      </a>
      <a href="#">
        <img src="/assets/images/icon-twitter-red.svg" alt="twitter">
      </a>
      <a href="#">
        <img src="/assets/images/icon-instagram-red.svg" alt="instagram">
      </a>
    </div>
  </div>
  <div class="row score-box mt-4">
    <div class=" download-result-wrapper d-flex justify-content-between align-items-center mb-3 w-100">
      <div class="overall show-md-down">
        <div>
          <h2>Overall score</h2>
          <div class="score-text">{{results.overall || '_'}}</div>
          <div class="text-small text-bold mt-1 text-md-start text-center speaking-date show-md-down">
            {{getTestStartDate() | date: 'dd-MMM-yy'}} {{results.productName}}
          </div>
        </div>
      </div>
    </div>
    <div class="overall show-md-up col-3">
      <div>
        <h2>Overall score</h2>
        <div class="score-text">{{results.overall || '_'}}</div>
      </div>
    </div>
    <div class="d-flex flex-column col-lg-8 col-12 p-0">
      <div class="item-container align-self-end">
        <div class="sub-container">
          <div class="item-box">
            <h2>Listening</h2>
            <div class="score-text weight-bold">{{results.listening || '_'}}</div>
          </div>
          <div class="item-box">
            <h2>Reading</h2>
            <div class="score-text weight-bold">{{results.reading || '_'}}</div>
          </div>
        </div>
        <div class="sub-container">
          <div class="item-box">
            <h2>Writing</h2>
            <div class="score-text weight-bold">{{results.writing || '_'}}</div>
          </div>
          <div class="item-box">
            <h2>Speaking</h2>
            <div class="score-text weight-bold">{{results.speaking || '_'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-small text-bold mt-1 text-md-start text-center speaking-date show-md-up">
    {{getTestStartDate() | date: 'dd-MMM-yy'}} {{results.productName}}
  </div>
</div>