<div *ngIf="isPageLoad | async" class="flexbox flex-column content align-items-center align-items-lg-start">
  <h2 class="content-title" i18n>
    {{ ((isOfflinePayment$ | async) || isVeritransCVS) ? 'Finalise payment to reserve your seat' :
    isProfileCompleted ? 'Your seat has been confirmed' : state?.userProfile?.firstName + ', you are nearly done...'
    }}
  </h2>
  <ng-template #isProfileCompletedContent>
    <p>It is important to double-check your personal information to ensure its accuracy.</p>
    <p>Please verify the validity of your ID and make any necessary updates.</p>
  </ng-template>
  <ng-container *ngIf="isVeritransCVS && isProfileCompleted" class="leadText" i18n>
    <p>To confirm your seat, finalise payment. </p>
    <p> Please review your details now.</p>
    <span *ngIf="newebPayDuration">
      <p>Please complete your payment at your nearest Convenience Store on or before
        {{newebPayDuration?.date}}</p>
    </span>
  </ng-container>
  <div class="leadText" i18n *ngIf="isOfflinePayment$ | async; else onlinePaymentSubtitle">
    <p *ngIf="(isNewOfflinePayment$ | async) === false; else newOfflinePaymentText">To confirm your seat, finalise payment using the 
      <span class="instructionLink" (click)="openOfflineInstructions()" (keyup)="openOfflineInstructions()">
        instructions
      </span> provided here.</p>
    <ng-template #newOfflinePaymentText>
      <p>To confirm your seat, finalise payment using the instructions from your profile.</p>
    </ng-template>
    <p>After making the payment, please inform your centre of the completed payment.</p>
    <p> Please review your details now.</p>
    <div class="d-flex flex-column flex-lg-row align-items-md-center row mt-4" *ngIf="!isProfileCompleted">
      <div class="col-12 col-lg-6 d-flex">
        Please pay offline and complete your profile to take the test.
      </div>
      <div class="col-12 col-lg-6">
        <button class="btn-blue btn-highlight text-nowrap" (click)="completeClick()">
          Complete your profile
        </button>
      </div>
    </div>
  </div>
  <ng-template #onlinePaymentSubtitle>
    <div class="leadText textDisplay" *ngIf="isProfileCompleted && !isVeritransCVS">
      <p>Congratulations {{state?.userProfile?.firstName}}, a spot has been reserved for you on the following test
        dates:</p>
      <ng-container *ngTemplateOutlet="isProfileCompletedContent"></ng-container>
    </div>
  </ng-template>
  <div *ngIf="isProfileCompleted" class="col-xl-12 col-xxl-4 ps-2 d-xxl-none d-flex justify-content-center">
    <button class="btn-blue text-nowrap" (click)="completeClick()">
      Review Details
    </button>
  </div>
  <div class="time-detail maxwidth">
    <div class="row justify-content-center">
      <div *ngIf="state.speakingtesttime" class="col flexbox test-item mt-3">
        <div class="side-circle side-circle-st">
          <p class="month">{{getTestDate('st', 'month')}}</p>
          <p class="day">{{getTestDate('st', 'day')}}</p>
          <p class="year">{{getTestDate('st', 'year')}}</p>
        </div>
        <div class="test-time">
          <h3 class="test-name">
            {{stTitle || ''}}
          </h3>
          <h3 class="time">
            <img src="../../../../assets/images/time-icon-black.svg" class="icon" alt="time icon" />
            <ng-container *ngIf="isNotIOLProduct; else IOLDurationDisplay">
              <span>{{getTestDate('st', 'time')}}</span>
            </ng-container>
            <ng-template #IOLDurationDisplay>
              <div>{{getTestDate('st', 'time')?.startTime}}</div>
              <div class="d-block d-md-none ms-2">(</div>
              <i class="fa fa-hourglass-half ms-2 me-2 d-none d-md-inline hour-glass" aria-hidden="true"></i>
              <div>{{getTestDate('st', 'time')?.duration}}</div>
              <div>&nbsp;mins</div>
              <div class="d-block d-md-none">)</div>
            </ng-template>
          </h3>
        </div>
      </div>
      <div *ngIf="state.lrwtesttime" class="col flexbox test-item mt-3">
        <div class="side-circle side-circle-lrw">
          <p class="month">{{getTestDate('lrw', 'month')}}</p>
          <p class="day">{{getTestDate('lrw', 'day')}}</p>
          <p class="year">{{getTestDate('lrw', 'year')}}</p>
        </div>
        <div class="test-time ">
          <h3 class="test-name">
            <span class="d-lg-block d-none">{{lrwTitle || ''}}</span>
            <span class="d-lg-none d-block">{{lrwTitleMd || ''}}</span>
          </h3>
          <h3 class="time">
            <img src="../../../../assets/images/time-icon-black.svg" alt="time icon" class="icon"/>
            <ng-container *ngIf="isNotIOLProduct; else IOLDurationDisplay">
              <span>{{getTestDate('lrw', 'time')}}</span>
            </ng-container>
            <ng-template #IOLDurationDisplay>
              <div>{{getTestDate('lrw', 'time')?.startTime}}</div>
              <div class="d-block d-md-none ms-2">(</div>
              <i class="fa fa-hourglass-half ms-2 me-2 d-none d-md-inline hour-glass" aria-hidden="true"></i>
              <div>{{getTestDate('lrw', 'time')?.duration}}</div>
              <div>&nbsp;mins</div>
              <div class="d-block d-md-none">)</div>
            </ng-template>
          </h3>
        </div>
      </div>
    </div>
    <div class="row button-section justify-content-xxl-around">
      <div *ngIf="isProfileCompleted" class="col-xl-12 col-xxl-4 ps-2 d-none d-xxl-flex">
        <button class="btn-blue text-nowrap" (click)="completeClick()">
          Review Details
        </button>
      </div>
      <div class="col-xl-12 col-xxl-4">
        <button class="btn-white confirm-white-btn" (click)="generateIcal()" i18n>Add to calendar</button>
      </div>
    </div>
  </div>


  <div class="row complete-profile maxwidth" *ngIf="(isOfflinePayment$ | async) === false && !isProfileCompleted">
    <div class="w-100" i18n>
      <p>Before you can sit the test, you need to complete the test registration process.</p>
      <p>This includes:</p>
      <ol class="text-start line-height-md">
        <li>Providing us with your details </li>
        <li>A copy of your ID document</li>
        <li>Information around why you are booking the test</li>
      </ol>
      <p>This must be completed as soon as possible to ensure you are able to successfully sit for the test.</p>
    </div>
    <div class="d-flex flex-column flex-lg-row align-items-md-center row">
      <div class="col-12 col-lg-6">
        <button class="btn-blue btn-highlight text-nowrap" (click)="completeClick()">
          Complete your profile
        </button>
      </div>
    </div>
  </div>
</div>