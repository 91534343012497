<form [formGroup]="form">
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-custom-input [fcn]="'address1'" maxLength="100" [form]="form" [placeHolder]="'Address line 1'"
        [required]="true" [title]='showTitle ? "Address line 1" : ""'>
      </app-custom-input>
    </div>
    <div class="col-12 col-lg-6">
      <app-custom-input [fcn]="'address2'" showError="true" maxLength="100" [form]="form"
        [placeHolder]="'Address line 2'" [required]="false" [title]='showTitle ? "Address line 2" : ""'>
      </app-custom-input>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-dropdown labelKey="name" idKey='id' i18n-placeholder placeholder="Country" height="51.5" *ngIf="countryList"
        (onChangeModel)="selectChange($event, 'country')" [options]="countryList"
        [formControl]="form && form.get('country')" [isRequired]="true" [title]='showTitle ? "Country" : ""'>
      </app-dropdown>
    </div>
    <div class="col-12 col-lg-6">
      <app-dropdown id='province' labelKey="name" idKey='id' i18n-placeholder placeholder="State, region or  province"
        height="51.5" (selectChange)="selectChange($event, 'province')" [options]="territoryList"
        *ngIf="territoryList && form" [formControl]="form && form.get('province')" [nonMandatory]="true"
        [title]='showTitle ? "State, region or  province" : ""' [showTick]="form && form.get('province').value">
      </app-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-custom-input [fcn]="'city'" showError="true" maxLength="100" [form]="form" [placeHolder]="'City'"
        [required]="false" [title]='showTitle ? "City" : ""'></app-custom-input>
    </div>
    <div class="col-12 col-lg-6">
      <app-custom-input [fcn]="'postcode'" showError="true" [form]="form"
        [placeHolder]="'Postcode, zip code or area code'" [required]="false" maxLength="10"
        [title]='showTitle ? "Postcode, zip code or area code" : ""'></app-custom-input>
    </div>
  </div>
</form>
