import { createReducer, createSelector, on, Selector } from '@ngrx/store';
import {
  setBackAction,
  setPageError,
  setRequestMode,
  set3dsPayment,
  setLocationMode,
  setSSR,
  setUpdateApplicationDetails,
  setEnableOfflineEOR,
  setDownloadEtrf,
  setEnableNewAccountUI,
  setEnableDashboardV2,
  setEnableNewOfflinePayment,
    setChangePaymentToOnline,
    setEnableOnetrustPolicy,
    setEnableCaptchaCheck
} from './global.actions';


export interface GlobalState {
    BackButtonAction: {
        BackButtonRoute: string,
        BackButtonEnable: boolean
    };
    pageError: { message: string, routeFrom: string };
    requestMode: 'Api' | 'Mock';
    locationMode: 'Api' | 'Mock';
    enable3DSPayment: boolean;
    enableSSR: boolean;
    enableUpdateApplicationDetails: boolean;
    enableOfflineEOR: boolean;
    enableDownloadEtrf: boolean;
    enableNewAccountUI: boolean;
    enableCaptchaCheck: boolean;
    enableDashboardV2: boolean;
    enableNewOfflinePayment: boolean;
    enableOnetrustPolicy: boolean;
    changePaymentToOnlineEnabled: boolean;
}
const { site } = require('src/assets/appSettings.json');
const { featureFlags } = require('src/assets/appSettings.json');
const initialState: GlobalState = {
    BackButtonAction: {
        BackButtonRoute: null,
        BackButtonEnable: true
    },
    pageError: null,
    requestMode: !site.alwaysUseApis ? 'Mock' : 'Api',
    locationMode: !site.alwaysUseApis ? 'Mock' : 'Api',
    enable3DSPayment: featureFlags.enabled3DS,
    enableSSR: featureFlags.enableSSR,
    enableUpdateApplicationDetails: featureFlags.enableUpdateApplicationDetails,
    enableOfflineEOR: featureFlags.enableOfflineEOR,
    enableDownloadEtrf: featureFlags.enableDownloadEtrf,
    enableNewAccountUI: featureFlags.enableNewAccountUI,
    enableCaptchaCheck: featureFlags.enableCaptchaCheck,
    enableDashboardV2: featureFlags.enableDashboardV2,
    enableNewOfflinePayment: featureFlags.enableNewOfflinePayment,
    enableOnetrustPolicy: featureFlags.enableOnetrustpopup,
    changePaymentToOnlineEnabled: featureFlags.changePaymentToOnlineEnabled,
};

export const globalReducer = createReducer(
    initialState,
    on(setBackAction, (state, payload: { BackButtonRoute: string, BackButtonEnable: boolean }) => ({
        ...state,
        BackButtonAction: payload
    })),
    on(setPageError, (state, { pageError }) => ({
        ...state,
        pageError
    })),
    on(setRequestMode, (state, { requestMode }) => ({
        ...state,
        requestMode
    })),
    on(setLocationMode, (state, { locationMode }) => ({
        ...state,
        locationMode
    })),
    on(set3dsPayment, (state, { enable3DSPayment }) => ({
        ...state,
        enable3DSPayment
    })),
    on(setSSR, (state, { enableSSR }) => ({
        ...state,
        enableSSR
    })),
    on(setUpdateApplicationDetails, (state, { enableUpdateApplicationDetails }) => ({
        ...state,
        enableUpdateApplicationDetails
    })),
    on(setEnableOfflineEOR, (state, { enableOfflineEOR }) => ({
        ...state,
        enableOfflineEOR
    })),
    on(setDownloadEtrf, (state, { enableDownloadEtrf }) => ({
        ...state,
        enableDownloadEtrf
    })),
    on(setEnableNewAccountUI, (state, { enableNewAccountUI }) => ({
        ...state,
        enableNewAccountUI
    })),
    on(setEnableCaptchaCheck, (state, { enableCaptchaCheck }) => ({
        ...state,
        enableCaptchaCheck
    })),
    on(setEnableDashboardV2, (state, { enableDashboardV2 }) => ({
        ...state,
        enableDashboardV2
    })),
    on(setEnableNewOfflinePayment, (state, { enableNewOfflinePayment }) => ({
        ...state,
        enableNewOfflinePayment
    })),
    on(setEnableOnetrustPolicy, (state, { enableOnetrustPolicy }) => ({
        ...state,
        enableOnetrustPolicy
    })),
    on(setChangePaymentToOnline, (state, { changePaymentToOnlineEnabled }) => ({
        ...state,
        changePaymentToOnlineEnabled
    })),
);

export const getState = (state: { globalStore: GlobalState }) => state.globalStore;

export const getEnableOfflineEOR = (state: GlobalState) => state.enableOfflineEOR;

export const getEnableNewOfflinePayment = (state: GlobalState) => state.enableNewOfflinePayment;

export const getChangePaymentToOnlineEnabled = (state: GlobalState) => state.changePaymentToOnlineEnabled;

export const getCheckCaptcha = (state: GlobalState) => state.enableCaptchaCheck;

const createMyTestsSelector = (selectMethod) => createSelector(
  getState,
  selectMethod
);

export const selectEnableOfflineEOR: Selector<{ globalStore: GlobalState }, any> = createMyTestsSelector(
  getEnableOfflineEOR
);

export const selectEnableNewOfflinePayment: Selector<
  { globalStore: GlobalState },
  any
> = createMyTestsSelector(getEnableNewOfflinePayment);

export const selectChangePaymentToOnlineEnabled: Selector<
  { globalStore: GlobalState },
  any
> = createMyTestsSelector(getChangePaymentToOnlineEnabled);

export const selectCheckCaptcha: Selector<{ globalStore: GlobalState }, any> = createMyTestsSelector(
    getCheckCaptcha
  );
