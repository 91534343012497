import { UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { DATE_REGEXP } from 'src/app/shared/sharedRegex';
export class ExpiryDateValidatorsHelper {
  static expiryDateValidator() {
    return (formGroup: UntypedFormGroup) => {
        const expiryDateControl = formGroup.controls['expiryDate'];
        if (!expiryDateControl.value || (expiryDateControl.errors && !expiryDateControl.errors.date)) {
          return;
        }
        const date = formGroup.controls['expiryDate'].value;
        const dateTime = DateTime.fromFormat(date || '', 'd/M/yyyy');
        // set error on matchingControl if validation fails
        if (expiryDateControl?.value?.length > 0 && expiryDateControl?.value?.length < 10) {
          return expiryDateControl.setErrors({ dateLengthMatch: true });
        } else {
          if ((date || expiryDateControl.hasError('required')) && !DATE_REGEXP.test(date)) {
            expiryDateControl.setErrors({ date: true });
          } else if ((date || expiryDateControl.hasError('required')) && !(dateTime.isValid && dateTime > DateTime.now().startOf('day'))) {
            expiryDateControl.setErrors({ invalidExpireDate: true });
          } else {
            expiryDateControl.setErrors(null);
          }
        }
      };
    }
}
