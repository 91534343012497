<h2>Search Criteria</h2>
<div class="d-inline-flex flex-column flex-lg-row align-items-stretch flex-grow-1 w-100 border border-dark">
  <div class="d-flex flex-column flex-grow-1 pt-1 pb-1 ps-2 pe-2 summary-item border-dark text-grey bg-light">
    <div class="text-small fw-light">Test format</div>
    <div class="text-medium leadText">
      <!--TODO: remove hardcoded value-->
      <app-ssr-list-item-test-format format="CD" [withIcon]="false"></app-ssr-list-item-test-format>
    </div>
  </div>
  <div class="d-flex flex-column flex-grow-1 pt-1 pb-1 ps-2 pe-2 summary-item border-dark">
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex flex-grow-1 flex-column">
        <div class="text-small fw-light">Test location</div>
        <div class="text-medium leadText">{{location}}</div>
      </div>
      <div class="d-flex">
        <button type="button" class="btn btn-edit" (click)="onEditLocation.emit()" title="Edit test location">
          <img src="../../../../../../assets/images/notify-institutions/pencil-solid.svg" alt="" class="img-content">
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column flex-grow-1 pt-1 pb-1 ps-2 pe-2 summary-item">
    <div class="d-flex flex-row align-items-center">
      <div class="d-flex flex-grow-1 flex-column">
        <div class="text-small fw-light">Test date</div>
        <div class="text-medium leadText">{{dateRange}}</div>
      </div>
      <div class="d-flex">
        <button type="button" class="btn btn-edit" (click)="onEditDate.emit()" title="Edit test date">
          <img src="../../../../../../assets/images/notify-institutions/pencil-solid.svg" alt="" class="img-content">
        </button>
      </div>
    </div>
  </div>
</div>
