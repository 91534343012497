<div class="no-result">
    <img src="./assets/images/no-result.svg" alt=""/>
    <div class="title">Sorry, your search returned no test dates.</div>
    <div class="subtitle">To expand your search, please try:</div>
    <ul>
      <li>Select another test format</li>
      <li>Updating the search location</li>
      <li>Expanding the date range</li>
    </ul>
    <button type="button" (click)="onClickAction.emit()" class="btn btn-primary mb-2">Search again</button>
</div>