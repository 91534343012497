import { Component, EventEmitter, Input, Output } from '@angular/core';
import dateFormat from 'dateformat';
import { formatDistance, is12am } from '../../util';
import { ViewportService } from '../../../../../shared/services/viewport.service';

@Component({
  selector: 'app-ssr-list-item',
  templateUrl: './ssr-list-item.component.html',
  styleUrls: ['ssr-list-item.component.scss']
})
export class SsrListItemComponent {
  @Input() session: any;
  @Output() onReserve = new EventEmitter();

  constructor(public viewportService: ViewportService) {
  }

  formatDate(date: string, format: string) {
    const formattedDate = (date && date.length > 6) ? date.substring(0, date.length - 6) : '';
    return (formattedDate && format) ? dateFormat(new Date(formattedDate), format) : '';
  }

  formatDistance(distance: number) {
    return formatDistance(distance);
  }

  is12am(testStartLocalDateTime: string) {
    return is12am(testStartLocalDateTime);
  }

  popoverClose(pop): void{
    pop.close();
  }
}
