import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
export type IStatus = 'completeDetail' | 'downloadPlayer' | 'speakingDetail' | 'completeSpeaking' | 'lrwDetail' | 'completeLRW' | 'lrwCompleted' | 'complete';
type IJourneyState = 'default' | 'active' | 'text-disabled' | 'disabled';
export type IJourney = {
  id: string,
  description?: string,
  text: string,
  status: IJourneyState,
  classes?: Array<string>,
  isIcon?: boolean,
  key?: IStatus,
  inVisible?: boolean,
  clickAble: boolean,
  order?: number,
  isSelected?: boolean,
  isManual?: boolean
};
export type ISelectedItemValue = IJourney | number | string;

@Component({
  selector: 'app-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class JourneyComponent {
  @Input() disabled = false;
  @Input('items') set Items(v: IJourney[]) {
    if (Array.isArray(v)) {
      const tempItems = v.sort((x, y) => {
        if (x.order > y.order) {
          return 1;
        } else if (x.order < y.order) {
          return -1;
        }
        return 0;
      });
      this.originalItems = [...tempItems];
      this._items = [...tempItems];
    } else {
      this._items = [];
    }
  }

  get items() {
    return this._items;
  }
  @Output() onItemClicked: EventEmitter<IJourney> = new EventEmitter();
  private _items: IJourney[] = [];
  private originalItems: IJourney[] = [];

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['< Previous', 'Next >'],
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      400: {
        items: 2,
        nav: true
      },
      740: {
        items: 3,
        nav: true
      },
      940: {
        items: 4,
        nav: false
      }
    },
    nav: false
  };

  onItemClick(item: IJourney) {
    /* istanbul ignore else */
    if (!item.clickAble || this.disabled) {
      return;
    }
    /* istanbul ignore else */
    if (this.onItemClicked) {
      this.onItemClicked.emit(item);
    }
  }

}
