<app-form-wrapper [hasBackButton]="false">
  <div title>Offline Payment Instructions</div>
  <div content class="main-content d-flex w-100 mt-4">
    <div class="content-box">
      <div class="row">
      </div>
      <div class="bg-theme has-border-top has-border-bottom mb-4 row p-2">
        <div class="col-md-10 col-lg-8 col-print-10 center-block">
          <div class="textalign well">
            <div>
              <p> An IDP Staff member told me to pay using the Pay Offline method:</p>
              <ul>
                <li> Great! You're in the right place. You can click here to pay for your test. You can follow the
                  instructions that you've received from IDP.</li>
              </ul>
              <p> I accidentally clicked the Pay Offline method:</p>
              <ul>
                <li> That's okay! We can help you pay for your test so that your booking is confirmed staright away.
                  Just follow these easy steps:
                  <ul class="sublist-style">
                    <li> Close this window </li>
                    <li> On the payment screen select the credit card option next to the offline button </li>
                    <li> Enter your payment details and confirm payment of the test </li>
                    <li> Complete your booking details to ensure you can sit the test </li>
                  </ul>
                </li>
              </ul>
              <p> I need to make an Enquiry on Results (EOR) Offline Payment</p>
              <ul>
                <li> Offline payments for EORs are not available, please make an online payment for your EOR.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-wrapper>