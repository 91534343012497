import { Languages } from './../languageList';
export const Options = {
    firstLang: [
        ...Languages.map(i => ({ id: i.name, name: i.nativeName }))
    ],
    occupationSector: [
        ...[
            'Administrative services',
            'Technical and Scientific',
            'Wholesale Trade',
            'Utilities (gas, water etc )',
            'Transport',
            'Telecommunications & the Media',
            'Retail Trade',
            'Personal Services',
            'Manufacturing and Assembly',
            'Law and Legal Services',
            'Installation, Maintenance and Repair Services',
            'Health and Social Services',
            'Education',
            'Craft and Design',
            'Construction Industries',
            'Catering and Leisure',
            'Banking and Finance',
            'Arts and Entertainment',
            'Agriculture, Fishing, Forestry, Mining',
            'Other',
        ].map((e, index) => ({ id: index, label: e }))
    ],
    educationLevel: [
        ...[
            'Secondary up to 16 years',
            'Secondary 16 to 19 years',
            'Degree or equivalent',
            'Post graduate',
        ].map((e, index) => ({ id: index, label: e }))
    ],
    studyEnglishYears: [
        ...[
            $localize`Less than 1 year`,
            $localize`2 years`,
            $localize`3 years`,
            $localize`4 years`,
            $localize`5 years`,
            $localize`6 years`,
            $localize`7 years`,
            $localize`8 years`,
            $localize`9 years or more`,
        ].map((e, index) => ({ id: index + 1, name: e }))
    ],
    occupationLevel: [
        ...[
            'Self-employed',
            'Student',
            'Retired',
            'Worker in the home',
            'Employee (Middle or Junior)',
            'Employee (Senior level)',
            'Employer/Partner',
            'Other',
        ].map((e, index) => ({ id: index, label: e }))
    ],
    destinationCountry: [
        ...[
            'Australia',
            'Canada',
            'New Zealand',
            'Republic of Ireland',
            'United Kingdom',
            'United States',
            'Other',
        ].map((e, index) => ({ id: index, label: e }))
    ]
};
