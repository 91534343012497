import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { debounceTime, delay, map, startWith, switchMap } from 'rxjs/operators';

/*
* ViewportService
* A service to watch window width real time.
* A component might subscribe to either isMobile$ or isTablet$ so it's notified if user changes
* the width of the viewport.
* */
@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isTablet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public viewPortChange$:  BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.evalMobile();
    }
    fromEvent(window, 'resize').pipe(debounceTime(500)).subscribe(() => this.evalMobile());
  }

  evalMobile() {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    this.isMobile$.next(width <= 768);
    this.isTablet$.next(width <= 1099);
  }

  isMobile() {
    return this.isMobile$.getValue();
  }

  isMobileView$ = merge(fromEvent(window, 'resize'), this.viewPortChange$)
  .pipe(
    debounceTime(500),
    delay(300),
    map(() => {
      return this.calculateWindowWidth() < 768;
    }),
    startWith(this.calculateWindowWidth() < 768)
  )

  calculateWindowWidth(): number {
    return (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  initViewport(): void {
    this.viewPortChange$.next();
  }

  isTablet() {
    return this.isTablet$.getValue();
  }
}

