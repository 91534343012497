export type OfflinePayment = {
  offlinePaymentLabel: string;
  offlinePaymentDescription: string;
  subOfflinePaymentConfigs: SubOfflinePaymentConfig[];
};

export const SubOfflinePaymentConfigMap: {
  [key: string]: SubOfflinePaymentConfigType;
} = {
  Cash: 'cash',
  Cheque: 'cheque',
  'Demand Draft': 'demand-draft',
  'Fund Transfer': 'fund-transfer',
  'Other': 'other',
};

export type SubOfflinePaymentConfigType =
  | "cash"
  | "cheque"
  | "fund-transfer"
  | "demand-draft"
  | "other";

export type SubOfflinePaymentConfig = {
  type: SubOfflinePaymentConfigType;
  label: string;
  description: string;
  instructions: string;
};

export const mockOfflinePaymentConfigs: SubOfflinePaymentConfig[] = [
  {
    type: "cash",
    label: "Cash payments",
    description:
      "Visit the test centre with a valid passport or ID and pay for you test booking registration via cash.",
    instructions: `<h2>Customisable instructions for Cash method</h2>
    <h4>Cash method step 1</h4>
    <p>Transfer money through any bank in Uzbekistan to the following address</p>
    <ul>
      <li>
        <p>
          Name of Organization: EDU ACTION LLC
        </p>
      </li>
      <li>
        <p>
          Bank Account Number: 20208000905333707001
        </p>
      </li>
      <li>
        <p>
          Bank Name: Ipak yoli
        </p>
      </li>
      <li>
        <p>
          Bank code (MFO): 01101
        </p>
      </li>
      <li>
        <p>
          INN: 307858754
        </p>
      </li>
    </ul>
    <p>In the payment details, Please include – FULL NAME OF THE APPLICANT, IELTS EXAM BOOKED DATE
    </p>
    <p>
      A confirmation e-mail will be sent to you. Applicants should allow 3 working days for processing and
      confirmation. Contact us if more than 3 days passed and no confirmation email was received.
    </p>
    <h4>Cash method step 2</h4>
    <p>Transfer money through any bank in Uzbekistan to the following address</p>
    <ul>
      <li>
        <p>
          Name of Organization: EDU ACTION LLC
        </p>
      </li>
      <li>
        <p>
          Bank Account Number: 20208000905333707001
        </p>
      </li>
      <li>
        <p>
          Bank Name: Ipak yoli
        </p>
      </li>
      <li>
        <p>
          Bank code (MFO): 01101
        </p>
      </li>
      <li>
        <p>
          INN: 307858754
        </p>
      </li>
    </ul>
    <p>In the payment details, Please include – FULL NAME OF THE APPLICANT, IELTS EXAM BOOKED DATE
    </p>
    <p>
      A confirmation e-mail will be sent to you. Applicants should allow 3 working days for processing and
      confirmation. Contact us if more than 3 days passed and no confirmation email was received.
    </p>`,
  },
  {
    type: "cheque",
    label: "Check payments",
    description:
      "Pay for your test via signed check to the assigned IDP IELTS Bank account",
    instructions: `<h2>Customisable instructions for Check payments method</h2>
    <h4>Check payments step 1</h4>
    <p>Transfer money through any bank in Uzbekistan to the following address</p>
    <ul>
      <li>
        <p>
          Name of Organization: EDU ACTION LLC
        </p>
      </li>
      <li>
        <p>
          Bank Account Number: 20208000905333707001
        </p>
      </li>
      <li>
        <p>
          Bank Name: Ipak yoli
        </p>
      </li>
      <li>
        <p>
          Bank code (MFO): 01101
        </p>
      </li>
      <li>
        <p>
          INN: 307858754
        </p>
      </li>
    </ul>`,
  },
  {
    type: "demand-draft",
    label: "Bank draft",
    description: "[Copy waiting to be determined by TCs or OPs]",
    instructions: "",
  },
  {
    type: "fund-transfer",
    label: "In-centre credit and debit payments",
    description:
      "Visit the test centre and pay for you test via credit or debit card (Master, Visa) with a valid passport and ID",
    instructions: `<h2>Customisable instructions for fund-transfer method</h2>
    <h4>fund-transfer step 1</h4>
    <p>Transfer money through any bank in Uzbekistan to the following address</p>`,
  },
  {
    type: "other",
    label: "[Other]",
    description: "Other description",
    instructions: "Other instructions",
  },
];
