import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Subscription } from 'rxjs';
import {
  setCurrentStep,
  setCurrentStepName,
  setProgressValue,
  setHasTimer,
  setFee, setFromTips,
  setFromOffline,
} from '../store/payment.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { paymentIState, selectOfflinePayment } from '../store/payment.reducer';
import { Title, Meta } from '@angular/platform-browser';
import { SetProductType, SetTestLocalTimeZone } from '../../booking/store/booking.actions';
import { createEventParam, generateIcal } from '../../../shared/utils/generate-ical';
import { calculateDuration } from 'src/app/shared/utils/custom-date-format';
import { setCurrentApplication } from '../../../store/applications/application.action';
import {
  Application,
  ApplicationService,
  DashboardItem
} from '@idp-education/ors-test-taker-bff-client-v1';
import { setBackAction } from '../../../store/global.actions';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { AppIState } from 'src/app/store/applications/application.reducer';
import { setGoToApplUpdate, setOriginalApplication } from 'src/app/store/my-tests/my-tests.actions';
import { setOnboardingApplication } from '../../onboarding/store/onboarding.actions';
import { DateTime } from 'luxon';
import { delay, expand, first, map, takeLast } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { PaymentUtilService } from '../payment-util.service';
import { GALocalStorageItemKeys, IOCLocalStorageItemKeys, PaymentGateways } from '../payment.enum';
import { testCentreCode$ } from './../../../shared/utils/initialize-payment-method';
import { PaymentMethodType } from '@idp-education/ors-test-taker-bff-client-v1/model/paymentMethodType';
import { ApplicationsService } from 'shared/services/applications.service';
import { IPaymentGateways } from 'shared/interfaces/payment.interface';
import { generateUtmUrl, UtmLinkKeys } from 'shared/utils/utm-params-utils';
import { selectEnableNewOfflinePayment } from 'store/global.reducer';
declare let dataLayer;
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  speakingtesttime;
  lrwtesttime;
  stTitle = $localize`Speaking test`;
  lrwTitle = $localize`Listening, Reading and Writing test`;
  lrwTitleMd = $localize`Listening, Reading & Writing test`;
  sub: Subscription;
  state: paymentIState;
  applicationState: AppIState;
  currentApplication: DashboardItem;
  lastDate: Date;
  isNotIOLProduct: boolean;
  testLocalTimezone: string;
  nonIOLLastDate: string;
  isVeritransCVS = false;
  speakingTime: { startTime: string, duration?: string } = { startTime: '' };
  lrwTime: { startTime: string, duration?: string } = { startTime: '' };
  isOfflinePayment$ = this.store.select(selectOfflinePayment);
  isNewOfflinePayment$ = this.store.select(selectEnableNewOfflinePayment);
  isProfileCompleted = false;
  application: Application;
  isPageLoad: Promise<boolean>;
  amount: number;
  taxAmount: number;
  productName: string;
  productId: string;
  testLocationId: string;
  payCode: string;
  transactionId: string;
  paymentMethod: string;
  newebPayDuration: any;

  constructor(
    private store: Store<{ paymentStore, bookingStore, applicationsStore, globalStore }>,
    private router: Router,
    private route: ActivatedRoute,
    private paymentUtilService: PaymentUtilService,
    private dashboardService: DashboardService,
    private applicationService: ApplicationService,
    private applicationsService: ApplicationsService,
    private loadingService: LoadingService,
    private titleService: Title, private metaTagService: Meta
  ) {
    // checking stripe payment / veritrans
    const paymentGateway = localStorage.getItem('paymentGateway');
    const paymentMethodType = localStorage.getItem('paymentMethodType');
    // Using isVeritransCVS variable to check if CVS is selected for Veritrans and Newebpay.
    this.isVeritransCVS = paymentMethodType === PaymentMethodType.CVS;
    if (paymentGateway === PaymentGateways.NEWEBPAY && this.isVeritransCVS) {
      const localstoredDuration = JSON.parse(localStorage.getItem('newebpayDateTime'));
      this.newebPayDuration = localstoredDuration;
    }
    this.route.queryParams.pipe(first()).subscribe(qp => {
      const gateways = IPaymentGateways as string[];
      if ((qp && Object.keys(qp).length) || gateways.includes(paymentGateway)) {
        this.restoreBookingInfo();
      }
    });
    this.titleService.setTitle('Your payment was successful | IDP IELTS');
    this.sub = this.store.select(appState => appState.paymentStore).subscribe((x) => {
      if (x.speakingtesttime && x.lrwtesttime) {
        this.state = x;
      } else {
        this.router.navigate(['/my-account']);
      }

    });
    this.sub.add(this.store.select(state => state.bookingStore.isNotIOLProduct).subscribe(payload => {
      this.isNotIOLProduct = payload;
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.testLocalTimezone).subscribe(x => {
      if (x) {
        this.testLocalTimezone = x;
      }
    }));
    this.sub.add(this.store.select(appState => appState.applicationsStore).subscribe(x => {
      if (x.currentApplication) {
        this.applicationState = x;
        this.store.dispatch(setCurrentApplication({ application: null }));
      }
    }));
    this.store.dispatch(setCurrentStep({ currentStep: 4 }));
    this.store.dispatch(setProgressValue({ progressValue: 100 }));
    this.store.dispatch(setHasTimer({ hasTimer: false }));
    this.sub.add(this.isOfflinePayment$.subscribe(isOffline => {
      this.store.dispatch(setCurrentStepName({
        currentStepName: isOffline ? 'Make offline payment' : 'Seat Reserved'
      }));
      if (isOffline) {
        this.store.dispatch(setBackAction({ BackButtonEnable: false, BackButtonRoute: null }));
      }
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.store.dispatch(SetProductType({
      isNotIOLProduct: false
    }));
    this.store.dispatch(setFromTips({ fromTips: false }));
    this.store.dispatch(setFromOffline({ fromOffline: false }));
  }

  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    this.getLastDate();
    this.getUpcomingTest();
  }

  // retrieving booking info from local storage in case of stripe payment
  // and store the same in @ngrx/store to maintain same code flow
  // info in local storage is cleared after restoring
  // refer storeBookingInfo() in stripe.component.ts
  restoreBookingInfo() {
    // converting enum to array
    const itemKeys = Object.values(IOCLocalStorageItemKeys).filter(v => isNaN(Number(v)));
    if (itemKeys.every(key => localStorage.getItem(key))) {
      const speakingtesttime = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.speakingtesttime)) as { from: Date, to: Date };
      const lrwtesttime = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.lrwtesttime)) as { from: Date, to: Date };
      const isNotIOLProduct = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.isNotIOLProduct));
      const testLocalTimezone = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.testLocalTimezone));
      const applicationsStore = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore));
      const productFee = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.productFee));

      speakingtesttime.from = new Date(speakingtesttime?.from);
      speakingtesttime.to = new Date(speakingtesttime?.to);
      lrwtesttime.from = new Date(lrwtesttime?.from);
      lrwtesttime.to = new Date(lrwtesttime?.to);

      this.store.dispatch(this.paymentUtilService.setSpeakingTestTimeAction({ speakingtesttime }));
      this.store.dispatch(this.paymentUtilService.setLrwTestTimeAction({ lrwtesttime }));
      this.store.dispatch(SetProductType({ isNotIOLProduct }));
      this.store.dispatch(SetTestLocalTimeZone({ testLocalTimezone }));
      this.store.dispatch(setCurrentApplication({ application: applicationsStore?.currentApplication }));
      this.store.dispatch(setFee({ fee: productFee }));

      itemKeys.forEach(key => localStorage.removeItem(key));
      localStorage.removeItem('paymentGateway');
      localStorage.removeItem('paymentMethodType');
      localStorage.removeItem('bankAlfalahResult');
      localStorage.removeItem('newebpayDateTime');
    } else {
      this.router.navigate(['/my-account']);
    }
  }

  getLastDate() {
    if (this.isNotIOLProduct) {
      const speakingTimeClone = DateTime.fromJSDate(this.state?.speakingtesttime?.from)
        .setZone(this.testLocalTimezone);
      const lrwTimeClone = DateTime.fromJSDate(this.state.lrwtesttime.from)
        .setZone(this.testLocalTimezone);
      const speakingTime = new Date(speakingTimeClone.toFormat('dd') + ' ' + speakingTimeClone.toFormat('MMMM') + ' ' +
        speakingTimeClone.toFormat('yyyy') + ' ' + speakingTimeClone.toFormat('h:mm a'));
      const lrwTime = new Date(lrwTimeClone.toFormat('dd') + ' ' + lrwTimeClone.toFormat('MMMM') + ' ' +
        lrwTimeClone.toFormat('yyyy') + ' ' + lrwTimeClone.toFormat('h:mm a'));
      const latestDay = speakingTime < lrwTime ? speakingTime : lrwTime;
      latestDay.setHours(latestDay.getHours() - 48);
      this.lastDate = latestDay;
    } else {
      const dt = new Date(this.state?.speakingtesttime?.from);
      dt.setHours(dt.getHours() - 48);
      this.lastDate = dt;
    }
  }

  getUpcomingTest() {
    if (this.state?.isOffline || this.isVeritransCVS) {
      this.sub.add(this.dashboardService.getUpcomingTests().subscribe(upcomingTest => {
        this.currentApplication = upcomingTest.find(t => t.applicationId === this.applicationState?.currentApplication.id);
        this.isProfileCompleted = this.currentApplication.isComplete;
        this.getApplication();
      }));
    } else {
      this.loadingService.increaseLoadingCounter();
      let counter = 0;
      this.dashboardService.getUpcomingTests().pipe(first(), delay(3000),
        expand((upcomingTest) => {
          this.currentApplication = upcomingTest.find(t => t.applicationId === this.applicationState?.currentApplication.id);
          this.isProfileCompleted = this.currentApplication.status === 'COMPLETED';
          if (!this.isProfileCompleted && counter < 2) {
            counter++;
            return this.dashboardService.getUpcomingTests().pipe(first(), delay(3000));
          }
          return EMPTY;
        }),
        takeLast(1)
      ).subscribe(upcomingTest => {
        this.loadingService.decreaseLoadingCounter();
        this.getApplication();
      });
    }
  }

  getApplication() {
    this.sub.add(this.applicationService.getApplication(this.currentApplication.applicationId).subscribe(app => {
      this.application = app;
      if (app && app.applicationPayments?.length > 0 && app.bookings[0]) {
        const applicationPayment = this.applicationsService.getLatestApplicationPayment(app.applicationPayments);
        this.amount = applicationPayment.applicationFee?.totalAmount;
        const taxAmount = (applicationPayment.applicationFee?.totalAmount) - (applicationPayment.applicationFee?.baseAmount);
        const fixedAmount = (taxAmount).toFixed(2);
        this.taxAmount = Number(fixedAmount);
        this.productName = app.bookings[0].bookableProductName;
        this.productId = app.bookings[0].bookableProductId;
        this.testLocationId = app.bookings[0].testLocationId;
        this.payCode = applicationPayment.applicationFee.currencyIsoCode;
        this.transactionId = applicationPayment.receiptNumber;
        this.paymentMethod = applicationPayment.paymentMethod === 'OFFLINE' ? 'offline' : 'online';
        this.pushGAData();
      }
      this.store.dispatch(setOnboardingApplication({ application: this.application }));
      this.isPageLoad = Promise.resolve(true);
      this.store.dispatch(setFromTips({ fromTips: false }));
    }));
  }

  getTestDate(test: string, type: string) {
    switch (test) {
      case 'st': {
        if (this.state.speakingtesttime.from instanceof Date) {
          const speakingTimeClone = DateTime.fromJSDate(this.state.speakingtesttime.from)
            .setZone(this.testLocalTimezone);
          switch (type) {
            case 'month':
              if (this.isNotIOLProduct) {
                return speakingTimeClone.toFormat('MMM');
              } else {
                return this.state.speakingtesttime.from.toLocaleString('default', { month: 'short' });
              }
            case 'day':
              if (this.isNotIOLProduct) {
                return speakingTimeClone.toFormat('dd');
              } else {
                return ('0' + this.state.speakingtesttime.from.getDate()).slice(-2);
              }
            case 'year':
              if (this.isNotIOLProduct) {
                return speakingTimeClone.toFormat('yyyy');
              } else {
                return this.state.speakingtesttime.from.getFullYear();
              }
            case 'time':
              if (this.isNotIOLProduct) {
                const nonIOLTime = speakingTimeClone.toFormat('h:mm a').toLowerCase();
                return `${nonIOLTime}`;
              } else {
                const {
                  startTime,
                  duration
                } = calculateDuration(
                  DateTime.fromJSDate(this.state.speakingtesttime.from).toLocal(),
                  DateTime.fromJSDate(this.state.speakingtesttime.to).toLocal(),
                  true
                );
                return { startTime, duration };
              }
          }
        }
        break;
      }
      case 'lrw': {
        if (this.state.lrwtesttime.from instanceof Date) {
          const lrwTimeClone = DateTime.fromJSDate(this.state.lrwtesttime.from)
            .setZone(this.testLocalTimezone);
          switch (type) {
            case 'month':
              if (this.isNotIOLProduct) {
                return lrwTimeClone.toFormat('MMM');
              } else {
                return this.state.lrwtesttime.from.toLocaleString('default', { month: 'short' });
              }
            case 'day':
              if (this.isNotIOLProduct) {
                return lrwTimeClone.toFormat('dd');
              } else {
                return ('0' + this.state.lrwtesttime.from.getDate()).slice(-2);
              }
            case 'year':
              if (this.isNotIOLProduct) {
                return lrwTimeClone.toFormat('yyyy');
              } else {
                return this.state.lrwtesttime.from.getFullYear();
              }
            case 'time':
              if (this.isNotIOLProduct) {
                const nonIOLTime = lrwTimeClone.toFormat('h:mm a').toLowerCase();
                return `${nonIOLTime}`;
              } else {
                const {
                  startTime,
                  duration
                } = calculateDuration(
                  DateTime.fromJSDate(this.state.lrwtesttime.from).toLocal(),
                  DateTime.fromJSDate(this.state.lrwtesttime.to).toLocal(),
                  false
                );
                return { startTime, duration };
              }
          }
        }
      }
    }
  }

  generateIcal() {
    const speakingTestTimeFrom = new Date(this.state.speakingtesttime.from);
    const speakingTestTimeTo = new Date(this.state.speakingtesttime.to);
    const lrwFrom = new Date(this.state.lrwtesttime.from);
    const lrwTo = new Date(this.state.lrwtesttime.to);

    generateIcal([
      createEventParam(speakingTestTimeFrom, speakingTestTimeTo, 'Speaking test', 'Speaking test description'),
      createEventParam(lrwFrom, lrwTo, 'Listening, Reading and Writing test', 'Listening, Reading and Writing test description')
    ]);
  }

  completeClick() {
    if (this.isProfileCompleted) {
      this.store.dispatch(setOriginalApplication({ application: this.currentApplication }));
      this.store.dispatch(setGoToApplUpdate({ goToApplUpdate: true }));
    }
    const navigateToUrl = this.isProfileCompleted ? 'my-tests' : 'onboarding';
    this.router.navigate([navigateToUrl]);
  }

  openOfflineInstructions() {
    testCentreCode$.pipe(map(code => {
      return code ?  generateUtmUrl(UtmLinkKeys.BxInstructionUrl, code)  : undefined;
    })).subscribe(link => {
      window.open(link, '_blank');
    });
  }
  pushGAData() {
    const productName = this.getSnakeCaseData(this.productName);
    const payCode = this.getSnakeCaseData(this.payCode);
    const testModule = JSON.parse(localStorage.getItem(GALocalStorageItemKeys.testModule));
    const testFormat = JSON.parse(localStorage.getItem(GALocalStorageItemKeys.testFormat));
    const testCategory = JSON.parse(localStorage.getItem(GALocalStorageItemKeys.testCategory));
    const testLocation = JSON.parse(localStorage.getItem(GALocalStorageItemKeys.testLocation));
    const tm = this.productName.includes('Online') ? 'academic' : this.getSnakeCaseData(testModule);
    const tf = this.productName.includes('Online') ? 'online' : this.getSnakeCaseData(testFormat);
    const tc = this.productName.includes('Online') ? 'ielts' : this.getSnakeCaseData(testCategory);
    const tv = this.productName.includes('Online') ? 'na' : this.getSnakeCaseData(testLocation);
    const tcId = this.getSnakeCaseData(this.application?.bookings[0]?.testCentreCode);
    dataLayer.push({
      event: 'bxPurchase',
      transactionId: this.transactionId,
      transactionAffiliation: '',
      transactionTotal: this.amount,
      transactionTax: this.taxAmount,
      transactionShipping: '',
      transactionProducts: [{
        sku: this.productId,
        name: productName,
        price: this.amount,
        currency: payCode,
        quantity: 1,
        test_module: tm,
        test_delivery_format: tf,
        test_category: tc,
        payment_method: this.paymentMethod,
        test_venue: tv,
        test_centre_id: tcId
      }],
      ecommerce: {
        currency: payCode,
        value: this.amount,
        tax: this.taxAmount,
        shipping: '',
        affiliation: '',
        transaction_id: this.transactionId,
        coupon: 'na',
        items: [{
          item_name: productName,
          item_id: this.productId,
          price: this.amount,
          item_brand: tm,
          item_category: tf,
          item_category2: tc,
          item_category3: this.paymentMethod,
          item_category4: tv,
          item_category5: tcId,
          item_variant: 'na',
          quantity: '1'
        }]
      }
    });
    dataLayer.push({
      event: 'form_submit_enhance',
      enhanced_conversion_data: {
        email: this.application?.userProfile?.emailAddress
      }
    });
    const itemKeys = Object.values(GALocalStorageItemKeys).filter(v => isNaN(Number(v)));
    itemKeys.forEach(key => localStorage.removeItem(key));
  }
  getSnakeCaseData(data: any): string {
    return (data || '').replace(/\s+/g, '_').toLowerCase();
  }
}
