import {
  Component,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { IPaymentInterface } from '../../../interfaces/payment.interface';
import { CreditCardComponent } from 'shared/components/payment/credit-card/credit-card.component';
@Component({
  selector: 'app-mock-payment-card',
  templateUrl: './mock-credit-card.component.html',
  styleUrls: ['./mock-credit-card.component.scss'],
})
export class MockCreditCardComponent extends CreditCardComponent
  implements OnInit, OnChanges, IPaymentInterface
{

  createBrainTreeClientToken(
    applicationId,
    applicationPaymentId
  ): Observable<any> {
    if (!applicationId) {
      return throwError(new Error('Application is empty'));
    }
    if (!applicationPaymentId) {
      return throwError(new Error('Payment Id is empty'));
    }
    const token$ = of({token: '1f8cb798926f4372bb0dafa7d240475b'})
      .pipe(take(2));
    token$.subscribe((data) => {
      this.setToken(data.token);
    });
    return token$;
  }
  async createBrainTreeUI(token: string) {
    try {
      timer(500).subscribe(() => {
        this.onValidityChange.emit({
          isValid: true,
          errors: [],
          cardErrors: [],
        });
        this.deviceData = '{\"correlation_id\":\"7085416715b5321fc22e9cba96ca7dc4\"}';
        this.hostedFieldsInstance = {
          tokenize: () => Promise.resolve({nonce: 'tokencc_bj_g2ztjs_sznnr9_jk494b_g7vr8t_wb4'}),
          clear: () => { /* empty */ }
        } as any;
        this.onCreatedBraintree.emit();
      });
    } catch (error) {
      return;
    }
  }
}
