import {
  trigger,
  query,
  style,
  group,
  transition,
  animate
} from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition(`welcome=>*,
    selectIdType=>*,
    uploadId=>*,
    detailId=>*,
    addressDetail=>*,
    welcome=>void,
    profile=>*`, [
      query(':enter, :leave', [
        style({
          width: '100%',
        })
      ], { optional: true }),
      query(':enter', [
        style({
          position: 'relative',
          right: '100%',
          zIndex: 1
        })
      ], { optional: true }),
      query(':leave', [
        style({
          position: 'absolute',
          opacity: 1,
        })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('200ms', style({ opacity: 0 }))
        ], { optional: true }),
        query(':enter', [
          animate('400ms', style({ right: '0' }))
        ], { optional: true })
      ]),
    ]),
    transition(`*=>welcome,
      *=>selectIdType,
      *=>uploadId,
      *=>detailId,
      *=>addressDetail,
      *=>profile`, [
      query(':enter, :leave', [
        style({
          width: '100%',
        })
      ], { optional: true }),
      query(':leave', [
        style({
          position: 'relative',
          right: '0%',
          zIndex: 1
        })
      ], { optional: true }),
      query(':enter', [
        style({
          position: 'absolute',
          opacity: 0,
        })
      ], { optional: true }),
      group([
        query(':enter', [
          animate('600ms', style({ opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
          animate('600ms', style({ right: '100%' }))
        ], { optional: true })
      ]),
    ], { params: { optional: true } }),
  ]);
