import { Component, ElementRef, Input, Renderer2, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CountryISO } from 'ngx-intl-tel-input';
import { FormValidatorService } from 'shared/services/form-validator.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnChanges {
  @Input() showClearButton = false;
  @Input() showRequiredError = true;
  @Input() showPasswordIcon = false;
  @Input() enablePlaceholder = true;
  @Input() dropSpecialCharacters = false;
  @Input() mask = null;
  @Input() type = null;
  @Input() tooltip = null;
  @Input() errorCMessage = null;
  @Input() showError = false;
  @Input() required = false;
  @Input() placeHolder = null;
  @Input() form: UntypedFormGroup = null;
  @Input() fcn = null;
  @Input() inputDisabled = false;
  @Input() AcceptSpace = true;
  @Input() maxLength = null;
  @Input() firstErrorOnly = false;
  @Input() inOneLine = false;
  @Input() showTick = true;
  @Input() validShowTick = false;
  @Input() customMessage = {};
  @Input() id = '';
  CountryISO = CountryISO;
  // tslint:disable-next-line: no-output-native
  @Output() changedata: EventEmitter<string> = new EventEmitter();
  @Input() title = null;
  @ViewChild('tooltipBox') tooltipBox: ElementRef;
  @Input() onRender = (input: ElementRef) => { /* empty */ };
  @Input() onClear = () => { /* empty */ };
  @ViewChild('inputContainer') set inputContainer(ref: ElementRef<HTMLInputElement>) {
    this.onRender(ref);
  }
  constructor(
    private renderer: Renderer2,
    private formValidatorService: FormValidatorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      setTimeout(() => {
        this.showValidationTick((changes.form.currentValue as UntypedFormGroup)?.get(this.fcn)?.value);
      }, 1);
    }
  }

  checkAcceptSpace() {
    if (this.AcceptSpace) {
      return true;
    } else {
      const isWhitespace = this.form.get(this.fcn).value.trim().length === 0;
      if (isWhitespace) {
        return false;
      } else {
        return true;
      }
    }
  }
  showTooltip() {
    this.renderer.addClass(this.tooltipBox.nativeElement, 'showTooltip');
  }
  hideTooltip() {
    this.renderer.removeClass(this.tooltipBox.nativeElement, 'showTooltip');
  }
  onChange(event: any) {
    try {
      const value = event.currentTarget.value;
      this.changedata.emit(value ? value : '');
    } catch (error) {
      this.changedata.emit('');
    }
  }
  setNudge() {
    this.formValidatorService.setNudge(this.renderer, this.inputContainer.nativeElement, this.form, this.fcn);
  }

  showValidationTick(value) {
    if (value?.length > 0) {
      this.validShowTick = true;
    } else {
      this.validShowTick = false;
    }
  }
  hideValidationTick() {
    this.validShowTick = false;
  }
  onlyNumbers(event) {
    const eventData = event.clipboardData ? event.clipboardData : event.dataTransfer;
    const pastedData = eventData.getData('Text');
    if (isNaN(pastedData)) {
      event.preventDefault();
    } else {
      return;
    }
  }
}
