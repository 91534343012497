<div class="flexbox justify-contents-center">
  <div class="error-message">
    <i class="material-icons">
      error
    </i>
    <h1>404 Page Not Found</h1>
    <h2>You have reached a page URL that cannot be found.</h2>
    <button class="btn btn-block btn-filled" routerLink="/">Go to Home page</button>
  </div>
</div>
