<div class="flexbox flex-column content align-items-center align-items-lg-start pb-2" appApplyTabIndexes>
  <h2 class="content-title text-lg-start text-center"> 
    How Your ID Document should look
  </h2>
  <div class="flexbox flex-column align-items-center align-items-lg-start">
    <div class="uploadBoxWrapper">
      <div class="actions flexbox flex-column">
        <div class="uploadBox">
          <div class="text-center">
            <img src="./../../../../assets/images/passport_sample.png" class="passportImg" alt="passport"/>
          </div>
      </div>
    </div>
  </div>
    <app-identity-document-upload-form class="identity-document-upload-container" [imageUrl]="modifiedS3Url(imageUrl)"
      (uploaded)="uploadedImage($event)" (deleted)="deletedImage()" [renderHeading]="true">
    </app-identity-document-upload-form>
    <app-two-button (acceptClick)="onNextClick()" (rejectClick)="saveAndExitClick()"
      [enableAcceptClick]="!!state.imageUrl" defaultActiveButton="accept" acceptText="Next" [showReject]="false"
      rejectText="Save and Exit">
    </app-two-button>
  </div>
</div>
  <app-confirm-modal #saveAndExitModal title="Are you sure you wish to exit?"
    imageUrl="../../../../assets/images/Girl green organic shape.png" defaultActiveButton="reject"></app-confirm-modal>
