import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function titleGenderValidator(genders: any[]): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
        if (formGroup && genders) {
            const titleCtrl = formGroup.controls['title'];
            const genderCtrl = formGroup.controls['gender'];
            const maleId = genders?.find(g => g?.name?.toLowerCase() === 'male')?.id;
            const femaleId = genders?.find(g => g?.name?.toLowerCase() === 'female')?.id;
            const title = typeof titleCtrl?.value === 'string' ? titleCtrl?.value : titleCtrl?.value?.Id;
            const gender = typeof genderCtrl?.value === 'string' ? genderCtrl?.value : genderCtrl?.value?.Id;
            const maleTitleMismatch = ['MR'].includes(title) && gender !== maleId;
            const femaleTitleMismatch = ['MRS', 'MISS'].includes(title) && gender !== femaleId;
            if ((title && gender) && (maleTitleMismatch || femaleTitleMismatch)) {
                titleCtrl.setErrors({ titleGenderMismatch: true });
                genderCtrl.setErrors({ titleGenderMismatch: true });
                return { titleGenderMismatch: true };
            } else {
                if (title) { titleCtrl.setErrors(null); }
                if (gender) { genderCtrl.setErrors(null); }
                return null;
            }
        }
    };
}
