import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-status',
  templateUrl: './upload-status.component.html'
})
export class UploadStatusComponent {
  @Input() percent: number;
  @Output() abortUpload = new EventEmitter<void>();
}
