<ng-container *ngIf="!isNewJourney">
<div *ngIf="!isLoading">
  <div *ngIf="isPaymentMethod">
    <h2 class="content-title w-65">HSBC Omni payment options</h2>
  </div>
  <div *ngIf="!isPaymentMethod">
    <h2 class="content-title w-65">Payment options unavailable</h2>
  </div>
  <div class="card-wrapper d-flex" *ngIf="isPaymentMethod">
    <div class="w-100 mb-3 position-relative">
      <ul class="nav nav-tabs flex-column justify-content-center align-items-start">
        <li *ngIf="cardTitle"
          class="nav-item d-flex align-items-start justify-contnet-center flex-column m-2 rounded w-100 cursor-pointer"
          (click)="onCardClick('CREDIT_CARD')" (keyup)="onCardClick('CREDIT_CARD')">
          <div class="nav-link d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <span class="fa fa-credit-card"></span>
              <i class="fa fa-credit-card" class="card-icon"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body">{{cardTitle}}</div>

            </div>
          </div>
        </li>
        <li *ngIf="alipayTitle"
          class="nav-item d-flex align-items-start justify-contnet-center flex-column m-2 rounded w-100 cursor-pointer"
          (click)="onCardClick('ALIPAY')" (keyup)="onCardClick('ALIPAY')">
          <div class="nav-link d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <span class="fa fa-credit-card"></span>
              <i class="fa fa-credit-card" class="card-icon"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body">{{alipayTitle}}</div>

            </div>
          </div>
        </li>
        <li *ngIf="wechatpayTitle"
          class="nav-item d-flex align-items-start justify-contnet-center flex-column m-2 rounded w-100 cursor-pointer"
          (click)="onCardClick('WECHATPAY')" (keyup)="onCardClick('WECHATPAY')">
          <div class="nav-link d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <span class="fa fa-credit-card"></span>
              <i class="fa fa-credit-card" class="card-icon"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body">{{wechatpayTitle}}</div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="card" *ngIf="isLoading">
  <div class="card-body">
    <div class="d-flex flex-column justify-content-center align-items-start pt-1">
      <div class="sub-copy ps-1 pb-2 me-3 text-muted">
        You will be redirected to HSBC Omni to complete your order.
      </div>
    </div>
  </div>
</div>
</ng-container>
<div class="d-none" *ngIf="isLoading">
  <div id="form-container"></div>
</div>