import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-status-icons',
  templateUrl: './status-icons.component.html',
  styleUrls: ['./status-icons.component.scss']
})
export class StatusIconsComponent {
  @Input() status: string;
  @Input() showTitle = true;
  public readonly mapper: {
    [key: string]: string
  } = {
      IN_PROGRESS: 'autorenew',
      DELIVERED: 'checked',
      DELIVERY_FAILED: 'error_outline',
      DELIVERY_PENDING: 'pending',
      UNDELIVERED: 'pending',
      ACKNOWLEDGED: 'fact_check',
      // WITHDRAWN: 'comments_disabled',
      FAILED: 'error_outline'
    };
}
