import { uniqueId } from 'lodash';
import { Storage } from 'aws-amplify';
import compress from 'browser-image-compression';

export type ImageTransformType = 'mirror' | 'rotate-left' | 'rotate-right';

export function isPDF(file: File) {
  return !!['pdf'].filter((f) => file.type.split('/')[1] === f).length;
}
export function isImage(file: File) {
  return !!['png', 'jpg', 'jpeg'].filter((f) => file.type.split('/')[1] === f)
    .length;
}

export function validateFile(file: File, maxFileLength: number): { valid: boolean; error: string; } {
  let valid = true;
  let error: string;
  if (file && file.size > maxFileLength) {
    error = $localize`maximum file length is ${maxFileLength / 1024 / 1024
    }MB`;
    valid = false;
  }
  if (file && !isImage(file)) {
    error = $localize`file types accepted are JPG and PNG`;
    valid = false;
  }
  return { valid, error };
}

export async function detectWebcam(): Promise<boolean> {
  const md = navigator.mediaDevices;
  return new Promise((resolve, reject) => {
    if (!md || !md.enumerateDevices) {
      resolve(false);
    } else {
      md.enumerateDevices().then((devices) => {
        resolve(devices.some((device) => 'videoinput' === device.kind));
      });
    }
  });
}

export function convertUrlToFile(url: string): Promise<File> {
  const uuid = uniqueId('TEST_ID_');
  return new Promise((resolve) => {
    fetch(url).then(async (response) => {
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();
      const file = new File([blob], uuid, { type: contentType });
      resolve(file);
      // access file here
    });
  });
}

export async function getPhoto(key: string): Promise<string> {
  return (await Storage.get(key)) as string;
}

export async function getVersion(key: string) {
  const arr = await Storage.get(key, { download: true }) as any;
  return arr.VersionId;
}

export function fetchExtension(url: string) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export async function compressFile(file: File, maxFileLength) {
  if (isPDF(file)) {
    return file;
  }
  const options = {
    maxSizeMB: maxFileLength / 1024 / 1024, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920,
  };
  try {
    return await compress(file, options);
  } catch (error) {
    return null;
  }
}

