<form [formGroup]="form">
  <ng-content select="[topFields]"></ng-content>
  <app-custom-input [fcn]="'firstName'" [form]="form" id='firstname' i18n-placeholder placeHolder="First name" [showError]="true"
    [required]="true" [title]='showTitle ? "First name" : ""' [showTick]="!disableFirstName"
    (paste)="onlyAlphaNumeric($event)" (drop)="onlyAlphaNumeric($event)">
  </app-custom-input>
  <app-custom-input [fcn]="'lastName'" [form]="form" id='lastName' i18n-placeholder placeHolder="Family name"
    [showError]="true" [title]='showTitle ? "Family name" : ""'
    (paste)="onlyAlphaNumeric($event)" (drop)="onlyAlphaNumeric($event)">
  </app-custom-input>
  <app-custom-input [fcn]="'email'" [form]="form" id='email' i18n-placeholder placeHolder="Email address"
    [required]="true" type="email" [validShowTick]="true" [customMessage]="customMessages.email"
    [title]='showTitle ? "Email address" : ""'>
  </app-custom-input>
  <ng-container *ngIf="reverseMobileFieldOrder; else reversedTemplate">
    <ng-container *ngTemplateOutlet="dobTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
  </ng-container>
  <ng-template #reversedTemplate>
    <ng-container *ngTemplateOutlet="mobileTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="dobTemplate"></ng-container>
  </ng-template>
  <ng-content select="[bottomFields]"></ng-content>
</form>
<ng-template #mobileTemplate>
  <app-custom-input [fcn]="'mobileNumber'" [form]="form" id='mobile' i18n-placeholder [required]="true" [type]="getAutomationTestToggle() ? null : 'phone'"
    i18n-tooltip tooltip="Your mobile number" [errorCMessage]="customMessages.mobileNumber" [title]='showTitle ? "Mobile" : ""'
    [selectedCountryISO]="selectedcountryISO">
  </app-custom-input>
</ng-template>
<ng-template #dobTemplate>
  <app-custom-input [fcn]="'birthDate'" [form]="form" id='birthDate' i18n-placeholder
    placeHolder="Date of Birth DD / MM / YYYY" [required]="true" [tooltip]="disableDateOfBirth?null:getBirthDateTooltip()" type="birthDate" [showTick]="!disableDateOfBirth"
    mask="d0/M0/0000" (change)="onChangeBirthDate()" [customMessage]='customMessages.birthDate' [title]='showTitle ? "Date of Birth" : ""'>
  </app-custom-input>
</ng-template>
