import dateFormat from 'dateformat';

export const formatDateRange = (fromDate: string, toDate?: string) => {
  let result = '';
  if (fromDate && toDate === undefined) {
    const tempDate = new Date(fromDate);
    const month = tempDate.toLocaleString('en', {month: 'long'});
    result = dateFormat(fromDate, 'd') + ' ' + month + ' ' + dateFormat(fromDate, 'yyyy');
  }
  if (fromDate && toDate && (dateFormat(fromDate, 'm') === dateFormat(toDate, 'm')) && (dateFormat(fromDate, 'yyyy') === dateFormat(toDate, 'yyyy'))) {
    const tempToDate = new Date(toDate);
    const tempFromDate = new Date(fromDate);
    const toMonth = tempToDate.toLocaleString('en', {month: 'long'});
    const fromMonth = tempFromDate.toLocaleString('en', {month: 'long'});
    result = dateFormat(fromDate, 'd') + ' ' + fromMonth + ' ' + '-' +
      ' ' + dateFormat(toDate, 'd') + ' ' + toMonth + ' ' + dateFormat(toDate, 'yyyy');
  } else if (fromDate && toDate &&
    (
      (dateFormat(fromDate, 'm') !== dateFormat(toDate, 'm')) ||
      (dateFormat(fromDate, 'yyyy') !== dateFormat(toDate, 'yyyy'))
    )) {
    const tempFromDate = new Date(fromDate);
    const fromMonth = tempFromDate.toLocaleString('en', {month: 'long'});
    const tempToDate = new Date(toDate);
    const toMonth = tempToDate.toLocaleString('en', {month: 'long'});
    result = dateFormat(fromDate, 'd') + ' ' + fromMonth + ' ' + dateFormat(fromDate, 'yyyy') + ' ' + '-' + ' ' + dateFormat(toDate, 'd') + ' ' + toMonth + ' ' + dateFormat(toDate, 'yyyy');
  }

  return result;
};
