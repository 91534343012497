<div class="flexbox flex-column content align-items-center  align-items-lg-start" appApplyTabIndexes>
  <p class="mb-0 ms-1 title" i18n>
    Mandatory profile information:
  </p>
  <div class="form-wrapper mt-2 mb-3 w-75 p-1">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <app-custom-input [fcn]="'mobileNumber'" [form]="form" id='mobile' i18n-placeholder [required]="true"
            [type]="getAutomationTestToggle() ? null : 'phone'" i18n-tooltip tooltip="Your mobile number"
            [errorCMessage]="customMessages.mobileNumber" [title]='showTitle ? "Mobile" : ""'
            [selectedCountryISO]="selectedCountryISO">
          </app-custom-input>
        </div>
        <div class="col-lg-6 col-md-12">
          <app-custom-input [fcn]="'dateOfBirth'" [form]="form" id='birthDate' i18n-placeholder
            placeHolder="Date of Birth DD / MM / YYYY" [required]="!disableDateOfBirth"
            [tooltip]="disableDateOfBirth?null:getBirthDateTooltip()" type="birthDate" [showTick]="!disableDateOfBirth"
            mask="d0/M0/0000" (change)="onChangeBirthDate()" [customMessage]='customMessages.birthDate'
            [title]='showTitle ? "Date of Birth" : ""' [inputDisabled]="disableDateOfBirth">
          </app-custom-input>
        </div>
      </div>
    </form>
  </div>

  <p class="mb-0 ms-1 title" i18n>
    Tell us a little more about yourself to help us improve the test experience.
  </p>
  <div class="form-wrapper mt-2 mb-3 w-75 p-1">
    <app-survey-form [form]="form" [countryList]="countryList" [genderList]="genderList"
      [occupationSectorList]="occupationSectorList" [occupationLevelList]="occupationLevelList"
      [educationLevelList]="educationLevelList" [testReasonList]="testReasonList" [languages]="languages"
      [yearsStudyingEnglishList]="yearsStudy" [titleList]="titleList" (selectChange)="selectChange($event)"
      [hasTitle]="true" [hasGender]="true">
    </app-survey-form>
    <div class="mt-3">
      <app-two-button (acceptClick)="nextClick()" (rejectClick)="saveAndExitClick()" [enableAcceptClick]="form.valid"
        defaultActiveButton="accept" acceptText="Save and continue" rejectText="Save and Exit" [showReject]="false">
      </app-two-button>
    </div>
  </div>

</div>
<app-confirm-modal #saveAndExitModal title="Are you sure you wish to exit?"
  imageUrl="../../../../assets/images/Girl green organic shape.png" defaultActiveButton="reject"></app-confirm-modal>