import { Component, Input, HostBinding, Output, OnInit } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-field-errors',
  template: `
  <ul [id]='elementId' class='list-unstyled'>
    <li class='error-item custom-validation-error' *ngFor='let error of listOfErrors()'><i aria-hidden='true' class='fa fa-warning'></i> {{error}}</li>
  </ul>
 `,
  styleUrls: ['./field-errors.component.scss']
})
export class FieldErrorsComponent implements OnInit {

  private static readonly errorMessages = {
    required: () => 'This field is required',
    email: () => 'This field must be email',
    minlength: (params) => 'The min number of characters is ' + params.requiredLength,
    maxlength: (params) => 'The max allowed number of characters is ' + params.requiredLength,
    min: (params) => 'The minimum value is ' + params.min,
    max: (params) => 'The maximum value is ' + params.max,
    greaterThanEqual: (params) => 'The value must be greater than or equal to ' + params.target,
    lessThanEqual: (params) => 'The value must be less than or equal to ' + params.target,
    pattern: (params) => 'This field is not valid',
    years: (params) => params.message,
    uniqueName: (params) => params.message,
    telephoneNumber: (params) => params.message,
    serverError: (params) => params.message,
    ngbDate: (params) => params.invalid ? 'Date is invalid' : params.toString(),
    date: () => 'Invalid date',
    under16YrsOld: () => 'Under 16 years old',
    validatePhoneNumber: (param) => !param.valid ? 'Mobile number is not valid' : '',
    mustMatch: () => `Passwords do not match`,
    mustNotMatch: () => `New password must not be the same as Current password`,
    invalidExpireDate: () => `Expiration date should be in future.`,
    noSpace: () => `A valid value is required`,
    inValid: () => 'This field is not valid',
    noSpaceStartEnd: () => `This field should not start or end with space or special characters`,
    nameInvalid: () => `Invalid Value.`,
    singleHyphen: () => `A single hyphen is accepted only between characters.`,
    firstRLastNameRequired: () => `Either First Name or Last Name is required`,
    confirmEmailMatch: () => `Email and Confirm Email do not match.`,
    titleGenderMismatch: () => `Gender and Title doesn't match`,
    reviewRequired: () => `Review and update required`,
    dateLengthMatch: () => `Invalid format, Please enter DD/MM/YYYY format`,
    invalidLogin:()=>`Incorrect password.`,
    /** TODO: how do we display error message for date picker */
  };

  // @HostBinding('class.invalid-feedback') get showErrors() {return this.shouldShowErrors();}
  @Input() showRequiredError = true;
  @Input() private control: AbstractControlDirective | AbstractControl;
  @Input() componentId: string;
  @Input() firstErrorOnly = false;
  @HostBinding('class.invalid-feedback') invalidClass = true;
  @HostBinding('class.idp-bx-error-container') errorClass = true;
  @Input() customMessage = {};


  @Output()
  errors: string[];

  elementId: string;

  ngOnInit(): void {
    this.elementId = 'error-' + this.componentId;
  }

  listOfErrors(): string[] {
    if (!this?.control?.errors) {
      return [];
    }
    if (!this.showRequiredError && this.control) {
      delete this.control.errors.pattern;
    }
    if (!this.firstErrorOnly) {
      return Object.keys(this.control.errors)
        .map(field => this.getMessage(field, this.control.errors[field]));
    } else {
      const errors = Object.keys(this.control.errors)
        .map(field => this.getMessage(field, this.control.errors[field]));
      if (Array.isArray(errors) && errors.length) {
        return [errors[0]];
      }
      return errors;
    }
  }

  private getMessage(type: string, params: any) {
    if (typeof this.customMessage[type] === 'function') {
      return this.customMessage[type](params);
    }
    try {
      return FieldErrorsComponent.errorMessages[type](params);
    } catch (error) {
      return FieldErrorsComponent.errorMessages.inValid();
    }
  }
}
