import { ITimelineItem } from '../../shared/models/components/time-line';

function guidGenerator() {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}
export default [
  {
    id: guidGenerator(),
    text: '1',
    description: 'Complete details',
    key: 'completeDetail',
    clickAble: false,
    order: 0
  },
  {
    id: guidGenerator(),
    text: 'download',
    description: 'Download player',
    classes: ['downloadPlayer'],
    key: 'downloadPlayer',
    clickAble: true,
    order: 1
  },
  {
    id: guidGenerator(),
    text: '3',
    description: 'Speaking test',
    key: 'speakingDetail',
    clickAble: false,
    order: 2
  },
  {
    id: guidGenerator(),
    text: '4',
    description: 'Listening, Reading and Writing test',
    key: 'completeSpeaking',
    clickAble: false,
    order: 3
  }
] as ITimelineItem[];
