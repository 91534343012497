import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IdentificationType, Nationality } from '@idp-education/ors-test-taker-bff-client-v1';
import { ID_CONSTANTS } from 'src/app/pages/onboarding/onboarding.contants';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-identity-details-form',
  templateUrl: './identity-details-form.component.html',
  styleUrls: ['./identity-details-form.component.scss'],
})
export class IdentityDetailsFormComponent implements OnInit {
  @Input() form: UntypedFormGroup = null;
  @Input() nationalityList: Array<Nationality>;
  @Input() set disableIdNumber(value: boolean) {
    if(value) {
      this.disableFields('identityNo');
    }
  }
  @Input() disableCountryOfNationality = false;
  @Input() set disableExpiryDate(value: boolean) {
    if(value) {
      this.disableFields('expiryDate');
    }
  }
  @Input() identityType = 'P';
  @Input() identificationTypes: Array<IdentificationType>;


  ID_CONSTANTS = ID_CONSTANTS;

  customMessages = {
    identityNo: {
      pattern: () => $localize`Special characters are not allowed`,
    },
    expiryDate: {
      pattern: () => $localize`Invalid expiry date`,
      required: () => $localize`Expiry date is required`,
    },
  };


  ngOnInit() {
    this.setExpiryDateMandatory();
  }

  setExpiryDateMandatory() {
    if (!ID_CONSTANTS[this.identityType].isExpiryDateMandatory) {
      this.form.get('expiryDate').clearValidators();
      this.form.get('expiryDate').updateValueAndValidity();
    }
  }

  getNewOptionId(list: any[]): string {
    const option = list.find(
      (i) => (i?.name as string)?.toLowerCase() === 'other'
    );
    return option?.id;
  }

  get currentYer() {
    return DateTime.now().toFormat('yyyy');
  }

  get isValidExpireDate() {
    return !this.form.get('expiryDate').hasError('invalidExpireDate');
  }

  disableFields(formControlName) {
    this.form?.get(formControlName).disable();
  }
}
