<div class="test-info-box d-flex flex-column flex-lg-row p-4">
  <div class="d-flex flex-column flex-grow-1 align-items-stretch">
    <app-test-details-content class="mb-2">
      <ng-container testType>
        <ng-content select="[testType]"></ng-content>
      </ng-container>
      <ng-container testFormat>
        <ng-content select="[testFormat]"></ng-content>
      </ng-container>
      <ng-container testLocationName>
        <ng-content select="[testLocationName]"></ng-content>
      </ng-container>
      <ng-container address>
        <ng-content select="[address]"></ng-content>
      </ng-container>
    </app-test-details-content>
    <div class="d-flex w-100 justify-content-start flex-row align-items-center pb-2">
      <h6 class=" text-darker float-start m-0" i18n>{{ssrComponent}} test</h6>
      <button type="button" *ngIf="!hideEditButton" (click)="onBackButtonClick.emit()"
              class="btn btn-highlight btn-link float-end p-0 ms-2">
        <img src="../../../../assets/images/Icon feather-edit.svg" alt="update">
      </button>
    </div>
    <div class="w-100 d-flex justify-content-center align-items-center">
      <div class="circle-date-container">
        <app-circle-date [date]="testStartUtcDatetime"></app-circle-date>
      </div>
      <div class="test-time d-flex justify-content-start align-items-center flex-grow-1">
        <img src="../../../../assets/images/time-icon-black.svg" class="icon me-2 d-none d-md-inline" alt="icon"/>
        <span>{{testTime}}</span>
        <div class="d-block d-md-none">(</div>
        <i class="fa fa-hourglass-half d-none d-md-inline" aria-hidden="true"></i>
        {{duration}} mins
        <div class="d-block d-md-none">)</div>
      </div>
    </div>
  </div>
</div>
