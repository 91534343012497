<feature-toggle-provider [features]="featureFlags">
  <div class="flexbox flex-column content align-items-center align-items-xxl-start">
    <div class="all-content" *featureToggle="'socialLoginEnabled'">
      <h6 class="content-title" i18n>Use a social account</h6>
      <div class="actions">
        <button class="btn thirdParty-btn" (click)="facebookLogin()" (mouseenter)="onHoverAction('facebook')"
          (mouseleave)="onBlur()">
          <svg *ngIf="button === 'facebook'" fill="#E32836" viewBox="0 0 37 37" width="37px" height="37px"
            style="border-radius: 0 0 3px 3px;">
            <path class="svgPath"
              d="M32.949,2.25H3.954A3.954,3.954,0,0,0,0,6.2V35.2a3.954,3.954,0,0,0,3.954,3.954H15.259V26.607H10.07V20.7h5.189V16.2c0-5.119,3.048-7.947,7.716-7.947a31.439,31.439,0,0,1,4.573.4v5.025H24.973c-2.538,0-3.329,1.575-3.329,3.19V20.7h5.666L26.4,26.607H21.643V39.153H32.949A3.954,3.954,0,0,0,36.9,35.2V6.2A3.954,3.954,0,0,0,32.949,2.25Z" />
          </svg>
          <img src="../../../../assets/images/facebook.svg" alt="" *ngIf="button !== 'facebook'">
          <span class="text" i18n>Facebook</span>
        </button>
        <button class="btn thirdParty-btn" (mouseenter)="onHoverAction('google')" (mouseleave)="onBlur()"
          (click)="googleLogin()">
          <svg *ngIf="button === 'google'" fill="#E32836" viewBox="0 0 37 37" width="36px" height="36px"
            style="position: relative;top:1px">
            <path class="svgPath"
              d="M18.8,15.8v6.323H29.257c-.423,2.712-3.159,7.95-10.458,7.95A11.64,11.64,0,0,1,18.8,6.8a10.378,10.378,0,0,1,7.352,2.841l5-4.822A17.692,17.692,0,0,0,18.8,0a18.439,18.439,0,1,0,0,36.877c10.642,0,17.7-7.482,17.7-18.018a16.8,16.8,0,0,0-.29-3.056Z"
              transform="translate(-0.36)" />
          </svg>
          <img *ngIf="button !== 'google'" src="../../../../assets/images/google.svg" alt="">
          <span class="text" i18n>Google</span>
        </button>
        <button class="btn thirdParty-btn" (click)="AppleLogin()" (mouseenter)="onHoverAction('apple')"
          (mouseleave)="onBlur()">
          <svg *ngIf="button === 'apple'" width="33.483" height="40.312" viewBox="0 0 33.483 40.312">
            <path class="svgPath" id="Icon_awesome-apple" data-name="Icon awesome-apple"
              d="M28.276,23.553a8.418,8.418,0,0,1,4.448-7.632,9.528,9.528,0,0,0-7.535-4.014c-3.158-.252-6.609,1.863-7.873,1.863-1.334,0-4.394-1.773-6.8-1.773C5.556,12.078.281,16,.281,23.985a22.667,22.667,0,0,0,1.281,7.308C2.7,34.6,6.811,42.7,11.1,42.56c2.242-.054,3.825-1.611,6.743-1.611,2.829,0,4.3,1.611,6.8,1.611,4.323-.063,8.042-7.425,9.127-10.737a8.94,8.94,0,0,1-5.489-8.271ZM23.241,8.775A8.546,8.546,0,0,0,25.376,2.25a9.4,9.4,0,0,0-6.04,3.141,8.67,8.67,0,0,0-2.277,6.471c2.322.18,4.439-1.026,6.182-3.087Z"
              transform="translate(-0.281 -2.25)" />
          </svg>
          <img *ngIf="button !== 'apple'" src="../../../../assets/images/apple.svg" alt="">
          <span class="text" style="padding-bottom: 4px" i18n>Apple</span>
        </button>
      </div>
      <h5 class="d-none d-lg-block" i18n>Or use your email</h5>
      <div class="d-block d-lg-none">
        <app-text-middle-line i18n-text text="OR" textClass="separator"></app-text-middle-line>
      </div>
      <form style="min-width: 320px;width: inherit;">
        <div class="">
          <div class="input-group mb-3 flexbox justify-content-center justify-content-lg-start flex-nowrap">
            <input id="signupMail" class="form-control signup-email ms-1" i18n-placeholder placeholder="example@mail.com"
              (keyup)="onEnterEmail($event)" [formControl]="signupMail" />
            <div class="input-group-append">
              <button type="button" (click)="redirectToSignUp()" [disabled]="!signupMail.valid || !signupMail.value"
                class="btn btn-multiAction signin-mobile-btn">
                <span class="text" i18n>Next</span>
              </button>
            </div>
          </div>
          <div id="signupMail d-block" class="invalid-feedback" *ngIf="signupMail.invalid && signupMail.touched" i18n>
            Please enter a valid email
          </div>
        </div>
      </form>
      <h6 class="d-block d-lg-none pe-1 ps-1 flexbox text-center legend-question" i18n>Already have an
        IDP IELTS
        account?</h6>
      <div class="d-block d-lg-none pb-5 pe-1 ps-1 flexbox justify-content-center">
        <a style="margin: auto;" [routerLink]="['/account/signin']" class="btn btn-multiAction">
          <span class="text" i18n>Log in</span>
        </a>
      </div>
    </div>
    <div class="all-content" *featureToggle="'!socialLoginEnabled'">
      <h6 class="content-title" i18n>Sign-up using your email</h6>
      <form style="min-width: 320px;width: inherit;">
        <div class="">
          <div class="input-group mb-3 flexbox justify-content-center justify-content-lg-start flex-nowrap">
            <input id="signupMail" class="form-control signup-email ms-1" i18n-placeholder placeholder="example@mail.com"
              (keyup)="onEnterEmail($event)" [formControl]="signupMail" />
            <div class="input-group-append">
              <button type="button" (click)="redirectToSignUp()" [disabled]="!signupMail.valid || !signupMail.value"
                class="btn btn-multiAction signin-mobile-btn">
                <span class="text" i18n>Next</span>
              </button>
            </div>
          </div>
          <div class="d-block invalid-feedback text-start ps-2" *ngIf="signupMail.invalid && signupMail.touched" i18n>
            Please enter a valid email
          </div>
        </div>
      </form>
      <h6 class="d-block d-lg-none pe-1 ps-1 flexbox text-center legend-question" i18n>Already have an
        IDP IELTS
        account?</h6>
      <div class="d-block d-lg-none pb-5 pe-1 ps-1 flexbox justify-content-center">
        <a style="margin: auto;" [routerLink]="['/account/signin']" class="btn btn-multiAction">
          <span class="text" i18n>Log in</span>
        </a>
      </div>
    </div>
  </div>
</feature-toggle-provider>
