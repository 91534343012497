<form [formGroup]="form" *ngIf="form">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <app-custom-input [fcn]="'identityNo'" [form]="form"
                [placeHolder]="ID_CONSTANTS[identityType].idNumberPlaceholder" i18n-placeholder
                [required]="!disableIdNumber" [title]="ID_CONSTANTS[identityType].idNumber"
                [showTick]="!disableIdNumber" [validShowTick]="true" maxLength="20">
            </app-custom-input>
        </div>
        <div class="col-lg-6 col-md-12">
            <app-dropdown *ngIf="nationalityList" labelKey="name" idKey='id' i18n-placeholder id="nationality"
                [placeholder]="ID_CONSTANTS[identityType].countryPlaceholder" [options]="nationalityList"
                [formControl]="form && form.get('countryOfNationality')"
                [addNewOptionId]="getNewOptionId(nationalityList)" [showTick]="!disableCountryOfNationality" i18n-title
                [title]="ID_CONSTANTS[identityType].country" [isRequired]="!disableCountryOfNationality"
                [inputDisabled]="disableCountryOfNationality">
            </app-dropdown>
        </div>
        <div class="col-lg-6 col-md-12">
            <app-custom-input [fcn]="'expiryDate'" [form]="form" id='expiryDate' [validShowTick]="true" i18n-placeholder
                [placeHolder]="ID_CONSTANTS[identityType].expiryDatePlaceholder"
                [required]="!disableExpiryDate && ID_CONSTANTS[identityType].isExpiryDateMandatory" [showError]="true"
                [showTick]="!disableExpiryDate" type="birthDate" i18n-title
                [title]="ID_CONSTANTS[identityType].expiryDate" mask="d0/M0/0000" >
            </app-custom-input>
        </div>
        <div class="col-lg-6 col-md-12">
            <app-custom-input [fcn]="'issuingAuthority'" [form]="form" i18n-placeholder
                [placeHolder]="ID_CONSTANTS[identityType].issuingAuthority" i18n-title
                [title]="ID_CONSTANTS[identityType].issuingAuthorityPlaceholder" [AcceptSpace]="true"
                [showError]="true">
            </app-custom-input>
        </div>
    </div>
</form>
