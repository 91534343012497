<div class="yCmsContentSlot left-panel">
    <div class="static-content-block privacy_policy">
        <div class="content accrdn-scrolltop" id="accrdnClps">
            <h1>IDP Group Privacy Policy</h1>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-1" aria-expanded="false"
                    aria-controls="collapse-1"><span class="hdr_spn">Introduction</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-1" class="collapse" aria-labelledby="heading-1" data-parent="#accordion">
                    <p>At IDP* we understand that your privacy is important to you. We are committed to meeting your
                        expectations and will comply with all applicable privacy and data protection legislation in
                        relation to your personal information and sensitive information.</p>
                    <p>We also know you love the small print, but here’s a summary of how we protect your privacy:</p>
                    <p>1. We only collect what we need to help you with your request or provide you with information
                        that you ask for, personalising it to your interests.</p>
                    <p>2. We won’t hang on to your information. If we don’t need it, and you don’t want us to keep it,
                        we’ll delete it.</p>
                    <p>3. We want you to understand everything we do with your information, so we’ll try our best to
                        explain with no nonsense.</p>
                    <p>4. No surprises — you decide what and how you hear from us.</p>
                    <p>5. That goes for everyone. We do not pass your information to third parties for marketing
                        purposes without your knowledge.</p>
                    <p>The rest of this policy provides you with more detail of our privacy and personal information
                        handling practices.</p>
                    <p><strong>Who we are</strong></p>
                    <p>IDP is a global organisation that assists individuals to study overseas and provides English
                        language testing around the world. This requires us to collect personal information from you.
                        “We”, “us” and “our” refers to the IDP Group of companies, including IELTS Australia and IDP
                        Connect Limited.</p>
                    <p>Definitions of specific terms referred to in this policy can be found under the heading
                        ‘Glossary’ at the end of this document.</p>
                    <p><strong>Data Controller Contact Details</strong></p>
                    <p>IDP Education Limited Level 8<br>535 Bourke Street<br>MELBOURNE 3000<br>Victoria<br>Australia</p>
                    <p>Contact our Privacy Officer via email to: <a
                            href="mailto:privacyofficer@idp.com">privacyofficer@idp.com</a></p>
                    <p>We will endeavour to respond to you within a reasonable time.</p>
                    <p>IDP is also a joint Data Controller in certain circumstances. Links to the privacy policies of
                        these joint Controllers are found below:</p>
                    <ul>
                        <li>
                            <p>With IDP Connect, in supplying international education information and services at:</p>
                            <p><a href="https://www.idp-connect.com/privacy_notice/"
                                    target="_blank" rel="noopener">www.idp-connect.com/privacy_notice/</a></p>
                        </li>
                        <li>
                            <p>With the other IELTS Owners, for the purposes of delivering the IELTS Test.</p>
                            <p>The British Council’s is found at:</p>
                            <p><a href="http://www.britishcouncil.org/privacy-cookies/data-protection"
                                    target="_blank" rel="noopener">www.britishcouncil.org/privacy-cookies/data-protection</a></p>
                        </li>
                        <li>
                            <p>Cambridge Assessment English’s is found at:</p>
                            <p><a href="http://www.cambridgeenglish.org/footer/data-protection/"
                                    target="_blank" rel="noopener">www.cambridgeenglish.org/footer/data-protection/&nbsp;</a></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-2" aria-expanded="false"
                    aria-controls="collapse-2"><span class="hdr_spn">In your dealings with us for studying
                        overseas</span><span class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-2" class="collapse" aria-labelledby="heading-2" data-parent="#accordion">
                    <p><strong>International Education Information, Advice and Counselling</strong></p>
                    <p>The services we offer to help you study overseas include counselling, application assistance,
                        visa assistance, English language testing and training, student exhibitions and seminars.</p>
                    <p>If you register on our websites (<a rel="noopener" href="http://www.idp.com/" target="_blank">www.idp.com</a> or
                        <a rel="noopener" href="http://www.ieltsessentials.com/" target="_blank">www.ieltsessentials.com</a>) or
                        register to participate in an event we will collect personal information such as your name,
                        email address, contact details, country of residence, nationality, preferred study destination,
                        and when you intend to study.</p>
                    <p>If you enquire about study opportunities directly with an IDP counsellor, or we assist you with
                        your application to study, we may also request additional information such as education
                        qualifications, employment history, occupation, areas of interest and professional memberships,
                        copies of documents as proof of identity – e.g. copies of passport, proof of citizenship or
                        photographs, marital status and how you will fund your studies. We may also request sensitive
                        information about you such as racial or ethnic origin (from information on your country of
                        origin), and/or your health or medical information (e.g. from a medical examination required for
                        your visa application for an Australian visa).</p>
                    <p>Collecting your personal information allows us to:</p>
                    <ul>
                        <li>
                            <p>assist you to pursue the opportunity to undertake studies in an education institution
                                outside your home country;</p>
                        </li>
                        <li>
                            <p>provide you with relevant information on your study options;</p>
                        </li>
                        <li>
                            <p>present you as a candidate to your preferred institutions across all education sectors,
                                which are seeking to recruit international students who want to study in Australia, the
                                United States, the United Kingdom, the Republic of Ireland, Canada or New Zealand;</p>
                        </li>
                        <li>
                            <p>assist you to further your career after completing your studies at an overseas education
                                institution;</p>
                        </li>
                        <li>
                            <p>provide opportunities to participate in projects/programs as consultants;</p>
                        </li>
                        <li>
                            <p>provide opportunities to provide feedback and testimonials on your experience</p>
                        </li>
                        <li>
                            <p>inform and provide students, institutions and other clients of developments in the
                                services we provide (including events and opportunities to participate in
                                projects/programs);</p>
                        </li>
                        <li>
                            <p>conduct research for statistical, analytical and training purposes;</p>
                        </li>
                        <li>
                            <p>manage our internal business operations and events; and</p>
                        </li>
                        <li>
                            <p>respond to your questions.</p>
                        </li>
                    </ul>
                    <p><strong>Student Essentials Services</strong></p>
                    <p>IDP offers services from third parties to help support you in your studies overseas and we call
                        these Student Essentials. These services include Overseas Student Health Cover (OHSC),
                        accommodation arrangements, a 24/7 emergency helpline, referral to guardianship services, visa
                        application services and career skills training.</p>
                    <p>If you purchase a Student Essentials service we may collect personal information such as your
                        name, date of birth, and financial information, and sensitive information such as your health or
                        medical information. We may also collect payment information, however IDP does not store
                        information like credit card details.</p>
                    <p>Collection of your personal information and sensitive information (such as your health or medical
                        information) allows us to:</p>
                    <ul>
                        <li>
                            <p>provide the health insurance provider nominated by you with all relevant information for
                                your OHSC;</p>
                        </li>
                        <li>
                            <p>facilitate your accommodation arrangements with third party providers;</p>
                        </li>
                        <li>
                            <p>contact the relevant organisations, authorities or other third parties in relation to
                                your overseas guardianship application;</p>
                        </li>
                        <li>
                            <p>respond to your query and/or direct your query when you contact the IDP 24/7 Helpline</p>
                        </li>
                        <li>
                            <p>contact the Department of Home Affairs (DHA) with all relevant information required for
                                visa renewal or parent visa application;</p>
                        </li>
                        <li>
                            <p>contact Australian institutions or other organisations on your behalf to facilitate
                                visits, career skills training or internship placements;</p>
                        </li>
                        <li>
                            <p>arrange or provide other products and services to you.</p>
                        </li>
                    </ul>
                    <p><strong>Visa application services for Australian visas</strong></p>
                    <p>We provide visa application services for Australian visas including guiding you through the
                        application process and helping you prepare the necessary documents for your nominated visa
                        application.&nbsp; These visa application services not only cover visa applications required for
                        study, but also post-study work and skilled migration visa applications.</p>
                    <p>Collection of your personal information and sensitive information (such as information from your
                        medical examination) allows us to:</p>
                    <ul>
                        <li>
                            <p>assist you in your application for a student visa in Australia including contacting the
                                Department of Home Affairs (DHA) and other organisations on your behalf to discuss your
                                application;</p>
                        </li>
                        <li>
                            <p>provide the Department of Home Affairs (DHA) with all relevant information required for
                                your visa application;</p>
                        </li>
                        <li>
                            <p>contact the DHA or other organisations on your behalf to discuss or ask questions about
                                your visa application; and</p>
                        </li>
                        <li>
                            <p>keep you updated with the progress of your visa application and advise you of the outcome
                                of your application.</p>
                        </li>
                    </ul>
                    <p>Retention</p>
                    <p>IDP’s vision is to build a global community of connected students from classroom to career.&nbsp;
                        This means we would like to stay in touch for as long as you would like to hear from us, but we
                        will only retain your information as long as is necessary for the purpose it was given to us.
                    </p>
                    <p>As a guide we will keep your personal information for up to 7 years after commencing your studies
                        or your last activity with us so that we can continue to support you through your journey,
                        unless required to retain it for legal or regulatory purposes</p>
                    <p><strong>In your dealings with Hotcourses for studying overseas</strong></p>
                    <p>IDP Connect Limited is an IDP - owned company, and is the principal company and the Data
                        Controller in the IDP Connect Group of companies (IDP Education India Services LLP, Hotcourses
                        Pty Ltd (Australia), Hotcourses Inc (North America) and Complete University Guide).</p>
                    <p>IDP Connect helps students find suitable courses and learning providers both at home and overseas
                        and processes your personal data to help you make informed decisions when selecting courses and
                        learning providers. If you engage with the counselling services on Hotcourses Abroad, IDP
                        Connect's website (<a href="https://www.hotcoursesabroad.com/"
                            target="_blank" rel="noopener">www.hotcoursesabroad.com</a>), your data will be shared with IDP, which
                        operates both virtual and the in-person counselling services. IDP will also share data with IDP
                        Connect to allow you to continue track your enquiry and applications on <a
                            href="https://www.hotcoursesabroad.com/" rel="noopener" target="_blank">www.hotcoursesabroad.com.</a></p>
                    <p>The Hotcourses Abroad Privacy Notice provides more details on how personal data is managed at:<a
                            href="https://www.hotcoursesabroad.com/study/privacy.html" rel="noopener"
                            target="_blank">https://www.hotcoursesabroad.com/study/privacy.html</a></p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-3" aria-expanded="false"
                    aria-controls="collapse-3"><span class="hdr_spn">In your dealings with us for IELTS</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-3" class="collapse" aria-labelledby="heading-3" data-parent="#accordion">
                    <p>IELTS Australia is a subsidiary of IDP and is a joint Data Controller with the other IELTS Owners
                        (the British Council and Cambridge English Language Assessment) for the purposes of delivering
                        the IELTS test..</p>
                    <p>We deliver and administer the IELTS test at test centres around the world. IELTS test centres
                        exist in more than 140 countries, across Europe, Asia, Australia and New Zealand, North America,
                        South America, Africa and the Middle East.&nbsp; IELTS Australia operates and manages some of
                        these IELTS test centres and the British Council operates and manages others, to which you may
                        be referred.</p>
                    <p>The IELTS Australia privacy policy provides more details on the specific policy for IELTS: <a
                            href="https://www.ieltsessentials.com/global/footerlinks/privacypolicy" rel="noopener"
                            target="_blank">www.ieltsessentials.com/global/footerlinks/privacypolicy&nbsp;</a></p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-4" aria-expanded="false"
                    aria-controls="collapse-4"><span class="hdr_spn">In your dealings with us for conferences and
                        events</span><span class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-4" class="collapse" aria-labelledby="heading-4" data-parent="#accordion">
                    <p>IDP organises various events and conferences related to international education and English
                        language teaching and assessment which may be managed or presented by IDP or the IELTS Owners,
                        whether in our own right, or under contract for another organisation. The information we collect
                        is relevant to your attendance and participation.</p>
                    <p>In registering for, or participating in, an event we collect your personal details such as name,
                        postal and email addresses, date of birth, contact details, residency status, education
                        qualifications, employment history, occupation, areas of interest and professional memberships.
                    </p>
                    <p>Collection of your personal information allows us to:</p>
                    <ul>
                        <li>
                            <p>register you to attend the event;</p>
                        </li>
                        <li>
                            <p>provide services to you related to the event (e.g. to list you as a speaker in the
                                program or list you as an exhibitor or sponsor in our exhibitor directory)</p>
                        </li>
                        <li>
                            <p>respond to enquiries from you pre and post the event;</p>
                        </li>
                        <li>
                            <p>keep you informed about the event you registered for and/or future events</p>
                        </li>
                        <li>
                            <p>manage our operations for the event, including the fulfilment of any legal requirements;
                            </p>
                        </li>
                        <li>
                            <p>collect customer views on our services with a view to developing new and/or improved
                                services;</p>
                        </li>
                        <li>
                            <p>obtain website usage data to monitor our website performance; and</p>
                        </li>
                        <li>
                            <p>if applicable, process payment for registration or other event-related services/products
                                e.g. accommodation payments/deposits, additional social event tickets, etc).</p>
                        </li>
                    </ul>
                    <p><strong>AIEC</strong></p>
                    <p>IDP is the owner of the Australian International Education Conference (AIEC). The conference is
                        the major opportunity each year for international education practitioners, teaching staff,
                        researchers, policy makers and other stakeholders to learn about major industry trends and to
                        network with Australian and international colleagues.</p>
                    <p>For privacy details specific to AIEC, please visit:<a href="https://aiec.idp.com/privacy"
                            target="_blank" rel="noopener">www.aiec.idp.com/privacy</a></p>
                    <p><strong>CamTESOL Conference</strong></p>
                    <p>The annual CamTESOL Conference Series was established by IDP Education (Cambodia) in 2005.
                        CamTESOL is a conference for professionals in the field of English Language Teaching and related
                        issues. CamTESOL has now become the premier professional development conference in the Asia
                        Pacific region with an average audience of 1,700 attending annually from more than 30 countries
                        with several components: educational and cultural tours, Regional ELT Research Symposium, and
                        the main CamTESOL conference.</p>
                    <p>For more information: <a href="https://camtesol.org/Camtesol/Home/"
                            target="_blank" rel="noopener">www.CamTESOL.org</a></p>
                    <p><strong>Global Alumni Convention (GAC)</strong></p>
                    <p>The annual Global Alumni Convention series was established by IDP Education (Cambodia) in 2015.
                        The GAC has three components: The Global Education and Career Expo, the Global Education and
                        Business Forum and the Global Alumni Reunion Dinner. The GAC hosts education institutions from
                        Australia, New Zealand, the UK, the US, Canada and from around the globe showcasing world class
                        education options, featuring panel discussions by leaders and experts from governments,
                        embassies, civil society and industries, and providing a unique networking platform for alumni
                        and others for business partnerships, policy dialogues, and career opportunities.</p>
                    <p>For more information:<a rel="noopener" href="https://cambodiaga.org/" target="_blank">www.CambodiaGA.org</a></p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-5" aria-expanded="false"
                    aria-controls="collapse-5"><span class="hdr_spn">In your dealings with us for English Language
                        Education or Schools</span><span class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-5" class="collapse" aria-labelledby="heading-5" data-parent="#accordion">
                    <p>IDP is a leading provider of English language teaching (ELT) in South East Asia with 9 campuses
                        across Cambodia, and Vietnam delivering English programs ranging from short IELTS preparation
                        courses to extensive business English programs.</p>
                    <p>IDP Education’s English language schools are called:</p>
                    <ul>
                        <li>
                            <p>ACET in Vietnam; and</p>
                        </li>
                        <li>
                            <p>ACE in Cambodia.</p>
                        </li>
                    </ul>
                    <p>You can refer to the websites of our schools here.</p>
                    <p><a href="https://acet.edu.vn/en/">https://acet.edu.vn/en/</a></p>
                    <p><a rel="noopener" href="https://acecambodia.org/" target="_blank">https://acecambodia.org/</a></p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-6" aria-expanded="false"
                    aria-controls="collapse-6"><span class="hdr_spn">In your dealings with us for international
                        education market research and insights</span><span class="glyphicon glyphicon-menu-up"> </span>
                </h3>
                <div id="collapse-6" class="collapse" aria-labelledby="heading-6" data-parent="#accordion">
                    <p><strong>International education market research and insights</strong></p>
                    <p>As part of our commitment to the international education and English language testing sectors,
                        IDP Education conducts market research to obtain feedback from customers, potential customers
                        and other stakeholders.</p>
                    <p>The information collected in a research context may be used to improve services, develop new
                        products or services, gauge customer satisfaction, understand decision making behaviours, or to
                        test reaction to products, services or communications.</p>
                    <p>If you have an existing relationship with IDP we may contact you via email to invite you to
                        participate in our research. If you are not a customer, you may also be invited to participate
                        in research via social media or similar channels.</p>
                    <p>We collect personal data from research participants to allow us to provide them an incentive or
                        if they are successful in a prize draw (as per prize draw terms &amp; conditions).</p>
                    <p><em>Retention</em></p>
                    <p>We do not retain personally identifiable information for the purposes of market research. We may
                        retain contact information we collect for prize draws for up to 6 months, as per prize draw
                        terms and conditions, to allow for a re-draw if required.</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-7" aria-expanded="false"
                    aria-controls="collapse-7"><span class="hdr_spn">Other dealings with IDP</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-7" class="collapse" aria-labelledby="heading-7" data-parent="#accordion">
                    <p>Other ways you may deal with IDP include:</p>
                    <ul>
                        <li>
                            <p>registering your interest in being engaged as a consultant or employee</p>
                        </li>
                        <li>
                            <p>registering with a service offered by IDP (e.g. the Graduate Recruitment Service); or</p>
                        </li>
                        <li>
                            <p>as a representative of an educational institution.</p>
                        </li>
                    </ul>
                    <p>We will provide specific notice at the point your information is collected. Any unsolicited
                        personal information will be securely destroyed.</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-8" aria-expanded="false"
                    aria-controls="collapse-8"><span class="hdr_spn">How we collect personal information</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-8" class="collapse" aria-labelledby="heading-8" data-parent="#accordion">
                    <p>We will only collect personal information by lawful and fair means. Personal information may be
                        collected directly from you or your authorised representative, or may be collected from a third
                        party such as a licensee or representative authorised by us to provide services to you. You may
                        supply your personal information to us when communicating with us via social networks and other
                        online channels, e.g. through your Facebook or Twitter identity.</p>
                    <p>We do not collect personal and sensitive information unless the information is reasonably
                        necessary for our business functions or activities. We will obtain your consent before
                        collecting any sensitive information.</p>
                    <p>When you deal with us, you will need to identify yourself in order for us to provide our services
                        to you, as such we do not accept the use of pseudonyms.</p>
                    <p>If you access our websites, we may collect additional personal information about you in the form
                        of your IP address and/or domain name. For more details please see our Website Cookie Policy for
                        further information.</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-9" aria-expanded="false"
                    aria-controls="collapse-9"><span class="hdr_spn">The way in which we use and disclose personal
                        information</span><span class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-9" class="collapse" aria-labelledby="heading-9" data-parent="#accordion">
                    <p>We will use or disclose your personal information only for the purposes for which it was
                        collected. We will use or disclose your information for another purpose only if you have
                        consented, or if you would reasonably expect us to do so or as required by law.</p>
                    <p>We may use your personal information for the purposes of our own direct marketing, however we
                        will ensure you have the ability to opt out of future such communications.</p>
                    <p>We use Facebook Custom and Lookalike Audiences to deliver advertisements to Facebook users based
                        on email addresses, phone number and visits to our websites. We may use information we collect
                        from you to display advertisements to our target audience of users.</p>
                    <h3>Disclosure to others</h3>
                    <p>We may disclose your personal information to overseas recipients such as education institutions
                        located outside your home country, overseas visa agencies, IELTS test centres, professional
                        bodies and commercial organisations, or regulatory authorities when you have sought our
                        assistance to apply for study at those institutions or where disclosure is required for
                        verification purposes.&nbsp; As such, the location of these overseas recipients would depend on
                        the specific institutions to which you apply or where such verification is required.</p>
                    <p>Information disclosed by us to overseas recipients for the purposes of providing IELTS testing
                        services to you may be transferred offshore to the other IELTS Owners (British Council and
                        Cambridge). These recipients are United Kingdom based entities, subject to the Data Protection
                        Act (UK).&nbsp; Where we transfer data to offshore recipients, the data is subject to use
                        restrictions and safeguards against unauthorised access.</p>
                    <p>For events or conferences, we may disclose, with your consent, some of the information that is
                        collected via the online registration form such as your name or your email address to sponsors
                        for marketing purposes.</p>
                    <p>Some IDP IT systems and data are hosted on servers in countries outside Australia or the UK and
                        we may send your information to one of those servers as part of our business.</p>
                    <p>IDP may hold or transfer the data we collect in or to the following countries (in addition to
                        your home country):</p>
                    <ul>
                        <li>
                            <p>Australia</p>
                        </li>
                        <li>
                            <p>UK</p>
                        </li>
                        <li>
                            <p>Germany</p>
                        </li>
                        <li>
                            <p>India</p>
                        </li>
                        <li>
                            <p>Ireland</p>
                        </li>
                        <li>
                            <p>Singapore</p>
                        </li>
                        <li>
                            <p>Hong Kong</p>
                        </li>
                        <li>
                            <p>United States</p>
                        </li>
                    </ul>
                    <p>This information is stored and accessed only as required to provide our services to you. Where we
                        transfer data to offshore servers, the data is subject to use restrictions and safeguards
                        against unauthorised access.</p>
                    <p>The information disclosed to overseas recipients consists of any information you have given us to
                        provide the relevant services. By accepting services from us, you consent to us providing your
                        personal information to the relevant overseas recipient as mentioned above. IDP takes
                        appropriate steps to ensure there are adequate safeguards in place for the transfer of your
                        personal information including standard protection clauses in our contracts and an IDP Group
                        Data Transfer agreement.</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-10" aria-expanded="false"
                    aria-controls="collapse-10"><span class="hdr_spn">The steps we take to protect and keep secure the
                        personal information we hold</span><span class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-10" class="collapse" aria-labelledby="heading-10" data-parent="#accordion">
                    <p>We take reasonable technical and organisational measures to protect the personal information we
                        hold against interference, loss, unauthorised access, use, modification or disclosure, and other
                        misuse.</p>
                    <p>The steps we take include:</p>
                    <ul>
                        <li>maintenance of computer technology, people and process-based security measures for example
                            firewalls, network security configurations, encryption, use of passwords and other
                            appropriate measures where information is held in electronic form;</li>
                        <li>regular updates to security systems and configurations to protect our systems from malicious
                            activity;</li>
                        <li>restriction of access to data to only those staff that need access to carry out our business
                            activities;</li>
                        <li>training and ensuring that all our employees are required, as a condition of employment, to
                            treat personal information held by us as confidential.</li>
                    </ul>
                    <p>However, you should be aware that if you submit information to us electronically the internet is
                        not a secure environment. We take reasonable steps to provide a secure channel for receiving
                        information but cannot absolutely protect personal information before it reaches us.</p>
                    <p><strong>Retention</strong></p>
                    <p>When the personal information that we collect is no longer required, we will destroy, delete it
                        in a secure manner, or ensure that the information is de-identified in accordance with our
                        information destruction and de-identification policy, unless we are required by law to retain a
                        copy of the personal information or the information is contained in a Commonwealth record.</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-11" aria-expanded="false"
                    aria-controls="collapse-11"><span class="hdr_spn">Your rights</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-11" class="collapse" aria-labelledby="heading-11" data-parent="#accordion">
                    <p>You have the right to seek access to any of your personal information held by us unless there is
                        a valid reason under the Privacy Act or Data Protection Act for us to withhold the information.
                    </p>
                    <p>Valid reasons include:</p>
                    <p>We have reason to suspect that unlawful activity or misconduct of a serious nature has been
                        engaged in and giving access would be likely to prejudice the taking of appropriate action in
                        relation to the matter; or</p>
                    <p>Giving access would reveal evaluative information generated within us in connection with a
                        commercially sensitive process.</p>
                    <p>If your personal details change, or you believe the information we hold about you is incorrect,
                        incomplete or out-of-date, please contact us so that we can correct our records.</p>
                    <p><strong>Right to erasure</strong></p>
                    <p>At your request, we will remove all the data we have for you which we are not required legally to
                        retain.</p>
                    <p><strong>Right to restrict processing</strong></p>
                    <p>You have the right to restrict processing when you have exercised one of the above rights and it
                        may take some time to process that request. For example: when you contact us to update your
                        details you have the right to request restriction until your details are being updated.</p>
                    <p><strong>Right to withdraw consent</strong></p>
                    <p>For the processing activities where we have asked you for consent, you have the right to withdraw
                        this consent at any point by unsubscribing from a message from us, or by contacting <a
                            href="mailto:privacyofficer@idp.com">privacyofficer@idp.com</a></p>
                    <p><strong>Right to object</strong></p>
                    <p>You can object to the processing of your personal data.</p>
                    <p><strong>Right to not be subject to automated decisions and profiling</strong></p>
                    <p>None of our processing activities are purely automated and no decisions are made without human
                        intervention. There are instances where we process data to analyse or predict behaviour but we
                        will ask you for explicit consent when this processing will involve your personal data.</p>
                    <p><strong>Right to data portability</strong></p>
                    <p>If you have provided us with your personal data with consent or under the contact obligations,
                        you have the right to request the data you have provided to us in a machine-readable format
                        should you decide to move to another data controller.</p>
                    <p><strong>Making a request</strong></p>
                    <p>Requests for access or correction to your personal information or other privacy rights described
                        above should be addressed to The IDP Privacy Officer by emailing <a
                            href="mailto:privacyofficer@idp.com">privacyofficer@idp.com</a>. All requests will be
                        responded to in writing within a reasonable period of time. As part of this process we will
                        verify the identity of the individual requesting the information prior to providing access or
                        making any changes. If we cannot fulfil your request, we will respond provide you with an
                        explanation.&nbsp;</p>
                </div>
            </div>
            <div class="panel">
                <h3 class="clps_hdr collapsed" data-toggle="collapse" target="#collapse-12" aria-expanded="false"
                    aria-controls="collapse-12"><span class="hdr_spn">Making a complaint if you are unhappy about how we
                        have dealt with or are dealing with your personal information</span><span
                        class="glyphicon glyphicon-menu-up"> </span></h3>
                <div id="collapse-12" class="collapse" aria-labelledby="heading-12" data-parent="#accordion">
                    <p>We have procedures in place to deal with your inquiries or complaints.</p>
                    <p>If you have any questions about our policy or any complaint regarding the treatment of your
                        privacy by us, please contact <a
                            href="mailto:privacyofficer@idp.com">privacyofficer@idp.com.</a></p>
                    <p>If you feel we have intruded on your privacy or misused your data, you are able to complain.</p>
                    <p>In Australia</p>
                    <p>Office of the Australian Information Commissioner’s (OAIC) website at <a
                            href="http://www.oaic.gov.au/" rel="noopener" target="_blank">www.oaic.gov.au</a></p>
                    <p>The contact details for the OAIC are:</p>
                    <p>Office address:</p>
                    <p>Level 3, 175 Pitt Street, Sydney 2000<br>Postal address (Sydney): GPO Box 5218 Sydney NSW
                        2001<br>Postal address (Canberra): GPO Box 2999 Canberra ACT 2601<br>Phone: 1300 363 992<br>Fax:
                        + 61 2 9284 9666</p>
                    <p>Email: <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a></p>
                    <p>In the UK</p>
                    <p>Information Commissioners Office</p>
                    <p><a href="https://ico.org.uk/concerns/" rel="noopener" target="_blank">https://ico.org.uk/concerns/&nbsp;</a></p>
                    <p><strong>Updates to this Policy</strong></p>
                    <p>This version of the policy was published 14 February 2020. From time to time we may update this
                        privacy policy. We encourage you to take the time to review it any time you provide us with
                        personal information.</p>
                    <p>We aim for there to be no surprises in our dealings with your personal information. If we make
                        significant changes to the policy and we have your email address, we will send you an email
                        informing you of these changes, how they may impact you, and reminding you of your privacy
                        rights.</p>
                </div>
            </div>
            <h3>Glossary</h3>
            <ul>
                <li>
                    <p><strong>Cambridge</strong> refers to Cambridge Assessment English&nbsp;</p>
                </li>
                <li>
                    <p><strong>IDP</strong> or <strong>IDP Group</strong> refers to IDP Education Limited (ACN 117 676
                        463) and its related entities in the IDP Group, as applicable.</p>
                </li>
                <li>
                    <p><strong>Hotcourses</strong> refers to Hotcourses Limited and its related entities in the
                        Hotcourses Group, which are part of the IDP Group.&nbsp;</p>
                </li>
                <li>
                    <p><strong>IELTS Australia</strong> refers to IELTS Australia Pty Ltd (ACN 008 664 766), which is a
                        subsidiary of IDP.</p>
                </li>
                <li>
                    <p><strong>IELTS</strong> refers to the International English Language Testing System.</p>
                </li>
                <li>
                    <p><strong>IELTS Owners</strong> refers to The British Council, Cambridge and IDP (through its
                        subsidiary IELTS Australia Pty Ltd), who together own the IELTS test.</p>
                </li>
                <li>
                    <p><strong>Personal information</strong> means information or an opinion about an identified
                        individual, or an individual who is reasonably identifiable, whether the information or opinion
                        is true or not, and whether the information or opinion is recorded in a material form or not.
                    </p>
                </li>
                <li>
                    <p><strong>Privacy Act</strong> means the Privacy Act 1988 (Cth).</p>
                </li>
                <li>
                    <p><strong>Sensitive information</strong> includes information or an opinion about an individual’s
                        racial or ethnic origin, political opinions, membership of a professional or trade association
                        or criminal record that is also personal information, or health information about an individual,
                        or biometric information used for the purpose of automated biometric verification or
                        identification, or biometric templates.</p>
                </li>
                <li>
                    <p>“<strong>We</strong>”, “<strong>us</strong>” and “<strong>our</strong>” refers to members of the
                        IDP Group.</p>
                </li>
            </ul>
        </div>
    </div>
</div>