import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankalfalahPaymentService, PaymentStatus } from '@idp-education/ors-bankalfalah-payment-bff-client-v1';
import { BracPaymentService, PaymentStatus as BracPaymentStatus } from '@idp-education/ors-brac-payment-bff-client-v1';
import { EsewaPaymentService, PaymentStatusRequest, PaymentStatus as eSewaPaymentStatus } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { PaydollarPaymentService, PaymentStatus as PayDollarStatus } from '@idp-education/ors-paydollar-payment-bff-client-v1';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys, PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-payments-callback',
  templateUrl: './payments-callback.component.html',
  styleUrls: ['./payments-callback.component.scss']
})
export class PaymentsCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub= new Subscription();
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private bankalfalahPaymentService: BankalfalahPaymentService,
    private bracPaymentService: BracPaymentService,
    private esewaPaymentService: EsewaPaymentService,
    private paydollarPaymentService: PaydollarPaymentService,
  ) {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.apiCallingSub = this.store.select(appStore => appStore?.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
  }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreLocalData();
    });
    
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  restoreLocalData() {
    let CurrentApp: Application;
    switch (this.paymentType) {
      case 'IOC':
        CurrentApp = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore)).currentApplication;
        break;
      case 'EOR':
        CurrentApp = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
        break;
      case 'OSR':
        CurrentApp = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
        break;
      default:
        break;
    }
    this.applicationId = CurrentApp?.id;
    this.navigatePaymentGateway();
  }

  navigatePaymentGateway() {
    this.paymentGateway = localStorage.getItem('paymentGateway');
    switch (this.paymentGateway) {
      case PaymentGateways.BANKALFALAH:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.bankAlfalahStatus(queryParams);
        });
        break;
      case PaymentGateways.BRAC:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.bracStatus(queryParams);
        });
        break;
      case PaymentGateways.ESEWA:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.activatedRouteSub = this.activatedRoute.params.subscribe((params) => {
            this.eSewaStatus(params, queryParams);
          });
        });
        break;
      case PaymentGateways.PAYDOLLAR:
        this.routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
          this.payDollarStatus(queryParams);
        });
        break;
      default:
        break;
    }
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
    this.paymentNewModalPopup = false;
  }

  modalpopupDescription(status) {
    if (this.paymentGateway === PaymentGateways.PAYDOLLAR) {
      if (status === PayDollarStatus.PENDING) {
        status = '';
      } else if (status === PayDollarStatus.TIMEOUT) {
        status = PayDollarStatus.CANCELLED;
      }
    }
    if (this.paymentGateway === PaymentGateways.ESEWA && status === eSewaPaymentStatus.PENDING) {
      status = PaymentStatus.CANCELLED;
    }

    switch (status) {
      case eSewaPaymentStatus.SUCCESS:
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case eSewaPaymentStatus.FAILED:
      case PaymentStatus.FAILED:

        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case BracPaymentStatus.CANCELED:
      case eSewaPaymentStatus.NOTFOUND:
      case eSewaPaymentStatus.PENDING:
        this.acceptText = 'Payment Cancelled';
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'UNAUTHORISED':
      case 'Unauthorised':
        this.acceptText = 'Unauthorised Payment';
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.acceptText = 'Return to My Account';
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  newmodalpopupDescription(status) {
    if (this.paymentGateway === PaymentGateways.ESEWA && status === eSewaPaymentStatus.PENDING) {
      status = PaymentStatus.CANCELLED;
    }
    if (this.paymentGateway === PaymentGateways.PAYDOLLAR) {
      if (status === PayDollarStatus.PENDING) {
        status = '';
      } else if (status === PayDollarStatus.TIMEOUT) {
        status = PayDollarStatus.CANCELLED;
      }
    }
    switch (status) {
      case PaymentStatus.SUCCESS:
      case eSewaPaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
      case eSewaPaymentStatus.FAILED:
        this.title = `Payment Failed`;
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case BracPaymentStatus.CANCELED:
      case eSewaPaymentStatus.NOTFOUND:
      case eSewaPaymentStatus.PENDING:
        this.title = `Payment Cancelled`;
        this.imageUrl = './assets/images/payment-info.svg';
        this.acceptText = 'Ok';
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `Payment Unauthorised`;
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.imageUrl = './assets/images/payment-alert.svg';
        this.acceptText = 'Unauthorised Payment';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Payment Error`;
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.imageUrl = './assets/images/payment-alert.svg';
        this.acceptText = 'Try again';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  bankAlfalahStatus(queryParams) {
    this.bankalfalahPaymentService.configuration.accessToken = this.userToken;
    const orderId = queryParams.get('orderId');
    this.apiCallingSub.add(this.bankalfalahPaymentService?.paymentStatus(uuid.v4(), orderId, this.applicationId).subscribe((response) => {
      this.showModal(response.status);
    },
      error => {

        this.showModal('');
      }))
  }

  bracStatus(queryParams) {
    this.bracPaymentService.configuration.accessToken = this.userToken;
    const requestBody = {
      receiptNumber: queryParams.get('orderId'),
      txData: queryParams.get('txData')
    };
    this.apiCallingSub.add(this.bracPaymentService.getPaymentStatus(uuid.v4(), requestBody).subscribe(response => {
      this.showModal(response.status);
    },
      error => {

        this.showModal('');
      }));
  }

  eSewaStatus(params, queryParams) {
    this.esewaPaymentService.configuration.accessToken = this.userToken;
    let receiptId = '';
    let receiptNumber = '';
    let signature = '';
    let txData = '';
    if (params.status.toLowerCase().includes('failed')) {
      receiptId = queryParams.get('order_id');
      receiptNumber = queryParams.get('order_number');
      signature = queryParams.get('signature');
    } else {
      txData = queryParams.get('data');
    }
    const statusPayload: PaymentStatusRequest = {
      receiptId,
      receiptNumber,
      signature,
      txData
    };
    this.apiCallingSub.add(this.esewaPaymentService.paymentStatus(statusPayload, uuid.v4()).subscribe(response => {
      this.showModal(response.status);
    },
      error => {

        this.showModal('');
      }))
  }

  payDollarStatus(queryParams) {
    this.paydollarPaymentService.configuration.accessToken = this.userToken;
    const receiptNumber = queryParams.get('Ref');
    const signature = queryParams.get('signature');
    const statusPayload = {
      receiptNumber,
      signature,
    };
    this.apiCallingSub.add(this.paydollarPaymentService.getPaymentStatus(uuid.v4(), statusPayload).subscribe(response => {
      this.showModal(response.status);
    },
      error => {

        this.showModal('');
      }))
  }

  showModal(message) {
    if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(message);
    } else {
      this.modalpopupDescription(message);
    }
  }
}
