import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { GetAddressResponse, AddressSearchService } from '../../../../shared/services/map-box.service';
import { map, tap } from 'rxjs/operators';

export interface ITestFee {
  currencyCodeIso3: string;
  currencySymbol: string;
  decimalSeparator: string;
  totalAmount: number;
}

export interface ISessionService {
  searchSessions(sessionsSearchRequest: any): Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class SsrLocationMockService implements AddressSearchService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient
  ) {
  }

  searchAddresses(searchString: string): Observable<GetAddressResponse> {
    if (isPlatformBrowser(this.platformId)) {
      const mockAddresses = ['Melbourne', '21 Gaggin Street, Metford, 2323, NSW, Australia', '78 Chapman Avenue, Tarana, 2787, NSW, Australia', '33 Acheron Road, Sale North, 3850, VIC, Australia', '68 Nandewar Street, Thumb Creek, 2447, NSW, Australia', '88 Cecil Street, Putney, 2112, NSW, Australia', '19 George Street, Roma, 4455, QLD, Australia', '4 Gloucester Avenue, North Adelaide, 5006, SA, Australia', '40 Main Street, Mantung, 5308, SA, Australia', '64 Lapko Road, Mindarabin, 6336, WA, Australia', '33 Buoro Street, Hazledean, 4741, QLD, Australia', '56 Sunset Drive, Bauhinia, 4718, QLD, Australia', '16 Isaac Road, Boyer, 7140, TAS, Australia', '27 Black Range Road, Reedy Swamp, 2550, NSW, Australia', '85 Eungella Road, Mount Coolon, 4804, QLD, Australia', '64 Hummocky Road, De Mole River, 5223, SA, Australia', '1 Whitehaven Crescent, Tully, 4854, QLD, Australia', '18 McLeans Road, Harrami, 4630, QLD, Australia', '67 Mildura Street, Gray, 7215, TAS, Australia', '39 Buoro Street, Hazledean, 4741, QLD, Australia', '30 Larissa Court, Mildura West, 3500, VIC, Australia', '46 Hill Street, Davis, 7151, TAS, Australia', '72 McPherson Road, Huon, 3695, VIC, Australia', '1 Sale-Heyfield Road, Leongatha North, 3953, VIC, Australia'];
      if (searchString && searchString !== '') {
        const matchAddresses = mockAddresses.filter(a => a.toUpperCase().indexOf((searchString || '').toUpperCase()) > -1);
        const result = {
          type: 'FeatureCollection',
          query: ['melbourne'],
          features: matchAddresses.slice(0, 6).map(address => ({
            id: 'place.7068896531111320',
            type: 'Feature',
            place_type: [
              'place'
            ],
            relevance: 1,
            properties: {
              wikidata: 'Q3141'
            },
            text: 'Melbourne',
            place_name: address,
            bbox: [
              144.593741856,
              -38.433859306,
              145.512528832,
              -37.511273722
            ],
            center: [
              144.9632,
              -37.8142
            ],
            geometry: {
              type: 'Point',
              coordinates: [
                144.9632,
                -37.8142
              ]
            },
            context: [
              {
                id: 'region.10151275538899450',
                short_code: 'AU-VIC',
                wikidata: 'Q36687',
                text: 'Victoria'
              },
              {
                id: 'country.5736064365346070',
                wikidata: 'Q408',
                short_code: 'au',
                text: 'Australia'
              }
            ]
          })),
          attribution: 'NOTICE: © 2022 Mapbox and its suppliers. All rights reserved. Use of this data is subject to the Mapbox Terms of Service (https://www.mapbox.com/about/maps/). This response and the information it contains may not be retained. POI(s) provided by Foursquare.'
        };
        let timeout = (Math.floor(Math.random() * 5) + 2) * 100;
        if (searchString.toLowerCase().startsWith('s')) {
          timeout += 3000;
        }
        if (searchString === '4') {
          return timer(timeout).pipe(tap(() => {
            throw new Error('Generic error');
          }), map(() => ({} as any)));
        }
        return timer(timeout).pipe(map(() => result));
      } else {
        return of({items: []} as any);
      }
    } else {
      return of({items: []} as any);
    }
  }

  reverseGeocode(lat: number, long: number): Observable<GetAddressResponse> {
    if (isPlatformBrowser(this.platformId)) {
      const mockAddresses = ['2 Cunningham Street, Glenmorgan, 4423, QLD, Australia', '21 Gaggin Street, Metford, 2323, NSW, Australia', '78 Chapman Avenue, Tarana, 2787, NSW, Australia', '33 Acheron Road, Sale North, 3850, VIC, Australia', '68 Nandewar Street, Thumb Creek, 2447, NSW, Australia', '88 Cecil Street, Putney, 2112, NSW, Australia', '19 George Street, Roma, 4455, QLD, Australia', '4 Gloucester Avenue, North Adelaide, 5006, SA, Australia', '40 Main Street, Mantung, 5308, SA, Australia', '64 Lapko Road, Mindarabin, 6336, WA, Australia', '33 Buoro Street, Hazledean, 4741, QLD, Australia', '56 Sunset Drive, Bauhinia, 4718, QLD, Australia', '16 Isaac Road, Boyer, 7140, TAS, Australia', '27 Black Range Road, Reedy Swamp, 2550, NSW, Australia', '85 Eungella Road, Mount Coolon, 4804, QLD, Australia', '64 Hummocky Road, De Mole River, 5223, SA, Australia', '1 Whitehaven Crescent, Tully, 4854, QLD, Australia', '18 McLeans Road, Harrami, 4630, QLD, Australia', '67 Mildura Street, Gray, 7215, TAS, Australia', '39 Buoro Street, Hazledean, 4741, QLD, Australia', '30 Larissa Court, Mildura West, 3500, VIC, Australia', '46 Hill Street, Davis, 7151, TAS, Australia', '72 McPherson Road, Huon, 3695, VIC, Australia', '1 Sale-Heyfield Road, Leongatha North, 3953, VIC, Australia'];
      const result = {
        features: [{
          address: mockAddresses[Math.floor(Math.random() * (mockAddresses.length - 1))]
        }]
      };
      let timeout = (Math.floor(Math.random() * 5) + 2) * 100;
      if (Math.floor(Math.random() * 10) % 2 === 0) {
        timeout += 3000;
      }
      if ((window as any).error) {
        return timer(timeout).pipe(tap(() => {
          throw new Error('Generic error');
        }), map(() => ({} as any)));
      }
      return timer(timeout).pipe(map(() => result as any));
    } else {
      return of({items: []} as any);
    }
  }
}
