import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { MyAccountComponent } from './my-account.component';
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { LastScoreComponent } from './last-score/last-score.component';
@NgModule({
  declarations: [
    MyAccountComponent,
    LastScoreComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MyAccountRoutingModule,
  ],
  providers: [
    provideNgxMask()
  ]
})
export class MyAccountModule { }
