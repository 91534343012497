import { Component, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedStateService } from 'src/app/pages/booking/shared-state.service';
import { setActivePayment } from 'src/app/pages/payment/store/payment.actions';
import { ResetState } from 'src/app/pages/booking/store/booking.actions';
import { IBackButtonAction, IBackButtonType } from '../../models/components/form-wrapper';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';

@Component({
  selector: 'app-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss']
})
export class FormWrapperComponent implements OnDestroy {
  @Input() hasUserName = false;
  @Input() hasProgressBar = false;
  @Input() progressValue = 0;
  @Input() currentStepNum = 1;
  @Input() totalStep = 1;
  @Input() currentStepName = '';
  @Input() showFadeBox = false;
  @Input() stepsRoute = null;
  @Input() hasTimer = false;
  @Input() timerTargetTime = null;
  @Input() timerCurrentTime = null;
  @Input() hasBackButton = true;
  @Output() timerDone = new EventEmitter();
  @ViewChild('cancelModal') cancelModal: ElementRef;
  sub: Subscription;
  BackButtonAction: IBackButtonAction;
  activeButton: IBackButtonType = 'no';
  isNotIOLProduct: boolean;
  speakingUrl: string;
  hrefValues = {
    cxUrl: generateUtmUrl(UtmLinkKeys.CxUrl)
  }
  constructor(private location: Location,
              private router: Router,
              private sharedState: SharedStateService,
              private modalService: NgbModal,
              private store: Store<{ globalStore, bookingStore }>) {
    this.sub = this.store.select(appState => appState.globalStore.BackButtonAction).subscribe(subState => {
      this.BackButtonAction = subState;
    });
    this.sub.add(this.store.select(state => state.bookingStore.isNotIOLProduct).subscribe(payload => {
      if (payload) {
        this.isNotIOLProduct = payload;
      }
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.speakingUrl).subscribe(x => {
      if (x) {
        this.speakingUrl = x;
      }
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  open(modal) {
    if (this.currentStepName === 'Confirm') {
      this.modalService.open(modal);
    } else {
      this.router.navigate(['/my-account']);
    }
  }
  exitBooking() {
    this.sharedState.clearLocalStorage();
    this.sharedState.clearState();
    this.store.dispatch(setActivePayment({ activePayment: false }));
    this.isNotIOLProduct ? window.location.href = this.speakingUrl : this.router.navigate(['/my-account']);
    this.store.dispatch(ResetState());
    this.closeModal();
  }
  keypress(e){
    if (e.code === 'Enter') {
      this.open(this.cancelModal);
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  onHover(type: IBackButtonType) {
    this.activeButton = type;
  }
  goBackPage() {
    if (this.BackButtonAction?.BackButtonRoute) {
      this.router.navigate([this.BackButtonAction.BackButtonRoute]);
    } else {
      if (this.stepsRoute) {
        this.router.navigate([this.stepsRoute[this.currentStepNum - 1]]);
      } else if (this.isNotIOLProduct) {
        window.location.href = this.speakingUrl;
      }
      else {
        this.location.back();
      }
    }
  }
}
