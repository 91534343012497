import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OfflinePaymentService } from 'shared/services/offline-payment-service';
import { ApplicationService, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { first, switchMap } from 'rxjs';
import { OfflinePaymentRequestService } from 'shared/services/offline-payment-request.service';
const appSettings = require('src/assets/appSettings.json');
const ENVIRONMENT_TC_LIST = {
  PROD: ['JP112', 'JP035', 'JP114', 'JP033'],
  UAT: ['VN101']
};
@Component({
  selector: 'app-upcoming-test-offline-payment-info-v2',
  templateUrl: './upcoming-test-offline-payment-info-v2.component.html',
  styleUrls: ['./upcoming-test-offline-payment-info-v2.component.scss'],
})
export class UpcomingTestOfflinePaymentInfoV2Component implements OnInit {
  @Input() showHR = false;
  @Input() customLabel = '';
  @Input() applicationId = '';
  @Input() isChangePaymentEnabled = false;
  @Output() onClickViewPaymentOptions: EventEmitter<string> = new EventEmitter();
  @Output() onClickChangePaymentMethod: EventEmitter<void> = new EventEmitter();
  _receiptNumber: string;
  testLocationId: string;
  testCentreId: string;
  testCentreCode: string;
  isOfflineToOnlineTC: boolean;
  receiptId: string;

  get receiptNumber(): string {
    if (this._receiptNumber?.startsWith('BXA')) {
      return this._receiptNumber;
    }
    return null;
  }

  isChangePaymentAvailable = false;

  constructor(
    private applicationService: ApplicationService,
    private offlinePaymentService: OfflinePaymentService,
    private offlinePaymentRequestService: OfflinePaymentRequestService,
    private receiptService: ReceiptService
  ) { }

  ngOnInit(): void {
    if (this.applicationId) {
      this.applicationService
        .getApplication(this.applicationId)
        .pipe(first())
        .subscribe((application) => {
          if (application && application.applicationPayments && application.applicationPayments.length > 0) {
            this._receiptNumber =
              application.applicationPayments[0].receiptNumber;
            this.receiptId =
              application.applicationPayments[0].id;
            this.testLocationId = application.bookings[0].testLocationId;
            this.testCentreId = application.bookings[0].testCentreId;
            this.testCentreCode = application.bookings[0].testCentreCode;
            this.isOfflineToOnlineTC = this.isJapaneseTC(this.testCentreCode);
            if (this.isChangePaymentEnabled) {
              this.offlinePaymentService
                .hasOnlinePaymentMethods(this.testCentreId, this.testLocationId)
                .pipe(first())
                .subscribe((isValid) => {
                  this.isChangePaymentAvailable = isValid;
                });
            }
          }
        });
    }
  }

  isJapaneseTC(testCentreCode) {
    const getEnvironment = appSettings.site.environmentName;
    const tcList = ENVIRONMENT_TC_LIST[getEnvironment] || [];
    return tcList.includes(testCentreCode);
  }

  viewPaymentOptions() {
    this.onClickViewPaymentOptions.emit(this.testLocationId);
  }

  changePaymentMethod() {
    this.receiptService.searchReceipts(this._receiptNumber).pipe(first(),
      switchMap((res) => { return this.offlinePaymentRequestService.cancelOfflinePayment(res.items[0]?.id)})).subscribe( () => {
        this.onClickChangePaymentMethod.emit();
      })
  }
}
