import { UntypedFormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function confirmEmailMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmEmailMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (!control.value || control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmEmailMatch: true });
        } else {
            const result = matchingControl.errors && delete matchingControl.errors.confirmEmailMatch;
            const keys = Object.keys(matchingControl.errors || {});
            if (Array.isArray(keys) && keys.length) {
                matchingControl.setErrors({
                    ...matchingControl.errors,
                });
            } else {
                matchingControl.setErrors(null);
            }
        }
    };
}

