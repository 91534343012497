<div>
  <div class="margin" *ngIf="haveTitle">
    <div class="flexbox header-box">
      <div class="d-flex align-items-center">
        <button class="btn back-btn-highlight btn-link ms-0 ps-0 mt-3" (click)="onBackButtonClick.emit()">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.887" height="22.555" viewBox="0 0 14.887 22.555">
            <g id="Back_Button" data-name="Back Button" transform="translate(2.5 3.528)">
              <path id="Path_1" data-name="Path 1" d="M43.086,65.333l-8.859,7.75,8.859,7.75"
                transform="translate(-34.227 -65.333)" stroke="#1e1e1e" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="5" style="fill: transparent;" />
            </g>
          </svg>
        </button>
        <h2 class="underlined-title">Search and select institutions</h2>
      </div>
    </div>
    <div style="font-size: 18px;">

    </div>

  </div>
  <div class="content-wrapper margin">
    <app-search-institution (onInputNameChanged)="onSearchNameChanged($event)"(onInputCodeChanged)="onSearchCodeChanged($event)"
      title="Search for an institution to add to your list" [verticalCenter]="verticalCenter"
      [horizontalCenter]="horizontalCenter" placeholderName="Search by institution's name"
      placeholderCode="Search by institution's code" [hasTitle]="hasTitle">
    </app-search-institution>
    <div *ngIf="haveButtons" class="d-flex align-items-center justify-content-center ms-md-0 ms-lg-0 mb-3 mt-3">
      <app-two-button [acceptText]="'Search'" [rejectText]="'Skip'" [inCenter]="false"
        [enableAcceptClick]="!!searchNameValue || !!searchCodeValue" (rejectClick)="onSkipClicked()" [showReject]="showSkipButton"
        (acceptClick)="searchInstitutions()">
      </app-two-button>
    </div>
    <ng-template #noResult [ngIf]="isArray(institutionList) && institutionList.length < 1">
      <div class="mt-3 pt-3 text-gray">No result available</div>
    </ng-template>
    <div *ngIf ="isArray(institutionList) && institutionList.length > 0"><h2 class="count">Showing <span class="">{{totalCount}}</span> results</h2></div>
    <div class="contents">
      <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngIf="institutionList">
        <ngb-panel *ngFor="let item of institutionList; index as i" [cardClass]="'card customCard container-fluid'" [id]="'panel-' + i">
          <ng-template ngbPanelHeader #institutionRows>
            <div  [ngClass]="{
              'box': isSearchRO && !item?.isPostalRO,
              'search-box': !isSearchRO}"class=" d-flex flex-wrap card-header" [class.unselectable]="!selectable" [class.select]="item._select"
              (click)="isSearchRO && !item?.isPostalRO ? toggle(acc, i) : noop" (keyup)="isSearchRO && !item?.isPostalRO ? toggle(acc, i) : noop">
              <div class="selection-box">
                <div class="d-flex flex-column">
               <div class="mt-n3 d-contents"> <span class="w-75 me-auto"><h5 class="title mb-0" >{{item.title}}</h5></span>
              <span class="ms-auto mt-4">
                <button *ngIf = "isSearchRO" class="accord-align d-sm-none btn btn-link btn-highlight toggle-button">
                  <span [class.showAccordion]="item?.isPostalRO" class="material-icons" *ngIf="acc.isExpanded('panel-'+i)">
                    <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg"
                      style="transform: rotate(180deg);" alt="" >
                  </span>
                  <span [class.showAccordion]="item?.isPostalRO" class="material-icons" *ngIf="!acc.isExpanded('panel-'+i)">
                    <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg" alt="">
                  </span>
                </button></span>
                <div *ngIf="isSearchRO && item?.isPostalRO"class="mt-n2">
                  <app-information-box type="secondary" [classes]="['d-flex']" img="../../../../assets/images/information-icon.svg">
                    <ng-container *ngIf ="getProductName() === 'IOL'; else NonIOLDisplay">
                    <span class="sub-copy postal-font">To share your results with this institution, please <span class="postal-text">retrieve an electronic copy of your results</span> from the
                    <span class="postal-text">'My Tests'</span> page and <span class="postal-text">contact this institution directly</span>.</span>
                  </ng-container>
                  <ng-template #NonIOLDisplay><span class="sub-copy postal-font">
                    To share your results with this institution please <span class="postal-text">contact your Test Centre</span>.</span>
                  </ng-template>
                  </app-information-box>
                </div>
             <hr [class.mt-n3]="isSearchRO && !item?.isPostalRO || !isSearchRO">
                <div class="row mt-n2">
                  <div class="col-lg-3 col-6 ro-card">
                    Address
                  </div>
                  <div class="col-lg-6 col-6">
                    {{item.address}}, {{item.country}}
                  </div>
                  <div class="col-lg-1 col-6 ro-card">
                    ID
                  </div>
                  <div class="col-lg-2 col-6">
                    {{item.code}}
                  </div>
                </div>
              </div>
                </div>
              </div>
              <div style="flex: 0;">
                <div class="flags d-flex flex-wrap justify-content-end mt-2">
                  <button *ngIf = "isSearchRO" class="btn btn-link btn-highlight toggle-button">
                    <span [class.showAccordion]="item?.isPostalRO" class="material-icons d-none d-sm-block" *ngIf="acc.isExpanded('panel-'+i)">
                      <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg"
                        style="transform: rotate(180deg);" alt="" >
                    </span>
                    <span [class.showAccordion]="item?.isPostalRO" class="material-icons d-none d-sm-block" *ngIf="!acc.isExpanded('panel-'+i)">
                      <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg" alt="">
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <app-edit-notify-institutions [form]="form" [isAddROEnabled]="isAddROEnabled">
            </app-edit-notify-institutions>
            <div class="d-flex justify-content-center">
              <app-two-button [acceptText]="'Add'" [rejectText]="'Skip'"
              [enableAcceptClick]="form.valid" (rejectClick)="onSkipClicked()" [showReject]="showSkipButton"
              (acceptClick)="onAddClick(item)">
            </app-two-button>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

    </div>
    <div class="w-100 d-flex justify-content-center justify-content-md-end mt-3"
      *ngIf="institutionList && institutionList.length && totalCount > 10">
      <ngb-pagination [boundaryLinks]="deviceDetector.isDesktop()" [ellipses]="true" [(page)]="page"
        [maxSize]="deviceDetector.isDesktop() ? 5 : 1" [pageSize]="pageSize" (pageChange)="onPageChange($event)" [collectionSize]="totalCount">
        <ng-template ngbPaginationPrevious>
          <span class="material-icons" style="transform: rotate(180deg);">
            arrow_forward_ios
          </span>
        </ng-template>
        <ng-template ngbPaginationNext>
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </ng-template>
        <ng-template ngbPaginationNumber let-p>
          <div class="circle">{{p}}</div>
        </ng-template>
      </ngb-pagination>
    </div>

  </div>
</div>
