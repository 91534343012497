import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions-incentre',
  templateUrl: './terms-and-conditions-incentre.component.html',
  styleUrls: ['./terms-and-conditions-incentre.component.scss']
})
export class TermsAndConditionsIncentreComponent {

}
