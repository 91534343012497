import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { of } from 'rxjs';
import {
  setFromTips,
} from 'pages/payment/store/payment.actions';
import { catchError, concatMap, first, tap } from 'rxjs/operators';
import {
  ApplicationPaymentStatus
} from '@idp-education/ors-test-taker-bff-client-v1';
import { FeeType, TipsService } from 'shared/services/tips.service';

@Component({
  selector: 'app-tips-callback',
  templateUrl: './tips-callback.component.html',
  styleUrls: ['./tips-callback.component.scss']
})
export class TipsCallbackComponent implements OnInit {

  constructor(
    private store: Store<{ paymentStore, userStore }>,
    private router: Router,
    private route: ActivatedRoute,
    private tipsService: TipsService
  ) {
    this.store.dispatch(setFromTips({ fromTips: true }));
  }

  ngOnInit(): void {
    let feeType: FeeType | undefined;
    let productName: string | undefined;
    let isCancel = false;
    let isEOR = false;
    this.store.select(appStore => appStore.userStore).pipe(
      first(userStore => !!userStore.userToken),
      concatMap(() => this.route.queryParamMap),
      first(),
      concatMap((params: ParamMap) => {
        const appId = params.get('applicationId');
        feeType = FeeType[params.get('feeType')];
        productName = params.get('productName');
        isCancel = (params.get('cancel') || '').toLowerCase() === 'true';
        isEOR = feeType === FeeType.EOR;
        if (appId) {
          return this.tipsService.verifyTipsPayment(appId, isEOR, isCancel);
        } else {
          throw new Error('error in getting applicationId from query string parameter');
        }
      }),
      tap(({ application, applicationPayment }) => {
        if (applicationPayment.status === ApplicationPaymentStatus.PAID || !isCancel) {
          this.tipsService.onPaidApplicationPayment(feeType, productName, application, applicationPayment);
        } else {
          this.tipsService.onCancel(feeType, productName, application, applicationPayment);
        }
      }),
      catchError((err) => {
        this.onError();
        return of(null);
      })
    ).subscribe();
  }

  onError() {
    this.router.navigate(['/my-account']);
  }
}
