<ng-container *ngIf="!isNewJourney">
<div *ngIf="!isLoading">
  <div *ngIf="isPaymentMethod">
    <h2 class="content-title w-65">NewebPay payment options</h2>
  </div>
  <div *ngIf="!isPaymentMethod">
    <h2 class="content-title w-65">Payment options unavailable</h2>
  </div>
  <div class="card-wrapper d-flex" *ngIf="isPaymentMethod">
    <div class="w-100 mb-3 position-relative">
      <ul class="nav nav-tabs flex-column justify-content-center align-items-start">
        <li *ngIf="cardTitle"
          class="nav-item d-flex align-items-start justify-contnet-center flex-column border m-2 rounded w-100 cursor-pointer"
          (click)="onCardClick('card')" (keyup)="onCardClick('card')">
          <div class="nav-link d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <span class="fa fa-credit-card"></span>
              <i class="fa fa-credit-card" class="card-icon"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body">{{cardTitle}}</div>

            </div>
          </div>
        </li>
        <li *ngIf="cvsTitle"
          class="nav-item d-flex align-items-start justify-contnet-center flex-column border m-2 rounded w-100 cursor-pointer"
          (click)="onCardClick('cvs')" (keyup)="onCardClick('cvs')">
          <div class="nav-link d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <span class="fa fa-shopping-cart"></span>
              <i class="fa fa-credit-card" class="card-icon"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body">{{cvsTitle}}</div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="card" *ngIf="isLoading">
  <div class="card-body">
  </div>
</div>
</ng-container>
<div class="d-none">
  <form [formGroup]="formData" action="{{paymentUrl}}" method="post" name="newebPay" id="newebPay" #newebPay>
    <input type="text" id="MerchantID" name="MerchantID" formControlName="merchantId">
    <input type="text" id="TradeInfo" name="TradeInfo" formControlName="tradeInfo">
    <input type="text" id="TradeSha" name="TradeSha" formControlName="tradeSha">
    <input type="text" id="Version" name="Version" formControlName="version">
    <input value="Submit" type="submit">
  </form>
</div>
