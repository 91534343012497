export const USER_SIGNUP_CONSTANTS = {
  streetAddress1: 'Please enter Address',
  expiryDate: '2030-01-01',
  identificationTypeId: '1ebc931a-b826-9434-1786-3b35d6252696',
  countryApplyingToId: '28c6141c-c959-b0b8-cd23-4e2ef48f231c',
  countryApplyingToOther: 'Please select Country',
  currentlyStudyingEnglishAt: '',
  educationLevelId: '4f3d6883-14a1-1d9e-7ff5-f443badf3908',
  occupationLevelId: 'b84b2d3e-1bf4-fc79-d3d5-d6e0ceccc547',
  occupationLevelOther: 'Please select Occupation Level',
  occupationSectorId: '10375967-22c8-74f3-d8cc-2fd66e21b32e',
  occupationSectorOther: 'Please select Occupation Sector',
  testReasonId: 'e388ddb7-9c29-ed03-e91e-042be58be60b',
  testReasonOther: 'Please select Reason',
  yearsOfStudy: '1',
  languageId: '99fcace5-4689-004d-d34f-81799f15b154',
  languageOther: 'Please select First Language',
  termAndCondition2: false,
  communicationsCheckbox: false
};

export const USER_SIGNUP_CONSTANTS_V2 = {
  streetAddress1: 'Unknown',
  streetAddress2: 'Unknown',
  city: 'Unknown',
  expiryDate: '2040-01-01',
  identificationTypeId: '1ebc931a-b826-9434-1786-3b35d6252696',
  countryId: '29e7b37e-08ac-3b29-7477-fb12552762a9',
  nationalityId: '63999c7c-cdaa-304e-71da-3c1790cdd95d',
  countryApplyingToId: '28c6141c-c959-b0b8-cd23-4e2ef48f231c',
  countryApplyingToOther: 'Unknown',
  currentlyStudyingEnglishAt: 'Unknown',
  educationLevelId: '4f3d6883-14a1-1d9e-7ff5-f443badf3908',
  occupationLevelId: 'b84b2d3e-1bf4-fc79-d3d5-d6e0ceccc547',
  occupationLevelOther: 'Unknown',
  occupationSectorId: '10375967-22c8-74f3-d8cc-2fd66e21b32e',
  occupationSectorOther: 'Unknown',
  testReasonId: 'e388ddb7-9c29-ed03-e91e-042be58be60b',
  testReasonOther: 'Unknown',
  yearsOfStudy: '1',
  languageId: '99fcace5-4689-004d-d34f-81799f15b154',
  languageOther: 'Unknown',
  termAndCondition2: false,
  communicationsCheckbox: false
};

export const OTHER_OPTION_IDS = {
  countryId: '28c6141c-c959-b0b8-cd23-4e2ef48f231c',
  nationalityId: 'c4542852-a667-a0ff-251e-8b67c2ad34fa'
}

export type UserSignupConstantsV2Keys = keyof typeof USER_SIGNUP_CONSTANTS_V2;
