import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { setOfflinePayment } from '../../../../pages/payment/store/payment.actions';
import { testCentreCode$ } from '../../../utils/initialize-payment-method';
import { animate, style, transition, trigger } from '@angular/animations';
import { OfflinePaymentRequestService } from 'shared/services/offline-payment-request.service';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';
import { selectEnableNewOfflinePayment } from 'store/global.reducer';
import { of } from 'rxjs';


@Component({
  selector: 'app-offline-request-content',
  templateUrl: 'offline-request-content.component.html',
  styleUrls: ['./offline-request-content.component.scss'],
  animations: [
    trigger('inOutAnimationContent', [
      transition(':enter', [
        style({ left: 500, opacity: 0 }),
        animate('.2s .3s ease-in', style({ left: 0, opacity: 1 })),
      ]),
    ]),
  ]
})
export class OfflineRequestContentComponent {
  @Input()
  userName = '';
  @Input()
  receiptNumber = '';
  @Input()
  applicationPaymentId = '';
  @Input()
  customButtonLabel = '';
  @Input() showBackButton = true;
  @Input() isNonEOROSRProduct = false;
  form: UntypedFormGroup;
  instructionLink$ = testCentreCode$.pipe(map(code => {
    return code ? generateUtmUrl(UtmLinkKeys.BxInstructionUrl, code) : undefined;
  }));
  @Output() onRequestComplete = new EventEmitter<void>();
  @Output() backToPayment = new EventEmitter<void>();

  isInstructions = true;
  isNewOfflinePaymentEnabled$ = this.store.select(selectEnableNewOfflinePayment);

  get isPointVisible$() {
    if (this.isNonEOROSRProduct) {
      return this.isNewOfflinePaymentEnabled$.pipe(map(x => !x));
    }

    return of(true);
  }

  constructor(
    private store: Store<{ myTestsStore, paymentStore, globalStore, bookingStore, applicationsStore }>,
    private fb: UntypedFormBuilder,
    private offlinePaymentRequestService: OfflinePaymentRequestService,
  ) {
    this.form = fb.group({
      haveRead: [false, Validators.required]
    });
  }

  onPayClick() {
    this.store.dispatch(setOfflinePayment({
      isOffline: true
    }));
    this.offlinePaymentRequestService
      .initializeReceipt(this.applicationPaymentId, this.isNonEOROSRProduct)
      .pipe(takeUntil(this.onRequestComplete))
      .subscribe(() => {
        this.onRequestComplete.emit();
      });
  }
}
