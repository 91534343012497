<div class="main-options mt-4">
    <div class="d-flex align-items-center toggle-link" (click)="toggleClick()" (keyup)="toggleClick()">
        <span [class]="{'uparrow': !expanded,'downarrow': expanded }"></span>
        <h3>View options for this test</h3>
    </div>
    <div class="parent-container" [class]="{'show': expanded}" #parentContainer>
        <div class="child-container" #childContainer>
            <hr>
            <div class="options-list d-flex weight-bold mt-2"
                [class]="{'mobile-option-list': deviceService.isMobile()}">
                <a class="option-item">
                    <img src="../../../../../assets/images/Icon-ionic-ios-calendar.svg"  alt="calendar icon">
                    Change Booking
                </a>
                <a class="option-item">
                    <img src="../../../../../assets/images/Icon-ionic-ios-wallet.svg"  alt="wallet icon">
                    Refund Request
                </a>
                <a class="option-item">
                    <img src="../../../../../assets/images/Icon-awesome-receipt.svg"  alt="receipt icon">
                    View Receipt
                </a>
                <a class="option-item">
                    <img src="../../../../../assets/images/Icon-ionic-ios-document.svg"  alt="document icon">
                    Terms & Conditions
                </a>
            </div>
        </div>
    </div>
</div>