<h2 class="underlined-title">{{title}}</h2>
<div class="expandable-content">
    <div class="expandable-item" *ngFor="let item of items; let i = index" (click)="ClickOnItem(i)"
        [attr.tabindex]="17 + i" (keypress)="onKeyPress($event, i)">
        <div class="active-border" *ngIf="item.expanded"></div>
        <div class="item-content">
            <div class="item-head">
                <h3 [ngClass]="{'active': item.expanded}">{{item.title}}</h3>
                <span
                    [ngClass]="{'arrow-icon': true,'up': !item.expanded,'down': item.expanded,'leadText': true}"></span>
            </div>
            <div class="description-parent">
                <div [ngClass]="{'item-description': true,'show': item.expanded}" [innerHtml]="item.description"></div>
            </div>

        </div>
    </div>
</div>