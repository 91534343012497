import { Component, Input } from '@angular/core';
import { ProductFeeCharge } from '@idp-education/ors-test-taker-bff-client-v1';
import { isNumber } from 'lodash';

@Component({
  selector: 'app-confirmation-fee-row',
  template: `
  <div class="card border-0 ">
    <div class="card-body p-2 border-bottom">
      <div class="row align-items-center" *ngFor="let item of productFees">
        <div class="col data" [ngClass]="getCurrencyTextLength()>15 ? 'col-lg-4' : 'col-lg-6'">
          {{ item.description }}
        </div>
        <div class="data col-1 first-letter">{{ item && isNum(item.amount) && (item?.amount | currency: currencyIsoCode: 'symbol-narrow').substr(0,1) }}</div>
        <div id='currency' class="col data text-center text-nowrap" >
          {{toFix(item.amount)}}
          <span>{{ currencyIsoCode }}</span>
        </div>
      </div>
    </div>
  </div>`,
  styles: [`
    .data {
      color: grey;
      font-size: 1rem;
      font-weight: 400;
    }
    .flex-1 {
      flex: 1;
    }
    .first-letter {
      visibility: hidden;
    }
    .first-letter::first-letter {
      visibility: visible;
    }
    .text-nowrap{
      white-space: nowrap;
    }

  `]
})
// tslint:disable-next-line: component-class-suffix
export class FeeRowComponent {
  @Input() productFees: ProductFeeCharge[] = [];
  @Input() currencyIsoCode: any;
  isNum = isNumber;
  toFix = (num: number) => isNumber(num) ? num.toFixed(2) : '';
  getCurrencyTextLength() {
    const element = document.getElementById('currency');
    return element?.textContent?.length;
  }
}
