import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { CallToActionService } from '../../services/call-to-action.service';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent implements OnInit {
  @Input() callToActions = [];
  @Input() userName: string;
  @Output() onBeforeCall: EventEmitter<string> = new EventEmitter();
  @ViewChild('menuSection') menuSection: ElementRef;
  @ViewChild('callToActionSection') callToActionSection: ElementRef;
  constructor(
    private store: Store<{ myTestsStore, paymentStore, globalStore, bookingStore }>,
    private callToActionService: CallToActionService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.setSticky();
    }, 1);
  }
  identify(index, action) {
    return action.cta;
  }
  @HostListener('window:scroll', ['$event'])
  public onScroll(target) {
    this.setSticky();
  }

  setSticky() {
    if (window.pageYOffset > 35) {
      this.renderer.addClass(this.menuSection?.nativeElement, 'sticky');
      if (this.callToActionSection) {
        this.renderer.addClass(this.callToActionSection?.nativeElement, 'sticky');
      }
    } else {
      this.renderer.removeClass(this.menuSection?.nativeElement, 'sticky');
      if (this.callToActionSection) {
        this.renderer.removeClass(this.callToActionSection?.nativeElement, 'sticky');
      }
    }
  }

  isCtaHidden$() {
    return this.callToActionService.getHidden$();
  }
}
