<div class="d-flex flex-grow-1 flex-column content align-items-start justify-content-start">
  <div class="all-content d-flex justify-content-start align-items-stretch flex-column w-100 flex-lg-grow-1">
    <div class="d-flex align-items-center ">
      <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3" (click)="onBackButtonClick.emit()">
        <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
      </button>
      <h2 class="underlined-title">
        Test location
      </h2>
    </div>
    <h3 class="result-disclaimer">
      Please use the location search to choose a suitable test centre for your Retake test.
    </h3>
    <hr/>
    <h4 class="content-title">Where would you want to sit the test?</h4>
    <form [formGroup]="$form" (ngSubmit)="onSubmit()">
      <div class="d-flex justify-content-between align-items-center search-section">
        <label for="location-search" class="visually-hidden">Please enter your city, postcode or address</label>
        <app-search-input [fcn]="'locationSearch'" [form]="$form" id='password' [showRequiredError]="false"
                          i18n-placeholder placeHolder="Search city, postcode, address" [required]="true"
                          [type]="'text'" [id]="'location-search'" [onRender]="onInputRender"
                          [showClearButton]="showClearButton" [onClear]="onClear.bind(this)"></app-search-input>
      </div>
      <div id="invalid-message" *ngIf="(submitted || genericError) && selectedItem === undefined">
        <p class="text-danger"
           *ngIf="submitted && locationSearchControl.errors && locationSearchControl.errors['required']">Please
          enter a city, postcode or address before proceeding.</p>
        <p class="text-danger" *ngIf="submitted && locationOptions!==undefined && locationOptions.length === 0">Enter a
          valid city code, postcode or address</p>
        <p class="text-danger"
           *ngIf="submitted && locationSearchControl.errors && locationSearchControl.errors['addressValidator']">
          Your search must only contain letters and numbers. Please try again.</p>
        <p class="text-danger" *ngIf="genericError">Oops, looks like something went wrong. Please check your internet
          connection and try again.</p>
      </div>
    </form>
    <div class="d-flex justify-content-start align-items-center">
      <button class="btn current-location" type="button" (click)="onUseCurrentLocation()">Use my current location
      </button>
    </div>
    <div class="d-flex justify-content-start location-disabled-message" *ngIf="locationDisabled">
      <i class="fa fa-cog" aria-hidden="true"></i>
      <p>Your location service is disabled. You can enable this service in the <span
        class="text-dark-red">settings</span> menu of your browser.</p>
    </div>
    <div class="d-flex justify-content-start align-items-center align-items-lg-start option-container flex-lg-grow-1"
         *ngIf="locationOptions!==undefined && locationOptions.length > 0">
      <ul>
        <li *ngFor="let option of locationOptions; let i = index">
          <button type="button" class="list-item-button btn d-flex flex-row align-items-start align-items-lg-center"
                  id="location-option-{{i}}" (keydown)="cancelScrollEvent($event)" (keyup)="optionKeyUp($event)"
                  (focus)="onFocus(i)" (click)="onSelect(i)">
            <div class="map-icon d-flex align-items-center justify-content-center">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <div class="option-text">{{option.place_name}}</div>
          </button>
        </li>
      </ul>
    </div>
    <app-two-button [acceptText]="'Continue'" [rejectText]="'Cancel'" [inCenter]="false" [enableAcceptClick]="true"
                    [showReject]="true" [showAccept]="selectedItem!==undefined" (rejectClick)="onCancelClick.emit()"
                    (acceptClick)="onssrTestDateClick.emit()">
    </app-two-button>
  </div>
</div>
