import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentManageComponent } from './payment-manage.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { CountdownModule } from 'ngx-countdown';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { OfflinePaymentRequestComponent } from './offline-payment-request/offline-payment-request.component';
import { TipsCallbackComponent } from 'pages/payment/tips-callback/tips-callback.component';
import { VeritransCallbackComponent } from 'shared/components/payment/veritrans/veritrans-callback/veritrans-callback.component';
import { PaystackCallbackComponent } from 'shared/components/payment/paystack/paystack-callback/paystack-callback.component';
import { PaymentsCallbackComponent } from 'shared/components/payment/payments-callback/payments-callback.component';
import { BankAlfalahCallbackComponent } from 'shared/components/payment/bank-alfalah/bank-alfalah-callback/bank-alfalah-callback.component';
import { BracCallbackComponent } from 'shared/components/payment/brac/brac-callback/brac-callback.component';
import { ESewaCallbackComponent } from 'shared/components/payment/e-sewa/e-sewa-callback/e-sewa-callback.component';
import { PaydollarCallbackComponent } from 'shared/components/payment/paydollar/paydollar-callback/paydollar-callback.component';

@NgModule({
  declarations: [
    PaymentManageComponent,
    CardDetailComponent,
    ConfirmationComponent,
    OfflinePaymentRequestComponent,
    TipsCallbackComponent,
    VeritransCallbackComponent,
    PaystackCallbackComponent,
    PaymentsCallbackComponent,
    BankAlfalahCallbackComponent,
    BracCallbackComponent,
    ESewaCallbackComponent,
    PaydollarCallbackComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PaymentRoutingModule,
    CountdownModule,
    FeatureToggleModule
  ],
  providers: [
    provideNgxMask(),
  ]

})
export class PaymentModule { }
