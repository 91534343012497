<ng-container *ngIf="loadingService.isLoading | async">
    <div *ngIf="!enableNewAccountUI" class="loader">
      <img src="./assets/images/loader.gif" alt="Loading ..."/>
    </div>
    <div class="spin-overlay" *ngIf="enableNewAccountUI">
    <div class="spin-loader">
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
    </div>
</ng-container>