import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-action-button',
  templateUrl: './multi-action-button.component.html',
  styleUrls: ['./multi-action-button.component.scss']
})
export class MultiActionButtonComponent {
  @Input() disable = false;
  @Input() successed = false;
  @Input() successText = '';
  @Input() failed = false;
  @Input() failedText = '';
  @Input() loading = false;
  @Input() normalText = 'Update profile';

  @Output() OnClick = new EventEmitter();

  onclick() {
    this.OnClick.emit(this);
  }
}
