<app-account-layout [callToActions]="callToActions" (onBeforeCall)="onBeforeCall($event)" [userName]="getUserProfileName$ | async">
    <div otherContent>
        <div [ngSwitch]="currentPage">
            <div *ngSwitchCase="'my-test'" [@slideInOut]>
                <div>
                    <div class="upcoming-test">
                        <app-upcoming-test i18n-title [title]="'Upcoming tests'" i18n-stTitle
                            [stTitle]="'Speaking test'" i18n-lrwTitle [lrwTitle]="'Listening, Reading and Writing test'"
                            i18n-lrwTitleMd [lrwTitleMd]="'Listening, Reading & Writing test'"
                            [tests]="upComingApplications" [showOptions]="true" [showViewTestsLink]="false"
                            [showHeader]="true" (onClickOptions)="onClickOptions($event)"
                            (onTimelineItemClick)="onTimelineItemClicked($event)" [timelineItems]="timelineItems"
                            (onRefetchTests)="reload()" [showGetDetail]="true" [showConsentForm]="true">
                            <div class="no-booking"
                                *ngIf="(!upComingApplications || (upComingApplications &&!upComingApplications.length)) && !loadingApplication">
                                <app-no-booking></app-no-booking>
                            </div>
                        </app-upcoming-test>
                    </div>
                    <div class="past-test">
                        <app-past-test i18n-title [title]="'Past tests'" [tests]="pastApplications" [showOptions]="true"
                            (onClickOption)="onClickOptions($event)"
                            (onRequestRemarkClick)="onRequestRemarkClick($event)"
                            (onViewRemarkClick)="onViewRemarkClick($event)"
                            (onssrSearchClick)="onssrSearchClick($event)">
                        </app-past-test>
                    </div>
                    <div class="booking-cancelled">
                        <app-booking-cancelled i18n-title [title]="'Booking Cancelled'" i18n-stTitle
                            [stTitle]="'Speaking test'" i18n-lrwTitle [lrwTitle]="'Listening, Reading and Writing test'"
                            i18n-lrwTitleMd [lrwTitleMd]="'Listening, Reading & Writing test'" [tests]="cancelledTest"
                            [showOptions]="true" [showViewTestsLink]="false">
                        </app-booking-cancelled>
                    </div>
                </div>
            </div>
            <div *ngSwitchCase="'notify-institution'" [@slideInOut]>
                <app-notify-institutions (onBackButtonClick)="currentPage='my-test'" [ROList]="ROList"
                    (OnAddNewRO)="currentPage='select-institution'" [applicationId]="currentApplicationId"
                    [bookableProductName]="bookableProductName" (OnSave)="onInstitutionSave()">
                </app-notify-institutions>
                <div>
                </div>
            </div>
            <div *ngSwitchCase="'select-institution'" [@slideInOut]>
                <app-select-institution (onBackButtonClick)="currentPage='notify-institution'"
                    (onSelectItem)="addNewRO($event)" [horizontalCenter]="true" [verticalCenter]="true"
                    [haveButtons]="true" [isSearchRO]="true" [bookableProductName]="bookableProductName">
                </app-select-institution>
            </div>
            <div *ngSwitchCase="'update-application-details'" [@slideInOut]>
                <app-update-application-details (onBackButtonClick)="currentPage='my-test'">
                </app-update-application-details>
            </div>
            <div *ngSwitchCase="'EOR'" [@slideInOut]>
                <app-eor-management (onBackButtonClick)="currentPage='my-test'" [application]="eorApplication"
                    (onFinishProcess)="onFinishProcess(eorApplication)" [isEORPaymentCompleted]="isEORPaymentCompleted">
                </app-eor-management>
            </div>
            <div *ngSwitchCase="'view-EOR'" [@slideInOut]>
                <app-eor-view-form (onBackButtonClick)="currentPage='my-test'" [application]="viewEorApplication">
                </app-eor-view-form>
            </div>
            <div *ngSwitchCase="'ssr-instructions-display'" [@slideInOut]>
                <app-ssr-instructions-display (onBackButtonClick)="currentPage='my-test'"
                    (OnssrInstructionClick)="currentPage='ssr-search-institution'">
                </app-ssr-instructions-display>
            </div>
            <div *ngSwitchCase="'ssr-search-institution'" [@slideInOut]>
                <app-ssr-search-institution (onBackButtonClick)="currentPage='ssr-instructions-display'"
                (onCancelClick)="onCancelClick($event)" (onssrFormatClick)="OnssrTestFormatClick($event)">
                </app-ssr-search-institution>
            </div>
            <div *ngSwitchCase="'ssr-test-format'" [@slideInOut]>
                <app-ssr-test-format (onBackButtonClick)="currentPage='ssr-search-institution'"
                    (onCancelClick)="onCancelClick($event)" (onssrTestLocationClick)="currentPage='ssr-test-location'">
                </app-ssr-test-format>
            </div>
            <div *ngSwitchCase="'ssr-test-location'" [@slideInOut]>
                <app-test-location (onBackButtonClick)="currentPage='ssr-test-format'"
                    (onCancelClick)="onCancelClick($event)" (onssrTestDateClick)="currentPage='ssr-test-date'">
                </app-test-location>
            </div>
            <div *ngSwitchCase="'ssr-test-date'" [@slideInOut]>
                <app-ssr-test-date (onBackButtonClick)="currentPage='ssr-test-location'"
                    (onCancelClick)="onCancelClick($event)" (onssrTestResultsClick)="currentPage='ssr-test-result'"
                    [ssrSearchApplication]="ssrSearchApplication">
                </app-ssr-test-date>
            </div>
            <div *ngSwitchCase="'ssr-test-result'" [@slideInOut]>
                <app-ssr-test-result [ssrSearchApplication]="ssrSearchApplication"
                    (onBackButtonClick)="currentPage='ssr-test-date'" (onCancelClick)="onCancelClick($event)"
                    (onssrPaymentSummaryClick)="currentPage='ssr-booking-confirmation'"
                    (onssrTestDateClick)="currentPage='ssr-test-date'"
                    (onssrTestLocationClick)="currentPage='ssr-test-location'">
                </app-ssr-test-result>
            </div>
            <div *ngSwitchCase="'ssr-booking-confirmation'" [@slideInOut]>
                <app-ssr-booking-confirmation [ssrSearchApplication]="ssrSearchApplication"
                    (onBackButtonClick)="currentPage='ssr-test-result'" (onCancelClick)="onCancelClick($event)"
                    (onBookNowClick)="currentPage='ssr-payment'">
                </app-ssr-booking-confirmation>
            </div>
            <div *ngSwitchCase="'ssr-payment'" [@slideInOut]>
                <app-ssr-payment (OnPayClicked)="currentPage='ssr-payment-confirmation'"
                    (restartOSRBooking)="onRestartOSRBooking()">
                </app-ssr-payment>
            </div>
            <div *ngSwitchCase="'ssr-payment-confirmation'" [@slideInOut]>
                <app-ssr-payment-confirmation (onCancelClick)="onCancelClick($event)" [isStripe]="isStripe">
                </app-ssr-payment-confirmation>
            </div>
        </div>
    </div>
</app-account-layout>
<app-content-modal #downloadModal title="Download the latest version of Test Player"
    imageUrl="../../../../assets/images/notify-institutions/file-download-red.svg" imageClass="mt-2 mb-4"
    [showAccept]="false" [showReject]="false">
</app-content-modal>
<app-content-modal [showAccept]="false" #timelineDetail title="" [imageClass]="'image-gray'"
    imageUrl="../../../../assets/images/Girl green organic shape.png" [showAccept]="false" [showReject]="false">
    <div class="d-flex flex-column container me-5 ms-5 ">
        <div class="text-dark-red text-bold modal-title weight-semi red">{{timelineDetailTitle}}</div>
        <div class="text-small mt-3 modal-subtitle">
            <span>Your test will start in {{remainingToTest}}. At the start time:</span>
            <ol>
                <li>Launch the Inspera Exam Portal (<img src="../../../assets/images/app-icon.png" alt="app icon" />) from its
                    installed location</li>
                <li>Allow the application to run startup checks</li>
                <li>Use the credentials below to log in to your test</li>
            </ol>
            <span>The login credentials will appear 24 hours before your test:</span>
        </div>
        <table class="text-bold mt-3 modal-detail">
            <caption class="sr-only">User Details</caption>
            <th class="sr-only"></th>
            <tr>
                <td>Username</td>
                <td class="d-flex">
                    <span class="align-items-center textfont">
                        {{getDetail(speakingAndLRWDetails, 'username')}}
                    </span>
                </td>
            </tr>
            <tr>
                <td>Password</td>
                <td>
                    <div class="has-password-icon input-group">
                        <div class="password-icon " [ngClass]="passwordInputType === 'password' ? 'hide' : 'show'"
                            (click)="togglePassword()" (keyup)="togglePassword()" #passwordIconBox></div>
                        <input [type]="passwordInputType" [value]="getDetail(speakingAndLRWDetails,'pass')" readonly
                            class="readonly-password-input " />
                    </div>
                </td>
            </tr>
            <tr>
                <td>SEB PIN</td>
                <td class="d-flex">
                    <span class="align-items-center textfont">
                        {{getDetail(speakingAndLRWDetails, 'pin')}}
                    </span>
                </td>
            </tr>
        </table>
        <span class="mt-3 modal-subtitle">
            For support or further information, please contact <a
                href="mailto: ieltsonline@idp.com">ieltsonline@idp.com</a>.
        </span>
    </div>
</app-content-modal>