import { Injectable } from '@angular/core';
const appSettings = require('src/assets/appSettings.json');
declare let dataLayer;
declare global {
  interface Window {
    dataLayer: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
  isGaScriptadded: boolean 
  isOnetrustscriptadded: boolean 
  
  getCookieValue(key: string): string {
    const cookies = document.cookie.split('; ');
    const cookieName = `${key}=`;
    for (const cookie of cookies) {
      if (cookie.startsWith(cookieName)) {
        return cookie.substring(cookieName?.length);
      }
    }
    return '';
  }

  denyGrantGATag() {
    const existingConsent = this.getCookieValue('OptanonConsent') || null;
    let existingConsentArray = existingConsent ? existingConsent.split('&groups=') : [];
    if (existingConsentArray?.length) {
      existingConsentArray =  decodeURIComponent(existingConsentArray[1]).split(',')
    }
    const consentGroups = { 
      'C0004:0': ['ad_storage', 'ad_user_data', 'ad_personalization'],
      'C0003:0': ['analytics_storage'],
      'C0002:0': ['functionality_storage'],
      'C0001:0': ['personalization_storage', 'security_storage']
    };
    const consentStatus = Object.entries(consentGroups).reduce((acc, [group, purposes]) => {
      const isGranted = !existingConsentArray.includes(group);
      purposes.forEach(purpose => acc[purpose] = isGranted ? 'granted' : 'denied');
      return acc;
    }, {});
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push('consent', 'default', {
      ...consentStatus,
      wait_for_update: 500
    });
    const scriptUrl = 'https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js';
    const scriptType = 'text/javascript';
    const charset = 'UTF-8';
    if (!this.isOnetrustscriptadded) {
      this.addexternalScript(scriptUrl, scriptType, charset, appSettings.site.onetrustCookieId);
    }
  }
  
  addexternalScript(scriptUrl: string, type: string, charset: string, dataDomainScript: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.setAttribute('type', type);
      script.setAttribute('charset', charset);
      script.setAttribute('data-domain-script', dataDomainScript);
      script.onload = () => {
        this.addGaScript();
        resolve();
      };
      script.onerror = (error) => {
        this.addGaScript();
        reject(console.info('Bx Error: Something went wrong'));
      };
      document.head.appendChild(script);
      this.isOnetrustscriptadded = true
    });
  }

  addGaScript() {
  if (!this.isGaScriptadded) {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P2QKHDD');`
    document.head.appendChild(script)
   }
   this.isGaScriptadded = true
  }
}
