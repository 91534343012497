import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { UUID_TOKEN } from 'shared/utils/string-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { SplunkService } from 'shared/services/splunk.service';
const appSettings = require('src/assets/appSettings.json');


@Injectable()
export class SplunkUIErrorHandler implements ErrorHandler {
  private sessionKey: string;
  constructor(
    private splunkService: SplunkService,
    @Inject(UUID_TOKEN) private uuid: string) {
    this.sessionKey = this.uuid;
  }
  handleError(error: any): void {
    if (error instanceof HttpErrorResponse || this.isHTTPError(error)) { return; }

    const payload = this.splunkService.generatePayload({
      session_key: this.sessionKey,
      source_site: 'test_taker_ui',
      event_type: 'UI Error',
      error_error: error?.error,
      error_name: error?.name,
      error_message: error?.message,
      error_stack: error?.stack,
    });

    this.splunkService.logger(payload, error);
  }

  private isHTTPError(error: any): boolean {
    const isErrorString = typeof error === 'string' || error instanceof String;
    return isErrorString ? error?.substring(0, 11) === 'http-error:' : false;
  }
}
