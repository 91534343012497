export class OptionNavigator {
  public static getNextIndex(key: string, currentIndex: number, length: number): number | undefined {
    let direction = 0; // -1 - up, 1 down
    if (key === 'ArrowDown') {
      direction = 1;
    } else if (key === 'ArrowUp') {
      direction = -1;
    }
    if (direction !== 0) {
      const newIndex = (currentIndex || 0) + direction;
      if (newIndex === -1) {
        return newIndex;
      } else if (!(newIndex >= 0 && newIndex < length)) {
        return undefined;
      } else {
        return newIndex;
      }
    } else {
      return undefined;
    }
  }
}
