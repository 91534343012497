import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-institution',
  templateUrl: './search-institution.component.html',
  styleUrls: ['./search-institution.component.scss']
})
export class SearchInstitutionComponent {
  @Input() title = '';
  @Input() placeholderName = '';
  @Input() placeholderCode = '';
  @Input() verticalCenter = false;
  @Input() horizontalCenter = false;
  @Input() hasTitle = true;
  @Output() onInputNameChanged: EventEmitter<string> = new EventEmitter();
  @Output() onInputCodeChanged: EventEmitter<string> = new EventEmitter();
  searchInputName = '';
  searchInputCode = '';
  inputName$ = new Subject();
  inputCode$ = new Subject();
  readonly debounceTime = 300;
  constructor() {
    this.inputName$.pipe(debounceTime(this.debounceTime))
      .subscribe(value => {
        this.onInputNameChanged.emit((value as string));
      });
    this.inputCode$.pipe(debounceTime(this.debounceTime))
      .subscribe(value => {
        this.onInputCodeChanged.emit((value as string));
      });
  }

}
