<div *ngIf="!isEditMode">
  <span class='label'>{{title}}</span>
  <span class="value" *ngIf="form">{{options ? getValue(form.get(fcn).value) : form.get(fcn).value}}</span>
</div>
<!-- <div *ngIf="isEditMode" class="ms-2">
  <ng-container *ngIf="!options">
    <app-custom-input [fcn]="fcn" [form]="form" [title]="title" [inOneLine]="true" [showTick]="false" maxLength="255">
    </app-custom-input>
  </ng-container>
  <ng-container *ngIf="options">
    <app-dropdown [formControl]="form.get(fcn)" [options]="options" [title]="title" idKey="id" labelKey="value"
      placeholder="any" [isOneLine]="true" width="280px" [showTick]="false">
    </app-dropdown>
  </ng-container>
</div> -->