<div class="flexbox header">
    <div class="flexbox align-items-center">
        <h2 class="underlined-title">{{title}}</h2>
    </div>
</div>

<div *ngFor="let application of applications; let i = index;">
    <app-past-test-child [test]="application" [title]="title" [showOptions]="showOptions"
        (onClickOptions)="onClickOption.emit({key: $event.key, application: application})"
        (onRequestRemarkClick)="onRequestRemarkClick.emit($event)"
        (onViewRemarkClick)="onViewRemarkClick.emit($event)"
        (onssrSearchClick)="onssrSearchClick.emit($event)"
        [eTRFUrl$]="getURL(application.applicationId)"
        [meta]="itemMeta[application.applicationId]"></app-past-test-child>
</div>
