<div class="flexbox header">
  <div class="flexbox align-items-center">
    <h2 [class.underlined-title]="applications && applications.length">
      <span *ngIf="applications && applications.length">
        {{ title }}
      </span>
    </h2>
    <img *ngIf="showErrorIcon" src="../../../../assets/images/material-error.svg" class="erroricon" alt="error icon" />
  </div>
  <a *ngIf="showViewTestsLink" class="d-none d-lg-block view-test-link a-link" [routerLink]="['/my-tests']"
    [attr.tabindex]="10">View my tests
    <img src="../../../../assets/images/RightArrowCircle.svg" alt="right arrow icon" />
  </a>
</div>

<p *ngIf="deviceService.isMobile()" class="d-block">
  Tests cannot be taken on mobile devices
</p>
<ng-container *ngIf="applications && applications.length">
  <div *ngFor="let item of applications; let i = index" class="test-contenainer">
    <div *ngIf="
        showHeader &&
        item?.type?.IOL &&
        (item.app.status === 'COMPLETED' ||
          item.app.status === 'AWAITING_RESULT')
      " class="header-box">
      <h6 class="m-0 title text-center border-bottom">
        Click on the buttons to progress with your test
      </h6>
      <app-journey #journey [items]="item.timelineItems" (onItemClicked)="onClickJourneyItem($event, item.app)">
      </app-journey>
    </div>

    <div class="main-content up-test-item">
      <app-test-details-content class="test-details-content" [upcomingTestHeader]="true" *ngIf="!item?.type?.IOL">
        <ng-container testType>{{
          getTestType(item.app.productName)
          }}</ng-container>
        <ng-container testFormat>{{
          getTestFormat(item.app.productName)
          }}</ng-container>
        <ng-container testLocationName>{{
          item.app.testLocationName
          }}</ng-container>
        <ng-container address>{{
          getAddress(item.app.testLocationAddresses)
          }}</ng-container>
        <!--this hardcoded value will be replaced as part of task #24818-->
      </app-test-details-content>
      <div class="row no-margin">
        <div class="col-12 no-padding justify-content-center align-items-center" id="topPaymentStatus"
          *ngIf="isOfflinePaymentComplete(item)">
          <app-payment-status></app-payment-status>
        </div>
        <hr *ngIf="isOfflinePaymentComplete(item)" id="topPaymentStatus" />
        <div [ngClass]="{
            'col-12': true,
            'col-lg-9': addCol9Class(item),
            'no-padding': true
          }">
          <div class="row no-margin pb-3" *ngIf="
              item.app.speakingStartDateTimeUtc &&
              showSpeakingTestCard(
                item.app.productName,
                item.app.speakingStartDateTimeUtc
              )
            ">
            <div class="flexbox flex-sm-column flex-md-row circle-align test-item no-padding pe-2" [ngClass]="{
                'col-12': true,
                'col-lg-9':
                  displayUpcomingTimer(item) &&
                  !(
                    showHeader &&
                    isVisibleGetDetailBtn(item.lrwDate) &&
                    item?.type?.IOL
                  ),
                'flex-xl-wrap align-items-center':
                  item?.type?.IOC,
                'align-items-center align-self-center': !(
                  item.app.lrwStartDateTimeUtc &&
                  showLRWTestCard(
                    item.app.productName,
                    item.app.lrwStartDateTimeUtc
                  )
                ),
                'row-width col-lg-8':
                  showHeader &&
                  isVisibleGetDetailBtn(item.lrwDate) &&
                  item?.type?.IOL
              }">
              <div class="side-circle blue">
                <p class="month">
                  {{ getTestDate(item.speakingDate, "month") }}
                </p>
                <p class="day">{{ getTestDate(item.speakingDate, "day") }}</p>
                <p class="year">{{ getTestDate(item.speakingDate, "year") }}</p>
              </div>
              <div class="test-time">
                <div class="test-name">
                  <h2 class="{{ textClass }} + font-27">{{ stTitle || "" }}</h2>
                </div>
                <div *ngIf="item?.type?.IOL" class="time align-self-start {{ textClass }}">
                  <img src="../../../../assets/images/time-icon.svg" class="icon {{ textClass }}" alt="time icon" />
                  <span>
                    <h3>
                      {{ getTestDate(item.speakingDate, "time") }} ({{
                      getTimezone(item.app.speakingStartDateTimeUtc)
                      }})
                    </h3>
                  </span>
                </div>
                <div class="row" *ngIf="item?.type?.IOC">
                  <div class="product-name font-16 product-font col-12">
                    {{ item.app.productName }} -
                    <div class="time col-12">
                      <img src="../../../../assets/images/time-icon.svg" class="icon {{ textClass }}" alt="time icon" />
                      <span>
                        <h3 class="product-font">
                          {{ getTestDate(item.speakingDate, "time") }}
                        </h3>
                      </span>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="displayUpcomingTimer(item)">
                  <app-upcoming-test-timer [timerClass]="'align-self-start align-self-lg-auto'"
                    [targetTime]="item.speakingDate" [testIndex]="i" [testType]="'speaking'"
                    (timeDone)="timeDone($event)" class="d-block d-lg-none mobile-timer" (onGetDetailClicked)="
                      onGetDetailClicked(
                        'speaking',
                        item.timelineItems,
                        item.app
                      )
                    " [visibleGetDetailBtn]="
                      showHeader && isVisibleGetDetailBtn(item.speakingDate)
                    ">
                  </app-upcoming-test-timer>
                </ng-container>
              </div>
            </div>
            <div *ngIf="displayUpcomingTimer(item)" [ngClass]="{
                'display-direction':
                  item?.type?.IOC,
                'col-lg-4':
                  showHeader &&
                  isVisibleGetDetailBtn(item.lrwDate) &&
                  item?.type?.IOL
              }" class="test-action col-lg-3 col-12 mt-3 no-padding">
              <div class="mt-1">
                <app-upcoming-test-timer [targetTime]="item.speakingDate" [testIndex]="i" [testType]="'speaking'"
                  (timeDone)="timeDone($event)" class="d-none d-lg-block test-timer" (onGetDetailClicked)="
                    onGetDetailClicked('speaking', item.timelineItems, item.app)
                  " [visibleGetDetailBtn]="
                    showHeader &&
                    isVisibleGetDetailBtn(item.speakingDate) &&
                    item?.type?.IOL
                  ">
                </app-upcoming-test-timer>
                <button *ngIf="
                    showHeader &&
                    isVisibleGetDetailBtn(item.speakingDate) &&
                    item?.type?.IOL
                  " class="btn actionbtn d-block d-lg-none" (click)="
                    onGetDetailClicked('speaking', item.timelineItems, item.app)
                  ">
                  Get Details
                </button>
              </div>
              <div class="test-instruction product-font d-flex mt-03 col-lg-12 col-md-12 col-10 no-padding ms-0-6"
                *ngIf="
                  item?.type?.IOC ||
                  item?.type?.SSR
                ">
                <span class="test-instruction-text-align">
                  Remember to arrive no later than
                  {{ format30minsSubtactedTime(item.speakingDate) }}
                </span>
              </div>
            </div>
          </div>
          <hr *ngIf="item?.type?.SSR" />
          <div class="row no-margin" *ngIf="
              item.app.lrwStartDateTimeUtc &&
              showLRWTestCard(
                item.app.productName,
                item.app.lrwStartDateTimeUtc
              )
            ">
            <div
              class="flexbox flex-sm-column flex-xl-row flex-lg-row flex-md-row test-item circle-align no-padding pe-2"
              [ngClass]="{
                'col-12': true,
                'col-lg-9':
                  displayUpcomingTimer(item) &&
                  !(
                    showHeader &&
                    isVisibleGetDetailBtn(item.lrwDate) &&
                    item?.type?.IOL
                  ),
                'flex-xl-wrap align-items-center':
                  item?.type?.IOC,
                'align-items-center align-self-center':
                  item.app.speakingStartDateTimeUtc &&
                  !showSpeakingTestCard(
                    item.app.productName,
                    item.app.speakingStartDateTimeUtc
                  ),
                'row-width col-lg-8':
                  showHeader &&
                  isVisibleGetDetailBtn(item.lrwDate) &&
                  item?.type?.IOL
              }">
              <div class="side-circle purple">
                <p class="month">{{ getTestDate(item.lrwDate, "month") }}</p>
                <p class="day">{{ getTestDate(item.lrwDate, "day") }}</p>
                <p class="year">{{ getTestDate(item.lrwDate, "year") }}</p>
              </div>
              <div class="test-time">
                <div *ngIf="!item?.type?.SSR" class="test-name">
                  <h2 class="{{ textClass }} d-lg-block d-none font-27" [ngClass]="{
                      'product-name-align': displayUpcomingTimer(item)
                    }">
                    {{ lrwTitle || "" }}
                  </h2>
                  <h2 class="{{ textClass }} d-lg-none d-block">
                    {{ lrwTitleMd || "" }}
                  </h2>
                </div>
                <div *ngIf="item?.type?.SSR" class="test-name">
                  <h2 class="{{
                      textClass
                    }} d-lg-block d-none product-name-align font-27">
                    {{ getOSRTestName() || "" }}
                  </h2>
                  <h2 class="{{ textClass }} d-lg-none d-block font-27">
                    {{ getOSRTestName() || "" }}
                  </h2>
                </div>
                <div *ngIf="item?.type?.IOL" class="time align-self-start {{ textClass }}">
                  <img src="../../../../assets/images/time-icon.svg" class="icon {{ textClass }}" alt="time icon" />
                  <span>
                    <h3>
                      {{ getTestDate(item.lrwDate, "time") }} ({{
                      getTimezone(item.app.lrwStartDateTimeUtc)
                      }})
                    </h3>
                  </span>
                </div>
                <div class="row" *ngIf="item?.type?.IOC">
                  <div class="product-name font-16 product-font col-12">
                    {{ item.app.productName }} -
                    <div class="time col-12">
                      <img src="../../../../assets/images/time-icon.svg" class="icon {{ textClass }}" alt="time icon" />
                      <span>
                        <h3 class="product-font">
                          {{ getTestDate(item.lrwDate, "time") }}
                        </h3>
                      </span>
                    </div>
                  </div>
                </div>
                <app-upcoming-test-timer *ngIf="displayUpcomingTimer(item)"
                  [timerClass]="'align-self-start align-self-lg-auto '" [targetTime]="item.lrwDate" [testIndex]="i"
                  [testType]="'lrw'" (timeDone)="timeDone($event)" class="d-block d-lg-none mobile-timer"
                  (onGetDetailClicked)="
                    onGetDetailClicked('lrw', item.timelineItems, item.app)
                  " [visibleGetDetailBtn]="
                    showHeader && isVisibleGetDetailBtn(item.lrwDate)
                  ">
                </app-upcoming-test-timer>
              </div>
            </div>
            <div *ngIf="displayUpcomingTimer(item)" [ngClass]="{
                'display-direction':
                  item?.type?.IOC,
                'col-lg-4':
                  showHeader &&
                  isVisibleGetDetailBtn(item.lrwDate) &&
                  item?.type?.IOL
              }" class="test-action col-lg-3 col-12 mt-3 no-padding">
              <div class="mt-1">
                <app-upcoming-test-timer [targetTime]="item.lrwDate" [testIndex]="i" [testType]="'lrw'"
                  (timeDone)="timeDone($event)" (onGetDetailClicked)="
                    onGetDetailClicked('lrw', item.timelineItems, item.app)
                  " class="d-none d-lg-block test-timer" [visibleGetDetailBtn]="
                    showHeader &&
                    isVisibleGetDetailBtn(item.lrwDate) &&
                    item?.type?.IOL
                  ">
                </app-upcoming-test-timer>
                <button *ngIf="
                    showHeader &&
                    isVisibleGetDetailBtn(item.lrwDate) &&
                    item?.type?.IOL
                  " class="btn actionbtn d-block d-lg-none" (click)="
                    onGetDetailClicked('lrw', item.timelineItems, item.app)
                  ">
                  Get Details
                </button>
              </div>
              <div class="test-instruction product-font d-flex mt-03 col-lg-12 col-md-12 col-10 no-padding ms-0-6"
                *ngIf="
                  item?.type?.IOC ||
                  item?.type?.SSR
                ">
                <span class="test-instruction-text-align">
                  Remember to arrive no later than
                  {{ format30minsSubtactedTime(item.lrwDate) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="item.app.status === 'UNPAID'" class="col-12 col-lg-3 no-padding not-start-content">
          <app-upcoming-test-unpaid [targetTime]="item.app.secondsToPayForApplication" targetUnit="seconds"
            [application]="item.app" (onTimerDone)="timerDone()">
          </app-upcoming-test-unpaid>
        </div>
        <div *ngIf="
            item?.app?.status === 'PAID_BUT_INCOMPLETE' ||
            (isOfflinePaymentComplete(item) && !item.app.isComplete)
          " [ngClass]="{
            'col-12': true,
            'col-lg-3': addCol9Class(item),
            'no-padding': true
          }">
          <app-upcoming-test-incomplete-action [targetTime]="item?.app?.secondsToCompleteApplication"
            targetUnit="seconds" [isOfflineIncompleteProfileComplete]="
              isOfflinePaymentComplete(item) && !item.app.isComplete
            ">
          </app-upcoming-test-incomplete-action>
        </div>
        <div *ngIf="isOfflinePaymentComplete(item)" [ngClass]="{
            'mt-lg-0 min-height':
              item?.app?.isComplete &&
              item?.type?.SSR,
            'mt-lg-4 ors-min-height':
              item?.app?.isComplete &&
              item?.type?.SSR,
            'col-lg-3': addCol9Class(item),
            'offset-9':
              !item?.app?.isComplete &&
              item?.type?.SSR
          }" class="col-12 mt-5 mt-lg-3 no-padding justify-content-center align-items-center" id="downPaymentStatus">
          <app-payment-status></app-payment-status>
        </div>
        <div *ngIf="item.app.status === 'INVALID'"
          class="col-12 col-lg-3 mt-3 mt-lg-0 no-padding d-flex justify-content-center align-items-center">
          <div class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <div *ngIf="showHeader">Incomplete</div>
            <div *ngIf="!showHeader">Not Available</div>
            <div style="font-style: italic">(Please contact support)</div>
          </div>
        </div>
        <div *ngIf="item.app.status === 'REFUNDED'"
          class="col-12 col-lg-3 mt-3 mt-lg-0 no-padding d-flex justify-content-center align-items-center">
          <div class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <div *ngIf="showHeader">Refunded / Canceled</div>
            <div *ngIf="!showHeader">Refunded / Canceled</div>
          </div>
        </div>
        <div *ngIf="item.app.status === 'PAYMENT_IN_PROGRESS'"
          class="col-12 col-lg-3 mt-3 mt-lg-0 no-padding d-flex justify-content-center align-items-center">
          <div class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <div>Payment in progress</div>
          </div>
        </div>
        <div *ngIf="item.app.status === 'CONFIRM_TIPS_PAYMENT'"
          class="col-12 col-lg-3 mt-3 mt-lg-0 no-padding d-flex justify-content-center align-items-center">
          <div *ngIf="itemMeta[item.app.applicationId]?.isLoading === false"
            class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <div class="d-flex action-content align-items-center flex-column justify-content-center">
              <button type="button" (click)="itemMeta[item.app.applicationId]?.callToAction()"
                class="not-start-btn btn actionbtn">
                Complete Booking
              </button>
            </div>
          </div>
          <div *ngIf="itemMeta[item.app.applicationId]?.isLoading === true"
            class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <img class="loader" src="./assets/images/loader.svg" alt="">
          </div>
        </div>
        <div *ngIf="item.app.status === 'AWAITING_RESULT'"
          class="col-12 col-lg-3 mt-3 mt-lg-0 no-padding d-flex justify-content-center align-items-center">
          <div class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
            <div>Awaiting Results</div>
          </div>
        </div>
        <div class="col-12 no-padding mt-4"
          *ngIf="item?.underAgeConsentDetails?.isUnderAgeOnTestDate && showConsentForm && (item.app.status !== 'UNPAID')">
          <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between align-items-md-start mb-2 gap-1">
            <div class="w-100">
              <div class="nowrap m-0 mb-2">
                <h2 class="p-0 m-0">
                  Complete and upload your consent form
                </h2>
              </div>
              <ng-container *ngIf="!item?.underAgeConsentDetails?.isConsentFormUploaded else reuploadConsentForm">
                <div>
                  To ensure a smooth test taking experience on the day please:
                </div>
                <ol>
                  <li>
                    <a class="btn btn-link no-padding download-constant" [href]="consentFormDownloadLink"
                      target="_blank">
                      Download
                    </a>
                    and complete the consent form
                  </li>
                  <li>
                    <button class="btn btn-link no-padding download-constant nowrap"
                      (click)="onUploadConsentFormModal(item)">
                      Upload the consent form
                    </button>
                  </li>
                </ol>
              </ng-container>
              <ng-template #reuploadConsentForm>
                <div class="w-100">
                  <div class="mt-3 mb-3">Consent Form</div>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex gap-1">
                      <div class="file-icon"><i class="fa fa-file-text-o fa-2x text-danger" aria-hidden="true"></i>
                      </div>
                      <div class="d-flex flex-column text_small">
                        <div class="file-name-link text-danger">
                          <a [href]="item.underAgeConsentDetails.fileUrl" target="_blank"
                            class="text-dark">{{item.underAgeConsentDetails.fileName || 'Loading ...'}}</a>
                        </div>
                        <div class="file-date d-flex align-items-center test-instruction"
                          *ngIf="item.underAgeConsentDetails.modifiedOn">
                          Update on {{ (item.underAgeConsentDetails.modifiedOn | date) || '' }}
                          <span *ngIf="item.underAgeConsentDetails.note">
                            {{item?.underAgeConsentDetails?.note?.length > 50 ? (item.underAgeConsentDetails.note |
                            slice:0:50) + ' ...' : item.underAgeConsentDetails.note }}</span>
                        </div>
                      </div>
                    </div>
                    <div><button class="btn btn-link" (click)="onUploadConsentFormModal(item)">
                        <i class="fa fa-pencil-square-o text-danger fa-2x" aria-hidden="true"></i>
                      </button></div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <app-upcoming-test-offline-payment-info *ngIf="isOfflinePaymentPending(item) && (!enableNewOfflinePayment || isOsrProduct(item?.app?.productName))"
        [showHR]="item?.type?.SSR && showOptions" [testCentreCode]="item?.app?.testCentreCode">
      </app-upcoming-test-offline-payment-info>
      <app-upcoming-test-offline-payment-info-v2 *ngIf="isOfflinePaymentPending(item) && (enableNewOfflinePayment && !isOsrProduct(item?.app?.productName))"
        [showHR]="item?.type?.SSR && showOptions"
        [applicationId]="item?.app?.applicationId"
        (onClickViewPaymentOptions)="viewOfflinePaymentOptions(offlinePaymentMethods, $event)"
        (onClickChangePaymentMethod)="changePaymentMethod(item)"
        [isChangePaymentEnabled]="isChangePaymentEnabled" 
        >
      </app-upcoming-test-offline-payment-info-v2>
      <app-upcoming-test-options *ngIf="showOptions" (onClickOptions)="
          onClickOptions.emit({ key: $event, application: item.app })
        " [actions]="item.app.actions || []">
      </app-upcoming-test-options>
    </div>
  </div>
</ng-container>
<div *ngIf="showViewTestsLink" class="d-block d-lg-none">
  <a class="view-test-link" [attr.tabindex]="17" [routerLink]="['/my-tests']">View my tests
    <img src="../../../../assets/images/RightArrowCircle.svg" alt="right arrow icon" />
  </a>
</div>
<app-confirm-modal #uploadSuccessful acceptText="Done" [title]="'Upload Successful'" [showReject]="false"
  [titleCentre]="false" [imageUrl]="'../../../../assets/images/green-tick.svg'" titleClass="text-darker">
  <div>
    Thank you for uploading your consent form.
  </div>
</app-confirm-modal>
<ng-template #offlinePaymentMethods let-offcanvas>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title m-0" id="offcanvasLabel">View payment options</h5>
    <button type="button" class="btn-close" (click)="$event.stopPropagation(); offcanvas.dismiss()" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <app-offline [testLocationId]="selectedTestLocationId" (onContinue)="offcanvas.dismiss()" [hasModalInstructions]="true"></app-offline>
  </div>
</ng-template>