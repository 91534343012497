import { Component, EventEmitter, Input, Output } from '@angular/core';
type IState = 'default' | 'active' | 'text-disabled' | 'disabled';
@Component({
  selector: 'app-journey-button',
  template: `
    <button [disabled]="isDisabled" [ngClass]="{
      'btn-journey-active': isActive,
      'text-disabled': isTextDisabled,
      'btn-journey': !isActive,
      'avoid-clicks': !clickable
    }"
      class="btn m-2 p-2" (click)="onClick.emit()">
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./journey-button.component.scss']
})
export class JourneyButtonComponent {
  @Input() state: IState = 'default';
  @Input() clickable = true;

  @Output() onClick: EventEmitter<void> = new EventEmitter();
  get isActive() {
    return this.state === 'active';
  }
  get isDisabled() {
    return this.state === 'disabled';
  }
  get isTextDisabled() {
    return this.state === 'text-disabled';
  }
  get isDefault() {
    return this.state === 'default';
  }
}
