<div class="test-wrapper">
    <div class="row" *ngIf="stDate">
        <div class="col-12 flexbox test-item">
            <div class="side-circle {{circleClass}}">
                <p class="month">{{getTestDate('st','month')}}</p>
                <p class="day">{{getTestDate('st','day')}}</p>
                <p class="year">{{getTestDate('st','year')}}</p>
            </div>
            <div class="test-time">
                <div class="test-name">
                    <h3 class="{{textClass}}">{{stTitle || ''}}</h3>
                    <a *ngIf="showPencil" href="#" class="update">
                        <img src="../../../../assets/images/Update-icon.svg" alt="update icon" />
                    </a>
                </div>
                <div class="time {{textClass}}">
                    <div class="d-flex time-wrapper">
                        <img *ngIf="!isNotIOLProduct" src="../../../../assets/images/time-icon.svg"
                            class="icon {{textClass}}" alt="time icon" />
                        <div>{{speakingTime.startTime}}</div>
                        <ng-container *ngIf="speakingTime?.duration">
                            <i class="fa fa-hourglass-half ms-2 sub-copy hour-glass" aria-hidden="true"></i>
                            <div>{{speakingTime?.duration}}</div>
                            <div>mins</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="lrwDate">
        <div class="col-12 flexbox test-item mt-3">
            <div class="side-circle {{circleClass}}">
                <p class="month">{{getTestDate('lrw','month')}}</p>
                <p class="day">{{getTestDate('lrw','day')}}</p>
                <p class="year">{{getTestDate('lrw','year')}}</p>
            </div>
            <div class="test-time">
                <div class="test-name">
                    <h3 class="{{textClass}} d-lg-block d-none"> {{lrwTitle || ''}} </h3>
                    <h3 class="{{textClass}} d-lg-none d-block"> {{lrwTitleMd || ''}} </h3>
                    <a *ngIf="showPencil" href="#" class="update">
                        <img src="../../../../assets/images/Update-icon.svg" alt="update icon" />
                    </a>
                </div>
                <div class="time {{textClass}}">
                    <img *ngIf="!isNotIOLProduct" src="../../../../assets/images/time-icon.svg"
                        class="icon {{textClass}}" alt="time icon" />
                    <div>{{lrwTime?.startTime}}</div>
                    <div class="d-flex time-wrapper">
                        <ng-container *ngIf="lrwTime?.duration">
                            <i class="fa fa-hourglass-half ms-2 sub-copy hour-glass" aria-hidden="true"></i>
                            <div>{{lrwTime?.duration}}</div>
                            <div>mins</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showPrice" class="row mt-4">
        <div class="col-12 flexbox amount-wrapper">
            <div class="amount-box" *ngIf="productFee">
                {{productFee?.totalAmount | currency: productFee?.currencyIsoCode : 'symbol-narrow'}}
                {{productFee?.currencyIsoCode}}
            </div>
        </div>
    </div>
</div>