import { Injectable } from '@angular/core';
import { Booking, Application, BookingStatus, ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';
import { DateTime } from 'luxon';
import { setSpeakingtesttime, setLrwtesttime } from './store/payment.actions';

@Injectable({
  providedIn: 'root'
})
export class PaymentUtilService {

  setSpeakingTestTimeAction(data) {
    return setSpeakingtesttime(data);
  }

  setLrwTestTimeAction(data) {
    return setLrwtesttime(data);
  }
  getSpeakingTestTime(speaking: Booking) {
    return speaking?.bookingLines[0].startDateTimeLocal ?
      {
        from: DateTime.fromISO(speaking.bookingLines[0].startDateTimeLocal, {setZone: true}).toJSDate(),
        to: DateTime.fromISO(speaking.bookingLines[0].endDateTimeLocal, {setZone: true}).toJSDate()
      } : { to: DateTime.now().toJSDate(), from: DateTime.now().toJSDate() };
  }
  getLRWTestTime(LRW: Booking) {
    return LRW?.bookingLines[0].startDateTimeLocal ?
      {
        from: DateTime.fromISO(LRW.bookingLines[0].startDateTimeLocal, {setZone: true}).toJSDate(),
        to: DateTime.fromISO(LRW.bookingLines[0].endDateTimeLocal, {setZone: true}).toJSDate()
      } : { to: DateTime.now().toJSDate(), from: DateTime.now().toJSDate() };
  }
  getSpeakingAndLRW(app: Application): { speaking: Booking, lrw: Booking } | null {
    try {
      const lrw = app.bookings.find(i => (i.bookingLines[0].languageSkill.match(/[LRW]/))?.length > 0
        && i.status !== BookingStatus.CANCELLED);
      const speaking = app.bookings.find(i => i.bookingLines[0].languageSkill === 'S' && i.status !== BookingStatus.CANCELLED);

      return { lrw, speaking };
    } catch (error) {
      return null;
    }
  }

  getSsrFee(app: Application): ProductFee {
    const appPayments = app?.applicationPayments[0];
    const ssrFee: ProductFee = {
      baseAmount: appPayments?.applicationFee?.baseAmount,
      charges: [{
        amount: appPayments?.applicationFee?.charges[0]?.amount,
        description: appPayments?.applicationFee?.charges[0]?.name
      }],
      currencyIsoCode: appPayments?.applicationFee?.currencyIsoCode,
      totalAmount: appPayments?.applicationFee?.totalAmount
    };
    return ssrFee;
  }
}
