<div class="main-options mt-4">
    <div class="d-flex tab align-items-center toggle-link" tabindex="0" 
    (click)="toggleClick()" (keydown.enter)="toggleClick()">
        <span [class]="{'uparrow': !expanded,'downarrow': expanded }"></span>
        <h3>View options for this test</h3>
    </div>
    <div class="parent-container" [class]="{'show': expanded}" #parentContainer>
        <div class="child-container" #childContainer>
            <hr>
            <div class="options-list d-flex weight-bold mt-2"
                [class]="{'mobile-option-list': deviceService.isMobile()}">
                <a *ngFor="let item of optionForTest" class="option-item tab" tabindex="0" 
                (click)="onClickOptions.emit(item)" (keydown.enter)="onClickOptions.emit(item)">
                    <img [src]="item.image" alt="image">
                    {{item.label}}
                </a>
            </div>
        </div>
    </div>
</div>