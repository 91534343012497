<div class="leftpanel">
  <app-form-wrapper [hasBackButton]="false">
    <div button *ngIf="leftSidePanel[activePage]?.action.link">
      <a style="color: white;text-decoration: none;">
        <div class="signout-highlight" tabindex="2" (click)="signOut()" (keydown.enter)="signOut()">
          <div class="signout-button ">
            <span class="padding-signout">Sign out</span>
            <img src="../../../../assets/images/Icon awesome-user-circle.svg" alt="">
          </div>
        </div>
      </a>
    </div>
    <div title>
      {{leftSidePanel[activePage]?.title || ''}}
    </div>
    <div subTitle>
      <ng-container *ngIf="leftSidePanel[activePage]?.subTitle?.title1; else Subtitle">
        <p>{{leftSidePanel[activePage]?.subTitle?.title1 || ''}}</p>
        <p>{{leftSidePanel[activePage]?.subTitle?.title2 || ''}}</p>
      </ng-container>
      <ng-template #Subtitle>
        {{leftSidePanel[activePage]?.subTitle || ''}}
      </ng-template>
    </div>

  </app-form-wrapper>
</div>

<div class="rightpanel">
  <app-signup-form></app-signup-form>
  <div tabindex="3" class="logo logofocus">
    <a rel="noopener" [href]="hrefValues?.cxUrl" target="_blank" title="IDP IELTS">
    </a>
  </div>
</div>
