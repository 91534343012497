import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ssr-expected-result',
  templateUrl: './ssr-expected-result.component.html'
})
export class SsrExpectedResultComponent {
  @Input() testFormat = '';
  @Input() testCategory = '';

  isComputerDelivered() {
    return this.testFormat === 'CD';
  }

  isPaperBased() {
    return this.testFormat === 'PB';
  }

  isLifeSkills() {
    return this.testCategory === 'LIFE_SKILLS';
  }
}
