<div class="table-pager d-flex flex-grow-1 g-2" *ngIf="totalCount > 0">
  <div class="table-pager-wrap d-flex flex-grow-1">
    <div class="table-pager-pagination d-flex flex-grow-1" *ngIf="pagingRequired">
      <ngb-pagination class="d-flex flex-grow-1" [collectionSize]="totalCount" [(page)]="page" [pageSize]="pageSize" [maxSize]="maxSize() | async" [rotate]="false"
                      [ellipses]="true" [boundaryLinks]="true">
        <ng-template ngbPaginationPrevious>
          <span class="material-icons" style="transform: rotate(180deg);">
            arrow_forward_ios
          </span>
        </ng-template>
        <ng-template ngbPaginationNext>
          <span class="material-icons">
            arrow_forward_ios
          </span>
        </ng-template>
        <ng-template ngbPaginationNumber let-p>
          <div class="circle">{{p}}</div>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
