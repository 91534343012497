import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-password-validation-status',
  template: `
    <ul class="wrapper list-unstyled password-text ps-3">
      <li *ngFor="let status of validateArr" [class]="{'custom-validation-error': status.color === 'red' , 'green': status.color === 'green'}">{{status.text}}</li>
    </ul>
  `,
  styleUrls: ['./pasword-validation-status.component.scss']
})
export class PasswordValidationStatusComponent implements OnChanges {
  @Input() password = '';
  @Input() checkList = {
    minLength: 'At least 8 characters',
    hasOneUpperCase: 'At least one uppercase alphabet character',
    hasOneLowerCase: 'At least one lowercase alphabet character',
    hasOneNumber: 'At least one numeric character',
    hasSpecialChar: 'At least one special character'
  };
  private patterns = {
    minLength: /^.{8,}/g,
    hasOneUpperCase: /.*[A-Z]/g,
    hasOneLowerCase: /.*[a-z ]/g,
    hasOneNumber: /.*[0-9]/g,
    hasSpecialChar: /.*[@$!%*#?&"'()+`.,/:;<=>[\]^_|{}~\\-]/g
  };
  public validateArr = [];
  ngOnChanges(changes: SimpleChanges): void {
    const value = changes.password.currentValue;
    this.validateArr = [];
    for (const patternName in this.patterns) {
      if (Object.prototype.hasOwnProperty.call(this.patterns, patternName)) {
        const pattern = this.patterns[patternName];
        if (value && value.match(pattern)) {
          this.validateArr.push({ text: this.checkList[patternName], color: 'green' });
        } else {
          this.validateArr.push({ text: this.checkList[patternName], color: 'red' });
        }
      }
    }
  }


}
