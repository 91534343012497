import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Application, ApplicationService, ApplicationWithResultsResponse, Booking, BookingLine,
  DashboardItem, LanguageSkill, ResultWithScores
} from '@idp-education/ors-test-taker-bff-client-v1';
import _, { values } from 'lodash';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import * as uuid from 'uuid';
import { SharedStateService } from '../../shared-state.service';
import { DateTime } from 'luxon';

const ProductId = ['8ccd6cf6-f06b-4910-a489-74863cb340c5', 'c623abd7-d823-4a6c-a8b9-46e4b5ff2b6f'];
const dateFormat = 'cccc, dd MMM yyyy';

@Component({
  selector: 'app-eor-view-form',
  templateUrl: './eor-view-form.component.html',
  styleUrls: ['./eor-view-form.component.scss']
})
export class EorViewFormComponent implements OnInit {
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Input() application: DashboardItem;
  viewEORDescription: string;
  viewEORContactText: string;
  candidateName: string;
  noChangeStatus = 'No change';
  pendingStatus = 'Pending';
  outcomeStatus: string;
  newScore: string;
  newListeningScore: string;
  newReadingScore: string;
  newWritingScore: string;
  newSpeakingScore: string;
  newOverallScore: string;
  outcome: string;
  remarkCompletionDate;
  testTakenDate;
  remarkRaisedDate;
  applicationResult: ApplicationWithResultsResponse;
  changedScores: string[] = [];
  listeningChanged: boolean;
  readingChanged: boolean;
  writingChanged: boolean;
  speakingChanged: boolean;
  overallChanged: boolean;
  payStatus: string;
  originalApplication: Application;
  lrwBookingLines: BookingLine[];
  speakingBookingLines: BookingLine[];
  eorBookingLines: string[] = [];
  remarkComponents: string;
  remarkFileds: string;
  eorResult: ResultWithScores;
  originalResult: ResultWithScores;
  eorNotChanged: boolean;
  eorChanged: boolean;
  isIOLProduct: boolean;
  private readonly skillsLabel: { [key in LanguageSkill]: string } = {
    L: 'Listening',
    R: 'Reading',
    S: 'Speaking',
    W: 'Writing'
  };

  constructor(private applicationsService: ApplicationsService,
              private applicationService: ApplicationService,
              private sharedState: SharedStateService) {
  }

  ngOnInit(): void {
    this.applicationService.getApplication(this.application.applicationId).subscribe((app) => {
      this.originalApplication = app;
      this.isIOLProduct = ProductId.find(i => (i === this.originalApplication?.bookings[0].bookableProductId)) ? true : false;
      this.getRemarkComponents();
    });
    const isDefined = (val: any): boolean => {
      return val !== null && val !== undefined;
    };
    this.applicationsService.getApplicationResults(uuid.v4(), this.application.applicationId).subscribe((data) => {
      this.applicationResult = data;
      this.applicationResult?.results?.forEach((result) => {
        if (!isDefined(result?.eorOutcome)) {
          this.originalResult = result;
        }
        if (result.eorOutcome === 'CHANGED') {
          this.eorResult = result;
          this.eorChanged = true;
        } else if (result.eorOutcome === 'NOT_CHANGED') {
          this.eorResult = result;
          this.eorNotChanged = true;
        }
      });
      this.getEorFields();
    });
  }

  getRemarkComponents() {
    const remarkComponents = [];
    this.eorBookingLines = Object.values(this.originalApplication?.EOR?.bookingLineIds);
    const [lrw, speaking] = this.sharedState.getSpeakingAndLRW(this.originalApplication);
    this.lrwBookingLines = lrw.bookingLines;
    this.speakingBookingLines = speaking.bookingLines;
    const originalBookingLines = [...this.speakingBookingLines, ...this.lrwBookingLines];
    this.eorBookingLines?.forEach((bookingLines) => {
      originalBookingLines.forEach((val) => {
        if (val.id === bookingLines) {
          remarkComponents.push(this.skillsLabel[val.languageSkill]);
          this.remarkComponents = remarkComponents.join(', ');
        }
      });
    });
  }

  getEorFields() {
    this.candidateName = this.applicationResult?.application?.firstName.trim() + '\t' +
      this.applicationResult?.application?.lastName.trim();
    const testTakenDate = this.sharedState.getTestStartDate(this.application);
    this.testTakenDate = DateTime.fromISO(testTakenDate).toFormat(dateFormat);
    this.remarkRaisedDate = DateTime.fromISO(this.originalApplication?.EOR?.createdOn).toFormat(dateFormat);
    const status = this.applicationResult?.application?.status.replace(/[_]/gi, ' ').toLowerCase();
    this.payStatus = status.charAt(0).toUpperCase() + status.slice(1);
    switch (this.application?.status) {
      case 'EOR_IN_PROGRESS':
        this.newScore = this.pendingStatus;
        this.outcome = this.pendingStatus;
        this.remarkCompletionDate = this.pendingStatus;
        this.viewEORDescription = 'Your re-mark application is currently in progress, you will be informed via email once the process has been completed. Your updated scores will be visible here once this process has been completed.';
        this.viewEORContactText = 'If you have any questions in the meantime, please contact';
        break;
      case 'EOR_COMPLETED':
        this.viewEORDescription = 'Your re-mark application is now complete, and the updated scores can be seen below. If the scores have changed, a full refund of the re-mark application fee will be processed. No refund will be provided if your scores have not changed.';
        this.viewEORContactText = ' If you require any further assistance, please contact';
        this.remarkCompletionDate = DateTime.fromISO(this.eorResult?.modifiedOn).toFormat(dateFormat);
        if (this.eorChanged) {
          this.newListeningScore = String(this.eorResult?.scores.listeningScore);
          this.newReadingScore = String(this.eorResult?.scores?.readingScore);
          this.newWritingScore = String(this.eorResult?.scores?.writingScore);
          this.newSpeakingScore = String(this.eorResult?.scores?.speakingScore);
          this.newOverallScore = String(this.eorResult?.scores?.overallScore);
          this.outcome = 'Scores changed';
          for (const keys in this.originalResult.scores) {
            if (this.eorResult?.scores[keys] &&
              this.originalResult.scores[keys] !== this.eorResult?.scores[keys]) {
              this.changedScores.push((keys));
              const changedScoresStatus = this.changedScores.filter(item => {
                switch (item) {
                  case 'listeningScore':
                    this.listeningChanged = true;
                    break;
                  case 'readingScore':
                    this.readingChanged = true;
                    break;
                  case 'writingScore':
                    this.writingChanged = true;
                    break;
                  case 'speakingScore':
                    this.speakingChanged = true;
                    break;
                  case 'overallScore':
                    this.overallChanged = true;
                    break;
                }
              });
            }
          }
        } else if (this.eorNotChanged) {
          this.outcome = this.noChangeStatus;
          this.newScore = this.noChangeStatus;
        }
        break;
    }
  }
}
