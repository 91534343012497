<app-form-wrapper [hasBackButton]="false">
  <div title>Terms and conditions</div>
  <div subTitle>
  </div>
  <div content class="main-content" style="display: flex;width: 100%">
    <div style="max-height: 85vh; overflow-y: scroll; overflow-x: hidden;">
      <div class="row">
        <div class="col-12 page-header">
          <h2>{{ product === 'IOL'? 'IELTS Online Terms and Conditions' : 'IELTS One Skill Retake Terms and Conditions'}}</h2>
        </div>
      </div>
      <div class="bg-theme has-border-top has-border-bottom mb-4 row p-2">
        <div class="col-md-10 col-lg-8 col-print-10 center-block " ng-transclude="">
          <div class="textalign well">
            <div [innerHTML]="loadTC">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-wrapper>