import { Component, EventEmitter, Output, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-two-button',
  template: `
  <div class="button-container">
    <div class="d-flex buttonsWrapper"  [ngClass]="inCenter ? 'justify-content-center' : 'justify-content-start'" >
      <div #wrapper class="d-flex justify-content-around flex-wrap flex-md-nowrap inner-container">
        <button
          #btn1
          *ngIf="showReject"
          type="button"
          class="btn btnfocus btn-irrelevantAction-focus {{ rejectClass }} hover-outline"
          i18n
          (click)="onRejectClick()"
        >
        <i [class]="rejectIconClass" aria-hidden="true"></i>
          {{ rejectText }}
        </button>
        <button
          #btn2
          [disabled]="!enableAcceptClick"
          *ngIf="showAccept"
          type="button"
          class="btn btnfocus btn-irrelevantAction-focus {{ acceptClass }} isActive"
          (click)="onAcceptClick()"
          i18n
        >
         <i [class]="acceptIconClass" aria-hidden="true"></i>
          {{ acceptText }}
        </button>
      </div>
    </div>
    </div>
  `,
  styleUrls: ['./two-button.component.scss'],
})
export class TwoButtonComponent {
  @Output() acceptClick = new EventEmitter<void>();
  @Output() rejectClick = new EventEmitter<void>();
  @Input() enableAcceptClick = false;
  @Input() defaultActiveButton: 'accept' | 'reject' = 'accept';
  @Input() acceptClass = '';
  @Input() acceptText = 'Yes';
  @Input() rejectClass = '';
  @Input() rejectText = 'No';
  @Input() showReject = true;
  @Input() showAccept = true;
  @Input() rejectIconClass = '';
  @Input() acceptIconClass = '';
  @Input() inCenter = true;
  @ViewChild('btn1') btn1: ElementRef;
  @ViewChild('btn2') btn2: ElementRef;

  activeButton: 'accept' | 'reject' = this.defaultActiveButton;
  private documentWidth = window.innerWidth;
  wrapperWidth = this.getWrapperWidth();
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.documentWidth = window.innerWidth;
    this.wrapperWidth = this.getWrapperWidth();
  }

  getWrapperWidth() {
    const btn1Width = this.btn1?.nativeElement.clientWidth || 0;
    const btn2Width = this.btn2?.nativeElement.clientWidth || 0;
    const width: number = (btn1Width + btn2Width <= 400 ? 400 : btn1Width + btn2Width) + 20;
    if (this.documentWidth < 450) {
      return null;
    }
    return width;
  }

  onRejectClick() {
    this.rejectClick.emit();
  }
  onHover(button: 'accept' | 'reject') {
    this.activeButton = button;
  }
  onAcceptClick() {
    this.acceptClick.emit();
  }
}
