<form [formGroup]="form" *ngIf="form">

  <h2 class="heading m-0 title-padding">
    Your Education
  </h2>

  <div class="row">
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('firstLanguage')" #firstLanguageEl labelKey="name" idKey="id"
        placeholder="First language" [title]='showTitle ? "First language" : ""' height="56" i18n-placeholder
        [tooltip]="!disableFirstLanguage" (onChangeModel)="selectChange.emit([$event, 'firstLanguage'])"
        [options]="languages" [showTick]="!disableFirstLanguage" [addNewOptionId]="getNewOptionId(languages)"
        [isRequired]="!disableFirstLanguage" [inputDisabled]="disableFirstLanguage"
        [defaultShowError]="form.get('firstLanguage').pristine">
      </app-dropdown>
    </div>
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('yearsStudyingEnglish')" i18n-placeholder #yearsStudyingEnglishEl
        placeholder="How many years have you been studying English?" [showTick]="true"
        [addNewOptionId]="getNewOptionId(yearsStudyingEnglishList)" [title]='showTitle ? "Years studying English" : "" '
        height="56" (onChangeModel)="selectChange.emit([$event, 'yearsStudyingEnglish'])" labelKey="name" idKey="id"
        [options]="yearsStudyingEnglishList" [isRequired]="true"
        [defaultShowError]="form.get('yearsStudyingEnglish').pristine">
      </app-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('educationLevel')" labelKey="name" idKey="id" #educationLevelEl
        i18n-placeholder placeholder="What level of education have you completed?"
        [title]='showTitle ? "Level of education" : ""' height="56"
        (onChangeModel)="selectChange.emit([$event, 'educationLevel'])" [options]="educationLevelList" [showTick]="true"
        [addNewOptionId]="getNewOptionId(educationLevelList)" [isRequired]="true"
        [defaultShowError]="form.get('educationLevel').pristine">
      </app-dropdown>
    </div>
  </div>

  <h2 class="heading m-0 title-padding">
    Your Occupation
  </h2>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('occupationLevel')" #occupationLevelEl labelKey="name" idKey="id"
        i18n-placeholder (onChangeModel)="selectChange.emit([$event, 'occupationLevel'])"
        placeholder="Occupation (level)" [title]='showTitle ? "Occupation level" : ""' height="56" [showTick]="true"
        [options]="occupationLevelList" [addNewOptionId]="getNewOptionId(occupationLevelList)" [isRequired]="true"
        [defaultShowError]="form.get('occupationLevel').pristine">
      </app-dropdown>
    </div>
    <div class="col-12 col-lg-6">
      <app-dropdown #occupationSectorEl [formControl]="form && form.get('occupationSector')" labelKey="name" idKey="id"
        i18n-placeholder (onChangeModel)="selectChange.emit([$event, 'occupationSector'])"
        placeholder="Occupation (sector)" [title]='showTitle ? "Occupation sector" : ""' height="56"
        [addNewOptionId]="getNewOptionId(occupationSectorList)" [options]="occupationSectorList" [showTick]="true"
        [isRequired]="true" [defaultShowError]="form.get('occupationSector').pristine">
      </app-dropdown>
    </div>
  </div>

  <h2 class="heading m-0 title-padding">
    About you
  </h2>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('intendLocation')" #intendLocationEl labelKey="name" idKey="id"
        (onChangeModel)="selectChange.emit([$event, 'intendLocation'])" i18n-placeholder [showTick]="true" height="56"
        placeholder="Which country do you intend to go to?" [title]='showTitle ? "Country intending to go to" : ""'
        [options]="countryList" [addNewOptionId]="getNewOptionId(countryList)" [isRequired]="true"
        [defaultShowError]="form.get('intendLocation').pristine">
      </app-dropdown>
    </div>
    <div class="col-12 col-lg-6">
      <app-dropdown [formControl]="form && form.get('reasonTest')" #reasonTestEl [showTick]="true"
        (onChangeModel)="selectChange.emit([$event, 'reasonTest'])" placeholder="Reason for taking the Test"
        [title]='showTitle ? "Reason for taking the Test" : ""' i18n-placeholder height="56" [options]="testReasonList"
        labelKey="name" idKey="id" [addNewOptionId]="getNewOptionId(testReasonList)" [isRequired]="true"
        [defaultShowError]="form.get('reasonTest').pristine">
      </app-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <app-custom-input [fcn]="'locationStudyEnglish'" [form]="form" id="locationStudyEnglish" #locationStudyEnglishEl
        (change)="selectChange.emit([null, 'locationStudyEnglish'])" i18n-placeholder
        placeHolder="Where are you currently studying English (if applicable)?"
        [title]='showTitle ? "Country currently studying English" : ""' maxLength="150" showError="true">
      </app-custom-input>
    </div>
  </div>
</form>
