import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DashboardItemAction } from '@idp-education/ors-test-taker-bff-client-v1/model/dashboardItemAction';
import { DashboardItemActionCode } from '@idp-education/ors-test-taker-bff-client-v1/model/dashboardItemActionCode';
const { featureFlags } = require('src/assets/appSettings.json');
export type IOptions = 'Change Booking' |
  'Refund Request' |
  'Update Details' |
  'View Receipt' |
  'Terms & Conditions' |
  'Notify Institutions';
type ItemAction = {
  title: string,
  iconUrl: string,
  key: DashboardItemActionCode,
  visible?: boolean,
  link?: string
};
type IDashboardItemAction = {
  [index in DashboardItemActionCode]?: ItemAction
};
@Component({
  selector: 'app-upcoming-test-options',
  templateUrl: './upcoming-test-options.component.html',
  styleUrls: ['./upcoming-test-options.component.scss']
})
export class UpcomingTestOptionsComponent implements OnInit {
  expanded = false;
  featureFlags = featureFlags;
  @Input('actions') set dashboardItemAction(v: DashboardItemAction[]) {
    this.list = v.map(item => ({
      ...this.originalActions[item.actionCode],
      visible: true,
    }));
  }
  @Output() onClickOptions: EventEmitter<any> = new EventEmitter();
  @ViewChild('childContainer') childContainer: ElementRef;
  @ViewChild('parentContainer') parentContainer: ElementRef;
  list: ItemAction[] = [];
  private originalActions: IDashboardItemAction = {
    TRANSFER_BOOKINGS: {
      key: 'TRANSFER_BOOKINGS',
      title: 'Change Booking',
      iconUrl: '../../../../../assets/images/Icon-ionic-ios-calendar.svg',
    },
    REQUEST_REFUND: {
      key: 'REQUEST_REFUND',
      iconUrl: '../../../../../assets/images/Icon-ionic-ios-wallet.svg',
      title: 'Refund Request'
    },
    UPDATE_DETAILS: {
      key: 'UPDATE_DETAILS',
      title: 'Update Details',
      iconUrl: '../../../../../assets/images/Icon-feather-edit-blue.svg',
      link: '/my-profile'
    },
    VIEW_RECEIPT: {
      key: 'VIEW_RECEIPT',
      title: 'View Receipt',
      iconUrl: '../../../../../assets/images/Icon-awesome-receipt.svg'
    },
    VIEW_TERMS_AND_CONDITIONS: {
      key: 'VIEW_TERMS_AND_CONDITIONS',
      iconUrl: '../../../../../assets/images/Icon-ionic-ios-document.svg',
      title: 'Terms & Conditions'
    },
    NOTIFY_INSTITUTION: {
      key: 'NOTIFY_INSTITUTION',
      iconUrl: '../../../../../assets/images/Icon-ionic-ios-document.svg',
      title: 'Notify Institutions'
    },
  };
  constructor(private renderer: Renderer2,
              public deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.toggleClick();
  }

  toggleClick() {
    this.expanded = !this.expanded;
    const parentElement = this.parentContainer?.nativeElement;
    const childElement = this.childContainer?.nativeElement;
    if (parentElement && childElement) {
      this.renderer.setStyle(parentElement, 'transition', 'height 0.3s');
      if (this.expanded) {
        this.renderer.setStyle(parentElement, 'height', `${childElement.offsetHeight}px`);
      } else {
        this.renderer.setStyle(parentElement, 'height', '0');
      }
    }
  }
  isInPilot(key: DashboardItemActionCode) {
    switch (key) {
      case 'NOTIFY_INSTITUTION':
      case 'VIEW_TERMS_AND_CONDITIONS':
      case 'UPDATE_DETAILS':
        return true;
      default:
        return false;
    }
  }

  onLinkClick(event: Event, item: ItemAction) {
    event.preventDefault();
    this.onClickOptions.emit(item.key);
  }
}
