import {
    trigger,
    query,
    style,
    group,
    transition,
    animate,
  } from '@angular/animations';

export const slideInAnimation =
    trigger('routeAnimations', [
      transition(`payment=>*`, [
        query(':enter, :leave', [
          style({
            width: '100%',
          })
        ]),
        query(':enter', [
          style({
            position: 'relative',
            right: '100%',
            zIndex: 1
          })
        ]),
        query(':leave', [
          style({
            position: 'absolute',
            opacity: 1,
          })
        ], { optional: true }),
        group([
          query(':leave', [
            animate('200ms', style({ opacity: 0 }))
          ], { optional: true }),
          query(':enter', [
            animate('400ms', style({ right: '0' }))
          ])
        ]),
      ]),

    ]);
