<fieldset>
  <div class="page-heading">
    <legend [class.hasProgressBar]="hasProgressBar">
      <img class="background-vertical" src="../../../../assets/images/heading/vertical/png/Component-4-1-2x.png" alt="bg" />
      <img class="background-horizontal" src="../../../../assets/images/heading/horizontal/png/Component-5-1-2x.png"  alt="bg"/>

      <div class="legend-container">
        <div class="header-icons-wrapper mt-4 mobile-icon-wrapper">
          <div *ngIf="isNotIOLProduct ? hasBackButton && BackButtonAction && BackButtonAction.BackButtonEnable :
          hasBackButton && BackButtonAction && BackButtonAction.BackButtonEnable && currentStepNum > 0"
            class="back-icon float-start bck-highlight" tabindex="1" (keydown.enter)="goBackPage()"
            (click)="goBackPage()">
            <img src="../../../../assets/images/back-button.svg" alt="" height="40px">
          </div>
          <div class="d-none d-lg-block" *ngIf="hasUserName">
            <a class="d-flex justify-content-end userName-height text-light" rel="noopener">
              <div tabindex="1" (click)="open(cancelModal)" (keypress)="keypress($event)"
                class="flexbox user-icon bck-highlight me-5 align-items-center justify-content-end">
                <div class="userName">
                  <ng-content select="[userName]" class="userName"></ng-content>
                </div>
                <img src="../../../../assets/images/Icon awesome-user-circle.svg" alt="" class="ms-2">
              </div>
            </a>
          </div>
        </div>
        <div class="content">
          <div class="button">
            <ng-content select="[button]"></ng-content>
          </div>
          <div class="title">
            <h1>
              <ng-content select="[title]"></ng-content>
            </h1>
          </div>
          <div class="subTitle">
            <ng-content select="[subTitle]"></ng-content>
          </div>
          <div class="actions">
            <ng-content select="[actions]"></ng-content>
          </div>
        </div>
        <div class="bottom-container" [ngClass]="{'align-content-center': !hasTimer}">
          <div class="progressBar" *ngIf="hasProgressBar === true">
            <div class="flexbox flex-column-reverse flex-lg-column flex-xl-column">
              <div class="flexbox justify-content-lg-between small justify-content-center mb-1"
                [style]="currentStepNum > totalStep ? 'direction:rtl;':''">
                <div *ngIf="currentStepNum <= totalStep">Step {{ currentStepNum }} of {{ totalStep }}</div>
                <div class="d-none d-lg-block">{{ currentStepName }}</div>
              </div>
              <ngb-progressbar height='2px' type="success" textType="success" [value]="progressValue"
                [showValue]="false">
              </ngb-progressbar>
            </div>
          </div>
          <div class="timer" *ngIf="hasTimer && timerTargetTime">
            <app-countdown-timer timerText="left to secure your booking" [targetTime]="timerTargetTime"
              [currentTime]="timerCurrentTime" (timeDone)="timerDone.emit()"></app-countdown-timer>
          </div>
        </div>
      </div>
    </legend>
  </div>
  <div class="flexbox flex-lg-column content-wrapper w-100">
    <div *ngIf="showFadeBox" class="fade-wrapper">
      <div class="fade-effect"></div>
    </div>
    <div class="logo logofocus">
      <a [href]="hrefValues.cxUrl"  rel="noopener" target="_blank" title="IDP IELTS">
        <img src="./../../../../assets/images/IDP_IELTS_COBRAND_RGB.png" alt="IDP logo">
      </a>
    </div>
    <ng-content class="content" select="[content]"></ng-content>
  </div>

</fieldset>

<ng-template #cancelModal let-modal>

  <div class="modal-body">
    <div class="d-flex flex-column align-items-center justify-content-center pt-4">
      <button type="button" class="float-end btn btn-link p-0 buttonAlign" aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span class="text-darker fontSize" aria-hidden="true">&times;</span>
      </button>
      <img src="../../../../assets/images/boy-read-book.png" alt="">
      <div class="d-flex align-items-center justify-content-center mt-3">
        <h6 class="m-0 text-center text-grey ps-2 " i18n>Are you sure you want to cancel?</h6>
      </div>
      <div class="text-grey small text-center me-2 ms-2 mt-4 " i18n>
        If you cancel your booking now, you may lose your time slots.
      </div>
      <div class="d-flex flex-wrap justify-content-center">
        <!-- <a [routerLink]="['/my-account']" style="text-decoration: none;"> -->
        <button class="btnfocus btn btn-irrelevantAction mt-4 me-2 shadow" [class.isActive]="activeButton==='yes'"
          (click)="exitBooking()" (hover)="onHover('yes')" i18n>
          Yes, cancel
        </button>
        <!-- </a> -->
        <!-- <a [routerLink]="['/my-account']" style="text-decoration: none;"> -->
        <button class="btnfocus btn btn-irrelevantAction mt-4 shadow" (hover)="onHover('no')" (click)="closeModal()"
          [class.isActive]="activeButton==='no'" i18n>
          No, don’t cancel
        </button>
        <!-- </a> -->
      </div>
    </div>
  </div>
</ng-template>
