enum GeneralAndAcademic {
  Academic = 'IELTS Academic',
  General = 'IELTS General Training'
}
const mainProductInclude = (bookableProductName) => (keyword) => bookableProductName.toLowerCase().includes(keyword.toLowerCase());
export const getTestType = (bookableProductName: string) => {
  const productInclude = mainProductInclude(bookableProductName);
  if (productInclude('life skills')) {
    if (productInclude('a1')) {
      return 'Life Skills A1';
    } else if (productInclude('b1')) {
      return 'Life Skills B1';
    } else {
      return '';
    }
  } else if (productInclude('SELT') || productInclude('UKVI')) {
    return `IELTS UKVI on ${extractGeneralOrAcademic(bookableProductName).replace(new RegExp('IELTS ', 'g'), '')}`;
  }
  return extractGeneralOrAcademic(bookableProductName);
};

function extractGeneralOrAcademic(productName): GeneralAndAcademic.Academic | GeneralAndAcademic.General | '-' {
  const productInclude = mainProductInclude(productName);
  if (productInclude('GT') || productInclude('General Training')) {
    return GeneralAndAcademic.General;
  } else if (productInclude('AC') || productInclude('Academic')) {
    return GeneralAndAcademic.Academic;
  }
  return '-';
}

export const getTestFormat = (bookableProductName: string) => {
  if (bookableProductName.toLowerCase().includes('on computer')) {
    return 'IELTS on computer';
  } else if (bookableProductName.toLowerCase().includes('on paper')) {
    return 'IELTS on paper';
  } else {
    return '';
  }
};
