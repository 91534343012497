<div class="upload-type">
  <button [disabled]="!hasWebcam" [attr.tabindex]="1" (click)="openCameraModal.emit()" class="btn">
    <img src="/assets/images/camera.svg" alt="Take a photo" />
    <span i18n>Take a photo</span>
  </button>
  <button [attr.tabindex]="1" (click)="onUploadClick.emit()" class="btn">
    <img src="/assets/images/image.svg" alt="Upload a local file" />
    <span i18n>Upload a file</span>
  </button>
</div>
