import { Component, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { IModalButtons } from '../../../shared/models/components/confirm-modal';
import { compact, isString, isNumber, toNumber } from 'lodash';
import {
  ApplicationOrganisation,
  ConfirmationType,
  ApplicationOrganisationStatus,
  OrganisationService, ComponentMinimumScore,
  LanguageSkill
} from '@idp-education/ors-test-taker-bff-client-v1';
import { first } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { conditionalValidator } from 'shared/validators/custom-validators';

const { featureFlags } = require('src/assets/appSettings.json');

@Component({
  selector: 'app-notify-institutions',
  templateUrl: './notify-institutions.component.html',
  styleUrls: ['./notify-institutions.component.scss']
})
export class NotifyInstitutionsComponent implements OnChanges {
  @Input() ROList: Subject<Array<ApplicationOrganisation>>;
  @Input() applicationId: string;
  @Input() bookableProductName = '';
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() OnAddNewRO: EventEmitter<any> = new EventEmitter();
  @Output() OnRemoveRO: EventEmitter<ApplicationOrganisation> = new EventEmitter();
  @Output() OnSave: EventEmitter<null> = new EventEmitter();
  @ViewChild('ConfirmModal') confirmModal: ConfirmModalComponent;
  @ViewChild('WithdrawConfirmModal') withdrawConfirmModal: ConfirmModalComponent;
  @ViewChild('acc') acc: NgbAccordion;
  withdrawItem: ApplicationOrganisation;
  public featureFlags: any = featureFlags;

  confirmationStatus: Array<{
    key: ConfirmationType,
    value: string,
    visible: boolean
  }> = [
      { key: 'CONDITIONAL', value: 'Only when I achieve the minimum scores', visible: true },
      { key: 'CONFIRMED', value: 'I achieve any score', visible: true },
    ];
  form: UntypedFormGroup;
  currentValue: ApplicationOrganisation;
  score = Array(17).fill(0);
  // tslint:disable-next-line: variable-name
  private _editMode: boolean;
  public get editMode(): boolean {
    return this._editMode;
  }
  public set editMode(v: boolean) {
    if (this.form && this.form.dirty && !v) {
      this.manageModal(() => {
        this._editMode = v;
      });
    } else {
      this._editMode = v;
    }
  }
  private _ROs: Array<ApplicationOrganisation>;
  get ROs(): Array<ApplicationOrganisation> {
    return this._ROs;
  }
  constructor(private fb: UntypedFormBuilder, private loading: LoadingService,
              private organisationService: OrganisationService) {
    this._editMode = false;
    this.fillScores();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ROList.currentValue) {
      this.ROList.pipe(first()).subscribe(data => {
        this._ROs = data;
        if (this.currentValue && !this.form) {
          this.currentValue = data.find(i => this.currentValue.id === i.id);
          this.form = this.formPrepare(this.currentValue);
        }
      });
    }
  }


  onClickBack(callback) {
    this.manageModal(() => this.onBackButtonClick.emit());
  }

  public manageModal(callback?: () => any) {
    if (!this.form) {
      return callback();
    } else if (this.form.valid && this.form.dirty) {
      this.confirmModal.open().closed.subscribe((result: IModalButtons) => {
        if (result === 'accept') {
          this.form = this.formPrepare(this.currentValue);
          if (callback) {
            callback();
          }
        }
      });
    } else {
      callback();
    }
  }

  private fillScores() {
    this.score[0] = 1;
    const roundedLocalScore = this.score.reduce((p, c, index) => {
      this.score[index] = this.score[index - 1] + .5;
    });
    this.score.splice(0, 0, 'Any');
    this.score = this.score.map((score, index) => ({ key: `${index}`, value: score }));
  }


  save(appOR: ApplicationOrganisation) {
    const getValue = (values, controlName) => values[controlName].value || values[controlName].label;
    const { value } = this.form;
    this._editMode = false;
    const listeningValue = value?.listening?.value || value?.listening?.option?.value;
    const readingValue = value?.reading?.value || value?.reading?.option?.value;
    const speakingValue = value?.speaking?.value || value?.speaking?.option?.value;
    const writingValue = value?.writing?.value || value?.writing?.option?.value;
    const hasValue = (v: string) => (isString(v) && v.toLowerCase() !== 'any') || (isNumber(v) && toNumber(v) > 0);
    const minimumScores: ComponentMinimumScore[] = compact([
      hasValue(listeningValue) ? {
        languageSkill: 'L',
        minimumScore: listeningValue
      } : undefined,
      hasValue(readingValue) ? {
        languageSkill: 'R',
        minimumScore: readingValue
      } : undefined,
      hasValue(speakingValue) ? {
        languageSkill: 'S',
        minimumScore: speakingValue
      } : undefined,
      hasValue(writingValue) ? {
        languageSkill: 'W',
        minimumScore: writingValue
      } : undefined,
    ]);
    const confirmationStatus = value?.confirmationStatus?.key || value?.confirmationStatus?.Id;
    this.organisationService.updateApplicationOrganisation(this.applicationId, appOR.id, {
      confirmationType: confirmationStatus,
      organisationId: appOR.organisationIdentity.id,
      version: appOR.version,
      caseNumber: value?.referenceNumber,
      contact: value?.contactName,
      minimumScores: confirmationStatus !== 'CONFIRMED' ? {
        overallMinimumScore: value?.overall?.option ? value?.overall?.option?.value : value?.overall?.value,
        componentMinimumScores: minimumScores
      } : undefined,
    }).subscribe({
      next: () => {
        this.OnSave.emit();
        this.form.markAsPristine();
      }
    });
  }
  formPrepare(values: ApplicationOrganisation): UntypedFormGroup {
    this.currentValue = { ...values };
    const defaultOption = this.score.filter(item => item.value === 'Any')[0];
    const getScore = (v) => {
      try {
        return this.score.filter(item => item.value === v)[0];
      } catch (e) {
        return '';
      }
    };
    const getLangSkillScore = (skill: LanguageSkill) => {
      try {
        const minScore = values?.minimumScores?.componentMinimumScores.filter(i => i.languageSkill === skill)[0].minimumScore;
        return this.score.filter(item => item.value === minScore)[0];
      } catch (e) {
        return '';
      }
    };
    const overall = values?.minimumScores?.overallMinimumScore;
    return this.fb.group({
      title: [values?.organisationIdentity.name || ''],
      status: [values?.status || ''],
      TRFNO: [''],
      contactName: [values?.contact || '', Validators.maxLength(255)],
      referenceNumber: [values?.caseNumber || '', Validators.maxLength(255)],
      confirmationStatus: [this.findInConfirmationStatus(values?.confirmationType) || '', Validators.required],
      overall: [overall ? getScore(overall) : ''],
      listening: [getLangSkillScore('L') || defaultOption],
      reading: [getLangSkillScore('R') || defaultOption],
      writing: [getLangSkillScore('W') || defaultOption],
      speaking: [getLangSkillScore('S') || defaultOption],
    }, { validators: conditionalValidator });
  }
  findInConfirmationStatus(value: ConfirmationType) {
    return this.confirmationStatus.find(item => item.key === value);
  }
  findInScores(score: string | number, scores: any[]): { key: string, value: string } | string {
    if (!score) {
      return '';
    }
    const scoreString = !isString(score) ? `${score}` : score.toString();
    if (scoreString.toLowerCase() === 'any') {
      return scores.find(item => item.value.toLowerCase() === 'any');
    } else {
      const s = parseFloat(scoreString);
      return scores.find(item => item.value === s);
    }
  }
  getValue(value: string) {
    if (value) {
      return value;
    }
    return '-';
  }
  onClickEditMode(values: ApplicationOrganisation) {
    this.editMode = true;
  }
  toggle(acc: NgbAccordion, index: any, values: ApplicationOrganisation) {
    this.currentValue = values;
    this.manageModal(() => {
      this.form = this.formPrepare(values);
      const panel = `panel-${index}`;
      acc.toggle(panel);
      this.editMode = false;
    });
  }
  isAccessible(value: ApplicationOrganisationStatus) {
    return value === ApplicationOrganisationStatus.UNDELIVERED;
  }
  isWithdraw(value: ApplicationOrganisationStatus) {
    return !(value === 'IN_PROGRESS' || value === 'DELIVERED');
  }
  rejectClick() {
    this.form = this.formPrepare(this.currentValue);
    this.editMode = false;
  }
  onRemove(item) {
    this.withdrawItem = item;
    this.withdrawConfirmModal.open();
  }
  getProductName(): 'IOL'|'NIOL' {
    if (this.bookableProductName.includes('IELTS Online')) {
      return 'IOL';
    } else {
      return 'NIOL';
    }
  }
  withdrawRO() {
    this.organisationService.withdrawApplicationOrganisation(this.applicationId, this.withdrawItem?.id, {
      version: this.withdrawItem.version
    }).subscribe({
      next: () => {
        this.loading.increaseLoadingCounter();
        setTimeout(() => {
          this.acc.collapseAll();
          this.OnSave.emit();
          this.loading.decreaseLoadingCounter();
        }, 5000);
      }, error: () => {
        this.loading.increaseLoadingCounter();
        setTimeout(() => {
          this.OnSave.emit();
          this.loading.decreaseLoadingCounter();
        }, 5000);
      }
    });
  }
}
