import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileRoutingModule } from './my-profile-routing.module';
import { MyProfileComponent } from './my-profile.component';
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [
    MyProfileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MyProfileRoutingModule,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class MyProfileModule { }
