import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import dateFormat from 'dateformat';
import { Observable, of } from 'rxjs';
const appSettings = require('../../../assets/appSettings.json');
const MAPBOX_API_AUTOCOMPLETE_ENDPOINT = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const MAPBOX_API_TYPES = 'postcode,place,locality';
export interface GetAddressResponse {
  features: Array<any>;
  type: any;
  attribution: any;
  query: any;
}

export interface AddressSearchService {
  searchAddresses(searchText: string): Observable<GetAddressResponse>;
  reverseGeocode(long: number, lat: number): Observable<GetAddressResponse>;
}
@Injectable({
  providedIn: 'root'
})
export class MapBoxService implements AddressSearchService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient
  ) { }
  searchAddresses(searchText: string): Observable<GetAddressResponse> {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get(MAPBOX_API_AUTOCOMPLETE_ENDPOINT + '/' + encodeURIComponent(searchText) + '.json', {
        params: this.toHttpParams({
          access_token: appSettings.site.mapBoxApiKey,
          types: MAPBOX_API_TYPES,
          autocomplete: true,
          limit: 6
        })
      }) as Observable<GetAddressResponse>;
    } else {
      return of({} as GetAddressResponse);
    }
  }
  reverseGeocode(long: number, lat: number): Observable<GetAddressResponse> {
    if (isPlatformBrowser(this.platformId)) {
      return this.http.get(MAPBOX_API_AUTOCOMPLETE_ENDPOINT + '/' + long.toString() + ',' + lat.toString() + '.json', {
        params: this.toHttpParams({
          access_token: appSettings.site.mapBoxApiKey,
          types: MAPBOX_API_TYPES,
          limit: 1
        })
      }) as Observable<GetAddressResponse>;
    } else {
      return of({} as GetAddressResponse);
    }
  }

  /**
   * Converts properties of an Object to a HttpParams hashtable.
   */
  toHttpParams(criteria: any): HttpParams | undefined {

    if (criteria) {
      const keys = Object.keys(criteria);
      let params = new HttpParams();
      for (const v in keys) {
        const key = isNaN(parseInt(v)) ? v : keys[v];
        if (criteria[key] === null) {
          criteria[key] = undefined;
        }

        if (Object.prototype.hasOwnProperty.call(criteria,key) && criteria[key] !== undefined) {
          let value;

          const filterValue = typeof criteria[key] === 'object' && Object.prototype.hasOwnProperty.call(criteria[key],'value')
            ? criteria[key].value
            : criteria[key]; // TODO: Check to see whether object should be allowed

          if (Object.prototype.toString.call(filterValue) === '[object Date]') {
            value = dateFormat(filterValue, 'yyyy-mm-ddTHH:MM:ss');
          } else if (Object.prototype.toString.call(filterValue) === '[object Array]') {
            filterValue.map((fValue: any) => {
              params = params.append(key, fValue);
            });
          } else if (filterValue !== undefined && typeof filterValue !== 'function' && filterValue !== '') {
            value = /* encodeURIComponent */(filterValue);
          }

          if (value !== undefined) {
            params = params.append(key, value);
          }
        }
      }
      return params;
    }
    return undefined;
  }
}
