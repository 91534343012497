import {
  Component,
  OnInit,
  ElementRef,
  ViewChild, OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { SharedStateService } from './../shared-state.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup, Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  setCurrentStep,
  setCurrentStepName,
  setDetail,
  setProgressValue,
} from '../store/onboarding.actions';
import { IState } from '../store/onboarding.reducer';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Nationality } from '@idp-education/ors-test-taker-bff-client-v1';
import { Title, Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { IDENTITYNO_REGEXP, ISSUING_AUTHORITY_REGEXP } from 'src/app/shared/sharedRegex';
import { DateTime } from 'luxon';
import { startEndSpaceValidator } from 'src/app/shared/validators/start-end-space-validator';
import { UserProfileService } from 'shared/services/user-profile.service';
@Component({
  selector: 'app-identity-detail',
  templateUrl: './identity-detail.component.html',
  styleUrls: ['./identity-detail.component.scss'],
})
export class IdentityDetailComponent
  implements OnInit, OnDestroy {
  state: IState;
  @ViewChild('uploadBox') uploadBox: ElementRef;
  @ViewChild('saveAndExitModal') confirmModal: ConfirmModalComponent;
  form: UntypedFormGroup;
  sub: Subscription;
  selectedLabels = {};
  nationalities: Array<Nationality> = [];
  private readonly pdfIcon = 'src/assets/images/pdfIcon.svg';
  constructor(
    private sharedState: SharedStateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<{ onboardingStore }>,
    private apiService: ApiService,
    private titleService: Title, 
    private metaTagService: Meta,
    private userProfileService: UserProfileService,
  ) {
    this.getNationalities();
    this.sharedState.setTitleAndSubscribe(
      'Provide your ID | IDP IELTS',
      null,
      5,
      80,
      'Identity Details',
      false,
      true
    );
    this.state = this.sharedState.getState();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  saveAndExitClick() {
    this.sharedState.saveAndExitClick(
      this.form.value,
      this.state,
      this.confirmModal
    );
  }

  onNextClick() {
    if (this.form.valid) {
      this.store.dispatch(setDetail({ detail: this.form.value }));
      this.sharedState.saveAndExit('detail', this.state).subscribe(() => {
        this.router.navigate(['/onboarding/review-profile']);
      }, (err) => {
        this.toastr.error(
          'Sorry, Saving failed, please try again'
        );
      }
      );
    }
  }

  expiryDateValidation() {
    return (formGroup: UntypedFormGroup) => {
      const expiryDateControl = formGroup.controls['expiryDate'];
      const date = formGroup.controls['expiryDate'].value;
      const dateTime = DateTime.fromFormat(date || '', 'dd/MM/yyyy');
      let error = null;
      if (expiryDateControl?.value?.length > 0 && expiryDateControl?.value?.length < 10) {
        return expiryDateControl.setErrors({ dateLengthMatch: true });
      } else {
      if ((date && !expiryDateControl.hasError('required')) && !dateTime.isValid) {
        error = { date: true };
        expiryDateControl.setErrors(error);
      } else if ((date && !expiryDateControl.hasError('required')) && !(dateTime.isValid && dateTime > DateTime.now().startOf('day'))) {
        error = { invalidExpireDate: true };
        expiryDateControl.setErrors(error);
      }
    }
    };
  }

  private prepareForm() {
    const { detail, userProfile } = this.state;
    // checking whether the user selected identity type and already saved identity type in user profile are same
    const identityTypeIdCheck = this.state?.selectedIdType?.id === userProfile?.identityDetails?.identificationTypeId;
    const userProfileIdentityDetails: any = identityTypeIdCheck ? {
      ...userProfile.identityDetails,
      nationalityId: userProfile?.nationalityId
    } : {};
    const form = this.fb.group({
      identityNo: [
        (detail && detail.identityNo) || (userProfileIdentityDetails?.number) || '',
        [
          Validators.required,
          Validators.pattern(IDENTITYNO_REGEXP),
          Validators.maxLength(20),
        ],
      ],
      countryOfNationality: [
        (detail && detail.countryOfNationality) ||
        this.sharedState.getOptionObject(userProfileIdentityDetails?.nationalityId, this.nationalities) || '',
        [Validators.required, Validators.maxLength(50)],
      ],
      identityTypeId: [
        (detail && detail.identityTypeId) || this.state?.selectedIdType?.code || userProfileIdentityDetails?.identificationTypeId || '',
        [Validators.required],
      ],
      issuingAuthority: [
        (detail && detail.issuingAuthority) || (userProfileIdentityDetails?.issuingAuthority) || '',
        [
          Validators.pattern(ISSUING_AUTHORITY_REGEXP),
          Validators.maxLength(50)
        ],
      ],
      expiryDate: [
        (detail && detail.expiryDate) || this.getSavedExpiryDate(userProfileIdentityDetails?.expiryDate) || '',
        [Validators.required],
      ],
    }, { validators: [startEndSpaceValidator, this.expiryDateValidation()] });
    return form;
  }

  getSavedExpiryDate(expiryDate) {
    this.userProfileService.fetchSavedExpiryDate(expiryDate);
  }

  ngOnInit(): void {
    this.form = this.prepareForm();
    setTimeout(async () => {
      if (this.uploadBox && this.state?.imageUrl) {
        const imageUrl = await this.userProfileService.getPhoto(this.userProfileService.modifiedS3Url(this.state?.imageUrl));
        this.userProfileService.setUploadBoxBackground(this.uploadBox, imageUrl, this.pdfIcon);
      }
    }, 1);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    if (this.state?.isAchievedLastStep) {
      this.router.navigate(['/onboarding/review-profile']);
    }
  }

  getNationalities() {
    this.apiService.GetNationality.subscribe(values => this.nationalities = values);
  }
}
