<feature-toggle-provider [features]="featureFlags">
    <div class="d-flex ps-3 pe-3 header-box justify-content-center align-items-center justify-content-md-between flex-column-reverse flex-md-row"
        [ngClass]="{'header-bg': getStatus() !== 'Re-mark in progress',
                'header-bk': getStatus() === 'Re-mark in progress'}">
        <div class="d-flex">
            <h3 class="card-title">{{getStatus()}}</h3>
            <img src="../../../../assets/images/icon-tick.svg" class="tickicon" alt="tick icon" *ngIf="!isPendingOfflineEOR()">
            <div class="hourglass-icon" *ngIf="isPendingOfflineEOR()"></div>
        </div>
        <div class="d-flex date-section">
            <h2>{{test?.productName}} {{getTestStartDate() | date: 'dd-MMM-yy'}}</h2>
        </div>
    </div>
    <div class="main-content">
        <div class="row no-margin share-box" *featureToggle="'shareSocialNetwork'">
            <span>You can now share your score online</span>
            <div class="social-box">
                <a href="#">
                    <img src="../../../../assets/images/icon-facebook-red.svg" alt="facebook">
                </a>
                <a href="#">
                    <img src="../../../../assets/images/icon-twitter-red.svg" alt="twitter">
                </a>
                <a href="#">
                    <img src="../../../../assets/images/icon-instagram-red.svg" alt="instagram">
                </a>
            </div>
        </div>
        <div class="result-wrapper">

        </div>
        <div class="row no-margin score-box mt-4 justify-content-center flex-column flex-md-reverse-row align-items-center">
            <div class=" download-result-wrapper d-flex flex-column flex-lg-row justify-content-end align-items-center align-items-end mb-3 w-100">
                <ng-container *featureToggle="'requestAndViewEOREnabled'">
                    <button *ngIf="test?.results" class="eorbtn m-0 btn d-flex gap-8 justify-content-center btnHover"
                        (click)="bookTestClick()">
                        <span class="text-nowrap">
                            Rebook IELTS test
                        </span>
                    </button>
                    <button *ngIf="showRequestRemark()" class="eorbtn m-0 btn w-sm-100 w-xs-100"
                        (click)="onRequestRemarkClick.emit(test)">
                        <span>Request re-mark</span>
                    </button>
                    <button *ngIf="showViewRemark()" class="eorbtn m-0 btn w-sm-100 w-xs-100" (click)="onViewRemarkClick.emit(test)">
                        <span>View re-mark</span>
                    </button>
                </ng-container>
                <div *ngIf="meta?.isLoading === false"
                  class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
                  <div class="d-flex action-content align-items-center flex-column justify-content-center">
                    <button type="button" (click)="onEORCompleteBooking(meta)"
                      class="not-start-btn btn actionbtn">
                      Complete Booking
                    </button>
                  </div>
                </div>
                <div *ngIf="meta?.isLoading === true"
                  class="not-available text-bold d-flex flex-column justify-content-center align-items-center">
                  <img class="loader" src="./assets/images/loader.svg" alt="">
                </div>
                <button *ngIf="showDownloadeTRF()" class="actionbtn btn download-result m-0 w-sm-100 w-xs-100"
                    (click)="downloadURL()">Download
                    result</button>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-centre justify-content-centre justify-content-md-between w-100 gap-2">
                <div class="overall" (click)="itemClick('Overall')" (keyup)="itemClick('Overall')">
                    <div *ngIf="!isAwaitingResult()">
                        <h2>Overall score</h2>
                        <div class="score-text">{{getResult('overallScore')}}</div>
                        <div *ngIf="activeState !== 'Overall'" class="arrow-down"></div>
                    </div>
                    <div *ngIf="getResult('overallScore') === '_' && isAwaitingResult()">
                        <h2>Result in Progress</h2>
                    </div>
                </div>
                <!--  TODO: WET CODE => Refactor needed -->
                <div class="item-container justify-content-center">
                    <div class="sub-container">
                        <div class="item-box"
                            [ngClass]="{'item-box-ssr': showOSR(),'item-box-disp': !showOSR(), 'active': activeState === 'Listening'}"
                            (click)="itemClick('Listening')" (keyup)="itemClick('Listening')">
                            <button *ngIf="showOSR()" class="retakebtn btn"
                                (click)="onRetakeClick('Listening')">Retake</button>
                            <h2 [ngClass]="{'pt-2': showOSR()}">Listening</h2>
                            <div class="score-text weight-bold">{{getResult('listeningScore')}}</div>
                            <div *ngIf="activeState !== 'Listening'" class="arrow-down"></div>
                        </div>
                        <div class="item-box"
                            [ngClass]="{'item-box-ssr': showOSR(),'item-box-disp': !showOSR(),'active': activeState === 'Reading'}"
                            (click)="itemClick('Reading')" (keyup)="itemClick('Reading')">
                            <button *ngIf="showOSR()" class="retakebtn btn"
                                (click)="onRetakeClick('Reading')">Retake</button>
                            <h2 [ngClass]="{'pt-2': showOSR()}">Reading</h2>
                            <div class="score-text weight-bold">{{getResult('readingScore')}}</div>
                            <div *ngIf="activeState !== 'Reading'" class="arrow-down"></div>
                        </div>
                    </div>
                    <div class="sub-container">
                        <div class="item-box"
                            [ngClass]="{'item-box-ssr': showOSR(),'item-box-disp': !showOSR(),'active': activeState === 'Writing'}"
                            (click)="itemClick('Writing')" (keyup)="itemClick('Writing')">
                            <button *ngIf="showOSR()" class="retakebtn btn"
                                (click)="onRetakeClick('Writing')">Retake</button>
                            <h2 [ngClass]="{'pt-2': showOSR()}">Writing</h2>
                            <div class="score-text weight-bold">{{getResult('writingScore')}}</div>
                            <div *ngIf="activeState !== 'Writing'" class="arrow-down"></div>
                        </div>
                        <div class="item-box"
                            [ngClass]="{'item-box-ssr': showOSR(),'item-box-disp': !showOSR(),'active': activeState === 'Speaking'}"
                            (click)="itemClick('Speaking')" (keyup)="itemClick('Speaking')">
                            <button *ngIf="showOSR()" class="retakebtn btn"
                                (click)="onRetakeClick('Speaking')">Retake</button>
                            <h2 [ngClass]="{'pt-2': showOSR()}">Speaking</h2>
                            <div class="score-text weight-bold">{{getResult('speakingScore')}}</div>
                            <div *ngIf="activeState !== 'Speaking'" class="arrow-down"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row no-margin discription mt-4">
            <div *ngIf="showScoreDescription">
                <h3 class="underlined-subtitle" i18n>Your {{activeState !== "Overall" ? activeState : ''}} score
                    explained
                </h3>
                <p *ngIf="activeState" i18n class="comment" [ngClass]="!showMore ? 'hide' : 'show'">
                    {{comment?.text}}
                    <ng-container *ngIf="comment?.improve">
                        <br><br> {{comment?.improve}}
                    </ng-container>
                </p>
                <a class="showlink" *ngIf="activeState && (comment?.improve ||comment?.text)"
                    (click)="showMore = !showMore" (keyup)="showMore = !showMore">
                    {{ showMore ? 'Show less' : 'Show more' }}
                </a>
            </div>
        </div>
        <app-upcoming-test-offline-payment-info *ngIf="isPendingOfflineEOR()" [showHR]="showOptions"
            customLabel="Remark Request Offline Payment in Progress" [testCentreCode]="test?.testCentreCode">
        </app-upcoming-test-offline-payment-info>
        <app-past-test-options *ngIf="showOptions" (onClickOptions)="onClickOptions.emit($event)"
            [optionForTest]="lists">
        </app-past-test-options>
    </div>
</feature-toggle-provider>
