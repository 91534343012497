<div class="offline-payment-info">
  <hr class="w-100">
  <div class="d-flex flex-row flex-wrap">
    <div class="d-flex flex-column flex-grow-1">
      <h2 class="my-0">Your offline payment application</h2>
    </div>
    <div class="title-text d-flex align-items-center text-orange">
      {{customLabel === '' ? 'Awaiting payment confirmation' : customLabel}}
      <div class="hourglass-icon"></div>
    </div>
  </div>
  <app-information-box type="secondary" [classes]="['d-flex']" icon="fa-info-circle">
    <div class="sub-copy text-dark-red flex-grow-1">About offline payment
    </div>
    <div class="w-100"></div>
    <ol class="sub-copy mb-0 text-dark-grey">
      <li>
        To Confirm your seat, pay now using the <a href="javascript:void(0);" (click)="openInstructionLink()"><u>instructions</u></a> provided here.
      </li>
      <li>
        Once the offline payment is made, please notify your test centre.
      </li>
    </ol>
  </app-information-box>
  <hr class="w-100 mt-4" *ngIf="showHR">
</div>
