export const generateUtmUrl = (url, code?): string => {
    if (!sessionStorage.getItem('utm_value')) {
        setUtminSession();   
    }
    const url_List = {
        cxUrl: 'https://ielts.idp.com',
        orsUrl: 'https://ielts.idp.com/book',
        cxPreparationUrl: 'https://ielts.idp.com/prepare/ielts-test-preparation-practice-tests-material',
        cxBandscoreUrl: 'https://ielts.idp.com/results/how-ielts-is-marked/band-score-calculator',
        cxSystemRequirements: 'https://ielts.idp.com/lp/ielts-online-system-requirements',
        bxSearchWizardUrl: 'https://bxsearch.ielts.idp.com/wizard',
        legalNoticeURLPrivacy: 'https://ielts.idp.com/legal-notices#ielts-online-privacy-notice',
        legalNoticeAusUrl: 'https://ielts.idp.com/legal-notices#ielts-australia-privacy-policy',
        legalNoticeTnc: 'https://ielts.idp.com/legal-notices#ielts-test-and-payment-terms-and-conditions',
        legalNotice: 'https://ielts.idp.com/legal-notices',
        bxInstructionURL: `https://ielts.idp.com/test-centres/${code}/offline-payment-instructions`
    }
    const utm_value = sessionStorage.getItem('utm_value');
    if (utm_value) {
        if (url.includes('://') && new URL(url).searchParams.size) {
            return url + utm_value
        } else {
            return url.includes('://') ? url + '?' + utm_value.substring(1) : url_List[url] + '?' + utm_value.substring(1);
        }
    } else {
        return url.includes('://') ? url : url_List[url]
    }
};

export const setUtminSession = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utm_url = formUtmparams(urlParams, 'u_id', 'utm_id') +
        formUtmparams(urlParams, 'u_src', 'utm_source') +
        formUtmparams(urlParams, 'u_mdm', 'utm_medium') +
        formUtmparams(urlParams, 'u_trm', 'utm_term') +
        formUtmparams(urlParams, 'u_cmp', 'utm_campaign') +
        formUtmparams(urlParams, 'u_cnt', 'utm_content');
    if (!sessionStorage.getItem('referrerUrl')) {
        sessionStorage.setItem('referrerUrl', (urlParams.get('referrerUrl') || document.referrer || document.location.origin)) 
    }  
    if (utm_url) {   
        sessionStorage.setItem('utm_value', utm_url);
    }
};

export const formUtmparams = (urlParams, encode_utm_value, utm_value) => {
    const utm_paranm = decodeURIComponent(urlParams.get(encode_utm_value) || decodeURIComponent(urlParams.get(utm_value)));
    return (utm_paranm && utm_paranm != 'null') ? '&' + encode_utm_value + '=' + utm_paranm : '';
};

export enum UtmLinkKeys {
    CxUrl = 'cxUrl',
    OrsUrl = 'orsUrl',
    CxPreparationUrl = 'cxPreparationUrl',
    CxBandscoreUrl = 'cxBandscoreUrl',
    CxSystemRequirements = 'cxSystemRequirements',
    BxSearchWizardUrl = 'bxSearchWizardUrl',
    legalNoticeURLPrivacy = 'legalNoticeURLPrivacy',
    LegalNoticeAusUrl = 'legalNoticeAusUrl',
    LegalNoticeTnc = 'legalNoticeTnc',
    LegalNotice = 'legalNotice',
    BxInstructionUrl = 'bxInstructionURL'
}

