
import { createReducer, on } from '@ngrx/store';
import {
    setCurrentStep,
    setOccupationSector,
    setGender,
    setEducationLevel,
    setOccupationLevel,
    setTestReason,
    setCountries,
    setIsAchievedLastStep,
    setProgressValue,
    setTotalSteps,
    setCurrentStepName,
    setImageUrl,
    setImageVersion,
    setDetail,
    setAddressDetail,
    setProfile,
    setUserProfile,
    setNationality,
    setFirstLanguages,
    setIdentificationType,
    setTerritory,
    setTestLocationId,
    setSelectedIdType,
    setOnboardingApplication,
    clearOnboardingStore,
    setAllIdentificationType
} from './onboarding.actions';
import { OccupationSector } from '@idp-education/ors-test-taker-bff-client-v1/model/occupationSector';
import { Gender } from '@idp-education/ors-test-taker-bff-client-v1/model/gender';
import { EducationLevel } from '@idp-education/ors-test-taker-bff-client-v1/model/educationLevel';
import { OccupationLevel } from '@idp-education/ors-test-taker-bff-client-v1/model/occupationLevel';
import { TestReason } from '@idp-education/ors-test-taker-bff-client-v1/model/testReason';
import { Country } from '@idp-education/ors-test-taker-bff-client-v1/model/country';
import {
  Application,
  GetTestLocationIdentificationTypesItem,
  IdentificationType,
  Language,
  UserProfileResponse,
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Nationality } from '@idp-education/ors-test-taker-bff-client-v1/model/nationality';
import { UserProfileTitle } from '@idp-education/ors-test-taker-bff-client-v1/model/userProfileTitle';
import { Territory } from '@idp-education/ors-test-taker-bff-client-v1';
import { IModel } from '../../../shared/models/components/dropdown';

export type OnboardingDetail = {
    countryOfNationality: IModel<Nationality>,
    expiryDate: string
    passportNo: string, // to be removed
    identityNo: string,
    issuingAuthority: string,
    identityTypeId: string
};

export type OnboardingAddressDetail = {
    address1: string,
    address2: string,
    city: string,
    country: IModel<Country>,
    postcode: string,
    province: IModel<Territory>,
};

export type OnboardingProfile = {
    gender: IModel<Gender>,
    regionOfResidence: Country,
    educationLevel: IModel<EducationLevel>,
    occupationSector: IModel<OccupationSector>,
    occupationLevel: IModel<OccupationLevel>,
    reasonTest: IModel<TestReason>,
    yearsStudyingEnglish: IModel<any>,
    locationStudyEnglish: string,
    intendLocation: IModel<Country>,
    firstLanguage: IModel<Language>,
    title: IModel<UserProfileTitle>,
    dateOfBirth: string,
    mobileNumber: string
};

export type IState = {
    userProfile?: UserProfileResponse,
    progressValue: number,
    totalSteps?: number,
    currentStepName?: string,
    imageUrl?: string | false,
    imageVersion?: string | false,
    detail?: OnboardingDetail,
    addressDetail?: OnboardingAddressDetail,
    profile?: OnboardingProfile,
    isAchievedLastStep: boolean,
    OccupationSectorList: OccupationSector[],
    GenderList: Gender[],
    EducationLevelList: EducationLevel[],
    OccupationLevel: OccupationLevel[],
    TestReasonList: TestReason[],
    Countries: Country[],
    currentStep: number,
    firstLanguage: any,
    identificationType: GetTestLocationIdentificationTypesItem[],
    allIdentificationType: GetTestLocationIdentificationTypesItem[],
    territories: {
        [key: string]: Territory[]
    },
    nationality: Nationality[],
    testLocationId: string,
    selectedIdType: IdentificationType,
    application: Application
};

export type IStage = 'passport' | 'detail' | 'address' | 'survey' | 'profile';

export const initialState: IState = {
    OccupationSectorList: null,
    GenderList: null,
    EducationLevelList: null,
    OccupationLevel: null,
    TestReasonList: null,
    Countries: null,
    currentStep: null,
    progressValue: 0,
    totalSteps: 0,
    currentStepName: '',
    imageUrl: false,
    imageVersion: '',
    isAchievedLastStep: false,
    firstLanguage: null,
    identificationType: [],
    allIdentificationType: [],
    territories: {},
    nationality: null,
    testLocationId: null,
    selectedIdType: null,
    application: null
};

const reducer = createReducer(
    initialState,
    on(setOccupationSector, (state, payload: { list: OccupationSector[] }) => ({
        ...state,
        OccupationSectorList: payload.list
    })),
    on(setFirstLanguages, (state, payload: { list: Language[] }) => ({
        ...state,
        firstLanguage: payload.list
    })),
    on(setGender, (state, payload: { list: Gender[] }) => ({
        ...state,
        GenderList: payload.list
    })),
    on(setEducationLevel, (state, payload: { list: EducationLevel[] }) => ({
        ...state,
        EducationLevelList: payload.list
    })),
    on(setOccupationLevel, (state, payload: { list: OccupationLevel[] }) => ({
        ...state,
        OccupationLevel: payload.list
    })),
    on(setTestReason, (state, payload: { list: TestReason[] }) => ({
        ...state,
        TestReasonList: payload.list
    })),
    on(setCountries, (state, payload: { list: Country[] }) => ({
        ...state,
        Countries: payload.list
    })),
    on(setCurrentStep, (state, { currentStep }) => ({
        ...state,
        currentStep
    })),
    on(setIsAchievedLastStep, (state, { isAchievedLastStep }) => ({
        ...state,
        isAchievedLastStep
    })),
    on(setProgressValue, (state, { progressValue }) => ({
        ...state,
        progressValue
    })),
    on(setTotalSteps, (state, { totalSteps }) => ({
        ...state,
        totalSteps
    })),
    on(setCurrentStepName, (state, { currentStepName }) => ({
        ...state,
        currentStepName
    })),
    on(setImageUrl, (state, { imageUrl }) => ({
        ...state,
        imageUrl
    })),
    on(setImageVersion, (state, { imageVersion }) => ({
        ...state,
        imageVersion
    })),
    on(setDetail, (state, { detail }) => ({
        ...state,
        detail
    })),
    on(setAddressDetail, (state, { addressDetail }) => ({
        ...state,
        addressDetail
    })),
    on(setProfile, (state, { profile }) => ({
        ...state,
        profile
    })),
    on(setUserProfile, (state, { userProfile }) => ({
        ...state,
        userProfile
    })),
    on(setNationality, (state, payload: { list: Nationality[] }) => ({
        ...state,
        nationality: payload.list
    })),
    on(setIdentificationType, (state, payload: { list: GetTestLocationIdentificationTypesItem[] }) => ({
        ...state,
        identificationType: payload.list
    })),
    on(setAllIdentificationType, (state, payload: { list: GetTestLocationIdentificationTypesItem[] }) => ({
        ...state,
        allIdentificationType: payload.list
    })),
    on(setTerritory, (state, payload: { list: Territory[], countryId: string }) => ({
        ...state,
        territories: {
            ...state.territories,
            [payload.countryId]: payload.list
        }
    })),
    on(setTestLocationId, (state, payload: { testLocationId: string }) => ({
        ...state,
        testLocationId: payload.testLocationId
    })),
    on(setSelectedIdType, (state, payload: { selectedIdType: IdentificationType }) => ({
        ...state,
        selectedIdType: payload.selectedIdType
    })),
    on(setOnboardingApplication, (state, payload: { application: Application }) => ({
        ...state,
        application: payload.application
    })),
    on(clearOnboardingStore, () => ({
        ...initialState
    })),
);

export function onboardingReducer(state: any, action: any) {
    return reducer(state, action);
}
