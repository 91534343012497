import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';
import { IState } from '../shared-state.service';
import { UserProfile, SSRSession as Session } from '@idp-education/ors-test-taker-bff-client-v1';

export type TestModule = 'A1' | 'A2' | 'AC' | 'B1' | 'B2' | 'GT';

export const TestModule = {
  A1: 'A1' as TestModule,
  A2: 'A2' as TestModule,
  AC: 'AC' as TestModule,
  B1: 'B1' as TestModule,
  B2: 'B2' as TestModule,
  GT: 'GT' as TestModule
};

export type TestCategory = 'IELTS' | 'IELTS_UKVI' | 'LIFE_SKILLS';

export const TestCategory = {
  IELTS: 'IELTS' as TestCategory,
  IELTSUKVI: 'IELTS_UKVI' as TestCategory,
  LIFESKILLS: 'LIFE_SKILLS' as TestCategory
};

export const getTestModule = (testType: string | undefined): TestModule[] => {
  try {
    const result: TestModule[] = [(testType || ',').split(',')[1] as TestModule];
    return result;
  } catch (e) {
    return [];
  }
};

export const getTestCategory = (testType: string | undefined): TestCategory[] => {
  try {
    const result: TestCategory[] = [(testType || '').split(',')[0] as TestCategory];
    return result;
  } catch (e) {
    return [];
  }
};

export const getTestTypeLabel = (type: string) => {
  const result = {
    'IELTS,GT': 'IELTS General Training',
    'IELTS,AC': 'IELTS Academic',
    'IELTS_UKVI,GT': 'IELTS for UKVI (General Training)',
    'IELTS_UKVI,AC': 'IELTS for UKVI (Academic)',
    'LIFE_SKILLS,A1': 'Life Skills A1',
    'LIFE_SKILLS,B1': 'Life Skills B1'
  }[type];
  return result ? result : '';
};

export const formatCurrencyTwoDecimals = (
  x: number | undefined | null,
  decimalSeparator: string | undefined | null,
  currencySymbol: string | undefined | null,
  currencyCodeIso3: string | undefined | null
) => {
  const LOG_ERROR_MSG = `Exception thrown in trying to format currency: amount ${x}, separator ${decimalSeparator}, symbol ${currencySymbol}, currencyCode ${currencyCodeIso3}`;
  if (
    x !== undefined &&
    decimalSeparator !== undefined &&
    currencySymbol !== undefined &&
    currencyCodeIso3 !== undefined &&
    x !== null &&
    decimalSeparator !== null &&
    currencySymbol !== null &&
    currencyCodeIso3 !== null
  ) {
    try {
      const parts = x.toFixed(2).split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${currencyCodeIso3} ${currencySymbol}${parts.join(decimalSeparator)}`;
    } catch (e: any) {
      console.error(LOG_ERROR_MSG);
      return '';
    }
  } else {
    console.error(LOG_ERROR_MSG);
    return '';
  }
};

export const dateToString = (date: NgbDateStruct) => `${date.year.toString().padStart(4, '0')}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}T00:00:00`;

export const formatDistance = (distance: number) => {
  if (distance < 1) {
    return (Math.max((distance * 10), 1)).toFixed(0) + '00 M';
  } else {
    return distance.toFixed(1) + ' KM';
  }
};

// construct params for seo-friendly url for result page
// example: target URL: https://<hostname>/book-ielts/type-ielts-academic/format-computer-delivered
//                      /location-melbourne-victoria-australia
// this method will return [
//   '/',
//   'book-ielts',
//   'type-ielts-academic', or ielts-general-training etc
//   'format-computer-delivered', or paper-based
//   'location-melbourne-victoria-australia'
// ]
export const constructResultUrl = (state: IState): string[] => {
  const getTestTypeUrl = (type: string) => {
    const result = {
      'IELTS,GT': 'IELTS General Training',
      'IELTS,AC': 'IELTS Academic',
      'IELTS_UKVI,GT': 'UKVI General Training',
      'IELTS_UKVI,AC': 'UKVI Academic',
      'LIFE_SKILLS,A1': 'Life Skills A1',
      'LIFE_SKILLS,B1': 'Life Skills B1'
    }[type];
    return result ? result : '';
  };
  const routes = ['/', 'book-ielts'];
  if (state.testType) {
    routes.push('type-' + getTestTypeUrl(state.testType).toLowerCase().toString().replace(/\s+/g, '-'));
  }
  if (state.testFormat && state.testFormat !== 'All') {
    routes.push('format-' + (state.testFormat === 'PB' ? 'paper-based' : 'computer-delivered'));
  }
  if (state.locLabel && state.locLabel !== '') {
    // regex will replace all other character than a-z0-9 with dash and remove double dashes
    // example: "Melbourne, Victoria, Australia" to "melbourne-victoria-australia"
    routes.push('location-' + state.locLabel.toLowerCase().toString().replace(new RegExp('[^0-9a-z]+', 'g'), '-'));
  }
  return routes;
};

export const setMeta = (state: IState, title: Title, meta: Meta) => {
  let format = '';
  let type = '';
  if (state.testFormat && state.testFormat !== 'All') {
    format = state.testFormat === 'PB' ? 'Paper Based ' : 'Computer Delivered ';
  }
  type = getTestTypeLabel(state.testType || '') + ' ';
  title.setTitle(`${format}${type}test Dates near ${state.locLabel} | IDP IELTS`);
  meta.updateTag({
    name: 'description',
    content: `Check available ${format.toLowerCase()}${type}test dates near ${state.locLabel} and book your IELTS test with IDP today.`
  });
};

export const is12am = (testStartLocalDateTime: string) => {
  const d = new Date(testStartLocalDateTime);
  if (d) {
    return (d.getHours() === 0 && d.getMinutes() === 0);
  } else {
    return false;
  }
};

export const getDuration = (ssrComponent: string) => {
  return (ssrComponent === 'Speaking' ? 20 : 60);
};

export const getUserName = ( userProfile: UserProfile) => {
  return userProfile?.firstName === '' ? userProfile?.lastName : userProfile?.firstName;
};

export const getTestTypeLabels: (type: string) => string = (type) => {
  const result = {
    'IELTS,GT': "IELTS General Training",
    'IELTS,AC': "IELTS Academic",
    'IELTS_UKVI,GT': "IELTS for UKVI (General Training)",
    'IELTS_UKVI,AC': "IELTS for UKVI (Academic)",
    'LIFE_SKILLS,A1': "Life Skills A1",
    'LIFE_SKILLS,B1': "Life Skills B1",
    'IELTS,GENERAL_TRAINING': "IELTS General Training",
    'IELTS,ACADEMIC': "IELTS Academic",
    'IELTS_UKVI,GENERAL_TRAINING': "IELTS for UKVI (General Training)",
    'IELTS_UKVI,ACADEMIC': "IELTS for UKVI (Academic)",

  }[type];
  return result ? result : '';
}

export const sortPrioritySessions = (sessions: Session[]): Session[] => {
  const availablePriorities = [] as number[];
  const priorityMap = {} as { [key: string]: Session[] };
  sessions.sort((a, b)  => {  
      const dayA = new Date(a.testStartLocalDatetime.substring(0, a.testStartLocalDatetime.length - 6)).setHours(0, 0, 0, 0);
      const dayB = new Date(b.testStartLocalDatetime.substring(0, b.testStartLocalDatetime.length - 6)).setHours(0, 0, 0, 0);
  
      if (dayA !== dayB) {
        return dayA - dayB;
      }
    });
  sessions.forEach(s => {
    if (s.testPriority !== undefined) {
      if (availablePriorities.indexOf(s.testPriority) === -1) {
        availablePriorities.push(s.testPriority);
        priorityMap[s.testPriority] = [s];
      } else {
        priorityMap[s.testPriority].push(s);
      }
    }
  });
  return priorityMap[Math.min(...availablePriorities)] || [];
};


