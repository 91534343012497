<div class="flexbox flex-column content align-items-center align-items-xxl-start">
    <div class="all-content">
        <h6 class="content-title pe-3 ps-3" i18n>Choose a new password</h6>
        <form class="form-horizontal mt-0 p-3">
            <div class="flexbox flex-column align-items-center align-items-lg-start">
                <div>
                    <div content>
                        <div class="form-group">
                            <table class="table">
                                <caption class="sr-only">Password Details</caption>
                                <th class="sr-only"></th>
                                <tr>
                                    <td>
                                        <input id="password" name="password" [type]="passwordType"
                                            placeholder="Choose your password" i18n-placeholder
                                            class="form-control input-md icon-inputbox text-start"
                                            (mousemove)="mouseOver($event)" [class.visibilityOnIcon]="visibility"
                                            (click)="changeVisiblity($event)" [class.visibilityOffIcon]="!visibility"
                                            [formControl]="$form.controls.password"
                                            [class.nudghInput]="checkShowError('password') && nudghInput['password']"
                                            (blur)="setNudgh('password')">
                                        <div class="d-table-cell d-sm-table-cell d-md-none" *ngIf="
                                                $form.controls.password.touched
                                                || $form.controls.confirmPassword.touched
                                                || $form.controls.password.dirty
                                                || $form.controls.confirmPassword.dirty">
                                            <app-password-validation-status [password]="$form.controls.password.value">
                                            </app-password-validation-status>
                                        </div>
                                    </td>
                                    <td class="d-none d-sm-none d-md-table-cell" rowspan="2">
                                        <div *ngIf="
                                                $form.controls.password.touched
                                                || $form.controls.confirmPassword.touched
                                                || $form.controls.password.dirty
                                                || $form.controls.confirmPassword.dirty">
                                            <app-password-validation-status [password]="$form.controls.password.value">
                                            </app-password-validation-status>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input id="comfirmPassword" name="comfirmPassword" [type]="passwordType"
                                            placeholder="Confirm your password" i18n-placeholder
                                            class="form-control input-md icon-inputbox text-start"
                                            (mousemove)="mouseOver($event)" [class.visibilityOnIcon]="visibility"
                                            (click)="changeVisiblity($event)" [class.visibilityOffIcon]="!visibility"
                                            [formControl]="$form.controls.confirmPassword"
                                            [class.nudghInput]="checkShowError('confirmPassword') && nudghInput['confirmPassword']"
                                            (blur)="setNudgh('confirmPassword')">
                                        <app-field-errors [control]="$form.controls.confirmPassword"
                                            componentId="confirmPassword" *ngIf="checkShowError('confirmPassword')">
                                        </app-field-errors>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="btn-submit-wrapper mt-5 flexbox justify-content-center">
                    <button [disabled]="$form.invalid" class="btn btn-multiAction" (click)="changePassword()">
                        <span class="flexbox align-items-center justify-content-center" i18n>
                            Next
                        </span>
                    </button>
                </div>
                <app-still-have-problem-link></app-still-have-problem-link>
            </div>
        </form>
    </div>
</div>