<div class="container-fluid">
  <div class="container-header">
    <div class="flexbox header-box mb-3">
      <div class="d-flex align-items-center ">
        <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3" (click)="backToMyTests()">
          <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
        </button>
        <h2 class="underlined-title">Institutions accepting One Skill Retakes</h2>
      </div>
    </div>
  </div>
  <div class="information-box">
    <app-information-box type="secondary" [classes]="['d-flex']" icon="fa-info-circle">
      <span class="sub-copy">
        The Australian Department of Home Affairs accepts IELTS One Skill Retake (OSR) for immigration purposes for
        certain subclasses.
        It is important to check the
        <a href=" https://immi.homeaffairs.gov.au/help-support/meeting-our-requirements/english-language"
          target="_blank">Department's website</a>
        to understand for which visa subclass you can use IELTS One Skill Retake
      </span>
    </app-information-box>
  </div>
  <div class="container-footer">
    <app-two-button [acceptText]="'Continue'" [rejectText]="'Cancel'" [inCenter]="false" [enableAcceptClick]="true"
      [showReject]="true" (rejectClick)="onCancelClick.emit()" (acceptClick)="onssrFormatClick.emit()">
    </app-two-button>
  </div>
</div>
