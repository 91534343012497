import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateService } from './can-activate.service';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PaymentManageComponent } from './payment-manage.component';
import { OfflinePaymentRequestComponent } from './offline-payment-request/offline-payment-request.component';
import { TipsCallbackComponent } from 'pages/payment/tips-callback/tips-callback.component';
import { VeritransCallbackComponent } from 'shared/components/payment/veritrans/veritrans-callback/veritrans-callback.component';
import { PaystackCallbackComponent } from 'shared/components/payment/paystack/paystack-callback/paystack-callback.component';
import { PaymentsCallbackComponent } from 'shared/components/payment/payments-callback/payments-callback.component';
import { BankAlfalahCallbackComponent } from 'shared/components/payment/bank-alfalah/bank-alfalah-callback/bank-alfalah-callback.component';
import { BracCallbackComponent } from 'shared/components/payment/brac/brac-callback/brac-callback.component';
import { ESewaCallbackComponent } from 'shared/components/payment/e-sewa/e-sewa-callback/e-sewa-callback.component';
import { PaydollarCallbackComponent } from 'shared/components/payment/paydollar/paydollar-callback/paydollar-callback.component';
import { NewebpayCallbackComponent } from 'shared/components/payment/newebpay/newebpay-callback/newebpay-callback.component';
import { StripeCallbackComponent } from 'shared/components/payment/stripe/stripe-callback/stripe-callback.component';
import { InipayCallbackComponent } from 'shared/components/payment/inipay/inipay-callback/inipay-callback.component';
import { HsbcOmniCallbackComponent } from 'shared/components/payment/hsbc-omni/hsbc-omni-callback/hsbc-omni-callback.component';

const routes: Routes = [{
  path: '',
  component: PaymentManageComponent,
  data: { state: 'payment' },
  children: [
    {
      path: 'card-detail',
      component: CardDetailComponent,
      canActivate: [CanActivateService],
      data: { state: 'carddetail', pagename: 'Finalise Payment' }
    },
    {
      path: 'confirmation',
      component: ConfirmationComponent,
      canActivate: [CanActivateService],
      data: { state: 'confirmation', pagename: 'Booking Complete' }
    },
    {
      path: 'offline-payment',
      component: OfflinePaymentRequestComponent,
      canActivate: [CanActivateService],
      data: { state: 'offlinepayment', pagename: 'Offline payment' }
    },
    {
      path: 'tips-callback',
      component: TipsCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'veritrans/:status',
      component: VeritransCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'paystack-callback',
      component: PaystackCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'payments-callback',
      component: PaymentsCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'payments-callback/:status',
      component: PaymentsCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'brac/:status',
      component: PaymentsCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'bank-alfalah-callback',
      component: BankAlfalahCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'bank-alfalah-callback/:status',
      component: BankAlfalahCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'brac-callback/:status',
      component: BracCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'e-sewa-callback/:status',
      component: ESewaCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'paydollar-callback',
      component: PaydollarCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'newebpay/:status',
      component: NewebpayCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'stripe-callback/:transactionId',
      component: StripeCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'inipay-callback/:status',
      component: InipayCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    },
    {
      path: 'hsbcomni-callback',
      component: HsbcOmniCallbackComponent,
      data: { state: 'confirm' },
      canActivate: [CanActivateService]
    }
  ]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class PaymentRoutingModule {

}
