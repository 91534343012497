import { UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1/model/userProfileResponse';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

const SET_UD = 'setUserDetails';
const SET_UFT = 'setUserFTime';
const SET_R = 'setRequested';
const SET_AU = 'setAllUser';
const FROM_LOGIN = 'SetFromLoginPage';
const SET_LO = 'setLogout';

export const setUserDetails = createAction(SET_UD, props<UserProfileResponse | null>());

export const setUserFTime = createAction(SET_UFT, props<DateTime | null>());

export const setRequested = createAction(SET_R, props<{ requested: boolean }>());

export const setAllUser = createAction(SET_AU,
    props<{ userDetails: UserProfileResponse | null, userFetchedTime: DateTime | null, userToken: string | null }>());

export const setFromLoginPage = createAction(FROM_LOGIN, props<{ fromLoginPage: boolean }>());
export const setLogOut = createAction(SET_LO, props<{ logout: boolean }>());
