import { EverFlowHelper } from '../../../shared/helper/everflow.helper';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
  HostListener,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import * as braintree from 'braintree-web';
import { UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { concatMap, delay, map, take } from 'rxjs/operators';
import {
  setCurrentStep,
  setTotalSteps,
  setProgressValue,
  setCurrentStepName,
  setActivePayment,
  setPayingStatus,
  setFee,
  IPaymentStatus,
  setOfflinePayment,
  setFromOffline
} from '../store/payment.actions';
import { first } from 'rxjs/operators';
import { paymentIState, selectFromOffline, selectOfflinePayment } from '../store/payment.reducer';
import {
  Application,
  ApplicationResponse,
  CreateApplicationResponse, ProductFee,
  ProductFeeService,
  ReceiptService,
  ReferenceDataService
} from '@idp-education/ors-test-taker-bff-client-v1';
import { PaymentsService } from 'src/app/shared/services/payment.services';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { ResetState, SetProductType, SetTestLocalTimeZone } from '../../booking/store/booking.actions';
import { Title, Meta } from '@angular/platform-browser';
import * as uuid from 'uuid';
import { ContentModalComponent } from 'src/app/shared/components/content-modal/content-modal.component';
import { CreditCardComponent } from 'src/app/shared/components/payment/credit-card/credit-card.component';
import { ToastrService } from 'ngx-toastr';
import { PurchaseItem } from '@paypal/paypal-js';
import { TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1/api/testCentre.service';
import { getTestCentreCode, initializePaymentMethodV2 } from '../../../shared/utils/initialize-payment-method';
import { setTestLocationId } from '../../onboarding/store/onboarding.actions';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { PaymentUtilService } from '../payment-util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnderageConsentFormModalComponent } from 'src/app/shared/components/underage-consent-form-modal/underage-consent-form-modal.component';
import { selectUserFullName } from 'src/app/store/user/user.selectors';
import { selectConsentAccepted } from 'src/app/pages/booking/store/booking.selectors';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { isIOCProduct, isOSRIOCProduct } from 'shared/utils/is-ioc-product';
import { OfflinePaymentRequestService } from 'shared/services/offline-payment-request.service';
import { selectEnableNewOfflinePayment } from 'store/global.reducer';
const getCountryISO2 = require('country-iso-3-to-2');
const { featureFlags, site } = require('../../../../assets/appSettings.json');

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedType = 'credit';
  speakingtesttime: any;
  lrwtesttime: any;
  hostedFieldsInstance: braintree.HostedFields;
  threeDSecure: any;
  cardholdersName: string;
  amount: number;
  productName: string;
  productId: string;
  testLocationId: string;
  isNotIOLProduct: boolean;
  speakingUrl: string;
  testLocalTimeZone$ = this.store.select(appState => appState.bookingStore.testLocalTimezone);
  @ViewChild('ErrorCode') ErrorCode: ElementRef;
  @ViewChild('paymentCardElem') paymentCardElem: CreditCardComponent;
  @ViewChild('btnSection') btnSection: ElementRef;
  @ViewChild('paymentErrorModal') paymentErrorModal: ContentModalComponent;
  @ViewChild('paymentErrorModalPaypal') paymentErrorModalPaypal: ContentModalComponent;

  token: string;
  paymentStatus: '' | 'Error' | 'Process' = '';
  cardErrors = [];
  showCvvModal = false;
  cardValidate = false;
  cardNumberValid = false;
  sub: Subscription;
  application: CreateApplicationResponse;
  currentApp: Application;
  state: paymentIState;
  deviceData: any;
  requestMode: 'Api' | 'Mock';
  enable3DSPayment: boolean;
  isPayButtonEnable = false;
  pay: number;
  payCode: string;
  userCountryCodeAlpha2: string;
  currentPaymentMethod: IPaymentMethods = IPaymentMethods.CREDITCARD;
  addressDetailsForm: UntypedFormGroup;
  public addThisCard = new UntypedFormControl('');
  public featureFlags: any = {
    ...featureFlags,
    mockBraintree: (site?.environmentName || '').toUpperCase() === 'PERF'
  };
  purchaseItem: PurchaseItem[];
  paymentMethods: IPaymentMethods[] = [];
  offlineMethodSelected = false;
  veritransMethodSelected = false;
  userFullName: string;
  tcPaymentMethod = [];

  private _paymentId: string;
  public get paymentId(): string {
    return this._paymentId;
  }
  private _profileId: string;
  public get profileId(): string {
    return this._profileId;
  }
  private _clientId: string;
  public get clientId(): string {
    return this._clientId;
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private paymentsService: PaymentsService,
    private store: Store<{ paymentStore, globalStore, applicationsStore, bookingStore, userStore }>,
    private applicationService: ApplicationsService,
    private testCentreService: TestCentreService,
    private loadingService: LoadingService,
    private everFlowHelper: EverFlowHelper,
    private titleService: Title, private metaTagService: Meta,
    private feeService: ProductFeeService,
    private refDataService: ReferenceDataService,
    private fb: UntypedFormBuilder,
    private toast: ToastrService,
    private paymentUtilService: PaymentUtilService,
    private userProfile: UserProfileService,
    private modalService: NgbModal,
    private receiptService: ReceiptService,
    private offlinePaymentRequestService: OfflinePaymentRequestService
  ) {
    this.enable3DSPayment = this.paymentsService.check3DSstatus();
    this.titleService.setTitle('Make payment to confirm booking | IDP IELTS');
    this.sub = this.store.select(appState => appState.paymentStore).subscribe(x => {
      this.state = x;
    });

    this.sub.add(this.applicationService.GetCurrentApplication().pipe(first()).subscribe(x => {
      if (!x || x && isOSRIOCProduct(x.bookings[0]?.bookableProductName)) {
        this.router.navigate(['/my-account']);
      } else {
        this.currentApp = x;
        if (
          isIOCProduct(this.currentApp.bookings[0]?.bookableProductName)
        ) {
          this.isNotIOLProduct = true;
          this.store.dispatch(SetProductType({
            isNotIOLProduct: true
          }));
          this.store.dispatch(SetTestLocalTimeZone({
            testLocalTimezone: this.currentApp?.timeZone
          }));
        }

        combineLatest([
          this.store.select(selectOfflinePayment),
          this.store.select(selectFromOffline),
          this.store.select(selectEnableNewOfflinePayment),
        ]).pipe(first(), concatMap(([isOfflinePaymentDefault, isFromOffline, isNewOfflinePaymentEnabled]) => {
          if (isOfflinePaymentDefault) {
            this.currentPaymentMethod = IPaymentMethods.OFFLINE;
          }
          return initializePaymentMethodV2({
            store: this.store,
            isNotIOLProduct: this.isNotIOLProduct,
            locationId: this.currentApp.bookings[0]?.testLocationId,
            testCentreService: this.testCentreService,
            receiptService: this.receiptService,
            testCentreCode: getTestCentreCode(x),
            isOSRorEORProduct: false,
            isFromOffline,
            isNewOfflinePaymentEnabled
          });
        })).subscribe(({ paymentMethod, location, testcentrePaymentMethods }) => {
          this.offlinePaymentRequestService.setTCPaymentMethods(testcentrePaymentMethods);
          this.tcPaymentMethod = testcentrePaymentMethods;
          this.paymentMethods = paymentMethod;
          this._clientId = location?.paypalConfiguration?.clientId;
        });
      }
    }));
    this.sub.add(this.store.select(state => state.applicationsStore.currentApplication).subscribe((payLoad: Application) => {
      if (payLoad && payLoad.applicationPayments?.length > 0 && payLoad.bookings[0]) {
        this.productName = payLoad.bookings[0].bookableProductName;
        this.productId = payLoad?.bookings[0]?.bookableProductId;
        this.testLocationId = payLoad.bookings[0].testLocationId;
        this.store.dispatch(setTestLocationId({ testLocationId: this.testLocationId }));
        this._paymentId = payLoad.applicationPayments[0].id;
      }
    }));
    this.sub.add(this.store.select(state => state.bookingStore.isNotIOLProduct).subscribe(payload => {
      this.isNotIOLProduct = payload;
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.speakingUrl).subscribe(x => {
      if (x) {
        this.speakingUrl = x;
      }
    }));
    this.sub.add(this.store.select(appState => appState.globalStore.requestMode).subscribe(x => {
      this.requestMode = x;
      if (this.requestMode === 'Api') {
        this.getFee().subscribe((data) => this.setFeeData(data), () => this.handleError());
      } else if (this.requestMode === 'Mock') {
        this.setFeeData({
          charges: [],
          currencyIsoCode: 'USD',
          totalAmount: 20
        } as ProductFee);
      }
    }));
    this.userProfile.getUserProfile(false).pipe(first()).subscribe((data: any) => this._profileId = data.userProfileId);
    const speakingAndLrw = this.paymentUtilService.getSpeakingAndLRW(this.currentApp);
    const speakerTestTime = this.paymentUtilService.getSpeakingTestTime(speakingAndLrw?.speaking);
    const lrwTestTime = this.paymentUtilService.getLRWTestTime(speakingAndLrw?.lrw);
    this.store.dispatch(this.paymentUtilService.setSpeakingTestTimeAction({ speakingtesttime: speakerTestTime }));
    this.store.dispatch(this.paymentUtilService.setLrwTestTimeAction({ lrwtesttime: lrwTestTime }));
    this.store.dispatch(setCurrentStep({ currentStep: this.isNotIOLProduct ? 0 : 3 }));
    this.store.dispatch(setCurrentStepName({ currentStepName: 'Payment' }));
    this.store.dispatch(setTotalSteps({ totalSteps: 3 }));
    this.store.dispatch(setProgressValue({ progressValue: 100 }));
    this.sub.add(this.store.select(state => state.applicationsStore.currentApplication)
      .pipe(
        take(1),
        concatMap((application: ApplicationResponse) => {
          return this.store.select(selectConsentAccepted)
            .pipe(
              take(1),
              map(consentAccepted => ({
                application,
                consentAccepted
              }))
            );
        })
      )
      .subscribe(({ application, consentAccepted }) => {
        if (application?.underAgeConsentDetails?.isUnderAgeOnTestDate) {
          if (!consentAccepted) {
            this.openConsentFormPopup();
          }
        }
      }));
    this.sub.add(this.store.select(selectUserFullName).subscribe(userFullName => this.userFullName = userFullName));
  }

  private setFeeData(fee: ProductFee) {
    this.store.dispatch(setFee({ fee }));
    this.pay = fee.totalAmount;
    this.payCode = fee.currencyIsoCode;
    const tax = fee.totalAmount - fee.baseAmount;
    this.purchaseItem = [{
      name: this.productName || 'Online Test',
      sku: this.productId || '',
      quantity: `1`,
      category: 'DIGITAL_GOODS',
      unit_amount: {
        value: `${fee.baseAmount}`,
        currency_code: `${this.payCode}`
      },
      tax: {
        value: `${tax.toFixed ? tax.toFixed(2) : tax}`,
        currency_code: `${this.payCode}`
      }
    }];
  }

  private handleError() {
    this.paymentErrorModal?.open()?.result.finally(() => this.backToMyAccount());
  }

  backToMyAccount() {
    this.isNotIOLProduct ? window.location.href = this.speakingUrl : this.router.navigate(['/my-account']);
  }

  get applicationId() {
    return this.currentApp?.id;
  }

  get applicationPaymentId() {
    return Array.isArray(this.currentApp?.applicationPayments) && this.currentApp?.applicationPayments.length
      && this.currentApp?.applicationPayments.reduce((a, b) => (new Date(a.createdOn) > new Date(b.createdOn) ? a : b)).id;
  }

  private getFee(): Observable<ProductFee> {
    if(this.productId) {
      return this.feeService.getTestLocationProductFee(uuid.v4(), this.productId, this.testLocationId)
      .pipe(map(res => res?.activeFee), first());
    }
  }

  ngOnInit(): void {
    this.addressDetailsForm = this.prepareBillingAddressForm();
    this.refDataService.getCountry().subscribe(countries => {
      this.userCountryCodeAlpha2 = getCountryISO2(countries.items
        .find(x => x.id === this.currentApp?.userProfile?.addressDetails?.countryId)?.code);
    });
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
  }

  private prepareBillingAddressForm() {
    return this.fb.group({
      email: ['',
        { validators: [Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)] }
      ],
      mobileNumber: [''],
    });
  }

  onPaymentStatusChange(status: IPaymentStatus) {
    this.store.dispatch(setPayingStatus({ status }));
  }

  ngAfterViewInit(): void {
    this.checkButtonSticky();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.store.dispatch(setFromOffline({ fromOffline: false }));
    this.loadingService.resetLoadingCounter();
  }

  onValidityChange({ isValid, errors }) {
    this.cardErrors = errors;
    this.cardNumberValid = isValid;
    this.isPayButtonEnable = !!isValid;
  }

  onCardTypeChange(event: IPaymentMethods) {
    this.offlineMethodSelected = event === 'OFFLINE';
    this.isPayButtonEnable = false;
    this.veritransMethodSelected = event === 'VERITRANS';
  }

  showModal() {
    this.showCvvModal = !this.showCvvModal;
  }

  onCreateBraintree() {
    this.hostedFieldsInstance = this.paymentCardElem.hostedFieldsInstance;
    this.threeDSecure = this.paymentCardElem.threeDSecureInstance;
  }

  onCreateError() {
    this.loadingService.resetLoadingCounter();
  }

  OnPayClick() {
    this.cardErrors = [];
    if (!this.hostedFieldsInstance) {
      throw new Error('braintree not created');
    }
    this.paymentCardElem.blurBackground = true;
    this.paymentStatus = 'Process';
    this.isPayButtonEnable = false;
    this.cdr.detectChanges();
    this.loadingService.increaseLoadingCounter();
    if (this.enable3DSPayment) {
      if (this.addressDetailsForm?.valid) {
        this.payWith3DSAuthentication();
      } else {
        return;
      }
    } else {
      this.payWithout3DSAuthentication();
    }

  }

  payWith3DSAuthentication() {
    const findNestedError = (e: any) => {
      if (e?.details && e.details?.originalError) {
        return findNestedError(e.details?.originalError);
      } else {
        // TODO Need to be discussed with backend
        return `Message: Payment failed. Reason: ${e?.error?.message || ''}`;
      }
    };
    const billingAddressValue = this.addressDetailsForm?.value;
    this.hostedFieldsInstance.tokenize()
      .then((payload) => {
        return this.threeDSecure.verifyCard({
          amount: this.currentApp.applicationPayments[0].amount,
          nonce: payload.nonce,
          bin: payload.details.bin,
          email: billingAddressValue.email,
          billingAddress: {

            phoneNumber: billingAddressValue?.mobileNumber?.e164Number,

          },
          onLookupComplete: (_: any, next: any) => {
            next();
          },
        });
      }).then((payload) => {
        if (payload.liabilityShifted) {
          this.doPay(this.ErrorCode?.nativeElement?.value ?? '', payload.nonce);
        } else {
          this.loadingService.decreaseLoadingCounter();
          this.setErrorClass();
          console.error('No Liability Shifted');
          this.clearHostedFields();
          this.hostedFieldsInstance.teardown();
          this.paymentCardElem.cardNumberValid = false;
          this.paymentCardElem.initPayment();
        }
      }).catch((error) => {
        this.paymentStatus = '';
        this.loadingService.decreaseLoadingCounter();
        this.store.dispatch(setPayingStatus({ status: 'failed' }));
        console.error('error : ', error);
        this.setErrorClass();
        this.clearHostedFields();
        this.hostedFieldsInstance.teardown();
        this.paymentCardElem.cardNumberValid = false;
        this.paymentCardElem.initPayment();
        this.toast.error(findNestedError(error), 'Payment failed');
        throw error;
      });
  }

  payWithout3DSAuthentication() {
    this.hostedFieldsInstance.tokenize()
      .then((payload) => {
        this.doPay(this.ErrorCode?.nativeElement?.value ?? '', payload.nonce);
        this.loadingService.decreaseLoadingCounter();
      }).catch((error) => {
        this.paymentStatus = '';
        this.store.dispatch(setPayingStatus({ status: 'failed' }));
        console.log(error);
        this.setErrorClass();
        this.clearHostedFields();
      });
  }

  clearHostedFields() {
    this.hostedFieldsInstance.clear('number');
    this.hostedFieldsInstance.clear('cvv');
    this.hostedFieldsInstance.clear('expirationMonth');
    this.hostedFieldsInstance.clear('expirationYear');
    this.hostedFieldsInstance.clear('cardholderName');
  }

  doPay(mode: any, nonce: any) {
    this.loadingService.increaseLoadingCounter();
    this.paymentsService.DoPayment(mode ?? '', nonce, this.currentApp, this.paymentCardElem.deviceData, this.paymentCardElem.token)
      .pipe(first(), delay(3000)).subscribe(data => {
        this.everFlowHelper.processEverflowConversion(this.currentApp);
        this.onApprovePayment(data);
        this.loadingService.decreaseLoadingCounter();
      },
        () => {
          this.loadingService.decreaseLoadingCounter();
          this.store.dispatch(setPayingStatus({ status: 'failed' }));
          this.setErrorClass();
          this.clearHostedFields();
          this.hostedFieldsInstance.teardown();
          this.paymentCardElem.cardNumberValid = false;
          this.paymentCardElem.initPayment();
        },
        () => { /* empty */ });
  }

  private onApprovePayment(x: any) {
    this.store.dispatch(setActivePayment({ activePayment: false }));
    this.store.dispatch(setPayingStatus({ status: 'success' }));
    this.store.dispatch(ResetState());
    this.router.navigate(['/payment/confirmation']).then();
  }
  setErrorClass() {
    this.paymentStatus = 'Error';
    this.renderer.addClass(this.paymentCardElem?.cardSection?.nativeElement, 'shake');

    this.cdr.detectChanges();
    setTimeout(() => {
      this.paymentStatus = '';
      this.renderer.removeClass(this.paymentCardElem?.cardSection?.nativeElement, 'shake');
      this.isPayButtonEnable = false;
      this.paymentCardElem.blurBackground = false;
    }, 2000);
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    if (this.paymentStatus === 'Process') {
      $event.returnValue = true;
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    if (this.paymentCardElem?.CvvModal) {
      const clickedInside = this.paymentCardElem?.CvvModal?.nativeElement.contains(target) ||
        this.paymentCardElem?.infoBtn?.nativeElement?.contains(target);
      if (!clickedInside) {
        if (this.showCvvModal) {
          this.showCvvModal = false;
        }
      }
    }
  }

  @HostListener('document:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])
  public onScroll() {
    this.checkButtonSticky();
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent() {
    this.showCvvModal = false;
  }

  checkButtonSticky() {
    try {
      const elem = this.paymentCardElem?.cardBackground?.nativeElement;
      if (document.documentElement.clientHeight + document.documentElement.scrollTop >=
        (elem?.offsetHeight + this.paymentCardElem?.cardContent?.nativeElement?.offsetTop + 230 + 30)) {
        this.renderer.removeClass(this.btnSection?.nativeElement, 'btn-sticky');
      } else if (this.renderer?.addClass) {
        this.renderer?.addClass(this?.btnSection?.nativeElement, 'btn-sticky');
      }
    } catch (error) { /* empty */ }
  }

  onAcceptClicked() {
    this.paymentErrorModalPaypal.closeModal(true);
  }

  onApprovePaypal(event: { id: string }) {
    this.onApprovePayment({ id: event.id });
  }

  onErrorPaypal(err) {

    if (err?.message === 'Detected popup close') {
      this.toast.warning('Payment is canceled');
    } else if (Array.isArray(err?.errors) && err?.errors[0]) {
      try {
        const reason = (err?.errors[0] as string)?.match('(?<=Reason:).*')[0];
        this.paymentErrorModalPaypal.subTitle = reason || 'Something is wrong';
      } catch (error) {
        this.paymentErrorModalPaypal.subTitle = 'Something is wrong';
      }
    } else {
      this.paymentErrorModalPaypal.subTitle = err.message || 'Something is wrong';
    }
    this.paymentErrorModalPaypal.open();
  }

  onOfflineContinue() {
    this.store.dispatch(setOfflinePayment({
      isOffline: true
    }));
    this.router.navigate(['/payment/offline-payment']);
  }

  openConsentFormPopup(): void {
    const modalRef = this.modalService
      .open(UnderageConsentFormModalComponent, { size: 'lg', animation: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.userFullName = this.userFullName;
  }
}
