import { Observable } from 'rxjs';

export type ImageTransformType = 'mirror' | 'rotate-left' | 'rotate-right';

export function mirrorImage(
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  x = 0,
  y = 0,
  horizontal = false,
  vertical = false
) {
  ctx.save();
  ctx.setTransform(-1, 0, 0, 1, ctx.canvas.width, 0);
  ctx.drawImage(image, 0, 0, x, y);
  ctx.restore();
}

export function rotate(
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  degrees
) {
  // ctx.canvas.style.width = '20%';
  if (Math.abs(degrees) === 90 || Math.abs(degrees) === 270) {
    ctx.canvas.width = image.height;
    ctx.canvas.height = image.width;
  } else {
    ctx.canvas.width = image.width;
    ctx.canvas.height = image.height;
  }
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  if (Math.abs(degrees) === 90 || Math.abs(degrees) === 270) {
    ctx.translate(image.height / 2, image.width / 2);
  } else {
    ctx.translate(image.width / 2, image.height / 2);
  }
  ctx.rotate((degrees * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
}

export function getImageEl(url: string) {
  const img = new Image();
  img.setAttribute('crossorigin', 'anonymous');
  img.src = url;
  return img;
}

export function imageTransformer(url: string, type: ImageTransformType, fileName: string): Observable<{ file: File, url: string }> {
  return new Observable((sub) => {
    const image = getImageEl(url);
    image.onerror = (e) => {
      sub.error(e);
      sub.complete();
    };
    image.onload = (e: Event) => {
      try {
        const img = e.target as HTMLImageElement;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        if (type === 'mirror') {
          mirrorImage(ctx, img, img.width, img.height, false, true);
        } else if (type === 'rotate-left') {
          rotate(ctx, img, -90);
        } else if (type === 'rotate-right') {
          rotate(ctx, img, 90);
        }
        canvas.toBlob(
          (blob) => {
            sub.next({
              file: new File([blob], fileName, { type: 'image/jpeg' }),
              url: URL.createObjectURL(blob)
            });
            sub.complete();
          },
          'image/jpeg',
          1
        );

      } catch (error) {
        sub.error(error);
        sub.complete();
      }
    };
  });
}

export function base64ToBlob(base64String) {
  // Validate base64 string format
  if (!base64String || typeof base64String !== 'string' || !base64String.startsWith('data:')) {
    console.info('BX Error: Invalid base64 string provided');
  }

  // Extract MIME type and data
  const splitData = base64String.split(';base64,');
  const mimeType = splitData[0].replace('data:', '');

  // Validate MIME type to restrict allowed image formats
  const allowedMimeTypes = ['image/png', 'image/jpeg', 'image/gif'];
  if (!allowedMimeTypes.includes(mimeType)) {
    console.info('BX Error: Unsupported image format. Only PNG, JPEG, and GIF allowed.');
  }

  // Decode base64 data and create blob
  const base64Data = splitData[1];
  const byteString = atob(base64Data);
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });

  return blob;
}

