import { Component } from '@angular/core';

@Component({
  selector: 'app-offline-payment-instruction',
  templateUrl: './offline-payment-instruction.component.html',
  styleUrls: ['./offline-payment-instruction.component.scss']
})
export class OfflinePaymentInstructionComponent {

}
