<div *ngFor="let test of applications; let i = index;">
    <div class="flexbox header-box">
        <h3 class="card-title">{{title}}</h3>
        <img src="../../../../assets/images/Icon-material-close.svg" class="erroricon" alt="error icon">
    </div>
    <div class="main-content up-test-item">
        <div class="row no-margin">
            <div class="col-12 col-lg-7 no-padding">
                <div class="row no-margin" *ngIf="test.startDateTimeSpeakingUtc">
                    <div class="flexbox test-item no-padding pe-2 col-12">
                        <div class="side-circle gray">
                            <p class="month">{{getTestDate(test.startDateTimeSpeakingUtc,'month')}}</p>
                            <p class="day weight-semi">{{getTestDate(test.startDateTimeSpeakingUtc,'day')}}</p>
                            <p class="year">{{getTestDate(test.startDateTimeSpeakingUtc,'year')}}</p>
                        </div>
                        <div class="test-time">
                            <div class="test-name">
                                <h2 class="{{textClass}}">{{stTitle || ''}}</h2>
                            </div>
                            <div class="time {{textClass}}">
                                <img src="../../../../assets/images/time-icon.svg" class="icon {{textClass}}" alt="time icon" />
                                <span>
                                    <h3>{{getTestDate(test.startDateTimeSpeakingUtc,'time')}} ({{getTimezone()}})</h3>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row no-margin mt-4" *ngIf="test.startDateTimeLrwUtc">
                    <div class="flexbox test-item no-padding pe-2 col-12">
                        <div class="side-circle gray">
                            <p class="month">{{getTestDate(test.startDateTimeLrwUtc,'month')}}</p>
                            <p class="day weight-semi">{{getTestDate(test.startDateTimeLrwUtc,'day')}}</p>
                            <p class="year">{{getTestDate(test.startDateTimeLrwUtc,'year')}}</p>
                        </div>
                        <div class="test-time">
                            <div class="test-name">
                                <h2 class="{{textClass}} d-lg-block d-none"> {{lrwTitle || ''}} </h2>
                                <h2 class="{{textClass}} d-lg-none d-block"> {{lrwTitleMd || ''}} </h2>
                            </div>
                            <div class="time {{textClass}}">
                                <img src="../../../../assets/images/time-icon.svg" class="icon {{textClass}}" alt="time icon" />
                                <span>
                                    <h3>{{getTestDate(test.startDateTimeLrwUtc,'time')}} ({{getTimezone()}})</h3>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-5 mt-lg-0 mt-3 no-padding">
                <div class="description-content">
                    <div class="d-none d-lg-flex left-side">
                        <img src="../../../../assets/images/Icon-material-error.svg" alt="error icon">
                    </div>
                    <div class="right-side">
                        <img class="d-inline d-lg-none" src="../../../../assets/images/material-error.svg" alt="error icon">
                        Your booking was cancelled because you did not complete your profile.
                        You can request a refund or reschedule your test by viewing options below.
                    </div>
                </div>
            </div>
        </div>
        <app-booking-cancelled-options *ngIf="showOptions"></app-booking-cancelled-options>
    </div>

</div>