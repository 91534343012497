import { IInstitution } from './select-Institution.component';
export default [
  {
    id: 1,
    flags: [
      { Id: 1, label: 'IELTS Academic', type: 'IELTS Academic' },
      { Id: 1, label: 'IELTS General', type: 'IELTS General' },
      { Id: 1, label: 'IELTS Online Academic', type: 'IELTS Online Academic' },
      { Id: 1, label: 'IELTS Online General', type: 'IELTS Online General' },
    ],
    subtitle: 'The University of Melbourne',
    title: 'The University of Melbourne',
  }, {
    id: 2,
    flags: [{ Id: 1, label: 'IELTS Academic', type: 'IELTS Academic' },
    { Id: 1, label: 'IELTS General', type: 'IELTS General' }, ],
    subtitle: 'Australian National University (ANU)',
    title: 'Australian National University (ANU)'
  }, {
    id: 3,
    flags: [{ Id: 1, label: 'IELTS General', type: 'IELTS General' },
    { Id: 1, label: 'IELTS Online Academic', type: 'IELTS Online Academic' }, ],
    subtitle: 'University of Sydney',
    title: 'University of Sydney'
  }, {
    id: 3,
    flags: [{ Id: 1, label: 'IELTS Online Academic', type: 'IELTS Online Academic' },
    { Id: 1, label: 'IELTS Online General', type: 'IELTS Online General' }, ],
    subtitle: 'University of Sydney',
    title: 'University of Sydney'
  }
] as IInstitution[];
