import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Country, Territory } from '@idp-education/ors-test-taker-bff-client-v1';
import { isString } from 'lodash';
import { Subscription } from 'rxjs';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { IModel } from '../../../models/components/dropdown';

@Component({
  selector: 'app-address-details-form',
  templateUrl: './address-details-form.component.html',
  styleUrls: ['./address-details-form.component.scss']
})
export class AddressDetailsFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: UntypedFormGroup = null;
  @Input() showTitle = false;
  countryList: Array<Country> = [];
  territoryList: Array<Territory>;
  getCountryObservable: Observable<Country[]>;
  subscription: Subscription = new Subscription();
  constructor(private loadingService: LoadingService,
              private apiService: ApiService) {
    this.getCountryObservable = this.getCountries();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.getCountryObservable.subscribe(data => {
        if (!data) {
          return;
        }
        const countryControl = this.form.get('country');
        const val = countryControl.value;
        if (isString(val)) {
          const countryOption = data.find(i => i.id === val);
          if (countryOption) {
            countryControl.setValue({
              Id: countryOption.id,
              label: countryOption.name,
              option: { ...countryOption }
            } as IModel<any>);
          }
        }
        countryControl.valueChanges.subscribe(value => {
          this.form.get('province').setValue('');
          this.territoryList = [];
          if (value) {
            this.fetchTerritory(value);
          }
        });
      });
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const countryId = this.form && this.form.get('country').value;
    if (countryId) {
      this.fetchTerritory(countryId);
    }

  }
  fetchTerritory(countryId: string) {
    const countryCode = this.convertCountryIdToCountryCode(countryId);
    this.subscription.add(countryCode.subscribe(code => {
      this.getTerritory(code);
    }));
  }
  convertCountryIdToCountryCode(countryId: string | IModel<any>): Observable<string> {
    this.loadingService.increaseLoadingCounter();
    const cid = isString(countryId) ? countryId : (countryId as IModel<any>).Id;
    if (this.countryList.length) {
      this.loadingService.decreaseLoadingCounter();
      return of(this.countryList.find(i => i.id === cid).code);
    } else {
      return new Observable(sub => {
        this.getCountryObservable.subscribe(countries => {
          sub.next(countries.find(i => i.id === countryId).code);
          this.loadingService.decreaseLoadingCounter();
        });
      });
    }
  }
  getCountries() {
    const gco = this.apiService.GetCountries;
    gco.subscribe(c => {
      if (c !== null) {
        this.countryList = c;
      }
    });
    return gco;
  }
  getTerritory(countryCode: string) {
    return this.apiService.GetTerritory(countryCode).subscribe(c => {
      if (c !== null) {
        this.territoryList = c;
      }
    });
  }
  selectChange(
    e: IModel<any>,
    controlName) { /* empty */ }
}
