import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { setAllUser, setFromLoginPage } from 'store/user/user.actions';

@Injectable({
    providedIn: 'root'
})
export class HaveCognitoProfile  {
    isFromLogin: boolean;
    constructor(private store: Store<{ userStore }>,
                private router: Router, private userProfileService: UserProfileService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>((observer) => {
            if(localStorage.getItem('fromLoginPage') !== undefined) {
                this.store.dispatch(setFromLoginPage({ fromLoginPage: localStorage.getItem('fromLoginPage') === 'true'}));
            }
            this.store.select(st => st.userStore.fromLoginPage).subscribe((payload) => {
                if (payload) {
                    observer.next(true);
                } else {
                    Auth.currentAuthenticatedUser()
                        .then(() => {
                            try {
                                this.userProfileService.getUserProfile().subscribe({
                                    error: e => observer.next(true),
                                    next: () => observer.next(this.router.parseUrl('/my-account'))
                                }).unsubscribe();
                            } catch (error) { /* empty */ }

                        })
                        .catch(() => {
                            observer.next(this.router.parseUrl('/account'));
                        });
                }

            });
        });
    }
}
