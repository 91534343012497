import {
  trigger,
  query,
  style,
  group,
  transition,
  animate,
  animateChild
} from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition(`account=>*`, [
      query(':enter, :leave', [
        style({
          width: '100%',
        })
      ]),
      query(':enter', [
        style({
          position: 'relative',
          right: '100%',
          zIndex: 1
        })
      ]),
      query(':leave', [
        style({
          position: 'absolute',
          opacity: 1,
        })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('200ms', style({ opacity: 0 }))
        ], { optional: true }),
        query(':enter', [
          animate('400ms', style({ right: '0' }))
        ])
      ]),
    ]),
    transition(`signin<=>signup,signin<=>resetPassword,step1<=>step2,step2<=>stepFinal`, [
      query(':enter, :leave', [
        style({
          width: '100%',
        })
      ]),
      query(':enter', [
        style({
          position: 'relative',
          right: '100%',
          zIndex: 1
        })
      ]),
      query(':leave', [
        style({
          position: 'absolute',
          opacity: 1,
        })
      ], { optional: true }),
      group([
        query(':leave', [
          animate('200ms', style({ opacity: 0 }))
        ], { optional: true }),
        query(':enter', [
          animate('400ms', style({ right: '0' }))
        ])
      ]),
    ]),
    transition(`*=>account, void=>signup,void=>signin,void=>resetPassword`, [
      query(':enter, :leave', [
        style({
          width: '100%',
        })
      ]),
      query(':leave', [
        style({
          position: 'relative',
          right: '0%',
          zIndex: 1
        })
      ], { optional: true }),
      query(':enter', [
        style({
          position: 'absolute',
          opacity: 0,
        })
      ]),
      group([
        query(':enter', [
          animate('600ms', style({ opacity: 1 }))
        ]),
        query(':leave', [
          animate('600ms', style({ right: '100%' }))
        ], { optional: true })
      ]),
    ]),

  ]);
