import { Component, OnInit, OnDestroy } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/validators/must-match';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Country, Gender, Nationality } from '@idp-education/ors-test-taker-bff-client-v1';
import { setBackAction } from 'src/app/store/global.actions';
import { isEmpty } from 'lodash';
import { Title, Meta } from '@angular/platform-browser';
import { CacheService } from 'src/app/shared/services/cache.service';
import { DateTime } from 'luxon';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';
import { dateLengthValidator, nameValidator } from 'src/app/shared/validators/custom-validators';
import { confirmEmailMatch } from 'src/app/shared/validators/confirm-email-match';
import { DATE_REGEXP, EMAIL_REGEXP, IDENTITYNO_REGEXP, NAME_REGEXP } from 'src/app/shared/sharedRegex';
import { getAutomationTestMobileFieldToggle } from '../../../shared/utils/automation-test-toggle';
import { titleGenderValidator } from 'src/app/shared/validators/gender-title.validator';
import { AuthService } from 'shared/services/auth.service';
import { ExpiryDateValidatorsHelper } from 'shared/helper/expiry-date-validation.helper';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';

const sha1 = require('sha1');

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  showLoading = false;
  visibility = false;
  enterEmail = '';
  nudghInput = {};
  currentMouseOver: string;
  invalidMessageSignUp = '';
  hasError = false;
  hrefValues = {
    legalNoticeURL: generateUtmUrl(UtmLinkKeys.legalNoticeURLPrivacy)
  }
  customMessages = {
    identityNo: {
      pattern: () => $localize`Special characters are not allowed`,
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    },
    email: {
      pattern: () => $localize`A valid email address is required`,
    },
    expiryDate: {
      pattern: () => $localize`Invalid expiry date`
    },
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`,
    },
  };
  userTitle: { name: string, value: string }[] = [
    { name: 'Mr', value: 'MR' },
    { name: 'Mrs', value: 'MRS' },
    { name: 'Miss', value: 'MISS' },
    { name: 'Dr', value: 'DR' },
  ];
  $form: UntypedFormGroup;
  genderList: Gender[] = [];
  countryList: Array<Country> = [];
  nationalityList: Array<Nationality> = [];
  BirthDateTooltip = 'Your date of birth';
  under16YearsOld = false;

  constructor(
    private fb: UntypedFormBuilder,
    private cacheService: CacheService,
    private toastr: ToastrService,
    private router: Router,
    private apiService: ApiService,
    private userProfileService: UserProfileService,
    public loadingService: LoadingService,
    private route: ActivatedRoute,
    private cognitoError: CognitoErrorService,
    private store: Store<{ bookingStore, globalStore }>,
    private titleService: Title, private metaTagService: Meta,
    private authService: AuthService) {
    this.titleService.setTitle('Create your IDP account | IDP IELTS');
    this.route.queryParams.subscribe(result => {
      this.enterEmail = result && result.email && result.email;
    });
    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: '/account' }));
    this.$form = this.defineForm();
  }

  ngOnDestroy(): void {
    this.cacheService.resumeStorageEvent();
    this.loadingService.resetLoadingCounter();
  }

  ngOnInit() {
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: 'Sign up for a new IDP account with an email ID and start your journey with IELTS Online.'
      },
    );
    this.getCountries();
    this.getGender();
    this.getNationalities();
    const email = localStorage.getItem('emailID');
    if (this.$form && email !== '') {
      this.$form.get('email').setValue(email);
    }
  }

  defineForm() {
    return this.fb.group({
      id: [''],
      lastName: [''],
      firstName: [''],
      title: ['', [Validators.required]
      ],
      country: ['', Validators.required],
      gender: ['',
        [Validators.required]
      ],
      email: [this.enterEmail || '', [
        Validators.pattern(EMAIL_REGEXP),
        Validators.required
      ]],
      confirmEmail: ['', [
        Validators.pattern(EMAIL_REGEXP),
        Validators.required
      ]],
      mobileNumber: ['', [
        Validators.required,
      ]],
      birthDate: ['', [
        Validators.pattern(DATE_REGEXP),
        Validators.required,
      ]],
      identityNo: ['', [Validators.required, Validators.pattern(IDENTITYNO_REGEXP),
      Validators.maxLength(50)]],
      countryOfNationality: ['', [Validators.required]],
      expiryDate: [''],
      pass: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(new RegExp('^()(?=.*[A-Z])(?=.*[a-z ])(?=.*[0-9])(?=.*[@$!%*#?&.,:;"\'+/<=>\\\\\[\\]^_|{}~()\\-]).{8,}$'))
      ]],
      confirmPass: [''],
      termAndCondition1: ['', [Validators.required]],
      termAndCondition2: [''],
      communicationsCheckbox: [''],
    }, {
      validators: [
        nameValidator,
        MustMatch('pass', 'confirmPass'),
        confirmEmailMatch('email', 'confirmEmail'),
        this.checkDate(),
        ExpiryDateValidatorsHelper.expiryDateValidator(),
        dateLengthValidator
      ]
    });
  }

  onSubmit() {
    if (this.$form.status === 'INVALID') {
      return;
    }
    this.signUp(this.$form.value);
  }

  onKeyPressLastName($event: KeyboardEvent) {
    if ($event.key.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi)) {
      return false;
    }
  }

  public noWhitespaceValidator(control: UntypedFormGroup) {
    const firstName = control.controls['firstName'];
    const lastName = control.controls['lastName'];

    if (firstName.value) {
      const isWhitespace = firstName.value.trim().length === 0;
      if (isWhitespace) {
        firstName.setErrors({ required: { message: $localize`Field is required` } });
      } else {
        const errors = { ...firstName?.errors };
        delete errors?.required;
        firstName.setErrors(isEmpty(errors) ? null : { ...errors });
      }
    }
    if (lastName.value) {
      const isWhitespace = lastName.value.trim().length === 0;
      if (isWhitespace) {
        lastName.setErrors({ required: { message: $localize`Field is required` } });
      } else {
        const errors = { ...lastName?.errors };
        delete errors?.required;
        lastName.setErrors(isEmpty(errors) ? null : { ...errors });
      }
    }
  }

  private checkDate() {
    return (formGroup: UntypedFormGroup) => {
      const birthDate = formGroup.controls['birthDate'];
      if (!birthDate.value || (birthDate.errors && !birthDate.errors.date)) {
        return;
      }
      const bdDate = DateTime.fromFormat(birthDate.value || '', 'd/M/yyyy');
      const today = DateTime.now();
      const years = DateTime.now().diff(bdDate, 'years').years;

      // set error on matchingControl if validation fails
      if (bdDate.startOf('day') > today.startOf('day')) {
        birthDate.setErrors({ date: true });
      } else {
        birthDate.setErrors(null);
      }
    };
  }

  async signUp(user) {

    try {
      this.loadingService.increaseLoadingCounter();
      this.showLoading = true;
      localStorage.setItem('Registering', '1');
      const email = user.email.toLowerCase();
      const createdUser = await Auth.signUp({
        username: sha1(email),
        password: user.pass,
        attributes: {
          phone_number: user.mobileNumber.e164Number,
          email,
        }
      }).catch(error => {
        this.showLoading = false;
        this.loadingService.decreaseLoadingCounter();
        this.updateMessageSignUpInvalid(error);
        localStorage.setItem('Registering', '-1');
      });
      if (createdUser !== undefined) {
        this.loadingService.increaseLoadingCounter();
        const userProfileRequest = this.userProfileService.setDefaultValuesToUserProfile(user);
        await Auth.signIn(email, user.pass).catch((error) => {
          this.updateMessageSignUpInvalid(error);
        });
        Auth.currentAuthenticatedUser().then(usr => {
          const token = usr.signInUserSession.accessToken.jwtToken;
          this.loadingService.increaseLoadingCounter();
          this.authService.token = token;
          this.userProfileService.insertProfile(userProfileRequest).pipe(first())
            .subscribe(() => {
              this.userProfileService.getUserProfile().pipe(first()).subscribe();
              this.store
                .select((appStore) => appStore.bookingStore)
                .pipe(first())
                .subscribe((x) => {
                  localStorage.setItem('Registering', '0');
                  if (x.latestPageUrl) {
                    this.router.navigate([x.latestPageUrl]);
                  } else {
                    this.router.navigate(['/my-account']);
                  }
                });
              this.loadingService.decreaseLoadingCounter();
            }, (error) => {
              this.loadingService.decreaseLoadingCounter();
              this.updateMessageSignUpInvalid(error);
              localStorage.setItem('Registering', '-1');
            });
          this.loadingService.decreaseLoadingCounter();
        }).catch(error => {
          this.showLoading = false;
          this.loadingService.decreaseLoadingCounter();
          this.updateMessageSignUpInvalid(error);
          localStorage.setItem('Registering', '-1');
        });
      }
    } catch (error) {
      this.loadingService.decreaseLoadingCounter();
      this.showLoading = false;
      this.updateMessageSignUpInvalid(error);
      localStorage.setItem('Registering', '-1');
    }
  }

  updateMessageSignUpInvalid(error: Error) {
    this.hasError = true;
    this.invalidMessageSignUp = this.cognitoError.getErrorMessage(error);
    setTimeout(() => {
      this.resetError();
    }, 6000);
  }

  resetError() {
    this.hasError = false;
    this.invalidMessageSignUp = '';
  }

  private getGender() {
    this.apiService.getGender.subscribe(values => {
      if (Array.isArray(values)) {
        this.genderList = [...values
          .filter(i => i.name.toLowerCase() !== 'other')
        ];
        const currentValidatorFn = this.$form.validator;
        this.$form.setValidators([
          currentValidatorFn,
          titleGenderValidator(values),
        ]);
      }
    });
  }

  private getCountries() {
    const gco = this.apiService.GetCountries;
    gco.subscribe(c => {
      if (c !== null) {
        this.countryList = c;
      }
    });
    return gco;
  }

  private getNationalities() {
    this.apiService.GetNationality.subscribe(values => {
      if (Array.isArray(values)) {
        this.nationalityList = [...values];
      }
    });
  }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }
}
