import { ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';
import { UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1/model/userProfileResponse';
import { createReducer, createSelector, on, Selector } from '@ngrx/store';
import {
    setActivePayment,
    setCurrentStep,
    setCurrentStepName,
    setHasTimer,
    setLrwtesttime,
    setProgressValue,
    setSpeakingtesttime,
    setTotalSteps,
    setUserProfile,
    setPayingStatus,
    IPaymentStatus,
    setClear,
    setFee,
    setOfflinePayment,
    setFromTips,
    setFromOffline
} from './payment.actions';


export type paymentIState = {
    userProfile?: UserProfileResponse,
    progressValue: number,
    currentStep?: number,
    totalSteps?: number,
    currentStepName?: string,
    detail?: any,
    speakingtesttime?: { from: Date, to: Date },
    lrwtesttime?: { from: Date, to: Date },
    applicationId: string,
    activePayment: boolean,
    hasTimer: boolean,
    paymentStatus: IPaymentStatus,
    productFee?: ProductFee,
    isOffline?: boolean,
    fromTips?: boolean,
    fromOffline?: boolean
};

const intialState: paymentIState = {
    progressValue: 0,
    currentStep: 0,
    totalSteps: 0,
    currentStepName: '',
    applicationId: null,
    speakingtesttime: null,
    lrwtesttime: null,
    userProfile: null,
    activePayment: false,
    hasTimer: true,
    paymentStatus: 'not started',
    productFee: null,
    isOffline: false,
    fromTips: false,
    fromOffline: false
};

export const paymentReducer = createReducer(
    intialState,
    on(setProgressValue, (state, { progressValue }) => ({
        ...state,
        progressValue
    })),
    on(setCurrentStep, (state, { currentStep }) => ({
        ...state,
        currentStep
    })),
    on(setTotalSteps, (state, { totalSteps }) => ({
        ...state,
        totalSteps
    })),
    on(setCurrentStepName, (state, { currentStepName }) => ({
        ...state,
        currentStepName
    })),
    on(setSpeakingtesttime, (state, { speakingtesttime }) => ({
        ...state,
        speakingtesttime
    })),
    on(setLrwtesttime, (state, { lrwtesttime }) => ({
        ...state,
        lrwtesttime
    })),
    on(setUserProfile, (state, { userProfile }) => ({
        ...state,
        userProfile
    })),
    on(setActivePayment, (state, { activePayment }) => ({
        ...state,
        activePayment
    })),
    on(setHasTimer, (state, { hasTimer }) => ({
        ...state,
        hasTimer
    })),
    on(setOfflinePayment, (state, { isOffline }) => ({
        ...state,
        isOffline
    })),
    on(setFromTips, (state, { fromTips }) => ({
        ...state,
        fromTips
    })),
    on(setPayingStatus, (state, { status }) => ({
        ...state,
        paymentStatus: status
    })),
    on(setClear, (state) => ({
        ...intialState
    })), on(setFee, (state, { fee }) => ({
        ...state,
        productFee: fee
    })),
    on(setFromOffline, (state, { fromOffline }) => ({
        ...state,
        fromOffline
    })),
);

export const getState = (state: { paymentStore: paymentIState }) => state.paymentStore;

export const isOfflinePayment = (state: paymentIState) => state?.isOffline;

export const getFromTips = (state: paymentIState) => state.fromTips;

export const getFromOffline = (state: paymentIState) => state.fromOffline;

const createPaymentSelector = (selectMethod) => createSelector(
  getState,
  selectMethod
);


export const selectOfflinePayment: Selector<{ paymentStore: paymentIState }, any> = createPaymentSelector(
  isOfflinePayment
);
export const selectFromTips: Selector<{ paymentStore: paymentIState }, any> = createPaymentSelector(
  getFromTips
);
export const selectFromOffline: Selector<{ paymentStore: paymentIState }, any> = createPaymentSelector(
    getFromOffline
);


