import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IList } from '../../../models/components/past-test';
@Component({
  selector: 'app-past-test-options',
  templateUrl: './past-test-options.component.html',
  styleUrls: ['./past-test-options.component.scss']
})
export class PastTestOptionsComponent {
  expanded = false;
  @ViewChild('childContainer') childContainer: ElementRef;
  @ViewChild('parentContainer') parentContainer: ElementRef;
  @Output() onClickOptions: EventEmitter<any> = new EventEmitter();
  @Input() optionForTest: IList[];
  toggleClicked = false;
  constructor(private renderer: Renderer2,
              public deviceService: DeviceDetectorService) { }


  toggleClick() {
    this.expanded = !this.expanded;
    const parentElement = this.parentContainer?.nativeElement;
    const childElement = this.childContainer?.nativeElement;
    if (parentElement && childElement) {
      this.renderer.setStyle(parentElement, 'transition', 'height 0.3s');
      if (this.expanded) {
        this.renderer.setStyle(parentElement, 'height', `${childElement.offsetHeight}px`);
      } else {
        this.renderer.setStyle(parentElement, 'height', '0');
      }
    }
  }
}
