import { EORLocalStorageItemKeys, IOCLocalStorageItemKeys, OSRLocalStorageItemKeys } from 'pages/payment/payment.enum';

export const restoreLocalData = (paymentType: string) => {
  let restoredData: any;
  switch (paymentType) {
    case 'IOC':
      restoredData = JSON.parse(localStorage.getItem(IOCLocalStorageItemKeys.applicationsStore)).currentApplication;
      break;
    case 'EOR':
      restoredData = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication));
      break;
    case 'OSR':
      restoredData = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
      break;
    default:
      break;
  }
  return restoredData;
};
