import { Gender, Nationality } from '@idp-education/ors-test-taker-bff-client-v1';
import { createAction, props } from '@ngrx/store';

export const SET_UP_EMAILADRESS = 'setUpEmailAddress';
export const SET_UP_PASSWORD = 'setupPassword';
export const SET_UP_ACCOUNT_INFO = 'setUpAccountInfo';
export const SET_STEP_COMPLETE = 'setStepComplete';
export const SET_STEP_CURRENT = 'setStepCurrent';
export const CLEAR_VALUES_ENTERED='clear email,password and checkbox values'

export const setUpEmailAddress = createAction(
    SET_UP_EMAILADRESS,
  props<{
    emailAddress: string;
  }>()
);

export const setupPassword = createAction(
    SET_UP_PASSWORD,
    props<{
      password: string;
      communications?: boolean;
      termsAndCondition?: boolean;
      supportService?:boolean;
      personalData?:boolean
    }>()
);

export const setUpAccountInfo = createAction(
    SET_UP_ACCOUNT_INFO,
    props<{
        details: {
            identityNo: string;
            firstName: string;
            lastName: string;
            dateOfBirth: string;
            genderId: string;
            mobileNumber: any;
            nationalityId: string;
            nationalityOther: string;
            issuingAuthority: string;
            expiryDate: string;
        }
    }>()
);

export const setStepComplete = createAction(
  SET_STEP_COMPLETE,
  props<{
    route: string,
    isComplete: boolean;
  }>()
);

export const setStepCurrent = createAction(
  SET_STEP_CURRENT,
  props<{
    route: string,
  }>()
);

export const clearEmailAndPasswordPageValues = createAction(CLEAR_VALUES_ENTERED);


