import { Component } from '@angular/core';

@Component({
  selector: 'app-term-and-condition-hong-kong',
  templateUrl: './term-and-condition-hong-kong.component.html',
  styleUrls: ['./term-and-condition-hong-kong.component.scss']
})
export class TermAndConditionHongKongComponent {

}
