const BASE_ID_CONSTANTS = {
  idType: 'Identification Type',
  country: 'Country of Nationality (per ID document)',
  expiryDate: 'Expiry Date',
  issuingAuthority: 'ID Issuing Authority',
  countryPlaceholder: 'Country of Nationality',
  expiryDatePlaceholder: 'Expiry Date DD / MM / YYYY',
  issuingAuthorityPlaceholder: 'ID Issuing Authority',
};

export const ID_CONSTANTS = {
  P: {
    ...BASE_ID_CONSTANTS,
    idNumber: 'Passport Number',
    idNumberPlaceholder: 'Passport Number',
    isExpiryDateMandatory: true,
  },
  I: {
    ...BASE_ID_CONSTANTS,
    idNumber: 'National ID Number',
    idNumberPlaceholder: 'National ID Number',
    isExpiryDateMandatory: false,
  },
};
