<div class="offline-payment-info">
  <hr class="w-100">
  <div class="d-flex flex-row flex-wrap">
    <div class="d-flex flex-column flex-grow-1">
      <h2 class="my-0">About payment</h2>
      <h6 *ngIf="receiptNumber" class="my-2 refId fw-normal">Reference ID: {{ receiptNumber }}</h6>
    </div>
    <div class="title-text d-flex align-items-center text-orange">
      {{customLabel === '' ? 'Awaiting payment confirmation' : customLabel}}
      <div class="hourglass-icon"></div>
    </div>
  </div>
  <app-information-box type="secondary" [classes]="['d-flex w-100']" icon="fa-info-circle">
    <div class="d-flex flex-column flex-sm-row justify-content-between align-items-center w-100">
      <div class="d-flex flex-column flex-grow-1 ">
        <div class="sub-copy text-dark-red flex-grow-1">You've chosen to pay using one of the payment methods.
        </div>
        <div class="w-100"></div>
        <ol class="sub-copy mb-0 text-dark-grey">
          <li>
            To confirm your seat, view available payment options and pay now.
          </li>
          <li>
            Once the payment is made, please notify your test centre.
          </li>
          <li *ngIf="receiptNumber && isOfflineToOnlineTC && isChangePaymentAvailable">
            If you want to pay your test online click on change payment method to proceed.
          </li>
        </ol>
      </div>
      <div class="d-flex flex-column flex-shrink-1 ms-0 ms-sm-2 mt-2 mt-sm-0">
        <button
          class="btn btn-danger fw-normal font"
          (click)="viewPaymentOptions()"
          >
          View payment options
        </button>
        <button
          class="btn btn-secondary fw-normal bg-white text-black mt-2"
          *ngIf="receiptNumber && isOfflineToOnlineTC && isChangePaymentAvailable"
          (click)="changePaymentMethod()"
          >
          Change payment method
        </button>
      </div>
    </div>
  </app-information-box>
  <hr class="w-100 mt-4" *ngIf="showHR">
</div>
