import { Injectable } from '@angular/core';
import { Duration } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class CalcRemainingTimeService {
  callToStopTimer: () => void;

  getTime(targetTime: number, resultType: 'full' | 'minutes' | 'hours' = 'full') {
    const isPlural = (count: number, str: string): string => Math.floor(count) > 1 ? `${str}s` : str;
    const duration = Duration.fromObject({ second: targetTime });
    let result = '';
    if (duration.as('seconds') < 1) {
      result = null;
    }
    if (duration.as('days') >= 1) {
      result = Math.floor(duration.as('days')) + ' day' + (duration.rescale().days > 1 ? 's' : '');
    } else if (duration.as('days') < 1 && duration.as('seconds') >= 1) {
      if (duration.as('hours') >= 1) {
        if (resultType === 'full' && duration.rescale().minutes !== 0) {
          result = duration.toFormat(`h \'hour${duration.rescale().hours > 1 ? 's' : ''} and\' m \'minute${duration.rescale().minutes > 1 ? 's' : ''}\'`);
          /* istanbul ignore else */
        } else if (resultType === 'hours' || (resultType === 'full' && duration.rescale().minutes === 0)) {
          result = duration.toFormat(`h \'hour${duration.rescale().hours > 1 ? 's' : ''}\'`);
        }
      } else if (duration.as('minutes') <= 60 && duration.as('minutes') >= 1) {
        const min = duration.as('minutes');
        const seconds = duration.rescale().seconds;
        if (resultType === 'full') {
          result =
            isPlural(+min, `${Math.floor(+min)} minute`) +
            (
              seconds > 0 ?
                (' and ' + isPlural(+seconds, `${Math.floor(+seconds)} second`)) :
                ''
            );
        } else if (resultType === 'minutes') {
          result = isPlural(+min, `${Math.floor(+min)} minute`);
        }
      } else {
        const sec = duration.as('seconds');
        result = isPlural(sec, `${Math.floor(+sec)} second`);
      }
    } else {
      result = null;
    }
    if (result === null) {
      this.callToStopTimer();
      return result;
    }
    return result;
  }

  toStopTimerCallBack(fn: () => void) {
    this.callToStopTimer = fn;
  }
}
