<div *ngIf="showMessage" class="action-content mb-2">
    <div class="left-sec">
        <span class="alert-icon"></span>
        <ng-container *ngIf="!hasCustomMessage; else customContent">
            <span class="message">
                {{message}}
            </span>
        </ng-container>
        <ng-template #customContent>
            <ng-content></ng-content>
        </ng-template>
        
    </div>
    <a *ngIf="cta !== ''" [attr.tabindex]="10" class="actionbtn btn" href="javascript:void()" (click)="onActionBtnClick(route)"> {{cta}} </a>
</div>