<div class="mb-3 form-group" [formGroup]="form"
  [ngClass]="inOneLine && 'd-flex justify-content-md-start align-items-md-center justify-content-center align-items-start flex-column flex-md-row'">
  <div style="overflow: hidden;">
    <div *ngIf="title" [title]="nextToLabelToolTip ? '' : title" for="fcn" class="form-label" i18n>
      {{ title }}
      <ng-container *ngIf="nextToLabelToolTip">
        <span>
          <i class="fa fa-info-circle text-danger"
             aria-hidden="true"
             placement="right"
             [tooltipClass]="nextToLabelToolTipClass"
             [ngbTooltip]="nextToLabelToolTip"
             triggers="click hover"
          ></i>
        </span>
      </ng-container>
    </div>
  </div>
  <div class="d-flex input-cn">
    <div class="input-group">
      <div *ngIf="tooltip" class="tooltip-box" #tooltipBox>{{ tooltip }}</div>
      <input container="body" *ngIf="!tooltip && type !== 'phone' && !showPasswordIcon"
        [type]="type ? type : 'text'" (change)="onChange($event)" (blur)="required ? setNudge() : ''"
        (focusout)="showValidationTick($event.target.value)" (focusin)="hideValidationTick()"
        [placeholder]="placeHolder" i18n-placeholder class="form-control custom-input-control field-height" [id]="fcn"
        [formControlName]="fcn" [mask]="mask ? mask : ''" tabindex [dropSpecialCharacters]="dropSpecialCharacters"
        #inputContainer [title]="form && form.get(fcn).value ?
        form && form.get(fcn).value : placeHolder " [attr.disabled]="inputDisabled ? 'true' : null"
        [maxlength]="maxLength" (paste)="isPreventPaste($event)" />
      <div *ngIf="tooltip && type !== 'phone' && !showPasswordIcon" class="input-group" #inputContainer>
        <div class="helppericon" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"></div>
        <input [ngbTooltip]="placeHolder" container="body" [type]="type ? type : 'text'" (input)="onChange($event)"
          (blur)="required ? setNudge() : ''" [placeholder]="placeHolder" i18n-placeholder [id]="fcn"
          (focusout)="showValidationTick($event.target.value)" (focusin)="hideValidationTick()" [formControlName]="fcn"
          [mask]="mask ? mask : ''" [dropSpecialCharacters]="dropSpecialCharacters" class="form-control field-height has-tooltip"
          [attr.disabled]="inputDisabled ? 'true' : null" tabindex [maxlength]="maxLength" />
      </div>

      <ngx-intl-tel-input id="tel-input" *ngIf="!tooltip && type === 'phone' && !showPasswordIcon"
        [inputId]="fcn ? fcn : 'mobileNumber'" cssClass="form-control input-md" class="phone-box field-height"
        [enablePlaceholder]="enablePlaceholder" [selectedCountryISO]="countryISOcode" [maxLength]="15"
        [separateDialCode]="true" [formControl]="form.controls[fcn]" container="body" (paste)="onlyNumbers($event)"
        (drop)="onlyNumbers($event)" [searchCountryFlag]="true" [phoneValidation]="true"
        [searchCountryField]="[SearchCountryField?.Iso2, SearchCountryField?.Name]" triggers="manual" #inputContainer
        customPlaceholder="Mobile number" [enableAutoCountrySelect]="false">
      </ngx-intl-tel-input>
      <div *ngIf="tooltip && type === 'phone' && !showPasswordIcon" class="form-control field-height has-tooltip phone-no-padding"
        #inputContainer>
        <div class="helppericon" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()"></div>
        <ngx-intl-tel-input id="tel-input" [inputId]="fcn ? fcn : 'mobileNumber'" [cssClass]="'c-phone-input'"
          [enablePlaceholder]="enablePlaceholder" [selectedCountryISO]="countryISOcode" [maxLength]="15"
          [separateDialCode]="true" [formControl]="form.controls[fcn]"
          (focusout)="showValidationTick($event.target.value)" (focusin)="hideValidationTick()"
          (paste)="onlyNumbers($event)" (drop)="onlyNumbers($event)" [searchCountryFlag]="true" [phoneValidation]="true"
          [searchCountryField]="[SearchCountryField?.Iso2, SearchCountryField?.Name]" container="body" triggers="manual"
          customPlaceholder="Mobile number" [enableAutoCountrySelect]="false">
        </ngx-intl-tel-input>
      </div>
      <div *ngIf="showPasswordIcon" class="form-control field-height has-password-icon" #inputContainer>
        <div class="passwordicon hide" (click)="togglePassword()" (keyup)="togglePassword()" #passwordIconBox></div>
        <input [ngbTooltip]="enablePlaceholder ? placeHolder : null" container="body" [type]="type ? type : 'text'" (input)="onChange($event)"
          (blur)="required ? setNudge() : ''" (focusout)="showValidationTick($event.target.value)"
          (focusin)="hideValidationTick()" [placeholder]="placeHolder" i18n-placeholder [id]="fcn"
          [formControlName]="fcn" [mask]="mask ? mask : ''" [dropSpecialCharacters]="dropSpecialCharacters"
          [attr.disabled]="inputDisabled ? 'true' : null" tabindex [maxlength]="maxLength" />
      </div>
      
      <div class="input-group-append" *ngIf="
          (form && form.get(fcn).valid &&
          form && form.get(fcn).value &&
          (type === 'phone' || form && form.get(fcn).value.length > 0 && checkAcceptSpace()) &&
          showTick && validShowTick) ||
          (form && form.get(fcn).value && form.get(fcn).disabled && showTick && validShowTick && form.get(fcn).value.length > 0 && checkAcceptSpace())
        ">
        <span class="input-group-text" id="basic-addon">
          <span class="material-icons"> done </span>
        </span>
      </div>
      <div class="input-group-append" *ngIf="!showTick && showNextButton">
        <button class="btn input-group-text" (click)="nextButtonClick.emit()" [disabled]="!form.get(fcn).valid" id="basic-addon">
          <span class="material-icons"> arrow_forward </span>
        </button>
      </div>
    </div>
    <div class="pt-1 ms-1 required-star custom-validation-error">
      <span *ngIf="required && form && form.get(fcn)">*</span>
    </div>
  </div>

  <app-field-errors [control]="form && form.get(fcn)" [componentId]="fcn" [showRequiredError]="showRequiredError" *ngIf="
      (required || showError) &&
      form && form.get(fcn).invalid &&
      form && form.get(fcn).touched &&
      !errorCMessage
    " [firstErrorOnly]="firstErrorOnly" [customMessage]="customMessage">
  </app-field-errors>
  <app-field-errors [customMessage]="errorCMessage" [control]="form && form.get(fcn)"
    [showRequiredError]="showRequiredError" [componentId]="fcn" *ngIf="
      (required || showError) &&
      form && form.get(fcn).invalid &&
      form && form.get(fcn).touched &&
      errorCMessage
    " [firstErrorOnly]="firstErrorOnly" [customMessage]="customMessage">
  </app-field-errors>
</div>
