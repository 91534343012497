<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <div class="d-flex flex-column"
      [ngClass]="{'pt-1': showCloseIcon || !!imageUrl || !!title || !!title2, 'align-items-center': titleCentre ,'justify-content-center': titleCentre}">
      <button *ngIf="showCloseIcon && titleCentre" type="button" class="float-end btn btn-link px-0 pt-3 close-button"
        aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span class="text-darker font-40" aria-hidden="true">&times;</span>
      </button>
      <div class="mt-auto mb-2 modal-title"
        [ngClass]="{'align-items-center': titleCentre, 'justify-content-center': titleCentre, 'text-center': iconCentre}" *ngIf="title || title2" [class]="modalClass">
        <img *ngIf="imageUrl" class="mb-1 me-1" [src]="imageUrl" [alt]="title" [class]="imageClass" />
        <h6 *ngIf="titleClass" [class]="titleClass" class="m-0 d-inline" [ngClass]="{'text-center': titleCentre}">{{
          title }}</h6>
        <h6 *ngIf="!titleClass" class="m-0 text-style text-grey" [ngClass]="{'text-center': titleCentre}">{{ title }}
        </h6><br>
        <h6 class="m-0 text-center text-style text-grey">{{ title2 }}</h6>
        <button *ngIf="showCloseIcon && !titleCentre" type="button" class="float-end btn btn-link p-0 close-button"
          aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span class="text-darker closeIconFont" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="text-grey small text-center me-2 ms-2 mt-4" *ngIf="subTitle">
        {{ subTitle }}
      </div>
      <ng-content></ng-content>
      <div class="d-flex flex-wrap justify-content-around w-100" *ngIf="showAccept || showReject">
        <app-two-button (acceptClick)="onAcceptClick()" (rejectClick)="onRejectClick()"
          [enableAcceptClick]="!disableAcceptButton" [defaultActiveButton]="defaultActiveButton"
          [acceptText]="acceptText" [rejectText]="rejectText" [acceptClass]="'shadow ' + acceptClass"
          [rejectClass]="'shadow ' + rejectClass" [showReject]="showReject" [showAccept]="showAccept"></app-two-button>
      </div>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</ng-template>