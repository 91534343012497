import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AccountManageComponent } from './account-manage.component';
import { EntryPointComponent } from './entry-point/entry-point.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { SocialSignupComponent } from './social-signup/social-signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './reset-password-confirm/reset-password-confirm.component';
import { ResetPasswordNewPasswordComponent } from './reset-password-new-password/reset-password-confirm.component';
import { CountdownModule } from 'ngx-countdown';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordFinalStepComponent } from './reset-password-final-step/reset-password-final-step.component';
import { StillHaveProblemLinkComponent } from './still-have-problem-link/still-have-problem-link.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { CodeInputModule } from 'angular-code-input';
import { CreateBookingAccountComponent } from './create-booking-account/create-booking-account.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    AccountManageComponent,
    EntryPointComponent,
    PrivacyPolicyComponent,
    SocialSignupComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    ResetPasswordNewPasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordFinalStepComponent,
    StillHaveProblemLinkComponent,
    CreateBookingAccountComponent,
    SignupFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AccountRoutingModule,
    CountdownModule,
    FeatureToggleModule,
    CodeInputModule.forRoot({
      codeLength: 6
    })
  ],
  providers: [
    provideNgxMask()
  ]
})
export class AccountModule { }
