import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CircularBuffer {
  capacity = 5;

  private _buffer: Array<any>;
  public get buffer(): Array<any> {
    return this._buffer;
  }
  public set buffer(v: Array<any>) {
    this._buffer = v;
  }

  constructor() {
    this.buffer = [];
  }

  enqueue(item) {
    if (this.buffer.length <= this.capacity) {
      this.buffer.unshift(item);
    } else {
      // Buffer is full, remove the oldest element
      this.buffer.pop();
      // Add the new item to the front
      this.buffer.unshift(item);
    }
  }

  dequeue() {
    if (this.buffer.length > 0) {
      return this.buffer.pop();
    } else {
      console.log('Buffer is empty. Cannot dequeue.');
    }
  }

  peek() {
    if (this.buffer.length > 0) {
      return this.buffer[this.buffer.length - 1];
    } else {
      console.log('Buffer is empty. Cannot peek.');
    }
  }

  size() {
    return this.buffer.length;
  }

  isFull() {
    return this.buffer.length === this.capacity;
  }

  isEmpty() {
    return this.buffer.length === 0;
  }
}
