<div class="view-form">
    <div style="margin: 0 12px;">
        <div class="flexbox header-box">
            <div class="d-flex align-items-center ">
                <button class="btn btn-link back-btn-highlight btn-focus ms-0 px-0 mt-3 me-3" (click)="onBackButtonClick.emit()">
                    <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
                </button>
                <h2 class="underlined-title">Re-mark outcome</h2>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center display-content w-100">
        {{viewEORDescription}}
        <br><br>
    </div>
    <ng-container *ngIf="isIOLProduct">
        <div class="align-items-center display-content">
            {{viewEORContactText}}
            <span><a href="mailto:ieltsonline@idp.com" class="link-darker">ieltsonline@idp.com</a></span>
        </div>
    </ng-container>
    <table class="display-content"[ngClass]="{'mt-4': isIOLProduct,'mt-2': !isIOLProduct}">
        <caption class="sr-only">EOR Details</caption>
        <th class="sr-only"></th>
        <tr class="vertical-align">
            <td>
                <p class="font">Re-mark Components</p>
            </td>
            <td>
                <p class="score-comp">{{remarkComponents}}</p>
            </td>
            <td>
                <p class="font">First and Last Name</p>
            </td>
            <td>
                <p class="score-comp">{{candidateName}}</p>
            </td>
        </tr>
        <tr class="vertical-align">
            <td>
                <p class="font">Original Score</p>
            </td>
            <td>
                <p>
                    <span class="score-comp">L:</span> <span class="score-space font-wt score-nochange">{{originalResult?.scores?.listeningScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp pd-l">R:</span><span class="score-space font-wt score-nochange">{{originalResult?.scores?.readingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp pd-l">W: </span><span class="pd-r font-wt score-nochange">{{originalResult?.scores?.writingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class=" score-comp"> S: </span><span class="pd-r font-wt score-nochange">{{originalResult?.scores?.speakingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp"> O:</span><span class="pd-l font-wt score-nochange">{{originalResult?.scores?.overallScore}}</span>
                </p>
            </td>
            <td>
                <p class="font">Candidate Number</p>
            </td>
            <td>
                <p class="score-comp">
                    {{applicationResult?.application?.shortCandidateNumber?.trim()}}
                </p>
            </td>
        </tr>
        <tr class="vertical-align">
            <td>
                <p class="font">New Score</p>
            </td>
            <td >
                <p *ngIf="eorChanged">
                    <span class="score-comp">L:</span> <span class="score-space font-wt" [ngClass]="{'scorechange': listeningChanged, 'score-nochange': !listeningChanged}">{{newListeningScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp pd-l">R:</span><span class="score-space font-wt" [ngClass]="{'scorechange': readingChanged, 'score-nochange': !readingChanged}">{{newReadingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp pd-l">W: </span><span class="pd-r font-wt" [ngClass]="{'scorechange': writingChanged, 'score-nochange': !writingChanged}">{{newWritingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class=" score-comp"> S: </span><span class="pd-r font-wt" [ngClass]="{'scorechange': speakingChanged, 'score-nochange': !speakingChanged}">{{newSpeakingScore}}</span>
                    <span class="vertical-bar"></span>
                    <span class="score-comp"> O:</span><span class="pd-l font-wt" [ngClass]="{'scorechange': overallChanged, 'score-nochange': !overallChanged}">{{newOverallScore}}</span>
                </p>
                <p *ngIf="this.application?.status === 'EOR_IN_PROGRESS' || eorNotChanged" class="score-comp">{{newScore}}</p>
            </td>
            <td>
                <p class="font">Date (Test Taken)</p>
            </td>
            <td>
                <p class="score-comp">
                    {{testTakenDate}}
                </p>
            </td>
        </tr>
        <tr class="vertical-align">
            <td>
                <p class="font">Outcome</p>
            </td>
            <td>
                <p class="score-comp">{{outcome}}</p>
            </td>
            <td>
                <p class="font">Payment Status</p>
            </td>
            <td>
                <p> <img src="../../../../assets/images/green-tick.svg" class="tick"  alt="tick icon">
                    <span class="score-comp ms-1"> {{payStatus}}</span>
                </p>
            </td>
        </tr>
        <tr class="vertical-align">
            <td>
                <p class="font">Re-mark Completion Date</p>
            </td>
            <td>
                <p class="score-comp">{{remarkCompletionDate}}</p>
            </td>
            <td>
                <p class="font">Re-mark Raised Date</p>
            </td>
            <td>
                <p class="score-comp">{{remarkRaisedDate}}</p>
            </td>
        </tr>
        <tr class="vertical-align">
            <td>
                <p class="font">Re-mark Raised Date</p>
            </td>
            <td>
                <p class="score-comp">{{remarkRaisedDate}}</p>
            </td>
        </tr>
    </table>
</div>
