<div class="flexbox flex-column content align-items-xxl-start">
    <div class="all-content">
        <h6 class="content-title p-3" i18n>Enter the six digit security code sent to your email address</h6>

        <form class="form-horizontal mt-5 p-3">

            <div class="flexbox flex-column">
                <div style="max-width: 330px;">
                    <code-input [isCodeHidden]="false" [initialFocusField]="0" (cut)="mouseEvent($event)" [codeLength]="6"
                    (keyup)="keyUp($event)" (codeCompleted)="onComplete($event)">
                    </code-input>
                </div>
                <button class="resend-btn mt-5" [disabled]="showCountDown || !stateData.email || hasResendCodeError" (click)="onResendCode()">
                    <span *ngIf="!showCountDown || hasResendCodeError" class="flexbox text-darker text-bold">
                        <span class="material-icons resendicon" i18n>
                            refresh
                        </span>Resend Security Code
                    </span>
                    <span *ngIf="showCountDown && !hasResendCodeError" class="resend" i18n>
                        Resend after
                        <countdown #cd [config]="{ leftTime: 100, format: 'm:ss' }" (event)="handleEvent($event)">
                        </countdown>
                    </span>
                </button>
                <div class="invalid-feedback" *ngIf="hasResendCodeError" [style.display]="'block'">
                    {{resendCodeErrorMessage}}
                </div>
                <h6 class="p-1 title" i18n>Choose a new password</h6>
                <div content>
                    <div class="form-group">
                        <table class="table">
                            <caption class="sr-only">Password Details</caption>
                            <th class="sr-only"></th>
                            <tr>
                                <td>
                                    <input id="password" name="password" [type]="passwordType" #passwordInput
                                        placeholder="Choose your password" i18n-placeholder
                                        class="form-control passwordinput input-md icon-inputbox text-start"
                                        (mousemove)="mouseOver($event)" [class.visibilityOnIcon]="visibility"
                                        (click)="changeVisiblity($event)" [class.visibilityOffIcon]="!visibility"
                                        [formControl]="$form.controls.password" autocomplete="off"
                                        [class.nudghInput]="checkShowError('password') && nudghInput['password']"
                                        (blur)="setNudgh('password')">
                                    <div class="d-table-cell d-sm-table-cell d-md-none" *ngIf="
                                            $form.controls.password.touched
                                            || $form.controls.confirmPassword.touched
                                            || $form.controls.password.dirty
                                            || $form.controls.confirmPassword.dirty">
                                        <app-password-validation-status [password]="$form.controls.password.value">
                                        </app-password-validation-status>
                                    </div>
                                </td>
                                <td class="d-none d-sm-none d-md-table-cell absolutealign" rowspan="2">
                                    <div *ngIf="
                                            $form.controls.password.touched
                                            || $form.controls.confirmPassword.touched
                                            || $form.controls.password.dirty
                                            || $form.controls.confirmPassword.dirty">
                                        <app-password-validation-status [password]="$form.controls.password.value">
                                        </app-password-validation-status>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input id="comfirmPassword" name="comfirmPassword" [type]="passwordType"
                                        placeholder="Confirm your password" i18n-placeholder
                                        class="form-control passwordinput input-md icon-inputbox text-start"
                                        (mousemove)="mouseOver($event)" [class.visibilityOnIcon]="visibility"
                                        (click)="changeVisiblity($event)" [class.visibilityOffIcon]="!visibility"
                                        [formControl]="$form.controls.confirmPassword"
                                        [class.nudghInput]="checkShowError('confirmPassword') && nudghInput['confirmPassword']"
                                        (blur)="setNudgh('confirmPassword')">
                                    <app-field-errors [control]="$form.controls.confirmPassword"
                                        componentId="confirmPassword" *ngIf="checkShowError('confirmPassword')">
                                    </app-field-errors>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="invalid-feedback" *ngIf="hasValidationError" [style.display]="'block'">
                    {{validationError}}
                </div>
                <div class="btn-submit-wrapper mt-2 flexbox">
                    <button [disabled]="$form.invalid || !validPassword || !validPin || hasResendCodeError" class="btn btn-multiAction"
                        (click)="changePassword()">
                        <span class="flexbox align-items-center justify-content-center" i18n>
                            Next
                        </span>
                    </button>
                </div>
                <app-still-have-problem-link></app-still-have-problem-link>

            </div>
        </form>
    </div>
    <div *ngIf="networkError">
        <app-confirm-modal #sessionsError title="Oops, an unexpected error has occurred. Please try again later"  [imageClass]="'w-100'"
            imageUrl="../../../../assets/images/Girl green organic shape.png" acceptText="Retry" [showReject]="false">
        </app-confirm-modal>
    </div>
</div>
