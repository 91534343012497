import { Component, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-circle-checkbox',
  templateUrl: './circle-checkbox.component.html',
  styleUrls: ['./circle-checkbox.component.scss']
})
export class CircleCheckboxComponent {
  @Output() checkboxClick = new EventEmitter<any>();

  @Input() control = '';
  @Input() form = null;
  @Input() disable = false;
  @Input() title = '';
  isChecked: boolean;

  changeAction(event) {
    const isChecked = (event?.target as HTMLInputElement)?.checked;
    this.checkboxClick.emit(isChecked);

  }

}
