// tslint:disable: variable-name
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { Country, EducationLevel, Language, OccupationLevel, OccupationSector, ReferenceDataService, TestReason, UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { remove } from 'lodash';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Options } from '../onboarding/profile/options';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { IModel } from '../../shared/models/components/dropdown';
import { noSpecialCharactersValidator } from 'src/app/shared/validators/custom-validators';
import { emptyWhiteSpaceValidator } from 'src/app/shared/validators/empty-whitespace-validator';
import { first, map, tap } from 'rxjs/operators';

export const moveOtherToLast = (list: Array<{ id: any; name: any }>): Array<any> => {
  try {
    if (!list || !Array.isArray(list)) {
      return null;
    }
    const tempList = [...list];
    const temp = remove(tempList, (item: { id: any; name: any }) => {
      return item && item.name && (item.name + '').toLowerCase() === 'other';
    });
    return temp && temp.length ? [...tempList, ...temp] : [...tempList];
  } catch (error) {
    return [];
  }
};


@Injectable()
export class PrepareSurveyFormService {
  constructor(private fb: UntypedFormBuilder,
              private loadingService: LoadingService,
              private apiService: ApiService) {
  }
  public get userProfile(): UserProfileResponse {
    return this._userProfile;
  }
  public set userProfile(v: UserProfileResponse) {
    this._userProfile = v;
  }
  public get subscription(): Subscription {
    return this._subscription;
  }
  public set subscription(v: Subscription) {
    this._subscription = v;
  }
  public get languages(): Language[] {
    return this._languages;
  }
  public set languages(v: Language[]) {
    this._languages = v;
  }
  public get studyEnglishYearsList(): any[] {
    return this._studyEnglishYearsList;
  }
  public set studyEnglishYearsList(v: any[]) {
    this._studyEnglishYearsList = v;
  }
  public get occupationSectorList(): OccupationSector[] {
    return this._occupationSectorList;
  }
  public set occupationSectorList(v: OccupationSector[]) {
    this._occupationSectorList = v;
  }
  public get countryList(): any[] {
    return this._countryList;
  }
  public set countryList(v: any[]) {
    this._countryList = v;
  }
  public get testReasonList(): TestReason[] {
    return this._testReasonList;
  }
  public set testReasonList(v: TestReason[]) {
    this._testReasonList = v;
  }
  public get occupationLevelList(): OccupationLevel[] {
    return this._occupationLevelList;
  }
  public set occupationLevelList(v: OccupationLevel[]) {
    this._occupationLevelList = v;
  }
  public get educationLevelList(): EducationLevel[] {
    return this._educationLevelList;
  }
  public set educationLevelList(v: EducationLevel[]) {
    this._educationLevelList = v;
  }

  private _userProfile: UserProfileResponse;

  private _subscription: Subscription;

  private _languages: Language[];


  private _studyEnglishYearsList: any[];


  private _occupationSectorList: OccupationSector[];


  private _countryList: any[];


  private _testReasonList: TestReason[];


  private _occupationLevelList: OccupationLevel[];


  private _educationLevelList: EducationLevel[];
  public static guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }
  public static getSavedDataWithOtherProperty(fieldName: string, dataSet: any, options: any[]): string | IModel<any> {
    if (options && dataSet && dataSet[`${fieldName}Id`] && dataSet[`${fieldName}Other`]) {
      const tempItem = options.find(item => item.id === dataSet[`${fieldName}Id`]);
      if (tempItem && tempItem.name && tempItem.name.toLowerCase() === 'other') {
        const id = this.guidGenerator();
        const op = {
          id,
          name: dataSet[`${fieldName}Other`],
          __isNewOption: true,
        };
        options.push(op);
        return {
          Id: id,
          label: op.name,
          option: { ...op },
          extendValue: {
            addNewOptionsId: dataSet[`${fieldName}Id`],
            isNewOption: true
          }
        } as IModel<any>;
      } else {
        return dataSet[`${fieldName}Id`];
      }
    } else {
      return dataSet[`${fieldName}Id`] || '';
    }
  }
  public prepareSurveyForm(): Observable<UntypedFormGroup> {
    if (!this._userProfile) {
      throw new Error('userProfile is required');
    }
    const profile = this.userProfile;
    const userProfileDetail = this.userProfile.marketingDetails;
    const allNeededDataIsReceived = forkJoin([
      this.getCountries(),
      this.getLanguagesList(),
      this.getReasonTestList(),
      this.getEducationLevel(),
      this.getOccupationLevel(),
      this.getOccupationSector(),
    ]).pipe(first());
    this.yearsStudyingEnglishList();
    const getData = (
      fieldName: string,
      options?: any[],
      upd: any = this.userProfile.marketingDetails) => {
      if (options) {
        return PrepareSurveyFormService.getSavedDataWithOtherProperty(fieldName, upd, options);
      }
      return this.getSavedData(fieldName, upd);
    };
    const buildForm = (): UntypedFormGroup => this.fb.group({
      gender: '',
      title: '',
      firstLanguage: [
        getData('language', this.languages, profile),
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      educationLevel: [
        getData('educationLevel', this.educationLevelList, userProfileDetail),
        Validators.required,
      ],
      occupationSector: [
        getData('occupationSector', this.occupationSectorList, userProfileDetail),
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      occupationLevel: [
        getData('occupationLevel', this.occupationLevelList, userProfileDetail),
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      reasonTest: [
        getData('testReason', this.testReasonList, userProfileDetail), [Validators.required, emptyWhiteSpaceValidator()]],
      yearsStudyingEnglish: [
        +getData('yearsOfStudy') ? +getData('yearsOfStudy') : '',
        Validators.required,
      ],
      locationStudyEnglish: [
        getData('currentlyStudyingEnglishAt'),
        [
          Validators.maxLength(150),
          emptyWhiteSpaceValidator(),
          noSpecialCharactersValidator
        ]
      ],
      intendLocation: [
        getData('countryApplyingTo', this.countryList, userProfileDetail),
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
    });
    this.loadingService.increaseLoadingCounter();
    return allNeededDataIsReceived.pipe(first(), map(([country, lang, testReason, edLevel, ocLevel, occSector]) => {
      this._countryList = country;
      this._educationLevelList = edLevel;
      this._languages = lang;
      this._occupationLevelList = ocLevel,
        // studyEnglishYearsList have already filed
        this._testReasonList = testReason;
      this._occupationSectorList = occSector;
      return buildForm();
    }), tap(() => {
      this.loadingService.decreaseLoadingCounter();
    }));
  }

  private getSavedData(fieldName: string, container: object) {
    return (container && (
      container[fieldName] ||
      ''
    )) || '';
  }


  public getLanguagesList(): Observable<Language[]> {
    const langObservable = new Observable<Language[]>(sub =>
      this.apiService.getLanguage.subscribe(languageList => {
        if (languageList) {
          this.languages = moveOtherToLast(languageList || []);
          sub.next(this.languages);
          sub.complete();
        }
      }));
    return langObservable;

  }
  public yearsStudyingEnglishList() {
    this.studyEnglishYearsList = moveOtherToLast(Options.studyEnglishYears);
  }
  public getOccupationSector(): Observable<OccupationSector[]> {
    const occupationSectorObservable = new Observable<OccupationSector[]>(sub => {
      this.apiService.getOccupationSector.subscribe(osl => {
        if (osl) {
          this.occupationSectorList = moveOtherToLast(osl || []);
          sub.next(this.occupationSectorList);
          sub.complete();
        }
      });
    });
    return occupationSectorObservable;
  }

  getCountries(): Observable<Country[]> {
    const countryObservable = new Observable<Country[]>(sub => {
      this.apiService.GetCountries.subscribe(data => {
        if (data) {
          this.countryList = moveOtherToLast(data || []);
          sub.next(this.countryList);
          sub.complete();
        }
      });
    });
    return countryObservable;
  }
  public getReasonTestList(): Observable<TestReason[]> {
    const testReasonObservable = new Observable<TestReason[]>(sub => {
      this.apiService.getTestReason.subscribe(data => {
        if (data) {
          this.testReasonList = moveOtherToLast(data || []);
          sub.next(this.testReasonList);
          sub.complete();
        }
      });
    });
    return testReasonObservable;
  }

  public getOccupationLevel(): Observable<OccupationLevel[]> {
    const occupationLevelObservable = new Observable<OccupationLevel[]>(sub => {
      this.apiService.getOccupationLevel.subscribe(data => {
        if (data) {
          this.occupationLevelList = moveOtherToLast(data || []);
          sub.next(this.occupationLevelList);
          sub.complete();
        }
      });
    });
    return occupationLevelObservable;
  }
  public getEducationLevel(): Observable<EducationLevel[]> {
    const educationLevelObservable = new Observable<EducationLevel[]>(sub => {
      this.apiService.getEducationLevels.subscribe(data => {
        if (data) {
          this.educationLevelList = moveOtherToLast(data || []);
          sub.next(this.educationLevelList);
          sub.complete();
        }
      });
    });
    return educationLevelObservable;
  }
}
