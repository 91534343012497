import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../../shared/services/user-profile.service';
import { first, map } from 'rxjs/operators';
import { createEventParam, generateIcal } from '../../../../shared/utils/generate-ical';
import { customDateFormat } from '../../../../shared/utils/custom-date-format';
import { selectOriginalApplication, selectOSRApplication, selectSelectedTest, selectSsrComponent, selectSSRFee } from '../../../../store/my-tests/my-tests.reducer';
import { Store } from '@ngrx/store';
import { getDuration } from '../util';
import { selectOfflinePayment } from '../../../payment/store/payment.reducer';
import { DateTime } from 'luxon';
import { setOSRCurrentApplication, setOriginalApplication, setSSRFee, setSelectedTest, setSsrComponent } from 'src/app/store/my-tests/my-tests.actions';
import { setFromTips, setOfflinePayment } from 'src/app/pages/payment/store/payment.actions';
import { OSRLocalStorageItemKeys } from 'src/app/pages/payment/payment.enum';
import { CallToActionService } from 'src/app/shared/services/call-to-action.service';
import { Subscription, combineLatest } from 'rxjs';
import { Application } from '@idp-education/ors-test-taker-bff-client-v1/model/application';
import { ApplicationsService } from 'shared/services/applications.service';
declare let dataLayer;

@Component({
  selector: 'app-ssr-payment-confirmation',
  templateUrl: './ssr-payment-confirmation.component.html',
  styleUrls: ['./ssr-payment-confirmation.component.scss']
})
export class SsrPaymentConfirmationComponent implements OnInit, OnDestroy {
  testTitle = '';
  testTitleMd = '';
  userProfile = '';
  ssrComponent$ = this.store.select(selectSsrComponent);
  testFee$ = this.store.select(selectSSRFee);
  selectedTest$ = this.store.select(selectSelectedTest);
  isOfflinePayment$ = this.store.select(selectOfflinePayment);
  selectedOSRApp$ = this.store.select(selectOSRApplication);
  selectedProduct$ = this.store.select(selectOriginalApplication);
  ssrComponentSub: Subscription = new Subscription();
  osrApplication: Application;
  btnRejectText: string;
  @Input() isStripe = false;
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  toFix = (num: number) => num ? num.toFixed(2) : '';


  constructor(
    private store: Store<{ myTestsStore, paymentStore }>,
    private userProfileService: UserProfileService,
    private callToActionService: CallToActionService,
    private applicationsService: ApplicationsService,
    private router: Router
  ) {
    this.userProfileService.getUserProfile(true).pipe(first()).subscribe((uProfile) => {
      if (uProfile) {
        this.userProfile = uProfile.firstName;
      } else {
        this.userProfile = null;
      }

    });
  }

  ngOnInit(): void {
    this.ssrComponentSub.add(this.ssrComponent$.pipe().subscribe(ssrComponent => {
      this.testTitle = ssrComponent + ' test';
    }));
    this.ssrComponentSub.add(this.selectedOSRApp$.pipe().subscribe(osrApplication => {
      this.osrApplication = osrApplication;
      this.pushGAData();
    }));
    this.ssrComponentSub.add(this.selectedProduct$.pipe().subscribe(originalApplication => {
      const isUKVI = this.getIsUKVI(originalApplication?.productName);
      this.btnRejectText = isUKVI ? 'Review Details' : 'Cancel';
    }));
    this.testTitleMd = this.testTitle;
    // receiving @Input() isStripe from parent component
    // to conditionally restore the OSR info stored in local storage
    // after successful payment and redirction
    if (this.isStripe) {
      this.restoreOsrInfo();
    }
    this.callToActionService.setHidden(true);
  }

  // retrieving OSR info from local storage in case of stripe payment
  // and store the same in @ngrx/store to maintain same code flow
  // info in local storage is cleared after restoring
  // refer storeBookingInfo() in stripe.component.ts
  restoreOsrInfo() {
    // converting enum to array
    const itemKeys = Object.values(OSRLocalStorageItemKeys).filter(v => isNaN(Number(v)));
    if (itemKeys.every(key => localStorage.getItem(key))) {
      const ssrComponent = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrComponent));
      const ssrFee = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrFee));
      const selectedTest = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.selectedTest));
      const offlinePayment = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.offlinePayment)) as boolean;
      const ssrApplication = JSON.parse(localStorage.getItem(OSRLocalStorageItemKeys.ssrApplication));
      this.store.dispatch(setSsrComponent({ ssrComponent }));
      this.store.dispatch(setSSRFee({ ssrFee }));
      this.store.dispatch(setSelectedTest({ selectedTest }));
      this.store.dispatch(setOfflinePayment({ isOffline: offlinePayment }));
      this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: ssrApplication }));
      this.btnRejectText = this.getIsUKVI(ssrApplication?.bookings[0]?.bookableProductName) ? 'Review Details' : 'Cancel';
      itemKeys.forEach(key => localStorage.removeItem(key));
    } else {
      this.onCancelClick.emit();
    }
  }

  getIsUKVI(productName) {
    return productName?.includes('IELTS UKVI on Computer')
      || productName?.includes('IELTS OSR UKVI on Computer');
  }

  getTestDate$() {
    return this.selectedTest$.pipe(map(test => {
      return DateTime.fromISO(test.testStartLocalDatetime, { setZone: true });
    }));
  }

  getTestTimeString$(type: string) {
    return this.selectedTest$.pipe(map(test => {
      return customDateFormat(test.testLocalTimeZone, type, new Date(test.testStartLocalDatetime));
    }));
  }

  generateIcal($event) {
    $event?.preventDefault();
    combineLatest([this.selectedTest$.pipe(first()), this.ssrComponent$.pipe(first())]).subscribe(([test, ssrComponent]) => {
      const from = new Date(test.testStartLocalDatetime);
      const to = new Date(from.getTime() + (1000 * 60 * getDuration(ssrComponent)));
      generateIcal([createEventParam(from, to, `${ssrComponent} test`, `${ssrComponent} test description`)]);
    });
  }

  getDuration$() {
    return this.ssrComponent$.pipe(map(ssrComponent => getDuration(ssrComponent).toString()));
  }

  goToProfile() {
    this.router.navigate(['/my-profile']);
  }
  pushGAData() {
    const applicationPayment = this.applicationsService.getLatestApplicationPayment(this.osrApplication?.applicationPayments);
    if (applicationPayment) {
      const amount = applicationPayment?.applicationFee?.totalAmount;
      const tax = (applicationPayment?.applicationFee?.totalAmount) - (applicationPayment?.applicationFee?.baseAmount);
      const fixedAmount = (tax).toFixed(2);
      const taxAmount = Number(fixedAmount);
      const productName = this.osrApplication?.bookings[0]?.bookableProductName;
      const productId = this.osrApplication?.bookings[0]?.bookableProductId;
      const paymentMethod = applicationPayment?.paymentMethod === 'OFFLINE' ? 'offline' : 'online';
      dataLayer.push({
        event: 'bxpurchase',
        transactionId: applicationPayment?.receiptNumber,
        transactionAffiliation: '',
        transactionTotal: amount,
        transactionTax: taxAmount,
        transactionShipping: '',
        transactionProducts: [{
          sku: productId,
          name: productName,
          category: 'OSR Test',
          price: amount,
          quantity: 1,
          payment_method: paymentMethod,
        }]
      });
      dataLayer.push({
        event: 'form_submit_enhance',
        enhanced_conversion_data: {
          email: this.osrApplication?.userProfile?.emailAddress
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.ssrComponentSub?.unsubscribe();
    this.store.dispatch(setFromTips({ fromTips: false }));
  }

}
