<div class="card">
  <div class="card-body">
    <p>Please wait while we fetch your payment status</p>
  </div>
</div>
<app-confirm-modal #paymentModalPopup [title]="title" imageUrl="assets/images/material-error.svg"
  [acceptText]="acceptText" titleClass="" [showReject]="false" [showCloseIcon]="false"
  (onAcceptClicked)="onAcceptClicked()" (onClose)="onAcceptClicked()">
</app-confirm-modal>
<app-payment-v2-popup [paymentErrorModal]="paymentNewModalPopup" [description]="desc"  [imageUrl]="imageUrl" [title]="title"
[acceptText]="acceptText" titleClass="" [showCloseIcon]="true" [showReject]="false" 
(onClose)="onAcceptClicked()" (onAcceptClicked)="onAcceptClicked()">