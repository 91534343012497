<div otherContent *ngIf="dataReady">
  <div class="flexbox header-box mb-2">
    <div class="d-flex align-items-center">
      <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3" (click)="onBackButtonClick.emit()">
        <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
      </button>
      <h2 class="underlined-title">Update my application details</h2>
    </div>
  </div>
  <app-information-box type="secondary" [classes]="['d-flex']" icon="fa-info-circle text-danger"
    *ngIf="ischeckVerified">
    <span class="alert-msg" [innerHtml]="myTestProfileAlertMsg"></span>
  </app-information-box>
  <app-information-box type="secondary" [classes]="['d-flex','mt-10']" icon="fa-info-circle text-danger"
    *ngIf="ischeckVerified && isVerified">
    <span class="alert-msg" [innerHtml]="verifyMsg"></span>
  </app-information-box>
  <div id="updateDetails" class="details-section py-4">
    <div ngbAccordion>
      <div ngbAccordionItem="profileInfo" [collapsed]="false">
          <div ngbAccordionHeader>
            <button ngbAccordionButton class="d-flex">
              <div class="w-100">
                <h2 class="heading m-0">Profile & ID information</h2>
              </div>
              <div class="me-3" *ngIf="personalNIdInformationForm">
                <span class="card-action">
                  <span
                    *ngIf="(personalNIdInformationForm.invalid && personalNIdInformationForm.pristine) || (personalNIdInformationForm.touched && personalNIdInformationForm.invalid) || !documentImageUpdated"
                    class="badge rounded-pill badge-danger">
                    <i class="fa fa-info-circle"></i>
                    Review Required
                  </span>
                  <span
                    *ngIf="personalNIdInformationForm.valid && documentImageUpdated"
                    class="badge rounded-pill badge-primary">
                    <i class="fa fa-check"></i>
                    Reviewed and updated
                  </span>
                </span>
              </div>
            </button>
          </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="personalNIdInformationForm">
                <ng-template #tipContent>
                  <h6 class="m-0 text-black text-bold">Please note:</h6>
                  <p class="text-black">If your date of birth is incorrect, please contact your test centre to update.
                  </p>
                </ng-template>
                <app-personal-info-form [form]="personalNIdInformationForm" [disableDateOfBirth]="true"
                  [dateOfBirthToolTip]="tipContent" dateOfBirthToolTipClass="dob-tooltip" [disableEmail]="true"
                  [disableFirstName]="isVerified" [disableFamilyName]="isVerified"
                  [identificationTypes]="IdentificationTypes" [disableGender]="isVerified"
                  [nationalityList]="nationalities" [genderList]="personalGenderList" [disableIdNumber]="isVerified"
                  [selectedcountryISO]="selectedCountryISO" [disableCountryOfNationality]="isVerified"
                  [testDate]="transformedTestDate"
                  [disableExpiryDate]="isVerified"
                  (expiryValidate)="receivedData($event)"
                  [disableIdType]="isVerified || (isOSRApplication && isUKVIApplication)"
                  [isUKVIApplication]="isUKVIApplication" [isOSRApplication]="isOSRApplication">
                </app-personal-info-form>
                <div class="title-padding">
                  <h2 class="heading m-0">
                    {{uploadTitle}}
                  </h2>
                  <span class="text-sm" [innerHtml]="uploadSubTitle"></span>
                </div>
                <div class="upload_identity_container height-auto">
                  <div class="upload-identity-content">
                    <app-application-document-upload-form class="d-block"
                      [style]="isVerified ? 'pointer-events:none;' : ''" [imageUrl]="modifiedS3Url(idImage)"
                      (uploaded)="onUploadDocumentPhoto($event)" [editableImage]="true" [deletableImage]="false"
                      (deleted)="deletedIdImage()" (documentPhotoUpdated)="onDocumentPhotoUpdate($event)"
                      [cancelChanges$]="cancelChanges$" (transformChangesReset$)="onImageTransformReset($event)"
                      [poolImageUrl]="idImage" [saveTheChanges$]="saveTheChanges$" [resetTransforms$]="resetTransform$"
                      [(imageTransformed)]="imageTransformed"></app-application-document-upload-form>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center justify-content-md-end mt-4">
                <button *ngIf="imageTransformed" (click)="resetTransform$.next()" type="button" class="btn btn-link">
                  Reset to default
                </button>
                <button type="button" class="btn btn-multiAction custom-outline-btn me-20"
                  (click)="onCancelDocumentUpdate()" [disabled]="!documentImageUploaded" i18n>Cancel</button>
                <button type="submit" class="btn btn-multiAction custom-btn btn-danger"
                  (click)="updatePersonalNIdDetails()" [disabled]="formInvalid || expiryDateCheck" i18n>Confirm & Update</button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="address">
        <div ngbAccordionHeader>
          <button [disabled]="!addressDetailsForm" ngbAccordionButton  class="d-flex">
            <div class="w-100"><h2 class="heading m-0">Address</h2></div>
            <div class="me-3" *ngIf="addressDetailsForm">
              <div class="card-action">
                <span
                  *ngIf="(addressDetailsForm.invalid && addressDetailsForm.pristine) || (addressDetailsForm.touched && addressDetailsForm.invalid)"
                  class="badge rounded-pill badge-danger">
                  <i class="fa fa-info-circle"></i>
                  Review Required
                </span>
                <span *ngIf="addressDetailsForm.valid && addressDetailsForm.pristine"
                  class="badge rounded-pill badge-primary">
                  <i class="fa fa-check"></i>
                  Reviewed and updated
                </span>
              </div>
            </div>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngIf="addressDetailsForm">
                <app-address-details-form [form]="addressDetailsForm" [showTitle]="true"></app-address-details-form>
              </div>
              <div class="d-flex justify-content-center justify-content-md-end mt-4">
                <button type="submit" [disabled]="addressDetailsForm.invalid || addressDetailsForm.pristine"
                  (click)="updateAddressDetails()" class="btn btn-multiAction custom-btn btn-danger" i18n>Confirm &
                  Update</button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem="ieltsInfo">
        <div ngbAccordionHeader>
          <button ngbAccordionButton [disabled]="!ieltsInfoForm" class="d-flex">
            <div class="w-100"><h2 class="heading m-0">IELTS Information</h2></div>
            <div class="me-3" *ngIf="ieltsInfoForm">
              <div class="card-action">
                <span
                  *ngIf="(ieltsInfoForm.invalid && ieltsInfoForm.pristine) || (ieltsInfoForm.touched && ieltsInfoForm.invalid)"
                  class="badge rounded-pill badge-danger">
                  <i class="fa fa-info-circle"></i>
                  Review Required
                </span>
                <span *ngIf="ieltsInfoForm.valid && ieltsInfoForm.pristine" class="badge rounded-pill badge-primary">
                  <i class="fa fa-check"></i>
                  Reviewed and updated
                </span>
              </div>
            </div>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template class="w-100">
              <div *ngIf="ieltsInfoForm">
                <app-ielts-info-form [form]="ieltsInfoForm" [countryList]="countryList || []" [genderList]="[]"
                  [occupationSectorList]="occupationSectorList || []" [occupationLevelList]="occupationLevelList || []"
                  [educationLevelList]="educationLevelList || []" [testReasonList]="testReasonList || []"
                  [languages]="languages || []" [yearsStudyingEnglishList]="studyEnglishYearsList || []"
                  (selectChange)="surveyChange($event)" [showTitle]='true'
                  [disableFirstLanguage]="isVerified && ieltsInfoForm.valid">
                </app-ielts-info-form>
              </div>
              <div class="d-flex justify-content-center justify-content-md-end mt-4">
                <button type="submit" [disabled]="ieltsInfoForm.invalid || ieltsInfoForm.pristine"
                  (click)="updateIeltsInfoDetails()" class="btn btn-multiAction custom-btn btn-danger" i18n>Confirm &
                  Update</button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isUKVIApplication">
        <div ngbAccordionItem="ukvi">
          <div ngbAccordionHeader>
            <button ngbAccordionButton [disabled]="!UKVIInfoForm" class="d-flex">
              <div class="w-100"><h2 class="heading m-0">UKVI Information</h2></div>
              <div class="me-3" *ngIf="UKVIInfoForm">
                <div class="card-action">
                  <span *ngIf="(UKVIInfoForm.invalid)" class="badge rounded-pill badge-danger">
                    <i class="fa fa-info-circle"></i>
                    Review Required
                  </span>
                  <span *ngIf="UKVIInfoForm.valid" class="badge rounded-pill badge-primary">
                    <i class="fa fa-check"></i>
                    Reviewed and updated
                  </span>
                </div>
              </div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template class="w-100">
                <div *ngIf="UKVIInfoForm">
                  <app-ukvi-questions-form [questionsForm]="UKVIInfoForm" [showInfoBox]="false"
                    [showTermsAndConditions]="false"
                    [readonly]="isOSRApplication" [isCefrDataAvailable]="isCefrDataAvailable"></app-ukvi-questions-form>
                </div>
                <div class="d-flex justify-content-center justify-content-md-end mt-4">
                  <button type="submit" [disabled]="UKVIInfoForm.invalid"
                    (click)="updateUKVIInfoDetails()" class="btn btn-multiAction custom-btn btn-danger" i18n>Confirm &
                    Update</button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<app-confirm-modal #cancelChangesConfirmation
  title="Are you sure you wish to cancel the applied changes? including photo and identity information">
</app-confirm-modal>