import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { ContentModalComponent } from '../../components/content-modal/content-modal.component';
import { MustMatch, MustNotMatch } from '../../validators/must-match';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../services/loading-service.service';
import { PASSWORD_REGEXP } from '../../sharedRegex';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  form: UntypedFormGroup;
  readonly customMessage = {
    pattern: () => 'Value entered is invalid'
  };
  @ViewChild('changePasswordModal') changePasswordModal: ContentModalComponent;
  constructor(private fb: UntypedFormBuilder,
    private toast: ToastrService,
    private loadingService: LoadingService,
    private authService: AuthService
  ) {
    this.form = this.prepareForm();
  }

  private prepareForm(): UntypedFormGroup {
    return this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(PASSWORD_REGEXP)
      ]],
      confirmPassword: ''
    }, {
      validators: [
        MustMatch('newPassword', 'confirmPassword'),
        MustNotMatch('oldPassword', 'newPassword'),
      ]
    });
  }

  onChangePassClick() {
    this.changePasswordModal.open();
    this.changePasswordModal.onAcceptClick = () => this.changePassword();
  }

  clearForm() {
    this.form.reset('');
    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
  changePassword() {
    this.loadingService.increaseLoadingCounter();
    const oldPass = this.form.get('oldPassword').value;
    const newPass = this.form.get('newPassword').value;
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(1);
        return Auth.changePassword(user, oldPass, newPass);
      }).then(data => {
        this.clearStorage();
        this.changePasswordModal.closeModal('');
        this.authService.signOut();
        this.toast.success($localize`Password changed successfully`);
      }).catch(err => {
        this.toast.error((err && err.message) || $localize`somthing is wrong`);
      }).finally(() => {
        this.loadingService.decreaseLoadingCounter();
      });
  }
  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();
    const cookies = document.cookie.split(";");
    cookies.forEach(cookie => {
      const name = cookie.split('=')[0]?.trim();
      document.cookie = name + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;";
    });
  }
}
