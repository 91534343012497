import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { SharedStateService } from '../shared-state.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { setAddressDetail } from '../store/onboarding.actions';
import { IState } from '../store/onboarding.reducer';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { startEndSpaceValidator } from 'src/app/shared/validators/start-end-space-validator';
import { ADDRESS_REGEXP, CITY_REGEXP, POST_CODE_REGEXP } from 'src/app/shared/sharedRegex';

@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.scss'],
})
export class AddressDetailComponent
  implements OnInit, OnDestroy {
  sub: Subscription;
  state: IState;
  @ViewChild('saveAndExitModal') confirmModal: ConfirmModalComponent;
  form: UntypedFormGroup;

  constructor(
    private sharedState: SharedStateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store<{ onboardingStore }>,
    private metaTagService: Meta
  ) 
  {
    this.sharedState.setTitleAndSubscribe(
      'Provide your address | IDP IELTS',
      this.form,
      1,
      16,
      'Address details',
      true,
      false
    );
    this.state = this.sharedState.getState();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private prepareForm() {
    const { addressDetail, userProfile } = this.state;
    return this.fb.group({
      address1: [
        (addressDetail && addressDetail.address1) || (userProfile?.addressDetails?.streetAddress1) || '',
        [
          Validators.required,
          Validators.pattern(ADDRESS_REGEXP),
        ],
      ],
      address2: [
        (addressDetail && addressDetail.address2) || (userProfile?.addressDetails?.streetAddress2) || '',
        Validators.pattern(ADDRESS_REGEXP),
      ],
      country: [
        (addressDetail && addressDetail.country) ||
        this.sharedState.getOptionObject(userProfile?.addressDetails?.countryId, this.state.Countries) || '',
        [
          Validators.required,
        ],
      ],
      province: [
        (addressDetail && addressDetail.province) || (userProfile?.addressDetails?.territoryId) || '',
      ],
      city: [
        (addressDetail && addressDetail.city) || (userProfile?.addressDetails?.city) || '',
        Validators.pattern(CITY_REGEXP),
      ],
      postcode: [
        (addressDetail && addressDetail.postcode) || (userProfile?.addressDetails?.postCode) || '',
        Validators.pattern(POST_CODE_REGEXP),
      ],
    }, { validators: [startEndSpaceValidator] });
  }

  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    this.form = this.prepareForm();
    if (this.state?.isAchievedLastStep) {
      this.router.navigate(['/onboarding/review-profile']);
    }
  }

  saveAndExitClick() {
    this.store.dispatch(setAddressDetail({ addressDetail: this.form.value }));

    this.confirmModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      this.sharedState.saveAndExit('address', this.state, true).subscribe(
        () => {
          this.router.navigate(['/my-account'], {
            state: {
              test: 'test',
            },
          });
        },
        (err) => {
          alert($localize`Sorry, Saving failed, please try again`);
        }
      );
    });
  }

  nextClick() {
    if (this.form?.valid) {
      this.store.dispatch(setAddressDetail({ addressDetail: this.form.value }));
      this.sharedState.saveAndExit('address', this.state).subscribe(() => {
        const navigateUrl = '/onboarding/' + (this.state.isAchievedLastStep ? 'review-profile' : 'profile');
        this.router.navigate([navigateUrl]);
      }, (err) => {
        alert($localize`Sorry, Saving failed, please try again`);
      }
      );
    }
  }
}
