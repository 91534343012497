// tslint:disable: variable-name
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedStateService } from '../shared-state.service';
import { Options } from './options';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { CustomDropdownComponent } from 'src/app/shared/components/forms/custom-dropdown/custom-dropdown.component';
import {
  Gender, OccupationSector,
  OccupationLevel,
  EducationLevel,
  TestReason,
  Language
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';

import {
  setCurrentStep,
  setCurrentStepName, setProfile,
  setProgressValue
} from '../store/onboarding.actions';
import { Subscription } from 'rxjs';
import { IState } from '../store/onboarding.reducer';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { remove } from 'lodash';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Title, Meta } from '@angular/platform-browser';
import { PrepareSurveyFormService } from '../../my-profile/prepare-survey-form.service';
import { DateTime } from 'luxon';
import { User } from '../../account/signup/userProfile.model';
import { getAutomationTestMobileFieldToggle } from '../../../shared/utils/automation-test-toggle';
import { startEndSpaceValidator } from 'src/app/shared/validators/start-end-space-validator';
import { removeCountryCode, getCountryCode } from 'src/app/shared/utils/phone-number-helper';
import { LOCATION_STUDY_ENGLISH_REGEXP } from 'src/app/shared/sharedRegex';
import { titleGenderValidator } from 'src/app/shared/validators/gender-title.validator';
import { emptyWhiteSpaceValidator } from 'src/app/shared/validators/empty-whitespace-validator';
import { DobDateCheckerHelper } from 'shared/helper/dob-date-checker.helper';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [PrepareSurveyFormService]
})
export class ProfileComponent implements OnInit, OnDestroy {
  state: IState;
  form: UntypedFormGroup;
  nudgeInput = [];
  customMessages = {
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`,
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    }
  };
  options = Options;
  countryList = [];
  genderList: Gender[] = [];
  occupationSectorList: OccupationSector[] = [];
  occupationLevelList: OccupationLevel[] = [];
  educationLevelList: EducationLevel[] = [];
  testReasonList: TestReason[] = [];
  languages: Language[] = [];
  selectedLabels: any = {};
  titleList: { name: string, value: string }[];
  currentDD: string;
  isOtherSelected = false;
  elements: Array<{ id: string; value: CustomDropdownComponent }> = [];
  sub: Subscription;
  showTitle = false;
  disableDateOfBirth = false;
  under16YearsOld = false;
  selectedCountryISO = '';
  @ViewChild('reasonTestEl') reasonTestEl: CustomDropdownComponent;
  @ViewChild('saveAndExitModal') confirmModal: ConfirmModalComponent;

  private _yearsStudy: any[];
  public get yearsStudy(): any[] {
    return this._yearsStudy;
  }
  public set yearsStudy(v: any[]) {
    this._yearsStudy = v;
  }

  constructor(
    private sharedState: SharedStateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private prepareSurveyFormService: PrepareSurveyFormService,
    private loadingService: LoadingService,
    private store: Store<{ onboardingStore }>,
    private apiService: ApiService,
    private titleService: Title, private metaTagService: Meta,
    private dobDateCheckerHelper: DobDateCheckerHelper
  ) {
    this.titleService.setTitle('IELTS questionaire | IDP IELTS');
    this.sub = this.store
      .select((appState) => appState.onboardingStore)
      .subscribe((e) => {
        this.state = e;
      });

    this.store.dispatch(setCurrentStep({ currentStep: 2 }));
    this.store.dispatch(setProgressValue({ progressValue: 32 }));
    this.store.dispatch(
      setCurrentStepName({ currentStepName: 'Profile details' })
    );

    this.getGender();
    this.getCountries();
    this.getOccupationSector();
    this.getOccupationLevel();
    this.getEducationLevel();
    this.getReasonTestList();
    this.getLanguagesList();
    this.yearsStudy = this.getYearsStudyingEnglishList();
    this.titleList = [
      { name: 'Mr', value: 'MR' },
      { name: 'Mrs', value: 'MRS' },
      { name: 'Miss', value: 'MISS' },
      { name: 'Dr', value: 'DR' },
    ];

  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.loadingService.resetLoadingCounter();
  }
  otherState(mode) {
    console.log(mode);
  }
  private prepareForm(): UntypedFormGroup {
    const user = new User();
    const { profile, userProfile } = this.state;
    const dateOfBirth = DateTime.fromFormat(userProfile?.dateOfBirth || '', 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
    const mobileNumber = removeCountryCode(userProfile?.mobileNumber);
    this.selectedCountryISO = getCountryCode(userProfile?.mobileNumber ?? '');
    const languageId = userProfile?.languageId;
    const educationLevelId = userProfile?.marketingDetails?.educationLevelId;
    const occupationSectorId = userProfile?.marketingDetails?.occupationSectorId;
    const occupationLevelId = userProfile?.marketingDetails?.occupationLevelId;
    const testReasonId = userProfile?.marketingDetails?.testReasonId;
    const yearsOfStudy = userProfile?.marketingDetails?.yearsOfStudy;
    const currentlyStudyingEnglishAt = userProfile?.marketingDetails?.currentlyStudyingEnglishAt;
    const countryApplyingToId = userProfile?.marketingDetails?.countryApplyingToId;
    const genderId = userProfile?.genderId;
    const title = userProfile?.title;
    return this.fb.group({
      dateOfBirth: [
        (profile && profile?.dateOfBirth) || dateOfBirth || '',
        { validators: [user.getValidatorFields('birthDate')] }
      ],
      mobileNumber: [
        (profile && profile?.mobileNumber) || mobileNumber || '',
        { validators: [user.getValidatorFields('mobileNumber')] }
      ],
      gender: [(profile && profile.gender) || this.sharedState.getOptionObject(genderId, this.genderList) || '', Validators.required],
      title: [
        (profile && profile.title) || this.sharedState.getOptionObject(title, this.titleList, null, 'title') || '',
        Validators.required
      ],
      firstLanguage: [
        (profile && profile.firstLanguage) || this.sharedState.getOptionObject(languageId, this.languages, userProfile?.languageOther)
        || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      educationLevel: [
        (profile && profile.educationLevel) || this.sharedState.getOptionObject(educationLevelId, this.educationLevelList) || '',
        Validators.required,
      ],
      occupationSector: [
        (profile && profile.occupationSector) ||
        this.sharedState.getOptionObject(
          occupationSectorId,
          this.occupationSectorList,
          userProfile?.marketingDetails?.occupationSectorOther
        ) || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      occupationLevel: [
        (profile && profile.occupationLevel) ||
        this.sharedState.getOptionObject(occupationLevelId, this.occupationLevelList, userProfile?.marketingDetails?.occupationLevelOther)
        || '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
      reasonTest: [(profile && profile.reasonTest) ||
        this.sharedState.getOptionObject(testReasonId, this.testReasonList, userProfile?.marketingDetails?.testReasonOther) || '',
      [Validators.required, emptyWhiteSpaceValidator()]
      ],
      yearsStudyingEnglish: [
        (profile && profile.yearsStudyingEnglish) || this.sharedState.getOptionObject(yearsOfStudy, this.yearsStudy) || '',
        Validators.required,
      ],
      locationStudyEnglish: [(profile && profile.locationStudyEnglish) || currentlyStudyingEnglishAt || '',
      [
        Validators.pattern(LOCATION_STUDY_ENGLISH_REGEXP),
      ]
      ],
      intendLocation: [
        (profile && profile.intendLocation) ||
        this.sharedState.getOptionObject(countryApplyingToId, this.countryList, userProfile?.marketingDetails?.countryApplyingToOther) ||
        '',
        [Validators.required, emptyWhiteSpaceValidator()]
      ],
    }, {
      validators: [
        DobDateCheckerHelper.checkDate(),
        startEndSpaceValidator,
        titleGenderValidator(this.genderList)
      ]
    });
  }
  getYearsStudyingEnglishList() {
    return this.moveOtherToLast(this.options.studyEnglishYears);
  }

  moveOtherToLast(list: Array<{ id: any; name: any }>): Array<any> {
    return this.sharedState.moveOtherToLast(list);
  }

  private getLanguagesList() {
    this.apiService.getLanguage.subscribe(values => {
      if (Array.isArray(values)) {
        this.languages = this.moveOtherToLast([...values]);
      }
    });
  }

  private getReasonTestList() {
    this.apiService.getTestReason.subscribe(values => {
      if (Array.isArray(values)) {
        this.testReasonList = this.moveOtherToLast([...values]);
      }
    });
  }
  private getOccupationLevel() {
    this.apiService.getOccupationLevel.subscribe(values => {
      if (Array.isArray(values)) {
        this.occupationLevelList = this.moveOtherToLast([...values]);
      }
    });
  }
  private getEducationLevel() {
    this.apiService.getEducationLevels.subscribe(values => {
      if (Array.isArray(values)) {
        this.educationLevelList = this.moveOtherToLast([...values]);
      }
    });
  }
  private getGender() {
    this.apiService.getGender.subscribe(values => {
      if (Array.isArray(values)) {
        this.genderList = [...values
          .filter(i => i.name.toLowerCase() !== 'other')
        ];
      }
    });
  }

  private getOccupationSector() {
    this.apiService.getOccupationSector.subscribe(values => {
      if (Array.isArray(values)) {
        this.occupationSectorList = this.moveOtherToLast([...values]);
      }
    });
  }

  getCountries() {
    this.apiService.GetCountries.subscribe(values => {
      if (Array.isArray(values)) {
        this.countryList = this.moveOtherToLast([...values]);
      }
    });
  }
  private getControlValue(controlName: string) {
    return this.form.get(controlName).value || null;
  }

  selectChange(arg: [
    {
      key: string | number | null;
      selectedModel: {
        id: string | number;
        name: string;
      } | null;
    },
    string]
  ) { /* empty */ }
  nextClick() {
    if (this.form.valid) {
      this.store.dispatch(setProfile({ profile: this.form.value }));
      this.sharedState.saveAndExit('survey', this.state).subscribe(() => {
        const navigateUrl = '/onboarding/' + (this.state.isAchievedLastStep ? 'review-profile' : 'identity-selection');
        this.router.navigate([navigateUrl]);
      }, (err) => {
        alert($localize`Sorry, Saving failed, please try again`);
      }
      );
    }
  }

  skipClick() {
    this.store.dispatch(setProfile({ profile: this.form.value }));
    this.router.navigate(['/onboarding/review-profile']);
  }

  checkShowError(fieldName: string): boolean {
    return (
      this.form.get(fieldName).invalid &&
      (this.form.get(fieldName).touched || this.form.get(fieldName).dirty)
    );
  }
  setNudge(fieldName: string) {
    this.nudgeInput[fieldName] = this.checkShowError(fieldName);
    setTimeout(() => {
      this.nudgeInput[fieldName] = false;
    }, 1000);
  }

  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    this.form = this.prepareForm();
    this.form.valueChanges.subscribe(values => {
      const regex = new RegExp(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/);
      if (values && values.locationStudyEnglish && regex.test(values.locationStudyEnglish)) {
        const locationStudyEnglish = values.locationStudyEnglish.replace(regex, '');
        this.form.get('locationStudyEnglish').setValue(locationStudyEnglish);
      }
    });
    if (this.state.isAchievedLastStep) {
      this.router.navigate(['/onboarding/review-profile']);
    }
  }

  getBirthDateTooltip() {
    return this.dobDateCheckerHelper.getTooltip(this.under16YearsOld);
  }
  onChangeBirthDate() {
    this.under16YearsOld = this.dobDateCheckerHelper.birthDateChanged(this.form);
  }

  saveAndExitClick() {
    this.store.dispatch(setProfile({ profile: this.form.value }));
    this.confirmModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      this.sharedState.saveAndExit('profile', this.state, true).subscribe(
        () => {
          this.router.navigate(['/my-account'], {
            state: {
              test: 'test',
            },
          });
        },
        (err) => {
          alert('Sorry, Saving failed, please try again');
        }
      );
    });
  }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }
}
