import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { UserProfileRequest } from '@idp-education/ors-test-taker-bff-client-v1';
import { Title, Meta } from '@angular/platform-browser';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';
import { nameValidator } from 'src/app/shared/validators/custom-validators';
import { AuthService } from 'shared/services/auth.service';
import { FormValidatorService } from 'shared/services/form-validator.service';
import { DobDateCheckerHelper } from 'shared/helper/dob-date-checker.helper';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class SignupFormComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  private userProfileRequest: UserProfileRequest;
  visibility = false;
  enterEmail = '';
  nudghInput = {};
  currentMouseOver: string;
  invalidMessageSignUp = '';
  customMessages = {
    email: {
      pattern: () => $localize`A valid email address is required`,
    },
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`,
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    },
  };
  $form: UntypedFormGroup;
  BirthDateTooltip = 'Your date of birth';
  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private userProfileService: UserProfileService,
    public loadingService: LoadingService,
    private route: ActivatedRoute,
    private cognitoError: CognitoErrorService,
    private authenticationService: AuthService,
    private store: Store<{ bookingStore, globalStore }>,
    private titleService: Title,
    private metaTagService: Meta,
    private formValidatorService: FormValidatorService
  ) {
    this.titleService.setTitle('Create your IDP account | IDP IELTS');
    this.authenticationService.fetchCurrentUserInfo().then(cred => {
      this.enterEmail = cred.attributes.email;
      this.$form?.get('email').setValue(this.enterEmail);
    });

    this.$form = this.defineForm();
  }
  ngOnDestroy(): void {
    this.loadingService.resetLoadingCounter();
  }
  ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Sign up for a new IDP account with an email ID and start your journey with IELTS Online.' },
    );
  }

  onSubmit() {
    if (this.$form.status === 'INVALID') {
      return;
    }
    this.signUp(this.$form.getRawValue());
  }

  onKeyPressLastName($event: KeyboardEvent) {
    if ($event.key.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi)) {
      return false;
    }
  }
  defineForm() {
    const form = this.fb.group({
      id: [''],
      lastName: [''],
      firstName: [''],
      email: [this.enterEmail || '', [
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        Validators.required
      ]],
      mobileNumber: ['', [
        Validators.required,
      ]],
      birthDate: ['', [
        Validators.pattern(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/),
        Validators.required,
      ]],
      termAndCondition1: ['', [Validators.required]],
      termAndCondition2: [''],
      communicationsCheckbox: [''],
    }, {
      validators: [
        nameValidator,
        DobDateCheckerHelper.checkDate()
      ]
    });
    return form;
  }

  async signUp(user) {

    try {
      this.loadingService.increaseLoadingCounter();
      this.showLoading = true;
      localStorage.setItem('Registering', '1');
      this.loadingService.increaseLoadingCounter();
      this.userProfileRequest = this.userProfileService.MappedValueToUserProfile(user);
      this.authenticationService.currentAuthenticatedUser().then(usr => {
        const token = usr.signInUserSession.accessToken.jwtToken;
        this.loadingService.increaseLoadingCounter();
        this.authenticationService.token = token;
        this.userProfileService.insertProfile({
          ...this.userProfileRequest,
          emailAddress: this.userProfileRequest.emailAddress.toLowerCase()
        }).subscribe(() => {
          this.userProfileService.getUserProfile(true).pipe(first()).subscribe({
            error: err => {
              if (err) {
                this.router.navigate(['/create-booking']);
              }
            }, next: () => {
              this.store
                .select((appStore) => appStore.bookingStore)
                .pipe(first())
                .subscribe((x) => {
                  localStorage.setItem('Registering', '0');
                  if (x.latestPageUrl) {
                    this.router.navigate([x.latestPageUrl]);
                  } else {
                    this.router.navigate(['/my-account']);
                  }
                });
            }
          });
          this.loadingService.decreaseLoadingCounter();
        }, (error) => {
          this.loadingService.decreaseLoadingCounter();
          this.updateMessageSignUpInvalid(error);
          localStorage.setItem('Registering', '-1');
        });
        this.loadingService.decreaseLoadingCounter();

      }).catch(err => {
        this.showLoading = false;
        this.loadingService.decreaseLoadingCounter();
        localStorage.setItem('Registering', '-1');
      });
    } catch (error) {
      this.loadingService.decreaseLoadingCounter();
      this.showLoading = false;
      this.updateMessageSignUpInvalid(error);
      localStorage.setItem('Registering', '-1');
    }
  }
  updateMessageSignUpInvalid(error) {
    this.invalidMessageSignUp = this.cognitoError.getErrorMessage(error);
    this.$form.setErrors({
      invalidSignUp: true
    });
    setTimeout(() => {
      this.$form.setErrors(null);
    }, 6000);
  }

}
