<app-form-wrapper [hasUserName]="true" [hasProgressBar]="state.currentStep > 0" [progressValue]="state.progressValue"
    [currentStepNum]="state.currentStep" [totalStep]="state.totalSteps" [currentStepName]="state.currentStepName"
    [stepsRoute]="stepRoutes" [hasBackButton]="state.currentStep !== reviewStepNo">
    <div userName *ngIf="leftSidePanel.hasUser">{{leftSidePanel.userName}}</div>
    <div title class="title-width">{{leftSidePanel[activePage]?.title || ''}}
    </div>
    <!-- <div subTitle>{{leftSidePanel[activePage]?.subTitle || ''}}</div> -->
    <div subTitle>
        <ng-container *ngIf="leftSidePanel[activePage]?.subTitle?.title1; else Subtitle">
            <p>{{leftSidePanel[activePage]?.subTitle?.title1 || ''}}</p>
            <p *ngIf="leftSidePanel[activePage]?.subTitle?.title2">
                {{leftSidePanel[activePage]?.subTitle?.title2 || ''}}
                <a href="https://www.idp.com/australia/privacy-policy/" rel="noopener" target="_blank" tabindex="1"
                    class="bck-highlight privacylink">
                    Privacy Policy.
                </a>
            </p>
            <p>{{leftSidePanel[activePage]?.subTitle?.title3 || ''}}</p>
            <p>{{leftSidePanel[activePage]?.subTitle?.title4 || ''}}</p>
        </ng-container>
        <ng-template #Subtitle>
            {{leftSidePanel[activePage]?.subTitle || ''}}
        </ng-template>
    </div>
    <div actions *ngIf="leftSidePanel[activePage]?.action?.link">
        <h5>{{leftSidePanel[activePage]?.action?.title || ''}}</h5>
        <a [routerLink]="[leftSidePanel[activePage]?.action?.link]"
            class="btn signin-btn">{{leftSidePanel[activePage]?.action?.linkTitle }}</a>
    </div>
    <div content [@routeAnimations]='getState(outlet)' class="main-content" style="display: flex;">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</app-form-wrapper>