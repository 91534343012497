/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// TODO fix this
const awsmobile = {
    aws_project_region: 'ap-southeast-1',
    aws_cognito_identity_pool_id: 'ap-southeast-1:f6ee5ff6-099f-4288-a20d-604667af1795',
    aws_cognito_region: 'ap-southeast-1',
    aws_user_pools_id: 'ap-southeast-1_P0ztPrcKW',
    aws_user_pools_web_client_id: '7h4fe21h8h7oe2gu3q8aua3g72',
    endpoint: 'https://ielts.api.security.idp.com/v1/ielts/cognito-proxy',
    oauth: {
        domain: 'idp-customer-identity-v1.auth.ap-southeast-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://book.ielts.idp.com/login',
        redirectSignOut: 'https://book.ielts.idp.com/login',
        responseType: 'code'
    },
    Storage: {
        AWSS3: {
            bucket: 'idp-customer-identity-v1-prod', // REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', // OPTIONAL -  Amazon service region
            level: 'private'
        }
    }
};


export default awsmobile;
