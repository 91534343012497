import * as ics from 'ics';
export const generateIcal = (events) => {
  ics.createEvents(events, (error, value) => {
    if (error) {
      console.log(error);
      return;
    }
    const blob = new Blob([value], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'Calendar.ics';
    a.click();
  });
};
export const createEventParam = (from: Date, to: Date, title: string, description: string) => ({
  start: [
    from.getFullYear(),
    from.getMonth() + 1,
    from.getDate(),
    from.getHours(),
    from.getMinutes()],
  end: [
    to.getFullYear(),
    to.getMonth() + 1,
    to.getDate(),
    to.getHours(),
    to.getMinutes()],
  title: $localize`${title}`,
  description: $localize`${description}`,
  status: 'CONFIRMED',
});
