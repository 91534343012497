import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-middle-line',
  template: `
    <div><span class="{{textClass}}">{{text}}</span></div>
  `,
  styles: [`
    div {
      width: 70%;
      text-align: center;
      border-bottom: 1px solid gray;
      line-height: 0.1em;
      margin: auto;
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    div span {
        background:#fff;
        padding:0 10px;
    }
  `]
})
export class TextMiddleLineComponent {
  @Input() text = '';
  @Input() textClass = '';
}
