const appSettings = require('src/assets/appSettings.json');
export const getAutomationTestMobileFieldToggle = () => {
  try {
    if (appSettings.site.environmentName !== 'PROD') {
      return (window as any)['switchMobileField'] === true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
