import { AbstractControl } from '@angular/forms';


export const addressValidator = () => {
  return (control: AbstractControl) => {
    if (!(control.value === '' || control.value === null || control.value === undefined)) {
      if (!control.value.match(/^[a-z0-9 ]*$/i)) {
        return { addressValidator: {} };
      }
    }
    return null;
  };
};
