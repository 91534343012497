<ng-container>
  <p class="sub-copy" i18n>Note: We accept Visa and Mastercard only.</p>
  <div class="card-errors" *ngIf="cardErrors.length > 0">
    <p class="flexbox align-items-center" *ngFor="let error of cardErrors"><img
        src="assets/images/payment/alert-circle.svg" alt="alert icon" />{{ error }}</p>
  </div>
  <div class="card-section" #cardContent [ngClass]="isFromNewPaymentPage ? 'tt-payment' : ''">
    <form class="needs-validation" novalidate="">
      <div class="card-warpper" #cardSection>
        <div class="cardbackground" [class.blurBackground]="blurBackground" #cardBackground>
          <div class="type-icon">
            <div #cicon class="credit" [ngClass]="cardType"></div>
          </div>
          <div class="input-container">
            <div class="row content-row">
              <div class="col-12 content-col">
                <div class="input-wrapper card-number" id="cardnumber" name="cardnumber">
                  <div *ngIf="cardNumberValid" class="card-valid-circle">
                    <img src="assets/images/payment/valid-credit-card-tick.svg" alt="tick icon" />
                  </div>
                </div>
                <div class="row cvv-row">
                  <div class="col-12 cvv-date cvv-col">
                    <div class="date-wrapper">
                      <div class="month-input input-wrapper" id="expiration-month"></div>
                      <div class="year-input input-wrapper" id="expiration-year"></div>
                    </div>
                    <div class="cvv-wrapper align-items-center justify-content-center">
                      <div class="cvv-modal" [hidden]="!showCvvModal" #CvvModal>
                        <div class="row main-modal">
                          <div class="col-5 no-padding padding-right">
                            <p class="title" i18n>CVV / CVC</p>
                            <p class="sub-copy" i18n>The last 3 digits located on the back of your card</p>
                          </div>
                          <div class="col-7 no-padding">
                            <img class="img-card" src="assets/images/payment/ccv-card.svg" alt="card" />
                          </div>
                        </div>
                        <div class="row main-modal row-modal-2">
                          <div class="col-5 no-padding padding-right">
                            <p class="title" i18n>AMEX CID</p>
                            <p class="sub-copy" i18n>The 4 digits located on the front of your card above the card
                              number</p>
                          </div>
                          <div class="col-7 no-padding">
                            <img class="img-card" src="assets/images/payment/ccv-card-amex.svg"
                              alt="card" />
                          </div>
                        </div>
                      </div>
                      <div class="infobox">
                        <span class="material-icons text-white info-icon" (click)="showCvvModal = !showCvvModal"
                          (keyup)="showCvvModal = !showCvvModal" #infoBtn>
                          info
                        </span>
                      </div>
                      <div class="inputbox input-wrapper" id="cvv" name="cvv"></div>
                    </div>
                  </div>
                </div>
                <div class="input-wrapper card-holder cardholderName" id="cardholderName" name="cardholderName"
                  #cardholderName></div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-process" *ngIf="(paymentStatus === 'paying' || paymentStatus === 'failed')">
          <img *ngIf="paymentStatus === 'paying'" src="./../../../../assets/images/payment/circle-notch.svg"
            class="fa-spin circle-proccess" alt="icon" />
          <img *ngIf="paymentStatus === 'failed'" src="./../../../../assets/images/payment/PaymentFailedCross.svg"
            class="failed-proccess" alt="icon" />
        </div>
      </div>
      <div class="flexbox align-center conditions sub-copy addTocard">
      </div>
    </form>
  </div>
</ng-container>