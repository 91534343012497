import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IModel } from '../../../models/components/dropdown';
import { getAutomationTestMobileFieldToggle } from '../../../utils/automation-test-toggle';

@Component({
  selector: 'app-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss']
})
export class BillingAddressFormComponent {
  @Input() form: UntypedFormGroup = null;

  selectChange(
    e: IModel<any>,
    controlName) { /* empty */ }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }
}
