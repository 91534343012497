import { createSelector, Selector } from '@ngrx/store';
import { AppIState } from 'store/applications/application.reducer';

export const getState = (state: { applicationsStore: AppIState }) => state.applicationsStore;

export const getUserProfile = (state: AppIState) =>
  state.currentApplication.userProfile.firstName === ''
    ? state?.currentApplication?.userProfile?.lastName : state?.currentApplication?.userProfile?.firstName;

export const getApplicationWithConsentUpdated = (state: AppIState) => state.applicationWithConsentFormUpdated;

const createMyTestsSelector = (selectMethod) => createSelector(
  getState,
  selectMethod
);

export const getApplicationUserName: Selector<{ applicationsStore: AppIState }, any> = createMyTestsSelector(getUserProfile);

export const getApplicationWithConsentFormUpdated: Selector<{ applicationsStore: AppIState }, any> =
  createMyTestsSelector(getApplicationWithConsentUpdated);
