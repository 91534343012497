<div class="flexbox flex-column content align-items-center" id="content">
    <h5 class="content-title" i18n>
        Please have your ID ready to complete your profile
    </h5>
    <div class="w-100 align-center">
        <div class="id-container" *ngFor="let id of idTypes; let i = index" (click)="onIdTypeSelect(id)" (keyup.enter)="onIdTypeSelect(id)"
            [class]="{ active: selectedId.code === id.code }" [tabindex]="i+1">
            <div class="id-check">
                <img *ngIf="selectedId.code === id.code" src="./assets/images/ID_Check.svg" alt="id icon" />
            </div>
            <img class="id-icon" [src]="'./assets/images/ID_'+ id.code +'.svg'" alt="id icon" />
            <div class="subtitle" i18n> {{ id.name }} </div>
        </div>
    </div>
    <button class="btn btn-multiAction m-2" (click)="onNextClick()" i18n [tabindex]="idTypes?.length + 1">Next</button>
</div>