<div class="d-flex flex-grow-1 content flex-column align-items-start justify-content-start">
  <div class="all-content d-flex justify-content-start align-items-stretch flex-column w-100">
    <div class="d-flex align-items-center ">
      <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3" (click)="onBackButtonClick.emit()">
        <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
      </button>
      <h2 class="underlined-title">
        Select a test date or date range
      </h2>
    </div>
    <h3 class="result-disclaimer">
      A One Skill Retake is only available to book within 60 days of the original test date.
    </h3>
    <div class="content-align" *ngIf="(viewportService.isTablet$ | async) !== true">
      <div class="dateselected" *ngIf="renderCalendar">
        <p class="paraselect" *ngIf="!showError">Date selected: {{selectedDate}}</p>
        <p class="required-error" *ngIf="showError">Please select a date range to continue.</p>
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
                        outsideDays="hidden" [minDate]="minDate" [firstDayOfWeek]="7" [maxDate]="maxDate"
                        [navigation]="navigation"
                        [markDisabled]="isDisabled" (navigate)="onNavigate()">
        </ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
          <div class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
               [class.before-from]="isBeforeFrom(date)" [class.first]="isFirst(date)"
               [class.selecting-second]="isSelectingSecond()" [class.sunday]="isSunday(date)"
               [class.last]="isLast(date)"
               [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
               (mouseleave)="hoveredDate = null">
            <div class="overlay">{{ date?.day }}</div>
          </div>
        </ng-template>
      </div>
      <div class="footer-row">
        <div [class.d-none]="showPara !== true" class="para">
          <img class="imageAlign" src="../../../../assets/images/Icon-ionic-ios-calendar.svg" alt="">
          <p class="para-text">Book this test within 60 days of previous test</p>
        </div>
      </div>
    </div>
    <div *ngIf="(viewportService.isTablet$ | async)">
      <div *ngIf="showPara === true" class="para">
        <img class="imageAlign" src="../../../../assets/images/Icon-ionic-ios-calendar.svg" alt="">
        <p class="para-text">Book this test within 60 days of previous test</p>
      </div>
      <div class="dateselected">
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
                        outsideDays="hidden" [minDate]="minDate" [firstDayOfWeek]="7" [maxDate]="maxDate"
                        [navigation]="navigation"
                        [markDisabled]="isDisabled" (navigate)="onNavigate()">
        </ngb-datepicker>

        <ng-template #t let-date let-focused="focused">
          <div class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
               [class.before-from]="isBeforeFrom(date)" [class.first]="isFirst(date)"
               [class.selecting-second]="isSelectingSecond()" [class.sunday]="isSunday(date)"
               [class.last]="isLast(date)"
               [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
               (mouseleave)="hoveredDate = null">
            <div class="overlay">{{ date?.day }}</div>
          </div>
        </ng-template>
      </div>
    <div class="footer-section d-flex flex-column">
      <p class="paraselect" *ngIf="!showError">Date selected: {{selectedDate}}</p>
      <p class="required-error" *ngIf="showError">Please select a date range to continue.</p>
    </div>
  </div>
  <app-two-button [acceptText]="'Continue'" [rejectText]="'Cancel'" [inCenter]="false" [enableAcceptClick]="true"
                  [showReject]="true" [showAccept]="true" (rejectClick)="onCancelClick.emit()"
                  (acceptClick)="findSession($event)">
    </app-two-button>
  </div>
</div>
