import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserProfileService } from '../../services/user-profile.service';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';
import { SetProductType } from 'src/app/pages/booking/store/booking.actions';
import { AuthService } from '../../services/auth.service';
import { Logout } from 'src/app/store/global.actions';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';
const appSettings = require('src/assets/appSettings.json');

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  @Input() userName: string;
  collapsed = true;
  sub: Subscription;
  @ViewChild('mobileMenu') mobileMenu: ElementRef;
  @ViewChild('changePasswordComponent') changePasswordComponent: ChangePasswordComponent;
  hrefValues = {
    cxUrl: generateUtmUrl(UtmLinkKeys.CxUrl)
  }
  constructor(private renderer: Renderer2,
              private userProfileService: UserProfileService,
              private router: Router,
              private store: Store,
              private authService: AuthService
  ) { }
  signOut() {
    this.authService.signOut();
  }
  openProfilePage() {
    this.router.navigate(['/my-profile']);
  }

  toggleMenu(): void {
    this.collapsed = !this.collapsed;
    if (!this.collapsed) {
      this.renderer.addClass(this.mobileMenu.nativeElement, 'links-mobile');
    } else {
      this.renderer.removeClass(this.mobileMenu.nativeElement, 'links-mobile');
    }
  }

  bookTestClick(): void {
    window.open(generateUtmUrl(appSettings.site.bxsearchURL), '_blank');
  }
}
