import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  loadScript(scriptUrl: string, clientKey: string, charset?: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      if (clientKey) {
        script.setAttribute('data-client-key', clientKey);
      }
      if (charset) {
        script.setAttribute('charset', charset);
      }
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      document.head.appendChild(script);
    });
  }

  loadBankAlfalahScript(scriptUrl: string, callBackError: string, cancelCallback: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.setAttribute('data-error', callBackError);
      script.setAttribute('data-cancel', cancelCallback);
      script.onload = () => {
        resolve();
      };
      script.onerror = (error) => {
        reject(error);
      };
      document.head.appendChild(script);
    });
  }
}
