<div class="container-fluid">
    <div class="container-header">
      <div class="flexbox header-box mb-3">
        <div class="d-flex align-items-center ">
          <button class="btn btn-link btn-focus ms-0 px-0 mt-3 me-3" (click)="backToMyTests()">
            <img src="../../../../assets/images/back-button-ssr.svg" alt="back icon">
          </button>
          <h2 class="underlined-title">IELTS One Skill Retake</h2>
        </div>
      </div>
    </div>
    <div class="container-body">
    <div>
      <h6 class="m-0">IELTS One Skill Retake - Important Information</h6>
      <div class="small mt-2">
        <p>One Skill Retake is accepted globally by many recognizing organizations. It is important that you check if the institution you are applying to accepts One Skill Retake.</p>
      </div>
      <h6 class="m-0">What you need to know about 'One Skill Retake':</h6>
        <div class="small mt-2">
            <ol class="p-3">
                <li>You can retake one skill within 60 days of your original test.</li>
                <li>You can only book and complete a single One Skill Retake for any full IELTS test.</li>
                <li>You have taken your original test at a IDP IELTS test centre.</li>
                <li>You will need to have the same identification document used for your original IELTS test to proceed with this booking.</li>
            </ol>
        </div>
    </div>
  </div>
    <form class="form" [formGroup]="osrCheckboxForm">
      <app-circle-checkbox class="save-check d-block text-gray Instructional-text" [form]="osrCheckboxForm" [control]="'osrCheckbox'"
          i18n-title title="I understand and agree to the above">
      </app-circle-checkbox>
  </form>
    <div class="container-footer mb-3 mt-3">
      <div class="d-flex buttonsWrapper" class="justify-content-start" >
        <div class="d-flex flex-wrap flex-md-nowrap inner-container'">
          <button [disabled]="!(osrCheckboxForm.controls.osrCheckbox.value)" type="button"
            class="btn btnfocus btn-irrelevantAction-focus isActive" (click)="OnssrInstructionClick.emit()" i18n>
           <i aria-hidden="true"></i>
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  