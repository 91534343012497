<div class="banner" *ngIf="session">
  <div class="card-mobile-day-banner d-none d-lg-flex">{{formatDate(session.testStartLocalDatetime, 'dddd')}}</div>
  <div class="card-mobile-date-banner d-flex">{{formatDate(session.testStartLocalDatetime, 'd')}}</div>
  <div class="day-month">
    <div class="card-mobile-day-banner d-flex d-lg-none">{{formatDate(session.testStartLocalDatetime, 'dddd')}}</div>
    <div class="card-mobile-month-banner d-flex">{{formatDate(session.testStartLocalDatetime, 'mmmm')}}</div>
  </div>
  <div class="card-mobile-time-banner">{{formatDate(session.testStartLocalDatetime, 'h:MM')}}
    <span>{{formatDate(session.testStartLocalDatetime, 'TT')}}</span>
  </div>
</div>
<div class="card-content">
  <div class="item-header">
    <div class="left-column">
      <div class="test-type card-item-header">
        <app-ssr-test-type [type]="[session.testCategory, session.testModule].join(',')"></app-ssr-test-type>
      </div>
      <div class="location-container">
        <a class="test-location card-description" placement="bottom-left" [disablePopover]="(viewportService.isTablet$ | async)" [ngbPopover]="popContent" popoverClass="ssr-location-popover" [autoClose]="'outside'" #popover="ngbPopover">
          <i class="fa fa-map-marker" aria-hidden="true"></i> <span>{{session.testLocation?.name}}</span>
        </a>
      </div>
    </div>
    <div class="right-column card-description">
      <app-ssr-seat-availability [seatAvailability]="session.seatAvailability"></app-ssr-seat-availability>
      <div class="price">
        <app-ssr-test-fee [testFee]="session?.testFee" [testLocationId]="session?.testLocation.id"></app-ssr-test-fee>
      </div>
      <div class="distance d-none d-lg-block">{{formatDistance(session.distanceToTestLocationKm)}}</div>
    </div>
  </div>
  <div class="item-description">
    <div class="left-column">
      <div class="test-format card-description">
        <app-ssr-list-item-test-format [format]="session.testFormat"></app-ssr-list-item-test-format>
      </div>
      <div class="result-expectation card-description">
        <div class="calendar-icon" aria-hidden="true"></div> <span><app-ssr-expected-result [testFormat]="session.testFormat" [testCategory]="session.testCategory"></app-ssr-expected-result></span>
        <div class="distance d-lg-none">{{formatDistance(session.distanceToTestLocationKm)}}</div>
      </div>
    </div>
    <div class="right-column">
      <button type="button" class="btn btn-outline-secondary btn-reserve" (click)="onReserve.emit(session)">Reserve
        now</button>
    </div>
  </div>
  <button type="button" class="btn btn-multiAction btn-reserve w-100"
    (click)="onReserve.emit(session)">Reserve
    now</button>
</div>
<ng-template #popContent>
  <div class="location-popup popover-description">
    <div class="header">
      <div>{{session.testLocation?.name}}</div>
      <button class="btn-close" type="button" (click)="popoverClose(popover)">
        <i aria-hidden="true" class="fa fa-times popover-icon-size"></i>
      </button>
    </div>
    <div class="content">
      <div class="address-row">
        <div class="address">
          <div class="text-blue">Address</div>
          <ng-container *ngIf="session.testLocation?.address?.line1">{{session.testLocation?.address?.line1}}
          </ng-container>
          <ng-container *ngIf="session.testLocation?.address?.line2"><br>{{session.testLocation?.address?.line2}}
          </ng-container>
          <ng-container *ngIf="session.testLocation?.address?.line3"><br>{{session.testLocation?.address?.line3}}
          </ng-container>
          <ng-container *ngIf="session.testLocation?.address?.line4"><br>{{session.testLocation?.address?.line4}}
          </ng-container>
        </div>
        <div class="phone" *ngIf="session.testLocation?.contactPhoneNumber">
          <div class="text-blue">Phone</div>
          {{session.testLocation?.contactPhoneNumber}}
        </div>
      </div>
      <div class="info" *ngIf="session.testLocation?.notes">
        <div class="text-blue">Venue information</div>
        {{session.testLocation?.notes?.join(' ')}}
      </div>
      <!--<div class="visit-link">
        <a [href]="session.testCentreUrl" target="_blank">Visit test centre<ion-icon
          name="chevron-forward-circle-outline"></ion-icon></a>
      </div>-->
    </div>
  </div>
</ng-template>
