<app-form-wrapper [showFadeBox]="true" [hasUserName]="true" [hasProgressBar]="state?.currentStep > 0"
  [progressValue]="state?.progressValue" [currentStepNum]="state?.currentStep" [totalStep]="state?.totalSteps"
  [currentStepName]="state?.currentStepName" [hasTimer]="hasTimer" [timerTargetTime]="applicationClose"
  [timerCurrentTime]="currentApplicationTime" (timerDone)="whenTimerDone()">
  <div userName *ngIf="leftSidePanel.hasUser">{{ leftSidePanel.userName }}</div>
  <div title>
    {{ leftSidePanel[activePage] ? leftSidePanel[activePage]?.title : '' }}</div>
  <div subTitle>
    <app-circle-sidebar *ngIf="state?.currentStep === 3 || (state.currentStepName === 'Payment' && isNotIOLProduct)" i18n-stTitle [stTitle]="'Speaking test'"
      [stDate]="state?.speakingtesttime" i18n-lrwTitle [lrwTitle]="'Listening, Reading and Writing test'"
      [lrwDate]="state?.lrwtesttime" [showPrice]="true" [showPencil]="false" [isNotIOLProduct]="isNotIOLProduct" [testLocalTimezone]="testLocalTimezone" [productFee]="state?.productFee">
    </app-circle-sidebar>
    <div class="sub-subtitle" *ngIf="state?.currentStep === 4">
      <span i18n *ngIf="((isOfflinePayment$ | async) || isVeritransCVS); else onlinePaymentTemplate">You will need to pay:</span>
      <ng-template #onlinePaymentTemplate>You have paid:</ng-template>
      <div class="row mt-3">
        <div class="col-12 flexbox amount-wrapper">
          <div class="amount-box">
            {{ state?.productFee?.totalAmount |currency : state?.productFee?.currencyIsoCode : 'symbol-narrow' }} {{state?.productFee?.currencyIsoCode}}
          </div>
        </div>
      </div>
      <div class="row mt-3" *ngIf="isOfflinePayment$ | async">
        <div class="col-12 d-flex flex-row align-items-center">
          <div class="receipt-icon"></div>
          <div class="sub-copy">
          Once you have successfully paid for the test,
          an invoice will be emailed to {{userEmail}}</div>
        </div>
      </div>

    </div>
  </div>
  <div actions *ngIf="leftSidePanel[activePage] && leftSidePanel[activePage]?.action.link">
    <h5>
      {{ leftSidePanel[activePage] ? leftSidePanel[activePage].action.title : '' }}
    </h5>
    <a [routerLink]="[leftSidePanel[activePage]?.action.link]" class="btn signin-btn">{{leftSidePanel[activePage] ?
      leftSidePanel[activePage]?.action.linkTitle : '' }}</a>
  </div>
  <div content [@routeAnimations]='getState(outlet)' class="main-content" style="display: flex;">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</app-form-wrapper>
<app-confirm-modal #restartBookingModal title="Oops! You've run out of time"
  subTitle="Sorry, you will need to choose your test times again."
  imageUrl="../../../../assets/images/boy-read-book.png" acceptText="Restart booking" [showReject]="false"
  acceptClass="btn-blue" (onClose)="timeOutModalConfirm()" (onAcceptClicked)="timeOutModalConfirm()">
</app-confirm-modal>
