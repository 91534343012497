import { Component } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../signup/userProfile.model';
import { ToastrService } from 'ngx-toastr';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { UserProfileRequest } from '@idp-education/ors-test-taker-bff-client-v1';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { DateTime } from 'luxon';
import { nameValidator } from 'src/app/shared/validators/custom-validators';
import { getAutomationTestMobileFieldToggle } from '../../../shared/utils/automation-test-toggle';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-social-signup',
  templateUrl: './social-signup.component.html',
  styleUrls: ['./social-signup.component.scss']
})
export class SocialSignupComponent {
  $form: UntypedFormGroup;
  showLoading = false;
  nudgeInput = {};
  private tempUserName = '';
  private tempSocialMedia = '';
  private userProfileRequest: UserProfileRequest;
  user = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    birthDate: '',
    termAndCondition1: false,
    termAndCondition2: false,
  };
  under16YearsOld = false;
  currentMouseOver: string;
  userModel: User;
  token: string;
  customMessages = {
    email: {
      pattern: () => $localize`A valid email address is required`
    },
    mobileNumber: {
      required: () => $localize`A valid telephone number is required`
    },
    birthDate: {
      pattern: () => $localize`A valid date is required`,
    },
  };
  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              private userProfileService: UserProfileService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private loadingService: LoadingService) {
    this.userModel = new User();

    Auth.currentAuthenticatedUser().then((userData) => {
      this.tempSocialMedia = this.getProviderName(userData);
      this.user.email = userData.attributes.email;
      this.token = userData.signInUserSession.accessToken.jwtToken;
      this.authService.token = this.token;
      if (this.user.email && this.tempSocialMedia !== 'facebook') {
        setTimeout(() => {
          this.$form.controls.email.setValue(this.user.email);
          this.$form.controls.email.disable({ onlySelf: true });
        }, 10);
      }
    }).catch((e) => {
      this.router.navigate(['/account']);
    });
    this.createForm();
  }
  getProviderName(data) {
    try {
      const identity = JSON.parse(data.attributes.identities);
      return identity[0].providerName;
    } catch (err) {
      return '';
    }
  }
  createForm() {
    this.$form = this.fb.group({
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      email: [this.user.email, [Validators.required, new User().getValidatorFields('email')]],
      mobileNumber: ['', this.userModel.getValidatorFields('mobileNumber')],
      birthDate: ['', this.userModel.getValidatorFields('birthDate')],
      termAndCondition1: [this.user.termAndCondition1, Validators.required],
      termAndCondition2: [this.user.termAndCondition2, Validators.required],
    }, {
      validators: [nameValidator]
    });
  }
  private checkName() {
    return (formGroup: UntypedFormGroup) => {
      const firstName = formGroup.controls['firstName'];
      const lastName = formGroup.controls['lastName'];

      // set error on matchingControl if validation fails
      if (!firstName.value && !lastName.value) {
        firstName.setErrors({ required: { message: $localize`Either first or last name is required` } });
      } else {
        firstName.setErrors(null);
      }
    };
  }
  checkShowError(fieldName: string): boolean {
    return this.$form.get(fieldName).invalid
      && (this.$form.get(fieldName).touched || this.$form.get(fieldName).dirty);
  }
  setNudge(fieldName: string) {
    this.nudgeInput[fieldName] = this.checkShowError(fieldName);
    setTimeout(() => {
      this.nudgeInput[fieldName] = false;
    }, 1000);
  }

  getBirthDateTooltip() {
    if (this.under16YearsOld) {
      return $localize`Under 16 years old`;
    }
    return $localize`Your date of birth`;
  }
  onChangeBirthDate() {
    if (!this.$form.get('birthDate').invalid) {
      const years = DateTime.now().diff(DateTime.fromFormat(this.$form.get('birthDate').value || '', 'd/M/yyyy'), 'years').years;
      if (years < 16) {
        this.under16YearsOld = true;
      } else {
        this.under16YearsOld = false;
      }
    }
  }
  onSubmit() {
    if (this.$form.status === 'INVALID') {
      return;
    }
    const user = new User();
    user.copyTo({ ...this.$form.value });
    this.insertUserProfile(user);
  }
  async insertUserProfile(userProfileData: User) {
    try {
      this.showLoading = true;
      userProfileData.mobileNumber = userProfileData.mobileNumber.e164Number;
      userProfileData.emailAddress = this.$form.get('email').value.toLowerCase();
      if (this.user !== undefined) {
        this.userProfileRequest = this.userProfileService.MappedValueToUserProfile(userProfileData);
        this.authService.token = this.token;
        this.loadingService.increaseLoadingCounter();
        this.userProfileService.insertProfile(this.userProfileRequest).subscribe(() => {
          this.router.navigate(['/my-account']);
          this.showLoading = false;
          this.loadingService.decreaseLoadingCounter();
        }, (e) => {
          this.showLoading = false;
          this.loadingService.decreaseLoadingCounter();
          this.toastr.error((e && e.error) || $localize`something is wrong`);
          Auth.signOut({ global: true }).then(() => {
            this.router.navigate(['/account/signin']);
          });
        });
      }
    } catch (error) {
      this.showLoading = false;
      this.loadingService.resetLoadingCounter();
      this.toastr.error((error && error.message) || $localize`something is wrong`);
    }
  }

  get userName() {
    return this.tempUserName;
  }
  get socialMedia() {
    return this.tempSocialMedia;
  }

  public getAutomationTestToggle() {
    return getAutomationTestMobileFieldToggle();
  }
}
