<div class="flexbox flex-column content align-items-center align-items-lg-start" appApplyTabIndexes>
    <h5 class="content-title" i18n>Please confirm your address</h5>
    <div class="form-wrapper mt-3 mb-2 w-100 p-1">
      <app-address-details-form [form]="form" *ngIf="form"></app-address-details-form>
    </div>
    <app-two-button (acceptClick)="nextClick()" (rejectClick)="saveAndExitClick()" [enableAcceptClick]="form?.valid"
      defaultActiveButton="accept" acceptText="Save and continue" rejectText="Save and Exit" [showReject]="false">
    </app-two-button>
  </div>
  <app-confirm-modal #saveAndExitModal title="Are you sure you wish to exit?"
    imageUrl="../../../../assets/images/Girl green organic shape.png" defaultActiveButton="reject"></app-confirm-modal>
