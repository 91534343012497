import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnBoardingRoutingModule } from './onboarding-routing.module';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ManageOnboardingComponent } from './manage-onboarding.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadPassportComponent } from './upload-passport/upload-passport.component';
import { DetailPassportComponent } from './detail-passport/detail-passport.component';
import { SharedStateService } from './shared-state.service';
import { AddressDetailPassportComponent } from './address-detail-passport/address-detail-passport.component';
import { ProfileComponent } from './profile/profile.component';
import { ReviewProfileComponent } from './review-profile/review-profile.component';
import { IdentityDocumentComponent } from './identity-document/identity-document.component';
import { IdentityDetailComponent } from './identity-detail/identity-detail.component';
import { AddressDetailComponent } from './address-detail/address-detail.component';
import { IdentitySelectionComponent } from './identity-selection/identity-selection.component';
@NgModule({
  declarations: [
    WelcomePageComponent,
    ManageOnboardingComponent,
    UploadPassportComponent,
    DetailPassportComponent,
    AddressDetailPassportComponent,
    ProfileComponent,
    ReviewProfileComponent,
    IdentityDocumentComponent,
    IdentityDetailComponent,
    AddressDetailComponent,
    IdentitySelectionComponent,
  ],
  imports: [CommonModule, SharedModule, OnBoardingRoutingModule],
  providers: [SharedStateService],
})
export class OnBoardingModule {}
