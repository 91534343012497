<div class="flexbox flex-column justify-content-center align-items-center">
  <h6 class="m-0" *ngIf="percent === 0" i18n>Trying to send...</h6>
  <h6 class="m-0" *ngIf="percent > 0" i18n>Your passport is uploading {{ percent }}%</h6>
  <h6 class="m-0" *ngIf="percent === 100" i18n>Complete</h6>
  <button *ngIf="percent < 100"
          class="btn"
          (click)="abortUpload.emit()"
          i18n
  >Cancel</button>
</div>
