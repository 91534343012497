
import { Selector, createReducer, createSelector, on } from '@ngrx/store';
import { setAllUser, setFromLoginPage, setLogOut, setRequested, setUserDetails, setUserFTime } from './user.actions';
import { UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1/model/userProfileResponse';
import { DateTime } from 'luxon';

export interface UserState {
    userDetails?: UserProfileResponse;
    userFetchedTime: DateTime;
    userToken: string;
    fromLoginPage: boolean;
    isLogOut: boolean;
}

export interface UserRequestState {
    requested: boolean;
}

export const initialState: UserState = {
    userFetchedTime: null,
    userToken: null,
    fromLoginPage: false,
    isLogOut: false
};

export const initialRequestState: UserRequestState = {
    requested: false
};

const reducer = createReducer(
    initialState,
    on(setUserDetails, (state, payload: UserProfileResponse) => ({
        ...state,
        userDetails: payload
    })),
    on(setUserFTime, (state, payload: DateTime) => ({
        ...state,
        userFetchedTime: payload
    })),
    on(setAllUser, (state, { userDetails, userFetchedTime, userToken }) => ({
        ...state,
        userFetchedTime,
        userDetails,
        userToken
    })),
    on(setFromLoginPage, (state, { fromLoginPage }) => ({
        ...state,
        fromLoginPage
    })),
    on(setLogOut, (state, payload) => ({
        ...state,
        isLogOut: payload.logout
    })),
);

const Requestreducer = createReducer(
    initialRequestState,
    on(setRequested, (state, { requested }) => ({
        ...state,
        requested
    })),
);
export function userReducer(state: any, action: any) {
    return reducer(state, action);
}

export function userRequestReducer(state: any, action: any) {
    return Requestreducer(state, action);
}

export const getState = (state: { userStore: UserState }) => state.userStore;

export const getUserName = (state: UserState) =>
    state?.userDetails?.firstName === '' ? state?.userDetails?.lastName : state?.userDetails?.firstName;

const createUserProfileSelector = (selectMethod) => createSelector(
    getState,
    selectMethod
);

export const getUserProfileName: Selector<{ userStore: UserState }, any> = createUserProfileSelector(getUserName);



