import { createReducer, on } from '@ngrx/store';
import { setViewEnable } from './account.actions';


const initialState = {
    ViewEnable: true
};

const reducer = createReducer(
    initialState,
    on(setViewEnable, (state, payload: { ViewEnable: boolean }) => ({
        ...state,
        ViewEnable: payload.ViewEnable
    })),
);

export function accountReducer(state: any, action: any) {
    return reducer(state, action);
}
