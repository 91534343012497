import { Component, EventEmitter, Output } from '@angular/core';
import { DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';

@Component({
  selector: 'app-ssr-search-institution',
  templateUrl: './ssr-search-institution.component.html',
  styleUrls: ['./ssr-search-institution.component.scss']
})
export class SsrSearchInstitutionComponent {

  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrFormatClick: EventEmitter<DashboardItem> = new EventEmitter();

  backToMyTests() {
    this.onBackButtonClick.emit();
 }

}
