import { map } from 'rxjs/operators';
import { SharedStateService, IState } from './../shared-state.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { setBackAction } from 'src/app/store/global.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-final-step',
  templateUrl: './reset-password-final-step.component.html',
  styleUrls: ['./reset-password-final-step.component.scss']
})
export class ResetPasswordFinalStepComponent implements OnInit, OnDestroy {
  stateData: IState;
  constructor(private store: Store<{any}>, private router: Router, private state: SharedStateService) {
  }

  ngOnInit(): void {
    this.store.dispatch(setBackAction({ BackButtonEnable: false, BackButtonRoute: null }));
    this.state.changeState({});
  }
  ngOnDestroy() {
    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: null }));
  }
}
