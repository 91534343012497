import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { createReducer, on } from '@ngrx/store';
import { setApplication, setCurrentApplication, updateApplicationConsentFormSuccess } from './application.action';

export type AppIState = {
    applicationsList: Application[];
    currentApplication: Application;
    applicationWithConsentFormUpdated?: Application;
};

const initialState: AppIState = {
    applicationsList: [],
    currentApplication: null
};

export const ApplicationsReducer = createReducer(
    initialState,
    on(setApplication, (state, payload: {application: Application}) => ({
        ...state,
        applicationsList: [...state.applicationsList,
                            payload.application ]
    })),
    on(setCurrentApplication, (state, payload: {application: Application}) => ({
        ...state,
        currentApplication: payload.application
    })),
    on(updateApplicationConsentFormSuccess, (state, payload) => ({
      ...state,
      applicationWithConsentFormUpdated: payload.updatedApplication
    }))
);
