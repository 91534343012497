import { CountryCode, NationalNumber, parsePhoneNumberFromString, } from 'libphonenumber-js';
import { getAutomationTestMobileFieldToggle } from './automation-test-toggle';

type MobileProperty = 'country' | 'countryCallingCode' | 'nationalNumber';

export const removeCountryCode = (phoneNumber: string, checkAutomationTestToggle = true): NationalNumber | string => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (!parsedPhoneNumber) {
    return undefined;
  }
  const { nationalNumber } = parsedPhoneNumber;
  if (checkAutomationTestToggle) {
    return getAutomationTestMobileFieldToggle() ? phoneNumber : nationalNumber;
  }
  return phoneNumber;
};

export const getCountryCode = (phoneNumber: string, checkAutomationTestToggle = true): CountryCode => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (!parsedPhoneNumber) {
    return undefined;
  }
  const { country } = parsedPhoneNumber;
  if (checkAutomationTestToggle) {
    return getAutomationTestMobileFieldToggle() ? null : country;
  }
  return country;
};

export const getMobileProperty = (
  phoneNumber: string,
  checkAutomationTestToggle = true,
  property: MobileProperty = 'country'
): string => {
  if (!phoneNumber) {
    return undefined;
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

  if (!parsedPhoneNumber) {
    return undefined;
  }
  const propertyValue = parsedPhoneNumber[property];
  if (checkAutomationTestToggle) {
    return getAutomationTestMobileFieldToggle() ? null : propertyValue;
  }
  return propertyValue;
};
