<div class="d-block align-items-center toggle-link">
  <ng-container *ngIf="isInstructions; else paymentReference">
    <div class="d-block" *ngIf="showBackButton">
      <div class="card-content-button position-relative">
        <button class="btn back-button" (click)="backToPayment.emit()">
          Back to payment options
        </button>
      </div>
    </div>
    <h3 class="content-title mt-5 mb-3" i18n>To successfully make your payment:</h3>
    <ol class="steps title-text">
      <li class="d-flex flex-row align-items-center">
        <div class="circle-number"></div>
        <div>
          On the next screen, note the Payment Reference Number. You will need to use it when making a payment to your test centre.
        </div>
      </li>
      <li class="d-flex flex-row align-items-center">
        <div class="circle-number"></div>
        <div>
          To complete your booking, make sure to pay. If you don't want to pay to the test center, you'll need to return to the payment options page. Once your application is confirmed, you can't change the payment method.
        </div>
      </li>
      <li *ngIf="isPointVisible$ | async" class="d-flex flex-row align-items-center">
        <div class="circle-number"></div>
        <div>
          Check the instructions for paying offline by clicking the link on the confirmation page. Pay attention to any important details.
        </div>
      </li>
      <li class="d-flex flex-row align-items-center">
        <div class="circle-number"></div>
        <div>
          Proceed to make a payment and if required provide confirmation to the test centre. Your test will only be reserved once the test centre successfully receives your payment.
        </div>
      </li>
    </ol>

    <app-circle-checkbox class="save-check" [disable]="false"
      title="I can confirm that I have read and understood the payment options information"
      [form]="form" [control]="'haveRead'">
    </app-circle-checkbox>
    <div class="my-3 center">
      <button [disabled]="!this.form?.controls.haveRead.value" class="btn btn-multiAction" type="submit"
        (click)="isInstructions = false" i18n>
        {{ customButtonLabel === "" ? "Continue" : customButtonLabel }}
      </button>
    </div>
  </ng-container>
  <ng-template #paymentReference>
    <div>
      <div class="btn d-flex my-3 flex-row align-items-center w-100 info-box"
        *ngIf="instructionLink$ | async; let link">
        <i class="fa d-flex align-items-center fa-info-circle text-danger me-1" aria-hidden="true"></i>
        <div class="sub-copy d-flex flex-grow-1">
          <span>
            Please use the following reference when making your payment
          </span>
        </div>
      </div>
      <h3 class="content-title mb-3" i18n>Your payment reference ID</h3>
      <div class="payment-id mb-3 title-text">{{ receiptNumber }}</div>
      <div class="my-3 center">
        <button [disabled]="!this.form?.controls.haveRead.value" class="btn btn-multiAction w-fit" type="submit"
          (click)="onPayClick()" i18n>
          {{
          customButtonLabel === ""
          ? "Complete your reservation"
          : customButtonLabel
          }}
        </button>
      </div>
    </div>
  </ng-template>
</div>