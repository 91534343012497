import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setCurrentStep,
  setCurrentStepName,
  setIsAchievedLastStep,
  setProgressValue,
} from '../store/onboarding.actions';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IState } from '../store/onboarding.reducer';
@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  state: IState;
  storeSub: Subscription;

  constructor(
    private store: Store<{ onboardingStore }>,
    private titleService: Title,
    private router: Router,
    private metaTagService: Meta
  ) {
    this.storeSub = this.store.select(appState => appState.onboardingStore).subscribe(e => {
      this.state = e;
    });
    this.titleService.setTitle('Create your profile | IDP IELTS');
    this.store.dispatch(setCurrentStep({ currentStep: 0 }));
    this.store.dispatch(setCurrentStepName({ currentStepName: 'Get started' }));
    this.store.dispatch(setProgressValue({ progressValue: 0 }));
    this.store.dispatch(setIsAchievedLastStep({ isAchievedLastStep: false }));
  }

  ngOnInit(): void {
    this.metaTagService.addTags([
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    ]);
  }

  onNextClick() {
    this.router.navigate(['/onboarding/address-detail']);
  }

  ngOnDestroy(): void {
    this.storeSub.unsubscribe();
  }
}
