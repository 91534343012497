<div class="flex flex-row onboarding-section">
    <div class="mb-5 text-center">
        <p class="mt-4" i18n>
            We will need your details before you sit the test.
        </p>
        <p class="mt-4" i18n>
            If you need to make any changes to your profile later, you can do so from your account.
        </p>
    </div>
    <app-two-button (acceptClick)="onNextClick()" defaultActiveButton="accept" acceptText="Get started"
        [showReject]="false" [enableAcceptClick]="true">
    </app-two-button>
</div>
