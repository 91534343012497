import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { LoadingService } from '../../../services/loading-service.service';
import { PaymentsService } from '../../../services/payment.services';
import * as braintree from 'braintree-web';
import { IPaymentStatus } from '../../../../pages/payment/store/payment.actions';
import { IPaymentInterface, IPaymentMethods } from '../../../interfaces/payment.interface';
import { ICon } from '../payment-container/payment-container.component';
@Component({
  selector: 'app-payment-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent
  implements OnInit, OnChanges, IPaymentInterface {
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() paymentToken: string;
  @Input() isFromNewPaymentPage: boolean;
  @Output() onCardTypeChange: EventEmitter<braintree.HostedFieldsEvent> =
    new EventEmitter();
  @Output() onValidityChange: EventEmitter<{
    isValid: boolean;
    errors: Array<string>;
    cardErrors: string[];
  }> = new EventEmitter();
  @Output() onPaymentStatusChange: EventEmitter<IPaymentStatus> =
    new EventEmitter();
  @Output() onCreatedBraintree: EventEmitter<void> = new EventEmitter();
  @Output() onCreateError: EventEmitter<void> = new EventEmitter();
  @Output() onLoaded: EventEmitter<void>;
  @Output() onApproval: EventEmitter<any>;
  @Output() onError: EventEmitter<{ errors: string[] }>;
  @Output() onValidationChange: EventEmitter<{
    isValid: string[];
    errors: string[];
    cardErrors: string[];
  }>;
  @ViewChild('cardBackground') cardBackground: ElementRef;
  @ViewChild('cardSection') cardSection: ElementRef;
  @ViewChild('cardContent') cardContent: ElementRef;
  @ViewChild('cicon') cicon: ElementRef;
  @ViewChild('CvvModal') CvvModal: ElementRef;
  @ViewChild('infoBtn') infoBtn: ElementRef;
  prepareData = new BehaviorSubject<any>({});
  selectedType: 'credit' | 'active' | 'creditActive' = 'credit';
  cardErrors: string[] = [];
  cardNumberValid = false;
  showCvvModal = false;
  paymentStatus: IPaymentStatus = 'not started';
  private _token: string;
  deviceData: string;
  hostedFieldsInstance: braintree.HostedFields;
  threeDSecureInstance: any;
  clientInstance: any;
  blurBackground = false;
  cardType = '';

  public get token() {
    return this._token;
  }

  private readonly brainTreeStyles = {
    input: {
      color: 'white',
      transition: 'color 160ms linear',
      '-webkit-transition': 'color 160ms linear',
      'line-height': '3',
      font: 'normal normal 300 18px/24px Arial',
      'letter-spacing': '0.2px',
    },
    '.expirationMonth': {
      'text-align': 'center',
    },
    '.expirationYear': {
      'text-align': 'center',
    },
    '.number': {
      'padding-left': '5px',
      'font-size': '20px',
    },
    '.cardholderName': {
      'padding-left': '5px',
    },
    '.cvv': {
      'text-align': 'center',
    },
    ':focus': {},
    '::-webkit-input-placeholder': {
      color: 'white',
    },
    ':-moz-placeholder': {
      color: 'white',
    },
    '::-moz-placeholder': {
      color: 'white',
    },
    ':-ms-input-placeholder': {
      color: 'pwhiteink',
    },
    'input.invalid': {},
  };

  private readonly BraintreeFields = {
    cardholderName: {
      selector: '#cardholderName',
      placeholder: 'Name',
    },
    number: {
      selector: '#cardnumber',
      placeholder: $localize`Credit card number`,
      supportedCardBrands: {
        visa: true,
        'american-express': true,
        'master-card': true,
      },
    },
    cvv: {
      selector: '#cvv',
      placeholder: $localize`CVV`,
    },
    expirationMonth: {
      selector: '#expiration-month',
      placeholder: $localize`MM`,
    },
    expirationYear: {
      selector: '#expiration-year',
      placeholder: $localize`YY`,
    },
  };

  constructor(
    // private loading: LoadingService,
    private paymentService: PaymentsService,
    private loading: LoadingService
  ) { }

  icon: ICon = {
    alt: 'credit card',
    key: 'CREDIT_CARD',
    url: '',
    class: 'fa fa-credit-card',
  };
  type: IPaymentMethods = 'CREDIT_CARD';
  title = 'Credit Card';
  static get tab(): ICon {
    return {
      alt: 'credit card',
      key: 'CREDIT_CARD',
      url: '',
      class: 'fa fa-credit-card',
      title: 'Pay online with Credit Card',
      description: 'Secure payments powered by Braintree',
    };
  }
  private _visible: boolean;
  public get visible(): boolean {
    return this._visible;
  }
  public set visible(v: boolean) {
    this._visible = v;
    if (v) {
      this.initPaymentCard();
    }
  }

  protected setToken(token: string) {
    this._token = token;
  }

  initPaymentCard(type?: IPaymentMethods): void | Observable<void> {
    this.prepareData.pipe(first()).subscribe({ next: () => this.initPayment() });
  }
  ngOnChanges(changes: SimpleChanges): void {
    const applicationId = changes?.applicationId?.currentValue;
    const applicationPaymentId = changes?.applicationPaymentId?.currentValue;
    if (applicationId && applicationPaymentId) {
      this.prepareData.next({ applicationId, applicationPaymentId });
    }
  }

  ngOnInit(): void {
    if (!this.visible) {
      return;
    }
    this.initPaymentCard();
  }

  public initPayment() {
    if (!this.paymentToken) {
      this.loading.increaseLoadingCounter();
      this.createBrainTreeClientToken(
        this.applicationId,
        this.applicationPaymentId
      ).subscribe((data) => {
        this.loading.decreaseLoadingCounter();
        this.createBrainTreeUI(data.token);
      });
    } else {
      this.createBrainTreeUI(this.paymentToken);
    }
    this.cardType = '';
  }

  createBrainTreeClientToken(
    applicationId,
    applicationPaymentId
  ): Observable<any> {
    if (!applicationId) {
      return throwError(new Error('Application is empty'));
    }
    if (!applicationPaymentId) {
      return throwError(new Error('Payment Id is empty'));
    }
    const token$ = this.paymentService
      .createToken(applicationId, applicationPaymentId)
      .pipe(take(2));
    token$.subscribe((data) => {
      this._token = data.token;
    });
    return token$;
  }
  async createBrainTreeUI(token: string) {
    try {
      this.loading.increaseLoadingCounter();
      const clientInstance = await this.getClientInstance(token);
      braintree.threeDSecure
        .create({
          version: 2, // Will use 3DS2 whenever possible
          client: clientInstance,
        })
        .then((threeDSecureInstance) => {
          this.threeDSecureInstance = threeDSecureInstance;
        });
      const dataCollectorInstance = await this.getDataCollectorInstance(
        clientInstance
      );
      this.deviceData = dataCollectorInstance?.deviceData;
      this.hostedFieldsInstance = await this.createBraintree(clientInstance);
    } catch (error) {
      this.loading.decreaseLoadingCounter();
      return;
    }

    this.hostedFieldsInstance.on('focus', (event) => {
      // const field = event.fields[event.emittedBy];
      // const label = this.findLabel(field);
    });

    this.hostedFieldsInstance.on('blur', (event) => {
      // const field = event.fields[event.emittedBy];
      // const label = this.findLabel(field);
    });

    this.hostedFieldsInstance.on('empty', (event) => {
      // const field = event.fields[event.emittedBy];
    });

    this.hostedFieldsInstance.on('cardTypeChange', (event) => {
      if (event?.cards?.length === 1) {
        this.cardType = event?.cards[0]?.type;
      } else {
        this.cardType = '';
      }
      this.onCardTypeChange.emit(event);
    });
    this.hostedFieldsInstance.on('validityChange', (event) => {
      this.cardErrors = [];
      const formErrors = Object.keys(event.fields).filter((key) => {
        return !event.fields[key].isValid && !event.fields[key].isEmpty;
      });
      formErrors.forEach((x) => {
        switch (x) {
          case 'number':
            this.cardErrors.push(
              $localize`Your credit card number is invalid. Please update and try again.`
            );
            break;
          case 'expirationMonth':
            this.cardErrors.push(
              $localize`Your month of expiration is invalid. Please update and try again.`
            );
            break;
          case 'expirationYear':
            this.cardErrors.push(
              $localize`Your year of expiration is invalid. Please update and try again.`
            );
            break;
          case 'cardholderName':
            this.cardErrors.push(
              $localize`Your name is invalid. Please update and try again.`
            );
            break;
        }
      });

      const cardNumberValid = Object.keys(event.fields).filter((key) => {
        return event.fields[key].isValid && key === 'number';
      });

      if (cardNumberValid.length > 0) {
        this.cardNumberValid = true;
      } else {
        this.cardNumberValid = false;
      }

      const formValid = Object.keys(event.fields).every((key) => {
        return event.fields[key].isValid;
      });
      this.onValidityChange.emit({
        isValid: formValid,
        errors: formErrors,
        cardErrors: this.cardErrors,
      });
    });
  }

  private createBraintree(
    clientInstance: any
  ): Promise<braintree.HostedFields> {
    const bt = braintree.hostedFields.create({
      client: clientInstance,
      styles: this.brainTreeStyles,
      fields: this.BraintreeFields,
    });
    bt.then((data) => {
      this.hostedFieldsInstance = data;
      this.onCreatedBraintree.emit();
    })
      .catch(() => {
        this.onCreateError.emit();
      })
      .finally(() => {
        this.loading.resetLoadingCounter();
      });

    return bt;
  }

  private getDataCollectorInstance(clientInstance: any) {
    return braintree.dataCollector.create({
      client: clientInstance,
    });
  }

  private getClientInstance(token: string) {
    return braintree.client.create({
      authorization: token,
    });
  }

  clearHostedFields() {
    this.hostedFieldsInstance.clear('number');
    this.hostedFieldsInstance.clear('cvv');
    this.hostedFieldsInstance.clear('expirationMonth');
    this.hostedFieldsInstance.clear('expirationYear');
    this.hostedFieldsInstance.clear('cardholderName');
  }

}
