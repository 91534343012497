import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoadingService } from '../../shared/services/loading-service.service';
import { isString } from 'lodash';
import { SplunkService } from 'shared/services/splunk.service';
import { UUID_TOKEN } from 'shared/utils/string-utils';
interface HttpErrorResponses extends HttpErrorResponse {
  errors: any[];
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private sessionKey: string;
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private splunkService: SplunkService,
    @Inject(UUID_TOKEN) private uuid: string) {
      this.sessionKey = this.uuid;
     }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* how to disable interceptor temporarily
    this.userProfileService.defaultHeaders = this.userProfileService.defaultHeaders.set('suppressError', 'on');
    ... execute API call
    this.userProfileService.defaultHeaders = this.userProfileService.defaultHeaders.delete('suppressError');*/
    if (request.headers.has('suppressError')) {
      const clonedReq = request.clone({
        headers: request.headers.delete('suppressError')
      });
      return next.handle(clonedReq);
    } else {
      return next.handle(request)

        .pipe(

          catchError((errorResponses: HttpErrorResponses) => {
            let errorMessage = '';
            try {
              if (errorResponses.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `client side error: ${errorResponses.error.message}`;
              }
              else if (errorResponses?.error?.errors?.length) {
                // server-side error
                const tempError: any[] = errorResponses.error.errors;
                let message = '';
                if (isString(tempError[0])) {
                  message = tempError[0];
                } else if (tempError[0]?.message) {
                  message = tempError[0]?.message;
                }
                errorMessage = `Error Code: ${errorResponses.status} Message: ${message || ''}`;
                if (message.toLowerCase().includes('payment failed')) {
                  this.toastr.error(message || 'Something went wrong', 'Payment failed');
                  return throwError(`http-error:${errorResponses.error}`);
                } else if (errorResponses.status === 406 && message.toLowerCase().includes('seat not found with product id')) {
                  this.toastr.error('Available seat no longer found, please try a different selection.');
                }
                else if (!message.includes('Unusual network traffic detected') && !message.includes('Unable to create more than one EOR')
                  && !message.toLowerCase().includes('active booking available with same test date') && !request.url.includes('/isBanned')) {
                  this.toastr.error(message || '', 'Something went wrong');
                }
              }
              else {
                // default server side error
                errorMessage = 'Oops .. something went wrong. Please try again later';
                if (errorResponses.status === 401) {
                  errorMessage = 'Unauthorized Access';
                }
                if (!this.checkRoute(errorResponses)) {
                  this.toastr.error('Oops .. something went wrong. Please try again later');
                }
              }
            } catch (error) {
              errorMessage = 'Oops .. something went wrong. Please try again later';
              this.toastr.error(errorMessage);
              console.log('failure in handling error');
            }
            this.loadingService.resetLoadingCounter();
            this.splunkLogger(errorResponses, this.sessionKey);

            return throwError(`http-error:${errorMessage}`);
          })
        );
    }
  }
  checkRoute(error): boolean {
    const ignoreList: {url: string, errorCodes: number[], match?}[] = [
      {url: '/account', errorCodes: [404]},
      {url: '/create-booking', errorCodes: [404]}
    ];
    return ignoreList.map(item => {
      return {
        ...item,
        match: this.router.url.includes(item.url) && item.errorCodes.includes(error.status)
      };
    }).some(item => item.match);
  }

  private splunkLogger(error: HttpErrorResponses, sessionKey: string): void {
    const payload = this.splunkService.generatePayload({
      session_key: sessionKey,
      source_site: 'test_taker_api',
      event_type: 'API Error',
      error_error: error?.error,
      error_name: error?.status,
      error_message: error?.message,
      error_stack: new Error().stack,
    });

    this.splunkService.logger(payload, error);
  }
}
