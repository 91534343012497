import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy, NgZone } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from '@aws-amplify/auth';
import { slideInAnimation } from './route-animations';
import { Title, Meta } from '@angular/platform-browser';
const { featureFlags } = require('src/assets/appSettings.json');
@Component({
  selector: 'app-account-manage',
  templateUrl: './account-manage.component.html',
  styleUrls: ['./account-manage.component.scss'],
  animations: [slideInAnimation]
})
export class AccountManageComponent implements OnInit, AfterViewChecked {
  signupMail: UntypedFormControl;
  activePage: 'account' | 'signup' | 'signin' = 'account';
  currentOutlet: 'account' | 'signup' | 'signin' = 'account';
  public featureFlags: any = featureFlags;
  leftSidePanel = {
    account: {
      title: $localize`Create your account`,
      subTitle: $localize`Create an account using email.`,
      action: {
        title: $localize`Already have an IDP IELTS account?`,
        link: '/account/signin',
        linkTitle: $localize`Log in`
      }
    },
    signin: {
      title: $localize`Log in to IDP IELTS`,
      subTitle: $localize`Got an IDP IELTS account? Log in using email.`,
      action: {
        title: $localize`Don’t have an account?`,
        link: '/account/signup',
        linkTitle: $localize`Create an account`
      }
    },
    signup: {
      title: $localize`Create your account`,
      subTitle:
      {
        title1: $localize`Okay, now we need some basic details.`,
        title2: $localize`We use these for your tests. Please make sure all the details are correct.`
      },
      action: {
      }
    },
    // action: {
    //   title: $localize`Already have an IDP IELTS account?`,
    //   link: '/account/signin',
    //   linkTitle: $localize`Log In`
    // }
    'social-signup': {
      title: $localize`Create your account`,
      subTitle: $localize`Create an account using email.`,
      action: {
        title: $localize`Already have an IDP IELTS account?`,
        link: '/account/signin',
        linkTitle: $localize`Log in`
      }
    },
    signout: {
      title: ``,
      subTitle: ``,
      action: {
      }
    },
    privacy: {
      title: $localize`Privacy`,
      subTitle: ``,
      action: {
      }
    },
    myAccount: {
      title: $localize`User Dashboard`,
      subTitle: ``,
      action: {
      }
    },
    resetPassword: {
      title: $localize`Reset your password`,
      action: {
      }
    }, step1: {
      title: $localize`Reset your password`,
      action: {
      }
    }, step2: {
      title: $localize`Reset your password`,
      action: {
      }
    }, stepFinal: {
      title: $localize`Reset your password`,
      action: {
      }
    },
  };
  getState(outlet: RouterOutlet) {
    const temp = outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
    this.currentOutlet = temp;
    return temp;
  }
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private titleService: Title, private metaTagService: Meta) {
    this.titleService.setTitle('Sign in or Sign up | IDP IELTS');
    this.signupMail = new UntypedFormControl('',
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    );
    if (featureFlags.socialLoginEnabled) {
      this.leftSidePanel.account.subTitle = $localize`Create an account using your social media or email`;
      this.leftSidePanel.signin.subTitle = $localize`Got an IDP IELTS account? Log in using your social media or email.`;
    }
  }
  onEnterEmail(event) {
    if (event.key === 'Enter' && this.signupMail.valid) {
      this.redirectToSignUp();
    }
  }
  redirectToSignUp() {
    this.zone.run(() => {
      this.router.navigate(['/signup'], {
        queryParams: { email: this.signupMail.value }
      });
    })
  }
  facebookLogin() {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook }).then((credentials) => {
      console.log(credentials);
    }).catch((error) => {
      console.error('error signing in: ', error);
    });

  }

  googleLogin() {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google
    })
      .then((credentials) => {
        console.log(credentials);
      })
      .catch((error) => {
        console.error('error signing in: ', error);
      });

  }
  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Welcome to IELTS Online! Sign in to your account. Don\'t have an account yet? Go ahead and sign up for a new IDP account!' },
    );
    console.log();
  }

  ngAfterViewChecked() {
    this.activePage = this.currentOutlet;
    this.cdr.detectChanges();
    if (this.activePage === 'signin') {
      try {
        localStorage.setItem('emailID', '');
      } catch (error) {
        console.error('error signing in: ', error);
      }
    }
  }

}
