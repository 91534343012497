import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EducationLevel, Gender, Language, OccupationLevel, OccupationSector, TestReason } from '@idp-education/ors-test-taker-bff-client-v1';

@Component({
  selector: 'app-ielts-info-form',
  templateUrl: './ielts-info-form.component.html',
  styleUrls: ['./ielts-info-form.component.scss']
})
export class IELTSInfoFormComponent {
  @Input() form: UntypedFormGroup;
  @Input() countryList = [];
  @Input() genderList: Gender[] = [];
  @Input() occupationSectorList: OccupationSector[] = [];
  @Input() occupationLevelList: OccupationLevel[] = [];
  @Input() educationLevelList: EducationLevel[] = [];
  @Input() languages: Language[] = [];
  @Input() testReasonList: TestReason[] = [];
  @Input() yearsStudyingEnglishList: any[] = [];
  @Input() showTitle = false;
  @Input() disableFirstLanguage = false;

  @Output() selectChange: EventEmitter<[any, string]> = new EventEmitter();
  getNewOptionId(list: any[]): string {
    const option = list.find(i => (i?.name as string)?.toLowerCase() === 'other');
    return option?.id;
  }

}
