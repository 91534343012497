import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';
const { featureFlags } = require('src/assets/appSettings.json');
const sha1 = require('sha1');
@Component({
  selector: 'app-create-booking-account',
  templateUrl: './create-booking-account.component.html',
  styleUrls: ['./create-booking-account.component.scss']
})
export class CreateBookingAccountComponent implements AfterViewChecked, OnDestroy {
  signupMail: UntypedFormControl;
  fromLoginPage: boolean;
  activePage: 'account' | 'signup' | 'signin' = 'account';
  currentOutlet: 'account' | 'signup' | 'signin' = 'account';
  leftSidePanel = {
    account: {
      title: $localize`Create your profile`,
      subTitle: $localize`We noticed you already have an IDP account, however, we need additional info to create a profile for bookings.`,
      action: {
        title: $localize`Already have an IDP IELTS account?`,
        link: '/account/signin',
        linkTitle: $localize`Log out`
      }
    }
  };
  hrefValues = {
    cxUrl: generateUtmUrl(UtmLinkKeys.CxUrl)
  }
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private titleService: Title, private metaTagService: Meta) {
    this.titleService.setTitle('Sign in or Sign up | IDP IELTS');
    this.signupMail = new UntypedFormControl('',
      Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    );
  }

  signOut() {
    this.authService.signOut();
  }

  ngAfterViewChecked() {
    this.activePage = this.currentOutlet;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    localStorage.removeItem('fromLoginPage');
  }
}
