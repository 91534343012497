<div class="d-flex flex-column flex-grow-1 align-items-stretch">
  <div class="d-flex w-100 justify-content-start">
    <h5 class=" text-darker float-start m-0 mb-2" [class.h2]="upcomingTestHeader" i18n>Test Details</h5>
  </div>
  <div class="d-flex justify-content-start flex-column flex-lg-row text-gray content-box-description row">
    <div class="d-flex col-lg-6 col-12 flex-column">
      <div class="content-test label-font">
        <div class="weight-semi label test-type">Test type</div>
        <div class="">
          <ng-content select="[testType]"></ng-content>
        </div>
      </div>
      <div class="content-test label-font">
        <div class="weight-semi label test-type">Test format</div>
        <div class="">
          <ng-content select="[testFormat]"></ng-content>
        </div>
      </div>
    </div>
    <div class="d-flex col-lg-6 col-12 flex-column">
      <div class="content-test label-font">
        <div class="weight-semi label test-type">Test venue</div>
        <ng-content select="[testLocationName]"></ng-content>
      </div>
      <div class="content-test label-font">
        <div class="weight-semi label test-type">Address</div>
        <div class="">
          <ng-content select="[address]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>