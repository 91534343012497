import { DateTime } from 'luxon';

export const isUnder18AtTarget = (birthDate: string, targetDate: DateTime): boolean => {
  const userBirthDate = DateTime.fromISO(birthDate);

  const ageDifference = targetDate.diff(userBirthDate, ['years', 'months']);

  return ageDifference.toObject().years < 18;
};

export const isUnderAge = (
  age: number,
  dateOfBirth: DateTime | string,
  targetDate: DateTime | string,
  format = 'yyyy-MM-dd'
): boolean => {
  const startDate = typeof dateOfBirth === 'string' ? DateTime.fromFormat(dateOfBirth, format) : dateOfBirth;
  const endDate = typeof targetDate === 'string' ? DateTime.fromFormat(targetDate, format) : targetDate;

  if (!startDate.isValid) { throw new Error(startDate.invalidExplanation); }
  if (!endDate.isValid) { throw new Error(startDate.invalidExplanation); }

  let ageDifference = endDate.year - startDate.year;
  if (
    (endDate.month < startDate.month) ||
    (endDate.month === startDate.month && endDate.day < startDate.day)
  ) {
    ageDifference--;
  }

  return ageDifference < age;
};
