<div class="flexbox flex-column content align-items-center align-items-xxl-start">
  <form class="form-horizontal ms-4 me-4 me-lg-0" [formGroup]="$form" (ngSubmit)="onSubmit()">
    <app-information-box type="secondary" [classes]="['d-flex','box-width']" icon="fa-info-circle text-danger">
      <span class="sub-copy">
        If you are booking for someone else, please ensure that you book using their own account
        with unique email address.
      </span>
    </app-information-box>

    <div class="signup-two-columns mt-4">
      <app-dropdown id="title" labelKey="name" idKey="value" i18n-placeholder placeholder="Title" [options]="userTitle"
        class="tab" [formControl]="$form.get('title')" [showTick]="true" [isRequired]="true">
      </app-dropdown>

      <app-dropdown id="gender" [formControl]="$form.get('gender')" labelKey="name" idKey="id" i18n-placeholder
        placeholder="Gender" [options]="genderList" [showTick]="true" [isRequired]="true">
      </app-dropdown>

      <app-custom-input [fcn]="'firstName'" [form]="$form" id='firstname' i18n-placeholder placeHolder="First name"
        [AcceptSpace]="true" [validShowTick]="true" [showError]="true" [required]="true">
      </app-custom-input>

      <app-custom-input [fcn]="'lastName'" [form]="$form" id='lastName' i18n-placeholder placeHolder="Family name"
        [showError]="true">
      </app-custom-input>

      <app-custom-input [fcn]="'email'" [form]="$form" id='email' i18n-placeholder placeHolder="Email address"
        [required]="true" type="email" [validShowTick]="true" (change)="resetError()"
        [customMessage]="customMessages.email">
      </app-custom-input>

      <app-custom-input [fcn]="'confirmEmail'" [form]="$form" id='confirmEmail' i18n-placeholder
        placeHolder="Confirm your email address" [required]="true" type="email" [validShowTick]="true"
        (change)="resetError()" [customMessage]="customMessages.email" [preventPaste]="'true'">
      </app-custom-input>

      <app-custom-input [fcn]="'birthDate'" [form]="$form" id='birthDate' i18n-placeholder
        placeHolder="Date of birth  DD/MM/YYYY" [required]="true" type="birthDate" [showTick]="true" mask="d0/M0/0000"
        [customMessage]='customMessages.birthDate' [validShowTick]="true" [showError]="true">
      </app-custom-input>

      <app-custom-input [fcn]="'identityNo'" [form]="$form" placeHolder="ID number" i18n-placeholder [required]="true"
        [showTick]="true" [validShowTick]="true" maxLength="50">
      </app-custom-input>

      <app-custom-input [fcn]="'expiryDate'" [form]="$form" id='expiryDate' [validShowTick]="true" i18n-placeholder
        placeHolder="ID expiry date  DD/MM/YYYY" [showTick]="true" type="birthDate" [showError]="true" mask="d0/M0/0000"
        [customMessage]='customMessages.expiryDate'>
      </app-custom-input>

      <app-dropdown *ngIf="nationalityList" labelKey="name" idKey='id' i18n-placeholder id="nationality"
        placeholder="Country of nationality" [options]="nationalityList"
        [formControl]="$form.get('countryOfNationality')" [showTick]="true" [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="input-width-100">
      <app-dropdown labelKey="name" idKey='id' i18n-placeholder placeholder="Country of residence" *ngIf="countryList"
        [options]="countryList" [showTick]="true" [formControl]="$form.get('country')" [isRequired]="true">
      </app-dropdown>
      <app-custom-input [fcn]="'mobileNumber'" [form]="$form" id='mobile' i18n-placeholder [required]="true"
        placeholder="Mobile number" [type]="getAutomationTestToggle() ? null : 'phone'" i18n-tooltip
        tooltip="Your mobile number" [errorCMessage]="customMessages.mobileNumber">
      </app-custom-input>
      <app-custom-input [fcn]="'pass'" [form]="$form" id='password' [showRequiredError]="false" i18n-placeholder
        placeHolder="Choose your password" [required]="true" [type]="'password'" [showPasswordIcon]="true">
      </app-custom-input>
      <div *ngIf="$form.controls.pass.touched
              || $form.controls.confirmPass.touched
              || $form.controls.pass.dirty
              || $form.controls.confirmPass.dirty">
        <app-password-validation-status [password]="$form.controls.pass.value">
        </app-password-validation-status>
      </div>
      <app-custom-input [fcn]="'confirmPass'" [form]="$form" id='comfirmPass' i18n-placeholder
        placeHolder="Confirm your password" [required]="true" [type]="'password'" [showPasswordIcon]="true">
      </app-custom-input>
    </div>

    <div class="d-flex flex-column justify-content-between conditions multiLine">
      <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'termAndCondition1'">
        <span i18n style="line-height: normal;">I agree to the&nbsp;</span>
        <a rel="noopener" [href]="hrefValues?.legalNoticeURL" target="_blank"
          class="termsAndCondition" i18n>IDP Privacy and Data Policy</a>
      </app-circle-checkbox>
      <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'termAndCondition2'" i18n-title
        title="I’d like to receive news, offers and promotions from IDP IELTS">
      </app-circle-checkbox>
      <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'communicationsCheckbox'"
        i18n-title title="I'd like to receive emails and notifications for IDP study abroad and support services">
      </app-circle-checkbox>
      <app-signup-field [classes]="['mt-3']">
        <div status>
        </div>
        <div content
          class="flexbox align-left justify-content-center align-items-center align-items-lg-start flex-column w-100">
          <div class="invalid-feedback" *ngIf="hasError">
            <ul class="list-unstyled">
              <li class="error-item ng-star-inserted">
                <i class="fa fa-warning"></i>
                {{ invalidMessageSignUp }}
              </li>
            </ul>
          </div>
          <button [disabled]="!($form.valid && ($form.controls.termAndCondition1.value)) || hasError || showLoading"
            class="btn btn-multiAction mt-3" type="submit" i18n>
            Create an account
          </button>
        </div>
      </app-signup-field>
    </div>
  </form>
</div>
