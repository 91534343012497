import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedStateService } from '../shared-state.service';
import { Router } from '@angular/router';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { IState } from '../store/onboarding.reducer';
import { Store } from '@ngrx/store';
import {
  setCurrentStep,
  setCurrentStepName,
  setIsAchievedLastStep,
  setOnboardingApplication,
  setProgressValue,
  setSelectedIdType,
  setTestLocationId,
} from '../store/onboarding.actions';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Storage } from 'aws-amplify';
import { Country, Nationality, Territory } from '@idp-education/ors-test-taker-bff-client-v1';
import { IModel } from '../../../shared/models/components/dropdown';
import { Observable, Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Title, Meta } from '@angular/platform-browser';
import { ID_CONSTANTS } from '../onboarding.contants';
import { DateTime } from 'luxon';
type IOptions = {
  personal: {
    label: string;
    updateLink?: string;
    attributes: Array<any>;
  },
  identityDetail: {
    label: string;
    updateLink: string;
    attributes: Array<any>;
  },
  Address: {
    label: string;
    updateLink: string;
    attributes: Array<any>;
  },
  profile: {
    label: string;
    updateLink: string;
    attributes: Array<any>;
  },
};
@Component({
  selector: 'app-review-profile',
  templateUrl: './review-profile.component.html',
  styleUrls: ['./review-profile.component.scss'],
})
export class ReviewProfileComponent implements OnInit, OnDestroy {

  options: IOptions;
  state: IState;
  activeTab = 0;
  subscription: Subscription;
  private readonly pdfIcon = 'src/assets/images/pdfIcon.svg';
  imageUrl = '';

  constructor(
    private sharedState: SharedStateService,
    private router: Router,
    private store: Store<{ onboardingStore }>,
    private api: ApiService,
    private loading: LoadingService,
    private titleService: Title, private metaTagService: Meta
  ) {
    this.titleService.setTitle('Review your profile | IDP IELTS');
    this.activeTab = 0;
    this.subscription = this.store
      .select((appState) => appState.onboardingStore)
      .subscribe((e) => {
        this.state = e;
        this.imageUrl = this.state.imageUrl as string;
      });
    this.store.dispatch(setCurrentStep({ currentStep: 6 }));
    this.store.dispatch(setProgressValue({ progressValue: 100 }));
    this.store.dispatch(
      setCurrentStepName({ currentStepName: 'Profile details' })
    );
    this.store.dispatch(setIsAchievedLastStep({ isAchievedLastStep: true }));
    this.getOptions().then(data => {
      this.options = data;
      const provinceId = this?.state?.addressDetail?.province;
      this.subscription.add(api.GetCountries.subscribe(countries => {
        const CountryCode = api.convertCountryIdToCountryCode(this?.state?.addressDetail?.country, countries);
        this.subscription.add(this.changeTerritoryCodeToName(provinceId, CountryCode).subscribe(value => {
          this.options.Address.attributes.find(i => i.label === 'State, region or province').data = this.nullCheck(value);
        }));
      }));
    });

  }

  async getOptions(): Promise<IOptions> {

    try {
      const result: IOptions = {
        personal: {
          label: $localize`Personal details`,
          attributes: [
            {
              label: $localize`First name`,
              data: this.nullCheck(
                this.state.userProfile && this.state.userProfile.firstName
              ),
              col: 3,
            },
            {
              label: $localize`Last name`,
              data: this.nullCheck(
                this.state.userProfile && this.state.userProfile.lastName
              ),
              col: 3,
            },
            {
              label: $localize`Mobile number`,
              data: this.nullCheck(
                this.state.userProfile &&
                this.formatPhoneNumber(this.state.userProfile.mobileNumber)
              ),
            },
            {
              label: $localize`Email address`,
              data: this.nullCheck(
                this.state.userProfile && this.state.userProfile.emailAddress
              ),
            },
            {
              label: $localize`Date of birth`,
              data: this.nullCheck(
                this.state.userProfile &&
                DateTime.fromFormat(
                  this.state.userProfile.dateOfBirth || '',
                  'yyyy-MM-dd'
                ).isValid &&
                DateTime.fromFormat(
                  this.state.userProfile.dateOfBirth || '',
                  'yyyy-MM-dd'
                ).toFormat('dd MMMM yyyy')
              ),
            },
          ],
        },
        Address: {
          label: $localize`Address`,
          updateLink: '../address-detail',
          attributes: [
            {
              label: $localize`Address1`,
              data: this.nullCheck(
                this.state.addressDetail && this.state.addressDetail.address1
              ),
            },
            {
              label: $localize`Address 2`,
              data: this.nullCheck(
                this.state.addressDetail && this.state.addressDetail.address2
              ),
            },
            {
              label: $localize`City`,
              data: this.nullCheck(
                this.state.addressDetail && this.state.addressDetail.city
              ),
            },
            {
              label: $localize`Country`,
              data: this.nullCheck(
                this.changeCountryCodeToName(this?.state?.addressDetail?.country)
              ),
            },
            {
              label: $localize`Postcode`,
              data: this.nullCheck(
                this.state.addressDetail && this.state.addressDetail.postcode
              ),
            },
            {
              label: $localize`State, region or province`,
              data: '-',
            },
          ],
        },
        profile: {
          label: $localize`IELTS Information`,
          updateLink: '../profile',
          attributes: [
            {
              label: $localize`Gender`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.gender?.label
              ),
            },
            {
              label: $localize`Title`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.title.label
              ),
            },
            {
              label: $localize`Where are you currently studying English (if applicable)?`,
              data: this.nullCheck(
                this.state.profile &&
                this.state.profile.locationStudyEnglish
              ),
            },
            {
              label: $localize`Occupation (sector)`,
              data: this.nullCheck(
                this.state.profile &&
                this.state.profile.occupationSector?.label
              ),
            },
            {
              label: $localize`First language`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.firstLanguage?.label
              ),
            },
            {
              label: $localize`Occupation (level)`,
              data: this.nullCheck(
                this.state.profile &&
                this.state.profile.occupationLevel?.label
              ),
            },
            {
              label: $localize`What level of education have you completed?`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.educationLevel?.label
              ),
            },
            {
              label: $localize`Which country do you intend to go to?`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.intendLocation?.label
              ),
            },
            {
              label: $localize`How many years have you been studying English?`,
              data: this.nullCheck(
                this.state.profile &&
                this.state.profile.yearsStudyingEnglish.label
              ),
            },
            {
              label: $localize`Reason for taking the Test`,
              data: this.nullCheck(
                this.state.profile && this.state.profile.reasonTest?.label
              ),
            },
          ],
        },
        identityDetail: {
          label: $localize`Identity details`,
          updateLink: '../identity-detail',
          attributes: [
            {
              label: $localize`Country of Nationality`,
              data: this.nullCheck(
                this.changeNationalityCodeToName(this.state.detail.countryOfNationality.Id)
              ),
              col: 6,
            },
            {
              label: $localize`Date of expiry`,
              data: this.formatDateOfExpire(this.state),
              col: 6,
            },
            {
              label: $localize`${(ID_CONSTANTS[this.state.selectedIdType.code]).idNumber}`,
              data: this.nullCheck(
                this.state.detail && this.state.detail.identityNo
              ),
              col: 6,
            },
            {
              label: $localize`ID Issuing Authority`,
              data: this.nullCheck(
                this.state.detail && this.state.detail.issuingAuthority
              ),
              col: 6,
            },
            {
              label: '',
              data: this.isPDF(this.imageUrl) ? this.pdfIcon : await this.getPhoto(this.modifiedS3Url(this.imageUrl)),
              type: 'image',
              editLink: '../identity-document',
            },
          ],
        },
      };
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  formatDateOfExpire(state: IState) {
    try {
      const expiryDate = this.state.detail.expiryDate;
      const date = DateTime.fromFormat(expiryDate || '', 'dd/MM/yyyy');
      if (date.isValid) {
        return date.toFormat('dd MMMM yyyy');
      }
      return '-';
    } catch (error) {
      return '-';
    }
  }
  isPDF(url: string | boolean): boolean {
    if (typeof url === 'string') {
      return url.split(/[#?]/)[0].split('.').pop().trim().toLocaleLowerCase() === 'pdf';
    }
    return false;
  }
  changeNationalityCodeToName(code: string): string {
    let result: Nationality;
    if (!code) {
      return null;
    }
    this.subscription.add(this.api.GetNationality.subscribe(nationalities => {
      result = nationalities?.find(n => n.id === code);
    }));
    return result && result.name;
  }
  changeCountryCodeToName(country: IModel<Country>): string {
    let result: Country;
    if (!country) {
      return null;
    }
    this.subscription.add(this.api.GetCountries.subscribe(countries => {
      result = countries?.find(n => n.id === country.Id);
    }));
    return result && result.name;
  }
  changeTerritoryCodeToName(code: IModel<Territory>, countryCode: string): Observable<string> {
    if (!code) {
      return of(null);
    }
    return new Observable(sub => {
      this.subscription.add(this.api.GetTerritory(countryCode).subscribe(territory => {
        const temp: Territory = (territory || [])?.find(n => n.id === code.Id);
        sub.next(temp?.name);
      }));
    });
  }
  modifiedS3Url(imageUrl: string) {
    if (imageUrl?.length > 0) {
      const indx = imageUrl.lastIndexOf('/');
      const fileName = imageUrl.substring(indx + 1);
      return fileName;
    }
    return null;
  }
  async getPhoto(key: string): Promise<string> {
    const photo = await Storage.get(key) as string;
    return photo;
  }
  formatPhoneNumber(pn) {
    try {
      const phoneNumber = PhoneNumberUtil.getInstance().parseAndKeepRawInput(
        pn,
        'AUS'
      );
      return PhoneNumberUtil.getInstance().format(
        phoneNumber,
        PhoneNumberFormat.INTERNATIONAL
      );
    } catch (error) { /* empty */ }
  }
  toArray(options: any): any[] {
    if (options) {
      return Object.keys(options);
    }
    return [];
  }
  togglePanel(index: number, accordion) {
    this.activeTab = index;
    accordion.toggle('panel-' + index);
  }
  nullCheck(value) {
    if (!value) {
      return '-';
    }
    return value;
  }
  async ngOnInit() {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    this.imageUrl = await this.getPhoto(`${this.state.imageUrl}`);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  saveOnboarding() {
    this.loading.resetLoadingCounter();
    this.loading.increaseLoadingCounter();
    this.sharedState.saveAndExit('profile', {
      ...this.state,
      addressDetail: {
        ...this.state.addressDetail,
      }
    }).pipe(delay(3000)).subscribe(async (result) => {
      await this.router.navigate(['/my-account'], {
        state: {
          test: 'test',
        },
      });
      this.loading.decreaseLoadingCounter();
    },
      (err) => {
        this.loading.decreaseLoadingCounter();
        alert($localize`Sorry, Saving failed, please try again`);
      }
    );
  }

  async goTo(page: string) {
    this.store.dispatch(setTestLocationId({ testLocationId: null }));
    this.store.dispatch(setSelectedIdType({ selectedIdType: null }));
    this.store.dispatch(setOnboardingApplication({ application: null }));
    await this.router.navigate([page], {
      state: {
        test: 'test',
      },
    });
  }
}
