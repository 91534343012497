import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './../route-animations';
@Component({
  selector: 'app-forgot-password',
  template: `<div content [@routeAnimations]='getState(outlet)' class="" style="display: flex;">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>`,
  styleUrls: ['./forget-password.component.scss'],
  animations: [slideInAnimation],
})
export class ForgotPasswordComponent {

  getState(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
  }

}
