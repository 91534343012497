<div *ngIf="!form" appApplyTabIndexes>
  <div class="d-flex align-items-center main-container" [ngClass]="{'disable': disable}" *ngIf="title">
    <label class="container-c">
      <input type="checkbox" tabindex [formControl]="control" (change)="changeAction($event)" [attr.disabled]="disable ? 'true' : null" class="form-control" >
      <span class="checkmark"></span>
    </label>
    <span>{{ title }}</span>
  </div>
  <label class="container-c" *ngIf="!title">
    <input type="checkbox" tabindex [formControl]="control" (change)="changeAction($event)" [attr.disabled]="disable ? 'true' : null" class="form-control" >
    <span class="checkmark"></span>
  </label>
</div>
<div *ngIf="form">
  <div [formGroup]="form" [ngClass]="{'disable': disable}">
    <div *ngIf="title" class="d-flex align-items-center main-container">
      <label class="container-c">
        <input type="checkbox" tabindex (change)="changeAction($event)" [formControlName]="control" [attr.disabled]="disable ? 'true' : null" 
          class="form-control">
        <span class="checkmark"></span>
      </label>
      <span *ngIf="title">{{ title }}</span>
    </div>
    <div *ngIf="!title" class="d-flex align-items-center main-container">
      <label class="container-c">
        <div>
        <input type="checkbox" tabindex (change)="changeAction($event)" [formControlName]="control" [attr.disabled]="disable ? 'true' : null" 
          class="form-control">
        <span class="checkmark"></span>
      </div>
      </label>
      <ng-content></ng-content>
    </div>

  </div>
</div>