import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-v2-popup',
  templateUrl: './payment-v2-popup.component.html',
  styleUrls: ['./payment-v2-popup.component.scss']
})
export class PaymentV2PopupComponent {
  @Input() paymentErrorModal = false;

  @Input() title = '';
  @Input() description = '';
  @Input() imageUrl = '';
  @Input() acceptText = 'Yes';
  @Input() rejectClass = '';
  @Input() rejectText = 'No';
  @Input() showReject = false;
  @Input() showCloseIcon = true;
  @Output() onOpen = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onAcceptClicked = new EventEmitter<void>();
  @Output() onRejectClicked = new EventEmitter<void>();
  @ViewChild('btn1') btn1: ElementRef;
  @ViewChild('btn2') btn2: ElementRef;
  private modalElement: NgbModalRef;


  private _showAccept = true;
  public get showAccept(): boolean {
    return this._showAccept;
  }

onAcceptClick() {
  this.onAcceptClicked.emit();
  this.paymentErrorModal = false;
}



onCloseClick(){
  this.onClose.emit();
  this.paymentErrorModal = false;
}

onRejectClick() {
  this.onRejectClicked.emit();
  this.paymentErrorModal = false;
}

closeModal(result) {
  this.modalElement?.close(result);
}
}
