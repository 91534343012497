<ng-container *ngIf="!isNewJourney">
<div>
    <h2 class="content-title w-65">Veritrans payment options</h2>
</div>
<div class="card-wrapper d-flex">
  <div
    class="w-100 mb-3 position-relative"
  >
<ul
class="nav nav-tabs flex-column justify-content-center align-items-start"
>
<li *ngIf="cardTitle"
  class="nav-item d-flex align-items-start justify-contnet-center flex-column border m-2 rounded w-100 cursor-pointer"
  (click)="onCardClick('card')" (keyup)="onCardClick('card')"
>
  <a
    href="javascript:void()"
    class="nav-link d-flex"
  >
    <div class="d-flex align-items-center justify-content-center">
      <span class="fa fa-credit-card card-icon"></span>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-start pt-1"
    >
      <div class="ms-1 text-bold text-body">{{cardTitle}}</div>

    </div>
  </a>
</li>
<li *ngIf="cvsTitle"
  class="nav-item d-flex align-items-start justify-contnet-center flex-column border m-2 rounded w-100 cursor-pointer"
  (click)="onCVSClick('cvs')" (keyup)="onCVSClick('cvs')"
>
  <a
    href="javascript:void()"
    class="nav-link d-flex"
  >
    <div class="d-flex align-items-center justify-content-center">
      <span class="fa fa-shopping-cart card-icon"></span>
    </div>
    <div
      class="d-flex flex-column justify-content-center align-items-start pt-1"
    >
      <div class="ms-1 text-bold text-body">{{cvsTitle}}</div>
      <span *ngIf="cvsExpiryHours" class="ml-1 mr-2 grey-500 font-16">Note:
        Convenience Store payment must be made within {{cvsExpiryHours}} hours, otherwise the application will expire.
      </span>

    </div>
  </a>
</li>
</ul>
</div>
</div>
</ng-container>