import { Injectable } from '@angular/core';
import { mockOfflinePaymentConfigs, SubOfflinePaymentConfig } from './mock-data/offlinePaymentItems';
import { concatMap, first, map, Observable, of, switchMap, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { PaymentUtilService } from 'pages/payment/payment-util.service';
import { Application, ApplicationPayment, ApplicationService, PaymentMethod, ReceiptService, TestCentrePaymentMethod, TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1';
import { SetProductType } from 'pages/booking/store/booking.actions';
import { setFee, setFromOffline, setLrwtesttime, setSpeakingtesttime } from 'pages/payment/store/payment.actions';
import { setCurrentApplication } from 'store/applications/application.action';

@Injectable({
  providedIn: 'root'
})
export class OfflinePaymentService {
  constructor(
    private store: Store<{ applicationsStore }>,
    private router: Router,
    private paymentUtilService: PaymentUtilService,
    private applicationService: ApplicationService,
    private receiptService: ReceiptService,
    private testCentreService: TestCentreService,
  ) {
  }

  getOfflinePaymentMethod(testLocationId: string): Observable<SubOfflinePaymentConfig[] | TestCentrePaymentMethod> {
    if (!testLocationId) {
      return of(mockOfflinePaymentConfigs);
    }

    return this.testCentreService.getTestLocation(testLocationId).pipe(
      first(),
      switchMap((testLocation) =>
        this.receiptService.getTestCentrePaymentMethods(
          testLocation.testCentreId
        )
      ),
      map((paymentMethods) =>
        paymentMethods.find(
          (pm) => pm.paymentMethod.code === PaymentMethod.OFFLINE
        )
      )
    );
  }

  hasOnlinePaymentMethods(testCentreId: string, testLocationId: string): Observable<boolean> {
    return this.receiptService.getTestCentrePaymentMethods(testCentreId).pipe(
      map((paymentMethods) => {
        const onlinePaymentMethods = paymentMethods.filter(
          (value) => value.paymentMethod.code !== PaymentMethod.OFFLINE
        );

        return !onlinePaymentMethods.every((value) =>
          value.disallowedTestLocationIds.includes(testLocationId)
        );
      })
    );
  }

  verifyOfflinePayment(
    applicationId: string,
  ): Observable<{ application: Application, applicationPayment: ApplicationPayment, error?: any }> {
    let application: Application;
    let applicationPayment: ApplicationPayment;
    let error: any;
    return this.applicationService.getApplication(applicationId).pipe(
      concatMap((app: Application) => {
        application = app;
        const appPayment = app?.applicationPayments.find(
          payment => payment.paymentMethod === PaymentMethod.OFFLINE
        );
        applicationPayment = appPayment;
        return of({
          application,
          applicationPayment,
          error
        });
      })
    );
  }

  convertToJSDateWithoutTimezone(date: string) {
    const timezoneRemoved = (date && date.length > 6) ? date.substring(0, date.length - 6) : '';
    return new Date(timezoneRemoved);
  }

  navigatePaymentMethodSelection(app: Application, payment: ApplicationPayment) {
    this.store.dispatch(SetProductType({
      isNotIOLProduct: false
    }));
    const { speaking, lrw } = this.paymentUtilService.getSpeakingAndLRW(app);
    this.store.dispatch(setSpeakingtesttime({
      speakingtesttime: {
        from: this.convertToJSDateWithoutTimezone(speaking.bookingLines[0].startDateTimeLocal),
        to: this.convertToJSDateWithoutTimezone(speaking.bookingLines[0].endDateTimeLocal)
      }
    }));
    this.store.dispatch(setLrwtesttime({
      lrwtesttime: {
        from: this.convertToJSDateWithoutTimezone(lrw.bookingLines[0].startDateTimeLocal),
        to: this.convertToJSDateWithoutTimezone(lrw.bookingLines[0].endDateTimeLocal)
      }
    }));
    this.store.dispatch(setFee({ fee: payment.applicationFee as any }));
    this.store.dispatch(setCurrentApplication({ application: app }));
    this.store.dispatch(setFromOffline({ fromOffline: true }));
    timer(0).subscribe(() => {
      this.router.navigate(["/payment/card-detail"]);
    });
  }
}
