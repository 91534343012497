<div class="wrapper">
  <div class="d-flex flex-column wrapper align-items-center">
    <div class="form-steps-container w-20" *ngIf="steps && steps.length">
      <ng-container *ngFor="let step of steps; let i = index;">
        <p [ngClass]="{ 
          'step-text-complete': step.isComplete, 
          'step-text-incomplete': !step.isComplete,
          'step-text-current': (currentStep | async)?.stepIndex === step.stepIndex }">Step</p>
        <div [ngClass]="{ 
                'step-complete': step.isComplete, 
                'step-incomplete': !step.isComplete, 
                'step-current': (currentStep | async)?.stepIndex === step.stepIndex 
              }" class="step-bubble">
          <span *ngIf="!step.isComplete">{{ step.stepIndex }}</span>
          <span class="material-icons" *ngIf="step.isComplete">
            done
          </span>
        </div>
        <div *ngIf="i < (steps)?.length - 1" class="step-divider"></div>
      </ng-container>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>