import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { Auth } from 'aws-amplify';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import { ApprovalStatus, InipayPaymentService, VerifyStatus } from '@idp-education/ors-inipay-payment-bff-client-v1';
import { PaymentStates } from 'pages/payment/payment.enum';
import * as uuid from 'uuid';
import { IPaymentText } from 'shared/interfaces/payment.interface';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-inipay-callback',
  templateUrl: './inipay-callback.component.html',
  styleUrls: ['./inipay-callback.component.scss']
})
export class InipayCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  subTitle: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  sub = new Subscription();
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    public navigationService: NavigationService,
    private inipayPaymentService: InipayPaymentService
  ) { this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.sub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      if(userDetails)
      {
        this.userName = userDetails?.firstName;
      }
    });
  }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
     
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
   
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.iniPayStatus();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
    this.paymentNewModalPopup = false;
  }

 

  modalPopupDescription(status) {
    switch (status) {
      case ApprovalStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case ApprovalStatus.FAILED:
        this.title = IPaymentText.failedTitle;
        this.acceptText = IPaymentText.failedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case ApprovalStatus.TIMEOUT:
      case VerifyStatus.CANCELLED:
        this.title = IPaymentText.cancelledTitle;
        this.acceptText = IPaymentText.cancelledAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case ApprovalStatus.UNAUTHORIZED:
      case VerifyStatus.UNAUTHORIZED:
        this.title = IPaymentText.unauthorizedTitle;
        this.acceptText = IPaymentText.unauthorizedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = IPaymentText.defaultTitle;
        this.acceptText = IPaymentText.defaultAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }
  newmodalPopupDescription(status) {
    switch (status) {
      case ApprovalStatus.UNAUTHORIZED:
      case VerifyStatus.UNAUTHORIZED:
        this.title = `Payment Unauthorised`;
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case ApprovalStatus.FAILED:
        this.title  = `Payment Failed`;
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
        case ApprovalStatus.SUCCESS:
          this.navigationService.storePaymentState(PaymentStates.COMPLETED);
          this.navigationService.navigateOnPaymentType(this.paymentType);
          break;
      case ApprovalStatus.TIMEOUT:
      case VerifyStatus.CANCELLED:
        this.title = `Payment Cancelled`;
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.acceptText = 'Ok';
        this.imageUrl = './assets/images/payment-info.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
     
      default:
        this.title = `Payment Error`;
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.rejectText = 'Contact test centre';
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }
  iniPayStatus() {
    this.inipayPaymentService.configuration.accessToken = this.userToken;
    this.sub.add(this.activatedRoute.params?.subscribe((params) => {
      const routerSub = this.activatedRoute.queryParamMap.subscribe((queryParams) => {
        if (params.status.toLowerCase().includes('cancelled')) {
          this.verifyStatus(queryParams);
        } else if (params.status.toLowerCase().includes('next')) {
          this.approveStatus(queryParams);
        }
      });
      this.sub?.add(routerSub);
    }));
  }

  verifyStatus(queryParams) {
    const statusPayload = this.createStatusPayload(queryParams);

    const apiCallingSub = this.inipayPaymentService.paymentVerify(uuid.v4(), statusPayload).subscribe(
        response => this.handleVerifyResponse(response),
        error => this.handleVerifyError(error)
    );

    this.sub?.add(apiCallingSub);
}

createStatusPayload(queryParams) {
    return {
        receiptId: queryParams.get('orderId'),
        receiptNumber: queryParams.get('orderNumber'),
        signature: queryParams.get('signature'),
    };
}

handleVerifyResponse(response) {
    const decodedString = this.decodeResponseMessage(response.message);
    this.subTitle = decodedString;

    this.isNewPaymentJourney === 'enabled' 
        ? this.newmodalPopupDescription(response.status) 
        : this.modalPopupDescription(response.status);
}

decodeResponseMessage(encodedString) {
    const decodedString = encodedString.replace(/\\x/g, '%'); // Convert \\x to % for proper URL decoding
    return decodeURIComponent(decodedString).replace(/'|b/g, '');
}

handleVerifyError(error) {
    const status = error.includes('Unauthorized Access') ? VerifyStatus.UNAUTHORIZED : '';
    this.isNewPaymentJourney === 'enabled' 
        ? this.newmodalPopupDescription(status) 
        : this.modalPopupDescription(status);
}

approveStatus(queryParams) {
  const statusPayload = this.extractStatusPayload(queryParams);
  const apiCallingSub = this.inipayPaymentService.paymentApproval(uuid.v4(), statusPayload).subscribe(
    response => this.handleSuccessResponse(response),
    error => this.handleErrorResponse(error)
  );

  this.sub?.add(apiCallingSub);
}

private extractStatusPayload(queryParams): any {
  const authTransactionId = this.cleanValue(queryParams.get('p_auth_tid'));
  const idcName = queryParams.get('p_idcname');
  const receiptId = queryParams.get('p_noti');
  const receiptNumber = queryParams.get('p_oid');
  const message = this.cleanValue(queryParams.get('p_rmesg'));

  return { authTransactionId, idcName, receiptId, receiptNumber, message };
}

private cleanValue(value: string): string {
  return value && value.includes('undefined') ? '' : value;
}

private handleSuccessResponse(response): void {
  this.subTitle = response.message;
  this.isNewPaymentJourney === 'enabled' 
    ? this.newmodalPopupDescription(response.status)
    : this.modalPopupDescription(response.status);
}

handleErrorResponse(error: string): void {
  const isUnauthorized = error.includes('Unauthorized Access');
  const status = isUnauthorized ? ApprovalStatus.UNAUTHORIZED : '';

  this.isNewPaymentJourney === 'enabled' 
    ? this.newmodalPopupDescription(status)
    : this.modalPopupDescription(status);
}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
