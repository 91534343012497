import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EsewaPaymentService, PaymentStatus, PaymentStatusRequest } from '@idp-education/ors-esewa-payment-bff-client-v1';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-e-sewa-callback',
  templateUrl: './e-sewa-callback.component.html',
  styleUrls: ['./e-sewa-callback.component.scss']
})
export class ESewaCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub = new Subscription();
  activatedRouteSub: Subscription;
  apiCallingSub= new Subscription();
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private esewaPaymentService: EsewaPaymentService
  ) {
    this.routerSub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');}

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
      
    });
  }

 
  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
    this.paymentNewModalPopup = false;
  }

  modalpopupDescription(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.NOTFOUND:
      case PaymentStatus.PENDING:
      case 'CANCELLED':
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.eSewaStatus();
  }

  newmodalpopupDescription(status) {
    switch (status) {
      case PaymentStatus.NOTFOUND:
      case PaymentStatus.PENDING:
        this.title = `Payment Pending`;
        this.desc = `Hi ${this.userName}, your payment confirmation is pending. Please check back shortly to view the status or contact your test centre for assistance.`;
        this.imageUrl = './assets/images/payment-wait.svg'
        this.acceptText = 'Try again';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;

      case PaymentStatus.FAILED:
        this.title = `Payment Failed`;
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `Payment Unauthorised`;
        this.acceptText = 'Unauthorised Payment';
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'CANCELLED':
        this.title = `Payment Cancelled`;
        this.acceptText = 'Ok';
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.imageUrl = './assets/images/payment-info.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;

      default:
        this.title = `Payment Error`;
        this.acceptText = 'Try again';
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }
  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
  }

  eSewaStatus() {
    this.esewaPaymentService.configuration.accessToken = this.userToken;

    this.routerSub.add(this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      this.activatedRouteSub = this.activatedRoute.params.subscribe((params) => {
        const status = params.status.toLowerCase();
        const isFailedStatus = status.includes('failed');

        const statusPayload: PaymentStatusRequest = this.createStatusPayload(queryParams, isFailedStatus);

        const apiCallingSub = this.esewaPaymentService.paymentStatus(statusPayload, uuid.v4()).subscribe(response => {
          this.showModal(response.status);
        },
          error => {
            const message = (error.status === 401) ? 'Unauthorised' : '';
            this.showModal(message);
          })
          this.routerSub.add(apiCallingSub)
      })
    }));
  }

  showModal(message) {
    if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(message);
    } else {
      this.modalpopupDescription(message);
    }
  }

  createStatusPayload(queryParams, isFailedStatus) {
    if (isFailedStatus) {
      return {
        receiptId: queryParams.get('order_id'),
        receiptNumber: queryParams.get('order_number'),
        signature: queryParams.get('signature'),
        txData: ''
      };
    } else {
      return {
        receiptId: '',
        receiptNumber: '',
        signature: '',
        txData: queryParams.get('data')
      };
    }
  }

  handlePaymentResponse(status) {
    if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(status);
    } else {
      this.modalpopupDescription(status);
    }
  }

  handlePaymentError(error) {
    const errorStatus = error.status === 401 ? 'Unauthorised' : '';
    if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(errorStatus);
    } else {
      this.modalpopupDescription(errorStatus);
    }
  }
}
