import { EverFlowHelper } from './../../../shared/helper/everflow.helper';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { Component, ElementRef, OnInit, ViewChild, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  setCurrentStep,
  setTotalSteps,
  setProgressValue,
  setCurrentStepName,
  setOfflinePayment,
} from '../store/payment.actions';
import { first, map } from 'rxjs/operators';
import { paymentIState } from '../store/payment.reducer';
import {
  Application, CreateApplicationResponse, PaymentService, ProductFeeService,
  ReferenceDataService
} from '@idp-education/ors-test-taker-bff-client-v1';
import { PaymentsService } from 'src/app/shared/services/payment.services';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { SetProductType, SetTestLocalTimeZone } from '../../booking/store/booking.actions';
import { Title, Meta } from '@angular/platform-browser';
import { ContentModalComponent } from 'src/app/shared/components/content-modal/content-modal.component';
import { setBackAction } from '../../../store/global.actions';
import { getApplicationUserName } from 'store/applications/application.selectors';
import { isIOCProduct } from 'shared/utils/is-ioc-product';
import { OfflinePaymentRequestService } from 'shared/services/offline-payment-request.service';

const getCountryISO2 = require('country-iso-3-to-2');

@Component({
  selector: 'app-offline-payment-request',
  templateUrl: './offline-payment-request.component.html',
  styleUrls: ['./offline-payment-request.component.scss']
})
export class OfflinePaymentRequestComponent implements OnInit, OnDestroy {
  speakingtesttime: any;
  lrwtesttime: any;
  isNotIOLProduct: boolean;
  speakingUrl: string;
  @ViewChild('ErrorCode') ErrorCode: ElementRef;
  @ViewChild('btnSection') btnSection: ElementRef;
  @ViewChild('paymentErrorModal') paymentErrorModal: ContentModalComponent;
  token: string;
  paymentStatus: '' | 'Error' | 'Process' = '';
  sub: Subscription;
  application: CreateApplicationResponse;
  CurrentApp: Application;
  state: paymentIState;
  requestMode: 'Api' | 'Mock';
  addressDetailsForm: UntypedFormGroup;
  form: UntypedFormGroup;
  testLocalTimeZone$ = this.store.select(appState => appState.bookingStore.testLocalTimezone);
  getApplicationUserName$ = this.store.select(getApplicationUserName);

  receiptNumber$ = this.getReceiptNumber(); 
    
  applicationPaymentId$ = this.applicationService.GetCurrentApplication().pipe(map(app => {
    if (app && app.applicationPayments && app.applicationPayments.length > 0) {
      return app.applicationPayments[0].id;
    } else {
      console.info('BX Error: Application Payment Id is missing');
    }
  }));


  constructor(
    private renderer: Renderer2,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private paymentService: PaymentsService,
    private offlinePaymentService: PaymentService,
    private store: Store<{ paymentStore, globalStore, applicationsStore, bookingStore }>,
    private applicationService: ApplicationsService,
    private loadservice: LoadingService,
    private everFlowHelper: EverFlowHelper,
    private titleService: Title, private metaTagService: Meta,
    private feeService: ProductFeeService,
    private refDataService: ReferenceDataService,
    private fb: UntypedFormBuilder,
    private offlinePaymentRequestService: OfflinePaymentRequestService
  ) {
    this.form = fb.group({
      haveRead: [false, Validators.required]
    });
    this.titleService.setTitle('Make payment to confirm booking | IDP IELTS');
    this.sub = this.store.select(appState => appState.paymentStore).pipe(first()).subscribe(x => {
      this.state = x;
    });


    this.sub.add(this.applicationService.GetCurrentApplication().pipe(first()).subscribe(x => {
      if (!x) {
        this.router.navigate(['/my-account']);
      } else {
        this.CurrentApp = x;
        if (isIOCProduct(this.CurrentApp.bookings[0]?.bookableProductName)) {
          this.store.dispatch(SetProductType({
            isNotIOLProduct: true
          }));
          this.store.dispatch(SetTestLocalTimeZone({
            testLocalTimezone: this.CurrentApp?.timeZone
          }));
        }
      }
    }));
    this.sub.add(this.store.select(state => state.bookingStore.isNotIOLProduct).subscribe(payload => {
      this.isNotIOLProduct = payload;
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.speakingUrl).subscribe(x => {
      if (x) {
        this.speakingUrl = x;
      }
    }));
    this.store.dispatch(setCurrentStep({ currentStep: this.isNotIOLProduct ? 0 : 3 }));
    this.store.dispatch(setCurrentStepName({ currentStepName: 'Payment' }));
    this.store.dispatch(setTotalSteps({ totalSteps: 3 }));
    this.store.dispatch(setProgressValue({ progressValue: 100 }));
    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: '/payment/card-detail' }));

  }

  get applicationId() {
    return this.CurrentApp?.id;
  }

  get applicationPaymentId() {
    return Array.isArray(this.CurrentApp?.applicationPayments) && this.CurrentApp?.applicationPayments.length
      && this.CurrentApp?.applicationPayments.reduce((a, b) => (new Date(a.createdOn) > new Date(b.createdOn) ? a : b)).id;
  }

  getReceiptNumber(): Observable<string> {
    return this.offlinePaymentRequestService.createReceipt().pipe(
      map((response) => {
        if (response && 'receiptNumber' in response) {
          return response.receiptNumber;
        }

        if (Array.isArray(response) && response.length) {
          return response[0].receiptNumber;
        }

        return '';
      })
    );
  }

  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.loadservice.resetLoadingCounter();
    this.store.dispatch(setBackAction({ BackButtonEnable: false, BackButtonRoute: null }));
  }

  private handleError(err) {
    this.paymentErrorModal?.open()?.result.finally(() => this.backToMyAccount());
  }

  backToMyAccount() {
    this.isNotIOLProduct ? window.location.href = this.speakingUrl : this.router.navigate(['/my-account']);
  }

  onRequestComplete() {
    this.everFlowHelper.processEverflowConversion(this.CurrentApp);
    this.router.navigate(['/payment/confirmation']);
  }

  backToPayment() {
    this.store.dispatch(setOfflinePayment({
      isOffline: false
    }));
    this.router.navigate(['/payment/card-detail']);
  }
}
