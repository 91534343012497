import { Injectable } from '@angular/core';
import { isFunction } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PubSubService {
  handlers: object;

  subscribe(eventName: string, handler: any, context: any) {
    if (typeof context === 'undefined') { context = handler; }
    this.handlers = {
      ...this.handlers,
      [eventName]: handler.bind(context)
    };
  }

  publish(eventName: string, args?: any) {
    if (this.handlers && this.handlers[eventName] && isFunction(this.handlers[eventName])) {
      this.handlers[eventName](args);
    }
  }

  removeSubscribe(eventName: string) {
    delete this.handlers[eventName];
  }
}
