import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, AfterContentInit } from '@angular/core';
import { StepsService } from '../../services/steps.service';
import { IStepModel } from '../../models/components/steps';
import { StepTemplateComponent } from './step-template/step-template.component';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  providers: [StepsService]
})
export class WizardComponent implements OnInit, AfterContentInit {

  @Input('steps') _stepsArg: IStepModel[] = [];
  @Input() isEnableBackButton = true;
  @Input() backButtonLabel = 'Back';
  @Input() NextButtonLabel = 'Next';
  @Output() onNextClick: EventEmitter<void> = new EventEmitter();
  @Output() onBackClick: EventEmitter<void> = new EventEmitter();
  @ContentChildren(StepTemplateComponent) stepsComponents: QueryList<StepTemplateComponent>;
  public currentStep: BehaviorSubject<IStepModel>;
  private _steps: IStepModel[];
  constructor(public stepService: StepsService) { }

  ngAfterContentInit(): void {
    this?.currentStep?.subscribe(cs => this.changeVisibleStepComponent(cs.stepIndex));
  }

  private changeVisibleStepComponent(stepIndex) {
    this.stepsComponents.forEach((item, index) => {
      if (index + 1 === stepIndex) {
        item.visible = true;
      } else {
        item.visible = false;
      }
    });
  }

  ngOnInit(): void {
    this.stepService.steps = this._stepsArg;
    this._steps = [...this._stepsArg];
    this.currentStep = this.stepService.currentStep$;
  }

  onStepClick(step: IStepModel) {
    this.stepService.setCurrentStep(step);
  }

  public get steps(): IStepModel[] {
    return this.stepService.steps;
  }
}
