import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import { HsbcomniPaymentService, PaymentStatus, PaymentStatusRequest } from '@idp-education/ors-hsbcomni-payment-bff-client-v1';
import * as uuid from 'uuid';
import { IPaymentText } from 'shared/interfaces/payment.interface';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-hsbc-omni-callback',
  templateUrl: './hsbc-omni-callback.component.html',
  styleUrls: ['./hsbc-omni-callback.component.scss']
})
export class HsbcOmniCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  subTitle: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  sub: Subscription;
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private hsbcomniPaymentService: HsbcomniPaymentService
  ) { 
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.sub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
  }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
  
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
    this.paymentNewModalPopup = false;
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.hsbcOmniStatus();
  }

  modalPopupDescription(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = IPaymentText.failedTitle;
        this.acceptText = IPaymentText.failedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.PENDING:
        this.title = IPaymentText.pendingTitle;
        this.acceptText = IPaymentText.pendingAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.TIMEOUT:
      case PaymentStatus.CANCELLED:
        this.title = IPaymentText.cancelledTitle;
        this.acceptText = IPaymentText.cancelledAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorized':
      case 'UNAUTHORIZED':
        this.title = IPaymentText.unauthorizedTitle;
        this.acceptText = IPaymentText.unauthorizedAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = IPaymentText.defaultTitle;
        this.acceptText = IPaymentText.defaultAcceptText;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  newmodalPopupDescription(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Payment Failed`;
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.PENDING:
        this.title = `Payment Pending`;
        this.desc = `Hi ${this.userName}, your payment confirmation is pending. Please check back shortly to view the status or contact your test centre for assistance.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-wait.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.TIMEOUT:
      case PaymentStatus.CANCELLED:
        this.title = `Payment Cancelled`;
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.imageUrl = './assets/images/payment-info.svg';
        this.acceptText = 'Ok';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorized':
      case 'UNAUTHORIZED':
        this.title = `Payment Unauthorised`;
        this.acceptText = 'Unauthorised Payment';
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Payment Error`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg';
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  hsbcOmniStatus() {
    this.hsbcomniPaymentService.configuration.accessToken = this.userToken;
    this.sub.add(this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const receiptId = queryParams.get('orderId');
      const signature = queryParams.get('signature');
      const receiptNumber = queryParams.get('orderNumber');
      const statusPayload: PaymentStatusRequest = {
        receiptId,
        receiptNumber,
        signature
      }
      const apiCallingSub = this.hsbcomniPaymentService.paymentStatus(uuid.v4(), statusPayload).subscribe(response => {
        this.showModal(response.status);
      },
      error => {
          const message = (error.status === 401) ? 'Unauthorised' : '';
          this.showModal(message);
      })
    
      this.sub.add(apiCallingSub);
    }));
  }
  showModal(message) {
    if (this.isNewPaymentJourney === 'enabled') {
        this.newmodalPopupDescription(message);
    } else {
        this.modalPopupDescription(message);
    }
  }
}
