import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ICon } from '../components/payment/payment-container/payment-container.component';
import { PaymentGateways } from 'pages/payment/payment.enum';
export interface IPaymentInterface {
  icon: ICon;
  type: IPaymentMethods;
  title: string;
  visible: boolean;
  onLoaded: EventEmitter<void>;
  onApproval: EventEmitter<any>;
  onError: EventEmitter<{ errors: string[] }>;
  onValidationChange: EventEmitter<{
    isValid: string[], errors: string[], cardErrors: string[]
  }>;
  initPaymentCard(type?: IPaymentMethods): Observable<void> | void;
}
export type IPaymentMethods = 'BRAINTREE' | 'CREDIT_CARD' | 'OFFLINE' | 'PAYPAL' | 'STRIPE' | 'TIPS' | 'PAYSTACK' | 'VERITRANS' | 'BANKALFALAH' | 'BRAC' | 'ESEWA' | 'PAYDOLLAR' | 'NEWEBPAY' | 'INIPAY' | 'HSBCOMNI';
export const IPaymentMethods = {
  BRAINTREE: 'BRAINTREE' as IPaymentMethods,
  CREDITCARD: 'CREDIT_CARD' as IPaymentMethods,
  OFFLINE: 'OFFLINE' as IPaymentMethods,
  PAYPAL: 'PAYPAL' as IPaymentMethods,
  STRIPE: 'STRIPE' as IPaymentMethods,
  TIPS: 'TIPS' as IPaymentMethods,
  PAYSTACK: 'PAYSTACK' as IPaymentMethods,
  VERITRANS: 'VERITRANS' as IPaymentMethods,
  BANKALFALAH: 'BANKALFALAH' as IPaymentMethods,
  BRAC: 'BRAC' as IPaymentMethods,
  ESEWA: 'ESEWA' as IPaymentMethods,
  PAYDOLLAR: 'PAYDOLLAR' as IPaymentMethods,
  NEWEBPAY: 'NEWEBPAY' as IPaymentMethods,
  INIPAY: 'INIPAY' as IPaymentMethods,
  HSBCOMNI: 'HSBCOMNI' as IPaymentMethods
};
export const IPaymentText = {
  failedTitle: `Your payment failed. Please retry the payment.`,
  failedAcceptText: 'Retry Payment',
  cancelledTitle: `Your payment is cancelled. Redirecting to your dashboard.`,
  cancelledAcceptText: 'Payment Cancelled',
  pendingTitle: 'Your payment is pending confirmation from HSBC Omni. Please check your IELTS Profile after sometime for the latest status or contact your test centre',
  pendingAcceptText: 'Payment Pending',
  unauthorizedTitle: `The payment authorisation failed due to a security check. Redirecting to your dashboard.`,
  unauthorizedAcceptText: 'Unauthorised Payment',
  defaultTitle: `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`,
  defaultAcceptText: 'Return to My Account'
}

export const IPaymentGateways = [
  PaymentGateways.VERITRANS,
  PaymentGateways.PAYSTACK,
  PaymentGateways.TIPS,
  PaymentGateways.BANKALFALAH,
  PaymentGateways.BRAC,
  PaymentGateways.ESEWA,
  PaymentGateways.PAYDOLLAR,
  PaymentGateways.NEWEBPAY,
  PaymentGateways.INIPAY,
  PaymentGateways.HSBCOMNI,
  PaymentGateways.PAYMENTOS
]