import { UntypedFormGroup } from '@angular/forms';

export function postValidator(control: UntypedFormGroup) {
  const postCode = control.controls['postcode'];
  const error = { noSpaceStartEnd: true };
  const postCodeValue = postCode.value;
  const regExp = /^(?![-\s])(?!.*[-\s]$)(?!.*[-\s]{2})[-A-Za-z0-9\s]{0,10}$/;
  if (postCode) {
    if (!regExp.test(postCodeValue)) {
      postCode.setErrors(error);
    }
    else {
      postCode.setErrors(null);
    }
  }
}
