import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { SharedStateService } from '../booking/shared-state.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { slideInAnimation } from './route-animations';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Application, Booking } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { SetLatestPageUrl, SetProductType, SetTestLocalTimeZone } from '../booking/store/booking.actions';
import { paymentIState, selectFromOffline, selectFromTips, selectOfflinePayment } from './store/payment.reducer';
import {
  IPaymentStatus,
  setClear,
  setHasTimer,
  setLrwtesttime,
  setSpeakingtesttime,
  setUserProfile
} from './store/payment.actions';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { setBackAction } from 'src/app/store/global.actions';
import { setCurrentApplication } from 'src/app/store/applications/application.action';
import { ResetState } from '../booking/store/booking.actions';
import { DateTime } from 'luxon';
import { PaymentMethodType } from '@idp-education/ors-test-taker-bff-client-v1/model/paymentMethodType';
import { isIOCProduct } from 'shared/utils/is-ioc-product';

@Component({
  selector: 'app-payment-manage',
  templateUrl: './payment-manage.component.html',
  styleUrls: ['./payment-manage.component.scss'],
  animations: [slideInAnimation],
})
export class PaymentManageComponent implements OnDestroy, AfterViewChecked {
  state: paymentIState;
  sub: Subscription;
  speakingUrl: string;
  testLocalTimezone: string;
  currentOutlet = 'welcome';
  activePage = 'welcome';
  speakingtesttime;
  lrwtesttime;
  applicationClose = null;
  currentApplicationTime = null;
  isNotIOLProduct: boolean;
  isVeritransCVS = false;
  leftSidePanel = {
    hasUser: false,
    userName: '',
    welcome: {
      title: $localize`Welcome To Payment`,
      subTitle: $localize`Let's complete your Payment`,
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    carddetail: {
      title: $localize`Finalise payment`,
      subTitle: $localize`We need your passport details for identity check. For more information, please see our privacy policy.`,
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    offlinepayment: {
      title: $localize`Finalise Booking`,
      subTitle: '',
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    confirmation: {
      title: $localize`Confirmation`,
      subTitle: ``,
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
  };
  userEmail = '';
  isOfflinePayment$ = this.store.select(selectOfflinePayment);
  fromTips$ = this.store.select(selectFromTips);
  fromOffline$ = this.store.select(selectFromOffline);
  _fromTips = false;
  _fromOffline = false;
  @ViewChild('restartBookingModal') restartBookingModal: ConfirmModalComponent;

  private _hasTimer: boolean;
  public get hasTimer(): boolean {
    return this._hasTimer;
  }

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    public bookingState: SharedStateService,
    private store: Store<{ paymentStore, userStore, bookingStore }>,
    private userProfileService: UserProfileService,
    private applicationService: ApplicationsService) {
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
    this.store.dispatch(setBackAction({ BackButtonEnable: false, BackButtonRoute: null }));
    this.sub = new Subscription();
    this.sub.add(this.userProfileService.getUserProfile(true).subscribe((uProfile) => {
      if (uProfile) {
        const userName = uProfile.lastName ? `${uProfile.firstName} ${uProfile.lastName}` : `${uProfile.firstName}`;
        this.store.dispatch(setUserProfile({ userProfile: uProfile }));
        this.userEmail = uProfile.emailAddress;
        this.leftSidePanel.welcome.title = $localize`Hi ${userName}. Welcome to IELTS Online`;
        this.leftSidePanel.userName = userName;
        this.leftSidePanel.hasUser = true;
        this.getStore();
      } else {
        this.store.dispatch(setUserProfile({ userProfile: null }));
      }
    }));
    this.sub.add(this.isOfflinePayment$.subscribe(isOffline => {
      if (isOffline) {
        this.leftSidePanel.confirmation.title = `Awaiting Payment Confirmation`;
      } else {
        this.leftSidePanel.confirmation.title = `Payment Confirmation`;
      }
    }));
    this.sub.add(this.store.select(appState => appState.paymentStore?.hasTimer).subscribe(t => this._hasTimer = t));
    this.sub.add(this.store.select(appState => appState.paymentStore).subscribe(x => {
      this.state = x;
    }));
    this.sub.add(this.store.select(state => state.bookingStore.isNotIOLProduct).subscribe(payload => {
      this.isNotIOLProduct = payload;
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.speakingUrl).subscribe(x => {
      if (x) {
        this.speakingUrl = x;
      }
    }));
    this.sub.add(this.store.select(appState => appState.bookingStore.testLocalTimezone).subscribe(x => {
      if (x) {
        this.testLocalTimezone = x;
      }
    }));
    this.sub.add(router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && ev.url === '/payment/offline-payment') {
        this.activePage = 'offlinepayment';
      }
    }));
    this.sub.add(this.fromOffline$.subscribe(fromOffline => {
      this._fromOffline = fromOffline;
    }));
    this.store.dispatch(setHasTimer({ hasTimer: true }));
    const paymentMethodType = localStorage.getItem('paymentMethodType');
    this.isVeritransCVS = paymentMethodType === PaymentMethodType.CVS;
  }


  get email() {
    return this.userEmail;
  }

  getStore() {
    this.fromTips$.pipe(first(), concatMap(fromTips => {
      this._fromTips = fromTips;
      return this.applicationService.GetCurrentApplication({ force: !fromTips });
    }), first()).subscribe((app: Application) => {
      if (app) {
        try {
          if (this.paidApplication(app) && !this._fromTips && !this._fromOffline) {
            this.store.dispatch(setCurrentApplication({ application: null }));
            this.router.navigate(['/my-account']);
          }
          const speakingAndLrw = this.getSpeakingAndLRW(app);
          if (!speakingAndLrw.lrw && !speakingAndLrw.speaking) {
            this.store.dispatch(setCurrentApplication({ application: null }));
            this.router.navigate(['/my-account']);
            return;
          }
          this.store.dispatch(setCurrentApplication({ application: app }));
          if (isIOCProduct(app?.bookings[0]?.bookableProductName)) {
            this.isNotIOLProduct = true;
          }
          const speakerTestTime = speakingAndLrw.speaking && getSpeakingTestTime(speakingAndLrw.speaking);
          const LrwTestTime = speakingAndLrw.lrw && getLRWTestTime(speakingAndLrw.lrw);
          if (!this._fromTips) {
            this.applicationClose = DateTime.fromISO(app.expiryDateTimeUtc, { zone: 'utc' });
          }
          this.currentApplicationTime = DateTime.now();
          this.store.dispatch(this.setSpeakingTestTimeAction({ speakingtesttime: speakerTestTime }));
          this.store.dispatch(this.setLrwTestTimeAction({ lrwtesttime: LrwTestTime }));
          if (isIOCProduct(app?.bookings[0]?.bookableProductName) ||
            app?.bookings[0]?.bookableProductName.includes('IELTS OSR UKVI on Computer')) {
            this.store.dispatch(SetProductType({
              isNotIOLProduct: true
            }));
            this.store.dispatch(SetTestLocalTimeZone({
              testLocalTimezone: app?.timeZone
            }));
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    function getLRWTestTime(LRW: Booking) {
      return LRW.bookingLines[0].startDateTimeLocal ?
        {
          from: DateTime.fromISO(LRW.bookingLines[0].startDateTimeLocal, { setZone: true }).toJSDate(),
          to: DateTime.fromISO(LRW.bookingLines[0].endDateTimeLocal, { setZone: true }).toJSDate()
        } : { to: DateTime.now().toJSDate(), from: DateTime.now().toJSDate() };
    }

    function getSpeakingTestTime(speaking: Booking) {
      return speaking.bookingLines[0].startDateTimeLocal ?
        {
          from: DateTime.fromISO(speaking.bookingLines[0].startDateTimeLocal, { setZone: true }).toJSDate(),
          to: DateTime.fromISO(speaking.bookingLines[0].endDateTimeLocal, { setZone: true }).toJSDate()
        } : { to: DateTime.now().toJSDate(), from: DateTime.now().toJSDate() };
    }
  }

  paidApplication(app: Application): boolean {
    return !!app?.applicationPayments?.find(payment => payment.status === 'PAID' || payment.status === 'IN_PROGRESS');
  }

  getSpeakingAndLRW(app: Application): { speaking: Booking, lrw: Booking } | null {
    try {
      const lrw = app.bookings.find(i => (i.bookingLines[0].languageSkill.match(/[LRW]/))?.length > 0 && i.status !== 'CANCELLED');
      const speaking = app.bookings.find(i => i.bookingLines[0].languageSkill === 'S' && i.status !== 'CANCELLED');

      return { lrw, speaking };
    } catch (error) {
      return null;
    }
  }

  setSpeakingTestTimeAction(data) {
    return setSpeakingtesttime(data);
  }

  setLrwTestTimeAction(data) {
    return setLrwtesttime(data);
  }

  ngOnDestroy(): void {
    if (!this._fromTips) {
      this.bookingState.clearState();
      this.store.dispatch(setClear());
      this.store.dispatch(setCurrentApplication({ application: null }));
    }
    this.sub?.unsubscribe();
  }

  ngAfterViewChecked() {
    this.activePage = this.currentOutlet;
    this.cdr.detectChanges();
  }

  getState(outlet: RouterOutlet) {
    const temp = outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
    this.currentOutlet = temp;
    return temp;
  }

  timeOutModalConfirm() {
    (this.isNotIOLProduct && !!this.speakingUrl) ? window.location.href = this.speakingUrl : this.router.navigate(['/booking']);
    this.store.dispatch(ResetState());
  }

  whenTimerDone() {
    this.sub.add(this.store.select(appState => appState.paymentStore.paymentStatus).subscribe((ps: IPaymentStatus) => {
      if ((ps === 'failed' || ps === 'not started') && !this._fromOffline ) {
        this.store.dispatch(setCurrentApplication({ application: null }));
        if (this.restartBookingModal) {
          this.restartBookingModal.open();
        }
      }
    }));
  }
}
