import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-test-details-content',
  templateUrl: './test-details-content.component.html',
  styleUrls: ['./test-details-content.component.scss']
})
export class TestDetailsContentComponent {
  @Input() testType: string;
  @Input() testFormat: string;
  @Input() testVenue: string;
  @Input() address: string;
  @Input() upcomingTestHeader = false;

}
