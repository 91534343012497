import { UntypedFormGroup } from '@angular/forms';

export function startEndSpaceValidator(control: UntypedFormGroup) {
  const formControlList = Object.values(control.controls).map(controlItem => controlItem);

  const error = { noSpaceStartEnd: true };
  const regExpForTrailingSpace = /^ | $/; // pattern for checking if there are trailing spaces

  formControlList.forEach(formControl => {
    if (formControl) {
      if (regExpForTrailingSpace.test(formControl.value)) {
        formControl.setErrors(error);
      }
    }
  });
}
