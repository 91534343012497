import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, Subject , throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  getCurrentLocation(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        const subject = new Subject();
        let errorResult: any;
        navigator.geolocation.getCurrentPosition(location => {
          subject.next(location);
          subject.complete();
        }, (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorResult = new Error('User denied the request for Geolocation');
              break;
            case error.POSITION_UNAVAILABLE:
              errorResult = new Error('Location information is unavailable');
              break;
            case error.TIMEOUT:
              errorResult = new Error('The request to get user location timed out.');
              break;
            default:
              errorResult = new Error('An unknown error occurred');
              break;
          }
          subject.error(errorResult);
        });
        return subject.asObservable();
      } else {
        return throwError(() => new Error('Location is not supported'));
      }
    }
    return of(null);
  }
}
