import { DateTime } from 'luxon';

export const customDateFormat = (timezone: string, format: string, dateFrom: any, dateTo?: any) => {
  if (dateFrom instanceof Date) {
    const fromTimeClone = DateTime.fromJSDate(dateFrom).setZone(timezone);
    switch (format) {
      case 'month':
        return fromTimeClone.toFormat('MMM');
      case 'day':
        return fromTimeClone.toFormat('dd');
      case 'year':
        return fromTimeClone.toFormat('yyyy');
      case 'time': {
        let fromTimeString = fromTimeClone.toFormat('h:mm a').toLowerCase();
        if (dateTo instanceof Date) {
          const toTimeClone = DateTime.fromJSDate(dateTo).setZone(timezone);
          fromTimeString += ' to ' + toTimeClone.toFormat('h:mm a').toLowerCase();
        }
        return `${fromTimeString}`;
      }
    }
  }
};

export const calculateDuration = (startDate: DateTime, endDate: DateTime, isSpeaking: boolean) => {
    const getHoursAndMin = (date: DateTime) => date.toFormat('h:mm a').toLowerCase();
    const getJustHours = (date: DateTime) => date.toFormat('h a').toLowerCase();
    const remainStartMinutes = startDate.minute;
    const remainEndMinutes = endDate.minute;
    const startDateHoursAndMins = getHoursAndMin(startDate);
    const startDateJustMin = getJustHours(startDate);
    const endDateHoursAndMins = getHoursAndMin(endDate);
    const endDateJustMin = getJustHours(endDate);
    const startTime = remainStartMinutes !== 0 ? startDateHoursAndMins : startDateJustMin;
    const endTime = remainEndMinutes ? endDateHoursAndMins : endDateJustMin;
    const duration = isSpeaking ? 20 : 180;
    return { startTime, duration };
};
