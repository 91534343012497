<div class="flexbox flex-column content align-items-center align-items-lg-start" appApplyTabIndexes>
  <div class="uploadBoxWrapper">
    <div class="uploadBox" #uploadBox></div>
  </div>
  <div class="form-wrapper form-content mt-3 mb-3 w-100 p-1">
    <app-identity-details-form [form]="form" [nationalityList]="this.nationalities"
      [identityType]="state.selectedIdType.code"></app-identity-details-form>
    <div class="form-group">
      <div class="form-row">
        <app-two-button (acceptClick)="onNextClick()" (rejectClick)="saveAndExitClick()"
          [enableAcceptClick]="form.valid" defaultActiveButton="accept" acceptText="Save and continue"
          rejectText="Save and Exit" [showReject]="false">
        </app-two-button>
      </div>
    </div>
  </div>
</div>
<app-confirm-modal #saveAndExitModal title="Are you sure you wish to exit?"
  imageUrl="assets/images/Girl green organic shape.png" defaultActiveButton="reject"></app-confirm-modal>