import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject,
  OnInit,
} from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { NgbOffcanvas, NgbOffcanvasConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  SubOfflinePaymentConfig,
  SubOfflinePaymentConfigMap,
} from '../../../services/mock-data/offlinePaymentItems';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OfflinePaymentService } from 'shared/services/offline-payment-service';
import { Subscription, first, tap } from 'rxjs';
import { OfflinePaymentRequestService } from 'shared/services/offline-payment-request.service';
import { extractText } from './offline.helper';
import { SharedStateService } from 'pages/account/shared-state.service';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
})

export class OfflineComponent implements OnInit {
  @Input() testLocationId: string;
  @Input() hasModalInstructions = false;
  @Output() onContinue = new EventEmitter<void>();
  paymentOptions: SubOfflinePaymentConfig[];
  sub: Subscription;

  constructor(
    offcanvasConfig: NgbOffcanvasConfig,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private offlinePaymentService: OfflinePaymentService,
    private sharedShateService: SharedStateService
  ) {
    offcanvasConfig.position = 'end';
  }

  static get tab(): ICon {
    const requestService = inject(OfflinePaymentRequestService);

    let title: string;
    let description: string;

    if (requestService && requestService.newOfflinePaymentEnabled) {
      title = requestService.offlinePaymentMethodContents?.label;
      description = extractText(
        requestService.offlinePaymentMethodContents?.description || ''
      );
    }

    if (!title || !description) {
      title = 'Pay offline';
      description = 'Pay directly to your test centre';
    }

    return {
      alt: 'offline',
      key: 'OFFLINE',
      url: '',
      class: 'fa fa-solid-wallet',
      title,
      description,
    };
  }

  ngOnInit(): void {
    this.sharedShateService?.testLocationId?.subscribe((val:any)=>{
      this.testLocationId = val;
    })
    this.loadPaymentOptions(this.testLocationId);  
  }

  loadPaymentOptions(testLocationId: string) {
    this.paymentOptions = [];
    this.offlinePaymentService
      .getOfflinePaymentMethod(testLocationId)
      .pipe(
        first(),
        tap((offlinePaymentMethod) => {
          if (Array.isArray(offlinePaymentMethod)) {
            this.paymentOptions = offlinePaymentMethod;
            return;
          }

          const {
            paymentMethod: { paymentMethodContents },
          } = offlinePaymentMethod;

          if (paymentMethodContents && paymentMethodContents.length > 0) {
            this.paymentOptions =
              paymentMethodContents[0].contents[0].paymentOptionContents.map(
                (option) => ({
                  type: SubOfflinePaymentConfigMap[option.title],
                  description: extractText(option.description),
                  instructions: option.instructions,
                  label: extractText(option.label),
                })
              );
          }
        })
      )
      .subscribe();
  }

  onOptionClick(option: SubOfflinePaymentConfig) {
    this.onContinue.emit();
  }

  viewOptionsInstructions(content: TemplateRef<any>) {
    if (this.hasModalInstructions) {
      const settings: NgbModalOptions = {
        size: 'lg',
        animation: true,
        backdrop: 'static',
        keyboard: false,
        scrollable: true,
      };
      if (this.modalService.hasOpenModals()) {
        return;
      }
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', ...settings });
    } else {
      if (this.offcanvasService.hasOpenOffcanvas()) {
        return;
      }
      this.offcanvasService.open(content, { panelClass: 'theme-1' });
    }
  }

  keyUpEvent($event: KeyboardEvent) {
    if ($event.code === 'Enter' || $event.code === 'Space') {
      if ($event.target instanceof HTMLElement) {
        $event.target.click();
      }
    }
  }
}
