import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ssr-test-format',
  templateUrl: './ssr-test-format.component.html',
  styleUrls: ['./ssr-test-format.component.scss']
})
export class SsrTestFormatComponent {
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrTestLocationClick: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

 backToRo() {
  this.onBackButtonClick.emit();
 }

}
