<form class="ukvi-questions-form" [formGroup]="questionsForm" *ngIf="questionsForm else missingTheForm">
  <app-yes-no-question question="Are you taking this test for UK Visas and Immigration (UKVI)?"
    [formControl]="questionsForm.get('isTestForUKVI')" [inputDisabled]="readonly || (!isCefrDataAvailable && disableUKVIFields)"></app-yes-no-question>
  <ng-container *ngIf="showInfoBox">
    <div class="info-box">
      <i class="fa fa-info-circle"></i>
      <p class="m-0">It is the responsibility of the test taker to register for the right type of test (IELTS, IELTS
        UKVI, Life Skills A1 or Life Skills B1)</p>
    </div>
  </ng-container>
  <div *ngIf="isCefrDataAvailable" class="col-12 col-lg-6">
    <app-dropdown id="cefrLevel" title="What is your desired CEFR level?" [formControl]="questionsForm?.get('targetCefrLevel')" labelKey="name" idKey="name"
      i18n-placeholder placeholder="CEFR Level" [options]="cefrDropdownValues" [showTick]="true" [isRequired]="true" [showValidationError]="true">
    </app-dropdown>
  </div>
  <app-yes-no-question *ngIf="!isCefrDataAvailable" question="Are you planning to study at a university with UKVI Highly trusted Sponsor status?"
    [formControl]="questionsForm.get('isUKVISponsorStatus')" [inputDisabled]="readonly || (!isCefrDataAvailable && disableUKVIFields)"></app-yes-no-question>
  <app-yes-no-question *ngIf="!isCefrDataAvailable" question="Were you referred by an agent / partner"
    [formControl]="questionsForm.get('isUKVIReferral')" [inputDisabled]="readonly || (!isCefrDataAvailable && disableUKVIFields)"></app-yes-no-question> 
  <div class="referred-box" *ngIf="!isCefrDataAvailable">
    <label for="referral_name" class="text-bold">Enter Referral Partner name below</label>
    <input class="form-control" [formControl]="questionsForm.get('referralName')" placeholder="Provide partner name"
      type="text" name="referral_name" id="referral_name" [readOnly]="readonly || (!isCefrDataAvailable && disableUKVIFields)"/>
    <app-field-errors [customMessage]="'Referral Partner name is required'"
      [control]="questionsForm.controls.referralName" componentId="referralName"
      *ngIf="questionsForm.controls.referralName.invalid && questionsForm.controls.referralName.touched">
    </app-field-errors>
  </div>
  <div class="referred-box" *ngIf="!isCefrDataAvailable">
    <label for="issuing-authority" class="text-bold isRequired">Enter ID issuing Authority</label>
    <input class="form-control" [formControl]="questionsForm.get('issuingAuthority')" placeholder="ID issuing authority"
      type="text" name="issuing-authority" id="issuing-authority" [readOnly]="readonly || (!isCefrDataAvailable && disableUKVIFields)"/>
    <app-field-errors 
      [control]="questionsForm.get('issuingAuthority')" componentId="issuingAuthority"
      *ngIf="questionsForm.get('issuingAuthority').invalid && questionsForm.get('issuingAuthority').touched">
    </app-field-errors>
  </div>
  <div class="d-flex align-center conditions" *ngIf="showTermsAndConditions">
    <app-circle-checkbox [form]="questionsForm" [control]="'termAndCondition2'"></app-circle-checkbox>
    <span i18n>I agree to the 
      <a (click)="openTermAndCondition()" (keyup)="openTermAndCondition()" class="btn-link text-grey terms-and-conditions"> 
        Terms and Conditions
      </a>
    </span>
  </div>
</form>

<ng-template #missingTheForm>
  <div class="missing-form-box">
    <i class="fa fa-info-circle text-danger"></i>
    <p class="m-0">The form is missing, make sure to read the component document and provide the form group as expected
    </p>
  </div>
</ng-template>