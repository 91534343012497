import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition-ukvi',
  templateUrl: './terms-and-condition-ukvi.component.html',
  styleUrls: ['./terms-and-condition-ukvi.component.scss']
})
export class TermsAndConditionUkviComponent {

}
