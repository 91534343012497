import { Component, EventEmitter, Output } from '@angular/core';
import { DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ssr-instructions-display',
  templateUrl: './ssr-instructions-display.component.html',
  styleUrls: ['./ssr-instructions-display.component.scss']
})
export class SsrInstructionsDisplayComponent {

  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() OnssrInstructionClick: EventEmitter<any> = new EventEmitter();
  osrCheckboxForm = new UntypedFormGroup({
    osrCheckbox: new UntypedFormControl('', Validators.required),
  });

  backToMyTests() {
    this.onBackButtonClick.emit();
 }

}
