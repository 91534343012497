import { createAction, props } from '@ngrx/store';

export const SET_BA = 'setBackAction';
export const SET_PE = 'setPageError';
export const SET_RM = 'setRequestMode';
export const SET_LM = 'setLocationMode';
export const SET_3DS = 'set3dsPayment';
export const LOGOUT = 'setLogout';
export const SET_SSR = 'setSSR';
export const SET_UPDATE_APPLICATION_DETAILS = 'setUpdateApplicationDetails';
export const SET_ENABLE_OFFLINE_EOR = 'setEnableOfflineEOR';
export const SET_DOWNLOAD_ETRF = 'setDownloadEtrf';
export const SET_NEW_ACCOUNT_UI = 'enableNewAccountUI';
export const SET_NEW_OFFLINE_PAYMENT = 'enableNewOfflinePayment';
export const SET_CHANGE_PAYMENT_TO_ONLINE = 'changePaymentToOnlineEnabled';
export const SET_DASHBOARD_V2 = 'enableDashboardV2';
export const SET_ONETRUST = 'setEnableOnetrustPolicy';
export const SET_CAPTCHA_CHECK = 'setEnableCaptchaCheck';

export const setBackAction = createAction(SET_BA, props<{ BackButtonRoute: string, BackButtonEnable: boolean }>());

export const setPageError = createAction(SET_PE, props<{ pageError: { message: string, routeFrom: string } }>());

export const setRequestMode = createAction(SET_RM, props<{ requestMode: 'Api' | 'Mock' }>());

export const setLocationMode = createAction(SET_LM, props<{ locationMode: 'Api' | 'Mock' }>());

export const set3dsPayment = createAction(SET_3DS, props<{ enable3DSPayment: boolean }>());

export const setSSR = createAction(SET_SSR, props<{ enableSSR: boolean }>());

export const setUpdateApplicationDetails = createAction(SET_UPDATE_APPLICATION_DETAILS, props<{
  enableUpdateApplicationDetails: boolean
}>());

export const setEnableOfflineEOR = createAction(SET_ENABLE_OFFLINE_EOR, props<{
  enableOfflineEOR: boolean
}>());

export const setDownloadEtrf = createAction(SET_DOWNLOAD_ETRF, props<{ enableDownloadEtrf: boolean }>());

export const setEnableNewAccountUI = createAction(SET_NEW_ACCOUNT_UI, props<{ enableNewAccountUI: boolean }>());

export const setEnableDashboardV2 = createAction(SET_DASHBOARD_V2, props<{ enableDashboardV2: boolean}>());

export const setEnableNewOfflinePayment = createAction(SET_NEW_OFFLINE_PAYMENT, props<{ enableNewOfflinePayment: boolean }>());

export const setChangePaymentToOnline = createAction(SET_CHANGE_PAYMENT_TO_ONLINE, props<{ changePaymentToOnlineEnabled: boolean }>());

export const setEnableOnetrustPolicy = createAction(SET_ONETRUST, props<{ enableOnetrustPolicy: boolean }>());

export const setEnableCaptchaCheck = createAction(SET_CAPTCHA_CHECK, props<{ enableCaptchaCheck: boolean }>());

export const Logout = createAction(LOGOUT);
