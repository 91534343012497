import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tips-loader',
  templateUrl: './tips-loader.component.html',
  styleUrls: ['./tips-loader.component.scss']
})
export class TipsLoaderComponent {
  @Input() msg: string;

}
