import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { IPayload } from 'shared/models/splunk';
import { generateUuidV4 } from 'shared/utils/string-utils';
import { XhrDetailsService } from 'src/app/xhr-details.service';
const appSettings = require('src/assets/appSettings.json');

@Injectable({
  providedIn: 'root'
})
export class SplunkService {
  private readonly SPLUNK_URL = 'https://http-inputs-idpeducation.splunkcloud.com:443/services/collector/event';
  private readonly SPLUNK_AUTH_KEY = appSettings.site.splunkLoggerAuthKey;
  private xhrDetailService: XhrDetailsService;
  private location: LocationStrategy;
  private pathUrl: string;
  private XhrDetails: any[];

  constructor(private injector: Injector) {
    this.location = this.injector.get(LocationStrategy);
    this.xhrDetailService = this.injector.get(XhrDetailsService);

    this.pathUrl = this.location instanceof PathLocationStrategy ? this.location.path() : '';
    this.XhrDetails = this.xhrDetailService.getMostRecentXHRs();
  }

  logger(payload: IPayload, error: any): void {
    try {
      if (window.location.hostname !== 'localhost') {
        this.send(payload);
      }
      else {
        console.error(error);
      }
    } catch (splunkError: any) {
      console.error(splunkError);
      console.error(error);
    }
  }

  generatePayload(s: Partial<IPayload>): IPayload {
    return {
      session_key: s.session_key,
      unique_key: s.unique_key || generateUuidV4(),
      source_site: s.source_site,
      host_site: s.host_site || window.location.hostname,
      event_type: s.event_type,
      error_error: s.error_error,
      error_name: s.error_name,
      error_message: s.error_message,
      error_stack: s.error_stack,
      error_url: s.error_url || this.pathUrl,
      environment: s.environment || appSettings.site.environmentName,
      build: s.build || appSettings.site.buildInfo,
      user_agent: s.user_agent || window.navigator.userAgent,
      user_language: s.user_language || window.navigator.language,
      lastXHR: s.lastXHR || this.XhrDetails
    };
  }

  send(data: IPayload, splunkKey?: string): Promise<unknown> {
    return new Promise((resolve, reject) =>
      this.xhr({
        method: 'POST',
        url: this.SPLUNK_URL,
        data: typeof data === 'string' ? data : JSON.stringify({ event: data }),
        headers: {
          Authorization: `Splunk ${this.SPLUNK_AUTH_KEY || splunkKey}`
        },
        success: () => { /* empty */ },
        error: () => { /* empty */ }
      })
    );
  }

  // the following are manual implementation to send error to Splunk as the Splunk SDKs seems to be inconsistent with our dependency.
  // Maybe if we find a newer version of the SDK we can replace these
  private xhr({ method, url, data, headers, success, error }): void {
    fetch(url, {
      method,
      headers: new Headers(headers),
      mode: 'cors',
      redirect: 'error',
      cache: 'no-cache',
      body: data
    }).then(success).catch(error);
  }
}
