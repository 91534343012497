<div class="card-section">
  <h3 class="mt-3 mb-2">The following payment methods are available at this test location, select any to continue. Your test booking will not be confirmed unless you proceed. Payment instructions are available from your profile.</h3>
  <div class="card-wrapper">
    <ul class="nav nav-tabs border-0 flex-column justify-content-center align-items-start">
      <ng-container *ngFor="let option of paymentOptions; index as i">
        <li
          class="nav-item d-flex align-items-start justify-content-center flex-column border m-2 rounded w-100 cursor-pointer"
          [class.with-description]="option.description !== undefined"
          [tabindex]="i+2"
          (keyup)="keyUpEvent($event)"
          (click)="onOptionClick(option)"
        >
          <div class="nav-link d-flex align-items-center gap-4">
            <div [class]="'flex-shrink-0 icon ' + option.type"></div>
            <div class="d-flex flex-column justify-content-center align-items-start pt-1">
              <div class="ms-1 text-bold text-body text-start">{{ option.label }}</div>
              <div class="sub-copy ps-1 pb-2 me-3 text-muted text-start">
                {{ option.description }}
              </div>
            </div>
          </div>
        </li>
        <button [tabindex]="i+2" type="button" *ngIf="!!option.instructions"
        (click)="$event.stopPropagation(); viewOptionsInstructions(instructions)"
        (keyup)="$event.stopPropagation()"
        class="sub-copy ps-1 pb-2 me-3 text-decoration-underline bg-transparent border-0 z-3 align-buttonContent">View
        payment instructions</button>
      <ng-template #instructions let-offcanvas>
        <div *ngIf="!hasModalInstructions" class="offcanvas-header">
          <h5 class="offcanvas-title m-0" id="offcanvasLabel">Payment instructions for {{ option.label }}</h5>
          <button type="button" class="btn-close" (click)="$event.stopPropagation(); offcanvas.dismiss()"
            aria-label="Close"></button>
        </div>
        <div [ngClass]="hasModalInstructions ? 'modal-body' : 'offcanvas-body'">
          <div [innerHTML] = "option.instructions"></div>
        </div>
        <div [ngClass]="hasModalInstructions ? 'modal-footer' : 'offcanvas-footer'">
          <button type="button" class="btn btn-danger" (click)="$event.stopPropagation(); offcanvas.dismiss()">Got
            it</button>
        </div>
      </ng-template>
      </ng-container>
    </ul>
  </div>
</div>