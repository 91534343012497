<div class="modal-header">
  <h4 class="modal-title">Upload consent form</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal(activeModal)">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!consentFormDetail else fileSelected">
    <div class="upload-consent-area" appFileDragDrop (fileDropped)="onFileSelect($event)">
      <div class="icon"><i class="fa fa-file-text fa-2x text-danger" aria-hidden="true"></i></div>
      <button class="btn btn-link text-info leadText" (click)="uploadInput.click()">
        Please upload a file
      </button>
      <p class="text-black text-medium">or</p>
      <p class="text-black text-medium">drag and drop</p>
      <span class="text-gray sub-text">Upload as PDF up to 5MB</span>
    </div>
  </ng-container>
  <div class="note-area mt-3">
    <label for="note">Notes</label>
    <textarea [formControl]="form.get('note')" name="note" id="note" placeholder="Please provide any additional notes or comments related to the underage consent forms (optional)" rows="6" maxlength="1500"></textarea>
  </div>
</div>
<div class="modal-footer">
  <button [disabled]="!form.dirty || !form.valid" type="button" class="btn btn-danger" (click)="onSave()">Save & continue</button>
</div>

<ng-template #fileSelected>
  <div class="uploaded-consent-form">
    <button class="btn upload-info" (click)="uploadInput.click()">
      <span class="icon"><i class="fa fa-file-text fa-2x text-danger" aria-hidden="true"></i></span>
      <span class="file-detail">
        {{consentFormDetail.fileName}}
        <span class="sub-text text-gray">Upload on {{consentFormDetail.fileDate | date}}</span>
      </span>
    </button>
    <button class="btn" (click)="removeFile(); uploadInput.value = ''"><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
  </div>
</ng-template>


<input #uploadInput type="file" (change)="onFileSelect($event.target.files[0])" accept="application/pdf" />
<app-content-modal #confirmation title="Your unsaved changes will be lost"
  [showAccept]="false" rejectText="Cancel" [showReject]="true">