import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { IBookingStoreState } from './booking.reducer';
import { SelectorFunction } from 'store/selector-function.type';

const createBookingSelector = <T, R>(selectMethod: SelectorFunction<IBookingStoreState, R>): MemoizedSelector<any, R> =>
  createSelector(
    (state: { bookingStore: IBookingStoreState }) => state.bookingStore,
    selectMethod
  );

export const selectLrwTestTime: Selector<{ bookingStore: IBookingStoreState }, IBookingStoreState['lrwTestTime']> =
  createBookingSelector((state: IBookingStoreState) => state.lrwTestTime);

export const selectUserUnder18: Selector<{ bookingStore: IBookingStoreState }, boolean> =
  createBookingSelector((state: IBookingStoreState) => state.userUnder18);

export const selectConsentAccepted: Selector<{ bookingStore: IBookingStoreState }, boolean> =
  createBookingSelector((state: IBookingStoreState) => state.consentAccepted);
