<div class="modal-header">
  <button type="button" class="close btn btn-link" aria-label="Close"
          (click)="activeModal.dismiss()">
            <span class="material-icons">
                close
            </span>
  </button>
</div>
<div class="modal-body">
  <div class="camera-container">
    <video #webcam autoplay playsinline></video>
    <canvas #canvas class="d-none"></canvas>
    <div class="camera-actions-wrapper">
      <button class="btn btn-link  btn-flip" (click)="flipCamera()">
                    <span class="material-icons">
                        flip_camera_android
                    </span>
      </button>
      <button class="btn-circle" [disabled]="!enableCameraButton" (click)="takeSnap()"> </button>
      <span></span>
    </div>
  </div>
</div>
