import { Country } from '@idp-education/ors-test-taker-bff-client-v1/model/country';
import { EducationLevel } from '@idp-education/ors-test-taker-bff-client-v1/model/educationLevel';
import { Gender } from '@idp-education/ors-test-taker-bff-client-v1/model/gender';
import { OccupationLevel } from '@idp-education/ors-test-taker-bff-client-v1/model/occupationLevel';
import { OccupationSector } from '@idp-education/ors-test-taker-bff-client-v1/model/occupationSector';
import { Nationality } from '@idp-education/ors-test-taker-bff-client-v1/model/nationality';
import { TestReason } from '@idp-education/ors-test-taker-bff-client-v1/model/testReason';
import { UserProfileResponse } from '@idp-education/ors-test-taker-bff-client-v1/model/userProfileResponse';
import { createAction, props } from '@ngrx/store';
import { OnboardingAddressDetail, OnboardingDetail, OnboardingProfile } from './onboarding.reducer';
import {
  Application,
  GetTestLocationIdentificationTypesItem,
  IdentificationType,
  Language,
  Territory,
} from '@idp-education/ors-test-taker-bff-client-v1';

const SET_RTL = 'setTestReason';
const SET_OL = 'setOccupationLevel';
const SET_EL = 'setEducationLevel';
const SET_G = 'setGender';
const SET_OS = 'setOccupationSector';
const SET_C = 'setCountries';
const SET_CS = 'setCurrentStep';
const SET_N = 'setNationality';
const SET_PFR = 'setProfileForReview';
const SET_IALS = 'setIsAchievedLastStep';
const SET_FL = 'setFirstLanguages';
const SET_PV = 'setProgressValue';
const SET_TS = 'setTotalSteps';
const SET_CSN = 'setCurrentStepName';
const SET_IU = 'setImageUrl';
const SET_IV = 'setImageVersion';
const SET_IT = 'setIdentificationType';
const SET_AIT = 'setAllIdentificationType';
const SET_DE = 'setDetail';
const SET_AD = 'setAddressDetail';
const SET_PO = 'setProfile';

const SET_UP = 'setUserProfile';
const SET_TT = 'setTerritory';
const SET_TLID = 'setTestLocationId';
const SET_SIDT = 'setSelectedIdType';
const SET_OBAPP = 'setOnboardingApplication';
const CLEAR_OS = 'clearOnboardingStore';

export const setOccupationSector = createAction(SET_OS, props<{ list: OccupationSector[] }>());

export const setGender = createAction(SET_G, props<{ list: Gender[] }>());

export const setEducationLevel = createAction(SET_EL, props<{ list: EducationLevel[] }>());

export const setOccupationLevel = createAction(SET_OL, props<{ list: OccupationLevel[] }>());

export const setNationality = createAction(SET_N, props<{ list: Nationality[] }>());

export const setTestReason = createAction(SET_RTL, props<{ list: TestReason[] }>());

export const setCountries = createAction(SET_C, props<{ list: Country[] }>());

export const setCurrentStep = createAction(SET_CS, props<{ currentStep: number }>());

export const setIsAchievedLastStep = createAction(SET_IALS, props<{ isAchievedLastStep: boolean }>());

export const setProgressValue = createAction(SET_PV, props<{ progressValue: number }>());

export const setTotalSteps = createAction(SET_TS, props<{ totalSteps: number }>());

export const setCurrentStepName = createAction(SET_CSN, props<{ currentStepName: string }>());

export const setImageUrl = createAction(SET_IU, props<{ imageUrl: string | false }>());

export const setImageVersion = createAction(SET_IV, props<{ imageVersion: string | false }>());

export const setDetail = createAction(SET_DE, props<{ detail: OnboardingDetail }>());

export const setAddressDetail = createAction(SET_AD, props<{ addressDetail: OnboardingAddressDetail }>());

export const setProfile = createAction(SET_PO, props<{ profile: OnboardingProfile }>());

export const setUserProfile = createAction(SET_UP, props<{ userProfile: UserProfileResponse }>());

export const setFirstLanguages = createAction(SET_FL, props<{ list: Language[] }>());

export const setIdentificationType = createAction(SET_IT, props<{ list: GetTestLocationIdentificationTypesItem[] }>());

export const setAllIdentificationType = createAction(SET_AIT, props<{ list: GetTestLocationIdentificationTypesItem[] }>());

export const setTerritory = createAction(SET_TT, props<{ list: Territory[], countryId: string }>());

export const setTestLocationId = createAction(SET_TLID, props<{ testLocationId: string }>());

export const setSelectedIdType = createAction(SET_SIDT, props<{ selectedIdType: IdentificationType }>());

export const setOnboardingApplication = createAction(SET_OBAPP, props<{ application: Application }>());

export const clearOnboardingStore = createAction(CLEAR_OS);
