<div class="flexbox flex-column content align-items-center align-items-xxl-start">
  <div class="all-content">
    <div class="card-holder page-content">
      <app-circle-sidebar i18n-stTitle [stTitle]="'Speaking test'" [stDate]="state.speakingtesttime" i18n-lrwTitle
        [lrwTitle]="'Listening, Reading and Writing test'" i18n-lrwTitleMd
        [lrwTitleMd]="'Listening, Reading & Writing test'" [lrwDate]="state.lrwtesttime" [showPrice]="false"
        [isNotIOLProduct]="isNotIOLProduct" i18n-circleClass [circleClass]="'red'" i18n-textClass [textClass]="'gray'"
        class="d-block d-lg-none mb-3" [testLocalTimezone]="testLocalTimeZone$ | async">
      </app-circle-sidebar>
      <app-offline-request-content (onRequestComplete)="onRequestComplete()"
        [userName]="getApplicationUserName$ | async"
        [receiptNumber]="receiptNumber$ | async" (backToPayment)="backToPayment()"
        [isNonEOROSRProduct]="true"
        [applicationPaymentId]="applicationPaymentId$ | async"></app-offline-request-content>
    </div>
  </div>
</div>