import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaydollarPaymentService, PaymentStatus } from '@idp-education/ors-paydollar-payment-bff-client-v1';
import { Auth } from 'aws-amplify';
import { PaymentStates } from 'pages/payment/payment.enum';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import * as uuid from 'uuid';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-paydollar-callback',
  templateUrl: './paydollar-callback.component.html',
  styleUrls: ['./paydollar-callback.component.scss']
})
export class PaydollarCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub = new Subscription();
  activatedRouteSub: Subscription;
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private paydollarPaymentService: PaydollarPaymentService
  ) {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.routerSub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
   }

  ngOnInit(): void {
    this.paymentType = localStorage.getItem('paymentType');
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.restoreData();
    });
  
  }

 
  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.payDollarStatus();
  }
  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentNewModalPopup = false;
    this.paymentModalPopup.closeModal(true);
  }

 

  modalpopupDescription(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case PaymentStatus.TIMEOUT:
        this.acceptText = 'Payment Cancelled';
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.acceptText = 'Unauthorised Payment';
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.acceptText = 'Return to My Account';
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  newmodalpopupDescription(status) {
    switch (status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Payment Failed`;
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case PaymentStatus.TIMEOUT:
        this.title = `Payment Cancelled`;
        this.imageUrl = './assets/images/payment-infp.svg';
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.acceptText = 'Ok';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `Payment Unauthorised`;
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
         this.imageUrl = './assets/images/payment-alert.svg';
        this.acceptText = 'Unauthorised Payment';
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Payment Error`;
        this.acceptText = 'Try again';
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  payDollarStatus() {
    this.paydollarPaymentService.configuration.accessToken = this.userToken;
    this.routerSub.add(this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      const receiptNumber = queryParams.get('Ref');
      const signature = queryParams.get('signature');
      const statusPayload = {
        receiptNumber,
        signature,
      };
      const apiCallingSub = this.paydollarPaymentService.getPaymentStatus(uuid.v4(), statusPayload).subscribe(
        response => {
          this.showModal(response.status);
      },
      error => {
          const message = (error.status === 401) ? 'Unauthorised' : '';
          this.showModal(message);
      })
      this.routerSub.add(apiCallingSub)
    }))
   
}

showModal(message) {
  if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(message);
  } else {
      this.modalpopupDescription(message);
  }
}

ngOnDestroy(): void {
  this.activatedRouteSub?.unsubscribe();
  this.routerSub?.unsubscribe();
}

}
