import { Injectable, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  checkShowError(form: FormGroup, fcn: string): boolean {
    const control = form.get(fcn);
    return control.invalid && (control.touched || control.dirty);
  }

  setNudge(renderer: Renderer2, element: HTMLElement, form: FormGroup, fcn: string): void {
    if (this.checkShowError(form, fcn)) {
      renderer.addClass(element, 'nudgeInput');
      setTimeout(() => {
        renderer.removeClass(element, 'nudgeInput');
      }, 1000);
    }
  }

  checkShowErrorField(form: FormGroup, fieldName: string): boolean {
    return form.get(fieldName).invalid
      && (form.get(fieldName).touched || form.get(fieldName).dirty);
  }

  setNudgeField(form: FormGroup, fieldName: string, nudghInput: { [key: string]: boolean }) {
    nudghInput[fieldName] = this.checkShowErrorField(form, fieldName);
    setTimeout(() => {
      nudghInput[fieldName] = false;
    }, 1000);
  }

}
