import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ssr-criteria-summary',
  templateUrl: './ssr-criteria-summary.component.html',
  styleUrls: ['./ssr-criteria-summary.component.scss']
})
export class SsrCriteriaSummaryComponent {
  @Input() location: string;
  @Input() dateRange: string;
  @Output() onEditLocation = new EventEmitter<any>();
  @Output() onEditDate = new EventEmitter<any>();

}
