import { ChangeDetectorRef, Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './route-animations';
import { SharedStateService } from './shared-state.service';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SetLatestPageUrl } from '../booking/store/booking.actions';
import { setCurrentStepName, setTotalSteps, setCurrentStep, setUserProfile, setImageUrl } from './store/onboarding.actions';
import { IState } from './store/onboarding.reducer';
import { setBackAction } from 'src/app/store/global.actions';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Gender, OccupationSector, OccupationLevel, EducationLevel, TestReason, Language, Nationality } from '@idp-education/ors-test-taker-bff-client-v1';

@Component({
  selector: 'app-manage-onboarding',
  templateUrl: './manage-onboarding.html',
  styleUrls: ['./manage-onboarding.scss'],
  animations: [
    slideInAnimation
  ]
})
export class ManageOnboardingComponent implements OnInit, AfterViewChecked, OnDestroy {
  activePage: 'welcome' = 'welcome' as const;
  currentOutlet: 'welcome' = 'welcome' as const;
  state: IState;
  stepRoutes;
  leftSidePanel = {
    hasUser: false,
    userName: '',
    welcome: {
      title: $localize`Welcome to IELTS at Home`,
      subTitle:
      {
        title1: $localize`Let's complete your profile.`,
        title2: $localize`For more information, please see our `
      },
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    selectIdType: {
      title: $localize`Upload your ID`,
      subTitle:
      {
        title1: $localize`We'll check your identity using your ID.`,
        title2: $localize`For more information, please see our `
      },
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    uploadId: {
      title: $localize`Upload your ID`,
      subTitle:
      {
        title1: $localize`We'll check your identity using your ID.`,
        title2: $localize`For more information, please see our `
      },
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    detailId: {
      title: $localize`Enter your ID details`,
      subTitle: {
        title1: $localize`We'll check your identity using your ID.`,
        title2: $localize`For more information, please see our `
      },
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    addressDetail: {
      title: $localize`Confirm your address`,
      subTitle: {
        title1: $localize` `,
        title2: $localize`For more information, please see our `
      },
      action: {
        title: '',
        link: '',
        linkTitle: ''
      }
    },
    profile: {
      title: $localize`Add to your profile`,
      subTitle: {
        title1: $localize`We'll complete your profile once we have a few more details.`,
        title2: $localize`For more information, please see our `
      },
      action: {

      }
    },
    reviewProfile: {
      title: $localize`Your Profile Details`,
      subTitle: {
        title1: $localize`You profile details have been applied to your application and it is now complete.`,
        title3: $localize`To make changes to your profile, navigate to "My account" and then to "My Profile".`,
        title4: $localize`To make changes to your application, navigate to "My Tests" and select "Update Details" from actions bar below the test.`,
      },
      action: {

      }
    }
  };
  sub: Subscription;
  storeSub: Subscription;
  countryList = [];
  genderList: Gender[] = [];
  occupationSectorList: OccupationSector[] = [];
  occupationLevelList: OccupationLevel[] = [];
  educationLevelList: EducationLevel[] = [];
  testReasonList: TestReason[] = [];
  languages: Language[] = [];
  nationalities: Array<Nationality> = [];
  storeImageUrl = true;
  reviewStepNo = 6;
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    public sharedService: SharedStateService,
    private apiService: ApiService,
    private store: Store<{ userStore, bookingStore, onboardingStore }>,
    private userProfileService: UserProfileService) {

    this.store.dispatch(setCurrentStep({ currentStep: 0 }));
    this.store.dispatch(setTotalSteps({ totalSteps: 6 }));
    this.store.dispatch(setCurrentStepName({ currentStepName: '' }));

    this.sub = this.userProfileService.getUserProfile(true).subscribe((uProfile) => {
      if (uProfile) {
        const userName = uProfile.lastName ? `${uProfile.firstName} ${uProfile.lastName}` : `${uProfile.firstName}`;
        this.leftSidePanel.welcome.title = $localize`Hi ${userName}` + '\n' + `Welcome to IDP IELTS`;
        this.leftSidePanel.userName = userName;
        this.leftSidePanel.hasUser = true;
        this.store.dispatch(setUserProfile({ userProfile: uProfile }));
      } else {
        this.store.dispatch(setUserProfile({ userProfile: null }));
      }
    });
    this.storeSub = this.store.select(appState => appState.onboardingStore).subscribe(e => {
      this.state = e;
      if (this.state?.userProfile?.identityDetails?.s3Url && !this.state.imageUrl && this.storeImageUrl) {
        this.store.dispatch(setImageUrl({ imageUrl: this.state.userProfile.identityDetails.s3Url }));
        this.storeImageUrl = false;
      }
    });
    this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
    this.stepRoutes = [
      '/onboarding',
      'onboarding/address-detail',
      'onboarding/profile',
      'onboarding/identity-selection',
      'onboarding/identity-document',
      'onboarding/identity-detail'
    ];

    this.store.dispatch(setBackAction({ BackButtonEnable: true, BackButtonRoute: null }));
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.storeSub.unsubscribe();
  }

  ngOnInit(): void {
    this.getGender();
    this.getCountries();
    this.getOccupationSector();
    this.getOccupationLevel();
    this.getEducationLevel();
    this.getReasonTestList();
    this.getLanguagesList();
    this.getNationalities();
  }

  ngAfterViewChecked() {
    this.activePage = this.currentOutlet;
    this.cdr.detectChanges();
  }

  getState(outlet: RouterOutlet) {
    const temp = outlet && outlet.activatedRouteData && outlet.activatedRouteData.state;
    this.currentOutlet = temp;
    return temp;
  }
  private getLanguagesList() {
    this.apiService.getLanguage.subscribe(values => {
      if (Array.isArray(values)) {
        this.languages = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }

  private getReasonTestList() {
    this.apiService.getTestReason.subscribe(values => {
      if (Array.isArray(values)) {
        this.testReasonList = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }
  private getOccupationLevel() {
    this.apiService.getOccupationLevel.subscribe(values => {
      if (Array.isArray(values)) {
        this.occupationLevelList = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }
  private getEducationLevel() {
    this.apiService.getEducationLevels.subscribe(values => {
      if (Array.isArray(values)) {
        this.educationLevelList = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }
  private getGender() {
    this.apiService.getGender.subscribe(values => {
      if (Array.isArray(values)) {
        this.genderList = [...values
          .filter(i => i.name.toLowerCase() !== 'other')
        ];
      }
    });
  }

  private getOccupationSector() {
    this.apiService.getOccupationSector.subscribe(values => {
      if (Array.isArray(values)) {
        this.occupationSectorList = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }

  getCountries() {
    this.apiService.GetCountries.subscribe(values => {
      if (Array.isArray(values)) {
        this.countryList = this.sharedService.moveOtherToLast([...values]);
      }
    });
  }
  getNationalities() {
    this.apiService.GetNationality.subscribe(values => this.nationalities = values);
  }
}
