import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading-service.service';
import { Store } from '@ngrx/store';
import { GlobalState } from 'store/global.reducer';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent {
  enableNewAccountUI: boolean;

  constructor(public loadingService: LoadingService, private store: Store<{ globalStore: GlobalState }>) {

    this.store.select(appState => appState.globalStore.enableNewAccountUI).subscribe(enableNewAccountUI => {
      this.enableNewAccountUI = enableNewAccountUI;
    });
  }
}
