import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../../shared/services/user-profile.service';
import { catchError, first, map } from 'rxjs/operators';
import { TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1/api/testCentre.service';
import { Store } from '@ngrx/store';
import {
  selectCriteria,
  selectSelectedTest,
  selectSsrComponent,
  selectTestCentreAddress,
  selectTestCentreName
} from '../../../../store/my-tests/my-tests.reducer';
import { setOSRCurrentApplication, setSSRFee, setTestCentreDetail } from '../../../../store/my-tests/my-tests.actions';
import { customDateFormat } from '../../../../shared/utils/custom-date-format';
import { forkJoin, Observable, of } from 'rxjs';
import * as uuid from 'uuid';
import {
  BannedStatus,
  DashboardItem, ProductFee,
  ProductFeeService, TermsAndConditionsResponse, TermsAndConditionsService,
} from '@idp-education/ors-test-taker-bff-client-v1';
import {
  UserProfileService as UserProfileAPIService
} from '@idp-education/ors-test-taker-bff-client-v1';
import { CacheService } from 'src/app/shared/services/cache.service';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { getDuration } from '../util';
import { DateTime } from 'luxon';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-ssr-booking-confirmation',
  templateUrl: './ssr-booking-confirmation.component.html',
  styleUrls: ['./ssr-booking-confirmation.component.scss']
})
export class SsrBookingConfirmationComponent {
  state: any;
  userProfileDetails: any;
  productFee: ProductFee;
  testDetails;
  feeToggle = false;
  termAndConditions = false;
  termAndConditionObject: Observable<TermsAndConditionsResponse>;
  uparrow = './../../../../assets/images/DownArrowRed.svg';
  selectedTest$ = this.store.select(selectSelectedTest);
  ssrComponent$ = this.store.select(selectSsrComponent);
  testFormat$ = this.selectedTest$.pipe(map(test => test ? test.testFormat : ''));
  address$ = this.store.select(selectTestCentreAddress);
  testCentreName$ = this.store.select(selectTestCentreName);
  testType$ = this.selectedTest$.pipe(map(test => test ? ([test.testCategory, test.testModule].join(',')) : ''));
  selectCriteria$ =this.store.select(selectCriteria);
  sameDateErrorMessage = `A booking for your profile already exists during this period. Select "My Tests" to review bookings to select new dates.`;
  @Input() ssrSearchApplication: DashboardItem;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onBookNowClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('sameDateError') sameDateError: ConfirmModalComponent;
  @ViewChild('bannedStatusError') bannedStatusError: ConfirmModalComponent;
  toFix = (num: number) => num ? num.toFixed(2) : '';
  getTestDate$() {
    return this.selectedTest$.pipe(map(test => {
      return DateTime.fromISO(test.testStartLocalDatetime, { setZone: true });
    }));
  }

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private testCentreService: TestCentreService,
    private productFeeService: ProductFeeService,
    private termAndConditionsService: TermsAndConditionsService,
    private cacheService: CacheService,
    private applicationsService: ApplicationsService,
    private userProfileAPIService: UserProfileAPIService,
    private store: Store<{ myTestsStore }>,
  ) {
    this.termAndConditionObject = this.termAndConditionsService.getLatestTermsAndConditions().pipe(first());
    this.userProfileService.getUserProfile(true).pipe(first()).subscribe((uProfile) => {
      this.userProfileDetails = uProfile;
    });
    forkJoin({
      selectedTest: this.store.select(selectSelectedTest).pipe(first()),
      ssrComponent: this.ssrComponent$.pipe(first())
    }).subscribe(({ selectedTest, ssrComponent }) => {
      this.testDetails = {
        ...selectedTest,
        languageSkills: [ssrComponent.substr(0, 1).toUpperCase()]
      };
      if (selectedTest?.testLocation?.address) {
        const address = selectedTest.testLocation.address;
        const append = (info: string | undefined, prefix = '') => {
          if (info === undefined || info === '' || info === null) {
            return '';
          } else {
            return prefix + ' ' + info;
          }
        };
        const addressLine = address.line1 +
          append(address.line2) +
          append(address.line3) +
          append(address.line4);
        this.store.dispatch(setTestCentreDetail({
          testCentreName: selectedTest?.testLocation?.name,
          testCentreAddress: addressLine
        }));
      }
    });
    this.getProductFee();
  }

  getProductFee() {
    this.productFeeService.getTestLocationProductFee(uuid.v4(), this.testDetails?.externalBookableProductId,
      this.testDetails?.testLocation?.externalReferenceId)
      .pipe(map(res => res?.activeFee), first()).subscribe((data) => {
        if (data) {
          this.productFee = data;
          const testFee = {
            amount: data.baseAmount,
            description: 'Test Fee'
          };
          this.productFee.charges = [testFee, ...this.productFee.charges];
          this.store.dispatch(setSSRFee({ ssrFee: data }));
        }
      });
  }

  onFeeToggleClick() {
    if (!this.feeToggle) {
      this.feeToggle = true;
      this.uparrow = '../../../../../assets/images/UpArrowRed.svg';
    } else {
      this.feeToggle = false;
      this.uparrow = '../../../../../assets/images/DownArrowRed.svg';
    }
  }

  openTermAndCondition() {
    if (this.ssrSearchApplication?.productName?.toLocaleLowerCase()?.includes('ukvi')) {
      window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions-ukvi-osr'], {
        queryParams: { tcName: this.ssrSearchApplication.testCentreCode }
      })), '_blank');
    } else {
      this.cacheService.preventStorageEvent();
      localStorage.setItem('checkProduct', 'OSR');
      window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions'])), '_blank');
    }
  }

  onBookNowClicked() {
    this.checkBannedStatus();
  }

  checkBannedStatus() {
    this.selectCriteria$.pipe(first()).subscribe((ssrCriteria) => {
      const selectedDate = ssrCriteria?.fromDate?.split("T")[0];
      this.userProfileAPIService.getBannedStatus(this.userProfileDetails?.userProfileId, { bookingStartDate: selectedDate }).pipe(first(),
      catchError(() => {
        this.createSSRApplication();
        return of(null); 
      })).subscribe(bannedStatusDetails => {
        if (bannedStatusDetails?.bannedStatus === BannedStatus.BANNED) {
          const isMatch = this.checkDateInRange(selectedDate, bannedStatusDetails);
          if (isMatch) {
            this.bannedStatusError.open();
          } else {
            this.createSSRApplication();
          }
        } else {
          this.createSSRApplication();
        }
      });
    })
  }

  checkDateInRange(selectedDate: string, bannedStatusDetails: any): boolean {
    const { startDate, endDate } = bannedStatusDetails.details;
    const selected = new Date(selectedDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return selected >= start && selected <= end;
  }

  navigateToMyAccount() {
    this.router.navigate(['/my-account']);
  }

  createSSRApplication() {
    this.termAndConditionObject.subscribe(data => {
      this.applicationsService.GenerateSSRApplication(this.ssrSearchApplication?.applicationId, this.testDetails, data.id)
        .pipe(first()).subscribe((app) => {
          if (app) {
            this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: app }));
            this.onBookNowClick.emit();
          }
        },
          e => {
            if ((e as string).toLowerCase().includes('active booking available with same test date')) {
              this.sameDateError.open().result.then((result: 'accept' | 'reject') => {
                if (result === 'accept') {
                  this.onCancelClick.emit();
                }
              });
            }
          }
        );
    });
  }

  getTimeString$() {
    return this.selectedTest$.pipe(map((test) => {
      const fromTime = new Date(test?.testStartLocalDatetime);
      return customDateFormat(test?.testLocalTimeZone, 'time', fromTime);
    }));
  }

  getDuration$() {
    return this.ssrComponent$.pipe(map(ssrComponent => getDuration(ssrComponent).toString()));
  }
}
