import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmationType } from '@idp-education/ors-test-taker-bff-client-v1';

@Component({
  selector: 'app-edit-notify-institutions',
  templateUrl: './edit-notify-institutions.component.html',
  styleUrls: ['./edit-notify-institutions.component.scss']
})
export class EditNotifyInstitutionsComponent {
  @Input() form: UntypedFormGroup;
  @Input() isAddROEnabled: boolean;
  score = Array(17).fill(0);
  scoreWithoutAny = Array(17).fill(0);
  _confirmationStatus: Array<{
    key: ConfirmationType,
    value: string,
    visible: boolean
  }> = [
      { key: 'CONDITIONAL', value: 'Only when I achieve the minimum scores', visible: true },
      { key: 'CONFIRMED', value: 'I achieve any score', visible: true },
    ];
  confirmationStatus: any[];
  // tslint:disable-next-line: variable-name
  private _showScores: boolean;
  public get showScores(): any {
    return this._showScores;
  }
  public set showScores(v: any) {
    this._showScores = (v as ConfirmationType) === 'CONDITIONAL';
  }

  constructor() {
    this.score = this.fillScores();
    this.scoreWithoutAny = this.fillScores().slice(1);
    this.confirmationStatus = this._confirmationStatus.filter(i => i.visible);
  }
  private fillScores() {
    let localScore = Array(17).fill(0);
    localScore[0] = 1;
    const roundedLocalScore = localScore.reduce((p, c, index) => {
      localScore[index] = localScore[index - 1] + .5;
    });
    localScore.splice(0, 0, 'Any');
    localScore = localScore.map((score, index) => ({ key: `${index}`, value: score }));
    return localScore;
  }
  hasScore() {
    return !!this.score;
  }
  hasScoreWithoutAny() {
    return !!this.scoreWithoutAny;
  }
}
