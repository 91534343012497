import { Component, Input, OnInit } from '@angular/core';
import { IExpandableBoxItem } from '../../models/components/expandable-box';
@Component({
  selector: 'app-expandable-box',
  templateUrl: './expandable-box.component.html',
  styleUrls: ['./expandable-box.component.scss']
})
export class ExpandableBoxComponent implements OnInit {
  @Input() items: Array<IExpandableBoxItem>;
  @Input() title: string;

  ngOnInit(): void {
    this.items.forEach(x => {
      x.expanded = false;
    });
  }

  ClickOnItem(elemindex: number) {
    this.items[elemindex].expanded = !this.items[elemindex].expanded;
    this.items.forEach((x, index) => {
      if (index !== elemindex) {
        x.expanded = false;
      }
    });
  }
  onKeyPress(e: KeyboardEvent, index: number) {
    if (e.code === 'Enter') {
      this.ClickOnItem(index);
    }
  }
}
