import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedStateService } from '../../my-tests/shared-state.service';

@Component({
  selector: 'app-last-score',
  templateUrl: './last-score.component.html',
  styleUrls: ['./last-score.component.scss']
})
export class LastScoreComponent {
  @Input() applicationId;
  @Input() results: {
    speaking?: number,
    reading?: number,
    listening?: number,
    writing?: number,
    overall?: number,
    speakingStartDateTimeUtc?: string,
    lrwStartDateTimeUtc?: string,
    productName?: string,
    testLocationTimeZone?: string
  } = {};
  @Output() onDownloadResult: EventEmitter<string> = new EventEmitter();
  constructor(private sharedState: SharedStateService) { }

  getTestStartDate(): string {
    return this.sharedState.getTestStartDate(this.results);
  }
}
