const DIACRITIC_UNICODE_RANGE = '\u00c0-\u01bf\u0384-\u0556\u0560-\u058f\u05d1-\u05eb';
export const PASSWORD_REGEXP = new RegExp('^(?=.*[A-Z])(?=.*[a-z ])(?=.*[0-9])(?=.*[@$!%*#?&.,:;"\'+/<=>\\\\\[\\]^_|{}~()\\-]).{8,}$');
export const EMAIL_REGEXP = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const DATE_REGEXP = new RegExp(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$$/);
export const NAME_REGEXP = new RegExp(
  '^(?![-\\s])(?!.*[-\\s]$)(?!.*\\s{2})(?!.*(?:\\.{2,}|--|-\\s|\\s-|\\.\\s|\\s\\.))[a-zA-Z0-9\\s.' +
  DIACRITIC_UNICODE_RANGE +
  '\\\'-]*$'
);
export const IDENTITYNO_REGEXP = new RegExp(/^(?!^[-_ ]+$)[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$/);
export const WHITESPACE_REGEXP = new RegExp(/^\s+|\s\s+|\s+$/);
export const SPL_CHAR_REGEXP = new RegExp(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/);
export const ADDRESS_REGEXP = new RegExp('^[ ]*[a-zA-Z0-9' + DIACRITIC_UNICODE_RANGE + '\\-\\,\\_\\;\\:\\/\\.\\"\\\'\# ]*$');
export const CITY_REGEXP = new RegExp('^[a-zA-Z0-9' + DIACRITIC_UNICODE_RANGE + '][a-zA-Z0-9' + DIACRITIC_UNICODE_RANGE + ' ]*$');
export const POST_CODE_REGEXP = new RegExp(/^(?![-\s])(?!.*[-\s]$)(?!.*[-\s]{2})[-A-Za-z0-9\s ]{0,10}$/);
export const ISSUING_AUTHORITY_REGEXP = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9\-\,\_\;\:\/\.\"\'\# ]*$/);
export const LOCATION_STUDY_ENGLISH_REGEXP = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9\-\,\_\;\:\/\.\"\'\# ]*$/);
export const BX_DATE_REGEX = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
