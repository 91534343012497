import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { base64ToBlob } from 'shared/components/forms/application-document-upload-form/image-transformer';

@Component({
  selector: 'app-crop-modal',
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss']
})
export class CropModalComponent {
  @Input() image?: string;
  croppedImage: string;

  constructor(public activeModal: NgbActiveModal) { }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onSave(): void {
    this.activeModal.dismiss(base64ToBlob(this.croppedImage));
  }

}
