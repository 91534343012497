import { ActionReducer, MetaReducer, StoreModule, StoreRootModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { accountReducer } from './pages/account/store/account.reducer';
import { bookingReducer } from './pages/booking/store/booking.reducer';
import { onboardingReducer } from './pages/onboarding/store/onboarding.reducer';
import { paymentReducer } from './pages/payment/store/payment.reducer';
import { ApplicationsReducer } from './store/applications/application.reducer';
import { DashboardReducer } from './store/dashboard/dashboard.reducer';
import { globalReducer } from './store/global.reducer';
import { userReducer, userRequestReducer } from './store/user/user.reducer';
import { environment } from 'src/environments/environment';
import { LOGOUT } from './store/global.actions';
import { ModuleWithProviders } from '@angular/core';
import { myTestsReducer } from './store/my-tests/my-tests.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from 'store/applications/application.effects';
import { accountV2Reducer } from './pages/account-v2/store/account-v2.reducer';
function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => reducer(action.type === LOGOUT ? undefined : state, action);
}

const metaReducers: MetaReducer<any>[] = [metaReducer];

export const Store: ModuleWithProviders<StoreRootModule> = StoreModule.forRoot({
  paymentStore: paymentReducer,
  bookingStore: bookingReducer,
  globalStore: globalReducer,
  accountStore: accountReducer,
  accountV2Store: accountV2Reducer,
  onboardingStore: onboardingReducer,
  userStore: userReducer,
  userRequestStore: userRequestReducer,
  applicationsStore: ApplicationsReducer,
  dashboardStore: DashboardReducer,
  myTestsStore: myTestsReducer,
  purchaseUnits: paymentReducer
}, {
  metaReducers
});
export const StoreDevTool: ModuleWithProviders<StoreDevtoolsModule> = StoreDevtoolsModule.instrument({
  name: 'NgRx Demo App',
  logOnly: environment.production
});

export const Effects = EffectsModule.forRoot([
  ApplicationEffects
]);
