import { Component, Input } from '@angular/core';
import { getTestTypeLabel } from '../../../util';

@Component({
  selector: 'app-ssr-test-type',
  templateUrl: './ssr-test-type.component.html'
})
export class SsrTestTypeComponent {
  @Input() type = '';
  getType() {
    return getTestTypeLabel(this.type);
  }
}
