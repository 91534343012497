// tslint:disable: variable-name
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { isNull, isNumber, isString, isUndefined } from 'lodash';
import { PubSubService } from '../../services/pub-sub.service';
import { IModel, IState } from '../../models/components/dropdown';
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DropdownComponent
    }
  ]
})
export class DropdownComponent implements ControlValueAccessor, Validator, AfterViewChecked {
  @Input() labelKey = 'label';
  @Input() idKey = 'id';
  @Input() title = '';
  @Input() isOneLine = false;
  @Input('options') set options(v: any[]) {
    try {
      this._options = [...v].map(i => ({
        ...i,
        __isSelected: i.__isSelected !== undefined ? i.__isSelected : false,
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  get options() {
    const removeNewOption = (op: any[]) => op.filter(i => !i.__isNewOption);
    if (!this.searchValue) {
      return removeNewOption(this._options);
    }
    return removeNewOption(this._options.filter(
      option => option[this.labelKey].toLowerCase().includes(this.searchValue.toLowerCase())
    ));
  }
  @Input() placeholder = 'Select';
  @Input() searchControlId = '';
  @Input() showTick = true;
  @Input() addNewOptionId = '';
  @Input() width = 'auto';
  @Input() tooltip = true;
  @Input() nonMandatory = false;
  @Input() set isRequired(v: boolean) {
    this._required = v;
  }
  @Input('model') set model(v: IModel<any> | string) {
    if (isNull(v) || isUndefined(v) || v === '') {
      this.onRemoveModel();
      return;
    } else if (!isNumber(v) && !isString(v) && (!v[this.idKey] || !v[this.labelKey]) && !this.instanceOfIModel(v)) {
      if (this.addNewOptionValue === '' && this._model.label !== '') {
        this._model = null;
      }
      console.warn('option is not correct');
    } else if (isString(v) || isNumber(v)) {
      // tslint:disable-next-line: triple-equals
      const op = this.options.find(i => i[this.idKey] == v);
      if (op && op[this.idKey] && op[this.labelKey]) {
        this._model = {
          Id: op[this.idKey],
          label: op[this.labelKey],
          option: op
        };
      } else {
        console.warn('can not find model in options');
      }
    }
    else if (this.instanceOfIModel(v)) {
      this._model = v as IModel<any>;
    } else {
      this._model = {
        Id: v[this.idKey],
        label: v[this.labelKey],
        option: { ...(v as any) },
      };
    }
    this.selectedAddNewOption = this.selectAddNewOption(v[this.idKey]);
    this.changeModel();
  }
  get model(): IModel<any> | string {
    return this._model;
  }
  @Input() defaultShowError = false;
  @Input() showValidationError = false;
  @Input() inputDisabled = false;
  @Output() onChangeState: EventEmitter<IState> = new EventEmitter();
  @Output() onChangeModel: EventEmitter<IModel<any>> = new EventEmitter();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('addNewOptionsInput') addNewOptionsInput: ElementRef;
  @ViewChild('selectedValue') selectedValue: ElementRef;
  @ViewChild('selectedValueWrapper') selectedValueWrapper: ElementRef;

  touched = false;
  disabled = false;
  control: AbstractControl;
  addNewOptionValue = '';
  isState = false;

  private _selectedAddNewOption = false;
  private _required: boolean;
  private _options = [];
  private _model: IModel<any>;
  private _searchValue: string;
  private dropdownStatus: IState = 'Close';
  private isEclipseEnded = true;
  public dirty = false;
  public focused = this.isFocused();
  constructor(
    private eRef: ElementRef,
    private pubsub: PubSubService,
    private changeDetector: ChangeDetectorRef) {
  }
  ngAfterViewChecked(): void {
    this.focused = this.isFocused();
    this.changeDetector.detectChanges();
  }
  onChange = (model) => { /* empty */ };

  onTouched = () => { /* empty */ };

  writeValue(model: IModel<any>): void {
    this.model = model;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  validate(control: AbstractControl): ValidationErrors {
    const tempModel = control.value;
    this.control = control;
    if (control.errors && control.errors.required !== undefined) {
      if (tempModel) {
        return null;
      } else {
        return {
          required: true,
        };
      }
    }
  }
  private instanceOfIModel(m: any): boolean {
    return 'option' in m && 'Id' in m && 'label' in m;
  }
  private findSelectedModel() {
    const selected = this.options.find(i => i.__isSelected);
    return selected;
  }
  private clearSelected() {
    this.options = this._options.map(i => ({
      ...i,
      __isSelected: false
    }));
  }
  onKeyPress(e: KeyboardEvent) {
    switch (e.code) {
      case 'Escape':
        if (!this.isClose) {
          this.closeDropdown();
        }
        break;
      case 'ArrowDown':
        if (this.isOpen) {
          this.focusOnNextOption();
        }
        break;
      case 'ArrowUp':
        if (this.isOpen) {
          this.focusOnPreviousOption();
        }
        break;
      case 'Enter':
        if (this.isClose) {
          this.pubsub.publish('openDD');
          this.pubsub.subscribe('openDD', this.closeDropdown, this);
          this.openDropdown();
        } else if (this.isOpen) {
          this.model = this.findSelectedModel();
          this.clearSelected();
        }
        break;
      case 'Tab':
        if (this.nonMandatory) {
          this.showTick = true;
          this.isState = true;
        }
        break;
      default:
        break;
    }
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  onRemoveModel() {
    this._model = null;
    this.changeModel();
    this.closeDropdown();
  }
  private changeSelectedOptionsWithArrow(operator: (x: number) => number): any[] {
    const op = [...this.options];
    const optionsLength = op.length;
    const selectedIndex = op.findIndex(i => i.__isSelected);
    if (selectedIndex !== -1 && operator(selectedIndex) < optionsLength && operator(selectedIndex) >= 0) {
      op[selectedIndex].__isSelected = false;
      op[operator(selectedIndex)].__isSelected = true;
    } else if (selectedIndex === -1) {
      op[0].__isSelected = true;
    }
    const temp = this._options.map(o => op.map(a => o[this.idKey] === a[this.idKey] ? { ...a } : { ...o })[0]);
    return temp;
  }
  private focusOnNextOption() {
    this.options = this.changeSelectedOptionsWithArrow(x => x + 1);
  }
  private focusOnPreviousOption() {
    this.options = this.changeSelectedOptionsWithArrow(x => x - 1);
  }
  private openDropdown() {
    this.markAsTouched();
    if (this.inputDisabled) {
      return;
    }
    setTimeout(() => {
      this.searchInput?.nativeElement?.focus();
    }, 10);
    this.dropdownStatus = 'Open';
  }
  private closeDropdown() {
    this.dropdownStatus = 'Close';
    this._searchValue = '';
    if (this.addNewOptionId && this._model?.Id === this.addNewOptionId) {
      this.onRemoveModel();
    }
  }
  private appendDropdown() {
    const addNewOption = [...this._options].find(i => i[this.idKey] === this.addNewOptionId);
    if (addNewOption) {
      this._searchValue = addNewOption[this.labelKey];
    }
    this.dropdownStatus = 'Append';
  }
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    const element: HTMLCollection = (this?.eRef?.nativeElement as HTMLElement)?.getElementsByClassName('dropdown');
    const isClickedOnThisElement = element && element[0] && element[0]?.contains(targetElement);
    const isClickedOnTheOpenArrow = this.isOpen && !targetElement.classList.contains('dropdown-arrow-open');
    const isClickedOnTheAppendInput = this.isAppend && targetElement.classList.contains('append-input');
    if (isClickedOnThisElement && (!this.isOpen || (isClickedOnTheOpenArrow))) {
      if (isClickedOnTheAppendInput) {
        return;
      }
      this.openDropdown();
    } else if (!(targetElement && this.addNewOptionId && targetElement.id === this.addNewOptionId)) {
      this.closeDropdown();
    }
    this.changeState();
  }
  isActive(option): boolean {
    if (!this.model) {
      return false;
    }
    return option[this.idKey] === this.model[this.idKey];
  }
  getNewOption(options) {
    return options.find(data => data.__isNewOption);
  }
  onUpdateNewOption() {
    let newOption = this.getNewOption(this._options);
    if (!newOption) {
      this.options = this.addNewOption(this.addNewOptionValue);
      newOption = this.getNewOption(this._options);
    } else {
      newOption[this.labelKey] = this.addNewOptionValue;
    }
    this.model = newOption;
    (this.model as IModel<any>).extendValue = {
      isNewOption: true,
      addNewOptionsId: this.addNewOptionId,
    };
  }
  eclipseText() {
    if (!this.isEclipseEnded) {
      return;
    }
    this.isEclipseEnded = false;
    setTimeout(() => {
      const innerElement: HTMLElement = (this.selectedValue?.nativeElement as HTMLHtmlElement);
      const wrapperHeight = (this.selectedValueWrapper?.nativeElement as HTMLHtmlElement).offsetHeight;
      let innerHeight = innerElement?.scrollHeight;
      let sourceText = `${this.label}`;
      while (innerElement && wrapperHeight && wrapperHeight <= innerHeight) {
        const tempText = sourceText.replace(/\W*\s(\S)*$/, '...');
        if (tempText !== sourceText) {
          sourceText = tempText;
        } else {
          break;
        }
        innerElement.innerText = sourceText;
        innerHeight = innerElement.scrollHeight;
      }
      this.isEclipseEnded = true;
    }, 10);
  }
  onAddOptionKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.onUpdateNewOption();
      this.closeDropdown();
    } else if ($event.key === 'Escape') {
      this.addNewOptionValue = '';
      this.onRemoveModel();
      this.closeDropdown();
    }
  }
  private selectAddNewOption(id: string): boolean {
    if (!this.addNewOptionId) {
      return false;
    }
    return this.addNewOptionId === id;
  }
  private changeState() {
    if (this.onChangeState) {
      this.onChangeState.emit(this.dropdownStatus);
    }
    this.eclipseText();
  }
  private changeModel() {
    if (this.onChangeModel) {
      this.onChangeModel.emit(this._model as IModel<any>);
    }
    this.onChange(this._model);
    if (this._model?.option?.__isNewOption && this.addNewOptionValue !== '') {
      (this.model as IModel<any>).extendValue = {
        isNewOption: true,
        addNewOptionsId: this.addNewOptionId,
      };
    }
    this.eclipseText();
  }
  private guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }
  private addNewOption(str: string): any {
    let _options = [];
    if (this._options.findIndex(i => i.__isNewOption) !== -1) {
      _options = this._options.map(data => {
        if (data.__isNewOption) {
          return {
            ...data,
            [this.labelKey]: str
          };
        }
        return data;
      });
    } else {
      _options = [
        ...this._options,
        {
          [this.idKey]: this.guidGenerator(),
          [this.labelKey]: str,
          __isNewOption: true,
          __isSelected: false,
        }
      ];
    }
    return _options;
  }
  onSelectOption(option: IModel<any>) {
    this.model = option;
    this.eclipseText();
  }
  get isOpen() {
    return this.dropdownStatus === 'Open';
  }

  get isClose() {
    return this.dropdownStatus === 'Close';
  }
  get hasOptions(): boolean {
    return this._options && !!this._options.length;
  }
  get required() {
    return this._required;
  }
  isFocused() {
    const thisComponent = (this?.eRef?.nativeElement as HTMLElement);
    return document.activeElement === thisComponent || thisComponent.contains(document.activeElement);
  }
  get label() {
    if (this.model) {
      return (this.model as IModel<any>).label;
    }
    return this.placeholder;
  }
  public get searchValue(): string {
    return this._searchValue;
  }
  public set searchValue(v: string) {
    this._searchValue = v;
  }

  public get selectedAddNewOption(): boolean {
    return this._selectedAddNewOption;
  }
  public set selectedAddNewOption(v: boolean) {
    if (v) {
      this.appendDropdown();
      this.options = [...this.addNewOption(this.addNewOptionValue)];
      const newOption = this.getNewOption(this._options);
      if (newOption && newOption[this.labelKey]) {
        this.model = newOption;
      } else {
        this._model = null;
        this.changeModel();
      }
    } else if (!this.isAppend) {
      this.closeDropdown();
    }
    this._selectedAddNewOption = v;
    this.changeState();
  }
  get isAppend(): boolean {
    return this.dropdownStatus === 'Append';
  }
  leaveComponent() {
    this.dirty = true;
    this.focused = this.isFocused();
  }
}
