import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';
import { ICircleSideBarLRW, ICircleSideBarSpeaking } from '../../models/components/circle-sidebar';
import { calculateDuration } from '../../utils/custom-date-format';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-circle-sidebar',
  templateUrl: './circle-sidebar.component.html',
  styleUrls: ['./circle-sidebar.component.scss']
})
export class CircleSidebarComponent implements OnChanges {
  @Input() stTitle: string = null;
  @Input() stDate: ICircleSideBarSpeaking = null;
  @Input() lrwTitle: string = null;
  @Input() lrwTitleMd: string = null;
  @Input() lrwDate: ICircleSideBarLRW = null;
  @Input() circleClass = '';
  @Input() textClass = '';
  @Input() showPrice = false;
  @Input() showPencil = false;
  @Input() productFee: ProductFee;
  @Input() isNotIOLProduct: boolean;
  @Input() testLocalTimezone: string;
  speakingTime: { startTime: string, duration?: string } = { startTime: '' };
  lrwTime: { startTime: string, duration?: string } = { startTime: '' };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stDate?.currentValue) {
      this.speakingTime = { ...this.calculateTime(this.speakingTimeClone, { ...this.stDate }, true) };
    }
    if (changes.lrwDate?.currentValue) {
      this.lrwTime = { ...this.calculateTime(this.lrwTimeClone, { ...this.lrwDate }, false) };
    }
  }

  get speakingTimeClone() {
    return DateTime.fromJSDate(this.stDate.from, { zone: 'utc' }).setZone(this.testLocalTimezone);
  }

  get lrwTimeClone() {
    return DateTime.fromJSDate(this.lrwDate.from, { zone: 'utc' }).setZone(this.testLocalTimezone);
  }

  calculateTime(dateTime: DateTime, date: { to: Date, from: Date }, isSpeaking: boolean): { startTime, duration? } {
    if (this.isNotIOLProduct) {
      const nonIOLTime = dateTime.toFormat('h:mm a').toLowerCase();
      return { startTime: nonIOLTime };
    } else {
      const { startTime, duration } = calculateDuration(
        DateTime.fromJSDate(date.from).toLocal(),
        DateTime.fromJSDate(date.to).toLocal(),
        isSpeaking
      );
      return { startTime, duration };
    }
  }

  getTestDate(test: string, type: string) {
    switch (test) {
      case 'st': {
        if (this.stDate.from instanceof Date) {
          switch (type) {
            case 'month':
              if (this.isNotIOLProduct) {
                return this.speakingTimeClone.toFormat('MMM');
              } else {
                return DateTime.fromJSDate(this.stDate.from).toFormat('MMM');
              }
            case 'day':
              if (this.isNotIOLProduct) {
                return this.speakingTimeClone.toFormat('dd');
              } else {
                return ('0' + this.stDate.from.getDate()).slice(-2);
              }
            case 'year':
              if (this.isNotIOLProduct) {
                return this.speakingTimeClone.toFormat('yyyy');
              } else {
                return this.stDate.from.getFullYear();
              }
          }
        }
        break;
      }
      case 'lrw': {
        if (this.lrwDate.from instanceof Date) {
          switch (type) {
            case 'month':
              if (this.isNotIOLProduct) {
                return this.lrwTimeClone.toFormat('MMM');
              } else {
                return DateTime.fromJSDate(this.lrwDate.from).toFormat('MMM');
              }
            case 'day':
              if (this.isNotIOLProduct) {
                return this.lrwTimeClone.toFormat('dd');
              } else {
                return ('0' + this.lrwDate.from.getDate()).slice(-2);
              }
            case 'year':
              if (this.isNotIOLProduct) {
                return this.lrwTimeClone.toFormat('yyyy');
              } else {
                return this.lrwDate.from.getFullYear();
              }
          }
        }
      }
    }
  }
}
