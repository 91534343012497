import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { generateUtmUrl } from 'shared/utils/utm-params-utils';
const appSettings = require('src/assets/appSettings.json');

@Component({
  selector: 'app-no-booking',
  templateUrl: './no-booking.component.html',
  styleUrls: ['./no-booking.component.scss']
})
export class NoBookingComponent {

  constructor(private router: Router) { }

  bookTestClick(): void {
    window.open(generateUtmUrl(appSettings.site.bxsearchURL), '_blank');
  }
}
