import { Component, Input } from '@angular/core';
import { UtmLinkKeys, generateUtmUrl } from 'shared/utils/utm-params-utils';
@Component({
  selector: 'app-upcoming-test-offline-payment-info',
  templateUrl: './upcoming-test-offline-payment-info.component.html',
  styleUrls: ['./upcoming-test-offline-payment-info.component.scss']
})
export class UpcomingTestOfflinePaymentInfoComponent {
  @Input() showHR = false;
  @Input() customLabel = '';
  @Input() testCentreCode = '';

  openInstructionLink() {
    if (this.testCentreCode) {
      const instructionLink = generateUtmUrl(UtmLinkKeys.BxInstructionUrl, this.testCentreCode);
      window.open(instructionLink, '_blank');
    }
  }

}
