import { UntypedFormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (!control.value || control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            const result = matchingControl.errors && delete matchingControl.errors.mustMatch;
            const keys = Object.keys(matchingControl.errors || {});
            if (Array.isArray(keys) && keys.length) {
                matchingControl.setErrors({
                    ...matchingControl.errors,
                });
            } else {
                matchingControl.setErrors(null);
            }
        }
    };
}

export function MustNotMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        // set error on matchingControl if validation fails
        if (control.value && matchingControl.value && control.value === matchingControl.value) {
            matchingControl.setErrors({
                ...(matchingControl.errors || {}),
                mustNotMatch: true,
            });
        } else {
            const result = matchingControl.errors && delete matchingControl.errors.mustNotMatch;
            const keys = Object.keys(matchingControl.errors || {});
            if (Array.isArray(keys) && keys.length) {
                matchingControl.setErrors({
                    ...matchingControl.errors,
                });
            } else {
                matchingControl.setErrors(null);
            }
        }
    };
}
