<div class="modal-header">
  <button type="button" class="float-end btn btn-link" aria-label="Close"
          (click)="activeModal.dismiss()">
            <span class="material-icons">
                close
            </span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex flex-row-reverse justify-content-between">
    <div class="crop-modal-img-wrapper">
      <image-cropper [imageURL]="image"
                     [aspectRatio]="4 / 3"
                     [hideResizeSquares]="true"
                     [output]="'base64'"
                     format="png"
                     (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-center">
  <button type="button" class="btn btn-singleAction" (click)="onSave()" i18n>Crop</button>
</div>
