<div class="yes-no-question" *ngIf="control">
  <div class="question text-bold mb-2" [class.isRequired]="control.hasError('required')">{{ question }}</div>
  <div class="radio-buttons">
    <div class="form-check form-check-inline" [class.selected]="control?.value === true"
      [class.shadow-sm]="!control?.value" (click)="inputDisabled ? false : control.setValue(true)"
      (keyup)="inputDisabled ? false : control.setValue(true)">
      <input class="form-check-input" type="radio" [name]="'yesNoRadioBtn-' + id" [id]="'radio1-' + id"
        [formControl]="control" [value]="true" [attr.disabled]="inputDisabled ? true : null">
      <label class="form-check-label" [for]="'radio1-' + id">Yes</label>
    </div>
    <div class="form-check form-check-inline" [class.selected]="control?.value === false"
      [class.shadow-sm]="control?.value !== false" (click)="inputDisabled ? false : control.setValue(false)"
      (keyup)="inputDisabled ? false : control.setValue(false)">
      <input class="form-check-input" type="radio" [name]="'yesNoRadioBtn-' + id" [id]="'radio2-' + id"
        [formControl]="control" [value]="false" [attr.disabled]="inputDisabled ? true : null">
      <label class="form-check-label" [for]="'radio2-' + id">No</label>
    </div>
  </div>
</div>