<div class="card-wrapper d-flex">
  <div
    class="w-100 mb-3 position-relative"
    *ngIf="!currentTemplateRef"
    [@inOutAnimationMenu]
  >
    <ul
      class="nav nav-tabs flex-column justify-content-center align-items-start"
      [class.hasNotSelectedTemplate]="!currentTemplateRef"
    >
      <li
        class="nav-item d-flex align-items-start justify-contnet-center flex-column border m-2 rounded w-100 cursor-pointer"
        *ngFor="let item of cardTypesIcon"
        [class.with-description]="item.description !== undefined"
        (click)="changeType(item.key)" (keyup)="changeType(item.key)"
      >
        <a
          href="javascript:void()"
          class="nav-link d-flex"
          [class.active]="
            item.key === currentType || cardTypesIcon?.length === 1"
          [class.offline-payment]="item.key === 'OFFLINE'"
        >
          <div class="d-flex align-items-center justify-content-center">
            <img
              [src]="item.url"
              class="card-icon"
              [alt]="item.alt"
              *ngIf="item.url"
            />
            <i [class]="item.class" class="card-icon" *ngIf="item.class"></i>
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-start pt-1"
          >
            <div class="ms-1 text-bold text-body">{{ item.title }}</div>
            <div
              *ngIf="item.description"
              class="sub-copy ps-1 pb-2 me-3 text-muted"
            >
              {{ item.description }}
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div
    class="card-content-button position-relative"
    *ngIf="currentTemplateRef"
    [@inOutAnimationContent]
  >
    <button (click)="clearSelectedPayment()" class="btn back-button" *ngIf="cardTypesIcon.length > 1">
      Back to payment options
    </button>
    <ng-template [ngTemplateOutlet]="currentTemplateRef"></ng-template>
  </div>
</div>
