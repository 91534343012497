import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../auth-guard.service';
import { MyAccountComponent } from './my-account.component';

const routes: Routes = [{
  path: '',
  component: MyAccountComponent,
  data: { pagename: 'Account' },
  canActivate: [AuthGuardService],
  children: []
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class MyAccountRoutingModule {

}
