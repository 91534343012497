import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  UserProfileResponse, UserProfileRequest, Gender, UserProfileTitle, ApplicationUserProfileUpdateRequest
} from '@idp-education/ors-test-taker-bff-client-v1';
import { UserProfileService } from 'src/app/shared/services/user-profile.service';
import { IState, IStage, OnboardingProfile } from './store/onboarding.reducer';
import { isNumber, isString } from 'lodash';
import { Store } from '@ngrx/store';
import { setUserDetails, setUserFTime } from 'src/app/store/user/user.actions';
import { IModel } from '../../shared/models/components/dropdown';
import { remove } from 'lodash';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import * as uuid from 'uuid';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { setCurrentStep, setCurrentStepName, setDetail, setProgressValue } from './store/onboarding.actions';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  constructor(
    private userProfileService: UserProfileService,
    private applicationService: ApplicationsService,
    private store: Store<{ onboardingStore }>,
    private titleService: Title,
    private router: Router,
  ) { }

  state: IState;

  saveAndExit(stage: IStage, state: IState, isAppProfile = false): Observable<UserProfileResponse> {
    return new Observable<UserProfileResponse>((observer) => {
      try {
        if (!state.userProfile) {
          observer.error(null);
        }
        let request;
        if (isAppProfile) {
          const applicationUserProfile: ApplicationUserProfileUpdateRequest = {
            ...this.mapperFactory(stage, state),
            id: state.userProfile.userProfileId,
            version: state.application.version
          };
          request = this.applicationService.updateApplicationUserProfile(
            state.application.id, applicationUserProfile);
        } else {
          request = this.userProfileService.updateProfile(
            state.userProfile.userProfileId, this.mapperFactory(stage, state));
        }
        request.subscribe(result => {
          observer.next(result);
          this.store.dispatch(setUserFTime(DateTime.now()));
          this.store.dispatch(setUserDetails(result));
        }, err => {
          console.log(err);
          observer.error(err);
        });
      } catch (error) {
        console.log(error);
        observer.error(error);
      }
    });

  }
  private mapperFactory(stage: IStage, state: IState): UserProfileRequest {
    switch (stage) {
      case 'passport':
        return this.mapperPassport(state);
      case 'detail':
        return this.mapperDetail(state);
      case 'address':
        return this.mapperAddress(state);
      case 'survey':
        return this.mapperSurvey(state);
      case 'profile':
        return this.mapperProfile(state);
      default:
        break;
    }
  }

  private isEqualWithOther(value: IModel<any> | string | number, isOtherAttribute: boolean): string {
    if (!value) {
      return;
    }
    if (value && (value as IModel<any>)?.extendValue && (value as IModel<any>).extendValue?.isNewOption) {
      if (isOtherAttribute) {
        return (value as IModel<any>).label?.trim();
      } else {
        return (value as IModel<any>).extendValue.addNewOptionsId;
      }
    } else {
      if (isOtherAttribute) {
        return undefined;
      } else {
        return isString(value) || isNumber(value) ? `${value}` : (value as IModel<any>).Id;
      }
    }
  }
  private convertGender(gender: IModel<Gender> | string): string {
    if (!gender) {
      return undefined;
    } else if (isString(gender)) {
      return gender.toString();
    }
    return (gender as IModel<Gender>).Id;
  }
  private mapperProfile(state: IState): UserProfileRequest {
    const { profile } = state;
    const detail = this.mapperDetail(state);
    return {
      ...state.userProfile,
      languageId: this.isEqualWithOther(state.profile.firstLanguage, false),
      languageOther: this.isEqualWithOther(state.profile.firstLanguage, true),
      genderId: this.convertGender(profile && profile.gender),
      title: profile && profile.title ? UserProfileTitle[profile.title.Id] : undefined,
      nationalityId: detail?.nationalityId,
      nationalityOther: detail?.nationalityOther,
      marketingDetails: {
        ...detail.marketingDetails,
        countryApplyingToId: this.isEqualWithOther(profile && profile.intendLocation, false),
        countryApplyingToOther: this.isEqualWithOther(profile && profile.intendLocation, true),
        educationLevelId: this.isEqualWithOther(profile && profile.educationLevel, false),
        occupationSectorId: this.isEqualWithOther(this.getOccupationSector(profile), false),
        occupationSectorOther: this.isEqualWithOther(this.getOccupationSector(profile), true),
        occupationLevelId: this.isEqualWithOther(this.getOccupationLevel(profile), false),
        occupationLevelOther: this.isEqualWithOther(this.getOccupationLevel(profile), true),
        yearsOfStudy: this.isEqualWithOther(profile && profile.yearsStudyingEnglish, false),
        testReasonOther: this.isEqualWithOther(profile && profile.reasonTest, true),
        testReasonId: this.isEqualWithOther(profile && profile.reasonTest, false),
        currentlyStudyingEnglishAt: profile && profile.locationStudyEnglish,
      },
      addressDetails: {
        ...this.mapperAddress(state).addressDetails,
      },
      identityDetails: {
        ...this.mapperDetail(state).identityDetails,
      }
    };
  }
  private mapperSurvey(state: IState): UserProfileRequest {
    const { profile } = state;
    const detail = this.mapperDetail(state);
    return {
      ...state.userProfile,
      languageId: this.isEqualWithOther(state.profile.firstLanguage, false),
      languageOther: this.isEqualWithOther(state.profile.firstLanguage, true),
      genderId: this.convertGender(profile && profile.gender),
      title: profile && profile.title ? UserProfileTitle[profile.title.Id] : undefined,
      nationalityId: detail?.nationalityId || state.userProfile?.nationalityId,
      nationalityOther: detail?.nationalityOther || state.userProfile.nationalityOther,
      mobileNumber: (profile?.mobileNumber as any)?.e164Number,
      dateOfBirth: DateTime.fromFormat(profile?.dateOfBirth || '', 'd/M/yyyy').toFormat('yyyy-MM-dd'),
      marketingDetails: {
        ...detail.marketingDetails,
        countryApplyingToId: this.isEqualWithOther(profile && profile.intendLocation, false),
        countryApplyingToOther: this.isEqualWithOther(profile && profile.intendLocation, true),
        educationLevelId: this.isEqualWithOther(profile && profile.educationLevel, false),
        occupationSectorId: this.isEqualWithOther(this.getOccupationSector(profile), false),
        occupationSectorOther: this.isEqualWithOther(this.getOccupationSector(profile), true),
        occupationLevelId: this.isEqualWithOther(this.getOccupationLevel(profile), false),
        occupationLevelOther: this.isEqualWithOther(this.getOccupationLevel(profile), true),
        yearsOfStudy: this.isEqualWithOther(profile && profile.yearsStudyingEnglish, false),
        testReasonOther: this.isEqualWithOther(profile && profile.reasonTest, true),
        testReasonId: this.isEqualWithOther(profile && profile.reasonTest, false),
        currentlyStudyingEnglishAt: profile && profile.locationStudyEnglish,
      },
      addressDetails: {
        ...this.mapperAddress(state).addressDetails,
      },
    };
  }
  private getOccupationLevel(profile: OnboardingProfile): any {
    return profile && profile.occupationLevel;
  }

  private getOccupationSector(profile: OnboardingProfile): any {
    return profile && profile.occupationSector;
  }

  private getImageVersion(profile: OnboardingProfile): any {
    return profile && profile.occupationSector;
  }

  private mapperAddress(state: IState): UserProfileRequest {
    const { addressDetail } = state;

    const getAddress = (address1: string, address2: string): string => {
      if (address1 && !address2) {
        return `${address1}`;
      } else if (address1 && address2) {
        return `${address1} - ${address2}`;
      } else if (!address1 && address2) {
        return `${address2}`;
      } else {
        return '';
      }
    };
    return {
      ...state.userProfile,
      addressDetails: {
        ...(state.userProfile && state.userProfile.addressDetails),
        city: addressDetail && addressDetail.city,
        countryId: addressDetail && addressDetail?.country?.Id,
        postCode: addressDetail && addressDetail.postcode,
        territoryId: addressDetail && addressDetail?.province?.Id,
        streetAddress1: addressDetail && addressDetail.address1,
        streetAddress2: addressDetail && addressDetail.address2
      },
    };
  }

  private mapperDetail(state: IState): UserProfileRequest {
    const { detail, profile, userProfile } = state;

    return {
      ...state.userProfile,
      nationalityId: this.isEqualWithOther(detail && detail.countryOfNationality, false),
      nationalityOther: this.isEqualWithOther(detail && detail.countryOfNationality, true),
      languageId: this.isEqualWithOther(state.profile.firstLanguage, false),
      languageOther: this.isEqualWithOther(state.profile.firstLanguage, true),
      genderId: this.convertGender(profile && profile.gender),
      title: profile && profile.title ? UserProfileTitle[profile.title.Id] : undefined,
      mobileNumber: (profile?.mobileNumber as any)?.e164Number,
      dateOfBirth: DateTime.fromFormat(profile?.dateOfBirth || '', 'd/M/yyyy').toFormat('yyyy-MM-dd'),
      identityDetails: {
        ...this.mapperPassport(state).identityDetails,
        expiryDate: detail?.expiryDate ? DateTime.fromFormat(detail.expiryDate || '', 'dd/MM/yyyy').toFormat('yyyy-MM-dd') : undefined,
        issuingAuthority: detail && detail.issuingAuthority,
        number: detail && detail.identityNo ? detail.identityNo : 'NA',
        identificationTypeId: this.getIdentityId(detail && detail.identityTypeId, state),
      },
      addressDetails: {
        ...this.mapperAddress(state).addressDetails,
      },
      marketingDetails: {
        ...userProfile.marketingDetails,
        countryApplyingToId: this.isEqualWithOther(profile && profile.intendLocation, false),
        countryApplyingToOther: this.isEqualWithOther(profile && profile.intendLocation, true),
        educationLevelId: this.isEqualWithOther(profile && profile.educationLevel, false),
        occupationSectorId: this.isEqualWithOther(this.getOccupationSector(profile), false),
        occupationSectorOther: this.isEqualWithOther(this.getOccupationSector(profile), true),
        occupationLevelId: this.isEqualWithOther(this.getOccupationLevel(profile), false),
        occupationLevelOther: this.isEqualWithOther(this.getOccupationLevel(profile), true),
        yearsOfStudy: this.isEqualWithOther(profile && profile.yearsStudyingEnglish, false),
        testReasonOther: this.isEqualWithOther(profile && profile.reasonTest, true),
        testReasonId: this.isEqualWithOther(profile && profile.reasonTest, false),
        currentlyStudyingEnglishAt: profile && profile.locationStudyEnglish,
      },
    };
  }
  private mapperPassport(state: IState): UserProfileRequest {
    return {
      ...state.userProfile,
      identityDetails: {
        ...(state.userProfile && state.userProfile.identityDetails),
        s3Url: state.imageUrl ? state.imageUrl : '',
        number: 'NA',
      }
    };
  }

  getOptionObject(id, options, otherFieldValue?, field?): IModel<any> {
    if (id && options.length) {
      let modelObj: IModel<any>;
      if (field === 'title') {
        const filteredOption = options.find(o => o.value.toString() === id);
        modelObj = filteredOption ? {
          Id: filteredOption.value,
          label: filteredOption.name,
          option: filteredOption
        } : null;
      } else {
        const filteredOption = options.find(o => o.id.toString() === id);
        if (filteredOption?.name.toLowerCase() === 'other') {
          if (otherFieldValue) {
            modelObj = otherFieldValue ? {
              Id: uuid.v4(),
              extendValue: {
                isNewOption: true,
                addNewOptionsId: filteredOption.id
              },
              label: otherFieldValue,
              option: filteredOption
            } : null;
          }
        } else {
          modelObj = filteredOption ? {
            Id: filteredOption.id,
            label: filteredOption.name,
            option: filteredOption
          } : null;
        }
      }
      return modelObj;
    } else {
      return null;
    }
  }

  moveOtherToLast(list: Array<{ id: any; name: any }>): Array<any> {
    try {
      if (!list || !Array.isArray(list)) {
        return null;
      }
      const tempList = [...list];
      const temp = remove(tempList, (item: { id: any; name: any }) => {
        return item && item.name && (item.name + '').toLowerCase() === 'other';
      });
      return temp && temp.length ? [...tempList, ...temp] : [...tempList];
    } catch (error) {
      return [];
    }
  }

  getIdentityId(code: string, state: IState) {
    if (code) {
      return state.identificationType.find(i => i.code === code).id;
    } else {
      return undefined;
    }
  }

  patchProvinceModel(form: FormGroup, state: any): void {
    const territoryId = state.userProfile?.addressDetails?.territoryId;
    const countryCode = Object.keys(state.territories)[0];
    if (territoryId && (typeof territoryId === 'string') && countryCode && form.get('province').value !== null) {
      const territoryModel = this.getOptionObject(territoryId, state.territories[countryCode]);
      if (territoryModel) {
        form.get('province').setValue(territoryModel);
      }
    }
  }

  setTitleAndSubscribe(
    title: string,
    form: any,
    currentNumber: number,
    progressNumber: number,
    currentName: string,
    province: boolean,
    isImageUrl: boolean
  ) {
    this.titleService.setTitle(title);
  
    this.store.select('onboardingStore').subscribe((state: IState) => {
      this.state = state;
      if (!isImageUrl && province && Object.keys(this.state?.territories).length) {
        this.patchProvinceModel(form, this.state);
      } else if (isImageUrl && !province && !this.state?.imageUrl) {
        this.router.navigate(['/onboarding']);
      }
    });
  
    this.store.dispatch(setCurrentStep({ currentStep: currentNumber }));
    this.store.dispatch(setProgressValue({ progressValue: progressNumber }));
    this.store.dispatch(setCurrentStepName({ currentStepName: currentName }));
  }
  

  getState(): IState {
    return this.state;
  }
  

  saveAndExitClick(detail: any, state: any, confirmModal: ConfirmModalComponent) {
    // Dispatch action to update detail in the store
    this.store.dispatch(setDetail({ detail }));

    // Open confirm modal and handle result
    return new Promise<void>((resolve, reject) => {
      confirmModal.open().result.then((result: 'accept' | 'reject') => {
        if (result === 'reject') {
          reject(); // User rejected the confirmation
        } else {
          // Call saveAndExit method from sharedState service
          this.saveAndExit('detail', state).subscribe(
            () => {
              // On successful save, navigate to my-account with state
              this.router.navigate(['/my-account'], {
                state: {
                  test: 'test'
                }
              });
              resolve(); // Resolve the promise
            },
            (err) => {
              alert($localize`Sorry, Saving failed, please try again`);
              reject(err); // Reject with error
            }
          );
        }
      });
    });
  }

}

