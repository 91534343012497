import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SharedStateService } from './../shared-state.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { setCurrentStep, setCurrentStepName, setImageUrl, setImageVersion, setProgressValue } from '../store/onboarding.actions';
import { IState } from '../store/onboarding.reducer';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { Storage } from 'aws-amplify';
import { Title, Meta } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
type IFacingMode = 'user' | 'environment';
@Component({
  selector: 'app-identity-document',
  templateUrl: './identity-document.component.html',
  styleUrls: ['./identity-document.component.scss']
})
export class IdentityDocumentComponent implements OnInit, OnDestroy {
  state: IState;
  sub: Subscription;
  imageUrl = '';
  clearImageUrlCheck = true;
  @ViewChild('saveAndExitModal') confirmModal: ConfirmModalComponent;

  constructor(
    private sharedState: SharedStateService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<{ onboardingStore }>,
    private titleService: Title, private metaTagService: Meta
  ) {
    this.titleService.setTitle('Upload photo ID | IDP IELTS');
    this.sub = this.store.select(appState => appState.onboardingStore).subscribe(async (e) => {
      this.state = e;
      if (e?.imageUrl) {
        this.imageUrl = e.imageUrl;
      }
      if (this.clearImageUrlCheck) {
        // checking whether the user selected identity type and already saved identity type in user profile are same
        const identityTypeIdCheck = this.state?.selectedIdType?.id === this.state?.userProfile?.identityDetails?.identificationTypeId;
        if (!identityTypeIdCheck && !this.state.isAchievedLastStep) {
          this.imageUrl = '';
          this.store.dispatch(setImageUrl({ imageUrl: null }));
        } else {
          if (this.state?.userProfile?.identityDetails?.s3Url && !this.state.imageUrl) {
            this.store.dispatch(setImageUrl({ imageUrl: this.state.userProfile.identityDetails.s3Url }));
          }
        }
        this.clearImageUrlCheck = false;
      }
    });

    this.store.dispatch(setCurrentStep({ currentStep: 4 }));
    this.store.dispatch(setProgressValue({ progressValue: 64 }));
    this.store.dispatch(setCurrentStepName({ currentStepName: 'Identity Details' }));
  }
  async getPhoto(key: string): Promise<string> {
    const photo = await Storage.get(key) as string;
    return photo;
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }


  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'description', content: 'Book your IELTS test with IDP.' },
    );
    if (this.state?.isAchievedLastStep) {
      this.router.navigate(['/onboarding/review-profile']);
    }
  }
  modifiedS3Url(imageUrl: string) {
    if (imageUrl?.length > 0) {
      const indx = imageUrl.lastIndexOf('/');
      const fileName = imageUrl.substring(indx + 1);
      return fileName;
    }
    return null;
  }
  uploadedImage(e) {
    this.store.dispatch(setImageUrl({ imageUrl: e.URL }));
    this.store.dispatch(setImageVersion({ imageVersion: e.amzVersion }));
  }

  deletedImage() {
    this.store.dispatch(setImageUrl({ imageUrl: false }));
  }
  onNextClick() {
    this.router.navigate(['/onboarding/identity-detail']);
  }

  saveAndExitClick() {
    this.confirmModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'accept') {
        this.sharedState.saveAndExit('passport', this.state).subscribe(() => {
          this.router.navigate(['/my-account'], {
            state: {
              test: 'test'
            }
          });
        }, (err) => {
          this.toastr.error(
            'Sorry, Saving failed, please try again'
          );
        });
      }
    });
  }
}
