import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { noop } from 'lodash';
@Injectable({
    providedIn: 'root'
})
export class CacheService implements OnDestroy {
    constructor(
        private router: Router
    ) {
    }

    ngOnDestroy() {
        this.stop();
    }
    public startEvent(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this), true);
    }

    private storageEventListener(event: StorageEvent) {
        if (localStorage.getItem('preventStorageEvent') === '1') {
            return;
        }
        Auth.currentAuthenticatedUser().then(user => {
            const authPaths = [
                '/account/signin',
                '/account/password/signin',
                '/account/password/reset',
                '/account/password/setup',
                '/account/setup-account',
                '/account/password/success',
            ];
            const isAuthPath = authPaths.some((path) => this.router.url.includes(path));
            /* istanbul ignore else */
            if (isAuthPath) {
                // 0 : done
                // 1 : registering
                // -1: error
                const registering = parseInt(localStorage.getItem('Registering') || '0');
                /* istanbul ignore else */
                if (document.hidden && registering === 0) {
                    window.location.reload();
                }
            }
        }).catch(noop);
    }

    public stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this), true);
    }
    public preventStorageEvent() {
        this.stop();
        localStorage.setItem('preventStorageEvent', '1');
        this.startEvent();
    }
    public resumeStorageEvent() {
        this.stop();
        localStorage.setItem('preventStorageEvent', '0');
        this.startEvent();
    }
}
