<app-account-layout [callToActions]="callToActions" appApplyTabIndexes [userName]="getUserProfileName$ | async">
  <div otherContent>
    <div class="details-section">
      <app-call-to-action *ngIf="showUpdateAlertInfo" [hasCustomMessage]="true">
        <span class="alert-text" i18n>
          Changes made to your profile here will not be reflected in your test(s).
          Go to 
          <a class="text-danger" [routerLink]="['/my-tests']">
            <u>My tests</u>
          </a>
          page to update your application details.
        </span>
      </app-call-to-action>
      <app-information-box type="secondary" [classes]="['d-flex mb-4 align-items-center']"
        icon="fa-info-circle text-danger" *ngIf="showIdAlertInfo">
        <span class="sub-copy alert-text">
          {{ idAlertMsg }}
        </span>
      </app-information-box>
      <div ngbAccordion (shown)="onShow($event)">
        <div ngbAccordionItem="profileInfo" [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton [disabled]="!personalInformationForm"><h2 class="m-0">Profile information</h2></button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div *ngIf="personalInformationForm" class="card-body">
                  <app-dropdown id="title" labelKey="name" idKey="value" i18n-placeholder placeholder="Title"
                    [options]="personalUserTitle" class="tab ms-5" [formControl]="personalInformationForm.get('title')"
                    [showTick]="true" [isRequired]="true" [showValidationError]="true">
                  </app-dropdown>
                  <app-signup-detail-form [form]="personalInformationForm"
                    [selectedcountryISO]="selectedCountryISO" [disableEmail]="true"></app-signup-detail-form>
                  <app-dropdown id="gender" [formControl]="personalInformationForm.get('gender')" labelKey="name"
                    idKey="id" i18n-placeholder placeholder="Gender" [options]="personalGenderList" [showTick]="true"
                    [showValidationError]="true" [isRequired]="true">
                  </app-dropdown>
                  <app-circle-checkbox class="save-check d-block" [form]="personalInformationForm"
                    [control]="'marketingCheckbox'" i18n-title
                    title="I'd like to receive news, offers and promotions from IDP IELTS">
                  </app-circle-checkbox>
                  <app-circle-checkbox class="save-check mt-1 d-block" [form]="personalInformationForm"
                    [control]="'communicationsCheckbox'" i18n-title
                    title="I'd like to receive emails and notifications for IDP study abroad and support services">
                  </app-circle-checkbox>
                  <span class="lable"></span>
                  <app-multi-action-button class="update-btn mt-3" i18n.normalText normalText="Update
                    profile" [disable]="personalInformationForm.pristine || personalInformationForm.invalid"
                    (OnClick)="updatePersonalDetails($event)" i18n.successText successText="Successfully updated"
                    i18n.failedText failedText="Unable to save, please try again later">
                  </app-multi-action-button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="address" [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton [disabled]="!addressDetailsForm"><h2 class="m-0">Address</h2></button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div *ngIf="addressDetailsForm">
                  <app-address-details-form [form]="addressDetailsForm"></app-address-details-form>
                  <app-multi-action-button class="update-btn mt-3" i18n.normalText normalText="Update
                    profile" (OnClick)="updateAddressDetails($event)" i18n.successText
                    successText="Successfully updated" i18n.failedText failedText="Unable to save, please try
                    again later" [disable]="addressDetailsForm.invalid || addressDetailsForm.pristine">
                  </app-multi-action-button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="idDetail" [collapsed]="true">
          <div ngbAccordionHeader>
            <button [disabled]="!identityDetailForm" ngbAccordionButton><h2 class="m-0">ID details</h2></button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div *ngIf="identityDetailForm">
                  <div class="btn-container" *ngIf="!selectedIdType || newIdentity">
                    <button type="button" class="btn btn-tab mb-1" (click)="onIdSelect(id)" i18n tabindex
                      [ngClass]="{'btn-tab-active': selectedIdType?.code === id?.code}"
                      *ngFor="let id of state?.allIdentificationType || []">
                      <span [class]="'icon-' + id.code"></span>
                      Upload {{ id.name }}
                    </button>
                  </div>
                  <div class="ms-5" *ngIf="!selectedIdType">
                    <p class="fw-bold mb-0 font-initial">
                      IELTS Online and tests booked in Australia and Japan will require a Passport
                    </p>
                  </div>

                  <ng-container *ngIf="selectedIdType">
                    <div>
                      <!-- upload file select section -->
                      <app-identity-document-upload-form class="d-block" [imageUrl]="modifiedS3Url(identityImage)"
                        (uploaded)="uploadedIdentityImage($event)" (deleted)="deletedIdentityImage()"
                        *ngIf="newIdentity">
                      </app-identity-document-upload-form>

                      <!-- identity details form -->
                      <app-identity-details-form [form]="identityDetailForm" class="d-block mt-3"
                        [nationalityList]="nationalities" [identityType]="selectedIdType.code"
                        [disableCountryOfNationality]="identityDetailForm.get('countryOfNationality').disabled">
                      </app-identity-details-form>

                      <!-- save changes button -->
                      <app-multi-action-button class="update-btn mt-3" i18n.normalText
                        [disable]="!identityImage || !identityDetailForm.valid"
                        (OnClick)="updateIdentityDetails($event)" i18n.successText successText="Successfully updated"
                        i18n.failedText failedText="Unable to save, please try again later"
                        normalText="Confirm and upload" *ngIf="newIdentity">
                      </app-multi-action-button>

                      <!-- show new identity upload section button -->
                      <app-multi-action-button class="update-btn" i18n.normalText (OnClick)="updateId()"
                        normalText="Update ID" *ngIf="!newIdentity">
                      </app-multi-action-button>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div ngbAccordionItem="ieltsInfo" [collapsed]="true">
          <div ngbAccordionHeader class="m-0">
            <button [disabled]="!surveyForm" ngbAccordionButton><h2 class="m-0">IELTS Information</h2></button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div *ngIf="surveyForm">
                  <div #surveyFormContainer class="survey_form_container">
                    <div class="survey_form-content" #surveyFormContainer>
                      <app-survey-form [form]="surveyForm" [countryList]="countryList || []" [genderList]="[]"
                        [occupationSectorList]="occupationSectorList || []"
                        [occupationLevelList]="occupationLevelList || []"
                        [educationLevelList]="educationLevelList || []" [testReasonList]="testReasonList || []"
                        [languages]="languages || []" [yearsStudyingEnglishList]="studyEnglishYearsList || []"
                        [titleList]="[]" (selectChange)="surveyChange($event)" [hasTitle]="false" [hasGender]="false"
                        [showTitle]='true'>
                      </app-survey-form>
                    </div>
                  </div>
                  <app-multi-action-button class=" update-btn mt-3" i18n.normalText
                    normalText="Update IELTS Information" [disable]="surveyForm.pristine || surveyForm.invalid"
                    i18n.successText successText="Successfully updated" i18n.failedText
                    failedText="Unable to save, please try again later" (OnClick)="updateSurvey($event)">
                  </app-multi-action-button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-account-layout>