import { Component, Input, Renderer2 } from '@angular/core';
import { FormValidatorService } from 'shared/services/form-validator.service';
import { CustomInputComponent } from 'src/app/shared/components/forms/custom-input/custom-input.component';
@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent extends CustomInputComponent {
  @Input() isEditMode = false;
  @Input() options: any[];
  constructor(
    private render: Renderer2,
    private formValidationService: FormValidatorService,
  ) {
    super(render, formValidationService);
  }
  getValue(value) {
    if (value?.value) {
      return value?.value;
      /* istanbul else ignore */
    } else if (value?.option) {
      return value.option.value;
    }
  }
}
