import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IStepModel } from '../models/components/steps';


@Injectable({
  providedIn: 'any'
})
export class StepsService {

  private steps$: BehaviorSubject<IStepModel[]> = new BehaviorSubject([]);

  public set steps(v: IStepModel[]) {
    this.steps$.next(v);
    this.currentStep$.next(this.steps$.value[0]);
  }
  public get steps(): IStepModel[] {
    return this.steps$.value;
  }
  currentStep$: BehaviorSubject<IStepModel> = new BehaviorSubject<IStepModel>(null);

  get lastIndex(): number {
    try {
      const temp = this.steps.map(i => i.stepIndex).sort((a, b) => a - b);
      return temp[temp.length - 1];
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
  setCurrentStep(step: IStepModel): void {
    this.currentStep$.next(step);
  }

  getCurrentStep(): Observable<IStepModel> {
    return this.currentStep$.asObservable();
  }

  setCompleteCurrentStep() {
    const currentIndex = this.currentStep$?.value?.stepIndex;
    this.setCurrentStep({ ...this.currentStep$?.value, isComplete: true });
    this.steps$.next([
      ...this.steps$.value.map(item => ({
        ...item,
        isComplete: item.stepIndex === currentIndex ? true : item.isComplete
      }))
    ]);
  }

  getSteps(): Observable<IStepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;
    if (index + 1 <= this.lastIndex) {
      this.currentStep$.next(this.steps$.value.filter(item => item.stepIndex === index + 1)[0]);
    }
  }

  moveToPrevStep(): void {
    const index = this.currentStep$.value.stepIndex;
    if (index - 1 >= 0) {
      this.currentStep$.next(this.steps$.value.filter(item => item.stepIndex === index - 1)[0]);
    }
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }
  isFirst(): boolean {
    return this.currentStep$.value.stepIndex === 0;
  }
}
