<feature-toggle-provider [features]="featureFlags">
    <div class="notify-content">
        <div style="margin: 0 12px;">
            <div class="flexbox header-box">
                <div class="d-flex align-items-center ms-n4">
                    <button class="btn back-btn-highlight btn-link ms-0 ps-0 mt-3"
                        (click)="onClickBack(onBackButtonClick.emit)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.887" height="22.555"
                            viewBox="0 0 14.887 22.555">
                            <g id="Back_Button" data-name="Back Button" transform="translate(2.5 3.528)">
                                <path id="Path_1" data-name="Path 1" d="M43.086,65.333l-8.859,7.75,8.859,7.75"
                                    transform="translate(-34.227 -65.333)" stroke="#1e1e1e" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="5" style="fill: transparent;" />
                            </g>
                        </svg>
                    </button>
                    <h2 class="underlined-title">How to notify institutions</h2>
                </div>
            </div>
            <div class="title-text">
                You will be able to select up to 50 Institutions to receive your IELTS results electronically.
                To get started <span class="text-imp">select 'Add and notify another institution'</span>. Once you have found your institution you will be able to
                <span class="text-imp">select the minimum scores </span> you want to achieve before we send your results.

            </div>
            <div>
                <h3>
                    <span>Notified institutions:&nbsp;</span>
                    <span class="dark-red">{{ROs && ROs.length}} / 50</span>
                </h3>
            </div>
        </div>

        <div class="content">
            <ngb-accordion #acc [closeOthers]="true" *ngIf="ROs">
                <ngb-panel [cardClass]="'card first-customCard'">
                    <ng-template ngbPanelHeader>
                        <div class="d-flex align-items-center card-header-content card-header">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="../../../../assets/images/info-icon.svg" class="icon-info" alt="info icon">
                                <div class="sub-text ms-2">
                                    <span class="text-imp">Certain institutions are not selectable </span> in the below list.<br>
                                    <ng-container *ngIf ="getProductName() === 'IOL'; else NonIOLDisplay">To share your results with those institutions, please <span class="text-imp">retrieve an electronic
                                        copy of your results </span>
                                    from the <span class="text-imp">'My Tests' </span>page and <span class="text-imp">contact the
                                        institution directly</span>.
                                    </ng-container>
                                <ng-template #NonIOLDisplay>To share your results with those institutions, please <span class="text-imp">contact your Test Centre</span>.
                                </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel *ngFor="let item of ROs;index as i" [id]="'panel-' + i"
                    [cardClass]="'card customCard ' + (item?.status === 'Error' ? 'disabled' : '')">
                    <ng-template ngbPanelHeader>
                        <div class="d-sm-flex justify-content-sm-between align-items-sm-center card-header-content text-break card-header"
                            [id]="item.organisationIdentity.id">
                            <div class="d-sm-flex align-items-sm-center ">
                                <div class="me-3">
                                    <app-status-icons [status]="item?.status"></app-status-icons>
                                </div>
                                <div style="flex: 3; margin-top: -2em;">
                                    <div class="d-flex flex-column">
                                        <a class="link-darker" href="javascript:undefined">
                                            <h5 class="text-medium text-darker" (click)="toggle(acc, i, item)" (keyup)="toggle(acc, i, item)">
                                                {{item.organisationIdentity.name}}
                                            </h5>
                                        </a>
                                        <div class="invalid-feedback Instructional-text d-block">
                                            {{item.organisationIdentity.errorMessage}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-link toggle-button" (click)="toggle(acc, i, item)">
                                <span class="material-icons" *ngIf="acc.isExpanded('panel-'+i)">
                                    <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg"
                                        style="transform: rotate(180deg);" alt="">
                                </span>

                                <span class="material-icons" *ngIf="!acc.isExpanded('panel-'+i)">
                                    <img src="../../../../assets/images/notify-institutions/Chevron-Collapsed.svg"
                                        alt="">
                                </span>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ng-container *featureToggle="'enableNotifyInstitutionsEdit'">
                            <div class="actions mt-n2 d-lg-none d-block d-flex">
                                <a class="btn d-flex align-items-center btn-link" *ngIf="isWithdraw(form.get('status').value) && !editMode"
                                    (click)="onRemove(item)" (keyup)="onRemove(item)">
                                    <span class="material-icons">cancel</span> Withdraw
                                </a>
                                <a class="btn d-flex align-items-center btn-link" *ngIf="isAccessible(form.get('status').value) && !editMode"
                                    (click)="editMode = true" (keyup)="editMode = true">
                                    <span class="material-icons">edit</span> Edit
                                </a>
                            </div>
                        </ng-container>
                        <div class="d-flex flex-row justify-content-between card-body p-0" *ngIf="!editMode">
                            <div class="d-flex flex-column justify-content-center align-items-start">
                                <div class="row-content weight-bold">
                                    <span class='label'>Delivery Status:&nbsp;</span>
                                    <span class="value">{{form.get('status').value}}</span>
                                </div>
                                <div class="row-content weight-bold"
                                    *ngIf="form.get('status').value === 'Delivered' || form.get('status').value === 'Redelivered'">
                                    <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="TRFNO"
                                        title="TRF No:&nbsp;"></app-inline-edit>
                                </div>
                                <div class="row-content weight-bold">
                                    <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="contactName"
                                        title="Contact Name:&nbsp;"></app-inline-edit>
                                </div>
                                <div class="row-content weight-bold">
                                    <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="referenceNumber"
                                        title="Case/Reference Number:&nbsp;"></app-inline-edit>
                                </div>
                                <div class="row-content weight-bold">
                                    <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="confirmationStatus"
                                        title="Send my result when:&nbsp;" [options]="confirmationStatus">
                                    </app-inline-edit>
                                </div>
                                <ng-container *ngIf="form.get('confirmationStatus').value === 'Only when I achieve the minimum scores' || (
                                    form.get('confirmationStatus').value?.key === 'CONDITIONAL'
                                )">
                                    <div class="row-content weight-bold">
                                        <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="overall"
                                            title="Minimum overall:&nbsp;" [options]="this.score"></app-inline-edit>
                                    </div>
                                    <div class="row-content weight-bold">
                                        <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="listening"
                                            title="Minimum listening:&nbsp;" [options]="this.score"></app-inline-edit>
                                    </div>
                                    <div class="row-content weight-bold">
                                        <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="reading"
                                            title="Minimum reading:&nbsp;" [options]="this.score"></app-inline-edit>
                                    </div>
                                    <div class="row-content weight-bold">
                                        <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="writing"
                                            title="Minimum writing:&nbsp;" [options]="this.score"></app-inline-edit>
                                    </div>
                                    <div class="row-content weight-bold">
                                        <app-inline-edit [isEditMode]="editMode" [form]="form" fcn="speaking"
                                            title="Minimum speaking:&nbsp;" [options]="this.score"></app-inline-edit>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container *featureToggle="'enableNotifyInstitutionsEdit'">
                                <div class="actions mt-n2 d-lg-block d-none">
                                    <a class="btn btn-link" *ngIf="isWithdraw(form.get('status').value)"
                                        (click)="onRemove(item)" (keyup)="onRemove(item)">
                                        <span class="material-icons">cancel</span> Withdraw
                                    </a>
                                    <a class="btn btn-link" *ngIf="isAccessible(form.get('status').value) && !editMode"
                                        (click)="editMode = true" (keyup)="editMode = true">
                                        <span class="material-icons">edit</span> Edit
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                        <app-edit-notify-institutions [form]="form" *ngIf="editMode"></app-edit-notify-institutions>
                        <div class="d-flex align-items-end justify-content-end" *ngIf="editMode">
                            <app-two-button (acceptClick)="save(item)" (rejectClick)="rejectClick()"
                                [enableAcceptClick]="form.dirty && form.valid" acceptText="Update" rejectText="Cancel">
                            </app-two-button>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel [cardClass]="'card customCard'" *ngIf="ROs.length < 50">
                    <ng-template ngbPanelHeader>
                        <div class="d-flex align-items-center card-header-content addHeader card-header">
                            <div class="d-flex align-items-center justify-content-center">
                                <a href="javascript:void(0)"
                                    class="d-flex justify-contnet-center align-items-center link-dark"
                                    (click)="OnAddNewRO.emit()" [class.disabled]="ROs && ROs.length >= 50">
                                    <div class="ms-xl-n4 me-4">
                                        <img src="../../../../assets/images/plus-icon.svg" class="icon-plus" alt="plus icon">
                                        <img src="../../../../assets/images/Ellipse 125.svg" class="icon-ellipse" alt="ellipse icon">
                                    </div>
                                    <div class="add-text">
                                        Add and notify another institution
                                    </div>
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <app-confirm-modal title="Your changes will not be saved, proceed?" defaultActiveButton="reject" #ConfirmModal>
        </app-confirm-modal>
        <app-confirm-modal title="Are you sure you want to withdraw?" modalClass="d-contents"
            imageUrl="../../../../assets/images/notify-institutions/siren-on-regular.svg" acceptText="Confirm"
            rejectText="Cancel" imageClass="w-50" defaultActiveButton="reject" (onAcceptClicked)="withdrawRO()"
            #WithdrawConfirmModal>
        </app-confirm-modal>
    </div>
</feature-toggle-provider>
