<div class="wrapper  w-100" [class.disabled]=" disabled">
  <div class="m-2 p-2" *ngIf="items && items.length">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let item of items;index as i">
        <ng-template carouselSlide>
          <app-journey-button [state]="item.status" (onClick)="onItemClick(item)" [clickable]="item.clickAble">
            <span class="text">{{item.description}}</span>
          </app-journey-button>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>