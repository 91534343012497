import { Gender, Nationality } from '@idp-education/ors-test-taker-bff-client-v1';

export interface ISignUp {
    emailAddress: string;
    identityNo: string;
    password: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    genderId: string;
    mobileNumber: any;
    nationalityId: string;
    nationalityOther: string;
    communications: boolean;
    termsAndCondition: boolean;
    expiryDate: string;
    issuingAuthority: string;
    supportService:boolean;
    personalData:boolean
}

export interface Step {
    title: string;
    routes: string[];
    isCurrent: boolean;
    isComplete: boolean;
}


export type AccountV2State = {
    signUpDetails: ISignUp;
    steps: Step[];
};


export function initSteps() {
    return [
        {
          title: 'Select test date',
          routes: ['test-date'],
          isComplete: true,
          isCurrent: false,
        },
        {
          title: 'Account',
          routes: ['email', 'password', 'setup-account', 'incomplete-profile'],
          isComplete: false,
          isCurrent: false,
        },
        {
          title: 'Review',
          routes: ['booking-summary'],
          isComplete: false,
          isCurrent: false,
        },
        {
          title: 'Complete payment',
          routes: ['payment-options', 'booking-confirmation'],
          isComplete: false,
          isCurrent: false,
        },
      ] as Step[];
}
