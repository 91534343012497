import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
@Injectable({
  providedIn: 'root'
})
export class CanActivateService  {

  constructor(private router: Router) { }
  canActivate(
    route?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((observer) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          observer.next(true);
        })
        .catch(() => {
          observer.next(this.router.parseUrl('/account/signin'));
        })
        .finally(() => {
          observer.complete();
        });
    });
  }
}

