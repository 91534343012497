import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/shared/validators/must-match';
import { User } from './../signup/userProfile.model';
import { SharedStateService } from './../shared-state.service';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { CognitoErrorService } from 'src/app/shared/services/cognito-error.service';


@Component({
  selector: 'app-reset-password-new',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordNewPasswordComponent {
  $form: UntypedFormGroup;
  currentMouseOver: string;
  visibility = false;
  passwordType = 'password';
  nudghInput = {};
  stateData = {};
  error = '';
  hasError = false;

  constructor(
    private fb: UntypedFormBuilder,
    private state: SharedStateService,
    public loadingService: LoadingService,
    private cognitoError: CognitoErrorService,
    private router: Router) {
    const user = new User();
    this.state.getState().subscribe(data => {
      this.stateData = data;
      this.$form = this.fb.group({
        password: [data.password || '', user.getValidatorFields('pass')],
        confirmPassword: [data.password || '', user.getValidatorFields('confirmPass')]
      }, { validators: [MustMatch('password', 'confirmPassword')] });
    });
  }
  mouseOver(event: any) {
    const name = event.target.getAttribute('name');
    if (event.target.offsetWidth - event.offsetX < 40) {
      event.target.style.cursor = 'pointer';
      this.currentMouseOver = name.substr(0, 1);
    }
    else {
      event.target.style.cursor = 'auto';
    }
  }
  setNudgh(fieldName: string) {
    this.nudghInput[fieldName] = this.checkShowError(fieldName);
    setTimeout(() => {
      this.nudghInput[fieldName] = false;
    }, 1000);
  }
  checkShowError(fieldName: string): boolean {
    return this.$form.get(fieldName).invalid
      && (this.$form.get(fieldName).touched || this.$form.get(fieldName).dirty);
  }
  changeVisiblity(event) {
    if (event.target.offsetWidth - event.offsetX < 40) {
      this.visibility = !this.visibility;
      this.passwordType = this.visibility ? 'text' : 'password';
    }
  }
  changePassword() {
    let isClicked = true;
    if (this.$form.invalid) {
      return;
    }

    this.state.changeState({
      ...this.stateData,
      password: this.$form.get('password').value,
      validationMessage: ''
    });
    this.loadingService.isLoading.next(true);
    this.state.getState().subscribe(data => {
      if (!isClicked) {
        return;
      }
      if (data.completeForgetPassword) {
        this.router.navigate(['account/reset-password/final']);
        return;
      }
      if (data.code && data.email && data.password) {
        Auth.forgotPasswordSubmit(data.email, data.code, data.password).then(result => {
          this.state.changeState({
            ...data,
            completeForgetPassword: true,
          });
          this.router.navigate(['account/reset-password/final']);
          this.loadingService.isLoading.next(false);
          isClicked = false;

        }).catch(e => {
          this.loadingService.isLoading.next(false);
          this.state.changeState({
            ...data,
            validationMessage: this.cognitoError.getErrorMessage(e)
          });
          this.router.navigate(['account/reset-password/step1']);
          isClicked = false;

        });
      }
    });


  }
}
