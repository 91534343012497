import { ElementRef, Injectable } from '@angular/core';
import {
  UserProfileService as UserProfile,
  UserProfileRequest,
  UserProfileResponse
} from '@idp-education/ors-test-taker-bff-client-v1';
import { catchError, concatMap, map, share, tap } from 'rxjs/operators';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { setAllUser, setRequested } from '../../store/user/user.actions';
import { UserState } from 'src/app/store/user/user.reducer';
import { Observable, throwError, BehaviorSubject, Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { OTHER_OPTION_IDS, USER_SIGNUP_CONSTANTS_V2 } from 'src/app/pages/account/signup/signup.constants';
import { AuthService } from './auth.service';
import { fetchExtension } from 'shared/components/forms/application-document-upload-form/image-utils';
const { signup, requestExpireTime } = require('src/assets/appSettings.json');
import { Storage } from 'aws-amplify';


export const selectUser = createFeatureSelector('userStore');
export const selectUserProfile = createSelector(
  selectUser,
  (selectedUser: UserState) => {
    return { user: selectedUser.userDetails, fTime: selectedUser.userFetchedTime };
  }
);

export const UserRequest = createFeatureSelector('userRequestStore');

export class NotValidUser extends Error {

}
@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  USER_PROFILE = 'userProfile';
  uProfile = null;
  timer;
  private upRequest$: BehaviorSubject<UserProfileResponse> = new BehaviorSubject(null);
  userProfileRequest: Observable<UserProfileResponse> = this.upRequest$.asObservable();
  private fetchUserProfile$: Observable<UserProfileResponse>;

  forceFetch = false;

  private _lastFetchTime: number;
  public get lastFetchTime(): number {
    return this._lastFetchTime;
  }
  private readonly expireTime = requestExpireTime.userProfile;
  // tslint:disable-next-line: variable-name
  private sub = new Subscription();
  constructor(
    private userProfileService: UserProfile,
    private store: Store<{ userStore }>,
    private authenticationService: AuthService) {
    this.upRequest$ = new BehaviorSubject(null);
    this.userProfileRequest = this.upRequest$.asObservable();
    this.sub.add(this.store.select(st => st.userStore).subscribe(data => {
      if (data?.isLogOut) {
        this.clear();
      }
    }));
  }

  public clear() {
    this.sub.unsubscribe();
    this.upRequest$.unsubscribe();
    this.upRequest$ = null;
    this.userProfileRequest = null;
    this.fetchUserProfile$ = null;
  }

  getUserProfile(force = false): Observable<UserProfileResponse> {
    this.forceFetch = force;
    const isTheLastReqExpired = () => {
      return new Date().getTime() - this.lastFetchTime > this.expireTime;
    };
    if (force || (!force && (!this.upRequest$?.value || isTheLastReqExpired()))) {
      if (!this.fetchUserProfile$) {
        this.fetchUserProfile$ = this.fetchUserProfile();
      }
      return this.prepareStore(this.fetchUserProfile$);
    } else if (this.upRequest$.value && !this.userProfileRequest) {
      this.userProfileRequest = this.upRequest$.asObservable();
    }
    return this.prepareStore(this.userProfileRequest);
  }

  private prepareStore(req: Observable<UserProfileResponse>) {
    return req.pipe(
      tap((userProfile) => {
        this.store.dispatch(
          setAllUser({
            userDetails: userProfile,
            userFetchedTime: DateTime.now(),
            userToken: this.authenticationService.token
          }));
      }),
      catchError((e) => {
        this.authenticationService.token = '';
        return throwError(e);
      })
    );
  }
  private fetchUserProfile(): Observable<UserProfileResponse> {
    return this.authenticationService.checkToken().pipe(
      map(user => {
        if (user) {
          return user;
        }
        throwError('User is not valid');
      }),
      catchError((e) => {
        console.log('error');
        this.authenticationService.token = '';
        this.store.dispatch(setAllUser({ userDetails: null, userFetchedTime: null, userToken: null }));
        this.store.dispatch(setRequested({ requested: false }));
        return throwError(e);
      }),
      concatMap((user) => {
        return this.userProfileService.getUserProfile(user.sub);
      }), share(),
      tap(() => this._lastFetchTime = new Date().getTime()),
      tap(data => {
        if (this.upRequest$) {
          this.upRequest$?.next(data);
        }
      })
    );
  }

  insertProfile(userProfileRequest: UserProfileRequest): Observable<UserProfileResponse> {
    return this.userProfileService.insertUserProfile(userProfileRequest);
  }

  public MappedValueToUserProfile(item): UserProfileRequest {
    try {
      let dobObject = DateTime.fromFormat(item.birthDate || '', 'd/M/yyyy');
      if (!dobObject.isValid) {
        dobObject = DateTime.fromFormat(item.birthDate || '', 'd/M/yy');
      }
      return {
        emailAddress: item.email,
        mobileNumber: item.mobileNumber.e164Number,
        firstName: item.firstName != null ? item.firstName : '',
        lastName: item.lastName != null ? item.lastName : '',
        dateOfBirth: dobObject.toFormat('yyyy-MM-dd'),
        marketingDetails: {
          preparationContactPermission: item.termAndCondition2,
          studyContactPermission: item.communicationsCheckbox
        }
      };
    } catch (error) {
      return {} as UserProfileRequest;
    }
  }

  private getPropertyId(user, controlName: string, propertyName: string): string {
    const propertyId =
    user?.[controlName]?.Id ||
    user?.[controlName];
    if (propertyId === OTHER_OPTION_IDS[propertyName]) {
      return USER_SIGNUP_CONSTANTS_V2[propertyName];
    }
    return propertyId;
  }

  public setDefaultValuesToUserProfile(user): UserProfileRequest {
    try {
      let dobObject = DateTime.fromFormat(user.birthDate || '', 'd/M/yyyy');
      const idExpiryDate = user.expiryDate
        ? DateTime.fromFormat(user.expiryDate || '', 'd/M/yyyy').toFormat('yyyy-MM-dd')
        : USER_SIGNUP_CONSTANTS_V2.expiryDate;
      if (!dobObject.isValid) {
        dobObject = DateTime.fromFormat(user.birthDate || '', 'd/M/yy');
      }
      return {
        emailAddress: user.email.toLowerCase(),
        mobileNumber: user.mobileNumber.e164Number,
        firstName: user.firstName != null ? user.firstName : '',
        lastName: user.lastName != null ? user.lastName : '',
        dateOfBirth: dobObject.toFormat('yyyy-MM-dd'),
        title: user.title?.Id,
        genderId: user.gender?.Id,
        addressDetails: {
          city: USER_SIGNUP_CONSTANTS_V2.city,
          countryId: this.getPropertyId(user, 'country', 'countryId'),
          streetAddress1: USER_SIGNUP_CONSTANTS_V2.streetAddress1,
          postCode: '',
          streetAddress2: USER_SIGNUP_CONSTANTS_V2.streetAddress2
        },
        identityDetails: {
          expiryDate: idExpiryDate,
          identificationTypeId: USER_SIGNUP_CONSTANTS_V2.identificationTypeId,
          number: user.identityNo,
          s3Url: signup.s3IdImageUrl || ''
        },
        marketingDetails: {
          preparationContactPermission: user.termAndCondition2 ? true : false,
          studyContactPermission: user.communicationsCheckbox ? true : false,
          countryApplyingToId: USER_SIGNUP_CONSTANTS_V2.countryApplyingToId,
          countryApplyingToOther: USER_SIGNUP_CONSTANTS_V2.countryApplyingToOther,
          currentlyStudyingEnglishAt: USER_SIGNUP_CONSTANTS_V2.currentlyStudyingEnglishAt,
          educationLevelId: USER_SIGNUP_CONSTANTS_V2.educationLevelId,
          occupationLevelId: USER_SIGNUP_CONSTANTS_V2.occupationLevelId,
          occupationLevelOther: USER_SIGNUP_CONSTANTS_V2.occupationLevelOther,
          occupationSectorId: USER_SIGNUP_CONSTANTS_V2.occupationSectorId,
          occupationSectorOther: USER_SIGNUP_CONSTANTS_V2.occupationSectorOther,
          testReasonId: USER_SIGNUP_CONSTANTS_V2.testReasonId,
          testReasonOther: USER_SIGNUP_CONSTANTS_V2.testReasonOther,
          yearsOfStudy: USER_SIGNUP_CONSTANTS_V2.yearsOfStudy,
        },
        languageId: USER_SIGNUP_CONSTANTS_V2.languageId,
        languageOther: USER_SIGNUP_CONSTANTS_V2.languageOther,
        nationalityId: this.getPropertyId(user, 'countryOfNationality', 'nationalityId'),
      };
    } catch (error) {
      return {} as UserProfileRequest;
    }
  }

  updateProfile(profileId: string, profile: UserProfileRequest): Observable<UserProfileResponse> {
    return this.userProfileService.updateUserProfile(profileId, profile);
  }

  fetchSavedExpiryDate(expiryDate) {
    if (expiryDate) {
      return DateTime.fromFormat(expiryDate, 'yyyy-MM-dd').toFormat('dd/MM/yyyy');
    } else {
      return null;
    }
  }

  async getPhoto(key: string): Promise<string> {
    const photo = await Storage.get(key) as string;
    return photo;
  }

  modifiedS3Url(imageUrl: string): string | null {
    if (imageUrl?.length > 0) {
      const indx = imageUrl.lastIndexOf('/');
      const fileName = imageUrl.substring(indx + 1);
      return fileName;
    }
    return null;
  }

  setUploadBoxBackground(uploadBox: ElementRef, imageUrl: string, pdfIcon: string) {
    const backgroundImageUrl = fetchExtension(imageUrl).toLowerCase() !== 'pdf' ? imageUrl : pdfIcon;
    uploadBox.nativeElement.style.backgroundImage = `url('${backgroundImageUrl}')`;
    uploadBox.nativeElement.style.backgroundRepeat = 'no-repeat';
    uploadBox.nativeElement.style.backgroundPosition = 'center';
    uploadBox.nativeElement.style.backgroundSize = 'contain';
  }

  validateUserProfile(validateEmailDetails) {
    return this.userProfileService.userProfileValidate(validateEmailDetails);
  }

}
