import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { Application, CreateReceiptRequest, CreateReceiptResponse, PaymentMethodType, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { NavigationService } from 'shared/services/navigation.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { Store } from '@ngrx/store';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import * as uuid from 'uuid';
import { NewebpayPaymentService, PaymentType } from '@idp-education/ors-newebpay-payment-bff-client-v1';
import { LoadingService } from 'shared/services/loading-service.service';
import { SharedStateService } from 'pages/account/shared-state.service';
@Component({
  selector: 'app-newebpay',
  templateUrl: './newebpay.component.html',
  styleUrls: ['./newebpay.component.scss']
})
export class NewebpayComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;

  static get tab(): ICon {
    return {
      alt: 'newebpay',
      key: 'NEWEBPAY',
      url: '/assets/images/payment/newebPay.svg',
      class: '',
      title: 'Pay online with Credit Card and Convenience Store',
      description: 'Secure payments powered by NewebPay',
    };
  }

  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  formData: FormGroup;
  paymentUrl: string;

  paymentMethods = [];
  cardTitle: string;
  cvsTitle: string;
  tcPaymentMethodId: string;
  paymentSelected: any;
  isPaymentMethod = false;
  isLoading = false;

  constructor(
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder,
    private newebpayPaymentService: NewebpayPaymentService,
    private loadingService: LoadingService,
    private store: Store<{ userStore }>,
    private sharedState: SharedStateService
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.NEWEBPAY) {
        this.onCardClick(paymentDetails.typeVal);
      }
    }));
    this.isPaymentMethod = false;
    this.isLoading = false;
    const tcPaymentMethod = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.NEWEBPAY);
    this.tcPaymentMethodId = tcPaymentMethod?.id;
    this.paymentMethods = tcPaymentMethod?.paymentMethodConfig?.document?.paymentMethodConfiguration?.paymentMethods;
    this.formData = this.formBuilder.group({
      merchantId: new FormControl('', [Validators.required]),
      tradeInfo: new FormControl('', [Validators.required]),
      tradeSha: new FormControl('', [Validators.required]),
      version: new FormControl('', [Validators.required])
    });
    this.getPaymentMethods();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  getPaymentMethods() {
    if (this.paymentMethods?.length) {
      this.paymentMethods?.forEach(method => {
        if (method === PaymentMethodType.CREDITCARD) {
          this.cardTitle = 'Card Payment';
          this.isPaymentMethod = true;
        } else if (method === PaymentMethodType.CVS) {
          this.cvsTitle = 'Convenience Store Payment';
          this.isPaymentMethod = true;
        }
      });
    }
  }

  createReceipt() {
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: this.tcPaymentMethodId,
      paymentMethod: this.paymentSelected
    };

    const postReceipt = this.receiptService.createReceipt(uuid.v4(), createReceiptRequest).subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    });
    this.sub.add(postReceipt);
  }

  onCardClick(paymentType) {
    this.paymentSelected = paymentType === 'card' ? PaymentType.CREDITCARD : PaymentType.CVS;
    this.isLoading = true;
    this.createReceipt();
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.NEWEBPAY);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    this.fetchTradeInfo();
  }

  fetchTradeInfo() {
    this.newebpayPaymentService.configuration.accessToken = this.userState?.userToken;
    const appId = this.applicationId;
    const receiptId = this.receiptResponse.id;
    const postSub = this.newebpayPaymentService.getPaymentTradeInfo(appId, this.paymentSelected, receiptId, uuid.v4()).subscribe(result => {
      this.loadingService.increaseLoadingCounter();
      this.formData.setValue({
        merchantId: result.merchantId,
        tradeInfo: result.tradeInfo,
        tradeSha: result.tradeSha,
        version: result.version
      });
      this.paymentUrl = result.actionUrl;
      const formElement: HTMLFormElement = document.getElementById('newebPay') as HTMLFormElement;
      this.loadingService.decreaseLoadingCounter();
      if (formElement && this.formData.value) {
        formElement.action = this.paymentUrl;
        formElement.submit();
      }
    }, error => {
      this.loadingService.decreaseLoadingCounter();
    });
    this.sub.add(postSub);
  }
}
