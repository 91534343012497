import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-booking-cancelled-options',
  templateUrl: './booking-cancelled-options.component.html',
  styleUrls: ['./booking-cancelled-options.component.scss']
})
export class BookingCancelledOptionsComponent {
  expanded = false;
  @ViewChild('childContainer') childContainer: ElementRef;
  @ViewChild('parentContainer') parentContainer: ElementRef;
  constructor(private renderer: Renderer2,
              public deviceService: DeviceDetectorService) { }


  toggleClick() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.renderer.setStyle(this.parentContainer.nativeElement, 'height', this.childContainer.nativeElement.offsetHeight + 'px');
      setTimeout(() => {
        this.renderer.removeStyle(this.parentContainer.nativeElement, 'height');
      }, 300);
    }else
    {
      this.renderer.setStyle(this.parentContainer.nativeElement, 'height', this.childContainer.nativeElement.offsetHeight + 'px');
      setTimeout(() => {
        this.renderer.setStyle(this.parentContainer.nativeElement, 'height', '0');
      }, 1);
      setTimeout(() => {
        this.renderer.removeStyle(this.parentContainer.nativeElement, 'height');
      }, 300);
    }
  }
}
