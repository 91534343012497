<div class="modal-header">
  <h2 class="modal-title align-self-start mt-0" i18n><span class="text-danger fa fa-file-text me-1" aria-hidden="true"></span> Please complete your consent form with us</h2>
</div>
<div class="modal-body">
  <p i18n>Hi {{userFullName}}, we've noticed that you are under 18 years old. To ensure a smooth test-taking experience on the day, please follow these steps:</p>
  <ol class="pt-2" i18n>
    <li><p>Download the consent form using the provided link.</p></li>
    <li><p>Complete the consent form. This must be signed by a parent or a guardian.</p></li>
    <li><p>Remember to bring the completed consent form with you on test day.</p></li>
  </ol>
  <div class="w-100 pb-4">
    <a class="text-info" [href]="consentFormDownloadLink" target="_blank" i18n>Download the consent form <span class="ms-1 fa fa-file-pdf-o" aria-hidden="true"></span></a>
  </div>
  <div class="d-flex align-items-baseline">
    <input [(ngModel)]="checkboxModel" class="pt-1" type="checkbox" name="consent-accepted" id="consent-accepted">
    <label class="ps-2" for="consent-accepted" i18n>I have read the information provided above and have successfully downloaded the consent form.</label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onBackToMyTests()" i18n>Back to my tests</button>
  <button type="button" class="btn btn-danger" [disabled]="!checkboxModel" (click)="onContinuePayment(checkboxModel)" i18n>Continue with payment</button>
</div>
