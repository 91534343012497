// this store just needed for test and mock mode ,
// we dont need this store in api mode

import { DashboardResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import { createReducer, on } from '@ngrx/store';
import { setDashboard } from './dashboard.action';

export type DashboardIState = {
    dashboardApplications?: DashboardResponse
};

const initialState: DashboardIState = {
};

export const DashboardReducer = createReducer(
    initialState,
    on(setDashboard, (state, payload: { applications: DashboardResponse } | null) => ({
        ...state,
        dashboardApplications: payload ? { ...payload?.applications } : null
    })),
);
