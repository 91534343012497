<div class="nav-content" id="NavBar" #navBar>
  <div class="left-sec">
    <div class="menubtn">
      <input type="checkbox" (click)="toggleMenu()" />
      <span></span>
      <span></span>
      <span></span>
    </div>
    <a class="logo" rel="noopener" [href]="hrefValues.cxUrl" target="_blank" title="IDP IELTS">
      <img src="../../../../assets/images/IELTS.svg" [attr.tabindex]="1" alt="logo">
    </a>
  </div>

  <div class="links" #mobileMenu>
    <ul>
      <li routerLinkActive="active">
        <div class="menu-item-content">
          <a [routerLink]="['/my-account']" [attr.tabindex]="2">My account
            <span></span>
          </a>
        </div>
      </li>
      <li routerLinkActive="active">
        <div class="menu-item-content">
          <a [routerLink]="['/my-tests']" [attr.tabindex]="3">My tests
            <span></span>
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="right-sec">
    <a class="profile" href="#" [attr.tabindex]="5">
      <img src="../../../../assets/images/login-header.svg" alt="user icon" class="d-inline-block align-top">
      <span>{{userName}}</span>
    </a>
    <div class="dropdown" ngbDropdown #myDrop="ngbDropdown">
      <button class="btn dropdown-toggle" type="button" ngbDropdownAnchor (click)="myDrop.toggle()"
        [attr.tabindex]="6"><span></span>
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem [attr.tabindex]="myDrop.isOpen() && 7"
          (click)="openProfilePage()" id="myProfilePage" name="myProfilePage"
          class="btn dropdownoption" i18n>My profile</button>
        <button ngbDropdownItem [attr.tabindex]="myDrop.isOpen() && 8"
          (click)="changePasswordComponent.onChangePassClick()" id="changePassword" name="changePassword"
          class="btn dropdownoption" i18n>Change password</button>
        <button ngbDropdownItem class="btn dropdownoption" (click)="signOut()"
          [attr.tabindex]="myDrop.isOpen() && 9">Sign out</button>
      </div>
    </div>
    <div class="divider"></div>
    <button class="book-button" (click)="bookTestClick()" [attr.tabindex]="10">
      <img src="../../../../assets/images/calendar-header.svg" alt="calendar icon">
      <span class="d-none d-lg-block">Book a test</span>
      <span class="d-block d-lg-none">Book</span>
    </button>
  </div>
</div>
<app-change-password #changePasswordComponent></app-change-password>
