import { createReducer, on } from '@ngrx/store';
import { clearEmailAndPasswordPageValues, setStepComplete, setStepCurrent, setUpAccountInfo, setUpEmailAddress, setupPassword } from './account-v2.action';
import { AccountV2State, initSteps } from './account-v2.model';

const initialState: AccountV2State = {
    signUpDetails: null,
    steps: initSteps(),
};

const reducer = createReducer(
    initialState,
    on(setUpEmailAddress, (state, payload) => ({
        ...state,
        signUpDetails: {
            ...state.signUpDetails,
            emailAddress: payload.emailAddress
        },
    })),
    on(clearEmailAndPasswordPageValues,(state)=>({
        ...state,
        signUpDetails: {
            ...state.signUpDetails,
            emailAddress: '',
            password:'',
            communications: false,
            termsAndCondition:false,
            supportService:false,
            personalData:false
        }
    })),
    on(setupPassword, (state, payload) => ({
        ...state,
        signUpDetails: {
            ...state.signUpDetails,
            ...payload
        },
    })),
    on(setUpAccountInfo, (state, payload) => ({
        ...state,
        signUpDetails: {
            ...state.signUpDetails,
            ...payload.details
        }
    })),
    on(setStepComplete, (state, payload) => ({
        ...state,
        steps: [...state.steps].map(x => {
            return x.routes.includes(payload.route)
            ? { ...x, isComplete: payload.isComplete }
            : x;
        })
    })),
    on(setStepCurrent, (state, payload) => ({
        ...state,
        steps: [...state.steps].map(x => {
            return { ...x, isCurrent: x.routes.includes(payload.route) };
        })
    })),
);

export function accountV2Reducer(state: any, action: any) {
    return reducer(state, action);
}
export { AccountV2State };

