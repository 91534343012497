import {
  set3dsPayment,
  setSSR,
  setLocationMode,
  setUpdateApplicationDetails,
  setEnableOfflineEOR,
  setDownloadEtrf,
  setEnableNewAccountUI,
  setEnableDashboardV2,
  setEnableNewOfflinePayment,
  setEnableOnetrustPolicy,
  setChangePaymentToOnline,
  setEnableCaptchaCheck
} from './../../store/global.actions';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setRequestMode } from 'src/app/store/global.actions';
import { Storage } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { GlobalState } from 'store/global.reducer';
const { featureFlags } = require('src/assets/appSettings.json');
const { site } = require('src/assets/appSettings.json');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  requestMode = 'Mock';
  locationMode = 'Mock';
  enable3DSPayment = false;
  enableSSR = false;
  enableDownloadEtrf = false;
  enableUpdateApplicationDetails = false;
  enableOfflineEOR = false;
  enableNewAccountUI = false;
  enableCaptchaCheck = false;
  enableDashboardV2 = false;
  enableNewOfflinePayment = false;
  enableOnetrustPolicy = false;
  changePaymentToOnlineEnabled = false;
  public featureFlags: any = featureFlags;
  public buildInfo: string = site.buildInfo;
  public environmentName: string = site.environmentName;
  public file;
  public files: Array<{
    fileName: string,
    fileSize?: number,
    address: string
  }> = [];
  list = [{
    id: '1',
    description: 'text 1',
    text: 'Default',
    status: 'default',
  }, {
    id: '2',
    description: 'text 1',
    text: 'Active',
    status: 'active',
  },
  {
    id: '1',
    description: 'text 1',
    text: 'Default',
    status: 'disabled',
  }, {
    id: '2',
    description: 'text 1',
    text: 'Active',
    status: 'text-disabled',
  },
  ];
  public percentage = 0;
  constructor(
    private router: Router,
    private store: Store<{ globalStore: GlobalState }>,
    private toast: ToastrService,
    private loading: LoadingService) {
    this.store.select(appState => appState.globalStore).subscribe(x => {
      this.requestMode = x.requestMode;
      this.locationMode = x.locationMode;
      this.enable3DSPayment = x.enable3DSPayment;
      this.enableSSR = x.enableSSR;
      this.enableUpdateApplicationDetails = x.enableUpdateApplicationDetails;
      this.enableOfflineEOR = x.enableOfflineEOR;
      this.enableDownloadEtrf = x.enableDownloadEtrf;
      this.enableNewAccountUI = x.enableNewAccountUI;
      this.enableCaptchaCheck = x.enableCaptchaCheck;
      this.enableDashboardV2 = x.enableDashboardV2;
      this.enableNewOfflinePayment = x.enableNewOfflinePayment;
      this.enableOnetrustPolicy = x.enableOnetrustPolicy;
      this.changePaymentToOnlineEnabled = x.changePaymentToOnlineEnabled;
    });
  }

  ngOnInit(): void {
    this.fetchFiles();
    localStorage.removeItem('enableDashboardV2');
  }
  getList() {
    return Storage.list('files/');
  }
  public fetchFiles() {
    this.loading.increaseLoadingCounter();
    this.getList().then(({results}) => {
      this.files = results
        .filter(i => i.key?.split('.')?.pop()?.toLowerCase() === 'json')
        .map(i => ({
          address: i.key,
          fileName: this.trim(i.key.split('-').pop()),
          fileSize: i.size
        }));
      this.loading.decreaseLoadingCounter();
    }).catch(err => {
      this.loading.decreaseLoadingCounter();
      console.log(err);
    });
  }

  async getLink(fileName) {
    try {
      return await Storage.get(fileName) as string;
    } catch (error) {
      this.toast.error(error.message || 'Oops |:');
    }
  }
  async copyLink(link) {
    this.loading.increaseLoadingCounter();
    navigator.clipboard.writeText(await this.getLink(link));
    this.loading.decreaseLoadingCounter();
    this.toast.info('Link copied');
  }
  async deleteFile(file) {
    try {
      this.loading.increaseLoadingCounter();
      await Storage.remove(file.address);
      this.fetchFiles();
      this.loading.decreaseLoadingCounter();
      this.toast.success('file removed');
    } catch (err) {
      this.toast.error(err.message || 'something wrong');
    }
  }
  trim(str: string, length = 20) {
    try {
      if (str.length > length) {
        const splittedFileName = str.split('.');
        const extension = splittedFileName.pop();
        const fileName = splittedFileName.join();
        const firstPart = fileName.slice(0, length - (4 + extension.length + 5));
        const lastPart = fileName.slice(fileName.length - (extension.length + 5), fileName.length - extension.length);
        return `${firstPart}... ${lastPart}.${extension}`;
      }
      return str;
    } catch (error) {
      return str;
    }
  }
  uploadFile(file: File) {
    const fileName = `files/${this.guidGenerator()}-${file.name}`;
    this.percentage = 1;
    const uploadObj = Storage.put(fileName, file, { progressCallback: (i) => this.onUploadProgress(i) });
    uploadObj.then((data: { key: string }) => {
      this.fetchFiles();
    }).catch(err => {
      this.toast.error(err?.message || 'Upload failed');
    }).finally(() => {
      this.percentage = 0;
    });
  }
  onUploadProgress(progress) {
    this.percentage = Math.round(progress.loaded / progress.total * 100);
  }
  guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }
  setRequestM(mode) {
    this.store.dispatch(setRequestMode({ requestMode: mode }));
  }
  setLocationM(mode) {
    this.store.dispatch(setLocationMode({ locationMode: mode }));
  }

  set3dsPaymentState(status) {
    this.store.dispatch(set3dsPayment({ enable3DSPayment: status }));
  }

  setSSR(status) {
    this.store.dispatch(setSSR({ enableSSR: status }));
  }

  setUpdateApplicationDetails(status) {
    this.store.dispatch(setUpdateApplicationDetails({ enableUpdateApplicationDetails: status }));
  }

  setEnableOfflineEOR(status) {
    this.store.dispatch(setEnableOfflineEOR({ enableOfflineEOR: status }));
  }
  setDownloadEtrf(status) {
    this.store.dispatch(setDownloadEtrf({ enableDownloadEtrf: status }));
  }

  setEnableNewAccountUI(status: boolean) {
    this.store.dispatch(setEnableNewAccountUI({ enableNewAccountUI: status }));
  }

  setEnableCaptchaCheck(status: boolean) { 
    this.store.dispatch(
      setEnableCaptchaCheck({ enableCaptchaCheck: status }));
  }
  
  setEnableDashboardV2(status: boolean) {
    this.store.dispatch(setEnableDashboardV2({ enableDashboardV2: status}));
    localStorage.setItem('enableDashboardV2', JSON.stringify(status));
    this.enableDashboardV2 = status;
  }

  setEnableNewOfflinePayment(status: boolean) {
    this.store.dispatch(
      setEnableNewOfflinePayment({ enableNewOfflinePayment: status })
    );
  }

  setEnableOnetrustPolicy(status: boolean) {
    this.store.dispatch(
      setEnableOnetrustPolicy({ enableOnetrustPolicy: status }));
  }
  
  setChangeOfflineToOnline(status: boolean) {
    this.store.dispatch(
      setChangePaymentToOnline({ changePaymentToOnlineEnabled: status })
    );
  }

  reload() {
    window.location = '/home' as any;
  }

  goToHome() {
    window.location = '' as any;
  }

}
