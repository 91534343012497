export const isIOCProduct = (productName: string): boolean => {
  const iocProducts = ['IELTS on Computer', 'IELTS UKVI on Computer', 'IELTS SELT on Computer'];
  return iocProducts.some((productType) =>
    productName?.toLowerCase()?.includes(productType.toLowerCase())
  );
};

export const isOSRIOCProduct = (productName: string): boolean => {
  const iocProducts = ['IELTS OSR on Computer', 'IELTS OSR UKVI on Computer', 'IELTS OSR SELT on Computer'];
  return iocProducts.some((productType) =>
    productName?.toLowerCase()?.includes(productType.toLowerCase())
  );
};
