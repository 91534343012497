<button class="btn-blue" [class]="{'failedButton': failed, 'successButton': successed}"
  [attr.disabled]="disable ? 'true' : null" (click)="onclick()" i18n tabindex>
  <span *ngIf="!successed && !failed">
    <span *ngIf="loading" class="fa fa-spinner fa-spin icon"></span>
    {{ normalText }}
  </span>
  <span *ngIf="successed">
    <span class="fa fa-check icon"></span>
    {{ successText }}
  </span>
  <span *ngIf="failed">
    <span class="fa fa-close icon"></span>
    {{ failedText }}
  </span>

</button>