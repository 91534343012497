<div class="stripe-error mb-3">
    {{ stripeErrMsg }}
</div>
<form id="payment-form" [ngClass]="{
    'tt-payment': isFromNewPaymentPage,
    'd-block': showStripeForm, 'd-none': !showStripeForm}">
    <div class="cardbackground">
        <div id="link-authentication-element">
            <!--Stripe.js injects the Link Authentication Element-->
        </div>
        <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
        </div>
    </div>
    <button class="btn btn-multiAction mt-3 mb-3" id="submit" (click)="confirmPayment()" *ngIf="showPayNowBtn">
        <span id="button-text">Pay now</span>
    </button>
</form>
<app-confirm-modal #confirmationModal [title]="title" imageUrl="assets/images/material-error.svg"
    (onClose)="returnMyAccount()" [acceptText]="acceptText" titleClass="" (onAcceptClicked)="returnMyAccount()"
    [showReject]="false" [showCloseIcon]="false">
</app-confirm-modal>