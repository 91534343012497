<form [formGroup]="form">
  <div class="form-container w-100">
    <div class="row sub-copy mb-2">
      <div class="col-2 d-flex align-items-center justify-content-center">
        <img src="../../../../assets/images/payment/3D-Secure.svg" alt="" class="img-content">
      </div>
      <div class="col-10 p-1 margin-align" i18n>IDP uses 3D Secure. For the best possible checkout
        experience we recommend you to provide the below fields:
      </div>
    </div>
    <app-custom-input [fcn]="'mobileNumber'" [form]="form" id='mobile' i18n-placeholder placeHolder="Mobile number"
      [required]="true" [type]="getAutomationTestToggle() ? null : 'phone'" i18n-tooltip tooltip="Your mobile number"
      [errorCMessage]="'Valid mobile number is required'">
    </app-custom-input>
    <app-custom-input [fcn]="'email'" [form]="form" id='email' i18n-placeholder placeHolder="Email address" type="email"
      [required]="true" [validShowTick]="true" [customMessage]="'Valid email is required'">
    </app-custom-input>
  </div>
</form>
