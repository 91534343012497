import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CoreRoutingModule } from './core-routing.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    CoreRoutingModule,
    RouterModule
  ],
  declarations: [
    UnauthorizedComponent,
    ErrorPageComponent,
    NotFoundPageComponent
  ],
  exports: [
    ErrorPageComponent
  ],
  providers: []
})
export class CoreModule { }
