import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import * as worker from 'worker-timers';
import { DateTime } from 'luxon';
import { CalcRemainingTimeService } from '../../../services/calcRemaining-time.service';

@Component({
  selector: 'app-upcoming-test-timer',
  templateUrl: './upcoming-test-timer.component.html',
  styleUrls: ['./upcoming-test-timer.component.scss']
})
export class UpcomingTestTimerComponent implements OnInit, OnDestroy {
  @Input() targetTime: DateTime = null;
  @Input() testIndex: string = null;
  @Input() testType: string = null;
  @Input() visibleTime = true;
  @Input() visibleGetDetailBtn = false;
  @Input() timerClass = '';

  @Output() timeDone = new EventEmitter<object>();
  @Output() onGetDetailClicked = new EventEmitter<void>();
  timerr = null;
  ctime = null;
  ttime: DateTime = null;
  showTime = null;
  @ViewChild('timer') timer: ElementRef;

  constructor(
    private renderer: Renderer2,
    private calcRemainingTime: CalcRemainingTimeService,
  ) {

  }

  onDetailClick() {
    this.onGetDetailClicked.emit();
  }

  ngOnInit(): void {
    this.ctime = DateTime.now().setZone(this.targetTime.zoneName);
    this.ttime = this.targetTime;
    this.checkTimer();
    this.timerr = worker.setInterval(() => {
      this.ctime = DateTime.now().setZone(this.targetTime.zoneName);
      const { asHours, asSeconds } = this.checkTimer();
      this.setColor(asHours, asSeconds);
      if (asSeconds < 1) {
        this.timeDone.emit({ index: this.testIndex, type: this.testType });
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.timerr) {
      worker.clearInterval(this.timerr);
      this.timerr = null;
    }
  }

  checkTimer() {
    const duration = this.ttime.diff(this.ctime, ['hours', 'minutes', 'seconds']);
    const asSeconds = duration.as('seconds');
    const asHours = duration.hours;
    if (asSeconds < 1) {
      this.showTime = null;
      if (this.timerr) {
        worker.clearInterval(this.timerr);
        this.timerr = null;
      }
    } else {
      if (duration.as('days') >= 1) {
        const currentDate: any = new Date(this.ctime.toISODate());
        const targetDate: any = new Date(this.ttime.toISODate());
        const daysDiff = (Math.floor(targetDate - currentDate) / (1000 * 60 * 60 * 24));
        this.showTime = daysDiff + ' day' + (daysDiff === 1 ? '' : 's');
      } else {
        this.showTime = this.calcRemainingTime.getTime(asSeconds);
      }
    }
    return {
      asHours,
      asSeconds
    };
  }

  setColor(asHours: number, asSeconds: number) {
    if (asHours > 1) {
      if (this.timer) {
        this.renderer.addClass(this.timer.nativeElement, 'green');
        this.renderer.removeClass(this.timer.nativeElement, 'red');
      }
    } else {
      if (this.timer) {
        this.renderer.addClass(this.timer.nativeElement, 'red');
        this.renderer.removeClass(this.timer.nativeElement, 'green');
      }
    }
  }
}
