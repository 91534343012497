<app-form-wrapper [hasBackButton]="false">
  <div title>Terms and conditions</div>
  <div subTitle></div>
  <div content class="main-content main-content-display">
    <div class="termsAndCondition">
      <div class="row">
        <div class="col-12 page-header">
          <h2>IELTS Declaration</h2>
        </div>
      </div>
      <div class="bg-theme has-border-top has-border-bottom mb-4 row p-2">
        <div class="col-md-10 col-lg-8 col-print-10 center-block">
          <div class="textalign well">
            <fieldset>
              <legend class="visually-hidden">Terms and conditions hongkong</legend>
              <ol>
                <li>
                  <p class="text-bold"> General Acknowledgements</p>
                  <p class="text-bold"> I acknowledge that…</p>
                  <ol>
                    <li>
                      The IELTS test is jointly owned by British Council, IELTS Australia Pty Ltd (ABN 84 008 664 766), which is wholly owned
                      by IDP Education Ltd (‘IDP: IELTS Australia’) and Cambridge English, part of Cambridge University Press & Assessment),
                      collectively referred to as the IELTS TEST PARTNERS or IELTS PARTNERS.
                    </li>
                    <li>
                      I will abide by these terms and conditions for the IELTS
                      On Computer test.
                    </li>
                    <li>
                      I understand that there may be local terms and conditions
                      that I must comply with and that the test centre will
                      provide details of these on request.
                    </li>
                    <li>
                      I certify that the information in my application is
                      complete, true, and accurate.
                    </li>
                    <li>
                      I acknowledge that I am responsible for taking my test
                      well in advance of any submission deadlines to Recognising
                      Organisations. The IELTS PARTNERS cannot be held
                      responsible if the issuance of test taker results exceeds
                      the normal period given in these terms and conditions.
                    </li>
                    <li>
                      I understand that my IELTS on computer test session may be cancelled or postponed on short notice if technical issues
                      impact on the running of the test. If a session is cancelled for technical reasons, I may choose to transfer to the next
                      available test at the same Test Centre or receive a full refund of the test fee.
                    </li>
                    <li>
                      I understand that my test result cannot be used for United
                      Kingdom Visas and Immigration (UKVI) application purposes
                      and that I will not be eligible for a refund if I
                      subsequently find that I require an IELTS for UKVI test
                      result.
                    </li>
                    <li>
                      I have read and understand IELTS Partner privacy policy
                      and provisions at
                      <a
                        rel="noopener"
                        href="https://ielts.org/legal/privacy-policy"
                        target="_blank"
                      >
                        https://ielts.org/legal/privacy-policy
                      </a>
                    </li>
                    <li>
                      My application may not be processed if my details submitted are incomplete.
                    </li>
                    <li>
                      I understand that these Terms and Conditions apply to the Test Taker taking the IELTS test, and in the case of minors,
                      it must be read and agreed to by the parent or legal guardian responsible for the Test Taker's registration.
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Personal Information</p>
                  <p class="text-bold"> I understand that…</p>
                  <ol>
                    <li>
                      It is necessary for my personal information to be
                      collected and processed in order for me to take the IELTS
                      test, to verify my identity and to receive my results.
                      This personal information is collected and securely stored
                      when I register for and when I take the IELTS test and is
                      used by the IELTS Test Partners for the purpose of the
                      IELTS test.
                    </li>
                    <li>
                      My personal information, including, without limitation,
                      test performance or score data and my test result may be
                      disclosed by the IELTS Test Partners to Recognising
                      Organisations to which I apply and to governments
                      (including visa processing authorities) for the purpose of
                      allowing these organisations to verify my test result.
                    </li>
                    <li>
                      My personal information may be processed in an anonymous form by the IELTS Test Partners for statistical and research
                      purposes for the development of Cambridge University Press and Assessment English (part of the University of Cambridge).
                    </li>
                    <li>
                      My photograph will be taken by the test centre on test day
                      and will appear on my Test Report Form. This photograph
                      will be provided to any Recognising Organisations or
                      government authorities to which I apply, to allow these
                      organisations to verify my test result.
                    </li>
                    <li>
                      I will be required to verify my identity on test day by providing the identity document specified by the test centre. I
                      may also be requested to confirm my identity on test day by having a finger scan taken. The finger scan is taken as a
                      Binary Large Object (BLOB), and no image of my fingerprint will be held. This biometric data will be held for 60 days
                      after my test and will not be disclosed to any entity except the IELTS Test Partners. This is provided that this
                      procedure is permissible under the local legislation.
                    </li>
                    <ol type="1">
                      <li>
                        I will be asked to provide my finger scan. If I am under 18 years
                        of age or I do not consent to a finger scan, I will be required to have my identity manually verified in line with
                        the
                        <a rel="noopener" href="https://www.idp.com/hongkong/pics/?lang=en" target="_blank">Personal Information Collection
                          Statement</a>
                      </li>
                    </ol>
                    <li>
                      My Speaking test will be recorded: audio and/or video. In the event the test does not record I will be required to
                      re-take the Speaking test or a full resit. An observer may attend my Speaking test as part of the examiner monitoring
                      process.
                    </li>
                    <li>
                      I may be filmed for security and quality assurance purposes on test day, before and during the test. The video footage
                      will be for held for 60 days after my test and may be used for audit or investigation purposes. This period may be
                      different in line with local legislation in a limited number of jurisdictions.
                    </li>
                    <li>
                      I can access further details on how the IELTS Test
                      Partners use my personal information (including how long
                      it is retained in different circumstances and how I can
                      exercise my rights) at
                      <a
                        rel="noopener"
                        href="https://www.ielts.org/policy/privacy-policy"
                        target="_blank"
                      >
                        www.ielts.org/policy/privacy-policy.
                      </a>
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Cancellation, refunds, and transfers</p>
                  <p>
                    Detailed policy terms vary according to test centre
                    location. I understand that I should contact my local test
                    centre as relevant for further information.
                  </p>
                  <p>
                    If local consumer protection law in the country in which I
                    have registered to take the IELTS test provides for
                    cancellation and transfer rights which are more favourable
                    than the terms set out in this document, then local consumer
                    protection law will apply.
                  </p>
                  <p>
                    Different policy terms apply to IELTS for UKVI and IELTS
                    Life Skills.
                  </p>
                  <ol>
                    <li>
                      <b>Cancellations</b> <br />
                      I can cancel my IELTS test booking at any time and taking the test by notifying the test centre. Refund policy and any
                      other applicable charges depend upon when I cancel and whether exceptional circumstances apply.
                    </li>
                    <li>
                      <b>Transfers</b> <br />
                      I may select a new test date at any time before the test,
                      and that…
                      <ol>
                        <li>
                          My transfer request may be treated as a cancellation
                          depending on how near to test day I make the request.
                        </li>
                        <li>
                          I must select a new test date, where available, within
                          three months of original test date.
                        </li>
                        <li>
                          If my preferred test date is more than three months
                          after original test date, the transfer will be treated
                          as a cancellation.
                        </li>
                        <li>I can only transfer the same booking once.</li>
                        <li>Administration charges may apply.</li>
                      </ol>
                    </li>
                    <li>
                      <b>Exceptional circumstances and special consideration</b>
                      <ol>
                        <li>
                          If I am unable to attend my test or I am disadvantaged
                          on the test day owing to illness, injury, or another
                          exceptional event outside my control, then I may apply
                          to the test centre for special consideration citing
                          exceptional circumstances. Exceptional circumstances
                          include bereavement, civil unrest, or a domestic
                          crisis. I can find more information on this process
                          <a href="https://ielts.org/take-a-test/booking-your-test/cancellations-refunds-remarks-transfers/special-consideration-owing-to-exceptional-circumstances"
                            target="_blank">here</a>.
                        </li>
                        <li>
                          Under certain circumstances outside IELTS’ control,
                          the test centre may need to cancel my test and
                          transfer my booking to a future date. These
                          circumstances include, but may not be limited to,
                          extreme weather conditions, natural disaster, civil
                          unrest, industrial action, global pandemic , or for
                          reasons of force majeure. In these circumstances, my
                          test centre will provide me with as much notice as
                          possible and give me the choice of either a full
                          refund, or a transfer to a future test date, whichever
                          I prefer.
                        </li>
                        <li>
                          If my test is cancelled or postponed due to other
                          circumstances such as technical failure, venue or
                          environmental factors, the test centre will provide me
                          with as much notice as possible and give me the choice
                          of a refund or a transfer to a future test.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Chargeback Prohibition</b> <br />
                      I agree that I will not dispute or otherwise seek a
                      “chargeback” from the company whose credit card or other
                      method of payment I use to book and pay for the IELTS test
                      or otherwise attempt to reverse payment. If I do so, my
                      IELTS test and/or results may be cancelled, and the IELTS
                      Partners may, at their discretion, refuse to accept future
                      purchases made from accounts on which such chargebacks
                      have been made. The IELTS Partners may prohibit future
                      purchases from the account holder, and from any person who
                      booked an IELTS test and reversed payment.
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Test Integrity, Security, and Malpractice</p>
                  <p class="text-bold"> I understand that…</p>
                  <ol>
                    <li>
                      I must attend all four test skills and answered all sections in order to receive a test result unless I have an
                      authorised exemption as part of Access Arrangements policy.
                    </li>
                    <li>
                      The IELTS Test Partners have a responsibility to all test
                      takers and Recognising Organisations to ensure the highest
                      confidence in IELTS test results. The IELTS Test Partners
                      may therefore be required to withhold test results
                      temporarily or permanently, or to cancel test results
                      which have been issued, if they consider those results to
                      be unreliable for any reason.
                    </li>
                    <li>
                      My test result may not be issued 1-5 days after the test if any of the IELTS Test Partners consider it necessary to
                      review any matter associated with my test or the administration of my test, including making enquiries as to whether I,
                      other test takers or other parties have breached test rules or regulations. I may be required to provide an additional
                      speaking sample for the purposes of assisting any investigation before or after the test. In exceptional circumstances I
                      may be required to re-take one or more IELTS skills.
                    </li>
                    <li>
                      If I am suspected of engaging in any form of malpractice or do anything that might damage the integrity and security of
                      IELTS, I may not receive a test result, my test fee will not be refunded, and I may be banned from taking the IELTS
                      tests for a five-year period in the future. If I am banned, I will receive a notification stating the length of the
                      ban.<br>
                      If I am subject to a ban and I register to take the test, I will not receive a test result and I will not be entitled to
                      a refund. If a result is issued to me while I am subject to a ban, this result will be cancelled, and I will not be
                      entitled to a refund.
                    </li>
                    <li>
                      Details of any proven or suspected malpractice may be provided to Recognising Organisations, including governments
                      (including visa processing authorities) and appropriate regulatory authorities centrally malpractice has been proven and
                      I may be also liable to prosecution.
                    </li>
                    <li>
                      The work I produce in the IELTS test remains the property
                      of the IELTS Test Partners and will not be released to me.
                      In cases of proven or suspected malpractice my work may be
                      provided to relevant authorities.
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Test Day Arrangements and Behaviour</p>
                  <p class="text-bold">
                    I understand and acknowledge that on the day of the test, I
                    must…
                  </p>
                  <ol>
                    <li>
                      Provide valid and original proof of my identity (passport or National Identity Card) bearing at registration. I
                      understand that in some countries other forms of ID are accepted. If in doubt, I should contact the test centre in
                      advance to confirm which type of identity document is accepted. If I am taking the test outside my own country, I must
                      use a passport for identity purposes.
                    </li>
                    <li>
                      Inform the centre of any changes to my identity document
                      before the test date. If I do not do this, I will not be
                      allowed to take the test and I will not be eligible for a
                      refund or transfer.
                    </li>
                    <li>
                      Bring the same valid identity document on test day, to both the Listening, Reading and Writing and Speaking Tests, as
                      the one I used to book my test. If I do not do this, I will not be allowed to take the test and I will not be eligible
                      for a refund or transfer.
                    </li>
                    <li>
                      Arrive at the centre before the scheduled test arrival time. If I arrive late, I will not be allowed to take the test
                      and will not be eligible for a refund or transfer.
                    </li>
                    <li>
                      Allow for my identity to be verified both at test booking
                      and on test day. This may include:
                      <ol>
                        <li>Having my photograph. I will be required to temporarily remove any covering from my face. Any test takers who refuse to
                        have a photograph taken will not be permitted to sit the test and will not be entitled to a <a rel="noopener"
                          href="https://ielts.org/take-a-test/booking-your-test/cancellations-refunds-remarks-transfers" target="_blank">refund and
                          transfer</a>. The
                        photograph taken by the centre will appear on my Test Report Form.
                        </li>
                        <li>Providing a sample of my signature.</li>
                        <li>
                          Having my finger scan taken to create a unique digital Identifier stored as a binary large object, if this procedure is
                          permissible under the local legislation.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Leave all personal belongings in the designated area. The only items I may bring into the test room are my approved
                      identity document, standard pen(s), pencil(s) and eraser(s), and a bottle of water (label-free). All electronic devices
                      and all watches must be left in the belongings area and must be switched off. I may be electronically scanned for
                      devices at any time on the test day. Any test takers who breach these conditions will not receive an IELTS test result
                      or be eligible for a refund or transfer. While the centre will take all reasonable measures to secure my items in the
                      belongings area, they cannot be held responsible for any loss.
                    </li>
                    <p class="text-bold"> I understand that I should…</p>
                    <li>
                      Inform the invigilator on the day of the test if I believe my performance is affected by ill health, or owing to the way
                      the test is delivered, or for any other reason. If I have any issues, concerns or complaints relating to the delivery of
                      the test I can submit a complaint.
                    </li>
                    <li>
                      Leave all test materials in the test room at the end of the test. The test materials include question papers, Speaking
                      booklets, answer sheets and rough paper. Any test taker who attempts to remove test materials from the test room will
                      not receive an IELTS test result.
                    </li>
                    <li>
                      <b>I undertake not to…</b>
                      <ol>
                        <li>
                          Talk to or disturb other test takers once the test has
                          started.
                        </li>
                        <li>
                          Lend anything to, or borrow anything from, another
                          test taker during the test.
                        </li>
                        <li>Eat or smoke in the test room.</li>
                        <li>
                          Leave the test room without the permission of the
                          invigilator.
                        </li>
                        <li>
                          Leave my seat until all test materials have been
                          collected and I have been told I can leave.
                        </li>
                        <li>
                          Engage in any form of malpractice which may damage the
                          integrity and security of the IELTS test. Malpractice
                          includes, but is not limited to attempting to cheat,
                          including notes of any kind from any source;
                          helping another test taker to cheat; impersonating
                          another test taker or having another test taker
                          impersonate me; copying the work of another test
                          taker; disrupting the test in any way; reproducing any
                          part of the test in any medium; attempting to alter
                          the data on the Test Report Form.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Test Results</p>
                  <p class="text-bold"> I understand that…</p>
                  <ol>
                    <li>
                      Results are issued by centres, usually results within 1-5 days after the test. The release of my results may be affected
                      by Public Holidays, in which case the results will usually be released on the next working day after the holiday.
                    </li>
                    <li>
                      I will be able to access a downloadable and printable
                      version of my electronic Test Report Form from the online
                      booking platform I used to register for my test.
                    </li>
                    <li>
                      The test centre does not normally supply paper Test report
                      Forms. If a Recognising Organisation makes it a
                      requirement to receive a paper Test Report Form directly
                      from IELTS then I should submit a request to the test
                      centre where I took the test and provide evidence of this
                      requirement e.g. an email from the organisation or
                      relevant website reference. At their discretion, the test
                      centre will then decide whether to send a paper version of
                      the Test Report Form to the nominated recognising
                      organisation. The test centre may charge a reasonable fee
                      for this service to cover postage and administrative
                      costs.
                    </li>
                    <li>
                      All personal details provided during the registration must match the test Taker’s identification document used for
                      registration and presented at the test location, as these will appear on the Test Taker’s Report Form (TRF). Once
                      registration is complete, I may be charged a fee if I ask you to modify any of this information before we issue the TRF.
                      No modifications can be made after the TRF has been issued.
                    </li>
                    <li>
                      The IELTS Partners may not issue my result within 1-5 days
                      after the test should they decide it is necessary to
                      review any matter associated with my test or the
                      administration of my test. To assist any investigation, I
                      may be required to provide writing and speaking samples.
                      In exceptional circumstances, I may be required to retake
                      one or more IELTS skills.
                    </li>
                    <li>
                      My result may be cancelled after it has been issued if any
                      irregularity is identified. I may be required to re-take
                      one or more IELTS skills.
                    </li>
                    <li>
                      My result will be disclosed to the Recognising
                      Organisations I nominate at time of booking or to whom I
                      send my Test Report Form. This is done for the purpose of
                      allowing these organisations to verify my test result or
                      to carry out any enquiries in relation to suspected
                      malpractice.
                    </li>
                    <li>
                      If any of the data on the Test Report Form provided by me
                      or my agent to Recognising Organisations has been altered
                      in any way, that test result will be cancelled, and I may
                      be banned from taking any IELTS test for a
                      five-year period.
                    </li>
                    <li>
                      I understand that there may be video recording and CCTV
                      (Closed Circuit TV) in the test centre. I agree to be
                      videoed while I am in the test centre on the test day,
                      including during check-in procedures and in each of the
                      test rooms used for each part of the test. I understand
                      that video recordings will be used for monitoring
                      purposes, investigations into suspected malpractice and
                      enquiries on results. I understand if I do not agree to be
                      video recorded, I will not be able to take the test.
                    </li>
                    <li>
                      My result may be adjusted or cancelled after release if
                      the IELTS PARTNERS identify any anomalies affecting the
                      reliability of my test score.
                    </li>
                    <li>
                      Violent, threatening, or abusive behaviour including shouting and swearing towards staff or other test takers will not
                      be tolerated. Test-takers who act in such a manner on test day will be asked to leave the exam; the Police may be
                      called, and I could receive a ban from taking IELTS.
                    </li>
                  </ol>
                </li>
                <li>
                  <p class="text-bold"> Disclaimers</p>
                  <ol>
                    <li>
                      The International English Language Testing System (IELTS) is designed to be one of many means and factors used by
                      academic institutions, government agencies, professional bodies, and employers in determining whether a test taker can
                      be admitted as a student or be considered for employment or for citizenship purposes. IELTS is not designed to be the
                      sole method of determining admission or employment. IELTS tests are made available to all persons, regardless of gender,
                      race, nationality or religion. The IELTS and One Skill Retake (OSR) is not recommended to persons under 16 years of age.<br>
                      OSR is accessible to test takers including those with Special Requirements or Access Arrangement registrations.
                    </li>
                    <li>
                      The IELTS PARTNERS and any other party involved in
                      creating, producing, or delivering IELTS shall not be
                      liable for any direct, incidental, consequential,
                      indirect, special, punitive, or similar damages arising
                      out of access to, use of, acceptance by, or interpretation
                      of the results by any third party, or any errors or
                      omissions in the content therein.
                    </li>
                  </ol>
                </li>
              </ol>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-wrapper>
