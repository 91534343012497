import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-information-box',
  template: `
    <div class="alert" [ngClass]="['alert-' + type, classes.join(' ')]" role="alert">
      <i *ngIf="icon" class="fa d-flex align-items-center" [ngClass]="icon" aria-hidden="true"></i>
      <img *ngIf="img" [src]="img" alt="">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./information-box.component.scss']
})
export class InformationBoxComponent {
  @Input() type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' = 'light';
  @Input() icon = '';
  @Input() img = '';
  @Input() classes = [];

}
