import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { OrganisationService, Organisation, ConfirmationType, GetOrganisationsResponse } from '@idp-education/ors-test-taker-bff-client-v1';
import mockList from './mockInstitutionList';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { noop } from 'lodash';
import { Router } from '@angular/router';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { isIOCProduct, isOSRIOCProduct } from 'shared/utils/is-ioc-product';
import { conditionalValidator } from 'shared/validators/custom-validators';

const ProductId = ['8ccd6cf6-f06b-4910-a489-74863cb340c5', 'c623abd7-d823-4a6c-a8b9-46e4b5ff2b6f'];
export type IFlag = {
  Id: string | number,
  type: 'IELTS General' | 'IELTS Academic' | 'IELTS Online General' | 'IELTS Online Academic',
  label: 'IELTS General' | 'IELTS Academic' | 'IELTS Online General' | 'IELTS Online Academic'
};
export type IInstitution = {
  id: string | number,
  title: string,
  subtitle: string,
  flags?: IFlag[],
  _select?: boolean,
  original: Organisation,
  address?: string,
  code?: string,
  isPostalRO?: boolean
};
@Component({
  selector: 'app-select-institution',
  templateUrl: './select-Institution.component.html',
  styleUrls: ['./select-Institution.component.scss']
})
export class SelectInstitutionComponent {
  @Input() haveTitle = true;
  @Input() selectable = true;
  @Input() haveButtons = false;
  @Input() hasTitle = true;
  @Input() verticalCenter = false;
  @Input() horizontalCenter = false;
  @Input() showSkipButton = false;
  @Input() isSearchRO = false;
  @Input() bookableProductName = '';
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onSelectItem: EventEmitter<any> = new EventEmitter();
  @ViewChild('acc') acc: NgbAccordion;
  fullList: any[] = [];
  totalCount: number;
  uparrow = './../../../assets/images/DownArrowRed.svg';
  form: UntypedFormGroup;
  isAddROEnabled: boolean;
  private readonly mockInstitutionList: Array<IInstitution> = mockList;
  public isArray = Array.isArray;
  // tslint:disable-next-line: variable-name
  private _institutionList: IInstitution[];
  public get institutionList(): IInstitution[] {
    return this._institutionList;
  }
  public set institutionList(v: IInstitution[]) {
    this._institutionList = v;
  }

  private _searchNameValue: string;
  private _searchCodeValue: string;
  public get searchNameValue(): string {
    return this._searchNameValue;
  }
  public set searchNameValue(v: string) {
    this._searchNameValue = v;
  }
  public get searchCodeValue(): string {
    return this._searchCodeValue;
  }
  public set searchCodeValue(v: string) {
    this._searchCodeValue = v;
  }

  private _page: number;
  public get page(): number {
    return this._page;
  }
  public set page(v: number) {
    this._page = v;
  }
  readonly pageSize = 10;
  readonly noop = noop;
  public get haveResult() {
    if (this.fullList?.length > 0) {
      return true;
    }
    return false;
  }
  constructor(
    private organisationService: OrganisationService,
    public deviceDetector: DeviceDetectorService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private applicationService: ApplicationsService
  ) {
    this.page = 1;
  }
  fetchInstitutionList(page = 1): Observable<GetOrganisationsResponse> {
    if (!this.isSearchRO || (this.isSearchRO && this.bookableProductName === 'IELTS Online Academic')) {
      return this.organisationService.getOrganisations(null, null, null, this.searchNameValue ? this.searchNameValue : null,
        this.searchCodeValue ? this.searchCodeValue : null, null, page, this.pageSize, ProductId[0]);
    }
    else if (this.isSearchRO && isIOCProduct(this.bookableProductName)) {
      return this.organisationService.getOrganisations(null, null, null, this.searchNameValue ? this.searchNameValue : null,
        this.searchCodeValue ? this.searchCodeValue : null, null, page, this.pageSize);
    } else if (this.isSearchRO && isOSRIOCProduct(this.bookableProductName)) {
      const productId = this.getProductId(this.bookableProductName);
      return this.organisationService.getOrganisations(null, null, null, this.searchNameValue ? this.searchNameValue : null,
        this.searchCodeValue ? this.searchCodeValue : null, null, page, this.pageSize, productId);
    }
  }
  searchInstitutions() {
    this.fetchAndPaginateResult();
  }
  getProductName(): 'IOL'|'NIOL' {
    if (this.bookableProductName.includes('IELTS Online')) {
      return 'IOL';
    } else {
      return 'NIOL';
    }
  }
  toggle(acc: NgbAccordion, index: any) {
    this.isAddROEnabled = true;
    this.form = this.prepareForm();
    const panel = `panel-${index}`;
    acc.toggle(panel);
  }
  prepareForm() {
    return this.fb.group({
      contactName: ['', [Validators.maxLength(255)]],
      referenceNumber: ['', [Validators.maxLength(255)]],
      confirmationStatus: ['', Validators.required],
      overall: [''],
      listening: [''],
      reading: [''],
      writing: [''],
      speaking: [''],
    }, { validators: conditionalValidator });
  }
  onSearchNameChanged(value) {
    this.searchNameValue = value;
    this._page = 1;
    if (this.haveButtons) {
      if (!this.searchNameValue) {
        this.institutionList = [];
        this.fullList = [];
      }
      return;
    }
    if (this.searchNameValue) {
      this.fetchAndPaginateResult();
    } else {
      this.institutionList = [];
      this.fullList = [];
    }
  }
  onSearchCodeChanged(value) {
    this.searchCodeValue = value;
    this._page = 1;
    if (this.haveButtons) {
      if (!this.searchCodeValue) {
        this.institutionList = [];
        this.fullList = [];
      }
      return;
    }
    if (this.searchCodeValue) {
      this.fetchAndPaginateResult();
    } else {
      this.institutionList = [];
      this.fullList = [];
    }
  }
  public fetchAndPaginateResult(page = 1) {
    this.fetchInstitutionList(page).subscribe(value => {
      timer(100).pipe(first()).subscribe(() => {
        this.acc?.collapseAll();
      });
      this.totalCount = value.totalCount;
      const institutionList: IInstitution[] = value.items.map(item => ({
        id: item.id,
        subtitle: item.alternateNames[0],
        title: item.name,
        address: item.address?.city,
        country: item.address?.countryName,
        original: { ...item },
        code: item.code,
        isPostalRO: item.methodOfDelivery === 'POSTAL' ? true : false
      }));
      this.institutionList = institutionList.map(item => ({
        ...item,
        _select: false
      }));
    });
  }
  onPageChange(pageNumber: number) {
    this.page = pageNumber;
    this.fetchAndPaginateResult(pageNumber);
  }
  isSelectable(flags: IFlag[]) {
    return !!flags.filter(flag => flag.label === 'IELTS Online Academic' || flag.label === 'IELTS Online General').length;
  }
  onAddClick(institution: IInstitution) {
    if (!this.selectable) {
      return;
    }
    institution._select = true;
    setTimeout(() => {
      const event = {
        form: this.form,
        item: institution
      };
      this.onSelectItem.emit(event);
      this.onBackButtonClick.emit();
    }, 200);
  }
  onSkipClicked() {
    this.router.navigate(['/booking/speaking-date']);
  }
  getProductId(productName: string) {
    const result = {
      'IELTS OSR on Computer Academic': '0661b30f-158e-41ba-b2ea-3185dd72273a',
      'IELTS OSR on Computer General Training': '3fae7f05-8dc6-471f-9408-c38b2ce2305e',
      'IELTS OSR UKVI on Computer Academic': 'a525d8d4-c9fd-4526-a1fc-007cbd5a539b',
      'IELTS OSR UKVI on Computer General Training': 'b12aae6d-f0cd-4cfc-879c-76affe1a1dcf'
    }[productName];
    return result ? result : '';
  }
}
