import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ssr-seat-availability',
  templateUrl: './ssr-seat-availability.component.html',
  styleUrls: ['ssr-seat-availability.component.scss']
})
export class SsrSeatAvailabilityComponent {
  @Input() seatAvailability: any;
}
