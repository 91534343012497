export enum IOCLocalStorageItemKeys {
    speakingtesttime = 'speakingtesttime',
    lrwtesttime = 'lrwtesttime',
    isNotIOLProduct = 'isNotIOLProduct',
    testLocalTimezone = 'testLocalTimezone',
    applicationsStore = 'applicationsStore',
    productFee = 'productFee'
}

export enum OSRLocalStorageItemKeys {
    ssrComponent = 'ssrComponent',
    ssrFee = 'ssrFee',
    selectedTest = 'selectedTest',
    offlinePayment = 'offlinePayment',
    ssrApplication = 'ssrApplication'
}

export enum EORLocalStorageItemKeys {
    eorApplication = 'eorApplication',
}

export enum PaymentTypes {
    normal = 'Normal',
    osr = 'OSR',
    ssr = 'SSR',
    EOR = 'EOR'
}

export enum PaymentGateways {
    STRIPE = 'STRIPE',
    VERITRANS = 'VERITRANS',
    PAYSTACK = 'PAYSTACK',
    TIPS = 'TIPS',
    BANKALFALAH = 'BANKALFALAH',
    BRAC = 'BRAC',
    ESEWA = 'ESEWA',
    PAYDOLLAR = 'PAYDOLLAR',
    NEWEBPAY = 'NEWEBPAY',
    INIPAY = 'INIPAY',
    PAYMENTOS = 'PAYMENTOS',
    HSBCOMNI = 'HSBCOMNI',
    CREDIT_CARD = 'CREDIT_CARD',
    BRAINTREE = 'BRAINTREE',
    PAYPAL = 'PAYPAL'
}

export enum PaymentStates {
    INITIATED = 'INITIATED',
    COMPLETED = 'COMPLETED'
}
export enum GALocalStorageItemKeys {
    testModule = 'testModule',
    testFormat = 'testFormat',
    testCategory = 'testCategory',
    testLocation = 'testLocation',
    eorObject = 'eorObject'
}
