
import { createReducer, on } from '@ngrx/store';
import { setTestLocationId } from '../../onboarding/store/onboarding.actions';
import {
    setSpeakingTestTime,
    ISpeakingTime,
    setCurrentStep,
    setCurrentStepName,
    setProgressValue,
    setTotalStep,
    SetLRWTestTime,
    clearLRWTestTime,
    SetLatestPageUrl,
    SetIsPayedBooking,
    SetRequestForGenerateBooking,
    ResetState,
    SetProductType,
    SetTestLocalTimeZone,
    SetNonIOLLocationId,
    SetNonIOLProductId,
    SetLRWRedirectUrl,
    SetSpeakingRedirectUrl,
    SetIsRegistered,
    clearState,
    SetUnder18User,
    SetConsentAccepted,
    SetReferralPartnerId,
    SetIsNewPaymentJourney
} from './booking.actions';
import { DateTime } from 'luxon';

export interface IBookingStoreState {
  progressValue: number;
  currentStep: number;
  totalSteps: number;
  currentStepName: string;
  latestPageUrl: string;
  speakingTestTime: {
    from: DateTime;
    to: DateTime;
  };
  lrwTestTime: {
    from: DateTime;
    to: DateTime;
  };
  requestForGenerateBooking: boolean;
  isPayed: boolean;
  isNotIOLProduct: boolean;
  speakingUrl: string;
  lrwUrl: string;
  nonIOLLocationId: string;
  testLocalTimezone: string;
  nonIOLProductId: string;
  isRegistered: boolean;
  userUnder18: boolean;
  consentAccepted: boolean;
  referralPartnerId: string;
}

const initialState: IBookingStoreState = {
    progressValue: 0,
    currentStep: null,
    totalSteps: 0,
    currentStepName: '',
    latestPageUrl: null,
    speakingTestTime: {
        from: null,
        to: null
    },
    lrwTestTime: {
        from: null,
        to: null
    },
    requestForGenerateBooking: false,
    isPayed: false,
    isNotIOLProduct: false,
    speakingUrl: null,
    lrwUrl: null,
    nonIOLLocationId: null,
    testLocalTimezone: null,
    nonIOLProductId: null,
    isRegistered: false,
    userUnder18: false,
    consentAccepted: false,
    referralPartnerId: '',
};

const reducer = createReducer(
    initialState,
    on(setSpeakingTestTime, (state, payload: ISpeakingTime) => ({
        ...state,
        speakingTestTime: payload
    })),
    on(setCurrentStep, (state, { currentStep }) => ({
        ...state,
        currentStep
    })),
    on(setCurrentStepName, (state, { currentStepName }) => ({
        ...state,
        currentStepName,
    })),
    on(setProgressValue, (state, { progressValue }) => ({
        ...state,
        progressValue
    })),
    on(setTotalStep, (state, { totalSteps }) => ({
        ...state,
        totalSteps
    })),
    on(SetLRWTestTime, (state, payload: ISpeakingTime) => ({
        ...state,
        lrwTestTime: payload
    })),
    on(clearLRWTestTime, (state) => ({
        ...state,
        lrwTestTime: null
    })),
    on(SetLatestPageUrl, (state, payload: { latestPage: string }) => ({
        ...state,
        latestPageUrl: payload.latestPage
    })),
    on(SetIsPayedBooking, (state, payLoad) => ({
        ...state,
        isPayed: payLoad.isPayed
    })),
    on(SetRequestForGenerateBooking, (state, payLoad) => ({
        ...state,
        requestForGenerateBooking: payLoad.requestForGenerateBooking
    })),
    on(SetProductType, (state, { isNotIOLProduct }) => ({
        ...state,
        isNotIOLProduct
    })),
    on(SetSpeakingRedirectUrl, (state, { speakingUrl }) => ({
        ...state,
        speakingUrl
    })),
    on(SetLRWRedirectUrl, (state, { lrwUrl }) => ({
        ...state,
        lrwUrl
    })),
    on(SetTestLocalTimeZone, (state, { testLocalTimezone }) => ({
        ...state,
        testLocalTimezone
    })),
    on(SetNonIOLProductId, (state, { nonIOLProductId }) => ({
        ...state,
        nonIOLProductId
    })),
    on(SetNonIOLLocationId, (state, { nonIOLLocationId }) => ({
        ...state,
        nonIOLLocationId
    })),
    on(SetIsRegistered, (state) => ({
        ...state,
        isRegistered: true
    })),
    on(ResetState, (state) => ({
        ...initialState,
        isPayed: state.isPayed,
        isNotIOLProduct: state.isNotIOLProduct,
        speakingUrl: state.speakingUrl,
        lrwUrl: state.lrwUrl,
        testLocalTimezone: state.testLocalTimezone,
        nonIOLProductId: state.nonIOLProductId,
        nonIOLLocationId: state.nonIOLLocationId
    })),
    on(clearState, (state) => ({
        ...initialState,
        currentStep: state.currentStep
    })),
    on(SetUnder18User, (state, { isUnder18 }) => ({
      ...state,
      userUnder18: isUnder18
    })),
    on(SetConsentAccepted, (state, { isAccepted }) => ({
      ...state,
      consentAccepted: isAccepted
    })),
    on(SetReferralPartnerId, (state, { referralPartnerId }) => ({
      ...state,
      referralPartnerId
    })),
    on(SetIsNewPaymentJourney, (state, { isNewPaymentJourney }) => ({
        ...state,
        isNewPaymentJourney
      }))
);


export function bookingReducer(state: any, action: any) {
    return reducer(state, action);
}
