import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { setCallToActionState } from 'src/app/store/my-tests/my-tests.actions';
import { CallToActionState } from 'src/app/store/my-tests/my-tests.reducer';
import { isOSRIOCProduct } from 'shared/utils/is-ioc-product';
import { IBookingStoreState } from 'pages/booking/store/booking.reducer';
import {SetLatestPageUrl, SetLRWRedirectUrl, SetNonIOLLocationId, SetNonIOLProductId, SetProductType,
  SetSpeakingRedirectUrl, SetTestLocalTimeZone
} from 'src/app/pages/booking/store/booking.actions';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent {
  @Input() message = '';
  @Input() route = '';
  @Input() cta = '';
  @Input() application = null;
  @Input() hasCustomMessage = false;
  @Output() onBeforeCall: EventEmitter<string> = new EventEmitter();
  showMessage = true;
  hasUnpaidOSRApp: boolean;
  constructor(private router: Router, private store: Store<{ bookingStore, myTestsStore }>) {
  }

  onActionBtnClick(route: string) {
    if (isOSRIOCProduct(this.application?.productName)) {
      this.store.dispatch(setCallToActionState({ callToActionState: CallToActionState.OSR_PRE_PAYMENT }));
      this.router.navigate(['/my-tests']);
    } else {
      this.store.dispatch(SetProductType({
        isNotIOLProduct: false
      }));
      this.onBeforeCall.emit(route);

      localStorage.setItem('isCompleteBooking',JSON.stringify(true));
      localStorage.setItem('prevUrl', '/my-account');
      const bookingStoreStateString = localStorage.getItem('bookingstore');
    if (bookingStoreStateString) {
      const bookingStoreState: IBookingStoreState = JSON.parse(bookingStoreStateString);
    
    this.store.dispatch(SetProductType({
      isNotIOLProduct: true
    }));
    this.store.dispatch(SetSpeakingRedirectUrl({
      speakingUrl: bookingStoreState.speakingUrl
    }));
    this.store.dispatch(SetLRWRedirectUrl({
      lrwUrl: bookingStoreState.lrwUrl
    }));
    this.store.dispatch(SetTestLocalTimeZone({
      testLocalTimezone: bookingStoreState.testLocalTimezone
    }));
    this.store.dispatch(SetNonIOLLocationId({
      nonIOLLocationId: bookingStoreState.nonIOLLocationId
    }));
    this.store.dispatch(SetNonIOLProductId({
      nonIOLProductId: bookingStoreState.nonIOLProductId
    }));
    this.store.dispatch(SetLatestPageUrl({
      latestPage: bookingStoreState.latestPageUrl
    }));
    }
    this.router.navigate([route]);
    }
  }

}
