<div class="flexbox flex-column content  align-items-center align-items-xxl-start">
  <div class="all-content">
    <h6 class="content-title p-3" i18n>Enter the email address you used to sign up. You will receive a code to reset
      your
      password</h6>

    <form class="form-horizontal mt-5 p-3" [formGroup]="$form" (submit)="onSubmit()">

      <div class="form-group">
        <div class="input-group mb-1 justify-content-center justify-content-lg-start">
          <input id="emailControl" name="emailControl" type="text" i18n-placeholder placeholder="Email address"
            class="form-control input-md" maxlength="100" (keyup)="inputChanged()" [formControl]="$form.controls.email" trim="blur">
        </div>
        <app-field-errors class="text-center text-lg-start" [customMessage]="customMessages.email"
          [control]="$form.controls.email" componentId="emailControl"
          *ngIf="$form.controls.email.invalid && $form.controls.email.touched">
        </app-field-errors>
        <div id="signupMail" class="invalid-feedback text-center text-lg-start" *ngIf="hasError"
          [style.display]="'block'">
          {{ error }}
        </div>
      </div>
      <div class="flexbox justify-content-center mt-5">
        <button type="submit" class="btn btn-multiAction" [disabled]="$form.invalid" i18n>Next</button>
      </div>
      <app-still-have-problem-link class="align"></app-still-have-problem-link>
    </form>
  </div>
</div>
