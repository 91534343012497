<div class="d-flex flex-grow-1 content flex-column align-items-start justify-content-start">
  <div class="all-content d-flex justify-content-start align-items-stretch flex-column w-100">
    <h1 class="visually-hidden">
      Test dates available
    </h1>
    <app-ssr-criteria-summary [location]="state?.criteria?.locLabelShort"
                              [dateRange]="getDateRange(state?.criteria?.fromDate, state?.criteria?.toDate)"
                              (onEditLocation)="onssrTestLocationClick.emit()"
                              (onEditDate)="onssrTestDateClick.emit()"
    ></app-ssr-criteria-summary>
      <h2 *ngIf="(loadingService.isLoading | async) === false && !isError"><b class="">{{totalCount}} IELTS</b> test dates available in {{state?.criteria?.locLabel}}</h2>
    <div class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center" *ngIf="sessions.length > 0">
      <h3 class="test-type-heading flex-grow-1 mt-0 mb-0">Results for <span class="text-purple">
                <i class="fa fa-circle inline-icon-size" aria-hidden="true"></i> {{state.ssrComponent}} test
              </span>
      </h3>
      <div class="btn d-flex align-items-center btn-sort align-self-end" id="btn-sort">
        <i class="material-icons pe-1">swap_vert</i>
        Sort by:&nbsp;<strong>{{sortBy}}</strong>
        <i class="fa fa-chevron-down ps-1 inline-icon-size" aria-hidden="true"></i>
        <select
          (change)="onSortSelect($event.target)" id="sort-select" [value]="this.sortBy">
          <option selected>Date</option>
          <option>Distance</option>
        </select>
      </div>
    </div>
    <ul class="list-view">
      <li *ngFor="let session of sessions; let i = index">
        <app-ssr-list-item [session]="session" (onReserve)="onReserve($event)"></app-ssr-list-item>
      </li>
    </ul>
    <ng-container *ngIf="languageSkill !== 'S'">
    <app-ssr-paging class="d-md-flex" [totalCount]="totalCount" [pageSize]="pageSize" [page]="currentCriteria?.page || 1"
                    [rows$]="rows$" (onPageChange)="setPage($event)"></app-ssr-paging>
    </ng-container>
    <div class="pt-3 mt-5 mt-lg-0">&nbsp;</div>
    <app-no-result *ngIf="(loadingService.isLoading | async) === false && !isError && sessions.length === 0"(onClickAction)="onssrTestDateClick.emit()"></app-no-result>
  </div>
</div>
