import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { isFunction } from 'lodash';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-booking-cancelled',
  templateUrl: './booking-cancelled.component.html',
  styleUrls: ['./booking-cancelled.component.scss']
})
export class BookingCancelledComponent implements OnInit {
  @Input() stTitle: string = null;
  @Input() lrwTitle: string = null;
  @Input() lrwTitleMd: string = null;
  @Input() textClass = '';
  @Input() status = false;
  @Input() title: string;
  @Input() tests: Subject<DashboardItem[]>;
  @Input() showOptions = false;
  @Input() showViewTestsLink = true;

  applications: DashboardItem[] = [];
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (!isFunction(this?.tests?.subscribe)) {
      return;
    }
    this?.tests?.subscribe(apps => {
      this.applications = apps;
    });
  }


  getTestDate(test: string, type: string) {
    switch (type) {
      case 'month':
        return DateTime.fromISO(test).toFormat('MMM').toUpperCase();
      case 'day':
        return DateTime.fromISO(test).toFormat('dd');
      case 'year':
        return DateTime.fromISO(test).toFormat('yyyy');
      case 'time':
        return `${DateTime.fromISO(test).toFormat('h:mm a').toLowerCase()}`;
    }
  }
  getTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

}
