<div class="d-flex flex-column content p-1">
  <div class="d-flex flex-column mb-4">
    <h6 class="content-title ms-0 ps-0 pb-0 mb-0" i18n>Review your details</h6>
    <div class="d-flex align-items-center ">
      <img src="../../../../assets/images/person.svg" alt="" class="me-1">
      <span class="small text-grey text-medium" style="color: #454545;" i18n>You're signed in through
        {{ socialMedia }}</span>
    </div>
  </div>

  <form class="form-horizontal" [formGroup]="$form" (ngSubmit)="onSubmit()">
    <app-custom-input [fcn]="'firstName'" [form]="$form" id='textinput' i18n-placeholder placeHolder="First name"
      [showError]="true">
    </app-custom-input>
    <app-custom-input [fcn]="'lastName'" [form]="$form" id='lastName' i18n-placeholder placeHolder="Family name"
      [showError]="true">
    </app-custom-input>
    <app-custom-input [fcn]="'email'" [form]="$form" id='email' i18n-placeholder placeHolder="Email" [required]="true"
      type="email">
    </app-custom-input>
    <app-custom-input [fcn]="'mobileNumber'" [form]="$form" id='mobile' i18n-placeholder [required]="true" [type]="getAutomationTestToggle() ? null : 'phone'"
      i18n-tooltip tooltip="Your mobile number" [errorCMessage]="customMessages.mobileNumber">
    </app-custom-input>
    <app-custom-input [fcn]="'birthDate'" [form]="$form" id='birthDate' i18n-placeholder
      placeHolder="Date of Birth DD / MM / YYYY" [required]="true" [tooltip]="getBirthDateTooltip()" type="birthDate"
      mask="d0/M0/0000" (change)="onChangeBirthDate()" [errorCMessage]='customMessages.birthDate'>
    </app-custom-input>

    <div class="d-flex align-center conditions">
      <app-circle-checkbox [control]="$form.controls.termAndCondition1"></app-circle-checkbox>
      <span i18n>I agree to the&nbsp;</span>
      <a class="link-gray" style="color: #8E8E93" i18n href="https://www.idp.com/australia/privacy-policy/"
        target="_blank" rel="noopener">terms &
        conditions</a>
    </div>
    <div class="d-flex align-center conditions">
      <app-circle-checkbox [control]="$form.controls.termAndCondition2"></app-circle-checkbox>
      <span i18n>I’d like to receive news, offers and promotions from IDP IELTS</span>
    </div>
    <div class="d-flex align-center justify-content-center justify-content-lg-start mt-4">

      <button [disabled]="!($form.valid && ($form.controls.termAndCondition1.value)) || showLoading"
        class=" btn btn-multiAction" type="submit" i18n>
        Create an account
      </button>

    </div>
  </form>
</div>
