import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { ErrorPageComponent } from './core/error-page/error-page.component';
import { HomeComponent } from './pages/home/home.component';
import { TermAndConditionsComponent } from './pages/termAndConditions/term-and-conditions.component';
import { CreateBookingAccountComponent } from './pages/account/create-booking-account/create-booking-account.component';
import { HaveCognitoProfile } from './pages/account/create-booking-account-can-activate.service';
import { LoginAuthGuardService } from './auth-guard.service';
import { TermsAndConditionsIncentreComponent } from './pages/terms-and-conditions-incentre/terms-and-conditions-incentre.component';
import { TermAndConditionHongKongComponent } from './pages/term-and-condition-hong-kong/term-and-condition-hong-kong.component';
import { OfflinePaymentInstructionComponent } from './pages/offline-payment-instruction/offline-payment-instruction.component';
import { TermsAndConditionUkviComponent } from 'pages/terms-and-condition-ukvi/terms-and-condition-ukvi.component';
import { TermsAndConditionUkviOsrComponent } from 'pages/terms-and-condition-ukvi-osr/terms-and-condition-ukvi-osr.component';
import { TermsAndConditionsUkviHongkongComponent } from 'pages/terms-and-conditions-ukvi-hongkong/terms-and-conditions-ukvi-hongkong.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'my-account',
    pathMatch: 'full'
  },
  {
    path: 'quicknav',
    component: HomeComponent,
    data: { animation: 'Home' }
  },
  {
    path: 'create-booking',
    component: CreateBookingAccountComponent,
    canActivate: [HaveCognitoProfile]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account-routing.module').then(m => m.AccountRoutingModule),
    data: { animation: 'Account' }
  },
  {
    path: 'account-v2',
    loadChildren: () => import('./pages/account-v2/account-v2.module').then(m => m.AccountV2Module),
    data: { animation: 'Account' }
  },
  {
    path: 'ui-component',
    loadChildren: () => import('./pages/ui-component/ui-component.module').then(m => m.UiComponentModule),
    data: { animation: 'UIComponent' }
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment-routing.module').then(m => m.PaymentRoutingModule),
    data: { animation: 'Payment' }
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding-routing.module').then(m => m.OnBoardingRoutingModule),
    data: { animation: 'Onboarding' }
  },
  {
    path: 'booking',
    loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingModule),
    data: { animation: 'booking' }
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/my-account/my-account.module').then(m => m.MyAccountModule),
    data: { animation: 'booking' }
  },
  {
    path: 'my-tests',
    loadChildren: () => import('./pages/my-tests/my-tests.module').then(m => m.MyTestsModule),
    data: { animation: 'booking' }
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
    data: { animation: 'booking' }
  },
  {
    path: 'login',
    redirectTo: 'my-account',
    data: { animation: 'Onboarding' }

  },
  {
    path: 'term-and-conditions',
    component: TermAndConditionsComponent,
    data: { animation: 'home', pagename: 'Terms and Condition' }

  },
  {
    path: 'term-and-conditions-hongkong',
    component: TermAndConditionHongKongComponent,
  },
  {
    path: 'term-and-conditions-incentre',
    component: TermsAndConditionsIncentreComponent,
  },
  {
    path: 'offline-payment-instruction',
    component: OfflinePaymentInstructionComponent,
  },
  {
    path: 'term-and-conditions-ukvi',
    component: TermsAndConditionUkviComponent,
  },
  {
    path: 'term-and-conditions-ukvi-hongkong',
    component: TermsAndConditionsUkviHongkongComponent,
  },
  {
    path: 'term-and-conditions-ukvi-osr',
    component: TermsAndConditionUkviOsrComponent,
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'error', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
