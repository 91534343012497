import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { LoginAuthGuardService, AuthGuardService, SocialLoginAuthGuardService } from '../../auth-guard.service';
import { AccountManageComponent } from './account-manage.component';
import { EntryPointComponent } from './entry-point/entry-point.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SocialSignupComponent } from './social-signup/social-signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './reset-password-confirm/reset-password-confirm.component';
import { ResetPasswordNewPasswordComponent } from './reset-password-new-password/reset-password-confirm.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordFinalStepComponent } from './reset-password-final-step/reset-password-final-step.component';
import { ResetPasswordFinalStepCanActivateService, ResetPasswordStep1CanActivateService, ResetPasswordStep2CanActivateService } from './reset-password-can-activate.service';
const routes: Routes = [{
  path: '',
  component: AccountManageComponent,
  children: [
    {
      path: 'signin',
      component: LoginComponent,
      canActivate: [LoginAuthGuardService],
      data: { state: 'signin', pagename: 'Sign in' }
    },
    { path: 'signup', component: SignupComponent, canActivate: [LoginAuthGuardService], data: { state: 'signup', pagename: 'Sign up' } },
    { path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [LoginAuthGuardService], data: { state: 'privacy', pagename: 'Privacy Policy' } },
    {
      path: 'social-signup', component: SocialSignupComponent,
      canActivate: [SocialLoginAuthGuardService],
      data: { state: 'social-signup', pagename: 'Social Signup' }
    },
    {
      path: 'reset-password',
      component: ForgotPasswordComponent,
      data: { state: 'resetPassword' },
      children: [
        {
          path: '',
          component: ResetPasswordComponent,
          data: {
            state: 'resetPassword', pagename: 'Password Reset'
          },
        },
        {
          path: 'step1',
          component: ResetPasswordConfirmComponent,
          data: { state: 'step1', pagename: 'Password Reset Step 1' },
          canActivate: [ResetPasswordStep1CanActivateService]
        },
        {
          path: 'step2',
          component: ResetPasswordNewPasswordComponent,
          data: { state: 'step2', pagename: 'Password Reset Step 2' },
          canActivate: [ResetPasswordStep2CanActivateService]
        },
        {
          path: 'final',
          component: ResetPasswordFinalStepComponent,
          data: { state: 'stepFinal', pagename: 'Password Reset Step Final' },
          canActivate: [ResetPasswordFinalStepCanActivateService]
        }
      ]
    },
    { path: '', component: EntryPointComponent, canActivate: [LoginAuthGuardService], data: { state: 'account' } },
  ]
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class AccountRoutingModule {

}
