import { Injectable, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { fetchExtension } from 'shared/components/forms/application-document-upload-form/image-utils';
import { IFacingMode } from 'shared/models/components/upload-password-form';

@Injectable({
  providedIn: 'root'
})
export class CameraFunctionsService {

  private renderer: Renderer2;


  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  flipCamera(currentFacingMode: IFacingMode): IFacingMode {
    return currentFacingMode === 'user' ? 'environment' : 'user';
  }

  setBackground(url: string, uploadBox: ElementRef, pdfIcon: string) {
    const style = uploadBox.nativeElement.style;
    style.background = '#fff';
    const extension = fetchExtension(url).toLowerCase();
    style.backgroundImage = `url('${extension !== 'pdf' ? url : pdfIcon}')`;
    style.backgroundRepeat = 'no-repeat';
    style.backgroundPosition = 'center';
    style.backgroundSize = 'contain';
    style.paddingTop = extension === 'pdf' ? '5px' : '0';
    style.paddingBottom = extension === 'pdf' ? '5px' : '0';
    style.backgroundOrigin = extension === 'pdf' ? 'content-box' : 'border-box';
  }

  tryAgainUpload(
    uploadError: string,
    resetBackground: () => void,
    inputFile: HTMLInputElement,
    file: File,
    prepareUpload: (file: File) => boolean,
    uploadFile: (file: File) => void,
    watchInputFileChange?: () => void
  ) {
    if (uploadError) {
      resetBackground();
      inputFile.value = '';

      if (watchInputFileChange) {
        setTimeout(() => {
          watchInputFileChange();
        }, 1);
      }
    } else {
      this.resetUpload(
        resetBackground,
        inputFile
      );
      if (prepareUpload(file)) {
        resetBackground();
        uploadFile(file);
      }
    }
  }

  private resetUpload(
    resetBackground: () => void,
    inputFile: HTMLInputElement
  ) {
    resetBackground();
    inputFile.value = '';
  }

}
