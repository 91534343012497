import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../auth-guard.service';
import { MyTestsComponent } from './my-tests.component';

const routes: Routes = [{
  path: '',
  component: MyTestsComponent,
  data: { pagename: 'List of my tests' },
  canActivate: [AuthGuardService],
  children: []
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class MyTestsRoutingModule {

}
