<div class="all-content ms-3">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel *ngFor="let sectionKey of toArray(options);index as i" [id]="'panel-' + i">
            <ng-template ngbPanelHeader>
                <div class="d-flex flex-column justify-content-between  align-items-center">
                    <div class="collapseHeader up" *ngIf="acc.isExpanded('panel-'+(i-1))">
                    </div>
                    <div class="w-100 d-flex row pt-2 pb-2">
                        <button (click)="togglePanel(i, acc)" class="btn btn-link ps-0 text-start col">
                            <h5>
                                {{options[sectionKey].label}}
                            </h5>
                        </button>
                        <div class="text-end col">
                            <button class="btn btn-link" (click)="acc.toggle('panel-'+i)">
                                <span class="material-icons" *ngIf="acc.isExpanded('panel-'+i)">
                                    remove
                                </span>

                                <span class="material-icons" *ngIf="!acc.isExpanded('panel-'+i)">
                                    add
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="collapseHeader bottom" *ngIf="!acc.isExpanded('panel-'+i)">
                    </div>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="row p-0">
                    <div *ngFor="let attribute of options[sectionKey].attributes"
                        class="{{'col-' + (attribute.col ? attribute.col + '' : '12')}} mb-2 ps-0">
                        <div class="userLabelWrapper">
                            <label class="userLabel"
                                [style.visibility]="attribute.label ? 'visible' : 'hidden'">{{attribute.label}}:</label>
                        </div>
                        <div class="userData" *ngIf="attribute.type === 'text' || !attribute.type">
                            {{attribute.data}}
                        </div>
                        <div class="userData image" *ngIf="attribute.type === 'image'">
                            <img [src]="attribute.data" alt="attribute.label">
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <div class="d-flex justify-content-center justify-content-center w-100 c-button mt-5">
        <button class="btn btn-multiAction m-2" (click)="goTo('/my-account')" i18n>Go to My Account</button>
        <button class="btn btn-multiAction m-2" (click)="goTo('/my-tests')" i18n>Go to My Tests</button>
    </div>
</div>