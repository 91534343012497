import { UserState } from 'store/user/user.reducer';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { SelectorFunction } from 'store/selector-function.type';

export const getUserState = (state: { userStore: UserState }) => state.userStore;

export const getUserBirthdate = (state: UserState) => state.userDetails?.dateOfBirth;

export const getUser = (state: UserState) => state.userDetails;

export const getUserFullName =
  (state: UserState) =>
    state.userDetails ? `${state.userDetails?.firstName} ${state.userDetails?.lastName}` : undefined;

const createUserSelector = <T, R>(selectMethod: SelectorFunction<UserState, R>): MemoizedSelector<any, R> =>
  createSelector(
    getUserState,
    selectMethod
  );

export const selectUserBirthdate: Selector<{ userStore: UserState }, string> = createUserSelector(getUserBirthdate);

export const selectUserFullName: Selector<{ userStore: UserState }, string> = createUserSelector(getUserFullName);

export const selectUser = createUserSelector(getUser);


