import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';


// Might need to polyfill depending on needed browser support...
const isInt = Number.isInteger;

@Injectable()
export class NgbMomentjsAdapter extends NgbDateAdapter<string> {

  // TODO: Do the conversion to/from server timezone here...
  fromModel(date: DateTime | string): NgbDateStruct {
    if (!date) {
      return null;
    }
    if ((typeof date === 'string')) {
      const dtString = date.length > 19 ? date.substring(0, 19) : date;
      date = DateTime.fromJSDate(new Date(dtString));
      // date = moment(dtString);
    }
    if (!DateTime.isDateTime(date)) {
      return null;
    }

    return {
      year: date.year,
      month: date.month,
      day: date.day,
    };
  }

  toModel(date: NgbDateStruct): string {
    if (!date || !isInt(date.day) || !isInt(date.day) || !isInt(date.day)) {
      return null;
    }

    return DateTime.fromJSDate(new Date(`${date.year}-${date.month}-${date.day}`)).toFormat('yyyy-MM-dd');
  }
}
