import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingService } from '../services/loading-service.service';
import { catchError, map } from 'rxjs/operators';
import { XhrDetailsService as XHRDetailService } from '../../xhr-details.service';
@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoadingService,
              private XhrDetailsService: XHRDetailService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.XhrDetailsService.setLatestXhrDetails(req);
    /* how to disable interceptor temporarily
    this.userProfileService.defaultHeaders = this.userProfileService.defaultHeaders.set('loading', 'off');
    ... execute API call
    this.userProfileService.defaultHeaders = this.userProfileService.defaultHeaders.delete('loading');*/
    if (req.headers.has('loading')) {
      const clonedReq = req.clone({
        headers: req.headers.delete('loading')
      });
      return next.handle(clonedReq).pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        return evt;
      }));
    }
    this.loaderService.increaseLoadingCounter();
    return next.handle(req)
      .pipe(catchError((err) => {
        this.loaderService.decreaseLoadingCounter();
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.loaderService.decreaseLoadingCounter();
        }
        return evt;
      }));
  }
}
