import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  EventEmitter,
  Input, Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, first } from 'rxjs/operators';
import { formatDateRange } from '../format-date-range';
import { BehaviorSubject, of } from 'rxjs';
import {
  DashboardItem,
  SSRSessionService, SSRSessionsSearchRequest,
  SSRSessionsSearchRequestSortBy,
  SSRSessionsSearchResponse,
  TestDeliveryFormat
} from '@idp-education/ors-test-taker-bff-client-v1';
import { setSelectedTest } from '../../../../store/my-tests/my-tests.actions';
import { getTestCategory, getTestModule, sortPrioritySessions } from '../util';
import { getState, IState } from '../../../../store/my-tests/my-tests.reducer';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
const DEFAULT_RADIUS_KM = 50;
const DEFAULT_LOC_LNG = 144.9632;
const DEFAULT_LOC_LAT = -37.8142;


@Component({
  selector: 'app-ssr-test-result',
  templateUrl: './ssr-test-result.component.html',
  styleUrls: ['./ssr-test-result.component.scss']
})
export class SsrTestResultComponent implements OnInit {
  routeState = '';
  state: IState;
  rows$ = new BehaviorSubject<any[]>([]);
  currentCriteria!: any;
  sortBy = 'Date';
  isError = false;
  sessions: any[] = [];
  totalCount = 0;
  pageSize: number;
  languageSkill: string;
  @Input() ssrSearchApplication: DashboardItem;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrPaymentSummaryClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrTestLocationClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrTestDateClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private store: Store<{ myTestsStore }>,
    @Inject(PLATFORM_ID) private platformId: any,
    public router: Router,
    public route: ActivatedRoute,
    private sessionService: SSRSessionService,
    public loadingService: LoadingService,
  ) {
    this.setState();
  }

  ngOnInit(): void {
    this.store.select(getState).pipe(first()).subscribe(s => {
      this.state = s;
      this.callSearchApi();
    });
  }

  setState() {
    this.route.data.pipe(first()).subscribe(data => {
      this.routeState = data['state'];
    });
  }

  getDateRange(fromDate: string, toDate: string) {
    return formatDateRange(fromDate, toDate);
  }

  onReserve(session: any) {
    this.store.dispatch(setSelectedTest({selectedTest: session}));
    this.onssrPaymentSummaryClick.emit(session);
  }

  setPage(pageNumber: number) {
    if (!this.currentCriteria) {
      return;
    }
    // scroll to top of the page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.currentCriteria.page = pageNumber;
    this.callSearchApi();
  }

  onSortSelect(target: any) {
    this.sortBy = target.value;
    this.setPage(1);
  }

  callSearchApi() {
    this.sessions = [];
    this.isError = false;
    if (
      this.state.criteria.testFormat === undefined ||
      this.state.criteria.testType === undefined ||
      this.state.criteria.fromDate === undefined ||
      this.state.criteria.toDate === undefined ||
      this.state.criteria.currentLoc === undefined
    ) {
      this.onCancelClick.emit();
      return of(null);
    }

    this.languageSkill = this.state.ssrComponent.substring(0, 1).toUpperCase();
    this.pageSize = this.getPageSize();
    const criteria = {
      languageSkills: [this.languageSkill],
      longitude: this.state.criteria.currentLoc.lng  || DEFAULT_LOC_LNG,
      latitude: this.state.criteria.currentLoc.lat || DEFAULT_LOC_LAT,
      fromTestStartDateLocal: this.state.criteria.fromDate,
      toTestStartDateLocal: this.state.criteria.toDate,
      // order?: Order;
      sortBy: this.sortBy === 'Date' ? SSRSessionsSearchRequestSortBy.TESTSTARTLOCALDATETIME : SSRSessionsSearchRequestSortBy.RADIUSKM,
      // testLocationIds?: Array<string>,
      // timeOfDay?: TimeOfDay,
      page: this.getPage(),
      pageSize: this.pageSize,
      radiusKm: DEFAULT_RADIUS_KM,
      testCategories: getTestCategory(this.state.criteria.testType),
      testModules: getTestModule(this.state.criteria.testType),
    } as SSRSessionsSearchRequest;
    if (this.state.criteria.testFormat && this.state.criteria.testFormat !== 'All') {
      criteria.testDeliveryFormats = [this.state.criteria.testFormat as TestDeliveryFormat];
    }
    this.currentCriteria = criteria;
    this.loadingService.isLoading.next(true);
    this.sessionService.searchSSRSessions(criteria).pipe(first(), catchError(() => {
      this.isError = true;
      return of();
    }), finalize(() => {
      this.loadingService.isLoading.next(false);
    })).subscribe((result: SSRSessionsSearchResponse) => {
      if (result) {
        this.sessions =
          this.languageSkill === 'S'
            ? sortPrioritySessions([...result.items])
            : result.items;
        this.totalCount = this.getTotalCount(result);
      }
    });
  }
getPageSize(): number {
  return this.languageSkill === 'S' ? 0 : 10;
}
getPage(): number {
  return this.currentCriteria?.page ?? 1;
}
getTotalCount(result): number {
  return this.languageSkill === 'S' ? this.sessions.length : result.totalCount;
}
}
