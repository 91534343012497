import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { Application, CreateReceiptRequest, PaymentExpiry, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { PaymentMethodType } from '@idp-education/ors-test-taker-bff-client-v1/model/paymentMethodType';
import { PaymentType, VeritransPaymentService } from '@idp-education/ors-veritrans-payment-bff-client-v1';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import * as uuid from 'uuid';
import { concatMap, first } from 'rxjs/operators';
import { UserState } from 'store/user/user.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NavigationService } from 'shared/services/navigation.service';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { DynamicScriptLoaderService } from 'shared/services/dynamic-script-loader.service';
import { Configuration } from 'src/app/app.constants';
import { LoadingService } from 'shared/services/loading-service.service';
import { SharedStateService } from 'pages/account/shared-state.service';
declare function popShow(paymentKey: string): any;
@Component({
  selector: 'app-veritrans',
  templateUrl: './veritrans.component.html',
  styleUrls: ['./veritrans.component.scss']
})
export class VeritransComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() tcPaymentMethod = [];
  @Input() isNewJourney = false;
  paymentMethods = [];
  cardTitle: string;
  cvsTitle: string;
  tcPaymentMethodId: string;
  paymentKey;
  state: UserState;
  sub: Subscription;
  cvsExpiryHours: number;
  paymentExpiry: PaymentExpiry[];
  static get tab(): ICon {
    return {
      alt: 'veritrans',
      key: 'VERITRANS',
      url: '../../../../../assets/images/Veritans.svg',
      class: '',
      title: 'Pay online with Credit Card and Convenience Store',
      description: 'Secure payments powered by Veritrans',
    };
  }

  constructor(
    private loadingService: LoadingService,
    private receiptService: ReceiptService,
    private veritransService: VeritransPaymentService,
    private navigationService: NavigationService,
    private scriptLoaderService: DynamicScriptLoaderService,
    private sharedState: SharedStateService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.state = x;
    });
  }

  ngOnInit(): void {
    const tcPaymentMethod = this.tcPaymentMethod?.find(x => x.paymentMethod.code === IPaymentMethods.VERITRANS);
    this.tcPaymentMethodId = tcPaymentMethod?.id;
    this.paymentMethods = tcPaymentMethod?.paymentMethodConfig?.document?.paymentMethodConfiguration?.paymentMethods;
    this.paymentExpiry = tcPaymentMethod?.paymentMethodConfig?.document?.
                         paymentMethodConfiguration?.paymentExpiry;
    this.getPaymentMethods();
    this.storeBookingInfo();
    this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.VERITRANS) {
        this.onCardClick(paymentDetails.typeVal);
      }
    }));
  }
  getPaymentMethods() {
    if (this.paymentMethods?.length) {
      this.paymentMethods?.forEach(method => {
        if (method === PaymentMethodType.CREDITCARD) {
          this.cardTitle = 'Card Payment';
        } else if (method === PaymentMethodType.CVS) {
          this.cvsTitle = 'Convenience Store Payment';
          const cvsExpiryMinutes = this.paymentExpiry.find(type => type.paymentMethod === PaymentMethodType.CVS)?.expirationMinutes;
          this.cvsExpiryHours = cvsExpiryMinutes ? Math.floor(cvsExpiryMinutes / 60) : undefined;
        }
      });
    }
  }
  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.VERITRANS);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
  }
  onCardClick(id) {
    this.onVeritransClick(id);
  }
  onCVSClick(id) {
    this.navigationService.storePaymentMethodType(PaymentMethodType.CVS);
    this.onVeritransClick(id);
  }
  onVeritransClick(id) {
    this.loadingService.increaseLoadingCounter();
    this.veritransService.configuration.accessToken = this.state?.userToken;
    const receiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: this.tcPaymentMethodId
    };
    const paymentType = id === 'card' ? PaymentType.CREDITCARD : PaymentType.CVS;
    this.receiptService.createReceipt(uuid.v4(), receiptRequest).pipe(first(), concatMap(receiptResponse => {
      return this.veritransService.getVeritransPaymentKey(this.applicationId, paymentType, receiptResponse.id);
    })).subscribe(paymentKeyResponse => {
      this.paymentKey = paymentKeyResponse.paymentKey;
      this.scriptLoaderService.loadScript(Configuration.VERITRANS_POPJS_URL, paymentKeyResponse.publishableKey)
        .then(() => {
          popShow(this.paymentKey);
          setTimeout(() => {
            this.loadingService.resetLoadingCounter();
          }, 3000);
        })
        .catch((error) => {
          console.error('Failed to load script:', error);
        });

    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
