import { Component, Input } from '@angular/core';
import { SetConsentAccepted } from 'src/app/pages/booking/store/booking.actions';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { selectConsentAccepted } from 'src/app/pages/booking/store/booking.selectors';

const AppSettings = require('src/assets/appSettings.json');

@Component({
  selector: 'app-underage-consent-form-modal',
  templateUrl: './underage-consent-form-modal.component.html',
  styleUrls: ['./underage-consent-form-modal.component.scss']
})
export class UnderageConsentFormModalComponent {
  @Input() userFullName: string;
  public readonly consentFormDownloadLink = AppSettings.s3Links.underageConsentForm;
  checkboxModel = false;

  constructor(
    private store: Store<{ bookingStore }>,
    public activeModal: NgbActiveModal,
    private router: Router
  ) { }

  public onBackToMyTests(): void {
    this.router.navigateByUrl('/my-tests').then(() => {
      this.activeModal.close('my-tests');
    });
  }

  public onContinuePayment(checked: boolean): void {
    this.store.dispatch(SetConsentAccepted({ isAccepted: checked }));
    this.activeModal.close('accepted');
  }

}
