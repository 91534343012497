import { UserProfileResponse, ProductFee } from '@idp-education/ors-test-taker-bff-client-v1';
import { createAction, props } from '@ngrx/store';
export type IPaymentStatus = 'paying' | 'not started' | 'success' | 'failed';
const SET_PV = 'setProgressValue';
const SET_CS = 'setCurrentStep';
const SET_TS = 'setTotalSteps';
const SET_CSN = 'setCurrentStepName';
const SET_STT = 'setSpeakingtesttime';
const SET_LTT = 'setLrwtesttime';
const SET_UP = 'setUserProfile';
const SET_AP = 'setActivePayment';
const SET_HT = 'setHasTimer';
const SET_PS = 'setPayingStatus';
const SET_C = 'setClear';
const SET_F = 'setFee';
const SET_OFFLINE_PAYMENT = 'setOfflinePayment';
const SET_FROM_TIPS = 'setFromTips';
const SET_FROM_OFFLINE = 'setFromOffline';

export const setProgressValue = createAction(SET_PV, props<{ progressValue: number }>());

export const setCurrentStep = createAction(SET_CS, props<{ currentStep: number }>());

export const setTotalSteps = createAction(SET_TS, props<{ totalSteps: number }>());

export const setCurrentStepName = createAction(SET_CSN, props<{ currentStepName: string }>());

export const setSpeakingtesttime = createAction(SET_STT, props<{ speakingtesttime: { from: Date, to: Date } }>());

export const setLrwtesttime = createAction(SET_LTT, props<{ lrwtesttime: { from: Date, to: Date } }>());

export const setUserProfile = createAction(SET_UP, props<{ userProfile: UserProfileResponse }>());

export const setActivePayment = createAction(SET_AP, props<{ activePayment: boolean }>());

export const setOfflinePayment = createAction(SET_OFFLINE_PAYMENT, props<{ isOffline: boolean }>());

export const setFromTips = createAction(SET_FROM_TIPS, props<{ fromTips: boolean }>());

export const setHasTimer = createAction(SET_HT, props<{ hasTimer: boolean }>());

export const setPayingStatus = createAction(SET_PS, props<{ status: IPaymentStatus }>());

export const setClear = createAction(SET_C);

export const setFee = createAction(SET_F, props<{ fee: ProductFee }>());

export const setFromOffline = createAction(SET_FROM_OFFLINE, props<{ fromOffline: boolean }>());
