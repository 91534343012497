import { Injectable } from '@angular/core';
import { Application, Booking } from '@idp-education/ors-test-taker-bff-client-v1';
import { BehaviorSubject } from 'rxjs';
import { DateTime } from 'luxon';

export type IState = {
  progressValue?: number,
  currentStep?: number,
  totalSteps?: number,
  currentStepName?: string,
  color?: 'blue' | 'purple',
  locLabel?: string | undefined,
  locLabelShort?: string | undefined,
  testFormat?: 'All',
  testType?: 'All',
  currentLoc?: [number, number],
  fromDate?: any,
  toDate?: any,
  resultUrl?: string | undefined,
  allSessions?: any,
  selectedTest?: any,
  mockMapbox?: boolean,
  mockSearchApi?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  private readonly initialState: IState = {
    progressValue: 0,
    currentStep: 0,
    totalSteps: 0,
    currentStepName: '',
    color: 'blue',
    mockMapbox: false,
    mockSearchApi: false
  };
  readonly timeFormat = 'YYYY-MM-DD HH:mm';
  state$ = new BehaviorSubject<IState>(this.initialState);
  private bookingObject = 'bookingObject';

  constructor() {
    const local = this.getFromLocalStorage();
    if (local !== this.initialState) {
      this.state$.next({
        ...local
      } as IState);
    }
  }

  getFromLocalStorage(): IState | null {
    try {
      return JSON.parse(localStorage.getItem(this.bookingObject) || '{}');
    } catch (error) {
      return null;
    }
  }

  changeState(myChange: IState) {
    this.state$.next(myChange);
    const temp = JSON.stringify({
      ...myChange,
    });
    localStorage.setItem(this.bookingObject, temp);
  }

  updateState(myChange: IState) {
    const newState = {
      ...this.state$.getValue(),
      ...myChange
    };
    this.state$.next(newState);
    const temp = JSON.stringify(newState);
    localStorage.setItem(this.bookingObject, temp);
  }

  getState() {
    return this.state$.asObservable();
  }

  clearLocalStorage() {
    localStorage.removeItem(this.bookingObject);
  }

  clearState() {
    this.state$.next(this.initialState);
  }
  getSpeakingAndLRW(application: Application): [Booking, Booking] {
    try {
      if (application?.bookings.length > 1) {
      return [
        application?.bookings?.find(i => (i.bookingLines[0].languageSkill.match(/[LRW]/))),
        application?.bookings?.find(i => i.bookingLines[0].languageSkill === 'S')
      ];
    } else {
      const listeningSkill = [ application?.bookings[0].bookingLines?.find(i => (i.languageSkill === 'L'))];
      const readingSkill = [ application?.bookings[0].bookingLines?.find(i => (i.languageSkill === 'R'))];
      const writingSkill = [ application?.bookings[0].bookingLines?.find(i => (i.languageSkill === 'W'))];
      const speakingSkill = [ application?.bookings[0].bookingLines?.find(i => (i.languageSkill === 'S'))];
      const lrwSkill = [...listeningSkill, ...readingSkill, ...writingSkill];
      return [
        {... application?.bookings[0], bookingLines: lrwSkill },
        {... application?.bookings[0], bookingLines: speakingSkill}
      ];
    }
    } catch (error) {
      return [null, null];
    }
  }
  getTestStartDate(test): string {
    const format = 'yyyy-MM-dd\'T\'HH:mm:ss';
    let startDate;
    if (test?.productName?.includes('OSR') && test?.speakingStartDateTimeUtc === '0001-01-01T00:00:00+00:00') {
      startDate = DateTime.fromISO(test?.lrwStartDateTimeUtc);
    } else {
      startDate = DateTime.fromISO(test?.speakingStartDateTimeUtc);
    }
    if (test?.productName?.includes('on Computer')) {
      startDate = startDate.setZone(test?.testLocationTimeZone).toFormat(format);
    } else {
      startDate = startDate.toFormat(format);
    }
    return startDate;
  }
}
