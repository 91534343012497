import { Directive, Host, OnInit, Optional } from '@angular/core';
import { PaypalComponent } from '../components/payment/paypal/paypal.component';
import { CreditCardComponent } from '../components/payment/credit-card/credit-card.component';
import { MockCreditCardComponent } from 'shared/components/payment/mock-credit-card/mock-credit-card.component';
export type IPaymentCard = CreditCardComponent | PaypalComponent;
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[isPaymentCard]'
})
export class IsPaymentCardDirective implements OnInit {
  host: IPaymentCard;
  constructor(
    @Optional() @Host() private hostPaypal: PaypalComponent,
    @Optional() @Host() private hostCredit: CreditCardComponent,
    @Optional() @Host() private hostMockCredit: MockCreditCardComponent) {
    if (this.hostPaypal) {
      this.hostPaypal.visible = false;
      this.host = this.hostPaypal;
    } else if (this.hostCredit) {
      this.hostCredit.visible = false;
      this.host = this.hostCredit;
    } else if (this.hostMockCredit) {
      this.hostMockCredit.visible = false;
      this.host = this.hostMockCredit as CreditCardComponent;
    }
  }
  ngOnInit(): void {
    this.host.visible = true;
  }

}
