import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-document-upload-form-actions',
  templateUrl: './document-upload-form-actions.component.html',
  styleUrls: ['./document-upload-form-actions.component.scss']
})
export class DocumentUploadFormActionsComponent {
  @Input() deletableImage = true;
  @Input() editableImage = false;
  @Input() editImageState: string;

  @Output() deleteUpload = new EventEmitter<void>();
  @Output() editIdDocumentImage = new EventEmitter<void>();
  @Output() onCropImage = new EventEmitter<void>();
  @Output() transformImage = new EventEmitter<'mirror' | 'rotate-left' | 'rotate-right'>();

}
