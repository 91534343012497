import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Auth } from 'aws-amplify';
import { gtmConst } from 'shared/utils/trackingConstants';

@Injectable({
  providedIn: 'root'
})
export class GtmTrackingService {
  previousUrl = '';
  currentStep = 0;
  dontUpdateCurrentStep = false;
  isLoggedIn: boolean;
  onceTrustActiveGroups: string;

  constructor(
    private gtmService: GoogleTagManagerService
  ) { }

  initGoogleAnalytics() {
    this.onceTrustActiveGroups = this.getConsentGroupsMatch();
    this.checkUserStatus();
  }

  updateCurrentStep(reduceStep?: any) {
    if (this.dontUpdateCurrentStep) {
      this.dontUpdateCurrentStep = false;
    } else {
      this.dontUpdateCurrentStep = !!reduceStep;
      this.currentStep += reduceStep ? -1 : 1;
    }
  }

  trackGa4(eventVal: any, titleVal: string, eventCategory?: string) {
    if (!this.onceTrustActiveGroups) {
      this.onceTrustActiveGroups = this.getConsentGroupsMatch();
    }
    if (!this.isLoggedIn) {
      this.checkUserStatus();
    }
    const gtmTag: any = this.formGtmEvents(eventVal, titleVal, eventCategory, this.previousUrl, this.currentStep);
    this.gtmService.pushTag(gtmTag);
  }

  formGtmEvents(event: string, pagename:string, eventCategory?:string, pageReferrerUrl?: string, currentStep?: number) {
      return {
        event: event,
        "event category": eventCategory || gtmConst.common.na,
        websitename: gtmConst.websiteName.bookingExperience,
        language: gtmConst.common.english,
        userstatus: this.isLoggedIn ? gtmConst.common.loggedIn : gtmConst.common.anonymous,
        pagename: pagename || '',
        page_referrer: pageReferrerUrl ? location.origin + pageReferrerUrl : gtmConst.common.na,
        version: gtmConst.common.v2,
        step: currentStep || 1,
        OnetrustActiveGroups: this.onceTrustActiveGroups
      };
  }

  getConsentGroupsMatch() {
    const cookieConsent = this.getCookieValue()
    const consentGroupsMatch = cookieConsent.match(/groups=([^&]*)/)
    const finalValue = []
    if (consentGroupsMatch?.length) {
      decodeURIComponent(consentGroupsMatch[1]).split(',').forEach(data => {
        if (data?.includes(':1')) {
          finalValue.push(data?.split(':')[0])
        }
      })
    }
    return finalValue.length ? ',' + finalValue.join(',') + ',' : '';
  }

  checkUserStatus() {
    Auth.currentAuthenticatedUser()?.then(user => {
      this.isLoggedIn = true;
      // this.userId = user.attributes.sub;
    }).catch(() => {
      this.isLoggedIn = false;
    });
  }

  getCookieValue(): string {
    const optanonConsent = document.cookie.split('; ').find(cookie => cookie.startsWith('OptanonConsent='));
    if (!optanonConsent) {
      return '';
    }
    return optanonConsent;
  }
}
