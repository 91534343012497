import { createAction, props } from '@ngrx/store';
import { Application, DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { ICoordinate } from './coordinate.interface';
import { CallToActionState } from 'store/my-tests/my-tests.reducer';

const SET_SELECTED_TEST = 'setSelectedTest';
const SET_TEST_CENTRE_DETAIL = 'setTestCentreDetail';
const SET_SSR_COMPONENT = 'setSsrComponent';
const SET_DATE_RANGE = 'setDateRange';
const SET_LOCATION = 'setLocation';
const SET_TEST_TYPE = 'setTestType';
const SET_TEST_FORMAT = 'setTestFormat';
const SET_ORIGINAL_APPLICATION = 'setOriginalApplication';
const RESET = 'ResetState';
const SSR_FEE = 'ssrFee';
const HAS_UNPAID_OSR_APP = 'setUnpaidOSRApp';
const SET_CALL_TO_ACTION_STATE = 'setCallToActionState';
const SET_OSR_CAP = 'setOSRCurrentApplication';
const SET_GO_TO_APPL_UPDATE = 'setGoToApplUpdate';

export const setSelectedTest = createAction(SET_SELECTED_TEST, props<{ selectedTest: any }>());
export const setTestCentreDetail = createAction(SET_TEST_CENTRE_DETAIL, props<{ testCentreName: string, testCentreAddress: string }>());
export const setDateRange = createAction(SET_DATE_RANGE, props<{ from: string, to: string }>());
export const setLocation = createAction(SET_LOCATION, props<{ currentLoc: ICoordinate, locLabel: string, locLabelShort: string }>());
export const setSsrComponent = createAction(SET_SSR_COMPONENT, props<{ ssrComponent: string }>());
export const setTestType = createAction(SET_TEST_TYPE, props<{ testType: string }>());
export const setTestFormat = createAction(SET_TEST_FORMAT, props<{ testFormat: string }>());
export const setOriginalApplication = createAction(SET_ORIGINAL_APPLICATION, props<{ application: DashboardItem }>());
export const setSSRFee = createAction(SSR_FEE, props<{ ssrFee: any }>());
export const ResetState = createAction(RESET);
export const setUnpaidOSRApp = createAction(HAS_UNPAID_OSR_APP, props<{ hasUnpaidApp: boolean }>());
export const setCallToActionState = createAction(SET_CALL_TO_ACTION_STATE, props<{ callToActionState: CallToActionState }>());
export const setOSRCurrentApplication = createAction(SET_OSR_CAP, props<{ currentOSRApplication: Application }>());
export const setGoToApplUpdate = createAction(SET_GO_TO_APPL_UPDATE, props<{ goToApplUpdate: boolean }>());
