import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
type IConsentFormDetails = {
  fileName,
  fileDate,
  note?,
  url?
};
@Component({
  selector: 'app-upload-consent-form-modal',
  templateUrl: './upload-consent-form-modal.component.html',
  styleUrls: ['./upload-consent-form-modal.component.scss']
})
export class UploadConsentFormModalComponent {
  form: UntypedFormGroup;
  @ViewChild('confirmation') confirmation: ConfirmModalComponent;
  private _consentFormDetail: IConsentFormDetails;
  set consentFormDetail(v: IConsentFormDetails) {
    this._consentFormDetail = v;
    if (v?.url?.length > 0) {
      this.fetchFile(v.url).then((blob) => {
        if (blob) {
          const file = new File([blob], v.fileName);
          this.form.get('file').setValue(file);
        }
      });
    }
    if (v) {
      this.form.get('note').setValue(v.note);
    }
  }
  get consentFormDetail() {
    return this._consentFormDetail;
  }
  public uploadDate: Date;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastrService
  ) {
    this.form = new UntypedFormGroup({
      note: new UntypedFormControl(''),
      file: new UntypedFormControl('', Validators.required)
    });
  }

  onFileSelect(file: File): void {
    if (file?.type === 'application/pdf') {
      if (this.isFileSizeValid(file, 5 * 1024 * 1024)) {
        this.consentFormDetail = {
          fileDate: new Date(),
          fileName: file.name,
        };
        this.form.get('file').setValue(file);
        this.form.markAsDirty();
        this.uploadDate = new Date();
      } else {
        this.toastService.error('Please make sure the file size is less than 5MB');
      }
    } else {
      this.toastService.error('Please drop a PDF file.');
    }
  }

  private isFileSizeValid(file: File, maxSizeInBytes: number): boolean {
    return file && file.size <= maxSizeInBytes;
  }

  onSave(): void {
    this.activeModal.close({
      consentForm: this.form.get('file').value,
      note: this.form.get('note').value
    });
  }
  removeFile() {
    this.form.get('file').setValue(null);
    this.consentFormDetail = null;
    this.form.markAsDirty();
  }
  fetchFile(url) {
    if (!url) {
      return;
    }
    return (fetch(url)).then((response) => {
      if (!response.ok) {
        throw new Error(`File error! Status: ${response.status}`);
      }
      return response.blob();
    }).catch((error) => {
      this.toastService.error('File not found');
    });
  }
  closeModal(activeModal: NgbActiveModal) {
    if (this.form.dirty && this.form.valid) {
      this.confirmation.open({ keyboard: false }).result.then((result: 'accept' | 'reject') => {
        if (result === 'reject') {
          this.activeModal.dismiss();
        }
      });
    } else {
      this.activeModal.dismiss();
    }
  }
}
