<ng-template #description>
    Please upload or take a clear photo of your ID.
</ng-template>

<span class="content-title text-lg-start text-center" i18n *ngIf="showTitle && !renderHeading">
    <ng-container *ngTemplateOutlet="description"></ng-container>
</span>

<h2 class="content-title text-lg-start text-center" i18n *ngIf="showTitle && renderHeading">
    <ng-container *ngTemplateOutlet="description"></ng-container>
</h2>

<feature-toggle-provider [features]="featureFlags">
    <div class="flexbox flex-column align-items-center align-items-lg-start">
        <span *ngIf="showTitle" class="content-title mt-2 Instructional-text" i18n>File types accepted are JPG and PNG</span>
        <div class="upload-box-wrapper">
          <ng-container *ngIf="completeUpload">
            <app-document-upload-form-actions
              [editImageState]="editImageState"
              (deleteUpload)="deleteUpload()"
              (onCropImage)="openCropModal(cropContent)"
              (transformImage)="transformImage(file, $event)"
            ></app-document-upload-form-actions>
          </ng-container>
            <div #uploadBox
                 class="upload-box"
                 [class.bg-secondary]="!visibleButtons && hasUploadError"
                 [class.completeUpload]="completeUpload">
              <ng-container *ngIf="visibleButtons">
                <input type="file" hidden #inputfile accept=".png, .jpg, .jpeg" multiple="false">
                <app-upload-type
                  [hasWebcam]="hasWebcam"
                  (openCameraModal)="openCameraModal(content)"
                  (onUploadClick)="onUploadClick()"
                ></app-upload-type>
              </ng-container>

                <div *ngIf="!visibleButtons && !hasUploadError && !completeUpload && !visibleCamera"
                    class="flexbox flex-column justify-content-center align-items-center">
                    <h6 class="m-0" *ngIf="percent === 0" i18n>Trying to send...</h6>
                    <h6 class="m-0" *ngIf="percent > 0" i18n>Your passport is uploading {{ percent }}%</h6>
                    <h6 class="m-0" *ngIf="percent === 100" i18n>Complete</h6>
                    <button *ngIf="percent < 100" class="btn btn-upload-action" (click)="abortUpload()"
                        i18n>Cancel</button>
                </div>
                <div *ngIf="!visibleButtons && hasUploadError"
                    class="flexbox flex-column justify-content-center align-items-center  text-light w-75">
                    <span class="material-icons" i18n>
                        error_outline
                    </span>
                    <span class="small" *ngIf="uploadError" i18n>{{ uploadError }}</span>
                    <span class="small text-center" *ngIf="!uploadError">
                        Something went wrong <br> and we couldn't upload your passport
                    </span>
                    <div class="flexbox justify-content-center align-items-center w-100 mt-2">
                        <!-- <button i18n class="btn btn-uploadAction" (click)="cancelUpload()">
                Cancel
              </button> -->
                        <button *ngIf="errorCode !== MAX_FILE_SIZE" class="btn btn-blue w-100 ms-1"
                            (click)="tryAgainUpload()">
                            <span class="material-icons" i18n>
                                refresh
                            </span>
                            <span i18n>Try again</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</feature-toggle-provider>

<ng-template #content let-modal>
    <div class="">
        <button type="button" class="close btn btn-link" aria-label="Close"
            (click)="closeCameraModal()">
            <span class="material-icons">
                close
            </span>
        </button>
    </div>
    <div class="modal-body">
        <div class="camera-container" width="640" height="480">
            <video id="webcam" autoplay playsinline></video>
            <canvas id="canvas" class="d-none"></canvas>
            <div class="camera-actions-wrapper">
                <button class="btn btn-link  btn-flip" (click)="flipCamera()">
                    <span class="material-icons">
                        flip_camera_android
                    </span>
                </button>
                <button class="btn-circle" [disabled]="!enableCameraButton" (click)="takeSnap()"> </button>
                <span></span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cropContent let-modal>
    <div class="">
        <button type="button" class="float-end btn btn-link" aria-label="Close"
            (click)="modalCrop.dismiss('Cross click')">
            <span class="material-icons">
                close
            </span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex flex-row-reverse justify-content-between">
            <div class="" style="height: 400px;width: 534px;">
                <image-cropper [imageURL]="imageUrl" [aspectRatio]="4 / 3" [hideResizeSquares]="true" format="png"
                    [output]="'base64'" (imageCropped)="imageCropped($event)">
                </image-cropper>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-singleAction" (click)="modalCrop.close('Save click')" i18n>Crop</button>
    </div>
</ng-template>

<app-confirm-modal #deletePhotoModal title="Are you sure you wish to delete this photo?"
    imageUrl="../../../../assets/images/Girl green organic shape.png">
</app-confirm-modal>
