import { Application } from '@idp-education/ors-test-taker-bff-client-v1';
import { createAction, props } from '@ngrx/store';
import { ApplicationResponse, UpdateUnderAgeConsentDetailsRequest } from '@idp-education/ors-test-taker-bff-client-v1/model/models';

export const SET_AP = 'setApplication';
export const SET_CAP = 'setCurrentApplication';

const UCF = 'uploadConsentForm';

const UCF_SUCCESS = 'uploadConsentFormSuccess';

const UCF_FAILED = 'uploadConsentFormFailed';

const UACF = 'updateApplicationConsentForm';

const UACF_SUCCESS = 'updateApplicationConsentFormSuccess';

const UACF_FAILED = 'updateApplicationConsentFormFailed';

export const setApplication = createAction(SET_AP, props<{ application: Application }>());
export const setCurrentApplication = createAction(SET_CAP, props<{ application: Application }>());

export const uploadConsentForm =
  createAction(UCF, props<{ applicationId: string, applicationVersion: string, consentForm: File, note?: string, s3UploadConfig?: any }>());

export const uploadConsentFormSuccess =
  createAction(UCF_SUCCESS, props<{ applicationId: string, s3Details: UpdateUnderAgeConsentDetailsRequest }>());

export const uploadConsentFormFailed = createAction(UCF_FAILED, props<{ error: any }>());


export const updateApplicationConsentForm =
  createAction(UACF, props<{xCorrelationId: string; applicationId: string; update: UpdateUnderAgeConsentDetailsRequest}>());

export const updateApplicationConsentFormSuccess = createAction(UACF_SUCCESS, props<{ updatedApplication: ApplicationResponse }>());

export const updateApplicationConsentFormFailed = createAction(UACF_FAILED, props<{ error: any }>());
