export const Languages = [
    {
        name: 'Abkhaz',
        nativeName: 'аҧсуа'
    },
    {
        name: 'Afar',
        nativeName: 'Afaraf'
    },
    {
        name: 'Afrikaans',
        nativeName: 'Afrikaans'
    },
    {
        name: 'Akan',
        nativeName: 'Akan'
    },
    {
        name: 'Albanian',
        nativeName: 'Shqip'
    },
    {
        name: 'Amharic',
        nativeName: 'አማርኛ'
    },
    {
        name: 'Arabic',
        nativeName: 'العربية'
    },
    {
        name: 'Aragonese',
        nativeName: 'Aragonés'
    },
    {
        name: 'Armenian',
        nativeName: 'Հայերեն'
    },
    {
        name: 'Assamese',
        nativeName: 'অসমীয়া'
    },
    {
        name: 'Avaric',
        nativeName: 'авар мацӀ, магӀарул мацӀ'
    },
    {
        name: 'Avestan',
        nativeName: 'avesta'
    },
    {
        name: 'Aymara',
        nativeName: 'aymar aru'
    },
    {
        name: 'Azerbaijani',
        nativeName: 'azərbaycan dili'
    },
    {
        name: 'Bambara',
        nativeName: 'bamanankan'
    },
    {
        name: 'Bashkir',
        nativeName: 'башҡорт теле'
    },
    {
        name: 'Basque',
        nativeName: 'euskara, euskera'
    },
    {
        name: 'Belarusian',
        nativeName: 'Беларуская'
    },
    {
        name: 'Bengali',
        nativeName: 'বাংলা'
    },
    {
        name: 'Bihari',
        nativeName: 'भोजपुरी'
    },
    {
        name: 'Bislama',
        nativeName: 'Bislama'
    },
    {
        name: 'Bosnian',
        nativeName: 'bosanski jezik'
    },
    {
        name: 'Breton',
        nativeName: 'brezhoneg'
    },
    {
        name: 'Bulgarian',
        nativeName: 'български език'
    },
    {
        name: 'Burmese',
        nativeName: 'ဗမာစာ'
    },
    {
        name: 'Catalan; Valencian',
        nativeName: 'Català'
    },
    {
        name: 'Chamorro',
        nativeName: 'Chamoru'
    },
    {
        name: 'Chechen',
        nativeName: 'нохчийн мотт'
    },
    {
        name: 'Chichewa; Chewa; Nyanja',
        nativeName: 'chiCheŵa, chinyanja'
    },
    {
        name: 'Chinese',
        nativeName: '中文 (Zhōngwén), 汉语, 漢語'
    },
    {
        name: 'Chuvash',
        nativeName: 'чӑваш чӗлхи'
    },
    {
        name: 'Cornish',
        nativeName: 'Kernewek'
    },
    {
        name: 'Corsican',
        nativeName: 'corsu, lingua corsa'
    },
    {
        name: 'Cree',
        nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ'
    },
    {
        name: 'Croatian',
        nativeName: 'hrvatski'
    },
    {
        name: 'Czech',
        nativeName: 'česky, čeština'
    },
    {
        name: 'Danish',
        nativeName: 'dansk'
    },
    {
        name: 'Divehi; Dhivehi; Maldivian;',
        nativeName: 'ދިވެހި'
    },
    {
        name: 'Dutch',
        nativeName: 'Nederlands, Vlaams'
    },
    {
        name: 'English',
        nativeName: 'English'
    },
    {
        name: 'Esperanto',
        nativeName: 'Esperanto'
    },
    {
        name: 'Estonian',
        nativeName: 'eesti, eesti keel'
    },
    {
        name: 'Ewe',
        nativeName: 'Eʋegbe'
    },
    {
        name: 'Faroese',
        nativeName: 'føroyskt'
    },
    {
        name: 'Fijian',
        nativeName: 'vosa Vakaviti'
    },
    {
        name: 'Finnish',
        nativeName: 'suomi, suomen kieli'
    },
    {
        name: 'French',
        nativeName: 'français, langue française'
    },
    {
        name: 'Fula; Fulah; Pulaar; Pular',
        nativeName: 'Fulfulde, Pulaar, Pular'
    },
    {
        name: 'Galician',
        nativeName: 'Galego'
    },
    {
        name: 'Georgian',
        nativeName: 'ქართული'
    },
    {
        name: 'German',
        nativeName: 'Deutsch'
    },
    {
        name: 'Greek, Modern',
        nativeName: 'Ελληνικά'
    },
    {
        name: 'Guaraní',
        nativeName: 'Avañeẽ'
    },
    {
        name: 'Gujarati',
        nativeName: 'ગુજરાતી'
    },
    {
        name: 'Haitian; Haitian Creole',
        nativeName: 'Kreyòl ayisyen'
    },
    {
        name: 'Hausa',
        nativeName: 'Hausa, هَوُسَ'
    },
    {
        name: 'Hebrew',
        nativeName: 'עברית'
    },
    {
        name: 'Hebrew',
        nativeName: 'עברית'
    },
    {
        name: 'Herero',
        nativeName: 'Otjiherero'
    },
    {
        name: 'Hindi',
        nativeName: 'हिन्दी, हिंदी'
    },
    {
        name: 'Hiri Motu',
        nativeName: 'Hiri Motu'
    },
    {
        name: 'Hungarian',
        nativeName: 'Magyar'
    },
    {
        name: 'Interlingua',
        nativeName: 'Interlingua'
    },
    {
        name: 'Indonesian',
        nativeName: 'Bahasa Indonesia'
    },
    {
        name: 'Interlingue',
        nativeName: 'Originally called Occidental; then Interlingue after WWII'
    },
    {
        name: 'Irish',
        nativeName: 'Gaeilge'
    },
    {
        name: 'Igbo',
        nativeName: 'Asụsụ Igbo'
    },
    {
        name: 'Inupiaq',
        nativeName: 'Iñupiaq, Iñupiatun'
    },
    {
        name: 'Ido',
        nativeName: 'Ido'
    },
    {
        name: 'Icelandic',
        nativeName: 'Íslenska'
    },
    {
        name: 'Italian',
        nativeName: 'Italiano'
    },
    {
        name: 'Inuktitut',
        nativeName: 'ᐃᓄᒃᑎᑐᑦ'
    },
    {
        name: 'Japanese',
        nativeName: '日本語 (にほんご／にっぽんご)'
    },
    {
        name: 'Javanese',
        nativeName: 'basa Jawa'
    },
    {
        name: 'Kalaallisut, Greenlandic',
        nativeName: 'kalaallisut, kalaallit oqaasii'
    },
    {
        name: 'Kannada',
        nativeName: 'ಕನ್ನಡ'
    },
    {
        name: 'Kanuri',
        nativeName: 'Kanuri'
    },
    {
        name: 'Kashmiri',
        nativeName: 'कश्मीरी, كشميري‎'
    },
    {
        name: 'Kazakh',
        nativeName: 'Қазақ тілі'
    },
    {
        name: 'Khmer',
        nativeName: 'ភាសាខ្មែរ'
    },
    {
        name: 'Kikuyu, Gikuyu',
        nativeName: 'Gĩkũyũ'
    },
    {
        name: 'Kinyarwanda',
        nativeName: 'Ikinyarwanda'
    },
    {
        name: 'Kirghiz, Kyrgyz',
        nativeName: 'кыргыз тили'
    },
    {
        name: 'Komi',
        nativeName: 'коми кыв'
    },
    {
        name: 'Kongo',
        nativeName: 'KiKongo'
    },
    {
        name: 'Korean',
        nativeName: '한국어 (韓國語), 조선말 (朝鮮語)'
    },
    {
        name: 'Kurdish',
        nativeName: 'Kurdî, كوردی‎'
    },
    {
        name: 'Kwanyama, Kuanyama',
        nativeName: 'Kuanyama'
    },
    {
        name: 'Latin',
        nativeName: 'latine, lingua latina'
    },
    {
        name: 'Luxembourgish, Letzeburgesch',
        nativeName: 'Lëtzebuergesch'
    },
    {
        name: 'Luganda',
        nativeName: 'Luganda'
    },
    {
        name: 'Limburgish, Limburgan, Limburger',
        nativeName: 'Limburgs'
    },
    {
        name: 'Lingala',
        nativeName: 'Lingála'
    },
    {
        name: 'Lao',
        nativeName: 'ພາສາລາວ'
    },
    {
        name: 'Lithuanian',
        nativeName: 'lietuvių kalba'
    },
    {
        name: 'Luba-Katanga',
        nativeName: ''
    },
    {
        name: 'Latvian',
        nativeName: 'latviešu valoda'
    },
    {
        name: 'Manx',
        nativeName: 'Gaelg, Gailck'
    },
    {
        name: 'Macedonian',
        nativeName: 'македонски јазик'
    },
    {
        name: 'Malagasy',
        nativeName: 'Malagasy fiteny'
    },
    {
        name: 'Malay',
        nativeName: 'bahasa Melayu, بهاس ملايو‎'
    },
    {
        name: 'Malayalam',
        nativeName: 'മലയാളം'
    },
    {
        name: 'Maltese',
        nativeName: 'Malti'
    },
    {
        name: 'Māori',
        nativeName: 'te reo Māori'
    },
    {
        name: 'Marathi (Marāṭhī)',
        nativeName: 'मराठी'
    },
    {
        name: 'Marshallese',
        nativeName: 'Kajin M̧ajeļ'
    },
    {
        name: 'Mongolian',
        nativeName: 'монгол'
    },
    {
        name: 'Nauru',
        nativeName: 'Ekakairũ Naoero'
    },
    {
        name: 'Navajo, Navaho',
        nativeName: 'Diné bizaad, Dinékʼehǰí'
    },
    {
        name: 'Norwegian Bokmål',
        nativeName: 'Norsk bokmål'
    },
    {
        name: 'North Ndebele',
        nativeName: 'isiNdebele'
    },
    {
        name: 'Nepali',
        nativeName: 'नेपाली'
    },
    {
        name: 'Ndonga',
        nativeName: 'Owambo'
    },
    {
        name: 'Norwegian Nynorsk',
        nativeName: 'Norsk nynorsk'
    },
    {
        name: 'Norwegian',
        nativeName: 'Norsk'
    },
    {
        name: 'Nuosu',
        nativeName: 'ꆈꌠ꒿ Nuosuhxop'
    },
    {
        name: 'South Ndebele',
        nativeName: 'isiNdebele'
    },
    {
        name: 'Occitan',
        nativeName: 'Occitan'
    },
    {
        name: 'Ojibwe, Ojibwa',
        nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
    },
    {
        name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
        nativeName: 'ѩзыкъ словѣньскъ'
    },
    {
        name: 'Oromo',
        nativeName: 'Afaan Oromoo'
    },
    {
        name: 'Oriya',
        nativeName: 'ଓଡ଼ିଆ'
    },
    {
        name: 'Ossetian, Ossetic',
        nativeName: 'ирон æвзаг'
    },
    {
        name: 'Panjabi, Punjabi',
        nativeName: 'ਪੰਜਾਬੀ, پنجابی‎'
    },
    {
        name: 'Pāli',
        nativeName: 'पाऴि'
    },
    {
        name: 'Persian',
        nativeName: 'فارسی'
    },
    {
        name: 'Polish',
        nativeName: 'polski'
    },
    {
        name: 'Pashto, Pushto',
        nativeName: 'پښتو'
    },
    {
        name: 'Portuguese',
        nativeName: 'Português'
    },
    {
        name: 'Quechua',
        nativeName: 'Runa Simi, Kichwa'
    },
    {
        name: 'Romansh',
        nativeName: 'rumantsch grischun'
    },
    {
        name: 'Kirundi',
        nativeName: 'kiRundi'
    },
    {
        name: 'Romanian, Moldavian, Moldovan',
        nativeName: 'română'
    },
    {
        name: 'Russian',
        nativeName: 'русский язык'
    },
    {
        name: 'Sanskrit (Saṁskṛta)',
        nativeName: 'संस्कृतम्'
    },
    {
        name: 'Sardinian',
        nativeName: 'sardu'
    },
    {
        name: 'Sindhi',
        nativeName: 'सिन्धी, سنڌي، سندھی‎'
    },
    {
        name: 'Northern Sami',
        nativeName: 'Davvisámegiella'
    },
    {
        name: 'Samoan',
        nativeName: 'gagana faa Samoa'
    },
    {
        name: 'Sango',
        nativeName: 'yângâ tî sängö'
    },
    {
        name: 'Serbian',
        nativeName: 'српски језик'
    },
    {
        name: 'Scottish Gaelic; Gaelic',
        nativeName: 'Gàidhlig'
    },
    {
        name: 'Shona',
        nativeName: 'chiShona'
    },
    {
        name: 'Sinhala, Sinhalese',
        nativeName: 'සිංහල'
    },
    {
        name: 'Slovak',
        nativeName: 'slovenčina'
    },
    {
        name: 'Slovene',
        nativeName: 'slovenščina'
    },
    {
        name: 'Somali',
        nativeName: 'Soomaaliga, af Soomaali'
    },
    {
        name: 'Southern Sotho',
        nativeName: 'Sesotho'
    },
    {
        name: 'Spanish; Castilian',
        nativeName: 'español, castellano'
    },
    {
        name: 'Sundanese',
        nativeName: 'Basa Sunda'
    },
    {
        name: 'Swahili',
        nativeName: 'Kiswahili'
    },
    {
        name: 'Swati',
        nativeName: 'SiSwati'
    },
    {
        name: 'Swedish',
        nativeName: 'svenska'
    },
    {
        name: 'Tamil',
        nativeName: 'தமிழ்'
    },
    {
        name: 'Telugu',
        nativeName: 'తెలుగు'
    },
    {
        name: 'Tajik',
        nativeName: 'тоҷикӣ, toğikī, تاجیکی‎'
    },
    {
        name: 'Thai',
        nativeName: 'ไทย'
    },
    {
        name: 'Tigrinya',
        nativeName: 'ትግርኛ'
    },
    {
        name: 'Tibetan Standard, Tibetan, Central',
        nativeName: 'བོད་ཡིག'
    },
    {
        name: 'Turkmen',
        nativeName: 'Türkmen, Түркмен'
    },
    {
        name: 'Tagalog',
        nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
    },
    {
        name: 'Tswana',
        nativeName: 'Setswana'
    },
    {
        name: 'Tonga (Tonga Islands)',
        nativeName: 'faka Tonga'
    },
    {
        name: 'Turkish',
        nativeName: 'Türkçe'
    },
    {
        name: 'Tsonga',
        nativeName: 'Xitsonga'
    },
    {
        name: 'Tatar',
        nativeName: 'татарча, tatarça, تاتارچا‎'
    },
    {
        name: 'Twi',
        nativeName: 'Twi'
    },
    {
        name: 'Tahitian',
        nativeName: 'Reo Tahiti'
    },
    {
        name: 'Uighur, Uyghur',
        nativeName: 'Uyƣurqə, ئۇيغۇرچە‎'
    },
    {
        name: 'Ukrainian',
        nativeName: 'українська'
    },
    {
        name: 'Urdu',
        nativeName: 'اردو'
    },
    {
        name: 'Uzbek',
        nativeName: 'zbek, Ўзбек, أۇزبېك‎'
    },
    {
        name: 'Venda',
        nativeName: 'Tshivenḓa'
    },
    {
        name: 'Vietnamese',
        nativeName: 'Tiếng Việt'
    },
    {
        name: 'Volapük',
        nativeName: 'Volapük'
    },
    {
        name: 'Walloon',
        nativeName: 'Walon'
    },
    {
        name: 'Welsh',
        nativeName: 'Cymraeg'
    },
    {
        name: 'Wolof',
        nativeName: 'Wollof'
    },
    {
        name: 'Western Frisian',
        nativeName: 'Frysk'
    },
    {
        name: 'Xhosa',
        nativeName: 'isiXhosa'
    },
    {
        name: 'Yiddish',
        nativeName: 'ייִדיש'
    },
    {
        name: 'Yoruba',
        nativeName: 'Yorùbá'
    },
    {
        name: 'Zhuang, Chuang',
        nativeName: 'Saɯ cueŋƅ, Saw cuengh'
    }
];
