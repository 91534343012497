<div class="form-group custom-dropdown">
  <div
    [ngClass]="isOneLine && 'd-flex justify-content-md-start align-items-md-center justify-content-center align-items-start flex-column flex-md-row'"
    [ngStyle]="{width: width}"
    [class.disabled]="inputDisabled"
  >
    <div style="overflow: hidden;min-width: fit-content;">
      <div *ngIf="title" [title]="title" class="form-label" i18n>{{ title }}</div>
    </div>
    <div class="d-flex align-items-start dropdown-wrapper"
      [class.has-input-append]="showTick && control && control.valid && (control.value || (!control.value && touched)) && isClose">
      <div class="dropdown d-flex flex-column"
        [class.has-input-group-append]="showTick && control && control.valid && (control.value || (!control.value && touched)) && isClose">
        <div class="d-flex justify-content-evenly">
          <div class="select-trigger form-control d-flex justify-content-between align-items-center field-height"
            [class.removedBorder]="showTick && control && control.valid && (control.value || (!control.value && touched))"
            [class.isOpenList]="isOpen || isAppend" (resized)="eclipseText()"
            [class.nudgeInput]='control && control.invalid && control.touched' [ngbTooltip]="tooltip ? placeholder : null" container="body"
            #selectedValueWrapper [title]="label">
            <ng-template [ngIf]="isClose" [ngIfElse]="searchTpl">
              <span [class.isPlaceholder]="!model" [class.selectedItem]="model" class="" #selectedValue tabindex="0"
                (focus)="markAsTouched()" (keyup)="onKeyPress($event)" (blur)="leaveComponent()">
                {{label}}
              </span>
            </ng-template>
            <ng-template #searchTpl>
              <div class="input-group h-100 float-start" [style.width]="'90%'">
                <input #searchInput [(ngModel)]='searchValue' (keyup)="onKeyPress($event)"
                  class="form-control w-100 h-100" [id]="this.searchControlId" tabindex (blur)="leaveComponent()">
              </div>
            </ng-template>
            <span class="float-end d-flex align-items-center justify-content-end " [style.width]="'10%'">
              <div class="w-100 float-start d-flex align-items-center star-box" *ngIf="!model">
                <span class="material-icons dropdown-arrow-close" *ngIf="isClose">
                  arrow_drop_down
                </span>
                <span class="material-icons  w-50 float-start dropdown-arrow-open" *ngIf="isOpen">
                  arrow_drop_up
                </span>
              </div>
              <span *ngIf="model && !inputDisabled" class="w-100 btn star-box d-flex"
                (click)="onRemoveModel()" (keyup)="onRemoveModel()">
                <span class="material-icons closeIcon">
                  close
                </span>
              </span>
            </span>
          </div>
          <div class="input-group-append"
            *ngIf="showTick && control && control.valid && (control.value || (!control.value && touched))">
            <span class="input-group-text" id="basic-addon">
              <span class="material-icons">
                done
              </span>
            </span>
          </div>
        </div>
        <div *ngIf="isOpen" #dropdown>
          <div class="select-menu">
            <div class="select-menu-inner w-100" [style.height.px]="((options && options.length) || 1) * 32">
              <div *ngIf="!hasOptions" class="no-results-item w-100">No results found...</div>
              <ng-container *ngFor="let option of options">
                <div class="select-item w-100" [id]="option[idKey]" [class.isActive]="isActive(option)"
                  [class.isSelected]="option.__isSelected" (click)="onSelectOption(option)" (keyup)="onSelectOption(option)"
                  [title]="option[labelKey]">
                  <span [id]="option[idKey]">{{ option[labelKey] }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="isAppend" #dropdown>
          <div class="select-menu">
            <div class="select-menu-inner w-100">
              <div class="p-2 w-100">
                <input class="form-control form-control-custom w-100 append-input" maxlength="50" #addNewOptionsInput
                  [(ngModel)]="addNewOptionValue" tabindex (ngModelChange)="onUpdateNewOption()"
                  (focus)="markAsTouched()" (keyup)="onAddOptionKeyUp($event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-1 ms-1 required-star custom-validation-error">
        <span *ngIf="required">*</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(control?.invalid && control?.touched) || (control?.invalid && showValidationError) || (control?.pristine && control?.value && defaultShowError)">
    <app-field-errors [control]="control" [componentId]="'control'">
    </app-field-errors>
  </ng-container>
</div>
