import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CircularBuffer } from 'shared/services/buffer.service';

@Injectable({
  providedIn: 'root'
})
export class XhrDetailsService {
  private latestXhrDetails: {
    method
    url
    status?
    statusText?
    headers?,
    correlationId
  };

  constructor(private buffer: CircularBuffer) {
  }
  setLatestXhrDetails(request: HttpRequest<any>, error?: any): void {
    try {
      const correlationId = request?.headers?.get('X-Correlation-Id');
      if (!correlationId) {
        return;
      }
      const method = request.method;
      const url = request.url;
      const status = error?.status;
      const statusText = error?.statusText;
      const headers = request.headers;
      this.latestXhrDetails = {
        method,
        url,
        status,
        statusText,
        correlationId
      };
      this.buffer.enqueue(this.latestXhrDetails);
    } catch (e) { /* empty */ }
  }

  getLatestXhrDetails() {
    return this.latestXhrDetails;
  }

  getMostRecentXHRs() {
    return this.buffer.buffer;
  }
}
