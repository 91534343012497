import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import * as worker from 'worker-timers';
import { CalcRemainingTimeService } from '../../services/calcRemaining-time.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() targetTime: DateTime = null;
  @Input() currentTime = null;
  @Input() timerText = null;
  @Input() isOSRPaymentTimer = false;
  @Output() timeDone = new EventEmitter();
  timerr = null;
  ctime = null;
  ttime = null;
  showTime = null;

  constructor(private calcRemainingTime: CalcRemainingTimeService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentTime.currentValue || changes.targetTime.currentValue) {
      this.prepareTimes();
    }
  }

  private prepareTimes() {
    this.ctime = DateTime.now();
    this.ttime = this.targetTime;
  }

  ngOnInit(): void {
    this.prepareTimes();
    this.checkTimer();
    this.timerr = worker.setInterval(() => {
      this.checkTimer();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.timerr) {
      worker.clearInterval(this.timerr);
      this.timerr = null;
    }
  }

  checkTimer() {
    if (this.ttime) {
      const duration = this.ttime.diff(DateTime.now(), ['hours', 'minutes', 'seconds']);
      const asSeconds = duration.as('seconds');
      if (asSeconds < 1) {
        this.showTime = null;
        this.timeDone.emit();
        if (this.timerr) {
          worker.clearInterval(this.timerr);
          this.timerr = null;
        }
      } else {
        this.showTime = this.calcRemainingTime.getTime(asSeconds, 'minutes');
      }
    }
  }
}
