import { Component, Input } from '@angular/core';
import { formatCurrencyTwoDecimals } from '../../../util';

@Component({
  selector: 'app-ssr-test-fee',
  templateUrl: './ssr-test-fee.component.html'
})
export class SsrTestFeeComponent {
  @Input() testLocationId = '';
  @Input() testFee: any = {};

  formatCurrency() {
    if (this.testFee) {
      return formatCurrencyTwoDecimals(
        this.testFee.totalAmount,
        this.testFee.decimalSeparator,
        this.testFee.currencySymbol,
        this.testFee.currencyCodeIso3
      );
    } else {
      return '';
    }
  }
}
