import { Injectable } from '@angular/core';
import { ReferenceDataService } from '@idp-education/ors-test-taker-bff-client-v1/api/referenceData.service';
import {
  Country,
  Nationality,
  Territory,
  IdentificationType,
  Language,
  OccupationSector,
  TestReason,
  OccupationLevel,
  EducationLevel,
  Gender,
  GetTestLocationIdentificationTypesItem,
  TestCentreService
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map, pluck, switchMap, tap } from 'rxjs/operators';
import {
  setCountries,
  setNationality,
  setIdentificationType,
  setTerritory,
  setFirstLanguages,
  setOccupationSector,
  setTestReason,
  setOccupationLevel,
  setEducationLevel,
  setGender,
  setAllIdentificationType
} from 'src/app/pages/onboarding/store/onboarding.actions';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { IModel } from '../../models/components/dropdown';
import { isString } from 'lodash';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private store: Store<{ onboardingStore }>,
              private referenceDataService: ReferenceDataService,
              private testCentreService: TestCentreService,
              private loadingService: LoadingService) {
  }


  get GetCountries(): Observable<Country[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.Countries).pipe(first(), switchMap((x) => {
      if (!x || (x as Country[]).length < 1) {
        return this.referenceDataService.getCountry().pipe(tap(c => {
          this.store.dispatch(setCountries({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get GetNationality(): Observable<Nationality[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.nationality).pipe(first(), switchMap((x) => {
      if (!x || (x as Nationality[]).length < 1) {
        return this.referenceDataService.getNationalities().pipe(tap(c => {
          this.store.dispatch(setNationality({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }
  GetTerritory(countryId: string): Observable<Territory[]> {
    // this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.territories).pipe(
      map((x: { [countryId: string]: Territory[] }): Territory[] => {
        if (x) {
          return x[countryId];
        }
        return null;
      }), switchMap((x) => {
        if (!x || !Array.isArray(x)) {
          return this.referenceDataService.getTerritories(null, null, countryId).pipe(tap(c => {
            this.store.dispatch(setTerritory({ list: c.items, countryId }));
            this.loadingService.decreaseLoadingCounter();
          }), map(data => data[countryId]));
        } else {
          this.loadingService.decreaseLoadingCounter();
          return of(x);
        }
      })
    );
  }

  getTestLocationIdentificationTypes(testLocationId: string): Observable<GetTestLocationIdentificationTypesItem[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.identificationType).pipe(first(), switchMap((x) => {
      if (!x || (x as GetTestLocationIdentificationTypesItem[]).length < 1) {
        return this.testCentreService.testLocationIdentificationTypes(uuid.v4(), testLocationId).pipe(tap(c => {
          this.store.dispatch(setIdentificationType({ list: c }));
          this.loadingService.decreaseLoadingCounter();
        }));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  public convertCountryIdToCountryCode(countryId: string | IModel<any>, countryList: Country[] = []): string {
    if (isString(countryId)) {
      return countryList?.find(i => i.id === countryId).code;
    }
    return countryList?.find(i => i.id === (countryId as IModel<any>).Id).code;
  }

  get GetIdentificationType(): Observable<IdentificationType[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.allIdentificationType).pipe(first(), switchMap((x) => {
      if (!x || (x as IdentificationType[]).length < 1) {
        return this.referenceDataService.getIdentificationTypes().pipe(tap(c => {
          // to match given mockup, reversed the id list to display passport and national id in that order
          this.store.dispatch(setAllIdentificationType({ list: c.items.reverse() }));
          this.loadingService.decreaseLoadingCounter();
        }), map(data => data?.items?.reverse()));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get getLanguage(): Observable<Language[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.firstLanguage).pipe(first(), switchMap((x) => {
      if (!x || (x as Language[]).length < 1) {
        return this.referenceDataService.getLanguages().pipe(tap(c => {
          this.store.dispatch(setFirstLanguages({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get getOccupationSector(): Observable<OccupationSector[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.OccupationSectorList).pipe(first(), switchMap((x) => {
      if (!x || (x as OccupationSector[]).length < 1) {
        return this.referenceDataService.getOccupationSectors().pipe(tap(c => {
          this.store.dispatch(setOccupationSector({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get getTestReason(): Observable<TestReason[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.TestReasonList).pipe(first(), switchMap((x) => {
      if (!x || (x as TestReason[]).length < 1) {
        return this.referenceDataService.getTestReason().pipe(tap(c => {
          this.store.dispatch(setTestReason({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get getOccupationLevel(): Observable<OccupationLevel[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.OccupationLevel).pipe(first(), switchMap((x) => {
      if (!x || (x as OccupationLevel[]).length < 1) {
        return this.referenceDataService.getOccupationLevels().pipe(tap(c => {
          this.store.dispatch(setOccupationLevel({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }

  get getEducationLevels(): Observable<EducationLevel[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.EducationLevelList).pipe(first(), switchMap((x) => {
      if (!x || (x as EducationLevel[]).length < 1) {
        return this.referenceDataService.getEducationLevels().pipe(tap(c => {
          this.store.dispatch(setEducationLevel({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }
  get getGender(): Observable<Gender[]> {
    this.loadingService.increaseLoadingCounter();
    return this.store.select(appStore => appStore?.onboardingStore?.GenderList).pipe(first(), switchMap((x) => {
      if (!x || (x as Gender[]).length < 1) {
        return this.referenceDataService.getGenders().pipe(tap(c => {
          this.store.dispatch(setGender({ list: c.items }));
          this.loadingService.decreaseLoadingCounter();
        }), pluck('items'));
      } else {
        this.loadingService.decreaseLoadingCounter();
        return of(x);
      }
    }));
  }
}
