import { Component } from '@angular/core';

@Component({
  selector: 'app-still-have-problem-link',
  template: `
    <div class="mt-3 text-bold">
        <span class="text-gray" i18n>Still having problems? </span>
        <p class="link-darker contact-info">Contact your test centre</p>
    </div>
  `,
  styleUrls: ['./still-have-problem-link.scss']
})
export class StillHaveProblemLinkComponent {

}
