import { Component, Input } from '@angular/core';
import { isNull, isUndefined } from 'lodash';
import { ConfirmModalComponent } from './../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-content-modal',
  templateUrl: './../confirm-modal/confirm-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent extends ConfirmModalComponent {
  @Input('showAccept') set showAcceptButton(v) {
    if (!isUndefined(v) && !isNull(v)) {
      this.changeAcceptButtonVisibility(v);
    }
  }
}
