<div class="card">
  <div class="card-body">
    <p>Please wait while we fetch your payment status</p>
  </div>
</div>
<app-confirm-modal #paymentModalPopup [title]="title" imageUrl="assets/images/material-error.svg"
  [acceptText]="acceptText" [subTitle]="subTitle" titleClass="" [showReject]="false" [showCloseIcon]="false"
  (onAcceptClicked)="onAcceptClicked()" (onClose)="onAcceptClicked()">
</app-confirm-modal>
<app-payment-v2-popup [imageUrl]="imageUrl" [description]="desc" [paymentErrorModal]="paymentNewModalPopup" [title]="title" 
titleClass="" [acceptText]="acceptText"  [showReject]="false" 
  (onAcceptClicked)="onAcceptClicked()" [showCloseIcon]="true" (onClose)="onAcceptClicked()">