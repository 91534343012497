import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  Application,
  ComponentMinimumScore,
  DashboardItem,
  DashboardItemActionCode, DashboardItemStatus
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import {
  ILRWTime,
  ISpeakingTime,
  SetIsRegistered,
  SetLatestPageUrl,
  SetLRWTestTime,
  setSpeakingTestTime
} from '../booking/store/booking.actions';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { fromEvent, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApplicationOrganisation, OrganisationService } from '@idp-education/ors-test-taker-bff-client-v1';
import { concatMap, first, pluck } from 'rxjs/operators';
import { IStatus, ITimelineItem } from '../../shared/models/components/time-line';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { setCurrentApplication } from 'src/app/store/applications/application.action';
import { Auth } from 'aws-amplify';
import { ContentModalComponent } from 'src/app/shared/components/content-modal/content-modal.component';
import { CalcRemainingTimeService } from 'src/app/shared/services/calcRemaining-time.service';
import { compact, isNumber, isString, result, toNumber } from 'lodash';
import {
  UpcomingTestUnpaidComponent
} from 'src/app/shared/components/upcoming-test/upcoming-test-unpaid/upcoming-test-unpaid.component';
import timelineIList from './timelineIList';
import { IInstitution } from './select-Institution/select-Institution.component';
import { Title, Meta } from '@angular/platform-browser';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as worker from 'worker-timers';
import {
  setCallToActionState,
  setGoToApplUpdate,
  setOriginalApplication,
  setSsrComponent
} from '../../store/my-tests/my-tests.actions';
import { selectGoToApplUpdate, selectCallToActionState } from 'src/app/store/my-tests/my-tests.reducer';
import { CallToActionService } from 'src/app/shared/services/call-to-action.service';
import { DateTime } from 'luxon';
import { EORLocalStorageItemKeys, PaymentGateways, PaymentStates } from '../payment/payment.enum';
import { getUserProfileName } from 'src/app/store/user/user.reducer';
import { IPaymentGateways } from 'shared/interfaces/payment.interface';

type IScrollPages = {
  myTest?: number,
  notify?: number,
  select?: number
};
const timelineItems: ITimelineItem[] = timelineIList;
type IPages =
  'my-test'
  | 'notify-institution'
  | 'select-institution'
  | 'EOR'
  | 'view-EOR'
  | 'ssr-instructions-display'
  | 'ssr-search-institution'
  | 'ssr-test-format'
  | 'ssr-test-location'
  | 'ssr-test-date'
  | 'ssr-test-result'
  | 'ssr-booking-confirmation'
  | 'ssr-payment'
  | 'ssr-payment-confirmation'
  | 'update-application-details';

@Component({
  selector: 'app-my-tests',
  templateUrl: './my-tests.component.html',
  styleUrls: ['./my-tests.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class MyTestsComponent implements OnInit, OnDestroy {

  @ViewChild('timelineDetail') detailModal: ContentModalComponent;
  @ViewChild('downloadModal') downloadModal: ContentModalComponent;
  loadingApplication = false;
  callToActions = [];
  upComingApplications: DashboardItem[];
  pastApplications: DashboardItem[];
  cancelledTest: DashboardItem[];
  stTitle = $localize`Speaking test`;
  lrwTitle = $localize`Listening, Reading and Writing test`;
  lrwTitleMd = $localize`Listening, Reading & Writing test`;
  currentScrollPosition: IScrollPages = {};
  passwordInputType: 'password' | 'text' = 'password';
  currentApplication: Application;
  timelineDetailTitle: string;
  timelineDetailSubtitle: string;
  speakingAndLRWDetails: {
    username?: string,
    pass?: string,
    pin?: string
  } = {};
  public eorApplication: DashboardItem;
  viewEorApplication: DashboardItem;
  ssrSearchApplication: DashboardItem;
  ssrComponent: string;
  ssrTestFormat: DashboardItem;
  public currentApplicationId = '';
  public bookableProductName = '';
  userName;
  private roList: Array<ApplicationOrganisation>;
  private subscriptions = new Subscription();
  private timerCounter = 0;
  worker: Worker;
  private readonly second = 1;
  isStripe = false;
  isEORPaymentCompleted = false;
  getUserProfileName$ = this.store.select(getUserProfileName);

  public get ROList(): Observable<Array<ApplicationOrganisation>> {
    return of(this.roList);
  }

  get timelineItems() {
    return timelineItems;
  }

  // tslint:disable-next-line: variable-name
  private _currentPage: IPages;
  public get currentPage(): IPages {
    return this._currentPage;

  }

  public set currentPage(v: IPages) {
    this._currentPage = v;
    this.setToScrollTop(0);
    this.setCurrentPositionToDocument(v);
    if (this._currentPage === 'my-test' || this._currentPage === 'EOR') {
      this.getAllDashboardApplications();
      this.store.dispatch(SetLatestPageUrl({ latestPage: null }));
    }
  }

  private _remainingToTest: string;
  public get remainingToTest(): string {
    return this._remainingToTest;
  }

  constructor(
    private dashboardService: DashboardService,
    private store: Store<{ bookingStore, globalStore, myTestsStore, userStore }>,
    private router: Router,
    private route: ActivatedRoute,
    private loading: LoadingService,
    private applicationService: ApplicationsService,
    private calcRemainingTime: CalcRemainingTimeService,
    private organizationService: OrganisationService,
    private callToActionService: CallToActionService,
    private titleService: Title, private metaTagService: Meta) {
    this.titleService.setTitle('My Tests | IDP IELTS');
    this.currentPage = 'my-test';

    this.subscriptions.add(this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    }));
    this.subscriptions.add(this.store.select(selectCallToActionState).subscribe(callToActionState => {
      switch (callToActionState) {
        case 'osr-pre-payment':
          this.currentPage = 'ssr-payment';
          break;
        case 'osr-post-payment':
          this.currentPage = 'ssr-payment-confirmation';
          break;
        case 'eor-pre-payment':
          this.currentPage = 'EOR';
          break;
        case 'eor-post-payment':
          this.currentPage = 'EOR';
          break;
      }
      this.store.dispatch(setCallToActionState({ callToActionState: null }));
    }));
    this.subscriptions.add(
      this.store.select(selectGoToApplUpdate).subscribe((goToApplUpdate) => {
        if (goToApplUpdate) {
          this.currentPage = 'update-application-details';
          this.store.dispatch(setGoToApplUpdate({ goToApplUpdate: false }));
        }
      })
    );

    // checking stripe payment / veritrans / paystack
    const paymentGateway = localStorage.getItem('paymentGateway');
    const paymentState = localStorage.getItem('paymentState');
    this.route.queryParams.pipe(first()).subscribe(qp => {
      const gateways = IPaymentGateways as string[];
      if ((qp && Object.keys(qp).length) || (gateways.includes(paymentGateway) && paymentState === PaymentStates.COMPLETED)) {
        const paymentType = localStorage.getItem('paymentType');
        if (paymentType && paymentType === 'EOR') {
          this.isEORPaymentCompleted = true;
          this.eorApplication = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication) || '{}');
          localStorage.removeItem(EORLocalStorageItemKeys.eorApplication);
        }
        this.currentPage = paymentType && paymentType === 'EOR' ? 'EOR' : 'ssr-payment-confirmation';
        this.isStripe = true;
        localStorage.removeItem('paymentType');
        localStorage.removeItem('paymentGateway');
        localStorage.removeItem('paymentState');
        localStorage.removeItem('bankAlfalahResult');
      } else if (
        (paymentGateway === PaymentGateways.TIPS) &&
        paymentState === PaymentStates.INITIATED
      ) {
        this.isEORPaymentCompleted = false;
        this.eorApplication = JSON.parse(localStorage.getItem(EORLocalStorageItemKeys.eorApplication) || '{}');
        localStorage.removeItem(EORLocalStorageItemKeys.eorApplication);
        localStorage.removeItem('paymentType');
        localStorage.removeItem('paymentGateway');
        localStorage.removeItem('paymentState');
      }
    });
    localStorage.removeItem('paymentMethodType');
  }

  private fetchROList(appId: string) {
    return this.applicationService.getApplicationOrganization(appId);
  }

  private getAllDashboardApplications() {
    this.loadingApplication = true;
    Auth.currentAuthenticatedUser().then(() => {
      setTimeout(() => {
        this.dashboardService.getAllDashboardApplications(true).subscribe(apps => {
          this.upComingApplications = apps.upcomingTests;
          this.pastApplications = apps.pastTests;
          this.callToActions = this.callToActionService.addToCallAction(this.upComingApplications);
          if (this.upComingApplications.filter(t => t.status === DashboardItemStatus.UNPAID).length) {
            this.startTimer();
          }
          this.dashboardService.getCancelledTests().subscribe(canceled => {
            this.cancelledTest = canceled;
          });
          this.loadingApplication = false;
        });
      }, this.isStripe ? 3000 : 1);
    });
  }

  startTimer() {
    let counter = 0;
    const start = Date.now();
    this.worker.postMessage({ key: 'start' });
    this.worker.onmessage = ({ data }) => {
      counter += 1000;
      this.callToActions = this.callToActionService.addToCallAction(this.upComingApplications, this.second * this.timerCounter);
      this.timerCounter += 1;
    };
  }

  toStopTimer() {
    this.worker.postMessage({ key: 'stop' });
    this.callToActions = [];
  }

  reload() { /* empty*/ }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.worker.postMessage({ key: 'stop' });
  }

  private guidGenerator() {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  ngOnInit(): void {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('./../../../assets/worker-js/timer.worker.js', { type: 'module' });
    }
    this.metaTagService.updateTag(
      {
        name: 'description',
        content: 'Check available IELTS online test dates, your IELTS test results. Book your IELTS test with IDP today.'
      },
    );
    this.callToActionService.setHidden(false);
    this.calcRemainingTime.toStopTimerCallBack(this.toStopTimer.bind(this));
  }

  public setToScrollTop(scroll: number) {
    document.documentElement.scrollTop = scroll;
  }

  setCurrentPositionToDocument(type: IPages) {
    const setPosition = (page: 'myTest' | 'notify' | 'select') => {
      setTimeout(() => {
        this.setToScrollTop(this.currentScrollPosition[page]);
      }, 100);
    };
    switch (type) {
      case 'my-test':
        setPosition('myTest');
        break;
      case 'notify-institution':
        setPosition('notify');
        break;
      case 'select-institution':
        setPosition('select');
        break;
      default:
        break;
    }
  }

  onRequestRemarkClick(event: DashboardItem) {
    this.currentPage = 'EOR';
    this.eorApplication = event;
  }

  onViewRemarkClick(event: DashboardItem) {
    this.currentPage = 'view-EOR';
    this.viewEorApplication = event;
  }

  onssrSearchClick({ test, component }: { test: DashboardItem, component: string }) {
    this.currentPage = 'ssr-instructions-display';
    this.ssrSearchApplication = test;
    this.store.dispatch(setOriginalApplication({ application: test }));
    this.ssrComponent = component;
    this.store.dispatch(setSsrComponent({ ssrComponent: component }));
  }

  OnssrTestFormatClick(event: DashboardItem) {
    this.currentPage = 'ssr-test-format';
    this.ssrTestFormat = event;
  }

  onCancelClick(event: DashboardItem) {
    this.currentPage = 'my-test';
  }

  onClickOptions({ key, application }: { key: DashboardItemActionCode, application: DashboardItem }) {
    switch (key) {
      case 'NOTIFY_INSTITUTION':
        this.currentApplicationId = application.applicationId;
        this.bookableProductName = application.productName;
        this.fetchROList(application.applicationId).pipe(first()).subscribe((data) => {
          this.roList = data;
          this.currentPage = 'notify-institution';
        });
        break;
      case 'TRANSFER_BOOKINGS':
        this.applicationService.GetApplication(application.applicationId).pipe(first()).subscribe(app => {
          this.store.dispatch(setCurrentApplication({ application: app }));
          this.router.navigate(['/booking']);
          const speakingAndLrw = UpcomingTestUnpaidComponent.getSpeakingAndLRW(app);
          if (!speakingAndLrw) {
            alert('something failed');
            return;
          }
          this.store.dispatch(setSpeakingTestTime(speakingAndLrw.speaking));
          this.store.dispatch(SetLRWTestTime(speakingAndLrw.lrw));
          this.store.dispatch(SetIsRegistered());
        });
        break;
      case 'UPDATE_DETAILS':
        this.store.dispatch(setOriginalApplication({ application }));
        this.store.select(st => st.globalStore.enableUpdateApplicationDetails).subscribe(enabled => {
          if (application.status === DashboardItemStatus.PAIDBUTINCOMPLETE) {
            this.router.navigate(['/my-profile']);
          } else if (enabled) {
            this.currentPage = 'update-application-details';
          } else {
            this.router.navigate(['/my-profile']);
          }
        });
        break;
      case 'VIEW_TERMS_AND_CONDITIONS': {
        const timeZone = application?.testLocationTimeZone;
        const hongKong = (timeZone.toLowerCase() === 'asia/hong_kong');
        if (application.productName.includes('IELTS on Computer') && hongKong) {
          window.open(this.router.serializeUrl(this.router.createUrlTree(['term-and-conditions-hongkong'])), '_blank');
        } else if (application.productName.includes('IELTS on Computer')) {
          window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions-incentre'])), '_blank');
        } else if (application.productName.includes('IELTS UKVI on Computer') && hongKong) {
          window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions-ukvi-hongkong'])), '_blank');
        } else if (application.productName.includes('IELTS UKVI on Computer')) {
          window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions-ukvi'])), '_blank');
        } else if (application.productName.includes('IELTS OSR UKVI on Computer')) {
          window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions-ukvi-osr'], {
            queryParams: {
              tcName: application.testCentreCode
            }
          })), '_blank');
        }
        else {
          application.productName === 'IELTS Online Academic' ? localStorage.setItem('checkProduct', 'IOL')
            : localStorage.setItem('checkProduct', 'OSR');
          window.open(this.router.serializeUrl(this.router.createUrlTree(['/term-and-conditions'])), '_blank');
        }
      }
        break;
      default:
        break;
    }
  }

  addNewRO(event) {
    const { value } = event.form;
    const listeningValue = value?.listening?.value || value?.listening?.option?.value;
    const readingValue = value?.reading?.value || value?.reading?.option?.value;
    const speakingValue = value?.speaking?.value || value?.speaking?.option?.value;
    const writingValue = value?.writing?.value || value?.writing?.option?.value;
    const hasValue = (v: string) => (isString(v) && v.toLowerCase() !== 'any') || (isNumber(v) && toNumber(v) > 0);
    const minimumScores: ComponentMinimumScore[] = compact([
      hasValue(listeningValue) ? {
        languageSkill: 'L',
        minimumScore: listeningValue
      } : undefined,
      hasValue(readingValue) ? {
        languageSkill: 'R',
        minimumScore: readingValue
      } : undefined,
      hasValue(speakingValue) ? {
        languageSkill: 'S',
        minimumScore: speakingValue
      } : undefined,
      hasValue(writingValue) ? {
        languageSkill: 'W',
        minimumScore: writingValue
      } : undefined,
    ]);
    const confirmationStatus = value?.confirmationStatus?.key || value?.confirmationStatus?.Id;
    this.organizationService.createOrganisation(this.currentApplicationId, {
      confirmationType: confirmationStatus,
      organisationId: event.item.original.id,
      caseNumber: value?.referenceNumber,
      contact: value?.contactName,
      minimumScores: confirmationStatus !== 'CONFIRMED' ? {
        overallMinimumScore: value?.overall?.option ? value?.overall?.option?.value : value?.overall?.value,
        componentMinimumScores: minimumScores
      } : undefined,
    }).subscribe(() => {
      this.loading.increaseLoadingCounter();
      setTimeout(() => {
        this.fetchROList(this.currentApplicationId).subscribe((data) => {
          this.roList = data;
          this.currentPage = 'notify-institution';
        });
        this.loading.decreaseLoadingCounter();
      }, 5000);
    });
  }

  openDownloadDialog() {
    this.downloadModal.open({ size: 'md' }).result.then(data => { /* empty */ }).catch(err => console.log(err));
  }

  download(fileUrl, fileName) {
    const aTag = document.createElement('a');
    aTag.href = fileUrl;
    aTag.setAttribute('a', fileName);
    aTag.setAttribute('target', '_blank');
    aTag.click();
  }

  onTimelineItemClicked(event: {
    timeLineItem: ITimelineItem
    application: DashboardItem
  }) {
    const { timeLineItem } = event;
    const { application } = event;
    switch (timeLineItem.key) {
      case 'completeDetail':
        this.router.navigate(['/my-profile']);
        break;
      case 'downloadPlayer':
        window.open('https://ielts.inspera.com/get-iep');
        break;
      case 'speakingDetail':
      case 'completeSpeaking':
        this.openDetailDialog(application, timeLineItem.key);
        break;
      default:
        break;
    }
  }

  private convertKeyToLabel(key: IStatus): 'Speaking' | 'Listening, Reading and Writing' {
    if (key === 'speakingDetail') {
      return 'Speaking';
    } else {
      return 'Listening, Reading and Writing';
    }
  }

  openDetailDialog(dashboardApp: DashboardItem, key: IStatus) {
    this.applicationService.GetApplication(dashboardApp.applicationId).pipe(first()).subscribe(app => {
      this.currentApplication = app;
      const detailDialog = () => {
        const {
          speaking: remainToSpeaking,
          lrw: remainingToLRW
        } = this.remainToSpeakingAndLRW(this.currentApplication);
        this.fillDialogDetails(key, remainToSpeaking, remainingToLRW);
      };
      detailDialog();
      const timerVal = worker.setInterval(detailDialog, 1000);
      const modal: NgbModalRef = this.detailModal.open({ size: 'lg' });
      modal.result.then(() => {
        this.passwordInputType = 'password';
      }).catch(() => {
        this.passwordInputType = 'password';
      }).finally(() => {
        worker.clearInterval(timerVal);
      });
    });
  }

  private fillDialogDetails(key: IStatus, remainToSpeaking: string, remainingToLRW: string) {
    const keyAsLabel = `How to complete your ${this.convertKeyToLabel(key)} test`;
    this.timelineDetailTitle = keyAsLabel;
    this.speakingAndLRWDetails = this.getApplicationDetail(this.currentApplication, key);
    switch (key) {
      case 'speakingDetail':
        this._remainingToTest = remainToSpeaking;
        break;
      case 'completeSpeaking':
        this._remainingToTest = remainingToLRW;
    }
  }

  openBooking(app: Application) {
    this.loading.increaseLoadingCounter();
    this.applicationService.GetApplication(app.id).subscribe(data => {
      this.store.dispatch(setCurrentApplication({ application: data }));
      const speakingAndLrw = this.getSpeakingAndLRW(data);
      if (!speakingAndLrw) {
        alert('something failed');
        return;
      }
      this.store.dispatch(setSpeakingTestTime(speakingAndLrw.speaking));
      this.store.dispatch(SetLRWTestTime(speakingAndLrw.lrw));
      this.store.dispatch(SetIsRegistered());
      this.loading.decreaseLoadingCounter();
    });
  }

  getSpeakingAndLRW(app: Application): { speaking: ISpeakingTime, lrw: ILRWTime } | null {
    try {
      const lrw = app.bookings.find(i => (i.bookingLines[0].languageSkill.match(/[LRW]/))?.length > 0);
      const speaking = app.bookings.find(i => i.bookingLines[0].languageSkill === 'S');
      return {
        lrw: {
          from: DateTime.fromISO(lrw.bookingLines[0].startDateTimeUtc, { zone: 'utc' }),
          to: DateTime.fromISO(lrw.bookingLines[0].endDateTimeUtc, { zone: 'utc' }),
          lrw: { ...lrw }
        },
        speaking: {
          from: DateTime.fromISO(speaking.bookingLines[0].startDateTimeUtc, { zone: 'utc' }),
          to: DateTime.fromISO(speaking.bookingLines[0].endDateTimeUtc, { zone: 'utc' }),
          speaking: { ...speaking },
        }
      };
    } catch (error) {
      return null;
    }
  }

  togglePassword() {
    if (this.passwordInputType === 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  remainToSpeakingAndLRW(app: Application): { speaking: string, lrw: string } {
    try {
      const { speaking, lrw } = this.getSpeakingAndLRW(app);
      // tslint:disable-next-line: no-shadowed-variable
      const result: {
        lrw: string,
        speaking: string
      } = {
        speaking: '',
        lrw: ''
      };
      [speaking, lrw].forEach((item, index) => {
        if (!item) {
          return;
        }
        const today = DateTime.now();
        const fromDate = item.from;
        result[index === 0 ? 'speaking' : 'lrw'] = this.calcRemainingTime.getTime(fromDate.diff(today).as('seconds'));
      });
      return result;
    } catch (error) {
      return {
        speaking: '',
        lrw: ''
      };
    }
  }

  onFinishProcess(app: DashboardItem) {
    this._currentPage = 'my-test';
    this.getAllDashboardApplications();
  }

  getApplicationDetail(app: Application, type: IStatus): {
    username?: string,
    pass?: string,
    pin?: string
  } {
    try {
      const { lrw: lrwBooking, speaking: speakingBooking } = this.getSpeakingAndLRW(app);
      if (type === 'completeSpeaking') {
        return {
          pass: this.decodePassword(lrwBooking.lrw.cmdsBookingDetail.testPlatformPassword),
          username: lrwBooking.lrw.cmdsBookingDetail.testPlatformUsername || 'Not available',
          pin: this.decodePin(lrwBooking),
        };
      } else if (type === 'speakingDetail') {
        return {
          pass: this.decodePassword(speakingBooking.speaking.cmdsBookingDetail.testPlatformPassword),
          username: speakingBooking.speaking.cmdsBookingDetail.testPlatformUsername || 'Not available',
          pin: speakingBooking.speaking.speakingBookingDetail.testPlatformPin || 'Not available',
        };
      }
    } catch (error) {
      return {
        pass: '',
        username: 'Not available',
        pin: 'Not available'
      };
    }
  }

  private decodePin(lrwBooking: ILRWTime) {
    let pin = '';
    try {
      pin = atob(lrwBooking.lrw.cmdsBookingDetail.testPlatformPin || '') || 'Not available';
    } catch (error) {
      pin = lrwBooking.lrw.cmdsBookingDetail.testPlatformPin || 'Not available';
    }
    return pin;
  }

  private decodePassword(password: string) {
    let pass = '';
    try {
      pass = atob(password || '') || '';
    } catch (error) {
      pass = password || '';
    }
    return pass;
  }

  getDetail(obj, field) {
    if (obj && isString(obj[field])) {
      return obj[field];
    }
    return '';
  }

  onBeforeCall(event) {
    console.log(event);
    if (event === '/booking') {
      // this.store.dispatch(SetProccessPaymentStatus({ proccessPaymentStatus: true }));
      // this.store.dispatch(SetProccessPaymentbBack({ proccessPaymentbBack: true }));
    }
  }

  onRestartOSRBooking() {
    this.currentPage = 'my-test';
  }

  onInstitutionSave() {
    this.loading.increaseLoadingCounter();
    timer(5000).pipe(concatMap(() => {
      return this.fetchROList(this.currentApplicationId);
    }), first()).subscribe((data) => {
      this.roList = data;
      this.loading.decreaseLoadingCounter();
    });
  }
}
