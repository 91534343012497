import { Component, Input } from '@angular/core';
import { ICircleDateColor } from '../../models/components/circle-date';
import { DateTime } from 'luxon';
@Component({
  selector: 'app-circle-date',
  template: `
     <div [ngClass]="classes.join(' ')" class="circle {{color}} d-flex flex-column justify-content-center align-items-center">
          <div class="month">{{month}}</div>
          <div class="day">{{day}}</div>
          <div class="year">{{year}}</div>
      </div>
  `,
  styleUrls: ['./circle-date.component.scss']
})
export class CircleDateComponent {
  @Input() date: DateTime;
  @Input() color: ICircleDateColor = 'blue';
  @Input() classes: Array<string> = [];

  private isDate(d: DateTime | null) {
    if (d && d instanceof DateTime) {
      return true;
    }
    return false;
  }
  get month(): string {
    if (this.isDate(this.date)) {
      return this.date.toFormat('MMM');
    }
    return '';
  }
  get day(): string {
    if (this.isDate(this.date)) {
      return this.date.toFormat('d');
    }
    return '';
  }
  get year(): string {
    if (this.isDate(this.date)) {
      return this.date.toFormat('yyyy');
    }
    return '';
  }

}
