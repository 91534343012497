<div class="flexbox flex-column content align-items-center align-items-xxl-start">
    <div class="all-content">
        <h6 class="content-title p-3" i18n>Your password has been successfully reset</h6>
        <div class="circle  flexbox justify-content-center align-items-center">
            <span class="material-icons">
                done
            </span>
        </div>
        <img src="../../../../assets/images/Boy listening.png" alt="" width="98" class="mt-1 mb-1">
        <div class="btn-submit-wrapper mt-5 flexbox justify-content-center">
            <a [routerLink]='["../../signin"]' class="btn btn-multiAction">
                <span class="flexbox  align-items-center justify-content-center btn-pointer" i18n>
                    Log in
                </span>
            </a>
        </div>
        <span class="text-center">
            <app-still-have-problem-link></app-still-have-problem-link>
        </span>
    </div>
</div>
