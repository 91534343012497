<feature-toggle-provider [features]="featureFlags">
    <div class="main-options mt-4">
        <div class="d-flex align-items-center tab toggle-link" 
        tabindex="0" (click)="toggleClick()" (keydown.enter)="toggleClick()">
            <span [class]="{'uparrow': !expanded,'downarrow': expanded }"></span>
            <h3>View options for this test</h3>
        </div>
        <div class="parent-container" [class]="{'show': expanded}" #parentContainer>
            <div class="child-container" #childContainer>
                <hr>
                <div class="options-list d-flex weight-bold mt-2"
                    [class]="{'mobile-option-list': deviceService.isMobile()}">
                    <ng-container *featureToggle="'postPilotFeaturesEnabled'">
                        <ng-container *ngFor="let item of list" class="tab" tabindex>
                            <a class="option-item"
                                [routerLink]="item && item.lnk ? [item.link] : null"
                                (click)="onLinkClick($event, item)" (keydown.enter)="onLinkClick($event, item)">
                                <img [src]="item.iconUrl" alt="icon">
                                {{item.title}}
                            </a>
                        </ng-container>
                    </ng-container>
                    <ng-container *featureToggle="'!postPilotFeaturesEnabled'">
                        <ng-container *ngFor="let item of list" class="tab" tabindex>
                            <a class="option-item"
                                [routerLink]="item && item.lnk ? [item.link] : null"
                                (click)="onLinkClick($event, item)" (keyup.enter)="onLinkClick($event, item)" *ngIf="isInPilot(item.key)">
                                <img [src]="item.iconUrl" alt="icon">
                                {{item.title}}
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</feature-toggle-provider>