import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-test-details-card',
  templateUrl: './test-details-card.component.html',
  styleUrls: ['test-details-card.component.scss']
})
export class TestDetailsCardComponent {
  @Input() ssrComponent: string;
  @Input() testStartUtcDatetime: DateTime;
  @Input() testFormat: string;
  @Input() testTime: string;
  @Input() duration: string;
  @Input() hideEditButton = false;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();

}
