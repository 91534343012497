<app-content-modal #changePasswordModal title="Change password" titleClass="text-darker" acceptText="Save password" [showReject]="false" [textClass]="'text-black'"
    [disableAcceptButton]="form.invalid" (onClose)="clearForm()">
    <div class="m-3 w-75">
        <div class="">
            <div class="form-group">
                <app-custom-input [fcn]="'oldPassword'" [form]="form" id="oldPassword" i18n-placeholder
                    placeHolder="Current password" maxLength="250" type="password" [AcceptSpace]="true" [required]="true"
                    [showPasswordIcon]="true">
                </app-custom-input>
            </div>
        </div>
        <div class="">
            <div class="form-group">
                <app-custom-input [fcn]="'newPassword'" [form]="form" id="newPassword" i18n-placeholder
                    [required]="true" placeHolder="New password" maxLength="250" type="password"
                    [showPasswordIcon]="true" [AcceptSpace]="true" [firstErrorOnly]="true"
                    [customMessage]="customMessage">
                </app-custom-input>
            </div>
        </div>
        <div *ngIf="form.get('newPassword').touched
                    || form.get('confirmPassword').touched
                    || form.get('newPassword').dirty
                    || form.get('confirmPassword').dirty">
            <app-password-validation-status [password]="form.get('newPassword').value">
            </app-password-validation-status>
        </div>
        <div class="">
            <div class="form-group">
                <app-custom-input [fcn]="'confirmPassword'" [form]="form" id="confirmPassword" i18n-placeholder
                    [required]="true" placeHolder="Reenter new password" maxLength="250" type="password"
                    [showPasswordIcon]="true" [AcceptSpace]="true" [firstErrorOnly]="true">
                </app-custom-input>
            </div>
        </div>
    </div>
</app-content-modal>