import { Injectable } from '@angular/core';
import { DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { CalcRemainingTimeService } from './calcRemaining-time.service';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';

type ICallToAction = {
  incompleteProfile?: DashboardItem[];
  unpaid?: DashboardItem[];
};

@Injectable({
  providedIn: 'root',
})
export class CallToActionService {
  private hidden$ = new BehaviorSubject(false);
  isNewPaymentJourney: any;
  routeVal: any;
  constructor(private calcRemainingTime: CalcRemainingTimeService,
    private store: Store<{ bookingStore }>,

  ) {
  
    this.isNewPaymentJourney =  localStorage.getItem('isNewPaymentJourney');
    this.getRoute();
  }
  getRoute() {
    if(this.isNewPaymentJourney === 'enabled') 
    {
      localStorage.setItem('prevUrl','/my-account');
    }
    return this.isNewPaymentJourney === 'enabled'
      ? '/account-v2/booking-summary/payment-options'
      : '/payment/card-detail';
  }

  getHidden$() {
    return this.hidden$.asObservable();
  }

  setHidden(value: boolean) {
    this.hidden$.next(value);
  }

  searchForCallToAction(uta: DashboardItem[]): ICallToAction {
    const result: ICallToAction = {
      incompleteProfile: [],
      unpaid: [],
    };
    // TODO: past or upcomingTests
    uta.forEach((item) => {
      switch (item.status) {
        case 'PAID_BUT_INCOMPLETE':
          result.incompleteProfile = [...result.incompleteProfile, item];
          break;
        case 'UNPAID':
          result.unpaid = [...result.unpaid, item];
          break;
        default:
          break;
      }
    });
    return {
      incompleteProfile: [result.incompleteProfile[0]].filter((i) => i),
      unpaid: [result.unpaid[0]].filter((i) => i),
    };
  }

  addToCallAction(uta: DashboardItem[], SecondsBeReduced = 0) {
    const status = this.searchForCallToAction(uta);
    const callToActions = [];
    const keys = Object.keys(status);
    keys.forEach((key) => {
      if (status[key].length > 0) {
        switch (key) {
          case 'incompleteProfile':
            if (status.incompleteProfile) {
              callToActions.push({
                message: $localize`You haven\'t completed your profile. You cannot sit the test until this is complete.`,
                route: '/my-profile',
                cta: $localize`Complete profile`,
              });
            }
            break;
          case 'unpaid':
            // TODO: Fix this
            status[key].forEach((item) => {
              const { secondsToPayForApplication: secondsToPay } = item;
              callToActions.push({
                message: $localize`Your booking is incomplete. You have
                ${this.calcRemainingTime.getTime(
                  secondsToPay - SecondsBeReduced > 0
                    ? secondsToPay - SecondsBeReduced
                    : 0,
                  'minutes'
                )}
                remaining to complete your booking.`,
                route: this.getRoute(),
                cta: $localize`Complete Booking`,
                application: item,
              });
            });
            break;
          default:
            break;
        }
      }
    });
    return callToActions;
  }



}
