<form [formGroup]="form" *ngIf="form">
  <div class="row">
    <div class="col-lg-6 col-md-12" *ngIf="hasGender">
      <app-dropdown id="gender" [formControl]="form && form.get('gender')" [title]='showTitle ?  "Gender" : ""'
        #genderEl labelKey="name" idKey="id" i18n-placeholder placeholder="Gender" height="56" [showValidationError]="true"
        (onChangeModel)="selectChange.emit([$event, 'gender'])" [options]="genderList" [showTick]="true"
        [isRequired]="hasGender">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="form-group">
        <app-custom-input [fcn]="'locationStudyEnglish'" [form]="form" id="locationStudyEnglish" #locationStudyEnglishEl
          (change)="selectChange.emit([null, 'locationStudyEnglish'])" i18n-placeholder
          placeHolder="Where are you currently studying English (if applicable)?"
          [title]='showTitle ? "Country currently studying English" : ""' maxLength="150" [showError]="true">
        </app-custom-input>
      </div>
    </div>
    <div class="col-lg-6 col-md-12" *ngIf="hasTitle">
      <app-dropdown #title [formControl]="form && form.get('title')" labelKey="name" idKey="value" i18n-placeholder
        (onChangeModel)="selectChange.emit([$event, 'title'])" placeholder="Title" [title]='showTitle ? "Title" : ""'
        [options]="titleList" #titleEl [showTick]="true" [isRequired]="hasTitle" [showValidationError]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown #occupationSectorEl [formControl]="form && form.get('occupationSector')" labelKey="name" idKey="id"
        i18n-placeholder (onChangeModel)="selectChange.emit([$event, 'occupationSector'])"
        placeholder="Occupation (sector)" [title]='showTitle ? "Occupation sector" : ""' height="56"
        [addNewOptionId]="getNewOptionId(occupationSectorList)" [options]="occupationSectorList" [showTick]="true"
        [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('firstLanguage')" #firstLanguageEl labelKey="name" idKey="id"
        placeholder="First language" [title]='showTitle ? "First language" : ""' height="56" i18n-placeholder
        [tooltip]="!disableFirstLanguage" (onChangeModel)="selectChange.emit([$event, 'firstLanguage'])"
        [options]="languages" [showTick]="!disableFirstLanguage" [addNewOptionId]="getNewOptionId(languages)"
        [isRequired]="!disableFirstLanguage" [inputDisabled]="disableFirstLanguage">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('occupationLevel')" #occupationLevelEl labelKey="name" idKey="id"
        i18n-placeholder (onChangeModel)="selectChange.emit([$event, 'occupationLevel'])"
        placeholder="Occupation (level)" [title]='showTitle ? "Occupation level" : ""' height="56" [showTick]="true"
        [options]="occupationLevelList" [addNewOptionId]="getNewOptionId(occupationLevelList)" [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('educationLevel')" labelKey="name" idKey="id" #educationLevelEl
        i18n-placeholder placeholder="What level of education have you completed?"
        [title]='showTitle ? "Level of education" : ""' height="56"
        (onChangeModel)="selectChange.emit([$event, 'educationLevel'])" [options]="educationLevelList" [showTick]="true"
        [addNewOptionId]="getNewOptionId(educationLevelList)" [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('intendLocation')" #intendLocationEl labelKey="name" idKey="id"
        (onChangeModel)="selectChange.emit([$event, 'intendLocation'])" i18n-placeholder [showTick]="true"
        placeholder="Which country do you intend to go to?" [title]='showTitle ? "Country intending to go to" : ""'
        height="56" [options]="countryList" [addNewOptionId]="getNewOptionId(countryList)" [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('yearsStudyingEnglish')" i18n-placeholder #yearsStudyingEnglishEl
        placeholder="How many years have you been studying English?" [showTick]="true"
        [addNewOptionId]="getNewOptionId(yearsStudyingEnglishList)" [title]='showTitle ? "Years studying English" : "" '
        height="56" (onChangeModel)="selectChange.emit([$event, 'yearsStudyingEnglish'])" labelKey="name" idKey="id"
        [options]="yearsStudyingEnglishList" [isRequired]="true">
      </app-dropdown>
    </div>
    <div class="col-lg-6 col-md-12">
      <app-dropdown [formControl]="form && form.get('reasonTest')" #reasonTestEl [showTick]="true"
        (onChangeModel)="selectChange.emit([$event, 'reasonTest'])" placeholder="Reason for taking the Test"
        [title]='showTitle ? "Reason for taking the Test" : ""' i18n-placeholder height="56" [options]="testReasonList"
        labelKey="name" idKey="id" [addNewOptionId]="getNewOptionId(testReasonList)" [isRequired]="true">
      </app-dropdown>
    </div>
  </div>
</form>