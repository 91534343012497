import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { LoadingService } from 'shared/services/loading-service.service';
import { NavigationService } from 'shared/services/navigation.service';
import { restoreLocalData } from '../../payment-utils/payment-utils';
import { NewebpayPaymentService, PaymentStatus, PaymentStatusRequest } from '@idp-education/ors-newebpay-payment-bff-client-v1';
import * as uuid from 'uuid';
import { PaymentStates } from 'pages/payment/payment.enum';
import { PaymentMethodType } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-newebpay-callback',
  templateUrl: './newebpay-callback.component.html',
  styleUrls: ['./newebpay-callback.component.scss']
})
export class NewebpayCallbackComponent implements OnInit, OnDestroy {

  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  paymentNewModalPopup = false;
  title: string;
  acceptText: string;
  rejectText: string;
  applicationId: string;
  userToken: string;
  paymentType: string;
  paymentGateway: string;

  routerSub: Subscription;
  activatedRouteSub: Subscription;
  apiCallingSub: Subscription;
  isNewPaymentJourney: string;
  userName: any;
  imageUrl: string;
  desc: string;

  constructor(
    private store: Store<{ userStore }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private newebpayPaymentService: NewebpayPaymentService,
  ) {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    this.apiCallingSub = this.store.select(appStore => appStore.userStore?.userDetails).subscribe((userDetails) => {
      this.userName = userDetails?.firstName;
    });
   }
   onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  ngOnInit(): void {
    Auth.currentSession().then(session => {
      this.userToken = session.getAccessToken().getJwtToken();
      this.paymentType = localStorage.getItem('paymentType');
      this.restoreData();
    });
   
  }

  restoreData() {
    const currentApp = restoreLocalData(this.paymentType);
    this.applicationId = currentApp?.id;
    this.newebPayStatus();
  }

  ngOnDestroy(): void {
    this.routerSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.apiCallingSub?.unsubscribe();
  }

  modalpopupDescription(paymentDetails, isCVS) {
    switch (paymentDetails.status) {
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        if (isCVS) {
          const paymentDuration = {
            date: paymentDetails?.expireDate,
            time: paymentDetails?.expireTime
          };
          this.navigationService.storeNewebpayDateTime(paymentDuration);
        }
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.FAILED:
        this.title = `Your payment failed. Please retry the payment.`;
        this.acceptText = 'Retry Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.CANCELLED:
      case PaymentStatus.TIMEOUT:
        this.title = `Your payment is cancelled. Redirecting to your dashboard.`;
        this.acceptText = 'Payment Cancelled';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.PENDING:
        if (isCVS) {
          const paymentDuration = {
            date: paymentDetails?.expireDate,
            time: paymentDetails?.expireTime
          };
          this.navigationService.storeNewebpayDateTime(paymentDuration);
          this.navigationService.navigateOnPaymentType(this.paymentType);
        } else {
          this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
          this.acceptText = 'Return to My Account';
          this.paymentModalPopup.open();
          this.loadingService.decreaseLoadingCounter();
        }
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `The payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }
  newmodalpopupDescription(paymentDetails, isCVS) {
    switch (paymentDetails.status) {
      case PaymentStatus.FAILED:
        this.title = `Payment Failed`;
        this.acceptText = 'Try again';
        this.desc = `Hi ${this.userName}, your payment has failed, possibly due to incorrect payment information. Please retry your payment.`;
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.SUCCESS:
        this.navigationService.storePaymentState(PaymentStates.COMPLETED);
        if (isCVS) {
          const paymentDuration = {
            date: paymentDetails?.expireDate,
            time: paymentDetails?.expireTime
          };
          this.navigationService.storeNewebpayDateTime(paymentDuration);
        }
        this.navigationService.navigateOnPaymentType(this.paymentType);
        break;
      case PaymentStatus.CANCELLED:
      case PaymentStatus.TIMEOUT:
        this.title = `Payment Cancelled`;
        this.acceptText = 'Ok';
        this.imageUrl = './assets/images/payment-info.svg';
        this.desc = `Hi ${this.userName}, your payment has been cancelled. You will be redirected to your profile.`;
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      case PaymentStatus.PENDING:
        if (isCVS) {
          const paymentDuration = {
            date: paymentDetails?.expireDate,
            time: paymentDetails?.expireTime
          };
          this.navigationService.storeNewebpayDateTime(paymentDuration);
          this.navigationService.navigateOnPaymentType(this.paymentType);
        } else {
          this.title = `Payment Pending`;
          this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
          this.acceptText = 'Try again';
          this.imageUrl = './assets/images/payment-wait.svg'
          this.paymentNewModalPopup = true;
          this.loadingService.decreaseLoadingCounter();
        }
        break;
      case 'Unauthorised':
      case 'UNAUTHORISED':
        this.title = `Payment Unauthorised`;
        this.desc = `Hi ${this.userName}, payment authorisation failed due to a security check. Redirecting to your dashboard.`;
        this.acceptText = 'Unauthorised Payment';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
      default:
        this.title = `Payment Error`;
        this.desc = `Hi ${this.userName}, an error occurred while processing your payment. This may be due to insufficient funds, inaccurate payment details, or temporary banking issues. Please try again later or contact your test centre.`;
        this.acceptText = 'Try again';
        this.imageUrl = './assets/images/payment-alert.svg'
        this.paymentNewModalPopup = true;
        this.loadingService.decreaseLoadingCounter();
        break;
    }
  }

  newebPayStatus() {
    this.newebpayPaymentService.configuration.accessToken = this.userToken;

    this.routerSub = this.activatedRoute.queryParamMap.subscribe(queryParams => {
        this.activatedRouteSub = this.activatedRoute.params.subscribe(params => {
            const { receiptId, receiptNumber, signature, txData } = this.extractPaymentDetails(queryParams, params);
            const isCVS = this.determinePaymentMethod(params.status);
            const statusPayload = { receiptId, receiptNumber, signature, txData };

            this.apiCallingSub.add(this.newebpayPaymentService.getPaymentStatus(uuid.v4(), statusPayload).subscribe(response =>{
              this.showModal(response, isCVS);
            },
            error => {
                const message = (error.status === 401) ? 'Unauthorised' : '';
                this.showModal(message);
            }))
            
        });
    });
}

showModal(message, isCVS?:boolean) {
  if (this.isNewPaymentJourney === 'enabled') {
      this.newmodalpopupDescription(message, isCVS);
  } else {
      this.modalpopupDescription(message, false);
  }
}

extractPaymentDetails(queryParams, params) {
    const receiptId = queryParams.get('orderId');
    let receiptNumber = '';
    let signature = '';
    let txData = '';

    if (params.status.toLowerCase().includes('clientback')) {
        receiptNumber = queryParams.get('orderNumber');
        signature = queryParams.get('signature');
    } else {
        txData = queryParams.get('txData');
    }

    return { receiptId, receiptNumber, signature, txData };
}

determinePaymentMethod(status) {
    const paymentMethodType = localStorage.getItem('paymentMethodType');
    let isCVS = false;

    if (status.toLowerCase().includes('customerurl')) {
        this.navigationService.storePaymentMethodType(PaymentMethodType.CVS);
        isCVS = true;
    } else if (paymentMethodType) {
        localStorage.removeItem('paymentMethodType');
    }

    return isCVS;
}


}
