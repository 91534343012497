<div class="actions flexbox flex-column">
                <span class="material-icons done" i18n>
                    done
                </span>
  <button *ngIf="deletableImage" class="material-icons btn-custom" tabindex
          (click)="deleteUpload.emit()"
          i18n i18n-title title="Delete photo">
    delete
  </button>
  <button *ngIf="editableImage" class="material-icons btn-custom" tabindex
          (click)="editIdDocumentImage.emit()"
          i18n i18n-title title="Edit Identity">
    edit
  </button>
  <button class="material-icons btn-custom" tabindex
          (click)="onCropImage.emit()"
          i18n i18n-title title="Crop">
    crop
  </button>
  <button class="material-icons btn-custom" tabindex
          (click)="transformImage.emit('mirror')"
          [class.disable]="editImageState === 'inEditFlip'"
          i18n i18n-title title="Flip"
          *featureToggle="'flipRotateImageButtonEnabled'">
    flip
  </button>
  <button class="material-icons btn-custom" tabindex
          (click)="transformImage.emit('rotate-left')"
          [class.disable]="editImageState === 'inEditRotateLeft'"
          i18n i18n-title title="Rotate left"
          *featureToggle="'flipRotateImageButtonEnabled'">
    rotate_left
  </button>
  <button class="material-icons btn-custom" tabindex
          (click)="transformImage.emit('rotate-right')"
          [class.disable]="editImageState === 'inEditRotateRight'"
          i18n i18n-title title="Rotate right"
          *featureToggle="'flipRotateImageButtonEnabled'">
    rotate_right
  </button>
</div>
