<ng-container *ngIf="format==='CD'">
  <div class="icon" *ngIf="withIcon"><img src="../../../../../../../assets/images/laptop.svg" alt="" class="img-content"></div> IELTS on computer
</ng-container>
<ng-container *ngIf="format==='PB'">
  <div class="icon" *ngIf="withIcon"><i class="fa fa-file-text-o inline-icon-size" aria-hidden="true"></i></div> IELTS on paper
</ng-container>
<!-- TODO: update with what backend value -->
<ng-container *ngIf="format==='IOL'">
  <div class="icon" *ngIf="withIcon"><img src="../../../../../../../assets/images/laptop.svg" alt="" class="img-content"></div> IELTS online
</ng-container>
