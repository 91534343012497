<div class="flexbox flex-column content align-items-center align-items-xxl-start">
    <div class="all-content">
      <form class="form-horizontal ms-4 me-4 me-lg-0" [formGroup]="$form" (ngSubmit)="onSubmit()">
        <app-signup-detail-form [disableEmail]="true" [form]="$form"></app-signup-detail-form>
        <div class="d-flex flex-column justify-content-between conditions multiLine">
          <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'termAndCondition1'">
            <span i18n style="line-height: normal;">I agree to the&nbsp;</span>
            <a href="https://www.idp.com/australia/privacy-policy/" target="_blank" rel="noopener"
              style="color: #656565; line-height: normal;" i18n>terms &
              conditions</a>
          </app-circle-checkbox>
          <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'termAndCondition2'" i18n-title
            title="I’d like to receive news, offers and promotions from IDP IELTS">
          </app-circle-checkbox>
          <app-circle-checkbox class="save-check mt-1 d-block" [form]="$form" [control]="'communicationsCheckbox'"
            i18n-title title="I'd like to receive emails and notifications for IDP study abroad and support services">
          </app-circle-checkbox>
        </div>
        <app-signup-field [classes]="['mt-3']">
          <div status>
          </div>
          <div content
            class="flexbox align-left justify-content-center align-items-center align-items-lg-start flex-column w-100">
            <div class="invalid-feedback " *ngIf="$form.invalid && $form.errors && $form.errors.invalidSignUp">
              <ul class="list-unstyled">
                <li class="error-item ng-star-inserted">
                  <i _ngcontent-pcp-c137="" aria-hidden="true" class="fa fa-warning"></i>
                  {{ invalidMessageSignUp }}
                </li>
              </ul>
            </div>
            <button [disabled]="!($form.valid && ($form.controls.termAndCondition1.value)) || showLoading"
              class=" btn btn-multiAction" type="submit" i18n>
              Create profile
            </button>
          </div>
        </app-signup-field>
      </form>
    </div>
  </div>
