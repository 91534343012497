import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function emptyWhiteSpaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control?.value;
        const otherValue = (typeof value !== 'string') ? value?.option?.name : '';
        if (!value) {
            return null;
        }
        else {
            const isWhitespace = (typeof value === 'string') ? value?.trim().length === 0 : otherValue.trim().length === 0;
            return isWhitespace ? { noSpace: true } : null;
        }
    };
}

