import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Webcam from './webcam';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss']
})
export class WebcamModalComponent implements OnInit, OnDestroy {
  @ViewChild('webcam', { static: true }) webcamEl: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', { static: true }) canvasEl: ElementRef<HTMLCanvasElement>;

  currentFacingMode: 'user' | 'environment' = 'environment';
  webcam?: Webcam;
  enableCameraButton = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.turnOnWebcam();
  }

  flipCamera() {
    if (this.currentFacingMode === 'user') {
      this.currentFacingMode = 'environment';
    } else if (this.currentFacingMode === 'environment') {
      this.currentFacingMode = 'user';
    }
    this.turnOnWebcam();
  }

  private turnOnWebcam(): void {
    // In case user tried to flip the camera
    if (this.webcam && this.webcam.stop) {
      this.webcam.stop();
    }

    try {
      this.webcam = new Webcam(this.webcamEl.nativeElement, this.currentFacingMode, this.canvasEl.nativeElement);
      this.webcam
        .start()
        .then(() => {
          this.webcamEl.nativeElement.addEventListener(
            'canplay',
            (ev) => {
              this.webcam.setHeight(`${(ev.currentTarget as HTMLVideoElement).clientHeight}`);
              this.webcam.setWidth(`${(ev.currentTarget as HTMLVideoElement).clientWidth}`);
              this.enableCameraButton = true;
            },
            false
          );
        })
        .catch(() => {
          this.enableCameraButton = false;
        });
    } catch (error) {
      this.enableCameraButton = false;
    }
  }

  takeSnap() {
    this.activeModal.dismiss(this.webcam.snap());
  }

  ngOnDestroy(): void {
    this.webcam.stop();
  }
}
