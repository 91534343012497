<div class="container-fluid">
  <div class="">
    <div class="flexbox header-box mb-2">
      <div class="d-flex align-items-center">
        <h2 class="underlined-title">Confirmation</h2>
      </div>
    </div>
    <h3 class="mt-0 mb-1" *ngIf="(isOfflinePayment$ | async) === false">
      Your seat has been confirmed
    </h3>
    <div class="content-box text-center d-flex flex-column p-4" *ngIf="(isOfflinePayment$ | async) === false">
      <h3 class="m-0">You have paid:</h3>
      <div class="text-red weight-bold card-item-header mt-2 mb-2" *ngIf="testFee$ | async; let testFee">
        {{ ((testFee?.totalAmount |
        currency: testFee?.currencyIsoCode : 'symbol-narrow') || '$').substr(0, 1)}}
        {{ toFix(testFee?.totalAmount)}}
        {{testFee?.currencyIsoCode}}
      </div>
      <p class="m-0 sub-text">Including GST</p>
    </div>
    <h3 class="mt-3 mb-2">
      Congratulations {{userProfile}}, a spot has been reserved for you on the following date:
    </h3>
    <div class="test-info-box d-flex flex-column flex-lg-row p-4 mb-2">
      <div class="d-flex flex-row flex-lg-grow-1 pb-4 pb-lg-0 test-item">
        <app-circle-date [date]="getTestDate$() | async"></app-circle-date>
        <div class="test-time ">
          <h3 class="test-name">
            <span class="d-lg-block d-none">{{testTitle || ''}}</span>
            <span class="d-lg-none d-block">{{testTitleMd || ''}}</span>
          </h3>
          <h3 class="time">
            <img src="../../../../assets/images/time-icon-black.svg" alt="time icon" class="icon d-none d-md-inline"/>
            <span>{{getTestTimeString$('time') | async}}</span>
            <div class="d-block d-md-none">(</div>
            <i class="fa fa-hourglass-half d-none d-md-inline" aria-hidden="true"></i>
            {{getDuration$() | async}} mins
            <div class="d-block d-md-none">)</div>
          </h3>
        </div>
      </div>
      <div class="d-flex text-end">
        <a href="#" class="a-link text-blue add-calendar-link d-flex flex-row align-items-center"
           (click)="generateIcal($event)" i18n>
          <div class="calendar-icon"></div>
          <u>Add to calendar</u></a>
      </div>
    </div>
    <h3 class="mt-0 mb-1" *ngIf="isOfflinePayment$ | async">
      Please pay offline and complete your profile to take the test.
    </h3>

  </div>
  <div class="container-footer">
    <app-two-button [acceptText]="'Return to Profile'" [rejectText]="btnRejectText" [inCenter]="false"
                    [enableAcceptClick]="true"
                    [showReject]="true" (rejectClick)="onCancelClick.emit()"
                    (acceptClick)="goToProfile()">
    </app-two-button>
  </div>
</div>

