import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-type',
  templateUrl: './upload-type.component.html',
  styleUrls: ['./upload-type.component.scss']
})
export class UploadTypeComponent {
  @Input() hasWebcam?: boolean;

  @Output() openCameraModal = new EventEmitter<void>();
  @Output() onUploadClick = new EventEmitter<void>();

}
