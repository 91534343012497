import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, of, race, Subject, throwError, timer } from 'rxjs';
import { setBackAction } from 'src/app/store/global.actions';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { OptionNavigator } from 'src/app/shared/utils/option-navigator';
import { addressValidator } from 'src/app/shared/validators/address-validator';
import { AddressSearchService, MapBoxService } from 'src/app/shared/services/map-box.service';
import { Router } from '@angular/router';
import { SharedStateService } from '../../shared-state.service';
import {
  catchError,
  concatMap,
  debounceTime,
  filter,
  finalize,
  first,
  map,
  share, switchMap,
  tap,
  throttleTime
} from 'rxjs/operators';
import { LocationService } from '../../../../shared/services/location.service';
import { SsrLocationMockService } from './ssr-location-mock.service';
import { setLocation } from '../../../../store/my-tests/my-tests.actions';
import { IState } from '../../../../store/my-tests/my-tests.reducer';

@Component({
  selector: 'app-test-location',
  templateUrl: './ssr-test-location.component.html',
  styleUrls: ['./ssr-test-location.component.scss']
})
export class SsrTestLocationComponent implements OnInit {
  state!: IState;
  currentStepNum = 0;
  totalStep = 1;
  locationSearchControl = new UntypedFormControl('', [Validators.required, addressValidator()]);
  focusIndex: number | undefined;
  inputElement!: HTMLInputElement;
  genericError = false;
  locationDisabled = false;
  isSelecting = false;
  selectedItem: any;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCancelClick: EventEmitter<any> = new EventEmitter();
  @Output() onssrTestDateClick: EventEmitter<any> = new EventEmitter();
  $form = new UntypedFormGroup({
    locationSearch: this.locationSearchControl,
  });
  showClearButton = false;
  submitted = false;
  locationOptions: any[] | undefined;
  submit$ = new Subject<string>();
  service!: AddressSearchService;
  onInputRender = (ref: ElementRef<HTMLInputElement>) => {
    this.inputElement = ref.nativeElement;
    ref.nativeElement.addEventListener('keyup', ($event: KeyboardEvent) => {
      if ($event.key === 'ArrowDown' && this.locationOptions && this.locationOptions.length > 0) {
        this.focusOnIndex(0);
      } else {
        this.focusIndex = undefined;
      }
    });
  }

  constructor(
    public router: Router,
    private sharedState: SharedStateService,
    public loadingService: LoadingService,
    private mockService: SsrLocationMockService,
    private mapBoxService: MapBoxService,
    private locationService: LocationService,
    private store: Store<{ globalStore }>,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(setBackAction({BackButtonEnable: true, BackButtonRoute: null}));
    this.store.select(appState => appState.globalStore).pipe(first()).subscribe((x) => {
      this.service = x.locationMode === 'Mock' ? this.mockService : this.mapBoxService;
    });
    merge(
      this.submit$.asObservable().pipe(tap(() => {
        this.genericError = false;
        this.locationDisabled = false;
        this.selectedItem = undefined;
      }), map(_ => this.locationSearchControl.value)),
      this.locationSearchControl.valueChanges.pipe(map(_ => undefined)),
      this.locationSearchControl.valueChanges.pipe(
        tap((str) => {
          this.loadingService.isLoading.next(false);
          this.selectedItem = undefined;
          this.submitted = false;
          this.genericError = false;
          this.locationDisabled = false;
          this.locationOptions = undefined;
          if (str) {
            this.showClearButton = true;
          } else {
            this.showClearButton = false;
          }
        }),
        debounceTime(500),
        filter(str => (str !== undefined && str !== null && str.length > 0))
      )
    ).pipe(
      throttleTime(500),
      switchMap(str => {
        if (str && !this.isSelecting) {
          this.submitted = true;
          if (this.$form.valid) {
            const api$ = this.service.searchAddresses(this.locationSearchControl.value).pipe(
              tap((result: any) => {
                if (result.features) {
                  this.locationOptions = result.features;
                }
              }),
              finalize(() => {
                this.loadingService.isLoading.next(false);
              }),
              catchError((err) => {
                this.genericError = true;
                this.locationOptions = undefined;
                return of({});
              }),
              share());
            return race(
              api$,
              timer(1500).pipe(concatMap(() => {
                this.loadingService.isLoading.next(true);
                return api$;
              }))
            );
          } else {
            return of(undefined);
          }
        } else {
          return of(undefined);
        }
      })).subscribe();
  }

  onSubmit(): void {
    this.locationDisabled = false;
    if (this.selectedItem === undefined) {
      this.submitted = true;
      this.genericError = false;
      if (this.$form.valid) {
        this.isSelecting = false;
        this.submit$.next(this.locationSearchControl.value);
      }
    }
  }

  onClear(): void {
    this.locationSearchControl.setValue('');
    this.locationSearchControl.updateValueAndValidity();
    this.inputElement.focus();
  }

  onUseCurrentLocation(): void {
    this.submitted = false;
    this.genericError = false;
    this.selectedItem = undefined;
    this.locationSearchControl.setValue('');
    this.locationSearchControl.updateValueAndValidity();
    const location$ = this.locationService.getCurrentLocation().pipe(
      catchError(error => {
        this.locationDisabled = true;
        return throwError(() => error);
      }),
      concatMap(location => {
        return this.service.reverseGeocode(location.coords.longitude, location.coords.latitude).pipe(catchError(error => {
          this.genericError = true;
          return throwError(() => error);
        }));
      }),
      tap((closestAddress: any) => {
        if (closestAddress.features && closestAddress.features.length > 0) {
          this.isSelecting = true;
          timer(510).subscribe(() => this.isSelecting = false);
          this.locationSearchControl.setValue(closestAddress.features[0].place_name);
          this.locationSearchControl.updateValueAndValidity();
          this.selectedItem = closestAddress.features[0];
          this.store.dispatch(setLocation({
            currentLoc: {
              lng: this.selectedItem.geometry.coordinates[0],
              lat: this.selectedItem.geometry.coordinates[1]
            },
            locLabel: closestAddress.features[0].place_name,
            locLabelShort: closestAddress.features[0].text ? closestAddress.features[0].text : closestAddress.features[0].place_name
          }));
        }
      }),
      finalize(() => this.loadingService.isLoading.next(false)),
      share()
    );
    race(location$, timer(100).pipe(concatMap(() => {
      this.loadingService.isLoading.next(true);
      return location$;
    }))).subscribe({
      error: err => {
        console.info('Bx Error: ' + err);
      }
    });
    location$.subscribe({
      error: err => {
        console.info('Bx Error: ' + err);
      }
    });
  }

  onSelect(index: number): void {
    if (this.locationOptions && this.locationOptions.length >= (index + 1)) {
      const selected = this.locationOptions[index];
      this.isSelecting = true;
      timer(510).subscribe(() => this.isSelecting = false);
      this.locationSearchControl.setValue(selected.place_name);
      this.locationSearchControl.updateValueAndValidity();
      this.selectedItem = selected;
      this.store.dispatch(setLocation({
        currentLoc: {
          lng: selected.geometry.coordinates[0],
          lat: selected.geometry.coordinates[1]
        },
        locLabel: selected.place_name,
        locLabelShort: selected.text ? selected.text : selected.place_name
      }));
    }
  }

  cancelScrollEvent($event: KeyboardEvent) {
    if ($event.key === 'ArrowDown' || $event.key === 'ArrowUp') {
      $event.preventDefault();
    }
  }

  optionKeyUp($event: KeyboardEvent) {
    if (this.locationOptions) {
      const newIndex = OptionNavigator.getNextIndex($event.key, this.focusIndex || 0, this.locationOptions.length);
      if (newIndex === -1) {
        this.inputElement.focus();
        this.focusIndex = undefined;
      } else if (newIndex !== undefined) {
        this.focusOnIndex(newIndex);
      }
    }
  }

  focusOnIndex(index: number) {
    document.getElementById('location-option-' + index)?.focus();
  }

  onFocus(index: number) {
    this.focusIndex = index;
  }

}
