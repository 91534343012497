import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter, Input,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import { PanelDirective } from '../../../directives/app-payment-panel.directive';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { PaypalComponent } from '../paypal/paypal.component';
import { OfflineComponent } from '../offline/offline.component';
import { StripeComponent } from '../stripe/stripe.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { setOfflinePayment } from 'src/app/pages/payment/store/payment.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { PaymentTypes } from 'src/app/pages/payment/payment.enum';
import { VeritransComponent } from '../veritrans/veritrans.component';
import { PaystackComponent } from '../paystack/paystack.component';
import { TipsComponent } from 'shared/components/payment/tips/tips.component';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { BankAlfalahComponent } from '../bank-alfalah/bank-alfalah.component';
import { BracComponent } from '../brac/brac.component';
import { ESewaComponent } from '../e-sewa/e-sewa.component';
import { PaydollarComponent } from '../paydollar/paydollar.component';
import { NewebpayComponent } from '../newebpay/newebpay.component';
import { InipayComponent } from '../inipay/inipay.component';
import { HsbcOmniComponent } from '../hsbc-omni/hsbc-omni.component';
import { GlobalState, selectEnableNewOfflinePayment } from 'store/global.reducer';
import { first } from 'rxjs';
export type IError = {
  message: string;
  type: 'warning' | 'error';
};
export type ICon = {
  url: string;
  alt: string;
  key: IPaymentMethods;
  class: string;
  description?: string;
  title?;
};
export type IPaymentCard = CreditCardComponent | PaypalComponent;
export type IValidationChange = {
  isValid: boolean;
  errors: Array<string>;
  cardErrors: string[];
};
@Component({
  selector: 'app-payment',
  templateUrl: './payment-container.component.html',
  styleUrls: ['./payment-container.component.scss'],
  animations: [
    trigger('inOutAnimationMenu', [
      transition(':enter', [
        style({ left: -300, opacity: 0 }),
        animate('.2s .1s ease-out', style({ left: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ left: 0, opacity: 1 }),
        animate('.2s ease-in', style({ left: -300, opacity: 0 })),
      ]),
    ]),
    trigger('inOutAnimationContent', [
      transition(':enter', [
        style({ left: 500, opacity: 0 }),
        animate('.2s .3s ease-in', style({ left: 0, opacity: 1 })),
      ]),
    ]),
  ],
})
export class PaymentContainerComponent implements AfterContentInit {
  @Input('currentType') set _currentType(v: IPaymentMethods) {
    this.currentType = v || 'CREDIT_CARD';
    this.onMethodChange.emit(this.currentType);
  }
  @ContentChildren(PanelDirective) paymentPanels: QueryList<PanelDirective>;
  @Input() tabs: ICon[] = [];
  @Input() paymentMethods: string[] = [];
  @Input() paymentType: string;
  @Input() set isStripeForwardingFailed(isFailed: boolean) {
    this.clearSelectedPayment();
  }
  @Output() onCreditCardLoad: EventEmitter<boolean> = new EventEmitter();
  types: IPaymentMethods[] = [];
  currentType: IPaymentMethods;
  currentTemplateRef: TemplateRef<any>;
  cardTypesIcon: ICon[];
  @Output() onMethodChange: EventEmitter<IPaymentMethods> = new EventEmitter();
  constructor(
    private router: Router,
    private store: Store<{ paymentStore, globalStore: GlobalState }>,
  ) {
    this.tabs.push(TipsComponent.tab);
    this.tabs.push(StripeComponent.tab);
    this.tabs.push(CreditCardComponent.tab);
    this.tabs.push(PaypalComponent.tab);
    this.tabs.push(PaystackComponent.tab);
    this.tabs.push(VeritransComponent.tab);
    this.tabs.push(OfflineComponent.tab);
    this.tabs.push(BankAlfalahComponent.tab);
    this.tabs.push(BracComponent.tab);
    this.tabs.push(ESewaComponent.tab);
    this.tabs.push(PaydollarComponent.tab);
    this.tabs.push(NewebpayComponent.tab);
    this.tabs.push(InipayComponent.tab);
    this.tabs.push(HsbcOmniComponent.tab);
  }
  ngAfterContentInit(): void {
    if (this.types.length === 0) {
      this.paymentPanels.forEach((panel) => {
        if (this.paymentMethods.indexOf(panel.paymentPanel) > -1) {
          this.manageCards(panel, this.currentType);
          this.types.push(panel.paymentPanel);
        }
      });
      this.cardTypesIcon = this.tabs
        .filter((i) => this.types.includes(i.key))
        .map((t) => ({ ...t }));
    }
  }
  private getPaymentMethodsLength() {
    let length = 0;
    this.paymentPanels.forEach((panel) => {
      if (this.paymentMethods.indexOf(panel.paymentPanel) > -1) {
        length += 1;
      }
    });
    return length;
  }
  private getFirstPaymentMethod(): IPaymentMethods {
    return this.paymentPanels.find((panel) => {
      if (this.paymentMethods.indexOf(panel.paymentPanel) > -1) {
        return true;
      }
    }).paymentPanel;
  }
  private manageCards(template: PanelDirective, type: IPaymentMethods) {
    if (type === template.paymentPanel) {
      this.currentTemplateRef = template.ref;
      if (template.paymentPanel === 'CREDIT_CARD') {
        this.onCreditCardLoad.emit(true);
      }
    }
  }
  changeType(key: IPaymentMethods) {
    this.currentType = key;
    this.paymentPanels.forEach((panel) => {
      this.manageCards(panel, key);
    });
    this.onMethodChange.emit(key);
    if (key === IPaymentMethods.OFFLINE && this.paymentType === PaymentTypes.normal) {
      this.OfflinePayment();
    }
  }
  clearSelectedPayment() {
    this.currentTemplateRef = null;
    this.onMethodChange.emit(null);
    this.onCreditCardLoad.emit(false);
  }

  OfflinePayment() {
    this.store
      .select(selectEnableNewOfflinePayment)
      .pipe(first())
      .subscribe((isOfflinePaymentEnabled) => {
        if (!isOfflinePaymentEnabled) {
          this.store.dispatch(
            setOfflinePayment({
              isOffline: true,
            })
          );
          this.router.navigate(['/payment/offline-payment']);
        }
      });
  }
}
