<app-form-wrapper>
    <div title class="tittle">{{leftSidePanel[activePage]?.title || ''}}</div>
    <div subTitle>
        <ng-container *ngIf="leftSidePanel[activePage]?.subTitle?.title1; else Subtitle">
            <p>{{leftSidePanel[activePage]?.subTitle?.title1 || ''}}</p>
            <p>{{leftSidePanel[activePage]?.subTitle?.title2 || ''}}</p>
        </ng-container>
        <ng-template #Subtitle>
            {{leftSidePanel[activePage]?.subTitle || ''}}
        </ng-template>
    </div>
    <div actions *ngIf="leftSidePanel[activePage]?.action.link">
        <h6 class="mb-1 mt-1 mb-sm-1 mt-sm-1 mb-md-1 mt-md-1 mb-lg-3 mt-lg-5">{{leftSidePanel[activePage]?.action.title || ''}}</h6>
        <a [routerLink]="[leftSidePanel[activePage]?.action.link]"
            class="btn btn-singleAction">{{leftSidePanel[activePage]?.action.linkTitle }}</a>
    </div>
    <div content [@routeAnimations]='getState(outlet)' class="main-content" style="display: flex;width: 100%">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</app-form-wrapper>
