import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../auth-guard.service';
import { MyProfileComponent } from './my-profile.component';

const routes: Routes = [{
  path: '',
  component: MyProfileComponent,
  data: { pagename: 'My profile details' },
  canActivate: [AuthGuardService],
  children: []
}];

@NgModule({
  imports: [
    RouterModule.forChild(routes)],
  exports: [RouterModule],

})
export class MyProfileRoutingModule {

}
