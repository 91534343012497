import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { ICon } from '../payment-container/payment-container.component';
import { Subscription } from 'rxjs';
import { UserState } from 'store/user/user.reducer';
import { NavigationService } from 'shared/services/navigation.service';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import { BracPaymentService } from '@idp-education/ors-brac-payment-bff-client-v1';
import { ConfirmModalComponent } from 'shared/components/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';
import { SharedStateService } from 'pages/account/shared-state.service';
@Component({
  selector: 'app-brac',
  templateUrl: './brac.component.html',
  styleUrls: ['./brac.component.scss']
})
export class BracComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @ViewChild('paymentModalPopup') paymentModalPopup: ConfirmModalComponent;
  @Input() isNewJourney = false;
  postReceipt: Subscription;
  sub: Subscription;
  bracSub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;

  title: string;
  acceptText: string;

  static get tab(): ICon {
    return {
      alt: 'BRAC',
      key: 'BRAC',
      url: '/assets/images/payment/brac.svg',
      class: '',
      title: 'Pay online with Visa and Mastercard',
      description: 'Secure payments powered by BRAC',
    };
  }
  constructor(
    private router: Router,
    private receiptService: ReceiptService,
    private navigationService: NavigationService,
    private bracPaymentService: BracPaymentService,
    private sharedState: SharedStateService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
   this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.BRAC) {
        this.openBRAC();
      }
    }));
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.postReceipt?.unsubscribe();
    this.sub?.unsubscribe();
    this.bracSub?.unsubscribe();
  }

  onAcceptClicked() {
    this.router.navigate(['/my-tests']);
    this.paymentModalPopup.closeModal(true);
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.BRAC);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };

    this.postReceipt = this.receiptService.createReceipt(uuid.v4(), createReceiptRequest).subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.BRAC);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
    if(!this.isNewJourney){
      this.openBRAC();
    }
    
  }

  openBRAC() {
    this.bracPaymentService.configuration.accessToken = this.userState?.userToken;
    this.bracSub = this.bracPaymentService.getPaymentUrl(this.applicationId, this.receiptResponse?.id, uuid.v4()).subscribe(res => {
      if (res.paymentUrl) {
        window.open(res.paymentUrl, '_self');
      } else {
        this.title = `Something went wrong. Please try again after sometime or please check your IELTS profile for the latest status or contact your test centre`;
        this.acceptText = 'Return to My Account';
        this.paymentModalPopup.open();
      }
    });
  }
}
