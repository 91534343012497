import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ICon } from '../payment-container/payment-container.component';
import { Application, CreateReceiptRequest, CreateReceiptResponse, ReceiptService } from '@idp-education/ors-test-taker-bff-client-v1';
import { PaystackAuthUrlRequest, PaystackPaymentService } from '@idp-education/ors-paystack-payment-bff-client-v1';
import { Subscription } from 'rxjs';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { UserState } from 'store/user/user.reducer';
import { Store } from '@ngrx/store';
import { NavigationService } from 'shared/services/navigation.service';
import { PaymentGateways, PaymentStates } from 'pages/payment/payment.enum';
import * as uuid from 'uuid';
import { SharedStateService } from 'pages/account/shared-state.service';

@Component({
  selector: 'app-paystack',
  templateUrl: './paystack.component.html',
  styleUrls: ['./paystack.component.scss']
})
export class PaystackComponent implements OnInit, OnDestroy {
  @Input() application: Application;
  @Input() applicationId: string;
  @Input() applicationPaymentId: string;
  @Input() tcPaymentMethod = [];
  @Input() paymentType: 'IOC' | 'EOR' | 'OSR';
  @Input() isNewJourney = false;

  postReceipt: Subscription;
  sub: Subscription;
  userState: UserState;
  receiptResponse: CreateReceiptResponse;
  static get tab(): ICon {
    return {
      alt: 'paystack',
      key: 'PAYSTACK',
      url: '',
      class: 'paystack-logo',
      title: 'Pay online with Visa and Mastercard',
      description: 'Secure payments powered by Paystack',
    };
  }
  constructor(
    private receiptService: ReceiptService,
    private paystackPaymentService: PaystackPaymentService,
    private navigationService: NavigationService,
    private sharedState: SharedStateService,
    private store: Store<{ userStore }>
  ) {
    this.sub = this.store.select(appState => appState.userStore).subscribe(x => {
      this.userState = x;
    });
  }

  ngOnInit(): void {
   this.sub.add(this.sharedState.triggerSubmit.subscribe((paymentDetails: any) => {
      if (paymentDetails.componentName === PaymentGateways.PAYSTACK) {
        this.onSubmit();
      }
    }));
    this.createReceipt();
  }

  ngOnDestroy(): void {
    this.postReceipt?.unsubscribe();
    this.sub?.unsubscribe();
  }

  createReceipt() {
    const tcPaymentMethodId = this.tcPaymentMethod.find(x => x.paymentMethod.code === IPaymentMethods.PAYSTACK);
    const createReceiptRequest: CreateReceiptRequest = {
      applicationId: this.applicationId,
      applicationPaymentId: this.applicationPaymentId,
      testCentrePaymentMethodId: tcPaymentMethodId?.id
    };
    this.postReceipt = this.receiptService.createReceipt(uuid.v4(), createReceiptRequest).subscribe(result => {
      this.receiptResponse = result;
      this.storeBookingInfo();
    });
  }

  storeBookingInfo() {
    this.navigationService.storePaymentType(this.paymentType);
    this.navigationService.storePaymentGateway(PaymentGateways.PAYSTACK);
    this.navigationService.storePaymentState(PaymentStates.INITIATED);
    switch (this.paymentType) {
      case 'IOC':
        this.navigationService.storeIOCInfo(this.application);
        break;
      case 'EOR':
        this.navigationService.storeEORInfo(this.application);
        break;
      case 'OSR':
        this.navigationService.storeOSRInfo(this.application);
        break;
      default:
        break;
    }
  }

  onSubmit() {
    this.paystackPaymentService.configuration.accessToken = this.userState?.userToken;
    const authRequest: PaystackAuthUrlRequest = {
      applicationId: this.receiptResponse.applicationId,
      receiptId: this.receiptResponse.id
    };
    this.paystackPaymentService.paystackAuthUrl(uuid.v4(), authRequest).subscribe(res => {
      if (res.authorization_url) {
        window.open(res.authorization_url, '_self');
      }
    });
  }

}
