import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-signup-field',
  templateUrl: './signup-field.component.html',
  styleUrls: ['./signup-field.component.scss']
})
export class SignupFieldComponent implements OnInit {
  // tslint:disable-next-line: no-inferrable-types
  @Input() hasStatus = true;
  @Input() classes: string[] = [];
  // tslint:disable-next-line: no-inferrable-types
  classesTemp = '';

  ngOnInit() {
    this.classesTemp = this.classes.join(' ');

  }

}
