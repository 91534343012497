<div class="main-account">
    <div class="org-shape"></div>
    <div class="content">
        <div class="menu" #menuSection>
            <app-nav-menu [userName]="userName"></app-nav-menu>
        </div>
        <div class="main-other-content">
            <div *ngIf="callToActions" class="call-to-action" #callToActionSection>
                <app-call-to-action *ngFor="let action of callToActions;trackBy:identify" [message]="action.message" [application]="action.application"
                    [route]="action.route" [cta]="action.cta" (onBeforeCall)="onBeforeCall.emit($event)" [class.hidden]="isCtaHidden$() | async">
                </app-call-to-action>
            </div>
            <ng-content select="[otherContent]"></ng-content>
        </div>
    </div>
</div>
