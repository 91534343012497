import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})

export class DobDateCheckerHelper {
  static checkDate(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const birthDate = formGroup.controls['birthDate'] || formGroup.controls['dateOfBirth'];
      if (!birthDate.value || (birthDate.errors && !birthDate.errors.date)) {
        return;
      }
      const bdDate = DateTime.fromFormat(birthDate.value || '', 'd/M/yyyy');
      const today = DateTime.now();
      if (bdDate.startOf('day') > today.startOf('day')) {
        birthDate.setErrors({ date: true });
      } else {
        birthDate.setErrors(null);
      }
      return null;
    };
  }

  getTooltip(under16YearsOld): string {
    if (under16YearsOld) {
      return $localize`Under 16 years old`;
    }
    return $localize`Your date of birth`;
  }

  birthDateChanged(form: UntypedFormGroup) {
    if (!form.get('birthDate').invalid) {
        const years = DateTime.now().diff(DateTime.fromFormat(form.get('birthDate').value || '', 'd/M/yyyy'), 'years').years;
        if (years < 16) {
          return true;
        } else {
          return false;
        }
      }
  }
}
