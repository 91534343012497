import { ToastrService } from 'ngx-toastr';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { Storage } from 'aws-amplify';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import Webcam from './webcam.js';
import compress from 'browser-image-compression';
import { isFunction, uniqueId } from 'lodash';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { IFacingMode } from '../../../models/components/upload-password-form';
import { Observable } from 'rxjs';
import { AuthService } from 'shared/services/auth.service';
import { first } from 'rxjs/operators';
import { rotate } from '../application-document-upload-form/image-transformer';
import { CameraFunctionsService } from 'shared/services/camera-functions.service';
const { S3Upload, featureFlags } = require('src/assets/appSettings.json');

@Component({
  selector: 'app-identity-document-upload-form',
  templateUrl: './identity-document-upload-form.component.html',
  styleUrls: ['./identity-document-upload-form.component.scss'],
})
export class IdentityDocumentUploadFormComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('imageUrl') inputImageUrl = null;
  @Input() renderHeading = false;
  @Input() showTitle = true;
  @Output() uploaded = new EventEmitter<object>();
  @Output() deleted = new EventEmitter();

  @ViewChild('inputfile') inputFile: ElementRef;
  @ViewChild('uploadBox') uploadBox: ElementRef;
  @ViewChild('deletePhotoModal') deletePhotoModal: ConfirmModalComponent;

  completeUpload = false;
  hasWebcam = false;
  photo: string;
  visibleCamera = false;
  webcam;
  currentFacingMode: IFacingMode = 'user';
  modalCamera: NgbModalRef;
  modalCrop: NgbModalRef;
  croppedImage: any = '';
  errorCode: string;
  MAX_FILE_SIZE = 'MAX_FILE_SIZE';
  percent = 0;
  uploadObj: Promise<object> = null;
  hasUploadError = false;
  uploadError = '';
  enableCameraButton = true;
  visibleButtons = true;
  imageUrl = '';
  editImageState:
    | 'notStarted'
    | 'inEditRotateLeft'
    | 'inEditRotateRight'
    | 'inEditFlip' = 'notStarted';
  multipartMap = {
    Parts: [],
  };
  partNum = 0;
  numPartsLeft;
  fileKey;
  lastUploadedSize = 0;

  public file: File;
  public featureFlags: any = featureFlags;

  private readonly pdfIcon = 'src/assets/images/pdfIcon.svg';
  private userIdentityPoolId: string;
  private maxFileLength = 5 * 1024 * 1024;
  private fileBuffer: Uint8Array;
  readonly partSize = 1024 * 1024 * 5;
  readonly maxUploadTries = S3Upload.maxUploadTries;
  readonly BUCKET_NAME = S3Upload.BucketName;

  constructor(
    private modalService: NgbModal,
    private loading: LoadingService,
    private authenticationService: AuthService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private cameraService: CameraFunctionsService
  ) {}

  ngOnInit(): void {
    this.loading.increaseLoadingCounter();
    this.authenticationService.fetchCurrentUserInfo().then((cred) => {
      this.userIdentityPoolId = cred.id;
      this.loading.resetLoadingCounter();
    });
  }

  watchInputFileChange() {
    this.inputFile.nativeElement.addEventListener(
      'change',
      this.onInputFileChange.bind(this)
    );
  }

  ngAfterViewInit(): void {
    this.watchInputFileChange();
    this.detectWebcam().then((result) => {
      this.hasWebcam = result;
    });
  }

  async getIdentityPoolId() {
    return await this.authenticationService.fetchCurrentUserInfo();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes?.inputImageUrl?.currentValue) {
      this.visibleButtons = false;
      this.hasUploadError = false;
      this.completeUpload = true;
      this.imageUrl = await this.getPhoto(changes.inputImageUrl.currentValue);
      if (!this.file) {
        this.convertUrlToFile(this.imageUrl).then((f) => (this.file = f));
      }
      setTimeout(() => {
        this.resetBackground();
        this.setBackground(this.imageUrl);
      }, 1);
    } else {
      this.completeUpload = false;
      this.visibleButtons = true;
      this.hasUploadError = false;
      this.errorCode = '';
      this.photo = null;
      setTimeout(() => {
        (this.inputFile.nativeElement as HTMLInputElement).value = '';
        this.resetBackground();
        this.watchInputFileChange();
      }, 1);
    }
  }

  public actionKeuUpEvent(event: KeyboardEvent, callback: any, args?: any[]) {
    if (event.code === 'Enter' && isFunction(callback)) {
      args ? callback.apply(this, args) : callback.call(this);
    }
  }

  convertUrlToFile(url: string): Promise<File> {
    const uuid = uniqueId('TEST_ID_');
    return new Promise((resolve) => {
      fetch(url).then(async (response) => {
        const contentType = response.headers.get('content-type');
        const blob = await response.blob();
        const file = new File([blob], uuid, { type: contentType });
        resolve(file);
        // access file here
      });
    });
  }

  onInputFileChange(e: Event) {
    const file = (e.target as HTMLInputElement).files[0];
    if (this.prepareUpload(file)) {
      this.compressFile(file).then((f) => this.uploadFile(f));
    }
  }

  async uploadFile(file: File) {
    if (!file) {
      return;
    }
    this.file = file;
    this.visibleButtons = false;
    this.hasUploadError = false;
    const fileName = file.name;
    this.uploadObj = Storage.put(fileName, file, {
      progressCallback: (value) => {
        if (this.loading.isLoading) {
          this.loading.resetLoadingCounter();
        }
        this.onUploadProgress(value);
      },
    });
    this.uploadObj.then(async (data: { key: string }) => {
      this.photo = data.key;
      this.resetBackground();
      this.imageUrl = (await this.getPhoto(data.key)) as string;
      const getAmzVer = (await this.getVersion(data.key));
      if (this.userIdentityPoolId) {
        this.uploaded.emit({ URL : `/${this.userIdentityPoolId}/${file.name}`, amzVersion: getAmzVer });
        this.setBackground(this.imageUrl);
        this.completeUpload = true;
        this.setBackground(this.imageUrl);
      } else {
        const cred = await this.getIdentityPoolId();
        const id = cred?.id;
        if (id) {
          this.uploaded.emit({ URL : `/${id}/${file.name}`, amzVersion: getAmzVer });
          this.setBackground(this.imageUrl);
          this.completeUpload = true;
          this.setBackground(this.imageUrl);
        } else {
          this.toastr.error(
            'Oops .. something went wrong. Please refresh the page and try again'
          );
        }
      }
      return true;
    });
    this.uploadObj.catch((error) => {
      if (error.toString().toLowerCase().includes('cancel')) {
        this.hasUploadError = true;
      } else {
        this.toastr.error(
          'sorry, Something was wrong'
        );
      }
    });
    this.uploadObj.finally(() => {
      if (this.loading.isLoading) {
        this.loading.resetLoadingCounter();
      }
      if (this.webcam && this.webcam.stop) {
        this.webcam.stop();
      }
    });
  }

  async getPhoto(key: string): Promise<string> {
    return (await Storage.get(key)) as string;
  }

  async getVersion(key: string) {
    const arr = await Storage.get(key, { download: true }) as any;
    return arr.VersionId;
  }

  takeSnap() {
    try {
      this.closeCameraModal();
      const pic = this.webcam.snap();
      if (!pic) {
        throw new Error('');
      }
      fetch(pic)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${+new Date()}.jpg`, blob);
          this.file = file;
          if (this.prepareUpload(file)) {
            this.uploadFile(file);
          }
          this.visibleCamera = false;
          this.visibleButtons = false;
        });
      this.webcam.stop();
    } catch (error) {
      this.visibleCamera = false;
      this.visibleButtons = true;
      this.watchInputFileChange();
      if (this.webcam) {
        this.webcam.stop();
      }
    } finally {
      if (!this.visibleButtons) {
        this.modalCamera.close();
      }
    }
  }

  flipCamera() {
    this.currentFacingMode = this.cameraService.flipCamera(this.currentFacingMode);
    this.turnOnWebcam(this.currentFacingMode).subscribe();
  }
  private setBackground(url: string) {
    this.cameraService.setBackground(url, this.uploadBox, this.pdfIcon);
  }

  validateFile(file: File) {
    let valid = true;
    if (file && file.size > this.maxFileLength) {
      this.uploadError = $localize`maximum file length is ${this.maxFileLength / 1024 / 1024
        }MB`;
      valid = false;
    }
    if (file && !this.isImage(file)) {
      this.uploadError = $localize`file types accepted are JPG and PNG`;
      valid = false;
    }
    return valid;
  }

  isImage(file: File) {
    return !!['png', 'jpg', 'jpeg'].filter((f) => file.type.split('/')[1] === f)
      .length;
  }

  isPDF(file: File) {
    return !!['pdf'].filter((f) => file.type.split('/')[1] === f).length;
  }

  resetUpload() {
    this.visibleButtons = true;
    this.hasUploadError = false;
    this.errorCode = '';
    this.uploadBox.nativeElement.style.backgroundSize = '0%';
    setTimeout(() => {
      (this.inputFile.nativeElement as HTMLInputElement).value = '';
      this.inputFile.nativeElement.addEventListener(
        'change',
        this.onInputFileChange.bind(this)
      );
    }, 1);
  }

  cancelUpload() {
    this.resetUpload();
  }

  tryAgainUpload() {
    this.visibleButtons = true;
    setTimeout(() =>{
      this.cameraService.tryAgainUpload(
        this.uploadError,
        this.resetBackground.bind(this),
        this.inputFile?.nativeElement as HTMLInputElement,
        this.file,
        this.prepareUpload.bind(this),
        this.uploadFile.bind(this),
        this.watchInputFileChange.bind(this)
      );
    },1);
  }

  onUploadProgress(progress) {
    const percent = Math.round((progress.loaded / progress.total) * 100);
    this.uploadBox.nativeElement.style.backgroundSize = `${percent}%`;
    this.percent = percent;
  }

  onMultiUploadProgress(progress) {
    const percent = Math.round(
      ((progress.loaded + this.lastUploadedSize) / this.fileBuffer.length) * 100
    );
    this.uploadBox.nativeElement.style.backgroundSize = `${percent}%`;
    this.percent = percent;
  }

  abortUpload() {
    Storage.cancel(this.uploadObj);
    this.resetUpload();
  }

  onCameraClick(fn: () => void) {
    this.enableCameraButton = false;
    setTimeout(() => {
      this.visibleButtons = false;
      this.visibleCamera = true;
      const webcam = this.turnOnWebcam();
      const subscription = webcam.subscribe((result) => {
        if (result) {
          fn();
          this.enableCameraButton = true;
          subscription.unsubscribe();
        } else {
          if (this.modalCamera && isFunction(this.modalCamera.close)) {
            this.modalCamera.close();
          }
          this.visibleButtons = true;
          setTimeout(() => {
            this.inputFile.nativeElement.addEventListener(
              'change',
              this.onInputFileChange.bind(this)
            );
          }, 1);
          this.visibleCamera = false;
          subscription.unsubscribe();
        }
      });
    }, 1);
  }

  onUploadClick() {
    this.inputFile.nativeElement.click();
  }

  deleteUpload() {
    this.deletePhotoModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      this.deleted.emit();
      this.resetBackground();
      this.photo = null;
      this.imageUrl = '';
      this.completeUpload = false;
      this.visibleButtons = true;
      this.hasUploadError = false;
      setTimeout(() => {
        (this.inputFile.nativeElement as HTMLInputElement).value = '';
        this.inputFile.nativeElement.addEventListener(
          'change',
          this.onInputFileChange.bind(this)
        );
      }, 1);
    });
  }

  reTry() {
    this.deletePhotoModal.open().result.then((result: 'accept' | 'reject') => {
      if (result === 'reject') {
        return;
      }
      this.completeUpload = false;
      this.deleted.emit();
      this.imageUrl = '';
      this.visibleButtons = true;
      this.hasUploadError = false;
      this.errorCode = '';
      this.resetBackground();
      this.photo = null;
      setTimeout(() => {
        (this.inputFile.nativeElement as HTMLInputElement).value = '';
        this.watchInputFileChange();
      }, 1);
    });
  }

  private resetBackground() {
    this.percent = 0;
    this.uploadBox.nativeElement.style.background = '';
    this.uploadBox.nativeElement.style.backgroundSize = '0%';
    this.uploadBox.nativeElement.style.backgroundPosition = 'left';
    this.uploadBox.nativeElement.style.backgroundColor =
      'linear-gradient(136deg, rgba(0,53,117,1) 0%, rgba(189,96,165,1) 100%) !important';
  }

  prepareUpload(file: File) {
    this.file = file;
    if (this.validateFile(file)) {
      return true;
    } else {
      this.hasUploadError = true;
      this.visibleButtons = false;
      return false;
    }
  }

  openCameraModal(content) {
    this.modalCamera = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.onCameraClick(() => {
      this.modalCamera.result
        .then((r) => {
          if (this.webcam && this.webcam.stop) {
            this.webcam.stop();
          }
        })
        .catch((e) => {
          this.visibleButtons = true;
          setTimeout(() => {
            this.watchInputFileChange();
          }, 1);
          this.visibleCamera = false;
          this.modalCamera.close();
          if (this.webcam && this.webcam.stop) {
            this.webcam.stop();
          }
        })
        .finally(() => {
          setTimeout(() => {
            if (this.webcam && this.webcam.stop) {
              this.webcam.stop();
            }
          }, 1);
        });
    });
  }

  closeCameraModal() {
    this.modalCamera.dismiss('Cross click');
    this.modalCamera.close();
    setTimeout(() => {
      (this.inputFile.nativeElement as HTMLInputElement).value = '';
      this.inputFile.nativeElement.addEventListener(
        'change',
        this.onInputFileChange.bind(this)
      );
    }, 1);
  }

  openCropModal(content) {
    this.modalCrop = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalCrop.result.then(
      async (r) => {
        try {
          await fetch(this.croppedImage)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], 'File name', { type: 'image/png' });
              this.file = file;
              this.completeUpload = false;
              if (file && this.prepareUpload(file)) {
                this.resetBackground();
                this.compressFile(file).then((f) => {
                  this.uploadFile(f);
                });
              }
            });
        } catch (error) {
          this.toastr.error(
            'sorry, Something was wrong'
          );
        }
      },
      (e) => console.log(e)
    );
  }

  async compressFile(file: File) {
    if (this.isPDF(file)) {
      return file;
    }
    const options = {
      maxSizeMB: this.maxFileLength / 1024 / 1024, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1920,
    };
    try {
      const compressedFile = await compress(file, options);
      return compressedFile;
    } catch (error) {
      return null;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  private turnOnWebcam(
    facingMode: IFacingMode = 'environment'
  ): Observable<boolean> {
    return new Observable((observer) => {
      if (!this.visibleButtons && this.visibleCamera) {
        if (this.webcam && this.webcam.stop) {
          this.webcam.stop();
        }
        this.currentFacingMode = facingMode;

        try {
          const webcamElement = document.getElementById(
            'webcam'
          ) as HTMLVideoElement;
          const canvasElement = document.getElementById(
            'canvas'
          ) as HTMLCanvasElement;
          this.webcam = new Webcam(webcamElement, facingMode, canvasElement);
          this.webcam
            .start()
            .then((result) => {
              webcamElement.addEventListener(
                'canplay',
                (ev) => {
                  this.webcam.setHeight(
                    `${(ev.currentTarget as HTMLVideoElement).clientHeight}`
                  );
                  this.webcam.setWidth(
                    `${(ev.currentTarget as HTMLVideoElement).clientWidth}`
                  );
                  observer.next(true);
                },
                false
              );
            })
            .catch((err) => {
              this.visibleButtons = true;
              this.watchInputFileChange();
              this.visibleCamera = false;
              observer.next(false);
            });
        } catch (error) {
          observer.next(false);
        }
      } else {
        observer.next(false);
      }
    });
  }

  detectWebcam(): Promise<boolean> {
    const md = navigator.mediaDevices;
    return new Promise((resolve, reject) => {
      if (!md || !md.enumerateDevices) {
        resolve(false);
      } else {
        md.enumerateDevices().then((devices) => {
          resolve(devices.some((device) => 'videoinput' === device.kind));
        });
      }
    });
  }

  guidGenerator() {
    const S4 = () =>
      (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  }

  private loadImage(file: File, context: CanvasRenderingContext2D, onload) {
    const img = new Image();
    img.onload = onload;
    img.setAttribute('crossorigin', 'anonymous');
    img.src = URL.createObjectURL(file);
    return img;
  }

  private mirrorImage(
    ctx: CanvasRenderingContext2D,
    image: HTMLImageElement,
    x = 0,
    y = 0,
    horizontal = false,
    vertical = false
  ) {
    ctx.save();
    ctx.setTransform(-1, 0, 0, 1, ctx.canvas.width, 0);
    ctx.drawImage(image, 0, 0, x, y);
    ctx.restore();
  }

  transformImage(
    file: File = this.file,
    type: 'mirror' | 'rotate-left' | 'rotate-right'
  ): Observable<File> {
    const canvas: HTMLCanvasElement = document.createElement(
      'canvas'
    ) as HTMLCanvasElement;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    if (this.editImageState !== 'notStarted') {
      return;
    }
    if (type === 'mirror') {
      this.editImageState = 'inEditFlip';
    } else if (type === 'rotate-left') {
      this.editImageState = 'inEditRotateLeft';
    } else if (type === 'rotate-right') {
      this.editImageState = 'inEditRotateRight';
    }
    const obs: Observable<File> = new Observable((sub) => {
      this.loadImage(file, ctx, (e: Event) => {
        try {
          const img = e.target as HTMLImageElement;
          canvas.width = img.width;
          canvas.height = img.height;
          if (type === 'mirror') {
            this.mirrorImage(ctx, img, img.width, img.height, false, true);
          } else if (type === 'rotate-left') {
            rotate(ctx, img, -90);
          } else if (type === 'rotate-right') {
            rotate(ctx, img, 90);
          }
          canvas.toBlob(
            (blob) => {
              sub.next(new File([blob], file.name, { type: 'image/jpeg' }));
            },
            'image/jpeg',
            1
          );
        } catch (error) {
          sub.error(error);
        }
      });
    });
    obs.pipe(first()).subscribe({
      next: (newFile) => {
        this.file = newFile;
        this.completeUpload = false;
        if (newFile && this.prepareUpload(newFile)) {
          this.resetBackground();
          this.compressFile(newFile).then((f) => {
            this.uploadFile(f).then(() => {
              this.editImageState = 'notStarted';
              this.completeUpload = true;
              this.cdr.detectChanges();
            });
          });
        }
      },
      error: () => {
        this.editImageState = 'notStarted';
        this.completeUpload = true;
      },
      complete: () => {
        setTimeout(() => {
          this.cdr.detectChanges();
        }, 100);
      },
    });
    return obs;
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
    if (this.webcam) {
      this.webcam.stop();
    }
  }
}
